import ActionIcon from 'components/ActionIcon';
import TailArrowIcon from 'components/Icons/TailArrowIcon';
import React from 'react'
import {useHistory} from "react-router-dom";
import { Wrapper } from 'styled/Wrapper';
import Text from "../../../../components/Text";
import styled, {css, useTheme} from "styled-components";

const HeaderDoctorMobile = ({leftIcon, iconTooltip = 'Назад', action, center, subtitle, rightSide, path, withoutShadow}) => {

    const history = useHistory();
    const theme = useTheme();
    const RightSide = rightSide;

    return (
        <HeaderWrapper direction="row" withoutShadow={withoutShadow}>
            <ActionIconWrapper>
                <ActionIcon
                    action={action ? () => action() : path && (() => {history.push({
                        pathname: path,
                    })})}
                    size={'24px'}
                    icon={leftIcon || TailArrowIcon}
                    tooltip={iconTooltip}
                    rotate={!leftIcon && 90}
                />
            </ActionIconWrapper>
            <Wrapper>
                <Text text={center} tag={"h4"} />
                {subtitle &&
                    <Text text={subtitle} tag={"text"} color={theme.colors.black54} font={'robotoCondensedRegular'} />
                }
            </Wrapper>
            <Wrapper flex="1 0">
                {rightSide && <RightSide/>}
            </Wrapper>
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
  display: flex;
  height: 50px;
  padding: 0 6px 0 16px;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  ${({theme}) => theme.mixins.innerShadow()};
  ${({theme, withoutShadow}) => withoutShadow && theme.isMobile && css`box-shadow: none`}; 
`

const ActionIconWrapper = styled.div`
  margin-right: 16px;  
`

export default HeaderDoctorMobile
