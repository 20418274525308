import {rgba} from "polished";

export const renderColor = (theme, type) => {
    switch(type) {
        case 'secondary':
            return theme.colors.secondary
        case 'green':
            return theme.colors.green
        case 'yellow':
            return theme.colors.orange
        case 'blue':
            return theme.colors.primary
        case 'purple':
            return theme.colors.purple
        case 'black':
            return theme.colors.black54
        case 'red':
            return theme.colors.red
        case 'orange':
            return theme.colors.orange
        default:
            return theme.colors.primary
    }
}
