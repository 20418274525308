import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { findInTypeBySystem } from "utils";
import {find, get} from "lodash";
import dayjs from "dayjs";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import {routes} from "consts/routes";
import { systemMessageDelay } from 'consts';

const initialState = {
    managerId: null,
    managerDetails: null,
    loading: {
        fullPage: true,
        data: true,
        managerFullPage: true,
        managerDetails: true
    },
    adminLogin: null
};

export const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        setManagerId: (state, action) => {
            state.managerId = action.payload;
        },
        setManagerDetails: (state, action) => {
            state.managerDetails = action.payload;
        },
        setManagerData: (state, action) => {
            state.data = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setAdminLogin: (state, action) => {
            state.adminLogin = action.payload;
        },
        resetState: (state, action) => {
            return initialState
        },
    },
});

export const selectManagerDetails = state => state.fundAdmin.manager.managerDetails;
export const selectManagerId = state => state.fundAdmin.manager.managerId;
export const selectManagerData = state => state.fundAdmin.manager.data;
export const selectLoading = state => state.fundAdmin.manager.loading;
export const selectAdminLogin = state => state.fundAdmin.manager.adminLogin;

export const ManagerActions = managerSlice.actions;

export const getManager = (id) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}?fg=practitioner::${id}:x:_include::practitioner,organization`
        }).then((response) => {

            let managerDetails = {};

            if (response.data.items) {
                const item = response.data.items.find(item => item.practitioner.id === id);

                managerDetails = {
                    id,
                    name: get(response.data, `resources[${get(item, "practitioner.reference")}].name[0].text`),
                    status: find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"})?.valueCoding?.code,
                    gender: get(response.data, `resources[${get(item, "practitioner.reference")}].gender`),
                    email: find(item?.telecom, {system: "email"})?.value,
                    organization: get(response.data, `resources[${get(item, "organization.reference")}].name`),
                    phone: find(item?.telecom, {system: "phone"})?.value,
                    role: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.display,
                    created: dayjs(find(item?.meta?.extension, {url: "ex:createdAt"})?.valueInstant).locale("ru").format("D MMMM YYYY"),
                };
            }

            dispatch(ManagerActions.setManagerDetails(managerDetails));
            dispatch(ManagerActions.setManagerData(response.data));
            dispatch(ManagerActions.setLoading({
                type: 'managerFullPage',
                value: false
            }))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const createOrUpdateManagerData = ({formData, managerId, callback}) => {
    return (dispatch) => {
        dispatch(ManagerActions.setLoading({
            type: 'createOrUpdateManager',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/createorupdateorgmanager`,
            data: {
                practitionerInfo: {
                    practitionerId: formData?.practitionerId,
                    fio: formData?.name,
                    gender: formData?.gender,
                    isActive: true,
                    photoUrl: formData?.practitionerPhoto || null,
                },
                practitionerRoleInfo: {
                    positionCode: formData?.role,
                    positionDisplay: formData?.roleName,
                    practitionerRoleId: formData?.id,
                    statusCode: formData?.status?.valueCoding?.code,
                    statusDisplayCode: formData?.status?.valueCoding?.display,
                    isActive: true,
                    organizationId: formData?.organization,
                    notifyWithEmail: true,
                    contactEmail: formData?.email,
                    phone: formData?.phone,
                    // loginEmail: formData?.loginEmail
                },
                appRole: 'organization-admin'
            }
        }).then((response) => {
            dispatch(ManagerActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            managerId && dispatch(getManager(managerId));
            callback && callback();

            dispatch(registerMessage({
                name: 'create-update-doctor-message',
                type: 'primary',
                title: 'Руководитель МО успешно обновлен',
                closable: true,
            }))
            dispatch(showMessage('create-update-doctor-message'))
            dispatch(closeMessage({name: 'create-update-doctor-message', delay: systemMessageDelay}))
        }).catch(err => {
            dispatch(ManagerActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            dispatch(registerMessage({
                name: 'create-update-doctor-error-message',
                type: 'red',
                title: 'Не удалось обновить руководителя МО',
                closable: true,
            }))
            dispatch(showMessage('create-update-doctor-error-message'))
            dispatch(closeMessage({name: 'create-update-doctor-error-message', delay: systemMessageDelay}))

            console.log(err)
        });
    }
}

export const sendActivationNotification = id => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.activationNotification}/${id}`
        }).then((response) => {
            dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
            dispatch(showMessage('activation-notification-message'))
            dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
        }).catch(error => {
            console.log("error", error);
            dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
            dispatch(showMessage('activation-notification-error-message'))
            dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
        });
    };
};

export const restoreManager = ({id, managerId, restoreOrganizationId}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${id}/RestoreFromArchive`,
        params: {
            organizationId: restoreOrganizationId
        }
        }).then((response) => {
            dispatch(getManager(managerId));
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const deleteManager = ({id, managerId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
            dispatch(registerMessage({name: 'delete-manager-message', type: 'primary', title: 'Руководитель удален', closable: true, url: routes.private.fund_admin.managers.path}))
            dispatch(showMessage('delete-manager-message'))
            dispatch(closeMessage({name: 'delete-manager-message', delay: systemMessageDelay}))
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const archiveManager = ({item, managerId}) => {
    return (dispatch) => {
      fetchInstance({
            method: "PUT",
            url: api.practitionerRole,
            data: item
        }).then((response) => {
            dispatch(getManager(managerId));
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const resetPassword = (email, userType) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.resetPassword}/${email}`,
        params: {
            usertype: userType
        }
    }).then((response) => {
          dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
          dispatch(showMessage('send-email-message'))
          dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
    }).catch((error) => {
          dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
          dispatch(showMessage('reset-password-error-message'));
          dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
    })
    };
};

export const getAdminLogin = ({roleId}) => {
    return (dispatch) => {
    fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
        }).then((response) => {
            const login = get(response, 'data.value');
            login && dispatch(ManagerActions.setAdminLogin(login));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default managerSlice.reducer;
