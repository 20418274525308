import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';
import { isEmpty, join } from 'lodash';

const initialState = {
    data: {},
    actions: {},
    loading: {
        actions: false,
        protocolChannelsTransition: {
            status: false,
            progress: 0,
        },
        fullPage: true
    }
};

export const protocolSlice = createSlice({
    name: 'protocol',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setActions: (state, action) => {
            state.actions = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        resetState: (state, action) => {
            return initialState
        }
    },
});

export const selectData = state => state.superAdmin.protocol.data;
export const selectActions = state => state.superAdmin.protocol.actions;
export const selectLoading = state => state.superAdmin.protocol.loading;
export const selectProtocolActions = state => state.superAdmin.protocol.actions;
export const ProtocolActions = protocolSlice.actions;

export const getProtocol = ({id}) => {
    return (dispatch) => {
        dispatch(ProtocolActions.setLoading({
            type: 'fullPage',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/${id}`
        }).then(response => {
            dispatch(ProtocolActions.setData(response?.data));
            dispatch(ProtocolActions.setLoading({
                type: 'fullPage',
                value: false
            }));
        }).catch(err => {
            console.log(err);
            dispatch(ProtocolActions.setLoading({
                type: 'fullPage',
                value: false
            }));
        });
    };
};

export const getProtocolActions = ({id}) => {
    return (dispatch) => {
        dispatch(ProtocolActions.setLoading({
            type: 'actions',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/${id}/GetProtocolActions`
        }).then(response => {
            dispatch(ProtocolActions.setActions(response?.data));
            dispatch(ProtocolActions.setLoading({
                type: 'actions',
                value: false
            }));
        }).catch(err => {
            console.log(err);
            dispatch(ProtocolActions.setLoading({
                type: 'actions',
                value: false
            }));
        });
    };
};

export const addProtocolAction = ({formData, planDefinitionId, callback}) => {
    console.log("formData", formData);
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/${planDefinitionId}/AddProtocolAction`,
            data: formData
        }).then(response => {
            callback();
            dispatch(getProtocol({id: planDefinitionId}));
            dispatch(getProtocolActions({id: planDefinitionId}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'add-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('add-protocol-action-error-message'))
                dispatch(closeMessage({name: 'add-protocol-action-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'add-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось добавить мероприятие протокола',
                    closable: true,
                }))
                dispatch(showMessage('add-protocol-action-error-message'))
                dispatch(closeMessage({name: 'add-protocol-action-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const editProtocolAction = ({formData, planDefinitionId, callback}) => {
    console.log("formData", formData);
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/${planDefinitionId}/EditProtocolAction`,
            data: formData
        }).then(response => {
            callback();
            dispatch(getProtocol({id: planDefinitionId}));
            dispatch(getProtocolActions({id: planDefinitionId}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'edit-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-action-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-action-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'edit-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось отредактировать мероприятие протокола',
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-action-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-action-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const deleteProtocolAction = ({planDefinitionId, actionId, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.planDefinition}/${planDefinitionId}/DeleteProtocolAction?actionId=${actionId}`
        }).then(response => {
            callback();
            dispatch(getProtocol({id: planDefinitionId}));
            dispatch(getProtocolActions({id: planDefinitionId}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'delete-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-action-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-action-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'delete-protocol-action-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось удалить мероприятие протокола',
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-action-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-action-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const changeProtocolStatus = ({item, status}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.planDefinition}/${item?.id}/SetStatus`,
            params: {
                status
            }
        }).then(response => {
            dispatch(getProtocol({id: item?.id}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'activate-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('activate-protocol-error-message'))
                dispatch(closeMessage({name: 'activate-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'activate-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось сменить статус протокола',
                    closable: true,
                }))
                dispatch(showMessage('activate-protocol-error-message'))
                dispatch(closeMessage({name: 'activate-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const deleteProtocol = ({id, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.planDefinition}/${id}`
        }).then(response => {
            callback();
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'delete-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'delete-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось удалить протокол',
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const editProtocol = ({item}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.planDefinition}`,
            data: item
        }).then(response => {
            dispatch(getProtocol({id: item?.id}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'edit-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'edit-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось отредактировать протокол',
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const getActivityReasons = () => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.activityReason}/_search`,
            data: {
                paging: {
                startIndex: 0,
                maxItems: 10
            },
            filtering:{
                searchString:  null,
                fieldsFilter: {
                }
            },
            sorting: [
                {
                    direction: 0,
                    propertyName: "name"
                }
                ]
            } 
        }).then(response => {
        }).catch(err => {
            console.log(err);
        });
    };
};

export const getActivityDefinitionById = ({id, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.activityDefinition}/_search`,
            data: {
                paging: {
                startIndex: 0,
                maxItems: 0
            },
            filtering:{
                searchString:  null,
                fieldsFilter: {
                    id
                }
            },
            sorting: [
                {
                    direction: 0,
                    propertyName: "name"
                }
                ]
            } 
        }).then(response => {
            // console.log(response.data);
            callback(response.data?.items[0]);
        }).catch(err => {
            console.log(err);
        });
    };
};

export const substituteProtocol = ({oldItem, newItem, handleHide}) => {
    return (dispatch) => {
        dispatch(ProtocolActions.setLoading({
            type: 'protocolChannelsTransition',
            value: {
                status: true,
                progress: 0,
            }
        })); 
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/Substitute`,
            data: {
                oldId: oldItem?.id,
                newId: newItem?.id
            }
        }).then((response) => {

            dispatch(getSubstituteUpdateStatus({id: response?.data?.jobId, item: oldItem, handleHide: handleHide, iteration: 14400})); // Длительность в секундах 4ч - 14400

        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({
                name: 'protocol-substitute-error-message',
                type: 'red',
                title: 'Ошибка',
                text: 'Не удалось инициализировать процесс перенос каналов на новый протокол',
                closable: true,
            }))
            dispatch(showMessage('protocol-substitute-error-message'))
            dispatch(closeMessage({name: 'protocol-substitute-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getSubstituteUpdateStatus = ({id, item, handleHide, iteration}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/GetSubstituteResult/${id}`,
        }).then((response) => {
            if (response?.data?.status === "Finished" || response?.data?.status === "Failed" || !iteration) {
                handleHide && handleHide();
                dispatch(ProtocolActions.setLoading({
                    type: 'protocolChannelsTransition',
                    value: {
                        status: false,
                        progress: response?.data?.percentCompleted,
                    }
                }));

                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                }

                dispatch(showSubstituteResult({id}))
                response?.data?.status !== "Failed" && dispatch(changeProtocolStatus({
                    item,
                    status: "retired"
                }));
            } else {
                dispatch(ProtocolActions.setLoading({
                    type: 'protocolChannelsTransition',
                    value: {
                        status: true,
                        progress: response?.data?.percentCompleted,
                    }
                })); 
                setTimeout(() => dispatch(getSubstituteUpdateStatus({id, item, handleHide, iteration: --iteration})), 1000);
            }
        }).catch(err => {
            dispatch(ProtocolActions.setLoading({
                type: 'protocolChannelsTransition',
                value: {
                    status: false,
                    progress: null,
                }
            }));
            dispatch(registerMessage({name: 'check-status-request-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('check-status-request-error-message'))
            dispatch(closeMessage({name: 'check-status-request-error-message', delay: systemMessageDelay}))
        });
    };
};

export const showSubstituteResult = ({id}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/GetSubstituteResult/${id}`,
        }).then((response) => {

            if(!isEmpty(response?.data?.errors)){
                dispatch(registerMessage({
                    name: 'substitute-result-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: join(response?.data?.errors, ', '),
                    closable: true,
                }))
                dispatch(showMessage('substitute-result-message'))
                dispatch(closeMessage({name: 'substitute-result-message', delay: systemMessageDelay}))
            }

        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({
                name: 'substitute-result-error-message',
                type: 'red',
                title: 'Ошибка',
                text: 'Не удалось получить результаты переноса каналов протокола',
                closable: true,
            }))
            dispatch(showMessage('substitute-result-error-message'))
            dispatch(closeMessage({name: 'substitute-result-error-message', delay: systemMessageDelay}))
        });
    };
};

export default protocolSlice.reducer;
