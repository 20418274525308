import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {get, isEmpty, cloneDeep, isArray, join, find, stubFalse, findIndex, clone, reverse} from "lodash";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {definePayloadType, clearFileTypeName, findInExpiredBySystem, findMessageCategory, getFileSizeInKb, findInTypeBySystem, adaptPatientsForChatList} from "utils";
import {v4 as uuidv4} from "uuid";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import utc from 'dayjs/plugin/utc';
import {mskUtcOffset, systemMessageDelay} from "consts";
import SecureLS from "secure-ls";

const ls = new SecureLS();

dayjs.extend(utc);

const createUuid = () => uuidv4();

const initialState = {
    doctor: {
        role: {},
        type: null,
        practitioner: {},
        organization: {},
        answerTimingRule: {},
        needChangePhoto: false,
    },
    patients: {
        data: {},
        tableSettings: {
            filtering: {
                searchString: '',
                fieldsFilter: {
                    // extended: true,
                    approle: 'AppRole/doctor',
                    status: "planned, in-progress",
                }
            },
            sorting: [
                {}
            ],
            paging: {
                startIndex: 0,
                maxItems: 0
            }
        }
    },
    activePatient: null,
    activePatientData: {
        periodFilteringLabel: 'За месяц',
        fieldsFilter: {
            startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            statType: 0,
            partof: null,
            profile: ''
        },
        activePeriodItem: 0,
        notes: [],
        authorNotes: [],
        planDefinitions: [],
        carePlan: null,
        lastNotifyPatientTime: false,
        consent: null,
        condition: null
    },
    activeChannelsCount: 0,
    messages: null,         // Изначальный массив сообщений
    adaptedMessages: null,  // Адаптированный массив для рендеринга, с необходимыми данными
    chatHistoryIsLoaded: false, // Флаг загрузки истории сообщений для чата пациента
    startIndex: 0,
    currentSearchMatch: 0,  // Текущее совпадение поиска
    matchesIds: [],         // id всех сообщений совпавших с поисковым значением (для скроллинга)
    matchSlideId: '',       // id текущего сообщения к которому нужно скроллировать
    searchValue: '',        // Значение поиска
    files: {                // Объект для списка фоток и файлов (по дате)
        images: [],
        files: [],
        links: []
    },
    carousel: {             // Объект для галлереи фоток
        carouselImages: [],
        activeMessageId: '',
        activeImageIndex: 0,
        activeSender: null,
        carouselLength: 0,
        imageSlideId: ''
    },
    searchDates: [],        // Массив дат для поиска по дате (для скроллинга)
    attachmentsWarning: true, // Необходимость показа предупреждения о типах и размерах загружаемых файлов
    replyMessage: null,
    loading: {
        closeChannel: false,
        patientsList: false,
        patientInfoModal: false,
        notes: true,
        SIZLData: false
    },
    blockLogoutButton: false
}

export const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
        setCarouselImages:  (state, action) => {
            const messagesCopy = cloneDeep(state.messages);
            const filteredImages = [];
            let imageIndex = 0;
            messagesCopy.forEach(item => {
                // Формируем объект с файлами
                const arr = [];
                let i = 0;
                item.payload.forEach(itm => {
                    if (get(itm, "content.contentTypeElement")) {
                        if (
                            get(itm, "content.contentTypeElement.value") === "image/jpeg"
                        ) {
                            // console.log("item", item);
                            arr.push({
                                contentType: itm.content.contentTypeElement.value,
                                url: itm.content.urlElement.value,
                                size: itm.content.sizeElement.value,
                                title: itm.content.titleElement.value,
                                index: imageIndex,
                                indexInArray: i,
                                parentId: item.idElement.value,
                            });
                            imageIndex++;
                            i++;
                        }
                    }
                });
                if (!isEmpty(arr)) filteredImages.push({
                    images: arr,
                    id: item.idElement.value,
                    date: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                    time: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').format('H:mm'),
                    senderRoleId: item.sender.referenceElement.value.slice(item.sender.referenceElement.value.indexOf('/') + 1),
                });
            });
            state.carousel.carouselImages = filteredImages;
            state.carousel.carouselLength = imageIndex;
        },
        setPreviousImage: (state, action) => {
            const currentId = state.carousel.activeMessageId;
            const currentIndexInArray = state.carousel.activeImageIndex;
            const currentMessage = state.carousel.carouselImages.find(item => item.id === currentId);
            const currentIndex = currentMessage.images[currentIndexInArray].index;
            let prevIndex = (currentIndex - 1) < 0 ? (state.carousel.carouselLength - 1) : currentIndex - 1;

            let prevIndexInArray = null;
            let prevMessageId = null;

            state.carousel.carouselImages.forEach(item => {
                if (!prevMessageId) {
                    const found = item.images.find(itm => {
                        return itm.index === prevIndex;
                    });

                    if (found) {
                        prevIndexInArray = found.indexInArray;
                        prevMessageId = item.id;
                    }
                }
            });

            state.carousel.activeMessageId = prevMessageId;
            state.carousel.activeImageIndex = prevIndexInArray;
        },
        setNextImage: (state, action) => {
            const currentId = state.carousel.activeMessageId;
            const currentIndexInArray = state.carousel.activeImageIndex;
            const currentMessage = state.carousel.carouselImages.find(item => item.id === currentId);
            const currentIndex = currentMessage.images[currentIndexInArray].index;
            let nextIndex = (currentIndex + 1) > (state.carousel.carouselLength - 1) ? 0 : currentIndex + 1;

            let nextIndexInArray = null;
            let nextMessageId = null;

            state.carousel.carouselImages.forEach(item => {
                if (!nextMessageId) {
                    const found = item.images.find(itm => {
                        return itm.index === nextIndex;
                    });

                    if (found) {
                        nextIndexInArray = found.indexInArray;
                        nextMessageId = item.id;
                    }
                }
            });

            state.carousel.activeMessageId = nextMessageId;
            state.carousel.activeImageIndex = nextIndexInArray;
        },
        setImageSlideId: (state, action) => {
            state.carousel.imageSlideId = action.payload;
        },
        setCarouselActiveMessageId: (state, action) => {
            state.carousel.activeMessageId = action.payload;
        },
        setCarouselActiveImageIndex: (state, action) => {
            state.carousel.activeImageIndex = action.payload;
        },
        setCarouselActiveSender: (state, action) => {
            state.carousel.activeSender = action.payload;
        },
        setRole: (state, action) => {
            state.doctor.role = action.payload;
        },
        setType: (state, action) => {
            state.doctor.type = action.payload;
        },
        setNeedChangePhoto: (state, action) => {
            state.doctor.needChangePhoto = action.payload;
        },
        setPractitioner: (state, action) => {
            state.doctor.practitioner = action.payload;
        },
        setOrganization: (state, action) => {
            state.doctor.organization = action.payload;
        },
        setPatients: (state, action) => {
            state.patients.data = action.payload;
        },
        setPatientsSearchString: (state, action) => {
            state.patients.tableSettings.filtering.searchString = action.payload;
        },
        setActivePatient: (state, action) => {
            state.activePatient = action.payload;
            state.messages = null;
            state.adaptedMessages = null;
            state.chatHistoryIsLoaded = false;
        },
        clearMessages: (state, action) => {
            state.messages = null;
            state.adaptedMessages = null;
        },
        addMessagesNew: (state, action) => {
            // console.log("message", action.payload.messages);

            const stateMessagesCopy = state.messages ? cloneDeep(state.messages) : [];
            const senderIsPatient = action.payload.messages?.length === 1 ? action.payload.messages[0]?.sender?.referenceElement?.value.indexOf('Patient/') > -1 ? true : false : false;

            // Если отпраляет пациент, помечаем все сообщения как прочитанные
            if (senderIsPatient) {
                stateMessagesCopy.forEach(item => {
                    if (get(item, "statusElement.value") === 1) item.statusElement.value = 3;
                });
            }

            let existingMessageIndex;
            let messagesCopy = [];

            // Проверяем есть ли полученное сообщение в массиве старых
            if (get(action.payload.messages, "length") === 1) existingMessageIndex = stateMessagesCopy.findIndex(item => item.idElement.value === action.payload.messages[0].idElement.value);

            if (existingMessageIndex > -1) {
                stateMessagesCopy[existingMessageIndex] = action.payload.messages[0];
                messagesCopy = stateMessagesCopy;
            } else {
                messagesCopy = stateMessagesCopy.concat(action.payload.messages);
            }

            state.messages = messagesCopy;
            state.loading.patientsList = false;

            const adaptedMessages = [];
            let date = '';
            const searchDates = [];

            messagesCopy.forEach(item => {
                const itemDate = dayjs(get(item, "sentElement.value", '')).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.";
                let labelIsPushed = false;
                if (date !== itemDate) {
                    date = itemDate;
                    let id = createUuid();
                    labelIsPushed = true;
                    adaptedMessages.push({
                        date: itemDate,
                        id: id,
                        type: 'dateLabel'
                    });
                    searchDates.push({
                        date: dayjs(get(item, "sentElement.value", '')).utc().add(mskUtcOffset, 'hour').valueOf(),
                        id: id,
                        formatted: dayjs(get(item, "sentElement.value", '')).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                    });
                }

                const type = definePayloadType(get(item, "payload"));

                // Проверяем payload на наличие Task
                let taskReference = '';
                item.payload.forEach((item, index) => {
                    if (get(item, "content.referenceElement.value", '').includes("Task/")) taskReference = get(item, "content.referenceElement.value");
                });

                let taskToShow;

                if ((get(item, "sender.referenceElement.value").indexOf("PractitionerRole") !== -1) && taskReference) {
                    const taskId = taskReference.replace("Task/", "");
                    taskToShow = action.payload.tasks.find(item => item?.id === taskId);
                }

                // Создаем массив фоток, если тип сообщения "image"
                let images = [];
                if (type === 'image') {
                    item.payload.forEach((item, index) => {
                        if (get(item, "content.urlElement.value"))
                            images.push({
                                id: index,
                                src: get(item, "content.urlElement.value"),
                            });
                    });
                }

                // Создаем массив файлов, если тип сообщения "file"
                let files = [];
                if (type === 'file') {
                    item.payload.forEach((item, index) => {
                        // console.log("item", item);
                        if (get(item, "content.urlElement.value"))
                            files.push({
                                id: index,
                                size: getFileSizeInKb(get(item, "content.sizeElement.value", 0)),
                                type: get(item, "content.titleElement.value", '').split('.').pop(),
                                title: get(item, "content.titleElement.value", ''),
                                url: get(item, "content.urlElement.value", ''),
                            });
                    });
                }

                const message = get(get(item, "payload", []).find(item => get(item, 'content.value')), "content.value", "");

                // Берем url первой ссылки в тексте, если тип "link"
                let link = message.match(/(?:http|https\?)[^\s]+/gi);

                // Копируем reply message если это ответ
                let replyMessage;
                let replyType;
                let replyImage;
                let replyFile;
                let replyFilesAmount = 0;
                const replyMessageId = get(item, "inResponseTo[0].referenceElement.value", '').replace("Communication/", '');
                if (replyMessageId) {
                    replyMessage = messagesCopy.find(item => get(item, "idElement.value") === replyMessageId);

                    get(replyMessage, "payload", []).forEach(item => {
                        if (item?.content?.contentTypeElement) {
                            replyFilesAmount++;
                        }
                    });

                    replyType = definePayloadType(get(replyMessage, "payload"));
                    if (replyType === 'image') {
                        const imageItem = replyMessage.payload.find((item, index) => get(item, "content.urlElement.value"));
                        replyImage = get(imageItem, "content.urlElement.value");
                    }
                    if (replyType === 'file') {
                        const fileItem = replyMessage.payload.find((item, index) => get(item, "content.urlElement.value"));
                        replyFile = get(fileItem, "content.titleElement.value", '').split('.').pop();
                    }
                }

                const messageThroughNumber = get(find(item?.identifier, el => el?.systemElement?.value === 'urn:mgfoms:szl:entity:communication:id'), 'valueElement.value');
                const correction = (!isEmpty(adaptedMessages) && !!messageThroughNumber) && findIndex(reverse(clone(adaptedMessages)), el => el?.messageThroughNumber < messageThroughNumber);

                const adaptedMessage = {
                    direction: get(item, "sender.referenceElement.value").indexOf("PractitionerRole") === -1 ? "left" : "right",
                    active: false,
                    messageThroughNumber,
                    messages: [
                        {
                            id: get(item, "idElement.value", ''),
                            message: message,
                            sender: get(item, "sender.referenceElement.value").indexOf("PractitionerRole") === -1 ? "patient" : "doctor",
                            senderId: get(item, "sender.referenceElement.value", "").replace("PractitionerRole/", "").replace("Patient/", ""),
                            time: dayjs(get(item, "sentElement.value", '')).utc().add(mskUtcOffset, 'hour').format('H:mm'),
                            date: dayjs(get(item, "sentElement.value", '')).utc().add(mskUtcOffset, 'hour'),
                            type: type,
                            images: images,
                            status: get(item, "statusElement.value") === 1 ? "sent" : get(item, "statusElement.value") === 3 ? "received" : get(item, "statusElement.value") === 'pending' ? 'pending' : "error",
                            files: files,
                            link: link,
                            expired: findInExpiredBySystem(get(item, "extension"), "http://miramedix.ru/fhir/StructureDefinition/message-overdue-flag"),
                            category: findMessageCategory(get(item, "category"), "http://miramedix.ru/fhir/CodeSystem/onlinedoc-message-category"),
                        }
                    ],
                    replyMessageId: get(item, "inResponseTo[0].referenceElement.value"),
                    replyAuthor: get(replyMessage, "sender.referenceElement.value", "").indexOf("PractitionerRole") === -1 ? "patient" : "doctor",
                    replyAuthorId: get(replyMessage, "sender.referenceElement.value", "").replace("PractitionerRole/", "").replace("Patient/", ""),
                    replyType,
                    replyMessage: get(replyMessage, "payload[0].content.value"),
                    replyImage,
                    replyFile,
                    replyFilesAmount,
                    taskReference,
                    taskToShow
                }

                if(!correction || correction === -1){
                    adaptedMessages.push(adaptedMessage);
                }else{
                    if(correction === 1 && labelIsPushed){
                        adaptedMessages.push(adaptedMessage);
                    }else{
                        adaptedMessages.splice(adaptedMessages.length - correction, 0, adaptedMessage);
                        // console.log('Correction: ', adaptedMessages.length - correction, correction, adaptedMessage);
                    }
                }
            });
            // console.log('Adapted messages: ', adaptedMessages);
            state.adaptedMessages = adaptedMessages;
            state.searchDates = searchDates;
        },
        setMessagesReceived: (state, action) => {
            const adaptedMessagesCopy = state.adaptedMessages ? cloneDeep(state.adaptedMessages) : [];
            const messagesCopy = state.messages ? cloneDeep(state.messages) : [];

            messagesCopy.forEach(item => {
                if (get(item, "statusElement.value") === 1) item.statusElement.value = 3;
            });

            adaptedMessagesCopy.forEach(item => {
                if (item.messages) {
                    item.messages.forEach(itm => {
                        if (itm.status === "sent") itm.status = "received";
                    });
                }
            });

            state.adaptedMessages = adaptedMessagesCopy;
            state.messages = messagesCopy;
        },
        deleteMessage: (state, action) => {
            const messagesCopy = cloneDeep(state.messages);
            const filteredMessages = messagesCopy.filter(item => get(item, "idElement.value") !== action.payload);

            const adaptedMessagesCopy = cloneDeep(state.adaptedMessages);
            const filteredAdaptedMessages = adaptedMessagesCopy.filter(item => get(item, "messages[0].id") !== action.payload);

            state.messages = filteredMessages;
            state.adaptedMessages = filteredAdaptedMessages;
        },
        fillFilesLibrary: (state, action) => {
            const messagesCopy = cloneDeep(state.messages);

            const filteredImages = [];
            const filteredFiles = [];
            const filteredLinks = [];

            messagesCopy.forEach(item => {

                // Формируем объект с файлами
                item.payload.forEach(itm => {
                    if ((item.payload.length === 1) && get(itm, "content.value", '').match(/(?:http|https\?)[^\s]+/gi)) {
                        filteredLinks.push({
                            ...itm,
                            sentElement: item.sentElement,
                            idElement: item.idElement
                        });
                    } else if (get(itm, "content.contentTypeElement")) {
                        if (
                            get(itm, "content.contentTypeElement.value") === "image/jpeg"
                        ) {
                            filteredImages.push({
                                ...itm,
                                sentElement: item.sentElement,
                                idElement: item.idElement
                            });
                        } else {
                            filteredFiles.push({
                                ...itm,
                                sentElement: item.sentElement,
                                idElement: item.idElement
                            });
                        }
                    }
                });
            });

            const adaptedImages = [];
            let currentDate = '';
            let imageIndex = 0;
            let currentMessageId = '';
            filteredImages.forEach(item => {
                if (currentMessageId === item.idElement.value) {
                    imageIndex++;
                } else {
                    currentMessageId = item.idElement.value;
                    imageIndex = 0;
                }
                if ((dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.") !== currentDate) {
                    currentDate = dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.";
                    adaptedImages.push({
                        date: currentDate,
                        links: [
                            {
                                id: item.idElement.value,
                                user: 'Отправитель',
                                date: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                                thumb: item.content.urlElement.value,
                                info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').format('H:mm'),
                                imageIndex: imageIndex,
                                name: item.content.titleElement.value,
                            },
                        ]
                    });
                } else {
                    adaptedImages[adaptedImages.length - 1].links.push({
                        id: item.idElement.value,
                        user: 'Отправитель',
                        date: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                        thumb: item.content.urlElement.value,
                        info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').format('H:mm'),
                        imageIndex: imageIndex,
                        name: item.content.titleElement.value,
                    });
                }
            });

            // {
            //     id: 3,
            //     name: 'Название файла3',
            //     type: 'xls',
            //     info: '1 апреля в 23:00・15.7 МБ'
            // }

            const adaptedFiles = [];
            let currentFileMonth = '';
            // console.log("filteredFiles", filteredFiles);
            filteredFiles.forEach(item => {
                if (dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('MMMM') !== currentFileMonth) {
                    currentFileMonth = dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('MMMM');
                    adaptedFiles.push({
                        date: (currentFileMonth.charAt(0).toUpperCase() + currentFileMonth.slice(1)) + ' ' + dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('YYYY') + ' г.',
                        files: [
                            {
                                id: item.idElement.value,
                                name: item.content.titleElement.value,
                                type: clearFileTypeName(item.content.contentTypeElement.value),
                                info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM') + " в " + dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').format('H:mm') + '・' + getFileSizeInKb(get(item, "content.sizeElement.value", 0)),
                                url: item.content.urlElement.value,
                            },
                        ]
                    });
                } else {
                    adaptedFiles[adaptedFiles.length - 1].files.push({
                        id: item.idElement.value,
                        name: item.content.titleElement.value,
                        type: clearFileTypeName(item.content.contentTypeElement.value),
                        info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM') + " в " + dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').format('H:mm') + '・' + getFileSizeInKb(get(item, "content.sizeElement.value", 0)),
                        url: item.content.urlElement.value,
                    });
                }
            });

            const adaptedLinks = [];
            let currentLinkMonth = '';
            filteredLinks.forEach(item => {
                const urlify = (text) => {
                    var urlRegex = /(https?:\/\/[^\s]+)/g;
                    return text.replace(urlRegex, function(url) {
                        return '<br/>' + url + '<br/>';
                    })
                }

                const replaced = urlify(get(item, "content.value", ''));
                const replacedArr = replaced.split("<br/>");
                const linksArr = replacedArr.filter(item => item.indexOf('http') > -1);
                // const regex = /\/\/(.*?)[\/:?&]/;
                const regex = /\/\/(.*?)[/:?&]/;

                if (dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('MMMM') !== currentLinkMonth) {
                    currentLinkMonth = dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('MMMM');
                    linksArr.forEach(linksArrItem => {
                        adaptedLinks.push({
                            date: (currentLinkMonth.charAt(0).toUpperCase() + currentLinkMonth.slice(1)) + ' ' + dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('YYYY') + ' г.',
                            links: [
                                {
                                    id: item.idElement.value,
                                    name: replacedArr[0] || get(regex.exec(linksArrItem), "[1]", ""),
                                    link: linksArrItem,
                                    info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                                },
                            ]
                        });
                    });
                } else {
                    linksArr.forEach(linksArrItem => {
                        adaptedLinks[adaptedLinks.length - 1].links.push({
                            id: item.idElement.value,
                            name: replacedArr[0] || get(regex.exec(linksArrItem), "[1]", ""),
                            link: linksArrItem,
                            info: dayjs(item.sentElement.value).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMMM YYYY') + " г.",
                        });
                    });
                }
            });

            state.files.images = adaptedImages;
            state.files.files = adaptedFiles;
            state.files.links = adaptedLinks;
        },
        setSlideNumber: (state, action) => {
            state.slideNumber = 0;
        },
        setActivePatientCodeName: (state, action) => {
            state.activePatient.codeName = action.payload;
        },
        setAdaptedMessages: (state, action) => {
            state.adaptedMessages = action.payload.adaptedMessages;
            state.matchesIds = action.payload.matchesIds;
            state.matchSlideId = action.payload.matchSlideId;
            state.searchValue = action.payload.searchValue;
        },
        resetSearch: (state, action) => {
            state.currentSearchMatch = 0;
            state.matchesIds = [];
            state.matchSlideId = '';
            state.searchValue = '';
        },
        setMatchSlideId: (state, action) => {
            state.matchSlideId = action.payload;
        },
        setCurrentSearchMatch: (state, action) => {
            state.currentSearchMatch = action.payload;
            state.matchSlideId = state.matchesIds[state.matchesIds.length - (action.payload)];
        },
        setActivePatientUKL: (state, action) => {
            state.activePatientData.UKL = action.payload;
        },
        setActivePatientInfo: (state, action) => {
            state.activePatientData.policyNumPart = action.payload.policyNumPart;
            state.activePatientData.gender = action.payload.gender;
            state.activePatientData.birthDate = action.payload.birthDate;
            state.activePatientData.name = action.payload.name;
            state.activePatientData.fullName = action.payload.fullName;
        },
        setActivePatientOrganization: (state, action) => {
            state.activePatientData.organization = action.payload;
        },
        setActivePatientEncounterOrganization: (state, action) => {
            state.activePatientData.encounterOrganization = action.payload;
        },
        setIndicators: (state, action) => {
            state.activePatientData.indicators = action.payload;
        },
        setSurveys: (state, action) => {
            state.activePatientData.surveys = action.payload;
        },
        setResComplication: (state, action) => {
            state.activePatientData.resComplication = action.payload;
        },
        setSurveysActiveItem: (state, action) => {
            state.activePatientData.surveys.activeItem = action.payload;
        },
        setResComplicationItem: (state, action) => {
            state.activePatientData.resComplication.activeItem = action.payload;
        },
        setChannelChronicDiseases: (state, action) => {
            state.activePatientData.channelChronicDiseases = action.payload;
        },
        setChannelVitalInformation: (state, action) => {
            state.activePatientData.channelVitalInformation = action.payload;
        },
        setResVitalInformation: (state, action) => {
            state.activePatientData.channelVitalInformation = action.payload;
        },
        setChannelDrugs: (state, action) => {
            state.activePatientData.channelDrugs = action.payload;
        },
        setDrugActiveItem: (state, action) => {
            state.activePatientData.channelDrugs.activeItem = action.payload;
        },
        setServices: (state, action) => {
            state.activePatientData.services = action.payload;
        },
        setServicesActiveItem: (state, action) => {
            state.activePatientData.services.activeItem = action.payload;
        },
        setStartDateToFilter: (state, action) => {
            state.activePatientData.fieldsFilter.startDate = action.payload;
        },
        setEndDateToFilter: (state, action) => {
            state.activePatientData.fieldsFilter.endDate = action.payload;
        },
        setActivePeriodItem: (state, action) => {
            state.activePatientData.activePeriodItem = action.payload;
        },
        setPeriodFilteringLabel: (state, action) => {
            state.activePatientData.periodFilteringLabel = action.payload;
        },
        clearNotes: (state, action) => {
            state.activePatientData.notes = [];
        },
        setNotes: (state, action) => {
            state.activePatientData.notes = action.payload;
        },
        setAuthorNotes: (state, action) => {
            state.activePatientData.authorNotes.push(action.payload);
        },
        setPlanDefinitions: (state, action) => {
            state.activePatientData.planDefinitions = action.payload;
        },
        setCarePlan: (state, action) => {
            state.activePatientData.carePlan = action.payload;
        },
        setCondition: (state, action) => {
            state.activePatientData.condition = action.payload;
        },
        clearNotAnswered: (state, action) => {
            const copy = cloneDeep(state.patients.data);
            const patient = copy.items.find(item => item.patientId === action.payload);
            patient.notAnsweredAndNotOverdue = 0;
            patient.notAnsweredAndOverdue = 0;
            state.patients.data = copy;
        },
        addNotAnswered: (state, action) => {
            const copy = cloneDeep(state.patients.data);
            const patient = copy.items.find(item => item.patientId === action.payload.id);
            if (patient.notAnsweredAndNotOverdue) {
                patient.notAnsweredAndNotOverdue++;
            } else {
                patient.notAnsweredAndNotOverdue = 1;
            }

            // patient.status = "in-progress";
            patient.lastCommunicationFromPatientTime = action.payload.time;

            // const activePatientCopy = cloneDeep(state.activePatient);
            // if ((activePatientCopy.id === patient.id) && (activePatientCopy.status !== "in-progress")) {
            //     activePatientCopy.status = "in-progress";
            //     state.activePatient = activePatientCopy;
            // }
            state.patients.data = copy;
        },
        setActiveChannelsCount: (state, action) => {
            state.activeChannelsCount = action.payload;
        },
        setAnswerTimingRule: (state, action) => {
            state.doctor.answerTimingRule = action.payload;
        },
        setLastNotifyPatientTime: (state, action) => {
            state.activePatientData.lastNotifyPatientTime = action.payload;
        },
        setAttachmentsWarning: (state, action) => {
            state.attachmentsWarning = action.payload;
        },
        setReplyMessage: (state, action) => {
            state.replyMessage = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setConsent: (state, action) => {
            state.activePatientData.consent = action.payload;
        },
        resetState: (state, action) => {
            return initialState
        },
        updateActviPatientStatistics: (state, action) => {
            state.activePatient.totalFromPatient = action.payload.totalFromPatient;
            state.activePatient.totalFromPractitioner = action.payload.totalFromPractitioner;
            state.activePatient.notAnsweredAndOverdue = action.payload.notAnsweredAndOverdue;
            state.activePatient.notAnsweredOrAnsweredOverdue = action.payload.notAnsweredOrAnsweredOverdue;
            state.activePatient.consultationPeriod = action.payload.consultationPeriod;
            state.activePatient.weekOfPregnancy = action.payload.week;
            state.activePatient.weekOfPregnancyConstant = action.payload.weekNow;
            state.activePatient.onSetDateTime = action.payload.onSetDateTime;
            state.activePatient.dateOfChildbirth = action.payload.dateOfChildbirth;
            state.activePatient.numberOfChildren = action.payload.numberOfChildren;
        },
        changePatientPregnancyStatus: (state, action) => {
            const copy = cloneDeep(state.patients.data);
            const activePatientCopy = cloneDeep(state.activePatient);
            const patient = copy.items.find(item => item.patientId === action.payload.id);
            if (action.payload.dateOfChildbirth) {
                patient.dateOfChildbirth = true;
                activePatientCopy.dateOfChildbirth = true;
            } else {
                delete patient.dateOfChildbirth;
                delete activePatientCopy.dateOfChildbirth;
            }
            state.patients.data = copy;
            state.activePatient = activePatientCopy;
        },
        setBlockLogoutButton: (state, action) => {
            state.blockLogoutButton = action.payload;
        },
        setChatHistoryIsLoaded: (state, action) => {
            state.chatHistoryIsLoaded = action.payload;
        },
        updateLocalChannelStatus: (state, action) => {
            const copy = cloneDeep(state.patients.data);
            const encounter = copy.items.find(item => item.id === action.payload.encounterId);

            const getEncounterLocalStatus = (status) => {
                switch (status) {
                    case 'InPregress': return 'in-progress';
                    case 'Planned': return 'planned';
                    case 'Onleave': return 'onleave';
                    case 'Finished': return 'finished';
                    case 'Cancelled': return 'cancelled';
                }
            }

            encounter.status = getEncounterLocalStatus(action.payload.status);

            const activePatientCopy = cloneDeep(state.activePatient);
            if ((activePatientCopy.id === action.payload.encounterId)) {
                activePatientCopy.status = getEncounterLocalStatus(action.payload.status);
                state.activePatient = activePatientCopy;
            }
            state.patients.data = copy;
        },
    },
});

export const selectRole = state => state.doctor.doctorChat.doctor.role;
export const selectType = state => state.doctor.doctorChat.doctor.type;
export const selectPractitioner = state => state.doctor.doctorChat.doctor.practitioner;
export const selectOrganization = state => state.doctor.doctorChat.doctor.organization;
export const selectPatients = state => state.doctor.doctorChat.patients.data;
export const selectPatientsTableSettings = state => state.doctor.doctorChat.patients.tableSettings;
export const selectActivePatient = state => state.doctor.doctorChat.activePatient;
export const selectActivePatientData = state => state.doctor.doctorChat.activePatientData;
export const selectMessages = state => state.doctor.doctorChat.messages;
export const selectStartIndex = state => state.doctor.doctorChat.startIndex;
export const selectSlideNumber = state => state.doctor.doctorChat.slideNumber;
export const selectAdaptedMessages = state => state.doctor.doctorChat.adaptedMessages;
export const selectCurrentSearchMatch = state => state.doctor.doctorChat.currentSearchMatch;
export const selectFiles = state => state.doctor.doctorChat.files;
export const selectMatchSlideId = state => state.doctor.doctorChat.matchSlideId;
export const selectMatchesIds = state => state.doctor.doctorChat.matchesIds;
export const selectSearchValue = state => state.doctor.doctorChat.searchValue;
export const selectSearchDates = state => state.doctor.doctorChat.searchDates;
export const selectFieldsFilter = state => state.doctor.doctorChat.activePatientData.fieldsFilter;
export const selectActivePeriodItem = state => state.doctor.doctorChat.activePatientData.activePeriodItem;
export const selectPeriodFilteringLabel = state => state.doctor.doctorChat.activePatientData.periodFilteringLabel;
export const selectNotes = state => state.doctor.doctorChat.activePatientData.notes;
export const selectAuthorNotes = state => state.doctor.doctorChat.activePatientData.authorNotes;
export const selectActiveChannelsCount = state => state.doctor.doctorChat.activeChannelsCount;
export const selectCarousel = state => state.doctor.doctorChat.carousel;
export const selectPlanDefinitions = state => state.doctor.doctorChat.activePatientData.planDefinitions;
export const selectCarePlan = state => state.doctor.doctorChat.activePatientData.carePlan;
export const selectAnswerTimingRule = state => state.doctor.doctorChat.doctor.answerTimingRule;
export const selectNotifyPatient = state => state.doctor.doctorChat.activePatientData.notifyPatient;
export const selectAttachmentsWarning = state => state.doctor.doctorChat.attachmentsWarning;
export const selectReplyMessage = state => state.doctor.doctorChat.replyMessage;
export const selectLoading = state => state.doctor.doctorChat.loading;
export const selectConsent = state => state.doctor.doctorChat.activePatientData.consent;
export const selectBlockLogoutButton = state => state.doctor.doctorChat.blockLogoutButton;
export const selectChatHistoryIsLoaded = state => state.doctor.doctorChat.chatHistoryIsLoaded;
export const selectActivePatientMessageStatus = id => state => {
    const patient = find(state.doctor.doctorChat.patients.data.items, { id });
    return patient ? {
        notAnsweredAndNotOverdue: patient?.notAnsweredAndNotOverdue,
        notAnsweredAndOverdue: patient?.notAnsweredAndOverdue,
        notAnsweredOrAnsweredOverdue: patient?.notAnsweredOrAnsweredOverdue,
    } : undefined
}
export const doctorActions = doctorSlice.actions;

export const getDoctor = (doctorRoleId) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}?fg=id::${doctorRoleId}:x:_include::practitioner,organization`
        }).then((response) => {
            const role = get(response.data, "items[0]");
            const practitioner = get(response.data, `resources[${get(role, "practitioner.reference")}]`, {});
            const organization = get(response.data, `resources[${get(role, "organization.reference")}]`, {});
            const type = findInTypeBySystem(get(response.data, `resources[${get(role, "organization.reference")}].type`), 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code === '2' ? 'gynecology' : 'oncology';
            dispatch(doctorActions.setRole(role));
            dispatch(doctorActions.setPractitioner(practitioner));
            dispatch(doctorActions.setOrganization(organization));
            dispatch(doctorActions.setType(type));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPatients = ({roleId, tableSettings, closeChannel}) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'patientsList',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.practitionerStatistic}/${roleId}/EncountersStatistics`,
        data: tableSettings
        }).then((response) => {
            dispatch(doctorActions.setPatients(response.data));
            if (closeChannel) dispatch(doctorActions.setActivePatient(null));
            dispatch(doctorActions.setLoading({
                type: 'patientsList',
                value: false
            }));
        }).catch(err => {
            console.log(err)
            dispatch(doctorActions.setLoading({
                type: 'patientsList',
                value: false
            }));
        });
    };
};

export const getActivePatientStatistics = ({roleId, tableSettings, activePatientId, callback}) => {
    return (dispatch) => {
      fetchInstance({
            method: "POST",
            url: `${api.practitionerStatistic}/${roleId}/EncountersStatistics`,
            data: tableSettings
        }).then((response) => {
            const items = response.data.items;
            const item = items.find(item => item.id === activePatientId);
            const adaptedPatients = adaptPatientsForChatList(items);
            if(item){
                dispatch(getActivePatientEncounterOrganization({encounterId: item?.id}));
                dispatch(doctorActions.updateActviPatientStatistics({
                    totalFromPatient: item?.totalFromPatient,
                    totalFromPractitioner: item?.totalFromPractitioner,
                    notAnsweredAndOverdue: item?.notAnsweredAndOverdue,
                    notAnsweredOrAnsweredOverdue: item?.notAnsweredOrAnsweredOverdue,
                    consultationPeriod: item?.consultationPeriod,
                    week: item?.week,
                    weekNow: item?.weekNow,
                    onSetDateTime: item?.conditionOnSetDateTime,
                    dateOfChildbirth: item?.dateOfChildbirth,
                    numberOfChildren: item?.numberOfChildren
                }));
                callback && callback();
            }else{

                const firstPatient = get(adaptedPatients, '[0]');

                dispatch(doctorActions.setActivePatient(firstPatient));
                
                if (firstPatient) dispatch(getPlanDefinitionByCarePlan({
                    carePlanId: firstPatient?.carePlanId
                }))

                ls.set('indexOfActivePatient', firstPatient?.id);

                callback && callback({hideModalFlag: true});
            }
        }).catch(err => {
            console.log(err)
        });
    };
};

export const editDoctor = ({image, practitioner, practitionerRole}) => {
    console.log("image", image);
    if (image) {
        let imageData = new FormData();
        imageData.append("files", image);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                data: imageData
            }).then((response) => {
                const practitionerCopy = cloneDeep(practitioner);
                delete practitionerCopy.photo;
                practitionerCopy.photo = [
                    {
                        url: response.data[0].url
                    }
                ]
                dispatch(editPractitioner({practitioner: practitionerCopy, practitionerRole}));
            }).catch(err => {
                console.log(err)
            });
        };
    } else if (image === null) {
        // console.log("Нужно сбросить");
        return (dispatch) => {
            const practitionerCopy = cloneDeep(practitioner);
            practitionerCopy.photo = [];
            dispatch(editPractitioner({practitioner: practitionerCopy, practitionerRole}));
        };
    } else {
        // console.log("Обновлять не нужно");
        return (dispatch) => {
            dispatch(editPractitioner({practitioner, practitionerRole}));
        };
    }
};

export const editPractitioner = ({practitioner, practitionerRole}) => {
    return (dispatch) => {
      fetchInstance({
        method: "PUT",
        url: api.practitioner,
        data: practitioner
        }).then((response) => {
            dispatch(editManager({practitioner, practitionerRole}));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const editManager = ({practitioner, practitionerRole}) => {
    return (dispatch) => {
            fetchInstance({
            method: "PUT",
            url: api.practitionerRole,
            data: practitionerRole
        }).then((response) => {
            dispatch(getDoctor(practitionerRole.id));
        }).catch(err => {
            console.log(err)
        })
    };
};

export const getPatientDetails = (id) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.patient}/_search`,
            data: {
                paging:{
                startIndex:0,
                maxItems: 0
            },
            filtering:{
                searchString: null,
                fieldsFilter:{
                    id : id
                }
            },
            sorting:[
                    {
                        direction:0,
                        propertyName:"name"
                    }
                ]
            }
        }).then((response) => {
            console.log("response.data", response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getChannelDetails = ({organizationId, patientId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${organizationId}/Encounters`,
            data: {
                paging:{
                startIndex:0,
                maxItems: 0
            },
            filtering:{
                searchString: null,
                fieldsFilter:{
                    carePlanId: patientId
                }
            },
            sorting:[
                    {
                        direction:0,
                        propertyName:"name"
                    }
                ]
            }
        }).then((response) => {
            console.log("response.data", response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getMkb10 = (code) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mkb10}/_search`,
            data: {
                paging:{
                startIndex:0,
                maxItems:10
            },
            filtering:{
                searchString: null,
                fieldsFilter:{
                    code
                }
            },
            sorting:[]
            }
        }).then((response) => {
           dispatch(doctorActions.setActivePatientCodeName(response.data.items[0].display));
        }).catch(err => {
            console.log(err)
        });
    }
};

export const getActivePatientData = (id) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.patient}/${id}`,
        }).then((response) => {
            dispatch(getPatientOrganization(response.data.managingOrganization.id));
            let activePatientUKL = '';
            let activePatientPolicy = '';
            for (let item in response.data.identifier) {
                if (response.data.identifier[item].use === 'usual') {
                    activePatientUKL = response.data.identifier[item].value;
                }
                if (response.data.identifier[item].use === 'official') {
                    activePatientPolicy = response.data.identifier[item].value;
                }
            }
            dispatch(doctorActions.setActivePatientUKL(activePatientUKL))
            dispatch(doctorActions.setActivePatientInfo({
                policyNumPart: activePatientPolicy,
                gender:  response.data.gender,
                birthDate:  response.data.birthDate,
                name:  response.data.name[0].text,
                fullName: `${response.data.name[0].family} ${response.data.name[0].given[0]} ${response.data.name[0].given[1]}`,
            }))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPatientOrganization = (id) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.organization}/${id}?fg=type:missing::false`,
        }).then((response) => {
            if (response.data.partOf) {
                dispatch(getPatientOrganization(response.data.partOf.id));
                dispatch(doctorActions.setActivePatientOrganization(response.data.name))
            }
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getActivePatientEncounterOrganization = ({encounterId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.encounter}`,
            params: {
                fg: `id::${encounterId}:x:_include::Encounter:service-provider:Organization`
            }
        }).then((response) => {
            dispatch(doctorActions.setActivePatientEncounterOrganization(get(response, `data.resources.${get(response, 'data.items[0].serviceProvider.reference')}`)))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getIndicators = (ukl, dateFrom, dateTo) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/Indicators/${ukl}?fromDate=${dateFrom}&toDate=${dateTo}`,
        }).then((response) => {
            dispatch(doctorActions.setIndicators(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'res-indicators-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('res-indicators-error-message'))
            dispatch(closeMessage({name: 'res-indicators-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getSurveys = (encounterId, dateFrom, dateTo) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/Surveys/${encounterId}`,
        }).then((response) => {
            dispatch(doctorActions.setSurveys(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'channel-surveys-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('channel-surveys-error-message'))
            dispatch(closeMessage({name: 'channel-surveys-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getSurveyFileData = (bucket, key, handleDownload) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.imageUpload}/${bucket}/${key}`,
            responseType: 'blob'
        }).then((response) => {
            handleDownload(response.data);
        }).catch(err => {
            console.log(err);
        });
    };
}

export const getResComplication = (encounterId, dateFrom, dateTo) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/PregnancyComplications/${encounterId}?fromDate=${dateFrom}&toDate=${dateTo}`,
        }).then((response) => {
            dispatch(doctorActions.setResComplication(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'res-complications-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('res-complications-error-message'))
            dispatch(closeMessage({name: 'res-complications-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getChannelChronicDiseases = (encounterId) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/ChronicDiseases/${encounterId}`,
        }).then((response) => {
            dispatch(doctorActions.setChannelChronicDiseases(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'channel-chronic-diseases-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('channel-chronic-diseases-error-message'))
            dispatch(closeMessage({name: 'channel-chronic-diseases-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getChannelVitalInformation = (encounterId) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/VitalInfo/${encounterId}`,
        }).then((response) => {
            dispatch(doctorActions.setChannelVitalInformation(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'channel-vitalinformation-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('channel-vitalinformation-error-message'))
            dispatch(closeMessage({name: 'channel-vitalinformation-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getChannelDrugs = (encounterId, fieldsFilter) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            //url: `${api.onco}/channeldrugs/${ukl}?fromDate=${fieldsFilter.startDate}&toDate=${fieldsFilter.endDate}`,
            url: `${api.sizl}/client/Drugs/${encounterId}?fromDate=${fieldsFilter.startDate}&toDate=${fieldsFilter.endDate}`,
        }).then((response) => {
            dispatch(doctorActions.setChannelDrugs(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'channel-drugs-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('channel-drugs-error-message'))
            dispatch(closeMessage({name: 'channel-drugs-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getServices = (encounterId, fieldsFilter) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'SIZLData',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.sizl}/client/Services/${encounterId}?fromDate=${fieldsFilter.startDate}&toDate=${fieldsFilter.endDate}`,
        }).then((response) => {
            dispatch(doctorActions.setServices(response.data));
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
        }).catch(err => {
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            dispatch(registerMessage({name: 'channel-services-error-message', type: 'red', title: 'Ошибка', text: err.response.data, closable: true}))
            dispatch(showMessage('channel-services-error-message'))
            dispatch(closeMessage({name: 'channel-services-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getNotes = (id, userId) => {
    return (dispatch) => {
        dispatch(doctorActions.clearNotes());
        dispatch(doctorActions.setLoading({
            type: 'notes',
            value: true
        }))
        fetchInstance({
            method: "POST",
            url: `${api.carePlans}`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 10
                },
                filtering: {
                    fieldsFilter: {
                        id: id,
                        status: "active"
                    }
                },
                sorting: [
                    {}
                ]
            }
        }).then((response) => {
            if (response.data.items[0] !== undefined && response.data.items[0].note !== undefined) {
                let authorsArray = [];
                for (let item of response.data.items[0].note) {
                    if (!authorsArray.includes(item.authorReference.id)) {
                        authorsArray.push(item.authorReference.id);
                    }
                }
                for (let item in authorsArray) {
                    dispatch(getAuthorNotes(authorsArray[item], userId));
                }
                dispatch(doctorActions.setNotes(response.data.items[0].note));
            }
            dispatch(doctorActions.setLoading({
                type: 'notes',
                value: false
            }))
        }).catch(err => {
            console.log(err)
            dispatch(doctorActions.setLoading({
                type: 'notes',
                value: false
            }))
        });
    };
};

export const getAuthorNotes = (id, userId) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}`,
            params: {
                fg: `id::${id}:x:_include::practitioner`
            }
        }).then((response) => {
            let reference = response.data.items[0].practitioner.reference;
            let newItem = {};
            let authorName = '';
            newItem.id = id;
            authorName = response.data.resources[reference].name[0].text;
            newItem.authorName = userId === response.data.items[0].id ? 'Вы' : authorName;
            dispatch(doctorActions.setAuthorNotes(newItem));
        }).catch(err => {
            console.log(err)
        });
    }
}

export const addNote = (id, note, authorId) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.carePlan}/${id}/AddNote`,
            data: {
                "authorPractitionerRoleId": authorId,
                "text": note
            }
        }).then((response) => {
            dispatch(getNotes(id, authorId));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getSenderName = (roleId) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}?fg=id::${roleId}:x:_include::practitioner`
        }).then((response) => {
            const reference = get(response.data, "items[0].practitioner.reference");
            if (get(response.data, `resources[${reference}].name[0].text`)) dispatch(doctorActions.setCarouselActiveSender(get(response.data, `resources[${reference}].name[0].text`)));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPlanDefinitionByCarePlan = ({carePlanId}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.carePlan}/${carePlanId}`
        }).then((response) => {
            const instantiatesCanonical = get(response, "data.instantiatesCanonical");
            const carePlan = get(response, 'data');

            carePlan && dispatch(doctorActions.setCarePlan(carePlan))

            if(!isEmpty(instantiatesCanonical) && isArray(instantiatesCanonical)){
                const ids = instantiatesCanonical.map(item => item?.split('/')[1])
                fetchInstance({
                    method: "POST",
                    url: `${api.planDefinition}/_search`,
                    data: {
                        paging: {
                            startIndex: 0,
                            maxItems: 10
                        },
                        filtering: {
                            fieldsFilter: {
                                id: join(ids, ',')
                            }
                        },
                        sorting: [
                            {}
                        ]
                    }
                }).then(response => {
                    // console.log(response?.data);
                    const planDefinitions = get(response, 'data.items');
                    dispatch(doctorActions.setPlanDefinitions(planDefinitions));
                })
            }
        }).catch(err => {
            console.log(err)
        });
    };
};

export const closeChannel = ({
    encounterId,
    code,
    roleId,
    tableSettings,
    callback
}) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'closeChannel',
            value: true
        }))
        fetchInstance({
            method: "GET",
            url: `${api.encounterClose}?encounterId=${encounterId}&deleteReasonCode=${code}`
            }).then((response) => {
                callback();
                dispatch(doctorActions.setLoading({
                    type: 'closeChannel',
                    value: false
                }));
                dispatch(getPatients({roleId, tableSettings, closeChannel: true}));
            }).catch(err => {
                dispatch(doctorActions.setLoading({
                    type: 'closeChannel',
                    value: false
                }))
        });
    };
};

export const getActiveChannelsCount = ({id, organizationId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitioners}/_search`,
            data: {
                paging:{
                    startIndex: 0,
                    maxItems: 10
                },
                filtering:{
                    searchString: null,
                    fieldsFilter: {
                        practitionerId: id,
                        organizationId,
                        statType: 0
                    }
                },
                sorting: [{}],
            }
        }).then((response) => {
            const doctor = find(get(response, 'data.items'), { practitioner: { id } })
            dispatch(doctorActions.setActiveChannelsCount(get(doctor, 'statistics.active')));
        }).catch(err => {
        });
    };
};

export const getDoctorAnswerTimingRule = id => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${id}/AnswerTimingRule`,
            }).then((response) => {
                dispatch(doctorActions.setAnswerTimingRule(response.data));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const sendPatientActivationNotification = ({id, date}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.encounter}/${id}/NotifyPatient`
        }).then((response) => {
            dispatch(doctorActions.setLastNotifyPatientTime(date));
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const getConsent = ({patientId, organizationId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.consent}/getactiveconsent?patientId=${patientId}&organizationId=${organizationId}`
        }).then((response) => {
            dispatch(doctorActions.setConsent(response.data));
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const deleteConsent = ({id, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.consent}/${id}`
        }).then((response) => {
            callback();
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const createConsent = ({files, person, organizationId, patientId}) => {
    let imageData = new FormData();
    imageData.append("files", files);
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'patientInfoModal',
            value: true
        }));

        if (files) {
            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                data: imageData
            }).then((response) => {
                console.log("response.data", response.data);
                const filesCopy = {
                    fileName: response.data[0]?.fileName,
                    url: response.data[0]?.url,
                };
                dispatch(createPerson({files: filesCopy, person, organizationId, patientId}));
            }).catch(err => {
                dispatch(doctorActions.setLoading({
                    type: 'patientInfoModal',
                    value: false
                }));
                dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                dispatch(showMessage('create-person-error-message'))
                dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))
            });
        } else {
            dispatch(createPerson({files: {}, person, organizationId, patientId}));
        }
    }
};

export const createPerson = ({files, person, organizationId, patientId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.patient}/createorupdate`,
            data: {
                organizationId,
                document: files,
                fio : person.surnameInitials,
                birthDate : dayjs(person.birthDate).format('YYYY-MM-DD'),
                gender : person.gender,
                police : person.policyNumPart,
                ukl : person.id,
            }
        }).then((response) => {
            dispatch(doctorActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));
            dispatch(getConsent({
                patientId,
                organizationId
            }));
        }).catch(err => {
            dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('create-person-error-message'))
            dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))

            dispatch(doctorActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));

            console.log(err);
        });
    };
};

export const getReplySenderName = ({roleId, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}?fg=id::${roleId}:x:_include::practitioner`
        }).then((response) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitioner}/${response.data?.items[0]?.practitioner?.id}`,
            }).then((response) => {
                callback(response.data?.name[0]?.text);
            }).catch(err => {
                console.log(err);
            });

            // const reference = get(response.data, "items[0].practitioner.reference");
            // if (get(response.data, `resources[${reference}].name[0].text`)) dispatch(doctorActions.setCarouselActiveSender(get(response.data, `resources[${reference}].name[0].text`)));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getConsentBlankEmpty = ({
    dsCode,
    callback
}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.agreement}/EmptyPdf?dsCode=${dsCode}`,
        responseType: 'blob'
        }).then((response) => {
            callback(response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getConsentBlankFilled = ({
    patientId,
    organizationId,
    dsCode,
    callback
}) => {
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.agreement}/PrefilledPdf`,
        data: {
            patientId,
            organizationId,
            dsCode
          },
        responseType: 'blob'
        }).then((response) => {
            callback(response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const deleteMessage = (id) => {
    return (dispatch) => {
        dispatch(doctorActions.deleteMessage(id));
      fetchInstance({
        method: "DELETE",
        url: `${api.communication}/${id}`
        }).then((response) => {
        }).catch(err => {
            console.log(err)
        });
    };
};

export const updateTask = ({task, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "PUT",
        url: `${api.task}`,
        data: task,
        }).then((response) => {
            callback()
        }).catch(err => {
            console.log(err)
        });
    };
};

export const createTask = ({task, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.task}`,
        data: task,
        }).then((response) => {
            callback()
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getHasBornTask = ({id, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.task}/${id}`
        }).then((response) => {
            callback(response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const setBirthEvent = ({birthDate, encounterId, childrenAmount, callback, roleId, tableSettings, activePatientId}) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'patientInfoModal',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.setBirthEvent}`,
        data: {
            birthDate,
            encounterId,
            childrenAmount
          }
        }).then((response) => {
            // dispatch(doctorActions.changePatientPregnancyStatus({
            //     id: response.data?.for?.id,
            //     dateOfChildbirth: response.data?.executionPeriod?.end ? true : false
            // }));
            dispatch(getStatisticUpdateStatus({id: encounterId, roleId, tableSettings, activePatientId, updatePatientList: true, callback}));
            // callback(response.data);
        }).catch(err => {
            // console.log(err.response.data);
            dispatch(registerMessage({name: 'set-birth-event-message', type: 'red', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Ошибка запроса`, closable: true}))
            dispatch(showMessage('set-birth-event-message'))
            dispatch(closeMessage({name: 'set-birth-event-message', delay: systemMessageDelay}));
            dispatch(doctorActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));
        });
    };
};

export const getStatisticUpdateStatus = ({id, iteration = 10, roleId, tableSettings, activePatientId, updatePatientList, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.statisticUpdateStatus}/${id}`,
        }).then((response) => {
            if (response.data === "Completed" || !iteration) {
                if (response.data === "Completed") {
                    dispatch(getActivePatientStatistics({roleId, tableSettings, activePatientId, callback}));
                    updatePatientList && dispatch(getPatients({ roleId, tableSettings }));
                    // callback && callback();
                    dispatch(doctorActions.setLoading({
                        type: 'patientInfoModal',
                        value: false
                    }));
                }
                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    //Все равно обновляем
                    dispatch(getActivePatientStatistics({roleId, tableSettings, activePatientId, callback}));
                    updatePatientList && dispatch(getPatients({ roleId, tableSettings }));
                    // callback && callback();

                    dispatch(doctorActions.setLoading({
                        type: 'patientInfoModal',
                        value: false
                    }));
                }
            } else {
                setTimeout(() => dispatch(getStatisticUpdateStatus({id, iteration: --iteration, roleId, tableSettings, activePatientId, updatePatientList, callback})), 1000);
            }
        }).catch(err => {
            console.log(err);
            dispatch(doctorActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));
        });
    };
};

export const setNotificationEnabled = ({ enabled, roleId }) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${roleId}/SetNotifyByEmail`,
        params: {
            doNotify: enabled
        }
        }).then((response) => {
            dispatch(getDoctor(roleId));

            dispatch(registerMessage({name: 'change-notification-state-message', type: 'primary', title: `Уведомления ${enabled ? 'включены' : 'отключены'}`, closable: true}))
            dispatch(showMessage('change-notification-state-message'))
            dispatch(closeMessage({name: 'change-notification-state-message', delay: systemMessageDelay}))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const changeOnsetDate = ({ conditionId, date, tableSettings, roleId, callback, encounterId }) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'patientInfoModal',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.condition}/${conditionId}/ChangeOnsetDate`,
            params: {
                newDate: dayjs(date).format('YYYY-MM-DD')
            }
        }).then((response) => {
            dispatch(getStatisticUpdateStatus({id: encounterId, roleId, tableSettings, activePatientId: encounterId, updatePatientList: true, callback}));
            dispatch(registerMessage({name: 'change-onset-date-message', type: 'primary', title: 'Дата успешно изменена', closable: true}))
            dispatch(showMessage('change-onset-date-message'))
            dispatch(closeMessage({name: 'change-onset-date-message', delay: systemMessageDelay}))
        }).catch(err => {
            console.log(err)
            dispatch(doctorActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));
            dispatch(registerMessage({name: 'change-onset-date-error-message', type: 'primary', title: 'Не удалось сменить дату', closable: true}))
            dispatch(showMessage('change-onset-date-error-message'))
            dispatch(closeMessage({name: 'change-onset-date-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getCondition = ({ conditionId }) => {
    return (dispatch) => {
        dispatch(doctorActions.setLoading({
            type: 'condition',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.condition}/${conditionId}`,
        }).then((response) => {

            dispatch(doctorActions.setCondition(response?.data))

            dispatch(doctorActions.setLoading({
                type: 'condition',
                value: false
            }));
        }).catch(err => {
            console.log(err)
            dispatch(doctorActions.setLoading({
                type: 'condition',
                value: false
            }));
        });
    };
};

export const changeDoctorPhoto = (state) => {
    return (dispatch) => {
        dispatch(doctorActions.setNeedChangePhoto(state))
    }
}

export const clearPeriodFilterState = () => {
    return dispatch => {
        dispatch(doctorActions.setPeriodFilteringLabel('За месяц'));
        dispatch(doctorActions.setActivePeriodItem(0));
        dispatch(doctorActions.setStartDateToFilter(dayjs().add(-1, "month").format("YYYY-MM-DD")));
        dispatch(doctorActions.setEndDateToFilter(dayjs().format("YYYY-MM-DD")));
    }
}

export default doctorSlice.reducer;
