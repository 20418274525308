import dayjs from "dayjs";

let hostname;

if (process.env.NODE_ENV !== 'production') {
    hostname = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}`
} else {
    hostname = `${window.location.protocol}//${window.location.hostname}`;
}

export const URL = `${hostname}:${process.env.REACT_APP_PORT}`;

export const BASE_URL = `${URL}${process.env.REACT_APP_BASE_URL}`;
export const CHAT_URL = `${URL}${process.env.REACT_APP_BASE_CHAT_URL}`;

export const organizationType = {
    clinic: "clinic",
    department: "department",
    organization: "organization"
}

export const reminderBlockingTime = parseInt(process.env.REACT_APP_REMINDER_BLOCKING_TIME) || 600; //Время блокировки отправки напоминания о выполнении мероприятия протокола
export const notifyPatientBlockingTime = parseInt(process.env.REACT_APP_NOTIFY_PATIENT_BLOCKING_TIME) || 600; //Время блокировки отправки повтороного письма активации пациента
export const months = ['января','февраля','марта','апреля','мая','июня','июля','августа','сентября','октября','ноября','декабря'];
export const noThanksMessage = "На каждое Ваше сообщение мне необходимо дать развёрнутый ответ, касающийся Вашего лечения и обследования, ответ на информационные сообщения общего характера («спасибо» и пр.) и не относящийся к Вашему заболеванию предоставить невозможно. Прошу Вас обращаться ко мне только с вопросами, требующими ответа.";
export const systemMessagesAnimationDuration = parseInt(process.env.REACT_APP_SYSTEM_MESSAGES_ANIMATION_DURATION) || 500; //мс
export const systemMessageDelay = parseInt(process.env.REACT_APP_SYSTEM_MESSAGE_DELAY) || 4000;
export const reloadChatHistoryTimeout = parseInt(process.env.REACT_APP_RELOAD_CHAT_HISTORY_TIMEOUT) || 4000; //Таймаут в мс после обнаружения пропуска сообщения

export const responseTime = [
    {
        id: 0,
        label: '12 часов',
        value: 720,
    },
    {
        id: 1,
        label: '24 часа',
        value: 1440,
    },
    {
        id: 2,
        label: '48 часов',
        value: 2880,
    },
]

export const stopWorkReasons = [
    {
        id: 0,
        code: "fired",
        display: "Уволен"
    },
    {
        id: 1,
        code: "sick-leave",
        display: "Больничный"
    },
    {
        id: 2,
        code: "studying",
        display: "На учебе"
    },
    {
        id: 3,
        code: "vacation",
        display: "Отпуск"
    }
]

export const trimesters = [
    {
        id: 0,
        label: 'Первый триместр',
        code: "1",
    },
    {
        id: 1,
        label: 'Второй триместр',
        code: "2",
    },
    {
        id: 2,
        label: 'Третий триместр',
        code: "3",
    },
]

export const protocolNotificationTexts = {
    getGynecologyText: (task, formData) => `Данное исследование назначено Вам дополнительно, на основании результатов ранее проведенных обследований - ${task?.description}. ${formData?.startDate && formData?.endDate && `Планируемый период проведения ${dayjs(formData?.startDate).locale('ru').format('DD MMMM YYYY г.')} - ${dayjs(formData?.endDate).locale('ru').format('DD MMMM YYYY г.')}`}`,
    getOncoText: (task, formData) => `Данное исследование назначено Вам дополнительно, на основании результатов ранее проведенных обследований - ${task?.description}. ${formData?.startDate && formData?.endDate && `Планируемый период проведения ${dayjs(formData?.startDate).locale('ru').format('DD MMMM YYYY г.')} - ${dayjs(formData?.endDate).locale('ru').format('DD MMMM YYYY г.')}`}. Записаться на выполнение данного исследования Вы можете в регистратуре Центра амбулаторной онкологической помощи по телефону: 8-499-261-30-42. В случае отсутствия данного исследования у нас Вы его можете выполнить в другой медицинской организации. Прошу Вас информировать меня о дате и месте (медицинской организации) проведения исследования.`,
    onco: 'Данное исследование назначено Вам дополнительно, на основании результатов ранее проведенных обследований. Записаться на выполнение данного исследования Вы можете в регистратуре Центра амбулаторной онкологической помощи по телефону: 8-499-261-30-42. В случае отсутствия данного исследования у нас Вы его можете выполнить в другой медицинской организации. Прошу Вас информировать меня о дате и месте (медицинской организации) проведения исследования.'
}

export const mskUtcOffset = 3;

export const prolongingPeriods = [
    {
        id: 0,
        value: 7,
        display: '1 неделя'
    },
    {
        id: 1,
        value: 14,
        display: '2 недели'
    },
    {
        id: 2,
        value: 21,
        display: '3 недели'
    },
    {
        id: 3,
        value: 28,
        display: '4 недели'
    },
    {
        id: 4,
        value: 35,
        display: '5 недель'
    },
    {
        id: 5,
        value: 42,
        display: '6 недель'
    },
    {
        id: 6,
        value: 49,
        display: '7 недель'
    },
    {
        id: 7,
        value: 56,
        display: '8 недель'
    },
    {
        id: 8,
        value: 63,
        display: '9 недель'
    },
    {
        id: 9,
        value: 70,
        display: '10 недель'
    },
    {
        id: 10,
        value: 77,
        display: '11 недель'
    },
    {
        id: 11,
        value: 84,
        display: '12 недель'
    },
    {
        id: 12,
        value: 91,
        display: '13 недель'
    },
    {
        id: 13,
        value: 98,
        display: '14 недель'
    },
    {
        id: 14,
        value: 105,
        display: '15 недель'
    },
    {
        id: 15,
        value: 112,
        display: '16 недель'
    },
    {
        id: 16,
        value: 119,
        display: '17 недель'
    },
    {
        id: 17,
        value: 126,
        display: '18 недель'
    },
    {
        id: 18,
        value: 133,
        display: '19 недель'
    },
    {
        id: 19,
        value: 140,
        display: '20 недель'
    },
    {
        id: 20,
        value: 147,
        display: '21 неделя'
    },
    {
        id: 21,
        value: 154,
        display: '22 недели'
    },
    {
        id: 22,
        value: 161,
        display: '23 недели'
    },
    {
        id: 23,
        value: 168,
        display: '24 недели'
    },
    {
        id: 24,
        value: 175,
        display: '25 недель'
    },
    {
        id: 25,
        value: 182,
        display: '26 недель'
    },
    {
        id: 26,
        value: 189,
        display: '27 недель'
    },
    {
        id: 27,
        value: 196,
        display: '28 недель'
    },
    {
        id: 28,
        value: 203,
        display: '29 недель'
    },
    {
        id: 29,
        value: 210,
        display: '30 недель'
    },
    {
        id: 30,
        value: 217,
        display: '31 неделя'
    },
    {
        id: 31,
        value: 224,
        display: '32 недели'
    },
    {
        id: 32,
        value: 231,
        display: '33 недели'
    },
    {
        id: 33,
        value: 238,
        display: '34 недели'
    },
    {
        id: 34,
        value: 245,
        display: '35 недель'
    },
    {
        id: 35,
        value: 252,
        display: '36 недель'
    },
    {
        id: 36,
        value: 259,
        display: '37 недель'
    },
    {
        id: 37,
        value: 266,
        display: '38 недель'
    },
    {
        id: 38,
        value: 273,
        display: '39 недель'
    },
    {
        id: 39,
        value: 280,
        display: '40 недель'
    },

    {
        id: 40,
        value: 287,
        display: '41 неделя'
    },
    {
        id: 41,
        value: 294,
        display: '42 недели'
    }
]

export const userTypesByAppRole = {
    //client/manager/root
    'fund-admin': 'manager',
    'doctor': 'client',
    'registrator': 'manager',
    'organization-admin': 'root',
}

export const organizationProfileEditTypes = {
    ALLOW_ALL: 'allow_all',
    DISBLED: 'disbled',
    ALLOW_MULTIPROFILE_AND_SAME_PROFILE: 'allow_masp',
    ONLY_CHILD_PROFILE: 'only_child_profile',
}

export const tripleCheckboxState = {
    CHECKED: 1,
    UNCHECKED: 0,
    PARTICALLY_CHECKED: null,
}