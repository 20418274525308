import React, {Suspense, useEffect, useState} from 'react'; 
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import LogoOnlineDoc from "modules/Header/LogoOnlineDoc";
import {Alignment} from "styled/Alignment";
import {Wrapper} from "styled/Wrapper";
import Spinner from "components/Spinner";
import Avatar from "components/Avatar";
import UserMenu from "components/UserMenu";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import DoctorMenu from "./DoctorMenu";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import {getDoctor, selectPractitioner} from "reducers/doctor";
import {getImageUrl} from "utils";

const HeaderDoctor = ({header, patientsCount}) => {
    const role = useSelector(state => state.app.userId);
    const rolename = useSelector(state => state.user.rolename);
    const practitioner = useSelector(selectPractitioner);
    const token = useSelector(state => get(state, "app.access_token"));
    let CustomHeader = header;
    const dispatch = useDispatch();
    const [avatar, setAvatar] = useState('');
    const [showModal] = useAnchorPopup({component: UserMenu, props: {image: avatar, username: get(practitioner, "name[0].text"), role: rolename, menuList: DoctorMenu }})

    useEffect(() => {
      dispatch(getDoctor(role));
    }, []);

    useEffect(() => {
      if (get(practitioner, "photo[0].url", '')) {
        setAvatar(getImageUrl(get(practitioner, "photo[0].url", ''), token));
      }
    }, [practitioner]);

    return (
        <HeaderWrapper>
            <LeftSide>
                <LogoWrapper>
                    <LogoOnlineDoc />
                </LogoWrapper>
                {/* <IconWrapper>
                    <ActionIcon action={() => {}} icon={BellIcon} tooltip={'Оповещения'} />
                </IconWrapper> */}
                <IconWrapper>
                    <Avatar
                    image={avatar}
                    onClick={(e) => showModal(e)} fullname={get(practitioner, "name[0].text")} tooltip={'Меню пользователя'} size={'small'} />
                </IconWrapper>
            </LeftSide>
            {patientsCount > 0 &&
                <CenterSide>
                    <Wrapper direction={'row'} width={'100%'} flex={'1'} minHeight={'40px'}>
                        <Alignment>
                            {header && (
                                <Suspense fallback={<Spinner/>}>
                                    <CustomHeader/>
                                </Suspense>
                            )}
                        </Alignment>
                    </Wrapper>
                </CenterSide>
            }
        </HeaderWrapper>
    );
};

const IconWrapper = styled.div`
  margin-left: 7px;
  display: flex;
`


const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({theme}) => theme.mixins.innerShadow()};
  background-color: ${({theme}) => theme.colors.white100};
  height: 64px;
  min-height: 64px;
`

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  min-width: 320px;
  padding: 0 16px 0 26px;
  height: 100%;
  min-height: 64px;
  ${({theme}) => theme.mixins.innerShadow({x: 'right'})};
  justify-content: space-between;
`

const CenterSide = styled.div`
  width: 100%;
  padding: 0 16px 0 24px;
  height: 100%;
  display: flex;
  min-height: 64px;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  width: 200px;
  overflow: hidden;
  transition: opacity, width, margin-right, .3s ${({theme}) => theme.animations.curve};
  
  ${({isOpen}) => isOpen && css`
    opacity: 0;
    width: 0;
  `};

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
    opacity: 0;
    width: 0;
  `};
`

HeaderDoctor.propTypes = {
    title: PropTypes.string.isRequired,
};

export default HeaderDoctor;
