import React, {useState} from 'react';
import {Wrapper} from 'styled/Wrapper';
import NativeDateInput from "../../../NativeDateInput";
import dayjs from "dayjs";
import NativeTimePicker from "../../../NativeTimePicker";
import {Row} from "../../../../styled/Row";
import SecondaryButton from "../../../Button/SecondaryButton";


const AcceptEventMobile = (props) => {

    const [date, setDate] = useState(dayjs())
    const [time, setTime] = useState({h: '08', m: '00'})

    const onAcceptTime = () => {
        const {actions} = props
        actions({date: date.toISOString(), time})
        props.hideModal();
    }

    const onChangeTime = (stringTime) => {
        setTime({h: stringTime.split(':')[0], m: stringTime.split(':')[0]})
    }

    return <Wrapper>
        <Wrapper paddings={'0 16px'} direction={'row'}>
            <NativeDateInput to={dayjs()} dateTemplate={'D MMMM YYYY г.'} onChange={setDate} defaultValue={date}/>
            <NativeTimePicker onChange={onChangeTime} defaultValue={time.h + ':' + time.m}/>
        </Wrapper>
        <Wrapper paddings={'6px'} direction={'row'} justify={'space-between'}>
            <SecondaryButton
                onClick={e => {
                    e.stopPropagation();
                    props.hideModal();
                }}
                label={'Отмена'}
            />
            <SecondaryButton
                onClick={e => {
                    e.stopPropagation();
                    onAcceptTime();
                }}
                label={'Подтвердить'}
            />
        </Wrapper>
    </Wrapper>;
};

AcceptEventMobile.propTypes = {};

export default AcceptEventMobile;
