import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from "../Text";
import styled, {css} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import { useFocus } from 'hooks/useFocus';

const TextField = ({label, text, addition, whiteSpace, textColor, onClick, textTag, autoFocus, onAutoFocus}) => {  

    const [ref, setFocus] = useFocus();

    useEffect(() => {
        autoFocus && setFocus(true)
    }, [])

    return (
        <Wrapper>
            <Label>
                {label}
            </Label>
            <Content onClick={onClick} clickable={!!onClick} tabIndex={0} autoFocus={autoFocus} onFocus={e => onAutoFocus && onAutoFocus(e)} ref={ref}>
                <Text tag={textTag || 'span'} color={textColor} text={text} font={'robotoRegular'} whiteSpace={whiteSpace ? whiteSpace : 'none'} />
                {addition && <Addition>
                    {addition}
                </Addition>}
            </Content>
        </Wrapper>
    );
};

const Label = styled.div`
    ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54, lineheight: theme.fonts.lineHeight.small})};
`

const Addition = styled.div`
    margin-left: 12px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    outline: none;
    ${({clickable}) => clickable && css`
        cursor: pointer;
    `}
`

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    addition: PropTypes.any,
};

export default TextField;
