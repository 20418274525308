import { find, get } from "lodash";
import { findInTypeBySystem } from "utils";

export const getAccountModel = ({practitioner, practitionerRole, organization}) => {

    const role = find(get(practitionerRole, 'extension'), { url: 'http://miramedix.ru/fhir/StructureDefinition/app-role-reference'})?.valueReference?.reference.replace('AppRole/', '');

    return {
        id: get(practitionerRole, "id"),
        name: get(practitioner, `name[0].text`),
        email: find(get(practitionerRole, `telecom`), {system: "email"})?.value,
        phone: find(get(practitionerRole, `telecom`), {system: "phone"})?.value,
        organization: get(organization, `name`),
        organizationId: get(organization, `id`),
        organizationObj: organization,
        practitionerId: get(practitioner, `id`),
        photo: get(practitioner, `photo[0].url`),
        gender: get(practitioner, `gender`),
        code: find(get(practitionerRole, `identifier`), {system: "urn:mgfoms:szl:entity:practitionerrole:id"})?.value,
        lastUpdated: get(practitionerRole, `meta.lastUpdated`),
        role: role,
        position: findInTypeBySystem(practitionerRole?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002'),
        practitionerRoleId: get(practitionerRole, `id`),
        created: find(get(practitionerRole, `meta.extension`), {url: "ex:createdAt"})?.valueInstant,
        edited: get(practitionerRole, `meta.lastUpdated`),
        activatingDate: find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/activation-date"})?.valueDateTime,
        status: {
            text: (role === 'doctor') ? find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.display :
                find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"})?.valueCoding?.display,
            value: (role === 'doctor') ? find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.code :
                find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"})?.valueCoding?.code
        },
        roleStatus: (role === 'doctor') ? find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"}) :
            find(get(practitionerRole, `extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"}),
        originalItem: practitionerRole
    }
}