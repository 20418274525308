import React, {useState} from 'react'; 
import Button from "components/Button";
import {useDispatch} from "react-redux";
import {AppActions, confirmPersonalDataUsage} from "reducers/app";
import styled from "styled-components";
import {Wrapper} from "styled/Wrapper";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import {getParameterByName} from "utils";
import Spinner from 'components/Spinner';

const ConsentPage = ({name, onAccept}) => {
    const dispatch = useDispatch();
    const [firstStep, setFirstStep] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleConsent = (val) => {
        dispatch(AppActions.setConsent(val));
        onAccept();
    }

    const handlePageChange = (val) => {
        setLoading(true);
        dispatch(confirmPersonalDataUsage({
            value: val,
            token: getParameterByName('token'),
            callback: (success) => {
                if (success) {
                    setFirstStep(false);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        }));
    }

    const renderStep1 = () => {
        return (
            <>
                {loading ? <Spinner /> : <></>}
                <Header><Steps>1 из 2</Steps> Согласие на обработку персональных данных</Header>
                <Content height={'auto'}>
                    <PerfectScrollbarWrapper>
                    <p>Я, <Name>{name}</Name>.</p>
                    <p>Согласие дается мною с целью регистрации меня в качестве пользователя Сервиса сопровождения пациентов (далее - ССП).</p>
                    <p>Согласен(сна) на совершение Оператором (далее - МГФОМС) следующих действий: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных предоставленных в МГФОМС работниками медицинской организации в рамках Сервиса, предусмотренных действующим законодательством Российской Федерации.</p>
                    <p>Подтверждаю, что ознакомлен(а) с положениями Федерального закона от 27.07.2006 152-ФЗ «О персональных данных» (<a target="_blank" rel="noreferrer" href="http://www.rg.ru/2006/07/29/personaljnye-dannye-dok.html">http://www.rg.ru/2006/07/29/personaljnye-dannye-dok.html</a>).</p>
                    <p>Даю настоящее согласие свободно, своей волей и в своем интересе.</p>
                    <p>Оператор гарантирует, что обработка персональных данных осуществляется в соответствии с действующим законодательством Российской Федерации.</p>
                    </PerfectScrollbarWrapper>
                </Content>
                <Footer>
                    <div></div>
                    {/* <Button buttonType={'secondary'} onClick={() => handlePageChange(true)} label={'Пропустить'} /> */}
                    <Button buttonType={'primary'} onClick={() => handlePageChange(false)} label={'Принять'} />
                </Footer>
            </>
        )
    }
    
    const renderStep2 = () => {
        return (
            <>
                <Header><Steps>2 из 2</Steps> Соглашение пользователя</Header>
                <Content height={'calc(100vh - 184px)'}>
                    <PerfectScrollbarWrapper>
                    <p>Настоящее Соглашение определяет условия использования Пользователями материалов Сервиса сайта onlinedoc.mgfoms.ru (далее «Сайт»)</p>
                    <p>1. Общие условия</p>
                    <p>1.1. Использование материалов Сайта регулируется нормами действующего законодательства Российской Федерации.</p>
                    <p>1.2. Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сервиса, Пользователь считается присоединившимся к настоящему Соглашению.</p>
                    <p>1.3. Оператор Сервиса вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 3 (Трех) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сервису, прекратить использование материалов и услуг Сервиса.</p>
                    <p>2. Обязательства Пользователя</p>
                    <p>2.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных правах, а также любых действий, которые приводят или могут привести к нарушению штатного функционирования Сервиса.</p>
                    <p>2.2. Использование материалов Сервиса без согласия правообладателей не допускается (статья 1270 ГК РФ).</p>
                    <p>2.3. Комментарии и иные записи Пользователя не должны вступать в противоречие с требованиями законодательства Российской Федерации и общепринятых норм морали и нравственности.</p>
                    <p>2.4. Пользователь обязан соблюдать правила по работе с Сервисом (далее «Правила»), изложенные ниже:</p>
                    <p>– Никому не сообщайте свою конфиденциальную информацию (логин и пароль) для доступа к Сервису.</p>
                    <p>– Не сохраняйте Вашу конфиденциальную информацию (логин и пароль) в текстовых файлах на компьютере, в мобильном устройстве, на других электронных носителях информации, т. к. при этом существует риск кражи и компрометации персональных идентификаторов.</p>
                    <p>– Используйте на Вашем компьютере (мобильном устройстве), с которого Вы осуществляете доступ к Сервису, современное лицензированное антивирусное программное обеспечение с безупречной репутацией и следите за его регулярным обновлением.</p>
                    <p>– Регулярно выполняйте антивирусную проверку на своем компьютере («Безопасность» на мобильном устройстве) в целях своевременного обнаружения вредоносных программ их блокирования и удаления, в том числе и спам рассылок.</p>
                    <p>– Своевременно проверяйте и устанавливайте обновления к операционной системе Вашего компьютера (мобильного устройства), рекомендуемые компанией-производителем в целях устранения выявленных в нем уязвимостей с учетом выявленных рисков.</p>
                    <p>– Завершение работы с Сервисом выполняется пользователем путем выбора соответствующего пункта меню «Выход».</p>
                    <p>– Старайтесь не обращаться к Сервису с не доверенных (не принадлежащих лично Вам компьютеров (мобильных устройств). По возможности избегайте не защищенных подключений к сети Интернет при работе с Сервисом (например: интернет-кафе, киосках, публичных WI-FI сетях), так как в данном случае увеличивается риск кражи Ваших конфиденциальных данных и вирусной атаки на ваше устройство.</p>
                    <p>– В случае некорректной работы Сервиса обратитесь на горячую линию МГФОМС <Link target="_blank" href="http://www.mgfoms.ru/feedback/feedback-hotline">http://www.mgfoms.ru/feedback/feedback-hotline</Link>.</p>
                    <p>– В случае если у Вас неожиданно перестала работать SIM-карта мобильного телефона, следует оперативно обратиться к своему оператору сотовой связи, с целью уточнения информации по ней, при необходимости произвести блокировку абонентского номера и замену SIM -карты.</p>
                    <p>– При работе с Сервисом постарайтесь ограничить возможность просмотра экрана Вашего компьютера (мобильного устройства) мобильного телефона посторонними (не допущенными к ознакомлению с данной информацией) лицами.</p>
                    <p>– Оператор Сервиса сопровождения пациентов принимает необходимые и достаточные меры по защите персональных данных пользователей Сервиса:</p>
                    <ul>
                    <li>обработка персональных данных осуществляется с соблюдением требований конфиденциальности персональных данных, установленных ст. 7 Федерального закона «О персональных данных», в соответствии с принятыми мерами предусмотренных ч. 1 ст. 19 Федерального закона от 27.07.2016 № 152-ФЗ «О персональных данных» (далее – Закон);</li>
                    <li>на основании ч. 1 ст. 9 Закона субъект персональных данных (далее – пользователь сервиса) принимает решение о предоставлении его персональных данных оператору Сервиса и дает согласие на их обработку своей волей и в своем интересе;</li>
                    <li>на основании ч. 1 ст. 6 Закона обработка персональных данных пользователя сервиса осуществляется оператором Сервиса с согласия субъекта персональных данных на обработку его персональных данных.</li>
                    <li>в соответствии с ч. 5 ст. 18 Закона оператор Сервиса использует базы данных, находящиеся на территории Российской Федерации.</li>
                    <li>подтверждением получения согласия от субъекта персональных данных является выполненная процедура регистрации и предоставления пользователю возможности использовать возможности Сервиса в своих целях.</li>
                    </ul>
                    <p>– Однако, оператор Сервиса не несет ответственности за нарушение конфиденциальности, целостности и доступности предоставленной пользователем информации, если оно возникло по вине пользователя вследствие несоблюдения им настоящих Правил.</p>
                    <p>2.5. МГФОМС не несет ответственности за нарушение конфиденциальности, целостности и доступности предоставленной пользователем информации, если оно возникло по вине пользователя вследствие несоблюдения им настоящих правил</p>
                    <p>3. Прочие условия</p>
                    <p>3.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.</p>
                    <p>3.2. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.</p>
                    <p>3.3. Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.</p>
                    </PerfectScrollbarWrapper>
                </Content>
                <Footer>
                    <Button buttonType={'secondary'} onClick={() => setFirstStep(true)} label={'Назад'} />
                    <Button buttonType={'primary'} onClick={() => handleConsent(true)} label={'Принять'} />
                </Footer>
            </>
        )
    }

    return (
        <Wrapper fullsize align={'center'} justify={'flex-start'}>
            <ModalWrapper fullsize direction={'row'}>
                {firstStep ? renderStep1() : renderStep2()}
            </ModalWrapper>
        </Wrapper>
    );
};

const ModalWrapper = styled.div`
    width: 672px;
    height: auto;
    max-height: calc(100vh - 60px);
    border-radius: 6px;
    border: 1px solid ${({theme}) => theme.colors.border};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

`

const Link = styled.a`
    color: ${({theme}) => theme.colors.primary};
`

const Header = styled.div`
    height: 65px;
    min-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px 0 24px;
    ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.h4, lineheight: theme.fonts.lineHeight.h4})};
`
const Name = styled.span`
    ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.normal})};
`

const Steps = styled.div`
    margin-right: 17px;
    ${({theme}) => theme.mixins.robotoCondensedBold({
        size: theme.fonts.sizes.text, 
        lineheight: theme.fonts.lineHeight.text, 
        color: theme.colors.black24
    })};
`

const Content = styled.div`
    width: 100%;
    height: ${({height}) => height};
    padding: 0 0 0 24px;
    flex-basis: auto;
    flex-grow: 1;
    
    p {
        margin: 0 0 16px 0;
        padding-right: 24px;
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.normal})};
    }

    a {
        color: ${({theme}) => theme.colors.primary}
    }
    a:hover {
        text-decoration: none;
    }

    ul li {
        padding-right: 24px;
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.normal})}; 
    }

`

const Footer = styled.div`
    flex: 1 1 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    flex-direction: ${({direction}) => direction === 'reverse' ? 'row-reverse' : 'row'};
`

export default ConsentPage;
