import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';
import { get, isEmpty, join } from 'lodash';

const initialState = {
    data: [],
    comparations: {},
    loading: {
        fullPage: true,
        protocols: true,
        createEvent: false,
        comparations: true,
        protocolChannelsTransition: {
            status: false,
            progress: 0,
        },
    }
};

export const protocolsSlice = createSlice({
    name: 'protocols',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setComparations: (state, action) => {
            state.comparations = action.payload;
        },
        clearComparations: (state, action) => {
            state.comparations = {};
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        resetState: (state, action) => {
            return initialState
        }
    },
});

export const selectData = state => state.superAdmin.protocols.data;
export const selectComparations = state => state.superAdmin.protocols.comparations;
export const selectLoading = state => state.superAdmin.protocols.loading;
export const SuperAdminProtocolsActions = protocolsSlice.actions;

export const getProtocols = ({tableSettings}) => {
    return (dispatch) => {
        dispatch(SuperAdminProtocolsActions.setLoading({
            type: 'protocols',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/_search`,
            data: {
                paging: {
                    ...tableSettings?.paging
                },
                filtering: {
                    searchString: tableSettings?.searchString,
                    fieldsFilter: {
                        ...tableSettings?.filtering?.fieldsFilter,
                    },
                },
                sorting: [...tableSettings?.sorting]
            }
        }).then(response => {
            dispatch(SuperAdminProtocolsActions.setData(response?.data));
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'protocols',
                value: false
            }));
        }).catch(err => {
            console.log(err);
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'protocols',
                value: false
            }));
        });
    };
};

export const createProtocol = ({formData, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}`,
            data: {
                resourceType: "PlanDefinition",
                title: formData?.title,
                status: "draft",
                useContext: formData?.useContext
            }
        }).then(response => {
            dispatch(getProtocols({tableSettings}));
        }).catch(err => {
            console.log(err);
        });
    };
};

export const editProtocol = ({item, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.planDefinition}`,
            data: item
        }).then(response => {
            dispatch(getProtocols({tableSettings}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'edit-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'edit-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось отредактировать протокол',
                    closable: true,
                }))
                dispatch(showMessage('edit-protocol-error-message'))
                dispatch(closeMessage({name: 'edit-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const changeProtocolStatus = ({item, status, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.planDefinition}/${item?.id}/SetStatus`,
            params: {
                status
            }
        }).then(response => {
            dispatch(getProtocols({tableSettings}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'activate-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('activate-protocol-error-message'))
                dispatch(closeMessage({name: 'activate-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'activate-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось сменить статус протокола',
                    closable: true,
                }))
                dispatch(showMessage('activate-protocol-error-message'))
                dispatch(closeMessage({name: 'activate-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const deleteProtocol = ({id, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.planDefinition}/${id}`
        }).then(response => {
            dispatch(getProtocols({tableSettings}));
        }).catch(err => {
            console.log(err);
            if(err?.response?.data && (typeof err?.response?.data === 'string')){
                dispatch(registerMessage({
                    name: 'delete-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: err?.response?.data,
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-error-message', delay: systemMessageDelay}))
            }else{
                dispatch(registerMessage({
                    name: 'delete-protocol-error-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: 'Не удалось удалить протокол',
                    closable: true,
                }))
                dispatch(showMessage('delete-protocol-error-message'))
                dispatch(closeMessage({name: 'delete-protocol-error-message', delay: systemMessageDelay}))
            }
        });
    };
};

export const getMkb10 = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.mkb10}`
        }).then((response) => {
            console.log(response.data);
            // const items = response.data.items;
            // const adaptedItems = items.map(item => {
            //     return {
            //         ...item,
            //         label: item?.display,
            //         pre: item?.code,
            //         checked: false,
            //     }
            // });

            // checkedItems.forEach(checked => {
            //     const matched = adaptedItems.find(item => item.id === checked.id);
            //     if (matched) matched.checked = true;
            // });

            // dispatch(TableFiltersActions.setAllFilterItems({
            //     filterName: 'diagnosis',
            //     value: adaptedItems,
            //     table
            // }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const addEvent = ({formData, callback}) => {
    return (dispatch) => {
        dispatch(SuperAdminProtocolsActions.setLoading({
            type: 'createEvent',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.activityDefinition}`,
            data: {
                resourceType: "ActivityDefinition",
                title: formData.title,
                status: "active",
                kind: "Task",
                identifier: [
                    {
                        use: "usual",
                        value: formData.codeTpp,
                        system: "urn:mmdx:onlinedoc:entity:activitydefinition:code"
                    },
                ]
            }
        }).then((response) => {
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'createEvent',
                value: false
            }));
            dispatch(registerMessage({name: 'add-event-message', type: 'primary', title: 'Мероприятие создано', closable: true}))
            dispatch(showMessage('add-event-message'))
            dispatch(closeMessage({name: 'add-event-message', delay: systemMessageDelay}));
            callback(response.data?.id);
        }).catch(err => {
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'createEvent',
                value: false
            }));
        });
    };
};

export const getProtocolComparations = ({oldItem, newItem}) => {
    return (dispatch) => {
        dispatch(SuperAdminProtocolsActions.setLoading({
            type: 'comparations',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/Comparation`,
            params: {
                oldId: oldItem?.id,
                newId: newItem?.id
            }
        }).then((response) => {
            const data = get(response, "data");

            dispatch(SuperAdminProtocolsActions.setComparations(data));

            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'comparations',
                value: false
            }));
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({
                name: 'load-protocol-comparations-error-message',
                type: 'red',
                title: 'Ошибка',
                text: 'Не удалось загрузить различия между протоколами',
                closable: true,
            }))
            dispatch(showMessage('load-protocol-comparations-error-message'))
            dispatch(closeMessage({name: 'load-protocol-comparations-error-message', delay: systemMessageDelay}))
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'comparations',
                value: false
            }));
        });
    };
};

export const substituteProtocol = ({oldItem, newItem, tableSettings, handleHide}) => {
    return (dispatch) => {
        dispatch(SuperAdminProtocolsActions.setLoading({
            type: 'protocolChannelsTransition',
            value: {
                status: true,
                progress: 0,
            }
        })); 
        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/Substitute`,
            data: {
                oldId: oldItem?.id,
                newId: newItem?.id
            }
        }).then((response) => {

            dispatch(getSubstituteUpdateStatus({id: response?.data?.jobId, item: oldItem, handleHide: handleHide, iteration: 14400, tableSettings})); // Длительность в секундах 4ч - 14400

        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({
                name: 'protocol-substitute-error-message',
                type: 'red',
                title: 'Ошибка',
                text: 'Не удалось инициализировать процесс перенос каналов на новый протокол',
                closable: true,
            }))
            dispatch(showMessage('protocol-substitute-error-message'))
            dispatch(closeMessage({name: 'protocol-substitute-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getSubstituteUpdateStatus = ({id, item, handleHide, iteration, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/GetSubstituteResult/${id}`,
        }).then((response) => {
            if (response?.data?.status === "Finished" || response?.data?.status === "Failed" || !iteration) {
                handleHide && handleHide();
                dispatch(SuperAdminProtocolsActions.setLoading({
                    type: 'protocolChannelsTransition',
                    value: {
                        status: false,
                        progress: response?.data?.percentCompleted,
                    }
                })); 

                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                }

                dispatch(showSubstituteResult({id}))
                response?.data?.status !== "Failed" && dispatch(changeProtocolStatus({
                    item,
                    status: "retired",
                    tableSettings
                }));
            } else {
                dispatch(SuperAdminProtocolsActions.setLoading({
                    type: 'protocolChannelsTransition',
                    value: {
                        status: true,
                        progress: response?.data?.percentCompleted,
                    }
                })); 
                setTimeout(() => dispatch(getSubstituteUpdateStatus({id, item, handleHide, iteration: --iteration, tableSettings})), 1000);
            }
        }).catch(err => {
            dispatch(SuperAdminProtocolsActions.setLoading({
                type: 'protocolChannelsTransition',
                value: {
                    status: false,
                    progress: null,
                }
            }));
            dispatch(registerMessage({name: 'check-status-request-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('check-status-request-error-message'))
            dispatch(closeMessage({name: 'check-status-request-error-message', delay: systemMessageDelay}))
        });
    };
};

export const showSubstituteResult = ({id}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/GetSubstituteResult/${id}`,
        }).then((response) => {

            if(!isEmpty(response?.data?.errors)){
                dispatch(registerMessage({
                    name: 'substitute-result-message',
                    type: 'red',
                    title: 'Ошибка',
                    text: join(response?.data?.errors, ', '),
                    closable: true,
                }))
                dispatch(showMessage('substitute-result-message'))
                dispatch(closeMessage({name: 'substitute-result-message', delay: systemMessageDelay}))
            }

        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({
                name: 'substitute-result-error-message',
                type: 'red',
                title: 'Ошибка',
                text: 'Не удалось получить результаты переноса каналов протокола',
                closable: true,
            }))
            dispatch(showMessage('substitute-result-error-message'))
            dispatch(closeMessage({name: 'substitute-result-error-message', delay: systemMessageDelay}))
        });
    };
};

export default protocolsSlice.reducer;
