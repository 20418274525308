import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tasks from './Tasks';
import TaskDetails from './TaskDetails';
import CreateTask from './CreateTask';
import {
    selectActiveEvent,
    selectAddWindowElement,
    getProtocolTasks,
    selectShowSearchWindow,
    doctorRightSideActions,
    selectLoading
} from 'reducers/doctor/rightSide';
import { selectActivePatient } from 'reducers/doctor';
import Spinner from 'components/Spinner';
import { setActiveTab } from 'reducers/tabs/tabsSlice';
import SecureLS from 'secure-ls';
import { isMobileOnly } from 'react-device-detect';

const ls = new SecureLS();

const Protocol = () => {

    const activeEvent = useSelector(selectActiveEvent);
    const addWindowElement = useSelector(selectAddWindowElement);
    const activePatient = useSelector(selectActivePatient);
    const showSearchWindow = useSelector(selectShowSearchWindow);
    const loading = useSelector(selectLoading);

    const dispatch = useDispatch();

    useEffect(() => {

        activePatient && dispatch(getProtocolTasks({
            encounterId: activePatient.id,
            sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
        }))

        !!showSearchWindow && dispatch(doctorRightSideActions.setShowSearchWindow(false));
        activeEvent !== null && dispatch(doctorRightSideActions.setActiveEvent(null));

        dispatch(setActiveTab({name: 'protocol-tabs', value: 0}));

    }, [activePatient?.id])

    useEffect(() => {
        if(isMobileOnly){
            if(ls.get('activeEvent')){
                ls.remove('activeEvent')
            }
        }
    }, []);
    

    return activeEvent !== null || loading?.protocolTask ? (
        /*Детальное описание мероприятия*/
        loading?.protocolTask ?
        <Spinner/>
        :
        <TaskDetails/>
    ) : (
            <>
                {addWindowElement == null ?
                    /*Список мероприятий*/
                    <Tasks/>
                :
                    /*Создание мероприятия*/
                    <CreateTask/>
                }
            </>
        );
};

export default Protocol;
