import React from 'react'; 
import styled from "styled-components";
import FormInput from "../FormInput/FormInput";
import {Wrapper} from "styled/Wrapper";
import dayjs from "dayjs";

const RangePicker = ({activeField, activeStartDate, activeEndDate, handleChangeField, width, textAlignInput}) => {

    return (
        <Wrapper direction={'row'} align={'center'} width={width || '100%'}>
            <Item>
                <FormInput disabled active={activeField === 'startDate'} onFocus={e => handleChangeField(e, 'startDate')} value={dayjs(activeStartDate).locale("ru").format("D MMMM YYYY")} placeholder={''} onChange={() => {}} focus textAlign={textAlignInput} />
            </Item>
            <Separator>
                –
            </Separator>
            <Item>
                <FormInput disabled active={activeField === 'endDate'} onFocus={e => handleChangeField(e, 'endDate')} value={dayjs(activeEndDate).locale("ru").format("D MMMM YYYY")} placeholder={''} onChange={() => {}} textAlign={textAlignInput} />
            </Item>
        </Wrapper>
    );
};

const Item = styled.div`
`

const Separator = styled.div`
  padding: 0 8px;
  ${({theme}) => theme.mixins.robotoRegular()};
`

RangePicker.propTypes = {

};

export default RangePicker;
