import React, {forwardRef} from 'react'; 
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import Icon from "components/Icon";
import CameraIcon from "components/Icons/CameraIcon";
import AddPhotoIcon from "components/Icons/AddPhotoIcon";
import {get} from "lodash";
import {v4 as uuidv4} from "uuid";
import {selectDefaultImagesUrls} from "reducers/app";
import {useSelector} from "react-redux";

const createUuid = () => uuidv4();

const UserImage = forwardRef(({img, type, size, onChange, disabled}, ref) => {
    const theme = useTheme();
    const id = createUuid();
    const defaultImagesUrls = useSelector(selectDefaultImagesUrls);

    const handleFileLoaded = e => {
        const { files } = e.target;

        if (files[0]) {
            // const reader = new FileReader();
            // reader.onloadend = () => onLoadEnd(reader.result);
            // reader.readAsDataURL(files[0]);

            if (get(files[0], "type", "").includes('image/')) {
              const localImageUrl =  window.URL.createObjectURL(files[0]);
              onChange(files[0], localImageUrl)
            } else {
              onChange(null, null);
            }
        } else {
          onChange(null, null);
        }
    }

    return (
        <UserImageWrapper size={size} empty={!!img}>
            {img && !disabled && <Overlay>
                <Icon icon={CameraIcon} color={theme.colors.white100} size={24} />
            </Overlay>}
            <ImageWrapper disabled={disabled} htmlFor={disabled ? 'none' : `upload-file-${id}`}>
                {img && <img src={img} alt={''} />}
                {!img ? !disabled ? <Icon icon={AddPhotoIcon} size={24} /> : <img src={defaultImagesUrls[type]} alt={''} /> : <></>}
            </ImageWrapper>
            <FileUploadWrapper>
                <input type="file" 
                ref={ref}
                accept="image/*" 
                id={`upload-file-${id}`}
                  onChange={handleFileLoaded}
                />
            </FileUploadWrapper>
        </UserImageWrapper>
    );
});

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.black40};
  display: none; 
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'};
  height: 100%;
  width: 100%;
  
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
`

const FileUploadWrapper = styled.div`
  position: absolute;
  left: -9999px;
  opacity: 0;
  height: 0;
`

const UserImageWrapper = styled.div`
  width: ${({size}) => size ? `${size}px` : '56px'};
  height: ${({size}) => size ? `${size}px` : '56px'};
  border-radius: ${({theme}) => theme.other.borderRadius};
  overflow: hidden;
  position: relative;
  pointer-events: none;
  flex: 0 0 auto;
  background-color: ${({theme, empty}) => empty ? theme.colors.white100 : theme.colors.black04};
  
  ${({theme}) => theme.isMobile && css`
    border-radius: 100%;
  `}
  
  :hover {
    background-color: ${({theme, empty}) => empty ? theme.colors.white100 : theme.colors.black08};
    ${Overlay} {
      display: flex;
    }
  }
`

UserImage.propTypes = {
    img: PropTypes.string.isRequired,
};

UserImage.defaultProps = {
    disabled: false,
}

export default UserImage;
