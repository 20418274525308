import React, {useEffect, useRef, useState} from 'react'; 
import {Wrapper} from "styled/Wrapper";
import styled, {css, useTheme} from "styled-components";
import Input from "components/Input/Input";
import SearchIcon from "components/Icons/SearchIcon";
import InfiniteList from 'components/InfiniteList';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSearchTaskList,
    getSearchTasks,
    doctorRightSideActions,
    selectProtocolTasksLoadedPageCount,
    selectProtocolTasksTotalCount,
    selectLoading,
    clearSearchTasksListState,
    addTaskToCarePlan
} from 'reducers/doctor/rightSide';
import {
    selectActivePatient,
    selectRole
} from 'reducers/doctor';
import axios from 'axios';
import ClearIcon from 'components/Icons/ClearIcon';
import { useOutsideClickHandler } from 'hooks/useOutsideClickHandler';
import {isMobileOnly, MobileOnlyView, CustomView, isDesktop, isTablet} from 'react-device-detect';
import {useHistory} from "react-router-dom";
import {routes} from "consts/routes";
import TailArrowIcon from "components/Icons/TailArrowIcon";
import ActionIcon from "components/ActionIcon";
import Text from "components/Text";
import { isEmpty } from 'lodash';
import magnifier from "assets/images/magnifier.svg"
import NoData from 'components/NoData';

let cancelTokenSource;

const TaskSearch = () => {

    const theme = useTheme()
    const ref = useRef();
    const history = useHistory();

    const items = useSelector(selectSearchTaskList);
    const protocolTasksLoadedPageCount = useSelector(selectProtocolTasksLoadedPageCount);
    const protocolTasksTotalCount = useSelector(selectProtocolTasksTotalCount);
    const loading = useSelector(selectLoading);
    const activePatient = useSelector(selectActivePatient);
    const doctorRole = useSelector(selectRole);

    const [searchString, setSearchString] = useState('');
    const [timerId, setTimerId] = useState();
    const [infinitieListRef, setStateInfinitieListRef] = useState();

    const [infinitieListHeight, setStateInfinitieListHeight] = useState();
    const [showSearchInput, setShowSearchInput] = useState(false);

    useEffect(() => {
        document.body.addEventListener('load', countScrollListHeight)
        window.addEventListener('resize', countScrollListHeight);
        return () => {
            window.removeEventListener('resize', countScrollListHeight)
            document.body.removeEventListener('load', countScrollListHeight)
        }
    })

    const countScrollListHeight = () => {
        setStateInfinitieListHeight(ref.current.scrollHeight)
    }

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(doctorRightSideActions.setShowSearchWindow(false));
    }

    useOutsideClickHandler({ref, callback: handleClose});

    const loadMoreItems = () => {

        cancelTokenSource = axios.CancelToken.source();

        return new Promise((resolve, reject) => {
            if(activePatient?.patientId){
                dispatch(getSearchTasks({
                    patientId: activePatient.patientId,
                    searchString,
                    startIndex: protocolTasksLoadedPageCount * 45,
                    maxItems: 45,
                    cancelTokenSource
                }))
                dispatch(doctorRightSideActions.setSearchTasksLoadedPageCount(protocolTasksLoadedPageCount + 1));
            }
        })
    }

    const handleSearchListItemClick = (item) => {
        activePatient && doctorRole && dispatch(addTaskToCarePlan({
            carePlanId: activePatient?.carePlanId,
            patientId: activePatient?.patientId,
            practitionerRoleId: doctorRole?.id,
            task: item,
            callback: (item) => {
                if(isMobileOnly){
                    history.push(`/create_task-${item?.id}`)
                }
            }
        }))
    }

    const renderItems = (items, searchString) => {

        let providedByProtocolTitleAdded = false;
        let dontProvidedByProtocolTitleAdded = false;

        let providedItems = []
        let notProvidedItems = []

        const regexp = new RegExp(searchString, "gi");

        items.forEach((item, index) => {

            let title;

            if(searchString){
                title = item?.title?.replace(regexp, `<mark>$&</mark>`);
            }else{
                title = item?.title
            }

            if(!!item.actionId){
                if(!providedByProtocolTitleAdded){
                    providedItems.push(() => (<SearchListItem color={theme.colors.primary}><b>Предусмотрено протоколом</b></SearchListItem>));
                    providedByProtocolTitleAdded = true;
                }
                providedItems.push(
                    () => (<SearchListItem color={theme.colors.black100}
                        onClick={() => handleSearchListItemClick(item)} dangerouslySetInnerHTML={{__html: title}} />)
                )
            }else{
                if(!dontProvidedByProtocolTitleAdded){
                    providedItems.push(() => (<SearchListItem color={theme.colors.primary}><b>Другие медицинские услуги</b></SearchListItem>));
                    dontProvidedByProtocolTitleAdded = true;
                }
                notProvidedItems.push(
                    () => (<SearchListItem color={theme.colors.black100}
                        onClick={() => handleSearchListItemClick(item)} dangerouslySetInnerHTML={{__html: title}} />)
                )
            }
        })

        return [...providedItems, ...notProvidedItems];
    }

    const clearSearchState = (cancelling) => {
        !cancelling && dispatch(clearSearchTasksListState());
        infinitieListRef?.current && infinitieListRef?.current?.resetLoadMoreRowsCache(true);
    }

    const handleSearchString = (e) => {
        setSearchString(e);
        loading?.nextPageSearchTaskList && cancelTokenSource.cancel();
        timerId && clearTimeout(timerId);
        !e ? clearSearchState() : setTimerId(setTimeout(clearSearchState, 1000, loading?.nextPageSearchTaskList))
    }

    const clearValue = () => {
        setSearchString('');
        clearSearchState();
    }

    useEffect(() => {
        activePatient && dispatch(clearSearchTasksListState());
    }, [activePatient?.patientId])

    const onBack = () => {
        history.push(routes.private.mobile_doctor.rightSide.path)
    }

    return (
        <WrapperBox>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper ref={ref} flex={'1 0 auto'} fullsize justify={'stretch'}>
                    <Wrapper paddings={'12px 16px 16px 16px'}>
                        <Input
                            // readonly={loading?.nextPageSearchTaskList}
                            onChange={handleSearchString} value={searchString} focus placeholder={'Найти в общем справочнике услуг'} icon={ClearIcon} iconShowOnType decorationIcon={isMobileOnly ? <></> : SearchIcon} iconAction={() => clearValue()} focusUnderline />
                    </Wrapper>
                    <InfiniteListWrapper>
                        <InfiniteList
                            loadNextPage={loadMoreItems}
                            items={renderItems(items, searchString)}
                            hasNextPage={(protocolTasksTotalCount === null || items.length < protocolTasksTotalCount)}
                            isNextPageLoading={loading.nextPageSearchTaskList}
                            setInfinitieListRefToParent={setStateInfinitieListRef}
                            propHeight={infinitieListHeight}
                        />
                    </InfiniteListWrapper>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper ref={ref} flex={'1 0 auto'} fullsize justify={'stretch'}>
                    {showSearchInput ?
                        <Wrapper paddings={'0 8px 0 16px'} direction={'row'} minHeight={'50px'} maxHeight={'50px'}
                                 align={'center'}>
                            <Wrapper paddings={'0 6px 0 0'} width={'auto'}>
                                <ActionIcon
                                    action={onBack}
                                    size={'24px'}
                                    icon={TailArrowIcon}
                                    rotate={90}
                                />
                            </Wrapper>
                            <Input
                                onChange={handleSearchString} value={searchString} focus
                                placeholder={'Найти в общем справочнике услуг'} icon={ClearIcon} iconShowOnType
                                iconAction={() => clearValue()} iconSize={'24'} withoutPaddings transparent/>
                        </Wrapper>
                        :
                        <Wrapper paddings={'0 16px'} direction={'row'} minHeight={'50px'} maxHeight={'50px'}
                                 align={'center'} justify={'space-between'}>
                            <ActionIcon
                                action={onBack}
                                size={'24px'}
                                icon={ClearIcon}
                                rotate={90}
                            />
                            <Wrapper paddings={'0 0 0 16px'} width={'100%'}>
                                <Text tag={'h4'} text={'Выбор мероприятия'} />
                            </Wrapper>
                            <ActionIcon
                                action={() => setShowSearchInput(true)}
                                size={'24px'}
                                icon={SearchIcon}
                            />
                        </Wrapper>
                    }
                    <InfiniteListWrapper>
                        {
                            isEmpty(items) && protocolTasksLoadedPageCount > 0 && !loading.nextPageSearchTaskList ?
                            <Wrapper align={'center'} justify={'center'} minHeight={'calc(100vh - 50px)'}>
                                <NoData image={magnifier} text={'Ничего не найдено'} margins={'0 0 64px 0'}
                                    imageMargins={'0 0 24px 0'}/>
                            </Wrapper>
                            :
                            <InfiniteList
                                loadNextPage={loadMoreItems}
                                items={renderItems(items, searchString)}
                                hasNextPage={(protocolTasksTotalCount === null || items.length < protocolTasksTotalCount)}
                                isNextPageLoading={loading.nextPageSearchTaskList}
                                setInfinitieListRefToParent={setStateInfinitieListRef}
                                propHeight={infinitieListHeight}
                            />
                        }
                    </InfiniteListWrapper>
                </Wrapper>
            </MobileOnlyView>
        </WrapperBox>
    );
};

const WrapperBox = styled.div`
    height: 100%;
    
    & > div {
        height: 100%;
    }
    ${({theme}) => theme.isMobile && css`
        height: calc(100vh);
        height: calc(var(--vh, 1vh) * 100);
    `}
`

const InfiniteListWrapper = styled.div`
    flex: 1 1 auto;
    height: calc(100% - 60px);
    
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.innerShadow({y: 'top'})};
    `}
`

const SearchListItem = styled.div`
    padding: 6px 16px;
    cursor: pointer;
    ${({theme, color}) => theme.mixins.robotoRegular({color: color})};
    ${({theme}) => theme.isMobile && css`
        ${({theme, color}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5, color: color})};
    `}
    
    b {
        font-weight: normal;
    }

    mark {
        background-color: ${({theme}) => theme.colors.mark};
    }

    &:hover {
        background: ${({theme}) => theme.colors.black04};
    }
`

TaskSearch.propTypes = {
};

export default TaskSearch;
