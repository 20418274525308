import {createSlice} from "@reduxjs/toolkit";
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";

const initialState = {
    data: {},
    loading:{
        data: false,
    },
    error: false
}

export const globalSearchSlice = createSlice({
    name: "globalSearch",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        clearData: (state, action) => {
            state.data = initialState.data;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setErrorFlag: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const selectData = state => state.globalSearch.search.data;
export const selectEncounterInfos = state => state.globalSearch.search.data.encounterInfos;
export const selectEncountersTotal = state => state.globalSearch.search.data.encounterInfosTotal;
export const selectOrganizationInfos = state => state.globalSearch.search.data.organizationInfos;
export const selectOrganizationsTotal = state => state.globalSearch.search.data.organizationInfosTotal;
export const selectPractitionerInfos = state => state.globalSearch.search.data.practitionerInfos;
export const selectPractitionersTotal = state => state.globalSearch.search.data.practitionerInfosTotal;
export const selectLoading = state => state.globalSearch.search.loading;
export const selectErrorFlag = state => state.globalSearch.search.error;
export const GlobalSearchActions = globalSearchSlice.actions;

export default globalSearchSlice.reducer;

export const search = ({searchString, organizationId}) => {
  return (dispatch) => {

        dispatch(GlobalSearchActions.setLoading({
            type: 'data',
            value: true
        }))
        dispatch(GlobalSearchActions.setErrorFlag(false));

        fetchInstance({
            method: "GET",
            url: api.globalSearch,
            params: {
                searchString,
                requestingOrganizationId: organizationId
            }
        }).then((response) => {
                console.log(response);
                dispatch(GlobalSearchActions.setData(response?.data))
                dispatch(GlobalSearchActions.setLoading({
                    type: 'data',
                    value: false
                }))
        }).catch(err => {
            console.log(err)
            dispatch(GlobalSearchActions.setLoading({
                type: 'data',
                value: false
            }))
            dispatch(GlobalSearchActions.setErrorFlag(true));
        });
  };
};
