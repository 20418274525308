import { createSlice } from '@reduxjs/toolkit';

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState: [],
    reducers: {
        setActiveTab: (state, action) => {
            for (let tabs in state) {
                if (state[tabs].name === action.payload.name) {
                    state[tabs].activeTab = action.payload.value;
                }
            }
        },
        registerTabsGroup: ((state, action) => {
            return [
                ...state,
                {
                    name: action.payload.name,
                    activeTab: action.payload.activeTab,
                }
            ]
        }),
        unregisterTabsGroup: ((state, action) => {
            return state.filter(item => item.name !== action.payload)
        })
    },
});

export const { setActiveTab, registerTabsGroup, unregisterTabsGroup } = tabsSlice.actions;

export default tabsSlice.reducer;
