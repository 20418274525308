import React from 'react';
import PropTypes from 'prop-types';
import Text from "components/Text";
import styled from "styled-components";

const TablePrimaryText = ({tooltip, text}) => {
    return (
        <TablePrimaryTextWrapper>
            <Text overflow font={'robotoRegular'} tooltip={tooltip}>
                {text}
            </Text>
        </TablePrimaryTextWrapper>
    );
};

const TablePrimaryTextWrapper = styled.div`
  padding: 10px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

TablePrimaryText.propTypes = {
    text: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};

export default TablePrimaryText;
