import React from 'react';
import PropTypes from 'prop-types';
import Textarea from "components/Textarea/Textarea";
import {Wrapper} from "styled/Wrapper";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import SecondaryButton from "components/Button/SecondaryButton";
import Button from "components/Button";
import Group from "components/Group";
import {Field, Form} from "react-final-form";
import {requiredValidator} from "utils/validators";

const DiagnosisCancelEventModal = (props) => {

    const {
        hideModal,
        onAccept,
        task
    } = props;

    const handleFormSubmit = (values) => {
        onAccept && onAccept(task, values?.reasons);
        hideModal && hideModal();
    }

    return (
        <Wrapper maxHeight={'calc(100vh - 124px)'} paddings={'0 6px'}>
            <PerfectScrollbarWrapper>
                <Form
                        initialValues={{
                        }}
                        onSubmit={values => {
                            handleFormSubmit(values)
                        }}
                    >
                        {({handleSubmit, pristine, form, submitting, invalid, values}) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Field name={"reasons"}
                                           validate={requiredValidator}
                                    >
                                        {({input, meta}) =>
                                            <Textarea
                                                {...input}
                                                placeholder={'Введите показание для отмены обязательного мероприятия'}
                                                required
                                                symbols={150}
                                                showError={meta.touched && meta.error}
                                                error={meta.touched && meta.error}
                                                withoutPaddings
                                            />}
                                    </Field>
                                    <Wrapper paddings={'24px 0 8px 0'} direction={'row'} justify={'space-between'}>
                                        <SecondaryButton onClick={hideModal} label={'Отмена'} />
                                        <Button
                                            buttonType={'redOutline'}
                                            disabled={!values.reasons || values.reasons.length <= 0}
                                            onClick={handleSubmit}
                                            label={'Подтвердить'} />
                                    </Wrapper>
                                </form>
                            )}}
                    </Form>
            </PerfectScrollbarWrapper>
        </Wrapper>
    );
};

DiagnosisCancelEventModal.propTypes = {
    hide: PropTypes.func.isRequired,
};

export default DiagnosisCancelEventModal;
