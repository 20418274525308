import React from 'react';
import styled, { keyframes } from 'styled-components';
import ProgressIcon from 'components/Icons/ProgressIcon';

const CircleProgress = ({size = 18}) => {
    return (
        <IconWrapper size={size}>
            <ProgressIcon size={size}/>
        </IconWrapper>
    );
};

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const IconWrapper = styled.div`
    width: ${({size}) => size && `${size}px`};
    height: ${({size}) => size && `${size}px`};
    animation: ${rotate} 2s infinite linear;
`

CircleProgress.propTypes = {
};

export default CircleProgress;
