import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'styled/Wrapper';
import styled from 'styled-components';
import dayjs from 'dayjs';

const NativeTimePicker = ({ onChange, defaultValue, from, to}) => {

    const [value, setValue] = useState(defaultValue);

    const handleDateChange = (e) => {
        if(e?.target?.value){
            onChange && onChange(e.target.value);
            setValue(e.target.value);
        }
    }

    return  <Wrapper>
                <Label>
                    {value}
                </Label>
                <HiddenDateInput type="time" onChange={handleDateChange} min={from} max={to} value={value}/>
            </Wrapper>
};

const Label = styled.label`
    ${({theme}) => theme.mixins.robotoRegular({
        color: theme.colors.primary,
        size: theme.fonts.sizes.h5,
        lineheight: theme.fonts.lineHeight.normal
  })};
`

const HiddenDateInput = styled.input`
    position: absolute;
    color: transparent;
    border: none;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    ::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        margin: 0;
        color: transparent;
        background: transparent;
    }

    ::-webkit-inner-spin-button {
        z-index: 1;
    }

    ::-webkit-clear-button {
        z-index: 1;
    }

    ::-webkit-datetime-edit { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-fields-wrapper { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-text { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-month-field { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-day-field { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-year-field { color: transparent; background: transparent; }
`

NativeTimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
};

NativeTimePicker.defaultProps = {
    defaultValue: '08:00'
}

export default NativeTimePicker;
