import { createSlice, current } from '@reduxjs/toolkit';
import { systemMessagesAnimationDuration } from 'consts';

export const systemMessagesSlice = createSlice({
    name: 'systemMessages',
    initialState: [

    ],
    reducers: {
        showMessage: (state, action) => {
            return state.map(item => item.name === action.payload ? {...item, toOpen: true} : item)
        },
        hideMessage: (state, action) => {
            let hideMessageFinded = false;
            return state.map((item, index) => {
                if(item.name === action.payload){
                    hideMessageFinded = true;
                    return ({...item, toHide: true, toOpen: false})
                }else{
                    if(hideMessageFinded){
                        return {...item, position: (index - 1), prevPosition: (index), toOpen: false, toHide: false}
                    }else{
                        return {...item}
                    }
                }
            })
        },
        registerMessage: ((state, action) => {
            return [
                ...state,
                {
                    name: action.payload.name,
                    toOpen: false,
                    tohide: false,
                    type: action.payload.type || 'primary',
                    url: action.payload.url || false,
                    data: {
                        title: action.payload.title,
                        text: action.payload.text,
                    },
                    closable: action.payload.closable,
                    position: state.length
                }
            ]
        }),
        unregisterMessage: ((state, action) => {
            return state.reduce((acc, item) => {
                if(item.name !== action.payload){
                    return [...acc, {...item, prevPosition: undefined}]
                }else{
                    return [...acc]
                }
            }, [])
        })
    },
});

export const selectMessage = name => state => state.systemMessages.find(item => item.name === name);

export const { showMessage, hideMessage, registerMessage, unregisterMessage } = systemMessagesSlice.actions;

export default systemMessagesSlice.reducer;

export const closeMessage = ({name, delay}) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(hideMessage(name))
            setTimeout(() => {
                dispatch(unregisterMessage(name))
            }, systemMessagesAnimationDuration)
        }, delay)
    }
}
