import React, {useState, useEffect} from 'react'; 
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import { get } from "lodash";
import Icon from "../../Icon";

const Item = ({item, maxWidth, active, onClick, color, bordered, type, pathToLabel, nowrap, icon, iconSize}) => {

  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    if (maxWidth) {
      const value = item?.display || item?.label || item?.name;
      const length = Math.round(maxWidth / 8.7);
      if (value && value.length > length) setTooltipText(value);
    } 
  }, [item, maxWidth]);

    const renderItem = value => {
      if (maxWidth) {
        const length = Math.round(maxWidth / 8.7);
        return value.length > length ? value.slice(0, length) + '...' : value;
      } 
      return value;
    }

    return (
        <ItemWrapper title={tooltipText} color={color} active={active} onClick={(e) => onClick(e, item)} bordered={bordered} type={type} nowrap={nowrap} icon={icon ? 'true' : false}>
            {icon &&
                <Icon icon={icon} size={iconSize} color={color} />
            }
            {pathToLabel ?
                Array.isArray(pathToLabel) ?
                    <ItemWrapperLine>{pathToLabel.map(i => <Element type={i}>{get(item, i)}</Element>)}</ItemWrapperLine>
                    : renderItem(get(item, pathToLabel))
                : renderItem(item.display || item.label || item.name)
            }
        </ItemWrapper>
    );
};

const ItemWrapper = styled.div`
  ${({theme}) => theme.mixins.robotoRegular()};
  padding: 6px 24px 6px 16px;
  cursor: pointer;
  color: ${({color}) => color};
  ${({theme}) => theme.isMobile && css`
    ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5})};
    color: ${({color}) => color};
    padding: 9.5px 24px 9.5px 16px;
  `}
  ${({icon}) => icon && css`
     display: flex;
     align-items: center;
     
     & > div {
        margin-right: 16px;
     }
  `}

  :hover {
    background-color: ${({theme, type}) => type === 'dark' ? theme.colors.white04 : theme.colors.black04};
  }

  :active {
    background-color: ${({theme}) => theme.colors.primary12};
    ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary})};
  }

  ${({bordered}) => bordered && css`
    border-bottom: 1px solid ${({theme}) => theme.colors.black12};
    padding-bottom: 14px;
    margin-bottom: 8px;
    line-height: 19px;
    box-shadow: inset 0px -8px 0px #fff;
    
     ${({theme}) => theme.isMobile && css`
        padding-bottom: 18px;
     `}
  `}

  ${({active}) => active && css`
    background-color: ${({theme}) => theme.colors.primary12};
    ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary})};
  `}
  
  ${({nowrap}) => nowrap && css`
    white-space: nowrap;
  `}
`

const Element = styled.div``

const ItemWrapperLine = styled.div`
    display: flex;
    
    ${Element} {
        &:first-child {
            ${({theme}) => theme.mixins.robotoRegular()};
            margin-right: 8px;
        }
        &:nth-child(2) {
            ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54})};
        }
    }
`

Item.propTypes = {
    item: PropTypes.object.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default Item;
