export const tableNames = {
    organization_admin_main_filials: "organization_admin_main_filials",
    organization_admin_main_branches: "organization_admin_main_branches",
    organization_admin_statistics: "organization_admin_statistics",
    organization_admin_filials: "organization_admin_filials",
    organization_admin_filial: "organization_admin_filial",
    organization_admin_branches: "organization_admin_branches",
    organization_admin_branches_branch: "organization_admin_branches_branch",
    organization_admin_channels: "organization_admin_channels",
    organization_admin_channels_channel: "organization_admin_channels_channel",
    organization_admin_filial_channel: "organization_admin_filial_channel",
    organization_admin_branch_channel: "organization_admin_branch_channel",
    organization_admin_filial_branch: "organization_admin_filial_branch",
    organization_admin_filial_branch_channel: "organization_admin_filial_branch_channel",
    organization_admin_filial_timeexceeded: "organization_admin_filial_timeexceeded",
    organization_admin_filial_end: "organization_admin_filial_end",
    organization_admin_branches_branch_timeexceeded: "organization_admin_branches_branch_timeexceeded",
    organization_admin_branches_branch_end: "organization_admin_branches_branch_end",
    organization_admin_filial_branch_timeexceeded: "organization_admin_filial_branch_timeexceeded",
    organization_admin_filial_branch_end: "organization_admin_filial_branch_end",
    organization_admin_administrators: "organization_admin_administrators",
    fund_admin_statistics: "fund_admin_statistics",
    fund_admin_organizations: "fund_admin_organizations",
    fund_admin_organization_filials: "fund_admin_organization_filials",
    fund_admin_organization_filial: "fund_admin_organization_filial",
    fund_admin_organization_filial_timeexceeded: "fund_admin_organization_filial_timeexceeded",
    fund_admin_organization_filial_end: "fund_admin_organization_filial_end",
    fund_admin_organization_filial_channel: "fund_admin_organization_filial_channel",
    fund_admin_organization_branches: "fund_admin_organization_branches",
    fund_admin_organization_branches_branch: "fund_admin_organization_branches_branch",
    fund_admin_organization_branches_branch_timeexceeded: "fund_admin_organization_branches_branch_timeexceeded",
    fund_admin_organization_branches_branch_end: "fund_admin_organization_branches_branch_end",
    fund_admin_organization_branches_branch_channel: "fund_admin_organization_branches_branch_channel",
    fund_admin_organization_filial_branch: "fund_admin_organization_filial_branch",
    fund_admin_organization_filial_branch_timeexceeded: "fund_admin_organization_filial_branch_timeexceeded",
    fund_admin_organization_filial_branch_end: "fund_admin_organization_filial_branch_end",
    fund_admin_organization_filial_branch_channel: "fund_admin_organization_filial_branch_channel",
    fund_admin_managers: "fund_admin_managers",
    fund_admin_managers_active: "fund_admin_managers_active",
    fund_admin_managers_archived: "fund_admin_managers_archived",
    fund_admin_channels: "fund_admin_channels",
    fund_admin_channels_channel: "fund_admin_channels_channel",
    fund_admin_managers_mailing_archive: "fund_admin_managers_mailing_archive",
    registrator_main_branches: "registrator_main_branches",
    registrator_main_timeexceeded: "registrator_main_timeexceeded",
    registrator_main_end: "registrator_main_end",
    registrator_main_branch_timeexceeded: "registrator_main_branch_timeexceeded",
    registrator_main_branch_end: "registrator_main_branch_end",
    registrator_doctors: "registrator_doctors",
    registrator_doctors_active: "registrator_doctors_active",
    registrator_doctors_archived: "registrator_doctors_archived",
    registrator_doctors_patients: "registrator_doctors_patients",
    registrator_channels: "registrator_channels",
    registrator_channels_archived: "registrator_channels_archived",
    registrator_channels_channel: "registrator_channels_channel",
    registrator_statistics: "registrator_statistics",
    registrator_statistics_channels: "registrator_statistics_channels",
    registrator_statistics_channels_channel: "registrator_statistics_channels_channel",
    registrator_main_channel: "registrator_main_channel",
    registrator_branch_channel: "registrator_branch_channel",
    super_admin_statistics: "super_admin_statistics",
    super_admin_channels: "super_admin_channels",
    super_admin_channels_channel: "super_admin_channels_channel",
    super_admin_account_channels: "super_admin_account_channels",
    super_admin_account_channels_channel: "super_admin_account_channels_channel",
    super_admin_protocols: "super_admin_protocols",
    super_admin_protocol: "super_admin_protocol",
    super_admin_accounts: "super_admin_accounts",
    super_admin_accounts_active: "super_admin_accounts_active",
    super_admin_events: "super_admin_events",
    super_admin_accounts_archived: "super_admin_accounts_archived",
    super_admin_settings: "super_admin_settings",
    super_admin_mailing_list: "super_admin_mailing_list",
    super_admin_organizations: "super_admin_organizations",
    super_admin_organization_filials: "super_admin_organization_filials",
    super_admin_organization_filial: "super_admin_organization_filial",
    super_admin_organization_filial_timeexceeded: "super_admin_organization_filial_timeexceeded",
    super_admin_organization_filial_end: "super_admin_organization_filial_end",
    super_admin_organization_filial_channel: "super_admin_organization_filial_channel",
    super_admin_organization_branches: "super_admin_organization_branches",
    super_admin_organization_branches_branch: "super_admin_organization_branches_branch",
    super_admin_organization_branches_branch_timeexceeded: "super_admin_organization_branches_branch_timeexceeded",
    super_admin_organization_branches_branch_end: "super_admin_organization_branches_branch_end",
    super_admin_organization_branches_branch_channel: "super_admin_organization_branches_branch_channel",
    super_admin_organization_filial_branch: "super_admin_organization_filial_branch",
    super_admin_organization_filial_branch_timeexceeded: "super_admin_organization_filial_branch_timeexceeded",
    super_admin_organization_filial_branch_end: "super_admin_organization_filial_branch_end",
    super_admin_organization_filial_branch_channel: "super_admin_organization_filial_branch_channel",
    super_admin_event_logs: "super_admin_event_logs",
    global_search_channels: "global_search_channels",
    global_search_organizations: "global_search_organizations",
    global_search_employees: "global_search_employees",
    global_channel: 'global_channel',
    global_doctor_channel: 'global_doctor_channel',
    global_organization_filials: 'global_organization_filials',
    global_organization_branches: 'global_organization_branches',
    global_doctor: 'global_doctor',
    global_branch: "global_branch",
    global_branch_timeexceeded: "global_branch_timeexceeded",
    global_branch_end: "global_branch_end",
    global_branch_channel: "global_branch_channel",
    global_filial: "global_filial",
    global_filial_timeexceeded: "global_filial_timeexceeded",
    global_filial_end: "global_filial_end",
    global_filial_channel: "global_filial_channel",
    global_filial_branch: "global_filial_branch",
    global_filial_branch_timeexceeded: "global_filial_branch_timeexceeded",
    global_filial_branch_end: "global_filial_branch_end",
    global_filial_branch_channel: "global_filial_branch_channel",
    analyst_statistics: "analyst_statistics",
}
