import React from 'react';
import PropTypes from 'prop-types'

const LogoSymbol = ({size}) => (
    <svg width={size?.width || 78} height={size?.height || 66} viewBox="0 0 78 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.48156 19.283C1.55889 19.4378 1.66715 19.6545 1.82182 19.9487C1.49702 19.4533 1.28049 19.1592 1.20316 19.0508C1.51249 19.6855 2.16208 20.9549 3.32207 23.1996C4.43566 25.3514 6.10605 28.5868 8.0703 32.2248C10.0345 35.8627 12.3236 39.9186 14.659 43.7423C16.9945 47.566 19.3918 51.1421 21.5107 54.1762C23.6142 57.2259 25.4701 59.7338 26.7229 61.4366C27.9603 63.124 28.6098 64.0064 28.981 64.4554C29.3832 64.9817 29.476 65.0127 29.5842 64.9972C29.6925 64.9817 29.7853 64.9353 30.4349 64.1922C31.0381 63.4956 32.1826 62.1488 34.3634 59.5325C36.5596 56.9163 39.8231 53.0307 43.5196 48.4639C47.2006 43.9126 51.2992 38.6957 55.1968 33.4323C62.961 22.9364 69.8591 12.2703 73.3854 6.83662L73.4164 6.7747L74.994 4.35973C77.0201 1.21717 77.0665 1.12429 76.9737 1.0314C76.85 0.923038 76.7726 1.00044 74.963 2.84263L74.035 3.77147C72.7204 5.08732 70.7716 7.05335 68.2042 9.62313C65.6213 12.2084 62.4197 15.4129 58.5994 19.2366C54.1606 23.664 48.8865 28.9274 44.231 33.5561C40.8439 36.9309 37.7815 39.9806 35.6162 42.1324C34.317 43.4327 33.3426 44.408 32.6157 45.1356C30.4504 47.2874 30.373 47.3803 30.3266 47.3803C30.3112 47.3803 30.2802 47.3648 30.0018 47.1016C29.6616 46.8075 29.0738 46.2192 27.9603 45.1046C27.3571 44.4854 26.5837 43.7269 25.7021 42.829C23.3512 40.4604 20.1497 37.2405 16.7934 33.9122C14.4889 31.6365 12.1225 29.2989 10.0036 27.2091C7.86923 25.1192 5.99778 23.277 4.68313 21.9921L1.51249 18.896C1.03303 18.4161 0.816496 18.2149 1.18769 18.9889C1.20316 19.0044 1.23409 19.0508 1.29596 19.1127L1.48156 19.283ZM76.7778 1.24952L76.6334 1.38754C76.7236 1.24952 76.7417 1.2298 76.7778 1.24952ZM1.23477 18.9937C1.14763 18.9598 1.14763 19.0276 1.3219 19.1293L1.23477 18.9937Z" fill="#009DE8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.2013 14.1955C24.5535 12.8444 26.4403 12 28.5 12C30.5755 12 32.4465 12.8444 33.8145 14.1955C35.1667 15.5619 36 17.435 36 19.5077C36 21.565 35.1667 23.4381 33.8145 24.8045C32.4465 26.1556 30.5755 27 28.5 27C26.4403 27 24.5535 26.1556 23.2013 24.8045C21.8491 23.4381 21 21.565 21 19.5077C21 17.435 21.8491 15.5619 23.2013 14.1955Z" fill="#96D065"/>
    </svg>
)

LogoSymbol.propTypes = {
    size: PropTypes.object,
}

export default LogoSymbol;
