import React from 'react'; 
import Input from "components/Input/Input";
import CalendarIcon from "../Icons/CalendarIcon";
import {useAnchorPopup} from "../AnchorPopup/useAnchorPopup";
import SimpleCalendar from "../SimpleCalendar";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";

const DateInput = (props) => {
    const template = 'DD MMMM YYYY г.'

    const [showAnchorPopup, hideAnchorPopup] = useAnchorPopup({
        component: SimpleCalendar,
        props: {
            onClick: (value) => {
                props?.onChange(dayjs(`${value?.year}-${value?.month+1}-${value?.day}`).local());
                hideAnchorPopup();
                props?.onBlur && props?.onBlur();
            },
            defaultValue: props?.value ? dayjs(props?.value) : dayjs(),
            fromDate: props.fromDate,
            toDate: props.toDate,
            disableSelectedDateCallback: props.disableSelectedDateCallback
        }
    })

    return (
        <Input
            {...props}
            placeholder={props?.placeholder ? props?.placeholder : ''}
            icon={CalendarIcon}
            onClick={e => {
                props?.onFocus && props?.onFocus();
                showAnchorPopup(e);
            }}
            iconAction={() => {}}
            iconPosition='right'
            cursor='pointer'
            readonly
            dontDisableText
            value={props?.value ? dayjs(props.value).locale(ru).format(template) : ''}
            onChange={() => {}}
        />
    );
};

DateInput.propTypes = {
};

DateInput.defaultProps = {
    disableSelectedDateCallback: true
}

export default DateInput;
