import React, {useEffect, useRef, useState} from 'react'; 
import PropTypes from 'prop-types';
import Icon from "../Icon";
import styled, {css, useTheme} from "styled-components";
import Error from "../Error";
import {Wrapper} from "styled/Wrapper";

const Textarea = ({value, onChange, label, placeholder, icon, iconSize, iconPosition, iconAction, error, type, showError, iconShowOnType, required, focus, height, symbols, onBlur, onFocus, cursor, readonly, resizable, withoutPaddings, maxHeight}) => {

    const theme = useTheme()

    const [focused, setFocused] = useState(false)
    const [currentSymbols, setCurrentSymbols] = useState(value?.length || 0);
    const ref = useRef();

    const handleChange = (e) => {
        setCurrentSymbols(e.target.value.length)
        return onChange(e.target.value)
    }

    useEffect(() => {
        if(iconShowOnType) {
            setShowIcon(!!value)
        }
    }, [iconShowOnType, value])

    const [showIcon, setShowIcon] = useState(true);

    const handleFocus = (focused) => {
        if(focused){
            onFocus && onFocus();
        }else{
            onBlur && onBlur();
        }
        setFocused(focused)
    }

    function calcHeight() {
        let newHeight = 0;
        if (ref.current.value.length === 0 && height) {
            newHeight = height;
        }
        else {
            newHeight = ref.current.scrollHeight;
        }
        return newHeight;
    }

    useEffect(() => {
        if (resizable) {
            let textarea = ref.current;
            if (textarea) {
                textarea.style.height = calcHeight() + "px";
                textarea.addEventListener("keyup", () => {
                    textarea.style.height = calcHeight() + "px";
                });
            }
        }
    }, [])

    useEffect(() => {
        if (resizable) {
            if (!value) {
                let textarea = ref.current;
                if (textarea) {
                    textarea.style.height = calcHeight() + "px";
                }
            }
        }
    }, [value])

    return (
        <Wrapper paddings={label && '22px 0 0 0'}>
            {symbols && (
                <Symbols>
                    {currentSymbols} / {symbols}
                </Symbols>
            )}
            <Field focus={focused} resizable={resizable} withoutPaddings={withoutPaddings}>
                {label && <Label>
                    {label}
                    {required &&
                    <i> *</i>
                    }
                </Label>}
                {icon && showIcon && <IconWrapper position={iconPosition}>
                    <Icon icon={icon} size={iconSize} action={iconAction} color={iconPosition === 'left' && theme.colors.black30} />
                </IconWrapper>}
                <TextareaWrapper position={iconPosition} cursor={cursor} readonly={readonly} height={height} resizable={resizable} withoutPaddings={withoutPaddings} maxHeight={maxHeight}>
                    <textarea ref={ref} maxlength={symbols} autoFocus={focus} type={type} value={value} readonly={readonly} disabled={readonly} onChange={handleChange} placeholder={placeholder} onFocus={() => handleFocus(true)} onBlur={() => handleFocus(false)} />
                </TextareaWrapper>
            </Field>
            <ErrorWrapper>
                <Error message={error} show={showError} />
            </ErrorWrapper>
        </Wrapper>
    );
};

const Symbols = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black30, size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small})};
`

const Field = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.isMobile ? theme.colors.white100 : theme.colors.black04};
  ${({theme}) => theme.mixins.borderRadius()};
  padding: ${({withoutPaddings, resizable, theme}) => withoutPaddings === true ? '0' : !theme.isMobile ? resizable ? '6px 10px 2px 10px' : '6px 10px' : '6px 7px'};
  
  :hover {
    background-color: ${({theme}) => theme.isMobile ? theme.colors.white100 : theme.colors.black08};
  }
`

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};
  position: absolute;
  left: 0;
  top: -22px;
  
  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  ${({position}) => renderIcon(position)};
  width: 18px;
  height: 18px;
`

const calculatePadding = (position) => {
    return position === 'left' ? '0 0 0 24px' : '0 24px 0 0'
}

const TextareaWrapper = styled.div`
  width: 100%;
  position: relative;
  
  textarea {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: ${({withoutPaddings, resizable, position, theme}) => withoutPaddings === true ? (resizable ? '6px 10px 2px 10px' : '6px 10px') : !theme.isMobile ? calculatePadding(position) : '0 7px'};
    ${({theme}) => theme.mixins.robotoRegular()}
    resize: none;
    ${({theme}) => theme.isMobile && css`
        caret-color: ${theme.colors.primary};
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})}
    `};
    max-height: ${({maxHeight}) => maxHeight ? maxHeight : 'none'};
    ${({height}) => height && css`
      min-height: ${({height}) => height && height+'px'};
    `};
    ${({cursor}) => cursor && css`
      cursor: ${({cursor}) => cursor};
    `};
    ${({readonly}) => readonly === true && css`
      color: ${({theme}) => theme.colors.black30};
    `};
    ${({resizable}) => resizable === true && css`
      overflow: ${({maxHeight}) => maxHeight ? 'auto' : 'hidden'};
    `};
    ::placeholder {
      color: ${({theme}) => theme.colors.black30};
    }
  }
`

const ErrorWrapper = styled.div`
  
`

const renderIcon = (position) => {
    switch (position) {
        case 'left':
            return css`
                left: 8px;
                top: 7px;
            `
        case 'right':
            return css`
                right: 8px;
                top: 7px;
            `
        default:
            return css`
                left: 8px;
                top: 7px;
            `
    }
}

Textarea.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.element,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconAction: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    iconShowOnType: PropTypes.bool,
};

Textarea.defaultProps = {
    type: 'text',
    iconPosition: 'right',
    iconAction: 'undefined',
    iconShowOnType: false,
    readonly: false,
    withoutPaddings: false
}

export default Textarea;
