import {get, isEmpty} from "lodash";
import React from "react";
import TableText from "../components/Table/TableText";
import TablePrimaryText from "../components/Table/TablePrimaryText";
import ArrowIcon from "components/Icons/ArrowIcon";
import Icon from "components/Icon";
import { findInTypeBySystem } from "utils";
import { organizationProfileEditTypes } from "consts";

export const findElementByElementId = (arr, elementId, childObjectName) => {
    let targetElement;
    let firstLevelElement = arr.find(item => (item && item.id === elementId));
    if (firstLevelElement) {
        targetElement = firstLevelElement;
    } else {
        arr.forEach(item => {
            if (item.content) {
                targetElement = findElementByElementId(item.content, elementId);
            }
        })
    }
    return targetElement;
}

export const adaptObjectForTable = (item, onClickArrow) => {
    const type = get(item, "organization") ?
        findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code :
        findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code;

    const childAmount = get(item, "content", []).length;
    const getFilials = type => {
        // if (type === "organization") {
        //     return '? фил. ・ ? отд.'
        // } else 
        if (type === "clinic") {
            return childAmount ? childAmount +' отд.' : null
        // } else if (type === "department") {
        //     return '? вр.'
        } else {
            return null
        }
    }

    let profileName = findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.display;
    profileName = profileName ? profileName.charAt(0).toUpperCase() + profileName.slice(1) : '';
    return (
        {
            id: get(item, "id"),
            name: (type === "clinic") && childAmount ? (
                <>
                    <TablePrimaryText
                        text={get(item, "name")}
                        tooltip={get(item, "name")}
                    />
                    <TableText text={getFilials(type)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                    <Icon icon={ArrowIcon} action={e => onClickArrow && onClickArrow(e, item)} rotate={item.open ? '180' : ''}/>
                </>
            ) :
            (
                <>
                    <TablePrimaryText
                        text={get(item, "name")}
                        tooltip={get(item, "name")}
                    />
                    <TableText text={getFilials(type)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                </>
            ),
            section: (
                <TableText text={profileName} type={'secondary'} />
            ),
            part: get(item, "id") || get(item, "id"),
            open: item.open,
        }
    )
}

const { ALLOW_ALL, DISBLED, ALLOW_MULTIPROFILE_AND_SAME_PROFILE, ONLY_CHILD_PROFILE  } = organizationProfileEditTypes;

export const getProfileMode = ({childOrganizations, currentOrganizationProfile, allChlidSameProfile, childOrganizationsProfile}) => {
    if(!isEmpty(childOrganizations)){
        if(currentOrganizationProfile?.code === 'multiprofile'){
            if(allChlidSameProfile && childOrganizationsProfile?.code !== 'multiprofile'){
                return ONLY_CHILD_PROFILE;
            }else{
                return DISBLED;
            }
        }else{
            if(allChlidSameProfile){
                return ALLOW_MULTIPROFILE_AND_SAME_PROFILE;
            }else{
                return DISBLED;
            }
        }
    }else{
        return ALLOW_ALL;
    }
}

export const getProfileOptions = (mode, catalog, childOrganizationsProfile) => {
    switch (mode) {
        case ALLOW_MULTIPROFILE_AND_SAME_PROFILE:
            return catalog?.filter(item => item?.code === 'multiprofile' || item?.code === childOrganizationsProfile?.code);
        case ONLY_CHILD_PROFILE:
            return [childOrganizationsProfile];
        case ALLOW_ALL:
            return catalog;
        default: return catalog;
    }
}