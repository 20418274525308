import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'styled/Wrapper';
import { doctorActions, selectFieldsFilter } from 'reducers/doctor';
import dayjs from 'dayjs';
import Group from 'components/Group';
import Button from 'components/Button';
import NativeDateInput from 'components/NativeDateInput';
import Text from "../../../../Text";
import {useTheme} from "styled-components";

const PeriodSelect = ({hideModal}) => {

    const fieldsFilter = useSelector(selectFieldsFilter);
    const dispatch = useDispatch();
    const theme = useTheme();

    const [activeStartDate, setActiveStartDate] = useState(dayjs(fieldsFilter.startDate));
    const [activeEndDate, setActiveEndDate] = useState(dayjs(fieldsFilter.endDate));

    const handleApply = () => {
        dispatch(doctorActions.setStartDateToFilter(dayjs(activeStartDate).format("YYYY-MM-DD")));
        dispatch(doctorActions.setEndDateToFilter(dayjs(activeEndDate).format("YYYY-MM-DD")));
        dispatch(doctorActions.setActivePeriodItem(3));
        dispatch(doctorActions.setPeriodFilteringLabel('Другое...'));
        hideModal();
    }

  return (
        <Wrapper>
            <Wrapper>
                <Wrapper direction="row" justify={"space-between"} paddings={"0 16px"}>
                    <Wrapper width={'unset'}>
                        <NativeDateInput label={'Дата начала'} dateTemplate='D MMMM YYYY г.' onChange={(value) => setActiveStartDate(dayjs(value))} to={activeEndDate} defaultValue={activeStartDate}/>
                    </Wrapper>
                    <Wrapper width={'unset'}>
                        <NativeDateInput label={'Дата окончания'} dateTemplate='D MMMM YYYY г.' onChange={(value) => setActiveEndDate(dayjs(value))} from={activeStartDate} defaultValue={activeEndDate}/>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper paddings={'14px 4px 8px 4px'}>
                <Group gap={8}  justify="space-between">
                    <Group.Item>
                        <Button type="link" buttonType="link" onClick={() => hideModal()} label={'Отмена'} />
                    </Group.Item>
                    <Group.Item>
                        <Button type="link" buttonType="link" onClick={handleApply} label={'Выбрать'} />
                    </Group.Item>
                </Group>
            </Wrapper>
        </Wrapper>
    )
};

PeriodSelect.propTypes = {
    hideModal: PropTypes.func
};

export default PeriodSelect;
