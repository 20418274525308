import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import {AnchorHeader} from "styled/AnchorHeader";
import {AnchorFooter} from "styled/AnchorFooter";
import styled from "styled-components";
import Group from "components/Group";
import PrimaryButton from "components/Button/PrimaryButton";
import SecondaryButton from "components/Button/SecondaryButton";
import Calendar from "components/Calendar";
import RangePicker from "components/RangePicker";
import {useSelector, useDispatch} from "react-redux";
import dayjs from "dayjs";
import {doctorActions, selectFieldsFilter} from "reducers/doctor";
import { isNil } from 'lodash';

const PeriodSelect = ({handleHide}) => {
    const fieldsFilter = useSelector(selectFieldsFilter);
    const dispatch = useDispatch();

    let defaultDate = new Date(Date.parse(fieldsFilter.startDate))
    defaultDate.setDate(1)

    const [date, setDate] = useState(defaultDate);
    const [activeStartDate, setActiveStartDate] = useState(new Date(Date.parse(fieldsFilter.startDate)));
    const [activeEndDate, setActiveEndDate] = useState(new Date(Date.parse(fieldsFilter.endDate)));
    const [activeField, setActiveField] = useState('startDate');

    const changeVisibleMonth = (direction, month, year) => {
        setDate(state => {
            return new Date(!isNil(year) ? year : state.getFullYear(), !isNil(month) ? month : state.getMonth() + direction, 1);
        })
    }

    const handleClickDay = (date) => {
        if (activeField === 'startDate') {
            setActiveField('endDate')
            setDate(dayjs(activeEndDate).set('date', 1).toDate())
            setActiveStartDate(new Date(date.year, date.month, date.day));
        } else {
            setActiveEndDate(new Date(date.year, date.month, date.day));
        }
    }

    const handleChangeField = (e, fieldName) => {
        if (fieldName !== activeField) {
            setActiveField(fieldName);
            setDate(fieldName === 'startDate' ? dayjs(activeStartDate).set('date', 1).toDate() : dayjs(activeEndDate).set('date', 1).toDate());
        }
    }

    const handleApply = e => {
        dispatch(doctorActions.setStartDateToFilter(dayjs(activeStartDate).format("YYYY-MM-DD")));
        dispatch(doctorActions.setEndDateToFilter(dayjs(activeEndDate).format("YYYY-MM-DD")));
        dispatch(doctorActions.setActivePeriodItem(3));
        dispatch(doctorActions.setPeriodFilteringLabel('Другое...'));
        handleHide(e);
    }

    return (
        <Wrapper>
            <AnchorHeader>
                Выбор периода
            </AnchorHeader>
            <Content>
                <RangeWrapper>
                    <RangePicker activeField={activeField} activeStartDate={activeStartDate} activeEndDate={activeEndDate} handleChangeField={handleChangeField} textAlignInput={'center'} />
                </RangeWrapper>
                <CalendarWrapper>
                    <Calendar onClick={handleClickDay} onChange={changeVisibleMonth} date={date} activeDate={activeField === 'startDate' ? activeStartDate : activeEndDate} /*fromDateForBlur={activeStartDate} activeField={activeField}*/ fromDate={activeField === 'endDate' ? activeStartDate : null} toDate={activeField === 'startDate' ? activeEndDate : null} />
                </CalendarWrapper>
            </Content>
            <AnchorFooter>
                <Group gap={8}>
                    <Group.Item>
                        <PrimaryButton onClick={handleApply} label={'Применить'} />
                    </Group.Item>
                    <Group.Item>
                        <SecondaryButton onClick={handleHide} label={'Отмена'} />
                    </Group.Item>
                </Group>
            </AnchorFooter>
        </Wrapper>
    );
};

const RangeWrapper = styled.div`
    padding: 6px 16px;
    margin-bottom: 12px;
`

const CalendarWrapper = styled.div`
  padding: 0 12px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
`

PeriodSelect.propTypes = {
    handleHide: PropTypes.func.isRequired,
};

export default PeriodSelect;
