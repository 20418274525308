import React from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import Icon from "../Icon";
import {renderBackground} from "utils/renderBackground";
import {renderColor} from "utils/renderColor";
import {useTooltip} from "../Tooltip/useTooltip";

const Label = ({text, type, icon, uppercase, tooltip, maxWidth, tooltipWrap}) => {

    const theme = useTheme()
    const [showTooltip, hideTooltip] = useTooltip({component: tooltip, props: {tooltipWrap: tooltipWrap}})

    return (
        <>
            <LabelWrapper type={type} uppercase={uppercase} onMouseEnter={tooltip && showTooltip} onMouseLeave={tooltip && hideTooltip} maxWidth={maxWidth}>
                {icon && <IconWrapper>
                    <Icon icon={icon} size={18} color={renderColor(theme, type)} />
                </IconWrapper>}
                {text}
            </LabelWrapper>
        </>
    );
};

const LabelWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({theme}) => theme.mixins.robotoCondensedBold({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small})};
  
  background-color: ${({type, theme}) => renderBackground(theme, type)};
  color: ${({type, theme}) => renderColor(theme, type)};
  
  padding: 3px 4px;
  min-width: 27px;
  text-align: center;
  text-transform: ${({uppercase}) => uppercase && 'uppercase'};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  
  ${({theme}) => theme.isMobile && css`
    ${({theme}) => theme.mixins.robotoCondensedBold({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.small})};
    color: ${({type, theme}) => renderColor(theme, type)};
  `}
`

const IconWrapper = styled.div`
  margin-right: 4px;
`

Label.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    icon: PropTypes.element,
};

export default Label;
