import React from 'react';
import PropTypes from 'prop-types';
import Avatar from "components/Avatar";
import styled from "styled-components";

const UserMenu = ({handleHide, username, role, image, menuList}) => {

    const MenuList = menuList

    return (
        <UserMenuWrapper>
            <UserInfo>
                <UserAvatar>
                    <Avatar fullname={username && username} image={image && image} size={'big'} />
                </UserAvatar>
                <UserName>
                    {username}
                </UserName>
                <Role>
                    {role}
                </Role>
            </UserInfo>
            <Menu>
                <MenuList handleHide={handleHide} />
            </Menu>
        </UserMenuWrapper>
    );
};

const Role = styled.div`
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black54})};
    text-align: center;
`

const Menu = styled.div`
    padding: 8px 0;
`

const UserMenuWrapper = styled.div`
    width: 284px;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  ${({theme}) => theme.mixins.innerShadow()};
`

const UserAvatar = styled.div`
    margin-bottom: 12px;
`

const UserName = styled.div`
    white-space: pre-line;
    text-align: center;
    ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})};
`

UserMenu.propTypes = {
    handleHide: PropTypes.func.isRequired,
    username: PropTypes.string,
    role: PropTypes.string.isRequired,
    image: PropTypes.element,
    menuList: PropTypes.array,
};

export default UserMenu;
