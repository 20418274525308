import React from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css} from "styled-components";

const CustomLabel = ({text, subtext, inline}) => {
    return (
        <Wrapper direction={'row'} inline={inline}>
            <Text inline={inline}>
                {text}
            </Text>
            <SubText inline={inline}>
                {subtext}
            </SubText>
        </Wrapper>
    );
};

const Text = styled.div`
    margin-right: 3px;
    
    display: ${({inline}) => inline ? 'inline' : 'flex'};
    ${({inline}) => inline && css`
        margin-right: 12px;
    `};
`

const SubText = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small, color: theme.colors.black30})};\
  display: ${({inline}) => inline ? 'inline' : 'flex'};
  
  ${({inline}) => inline && css`
    ${({theme}) => theme.mixins.robotoCondensedRegular({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.small, color: theme.colors.black54})};
  `}
`

export default CustomLabel;
