import React from 'react'; 
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const CellContext = React.createContext(-1);

const TableCell = ({row, item, width, index, overflow, maxWidth, flex}) => {

    const renderStatistics = (index) => {
        for(let i = 0; i < index; i++) {

        }
        // return <IconWrapper isOpen={isOpen}><Icon icon={ArrowIcon} /></IconWrapper>
    }

    const renderCell = (row, item, index) => {
        if (item.field) {
            return (
                <CellContent>
                    {Object.keys(row).filter(element => element === item.field).map(item => row[item])}
                    {index < 3 && renderStatistics(index)}
                </CellContent>
            )
        } else if (item.component) {
            return (
                <item.component content={row} />
            )
        }
        return null;
    };
    return (
        <CellWrapper width={width} overflow={overflow} flex={flex} maxWidth={maxWidth}>
            <CellContext.Provider value={index}>
                {renderCell(row, item, index)}
            </CellContext.Provider>
        </CellWrapper>
    );
};

const CellWrapper = styled.div`
  width: ${({width}) => width === '100%' ? '1px' : width};
  display: flex;
  flex: ${({width, flex}) => !flex ? width === '100%' ? '1 1 auto' : '0 0 auto' : flex};
  align-items: center;
  margin-right: 16px;
  text-overflow: ellipsis;
  overflow: ${({overflow}) => overflow};
  white-space: nowrap;
  ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ''}
  
  :last-child {
    margin-right: 0;
  }
`

const CellContent = styled.div`
  width: 100%;
  ${({theme}) => theme.mixins.robotoRegular()};
  display: flex;
  align-items: center;
`

// const IconWrapper = styled.div`
//   transform-origin: 50% 50%;
//   transform: rotate(${({isOpen}) => isOpen ? '180' : '0'}deg);
// `

TableCell.propTypes = {
    item: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    width: PropTypes.string,
    index: PropTypes.number,
    isOpen: PropTypes.bool,
};

TableCell.defaultProps = {
    width: '100%',
    overflow: 'hidden'
}

export default TableCell;
