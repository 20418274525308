import React, {useCallback, useEffect, useState} from 'react';
import styled, {css, useTheme} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {hide, unregisterCarouselModal} from "reducers/modalCarousel/modalCarouselSlice";
import ActionIcon from "components/ActionIcon";
import ClearIcon from "components/Icons/ClearIcon";
import {Wrapper} from "styled/Wrapper";
import {debounce} from "utils/debounce";
import {hide as hideTooltip} from 'reducers/tooltip/tooltipSlice'
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import ActivitiesBox from "components/ActivitiesBox";
import Icon from "components/Icon";
import ArrowIcon from "components/Icons/ArrowIcon";
import {get, nth} from "lodash";
import {hide as hideModal, unregisterModal} from 'reducers/modal/modalSlice'
import fileDownload from 'react-file-download';
import { getFileByUrl } from 'utils';
import { CustomView, isDesktop, isTablet, MobileOnlyView } from 'react-device-detect';
import ShowMessageIcon from 'components/Icons/ShowMessageIcon';
import ReplyIcon from 'components/Icons/ReplyIcon';
import ShareIcon from 'components/Icons/ShareIcon';
import { useChatReply } from 'hooks/useCharReply';

const ModalCarousel = () => {

    const [reducer, setReducer] = useState({});
    const [openedModal, setOpenedModal] = useState();

    const {
        selectCarousel = () => {},
        actions = null
    } = reducer;

    const theme = useTheme();
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight)
    const modal = useSelector(state => state.modalCarousel)
    const dispatch = useDispatch();
    const carousel = useSelector(selectCarousel);
    const [activeMessage, setActiveMessage] = useState(null);
    const [menuActions, setMenuActions] = useState(null);
    const [showLeftArrow, setShowLeftArrow] = useState(true);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const handleReply = useChatReply();

    useEffect(() => {
        setActiveMessage(carousel?.carouselImages.find((item, index) => {

            if(item.id === carousel?.activeMessageId){
                if(index === 0){
                    setShowLeftArrow(carousel?.activeImageIndex !== 0);
                }else{
                    setShowLeftArrow(true);
                }
                if(index === (carousel?.carouselImages.length - 1)){
                    setShowRightArrow(carousel?.activeImageIndex !== (item?.images?.length - 1));
                }else{
                    setShowRightArrow(true)
                }
            }

            return item.id === carousel?.activeMessageId
        }));
    }, [carousel?.carouselImages, carousel?.activeMessageId, carousel?.activeImageIndex]);

    const handleHide = (name) => {
        dispatch(unregisterCarouselModal(name))
        dispatch(hide(name))
    }

    const handleHideAll = (name) => {
        dispatch(unregisterCarouselModal(name))
        dispatch(hide(name))
        dispatch(unregisterModal())
        dispatch(hideModal())
    }

    const handleDownloadClick = (item) => {
        item?.url && item?.title &&
            getFileByUrl(item.url).then(response => {
                const buffer = Buffer.from(response.data, 'binary');
                fileDownload(buffer, item.title, response?.headers["content-type"])
            })
    }

    useEffect(() => {
        setMenuActions([
            {
                id: 0,
                label: 'Показать в чате',
                color: 'white100',
                action: (e) => {
                    dispatch(actions.setImageSlideId(carousel?.activeMessageId));
                    dispatch(actions.setMatchSlideId(carousel?.activeMessageId));
                    handleHideAll(openedModal?.name);
                },
                nowrap: true
            },
            {
                id: 1,
                label: 'Сохранить...',
                color: 'white100',
                action: () => handleDownloadClick(get(activeMessage, `images[${carousel?.activeImageIndex}]`)),
                nowrap: true
            },
        ])
    }, [activeMessage, carousel?.activeImageIndex]);

    const checkVisible = () => {
        return modal.filter(item => item.isOpen && true).length > 0
    }

    const calculateScrollHeight = useCallback(() => {
        return windowHeight - 60 - 64 + 'px'
    }, [windowHeight])

    useEffect(() => {

        const debouncedHandleResize = debounce(() => {
            setWindowHeight(window.innerHeight)
        }, 50)

        window.addEventListener('resize', debouncedHandleResize)
        calculateScrollHeight()
        return () => window.removeEventListener('resize', debouncedHandleResize)
    })

    useEffect(() => {
        modal.forEach(item => {
            if(item.isOpen) {
                setReducer(item?.modalProps?.reducer);
                setOpenedModal(item);
            }
        })
    }, [modal]);

    const renderContent = (item) => {
        if(item.component) {
            if(typeof item.component === 'string') return item.component
            return <item.component {...item.modalProps} hideModal={() => handleHide(item.name)} />
        }
    }

    const handleClose = (name) => {
        dispatch(hideTooltip())
        handleHide(name)
    }

    const handlePrevious = () => {
        dispatch(actions.setPreviousImage());
    }

    const handleNext = () => {
        dispatch(actions.setNextImage());
    }

    const handleShare = () => {
        const image = nth(get(activeMessage, 'images', []), carousel?.activeImageIndex);
        image?.url &&
            getFileByUrl(image?.url, null, 'blob').then(response => {
                const file = new File([response.data], image?.title, {type: response?.data?.type});
                if(navigator.canShare && window.isSecureContext){
                    navigator.share({
                        title: image?.title,
                        files: [file]
                    }).then(() =>{
                        console.log('Sharing success');
                    }).catch((e) => {
                        console.error('Sharing failed', e);
                    })
                }else{
                    console.error('Browser dont support sharing or your web application dont use secure context');
                    //Если браузер не поддерживает шаринг, то скачиваем
                    fileDownload(response.data, image?.title, response?.headers["content-type"])
                }
            })
    }

    const handleMessageReply = () => {
        handleReply(carousel?.activeMessageId);
        handleHideAll(openedModal?.name);
    }

    const handleShowInChat = () => {
        dispatch(actions.setImageSlideId(carousel?.activeMessageId));
        dispatch(actions.setMatchSlideId(carousel?.activeMessageId));
        handleHideAll(openedModal?.name);
    }

    return (
        <Container isVisible={checkVisible()}>
            {modal.map(item => item.isOpen && (
                <>
                    <CustomView condition={isDesktop || isTablet}>
                        <ActionIconWrapper>
                            <ActivitiesBox actions={menuActions} data={item.name} size={'79.9px'} iconSize={'32'} iconColor={theme.colors.white100} colorHover={theme.colors.black24} borderRadius={'0'} type={'dark'} />
                            <ActionIcon action={() => handleClose(item.name)} icon={ClearIcon} tooltip={'Закрыть'} size={'80px'} colorHover={theme.colors.black24} iconColor={theme.colors.white100} borderRadius={'0'} />
                        </ActionIconWrapper>
                        {showLeftArrow && <Arrow onClick={handlePrevious}><Icon icon={ArrowIcon} size={32} color={theme.colors.white70} rotate={'90'} /></Arrow>}
                        {showRightArrow && <Arrow onClick={handleNext} right><Icon icon={ArrowIcon} size={32} color={theme.colors.white70} rotate={'-90'} /></Arrow>}
                        <ModalWrapper>
                            <Wrapper>
                                <PerfectScrollbarWrapper>
                                    {renderContent(item)}
                                </PerfectScrollbarWrapper>
                            </Wrapper>
                        </ModalWrapper>
                    </CustomView>
                    <MobileOnlyView>
                        <CornerMobileIconWrapper top left >
                            <ActionIcon action={() => handleClose(item.name)} icon={ClearIcon} tooltip={'Закрыть'} size={'24px'} colorHover={theme.colors.black24} iconColor={theme.colors.white100} borderRadius={'0'} />
                        </CornerMobileIconWrapper>
                        <CornerMobileIconWrapper top right >
                            <ActionIcon action={() => handleShowInChat()} icon={ShowMessageIcon} tooltip={'Показать в чате'} size={'24px'} colorHover={theme.colors.black24} iconColor={theme.colors.white100} borderRadius={'0'} />
                        </CornerMobileIconWrapper>
                        <CornerMobileIconWrapper bottom left >
                            <ActionIcon action={() => handleMessageReply()} icon={ReplyIcon} tooltip={'Ответить'} size={'24px'} colorHover={theme.colors.black24} iconColor={theme.colors.white100} borderRadius={'0'} />
                        </CornerMobileIconWrapper>
                        <CornerMobileIconWrapper bottom right >
                            <ActionIcon action={() => handleShare()} icon={ShareIcon} tooltip={'Ответить'} size={'24px'} colorHover={theme.colors.black24} iconColor={theme.colors.white100} borderRadius={'0'} />
                        </CornerMobileIconWrapper>
                        {showLeftArrow && <Arrow onClick={handlePrevious}><Icon icon={ArrowIcon} size={32} color={theme.colors.white70} rotate={'90'} /></Arrow>}
                        {showRightArrow && <Arrow onClick={handleNext} right><Icon icon={ArrowIcon} size={32} color={theme.colors.white70} rotate={'-90'} /></Arrow>}
                        <ModalWrapperMobile>
                            <Wrapper>
                                <PerfectScrollbarWrapper>
                                    {renderContent(item)}
                                </PerfectScrollbarWrapper>
                            </Wrapper>
                        </ModalWrapperMobile>
                    </MobileOnlyView>
                </>
            ))}
            <CustomView condition={isDesktop || isTablet}>
                <Overlay />
            </CustomView>
        </Container>
    );
};

const CornerMobileIconWrapper = styled.div`
    position: absolute;
    display: flex;
    z-index: 9999;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    ${({top}) => top && css`
        top: 0;
    `}
    ${({bottom}) => bottom && css`
        bottom: 0;
    `}
    ${({left}) => left && css`
        left: 0;
    `}
    ${({right}) => right && css`
        right: 0;
    `}
`

const ActionIconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    pointer-events: all;
    display: flex;
    align-items: center;
`

const Arrow = styled.div`
    width: 80px;
    height: calc(100% - 80px);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    cursor: pointer;
    pointer-events: all;
    
    &:hover {
        background-color: ${({theme}) => theme.colors.black24};
    }
    ${({right}) => right && css`
        left: unset;
        right: 0;
    `};
`

    // max-width: 100%;
    // max-height: calc(100vh - 155px);

const ModalWrapper = styled.div`
  pointer-events: all;
  ${({theme}) => theme.mixins.shadowDark24dp()};
  position: absolute;
  z-index: 1000;
  max-height: calc(100vh - 155px);
  height: auto;
  width: auto;
  max-width: calc(100vw - 200px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  img {
    max-width: calc(100vw - 200px);
    max-height: calc(100vh - 155px);
    height: auto;
    width: auto;
    object-fit: contain;
  }
`

const ModalWrapperMobile = styled.div`
  pointer-events: all;
  ${({theme}) => theme.mixins.shadowDark24dp()};
  z-index: 1000;
  max-height: 100vh;
  height: auto;
  width: auto;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  img {
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    width: auto;
    object-fit: contain;
  }
`

const Container = styled.div`
  display: ${({isVisible}) => isVisible ? 'flex' : 'none'};
  background-color: ${({theme}) => !theme.isMobile ? theme.colors.black54 : theme.colors.black100};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: ${({theme}) => !theme.isMobile ? 'none' : 'unset'};
  z-index: 1000;
  
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 32, 51, 0.24);
  z-index: 999;
  pointer-events: all;
`

export default ModalCarousel;
