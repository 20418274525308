import PropTypes from 'prop-types';
import {withButtonStyle} from "hocs/withButtonStyle";
import Button from "../index";

const SecondaryButton = (props) => {
    return withButtonStyle('secondary', {...props})(Button)
};

SecondaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default SecondaryButton;
