import React, { useEffect, useRef, useState } from 'react'; 
import PropTypes from 'prop-types';
import {withButtonStyle} from "hocs/withButtonStyle";
import Button from "components/Button";
import styled, {css, useTheme} from 'styled-components'
import ArrowIcon from "components/Icons/ArrowIcon";

const SelectButton = ({ label, active, component, componentProps, buttonType, withoutPaddings, withoutIcon, anchorZIndex}) => {

    const [visible, setVisible] = useState(false)
    const [listHovered, setListHovered] = useState(false)
    const [topCorrection, setTopCorrection] = useState(0);

    const ref = useRef();

    const Menu = component;

    const handleChange = (e) => {
        if(visible) {
           setVisible(false);
        } else {
            setVisible(true);
        }
    }

    useEffect(() => {
        if(visible && ref?.current){
            const bottom = ref.current.getBoundingClientRect()?.bottom;
            if(bottom > window.innerHeight){
                setTopCorrection(window.innerHeight - bottom);
            }
        }
    }, [ref, visible])
    

    return (
        <ButtonWrapper tabIndex='0' onBlur={() => !listHovered && setVisible(false)} >
            {withButtonStyle(buttonType ? buttonType : 'secondary', {
                onClick: handleChange,
                label,
                icon: !withoutIcon && ArrowIcon,
                rotate: visible && 180,
                active: visible,
                withoutPaddings: withoutPaddings
            })(Button)}
            {visible &&
                <FloatingMenuWrapper ref={ref} visible={visible} topCorrection={topCorrection} onMouseEnter={() => setListHovered(true)} onMouseLeave={() => setListHovered(false)} anchorZIndex={anchorZIndex} >
                    <Menu {...componentProps} active={active} scrollToActiveElement handleHide={() => setVisible(false)} />
                </FloatingMenuWrapper>
            }
        </ButtonWrapper>
    );
};

const FloatingMenuWrapper = styled.div`
    position: absolute;
    background: ${({theme}) => theme.colors.white100};
    border-radius: ${({theme}) => theme.other.borderRadius};
    ${({theme}) => theme.mixins.shadowDark8dp()};
    top: ${({topCorrection}) => `${42 + topCorrection}px`};
    ${({anchorZIndex}) => anchorZIndex && css`
        z-index: ${anchorZIndex};
    `}
`

const ButtonWrapper = styled.div`
  position: relative;
`

SelectButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    component: PropTypes.element.isRequired,
    componentProps: PropTypes.any,
};

export default SelectButton;
