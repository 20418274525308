import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";

const SimpleCancelEventModal = () => {

    return (
        <Wrapper paddings={'0 8px 12px 24px'} maxHeight={'calc(100vh - 124px)'}>
            <PerfectScrollbarWrapper paddings={'0 16px 0 0'}>
                <Text text={'Отменить выбранное мероприятие?'} />
            </PerfectScrollbarWrapper>
        </Wrapper>
    );
};

SimpleCancelEventModal.propTypes = {
    hide: PropTypes.func.isRequired,
};

export default SimpleCancelEventModal;
