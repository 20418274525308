import React from 'react';

const ShareIcon = () => (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 7.24989V13.9999C12.5 14.8249 11.825 15.4999 11 15.4999H2C1.175 15.4999 0.5 14.8249 0.5 13.9999V7.24989C0.5 6.41739 1.175 5.74989 2 5.74989H3.5C3.91421 5.74989 4.25 6.08567 4.25 6.49989C4.25 6.9141 3.91421 7.24989 3.5 7.24989H2V13.9999H11V7.24989H9.5C9.08579 7.24989 8.75 6.9141 8.75 6.49989C8.75 6.08567 9.08579 5.74989 9.5 5.74989H11C11.825 5.74989 12.5 6.41739 12.5 7.24989Z"/>
        <path d="M9.72074 4.02915C9.42884 4.32105 8.95571 4.32151 8.66324 4.03017L7.25 2.62239V9.12489C7.25 9.5391 6.91421 9.87489 6.5 9.87489C6.08579 9.87489 5.75 9.5391 5.75 9.12489V2.62239L4.33676 4.03017C4.04429 4.32151 3.57116 4.32105 3.27926 4.02915C2.98696 3.73685 2.98696 3.26293 3.27926 2.97063L5.96967 0.280217C6.26256 -0.0126765 6.73744 -0.0126764 7.03033 0.280217L9.72074 2.97062C10.013 3.26293 10.013 3.73685 9.72074 4.02915Z"/>
    </svg>
)

export default ShareIcon;
