import {BASE_URL, mskUtcOffset, tripleCheckboxState} from "consts";
import {api} from "consts/api";
import { get, find, isEmpty, last, filter, every, cloneDeep, join, isArray } from "lodash";
import { fetchInstance } from "wrappers/axios";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const capitalizeFirstLetter =(string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getParameterByName = (name, url = window.location.href) => {
    // name = name.replace(/[\[\]]/g, '\\$&');
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const declOfNum = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export const sortTableFieldsById = (fields) => {
    const compare = (field1, field2) => field1.id > field2.id ? 1 : field1.id < field2.id ? -1 : 0
    return fields.sort(compare);
}

export const getImageUrl = (img, token) => {
    return `${BASE_URL}${api.imageUpload}/${img}?token=${token}`;
}

export const getIdFromUrl = (url, prefix) => {
    let urlCopy = url.split(prefix);
    return urlCopy[urlCopy.length - 1];
}
export const getProtocolIdFromUrl = (url) => {
    let urlCopy = url.split('/');
    return urlCopy[urlCopy.length - 1].replace("protocol-", "");
}

export const getFileByUrl = (url, token, type = 'arraybuffer') => {
    return fetchInstance({
        method: 'GET',
        url: `${BASE_URL}${api.imageUpload}/${url}${token ? `?token=${token}`:''}`,
        responseType: type
    })
}

export const getFileByNotMinioUrl = (url, token) => {
    return fetchInstance({
        method: 'GET',
        url: `${BASE_URL}${url}${token ? `?token=${token}`:''}`,
        responseType: 'blob'
    })
}

export const clearFileTypeName = (dirtyName) => {
    if (dirtyName.indexOf("application/") !== -1) {
        return dirtyName.replace("application/", "");
    } else if (dirtyName.indexOf("image/") !== -1) {
        return dirtyName.replace("image/", "");
    } else {
        return dirtyName
    }
}

export const adaptPatientsForChatList = (arr, activeId) => {
    let result = [];
    const today = dayjs();

    // console.log("arr", arr);

    arr.forEach(item => {
        const difference = get(item, "lastCommunicationFromPatientTime") ? today.diff(get(item, "lastCommunicationFromPatientTime"), 'day') : null;
        // console.log("item", item);
        result.push({
            id: get(item, "id"),
            name: get(item, "patientName"),
            time: difference ? dayjs(get(item, "conditionOnSetDateTime")).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMM') : dayjs(get(item, "conditionOnSetDateTime")).utc().add(mskUtcOffset, 'hour').format('H:mm'),
            sex: get(item, "patientGender") === 'Male' ? 'М' : 'Ж',
            birthDate: dayjs(get(item, "patientBirthDate")).format('DD.MM.YYYY'),
            age: `${get(item, "patientAge")} ${declOfNum(get(item, "patientAge"), ['год', 'года', 'лет'])}`,
            code: get(item, "conditionName"),
            amount: get(item, "notAnsweredAndNotOverdue") || get(item, "notAnsweredAndOverdue"),
            isTaskOverdue: get(item, "isTaskOverdue"),
            createdAt: get(item, "createdAt"),
            answered:  get(item, "answered"),
            notAnsweredAndAnsweredOverdue:  get(item, "notAnsweredAndAnsweredOverdue"),
            notAnsweredAndNotOverdue:  get(item, "notAnsweredAndNotOverdue"),
            notAnsweredAndOverdue:  get(item, "notAnsweredAndOverdue"),
            notAnsweredOrAnsweredOverdue:  get(item, "notAnsweredOrAnsweredOverdue"),
            patientPhone: get(item, "patientPhone"),
            patientEmail: get(item, "patientEmail"),
            status: get(item, "status"),
            patientStatus: get(item, "patientStatus", "") === "true" ? "active" : "awaiting",
            patientId: get(item, "patientId"),
            carePlanId: get(item, "carePlanId"),
            onSetDateTime: get(item, "conditionOnSetDateTime"),
            notAnswered: get(item, "notAnswered"),
            lastCommunicationFromPatientTime: difference === null ? null : difference ? dayjs(get(item, "lastCommunicationFromPatientTime")).utc().add(mskUtcOffset, 'hour').locale(ru).format('D MMM') : dayjs(get(item, "lastCommunicationFromPatientTime")).utc().add(mskUtcOffset, 'hour').format('H:mm'),
            // lastCommunicationFromPatientTime: difference ? dayjs(get(item, "LastCommunicationFromPatientTime")).locale(ru).format('D MMM') : dayjs(get(item, "LastCommunicationFromPatientTime")).format('H:mm'),
            totalFromPatient: get(item, "totalFromPatient"),
            totalFromPractitioner: get(item, "totalFromPractitioner"),
            // active: get(item, "status") === "finished" ? false : true,
            consultationPeriod: get(item, 'consultationPeriod'),
            practitionerRoleConsultationPeriod: get(item, "practitionerRoleConsultationPeriod"),
            weekOfPregnancy: get(item, "week"),
            weekOfPregnancyConstant: get(item, "weekNow") || get(item, "week"),  // Не меняется в зависимости от дату Родов
            dateOfChildbirth: get(item, "dateOfChildbirth"),
            consentId: get(item, "consentId"),
            conditionId: get(item, "conditionId"),
        })
    });

    return result;
}

export const definePayloadType = (payload) => {
    const hasLinks = payload.find(item => {
        return (get(item, "content.value", '').indexOf("http://") > -1) || (get(item, "content.value", '').indexOf("https://") > -1)
    });

    const hasFiles = payload.find(item => {
        if (get(item, "content.urlElement")) {
            const extention = get(item, "content.titleElement.value", '').split('.').pop();
            if (
                extention !== 'jpg' &&
                extention !== 'jpeg' &&
                extention !== 'png' &&
                extention !== 'git'
            ) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    });

    const hasImages = payload.find(item => {
        if (get(item, "content.urlElement")) {
            const extention = get(item, "content.titleElement.value", '').split('.').pop();
            if (
                extention === 'jpg' ||
                extention === 'jpeg' ||
                extention === 'png' ||
                extention === 'git'
            ) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    });

    if (hasLinks && !hasFiles && !hasImages) {
        return 'link';
    }

    if (hasFiles) {
        return 'file';
    }

    if (hasImages) {
        return 'image';
    }

    return 'text';
}

export const findInTypeBySystem = (type, system) => {
    return find(find(type, {
        coding: [
            { system }
        ]
    })?.coding, {
        system
    })
}

export const findInTypeBySystemElement = (type, system) => {
    return find(find(type, {
        coding: [
            {
                systemElement: {
                    value: system
                }
            }
        ]
    })?.coding, {
        systemElement: {
            value: system
        }
    })
}

export const findInContactBySystem = (contact, system) => {
    return find(find(contact, {
        telecom: [
            { system }
        ]
    })?.telecom, {
        system
    })
}

export const findRoleStatusBySystem = (arr, system) => {
    return arr.find(item => {
        return item?.valueCoding?.system === system;
    })?.valueCoding?.code;
}

export const getFileSizeInMb = (size) => {
    return (Math.floor((size / 1000000)* 100) / 100) + ' МБ'
}

export const findInExpiredBySystem = (extension, system) => {
    const extensionCopy = extension ? extension : [];
    return extensionCopy.find(item => item.url === system)?.value?.value;
}

export const findMessageCategory = (category, system) => {
    const categoryCopy = category ? category : [];
    let result;
    categoryCopy.forEach(item => {
        if (item.coding) {
            result = item.coding.find(itm => itm.systemElement.value === system);
        }
    });
    return result?.codeElement?.value;
}

export const createTrimesterFilterParams = (filterStateItems) => {
    let amount = 0;
    const codes = (filterStateItems && !isEmpty(filterStateItems)) ? filterStateItems.reduce((acc, item) => {
        if (item.checked) amount++;
        return item.checked ? acc ? `${acc},${item.code}` : `${item.code}` : acc
    }, null) : undefined;
    return filterStateItems.length === amount ? '' : codes
}

export const getFileSizeInKb = value => {
    return (value / 1000).toFixed(1) + " КБ";
}

export const getMimeTypeByExtension = ext => {
    if (ext === '.jpg' || ext === '.jpeg') return "image/jpeg";
    if (ext === '.pdf') return "application/pdf";
}

export const getStringEnding = number => {
    const str = number.toString();
    const last = str.slice(str.length - 1);
    const lastTwo = str.length > 1 && str.slice(str.length - 2);
    if (
        last === "2" ||
        last === "3" ||
        last === "4"
    ) {
        return "а"
    } else if (
        last === "5" ||
        last === "6" ||
        last === "7" ||
        last === "8" ||
        last === "9" ||
        last === "0" ||
        lastTwo === "11" ||
        lastTwo === "12" ||
        lastTwo === "13" ||
        lastTwo === "14"
    ) {
        return "ов"
    } else if (last === "1") {
        return ""
    } else {
        return ""
    }
}

export const createSavedItemsParams = (items) => {
    let result = '';
    items?.forEach(item => {
        if (item?.code) {
            if (result) {
                result = result + ", " + item?.code;
            } else {
                result = item?.code;
            }
        }
    })
    return result;
}

export const getFileEnding = number => {
    const str = number.toString();
    const last = str.slice(str.length - 1);
    const lastTwo = str.length > 1 && str.slice(str.length - 2);
    if (
        last === "2" ||
        last === "3" ||
        last === "4"
    ) {
        return "файла"
    } else if (
        last === "5" ||
        last === "6" ||
        last === "7" ||
        last === "8" ||
        last === "9" ||
        last === "0" ||
        lastTwo === "11" ||
        lastTwo === "12" ||
        lastTwo === "13" ||
        lastTwo === "14"
    ) {
        return "файлов"
    } else if (last === "1") {
        return "файл"
    } else {
        return "файл"
    }
}

export const getImagesEnding = number => {
    const str = number.toString();
    const last = str.slice(str.length - 1);
    const lastTwo = str.length > 1 && str.slice(str.length - 2);
    if (
        last === "2" ||
        last === "3" ||
        last === "4"
    ) {
        return "изображения"
    } else if (
        last === "5" ||
        last === "6" ||
        last === "7" ||
        last === "8" ||
        last === "9" ||
        last === "0" ||
        lastTwo === "11" ||
        lastTwo === "12" ||
        lastTwo === "13" ||
        lastTwo === "14"
    ) {
        return "изображений"
    } else if (last === "1") {
        return "изображение"
    } else {
        return "изображение"
    }
}

export const getTrimesterByWeeks = (weekNum, weekOfPregnancyData) => {
    let week;
    if(weekNum <= 43){
        week = weekOfPregnancyData && weekOfPregnancyData.find(item => item.code === weekNum);
    }else{
        week = last(weekOfPregnancyData)
    }
    return week && week?.property["week-of-pregnancy"]["trimester-of-pregnancy"]
}

/**
 * @param {object} tableSettings - Настройки таблицы в сторе конкретного экрана
 * @param {object} tableFilters - Настройки фильтров из стора фильтров таблиц
 * @param {array} paramsMapping - Маппинг полей между стором таблицы и стором фильтров
 * @returns {boolean}
 * @description Функция сравнивает фильтра в tableSettings c настройками в tableFilters по заданному маппингу, и при их совпадении по количеству, возвращает true.
 * Предназначена для исключения повторных запросов данных таблицы, во время инициализации фильтров.
 */
export const filtersInitialized = (tableSettings, tableFilters, paramsMapping) => {
    // console.log(tableSettings, tableFilters, paramsMapping)
    return paramsMapping.every(mapping => {
        const setting = get(tableSettings, `fieldsFilter.${mapping?.name}`)?.split(',');
        // console.group(mapping?.name);
        // console.log('Settings: ', setting)
        let filterItems = get(tableFilters, mapping.filter);
        // console.log('FilterItems: ', filterItems)
        // console.log(isEmpty(setting) && isEmpty(filter))
        // console.groupEnd();
        if(isEmpty(setting) && isEmpty(filterItems)) {
            if(mapping.checked){
                return false
            }else{
                return true
            }
        }

        if(mapping.checked && !isEmpty(filterItems) && every(filterItems, {checked: false}) && isEmpty(setting)) return true;
        if(mapping.checked && !isEmpty(filterItems) && !isEmpty(setting) && every(filterItems, {checked: false}) && setting?.length === filterItems?.length) return true;
        if(isEmpty(setting) || isEmpty(filterItems)) return false;
        return !mapping.checked ? setting?.length === filterItems?.length : setting?.length === filter(filterItems, 'checked')?.length
    })
}

export const formatSize = (length) => {
	var i = 0, type = ['Б','КБ','МБ','ГБ','Тб','ПБ'];
	while((length / 1000 | 0) && i < type.length - 1) {
		length /= 1024;
		i++;
	}
	return length.toFixed(0) + ' ' + type[i];
}

export const handleFileLoaded = (e, onChange) => {
    const { files } = e.target;
    if (files[0]) {
        if ((get(files[0], "type", "").includes('image/jpeg') ||
        get(files[0], "type", "").includes('image/png') ||
        get(files[0], "type", "").includes('application/pdf')) &&
        (get(files[0], "size", "") < 25000000)) {
            const localImageUrl =  window.URL.createObjectURL(files[0]);
            onChange(files[0], localImageUrl)
        } else {
            onChange(null, null);
        }

    } else {
        onChange(undefined);
    }
}

/**
 * Получение родительских элементов в иерархии чекбоксов
 * @param {Object} item Элемент для которого необходимо найти родителей
 * @param {Array} allItemsCopy Все элементы иерархии чекбоксов
 * @returns {Array} Массив с родительскими элементами
 */
export const getHierarchyCheckboxParents = (item, allItemsCopy) => {
    const parents = [];

    const findPath = (item, allItemsCopy, parentPath) => {

        let result;

        allItemsCopy.forEach((element, index) => {
            let copyEl = cloneDeep(element);
            if(parentPath) copyEl.parentPath = parentPath;
            if(copyEl?.id === item?.id){
                result = parentPath ? `${parentPath}.content[${index}]` : `[${index}]`;
            }else{
                const filteredContent = copyEl.content && findPath(item, copyEl.content, parentPath ? `${parentPath}.content[${index}]` : `[${index}]`);
                if(!isEmpty(filteredContent)) result = filteredContent;
            }
        });

        return result;
    }

    const pathToElement = findPath(item, allItemsCopy);
    let splittedPath = pathToElement && pathToElement.split('.content');
    splittedPath && splittedPath?.splice(-1, 1);

    const parentPaths = !isEmpty(splittedPath) && splittedPath.map((item, index) => {
        if(index > 0) {
            let copy = cloneDeep(splittedPath);
            copy.splice(index + 1, copy.length);
            return join(copy, '.content');
        } else {
            return item;
        }
    })

    if(isArray(parentPaths) && !isEmpty(parentPaths)){
        parentPaths.forEach(path => {
            const parent = get(allItemsCopy, path);
            if(parent){
                parents.push(parent);
            }
        })
    }

    return parents;
}

/**
 * Обновление статусов родителей в иерархии чекбоксов
 * @param {Array} parents Массив родительских элементов
 */
export const updateHierarchyCheckboxParentsStatus = (parents) => {
    parents.forEach(item => {
        if(every(item?.content, ['checked', tripleCheckboxState.CHECKED])){
            item.checked = tripleCheckboxState.CHECKED
        }else if(every(item?.content, ['checked', tripleCheckboxState.UNCHECKED])) {
            item.checked = tripleCheckboxState.UNCHECKED
        }else{
            item.checked = tripleCheckboxState.PARTICALLY_CHECKED
        }
    });
}

/**
 * Поиск ссылки на элемент в иерархии чекбоксов (сравнение ведется по id)
 * @param {object} item Исковый элемент 
 * @param {Array} items Все элементы иерархии чекбоксов
 * @returns {object} Объект из иерархии чекбоксов
 */
export const findHierarchyCheckboxItems = (item, items) => {

    let result;

    items.forEach((element) => {
        if(element?.id === item?.id){
            result = element;
        }else{
            const filteredContent = element.content && findHierarchyCheckboxItems(item, element.content);
            if(!isEmpty(filteredContent)) result = filteredContent;
        }
    });

    return result;
}