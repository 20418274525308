import React from 'react';

const HideIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.5303 2.46967C3.23741 2.17678 2.76253 2.17678 2.46964 2.46967C2.17675 2.76256 2.17675 3.23744 2.46964 3.53033L3.70074 4.76143C2.54604 5.62571 1.60864 6.76526 0.984948 8.08259C0.8224 8.42592 0.8224 8.82408 0.984948 9.16741C2.40688 12.1708 5.4594 14.25 8.99997 14.25C10.2353 14.25 11.4111 13.9969 12.4791 13.5398L14.4696 15.5303C14.7625 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L3.5303 2.46967ZM11.3197 12.3804L10.5575 11.6182C10.0912 11.862 9.5613 12 8.99997 12C7.13997 12 5.62497 10.485 5.62497 8.625C5.62497 8.06367 5.76295 7.53377 6.00676 7.06745L4.76792 5.82861C3.76935 6.52947 2.94452 7.48216 2.38497 8.625C3.62247 11.1525 6.15747 12.75 8.99997 12.75C9.80541 12.75 10.5862 12.6217 11.3197 12.3804ZM7.16717 8.22786C7.13953 8.35588 7.12497 8.48875 7.12497 8.625C7.12497 9.66 7.96497 10.5 8.99997 10.5C9.13622 10.5 9.26909 10.4854 9.39711 10.4578L7.16717 8.22786Z"/>
        <path d="M17.015 9.16741C16.5318 10.1879 15.8604 11.1018 15.0455 11.8636L13.9911 10.8091C14.6511 10.1997 15.2044 9.46351 15.615 8.625C14.3775 6.0975 11.8425 4.5 8.99997 4.5C8.58626 4.5 8.17906 4.53384 7.78143 4.59946L6.53103 3.34906C7.31462 3.12181 8.143 3 8.99997 3C12.5405 3 15.5931 5.07925 17.015 8.08259C17.1775 8.42592 17.1775 8.82408 17.015 9.16741Z"/>
        <path d="M12.375 8.625C12.375 8.80418 12.3609 8.98015 12.3338 9.15188L8.4731 5.29113C8.64482 5.26406 8.8208 5.25 8.99997 5.25C10.86 5.25 12.375 6.765 12.375 8.625Z" />
    </svg>
)

export default HideIcon;
