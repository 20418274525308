import React from 'react';
import {Wrapper} from "styled/Wrapper";

const Page404 = () => {
    return (
        <Wrapper fullsize align={'center'} justify={'center'}>
            page 404
        </Wrapper>
    );
};

export default Page404;
