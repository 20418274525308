import React, {useEffect, useState} from 'react';
import {Wrapper} from 'styled/Wrapper';
import BottomBarMenu from 'components/BottomBarMenu';
import BellIcon from "components/Icons/BellIcon";
import ClockIcon from "components/Icons/ClockIcon";
import RoundClearIcon from "components/Icons/RoundClearIcon";
import CheckIcon from "components/Icons/CheckIcon";
import RefreshIcon from "components/Icons/RefreshIcon";
import {get} from 'lodash';
import {useModal} from "../../../Modal/useModal";
import CancelMenuMobile from "./CancelMenuMobile";
import AcceptEventMobile from "./AcceptEventMobile";
import UndoIcon from "components/Icons/UndoIcon";

const EventActionsMobile = (props) => {
    const {
        getStatus,
        activePatient,
        activeEvent,
        reminder,
        reminderSending,
        renderReminderTime,
        handleRemind,
        gynecology,
        appointment,
        showAppointmentModal,
        showCancelEventModal,
        handleCancelComlpletation,
        handleRestoreClick,
        time,
        required,
        handleOnAcceptCancelEventMedical,
        handleOnAcceptCancelEventPatient,
        handleApproveClick
    } = props;

    const [actionsState, setActions] = useState([])
    const [timer, setTimer] = useState();
    const [startTimer, setStartTimer] = useState();
    const [reminderNew, setReminderNew] = useState(time);

    useEffect(() => {
        setStartTimer(time)
    }, [time])


    useEffect(() => {

        if (timer) {
            clearInterval(timer)
            setTimer(null);
        }
        let sec = startTimer;
        let newTimer = setInterval(() => {
            sec = sec - 1
            if (!sec || sec < 1) {
                setReminderNew(false);
                clearInterval(newTimer);
            } else {
                setReminderNew(sec)
            }
        }, 1000)
        // setTimeout(() => clearInterval(newTimer), seconds * 1000)
        setTimer(newTimer);

        return () => {
            newTimer && clearInterval(newTimer)
        }
    }, [startTimer])

    useEffect(() => {
            let actions = [];
            if (get(activePatient, "status") !== 'planned') {
                if (getStatus(activeEvent) !== 'cancelled') {
                    if (getStatus(activeEvent) !== 'executed') {
                        if (reminderNew || reminderSending) {
                            actions.push({
                                id: 0,
                                label: `Напомнить через: ${renderReminderTime(reminderNew)}`,
                                icon: BellIcon,
                                iconSize: 20,
                                color: 'black30',
                                action: () => {
                                }
                            })
                        } else {
                            actions.push({
                                id: 0,
                                label: `Напомнить о мероприятии`,
                                icon: BellIcon,
                                iconSize: 20,
                                action: () => handleRemind(activeEvent)
                            })
                        }
                    }

                    if (!gynecology && !appointment && getStatus(activeEvent) !== 'executed') {
                        actions.push({
                            id: 0,
                            label: `Указать время записи`,
                            icon: ClockIcon,
                            iconSize: 20,
                            action: () => showAppointmentModal(),
                        })
                    }
                    if (getStatus(activeEvent) !== 'executed') {
                        if(required !== undefined && required){
                            actions.push({
                                id: 0,
                                label: `Отменить мероприятие`,
                                icon: RoundClearIcon,
                                iconSize: 20,
                                action: () => selectCancelReason()
                            })
                        }else {
                            actions.push({
                                id: 0,
                                label: `Отменить мероприятие`,
                                icon: RoundClearIcon,
                                iconSize: 20,
                                action: () => showCancelEventModal()
                            })
                        }
                    }
                    if (getStatus(activeEvent) === 'executed' && !!activeEvent?.executionPeriod?.end) {
                        actions.push({
                            id: 0,
                            label: `Отменить выполнение`,
                            icon: UndoIcon,
                            iconSize: 20,
                            action: () => handleCancelComlpletation(activeEvent)
                        })
                    }
                    if (getStatus(activeEvent) !== 'executed' || (!!activeEvent?.executionPeriod?.start && !activeEvent?.executionPeriod?.end)) {
                        // actions.push({
                        //     id: 0,
                        //     label: 'Время подтверждения выполнения мероприятия',
                        //     icon: RefreshIcon,
                        //     iconSize: 20,
                        //     action: (e) => showAnchorPopup(e, null, {
                        //         onAccept: handleApproveClick,
                        //         toDate: dayjs().utc(),
                        //         label: 'Время подтверждения выполнения мероприятия'
                        //     })
                        // });
                        actions.push({
                            id: 0,
                            label: 'Подтвердить выполнение',
                            icon: CheckIcon,
                            iconSize: 20,
                            action: () => onAccept(activeEvent)
                        })
                    }
                } else {
                    actions.push({
                        id: 0,
                        label: 'Восстановить',
                        icon: RefreshIcon,
                        iconSize: 20,
                        action: () => handleRestoreClick(activeEvent)
                    })
                }
            }
            setActions(actions);
        }, [reminderNew]
    )

    const [showCancelMenuModal] = useModal({
        component: CancelMenuMobile,
        name: 'cancel-menu-event-modal',
        header: 'Отмена мероприятия',
        mode: 'mobile-bottom-bar',
        props: {
            actions: {handleOnAcceptCancelEventMedical, handleOnAcceptCancelEventPatient, task: activeEvent}
        }
    });

    const selectCancelReason = () => {
        showCancelMenuModal()
    }

    const [showTimeMobileModal] = useModal({
        component: AcceptEventMobile,
        name: 'time-mobile-modal',
        header: 'Подтвердить выполнение',
        mode: 'mobile-bottom-bar',
        props: {
            actions: handleApproveClick
        }
    });

    const onAccept = () => {
        showTimeMobileModal()
    }

    return <Wrapper>
        <BottomBarMenu {...props} actions={actionsState}/>
    </Wrapper>;
};

EventActionsMobile.propTypes = {};

export default EventActionsMobile;
