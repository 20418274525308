import styled, {useTheme} from "styled-components"; 
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    // getActivePatientData,
    selectActivePatient,
    selectActivePatientData,
    selectFieldsFilter,
    getChannelDrugs,
    doctorActions,
    selectLoading
} from "reducers/doctor";
import {get} from "lodash";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import Spinner from "components/Spinner";

export const DrugList = ({level, prevLevel}) => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const fieldsFilter = useSelector(selectFieldsFilter);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState([]);
    const [initialize, setInitialize] = useState(true);

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, "id");
        // dispatch(getActivePatientData(patientId));
       if(prevLevel < level && initialize) dispatch(getChannelDrugs(id, fieldsFilter));
       setInitialize(false);
    }, [activePatientData.UKL]);

    useEffect(() => {
        let id = get(activePatient, "id");
        if (id && !initialize) {
            dispatch(getChannelDrugs(id, fieldsFilter));
        }
    }, [activePatientData.fieldsFilter]);

    useEffect(() => {
        if (activePatientData.channelDrugs) {
            setData(activePatientData?.channelDrugs?.courses);
        }
    }, [activePatientData.channelDrugs]);

    const setActiveItem = (item) => {
        dispatch(doctorActions.setDrugActiveItem(item));
    }

    return (
        <>  
            {!loading?.SIZLData ? data && data.map(item =>
                    <Content onClick={() => setActiveItem(item)}>
                        <CustomView condition={isDesktop || isTablet}>
                            <ListItemWrapper>
                                <ListItemBox>
                                    <Wrapper direction={'row'} justify={'space-between'}>
                                        <Text tag={'small'} text={dayjs(item.date).locale(ru).format('D MMM YYYY, H:mm')} color={theme.colors.black54} />
                                        {item.isCourse &&
                                            <Text tag={'small'} text={'Курс приема'} color={theme.colors.black30}/>
                                        }
                                    </Wrapper>
                                    <NameBox direction={'row'}>
                                        <QuantityBox>
                                            <Text text={item.quantity} color={theme.colors.primary} font={'robotoCondensedBold'} />
                                        </QuantityBox>
                                        <Text text={item.name} font={'robotoCondensedRegular'} overflow={true} />
                                    </NameBox>
                                    {item?.schedule && <Text tag={'small'} text={item.schedule} color={theme.colors.black54} />}
                                </ListItemBox>
                            </ListItemWrapper>
                        </CustomView>
                        <MobileOnlyView>
                            <ListItemWrapper>
                                <ListItemBox>
                                    <Wrapper direction={'row'} justify={'space-between'}>
                                        <Text tag={'small'} text={dayjs(item.date).locale(ru).format('D MMM YYYY, H:mm')} color={theme.colors.black54} />
                                        {item.isCourse &&
                                        <Text tag={'small'} text={'Курс приема'} color={theme.colors.black30}/>
                                        }
                                    </Wrapper>
                                    <NameBox direction={'row'}>
                                        <QuantityBox>
                                            <Text text={item.quantity} color={theme.colors.primary} font={'robotoMedium'} tag={'h5'} />
                                        </QuantityBox>
                                        <Text text={item.name} font={'robotoRegular'} overflow={true} tag={'h5'} />
                                    </NameBox>
                                    {item?.schedule && <Text tag={'small'} text={item.schedule} color={theme.colors.black54} />}
                                </ListItemBox>
                            </ListItemWrapper>
                        </MobileOnlyView>
                    </Content>
            )
            :
            <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                <Spinner/>
            </Wrapper>
        }
        </>
    )
}

const Content = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 40px;
    padding: 0 16px;
    
    & > div {
        width: 100%;
        height: 100%;
    }
    
    /*&:hover {
        background: ${({theme}) => theme.colors.black04};
        ${({theme}) => theme.mixins.innerShadow()}
        
        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: #e0e0e0;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }*/
`

const ListItemWrapper = styled.div`
    min-height: 40px;
    padding: 8px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.mixins.innerShadow()}
`

const ListItemBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
`

const NameBox = styled.div`
    display: flex;
    overflow: hidden;
`

const QuantityBox = styled.div`
    width: auto;
    white-space: nowrap;
    margin-right: 8px;
`
