import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {useTooltip} from "components/Tooltip/useTooltip";

const TableText = ({text, type, wrap, tooltip, paddings, tooltipProps}) => {

    const [showTooltip, hideTooltip] = useTooltip({component: tooltip, props: tooltipProps});

    return (
        <TextWrapper wrap={wrap} type={type} onMouseEnter={tooltip && showTooltip} onMouseLeave={tooltip && hideTooltip} paddings={paddings}>
            {text}
        </TextWrapper>
    );
};

const renderLineHeight = (type, theme) => {
    switch (type) {
        case 'primary':
            return theme.fonts.lineHeight.normal
        case 'secondary':
        case 'alert':
            return theme.fonts.lineHeight.small
        default:
            return null
    }
}

const renderSize = (type, theme) => {
    switch (type) {
        case 'primary':
        case 'primaryGrey':
            return theme.fonts.sizes.text
        case 'secondary':
        case 'alert':
            return theme.fonts.sizes.label
        default:
            return null
    }
}

const renderColor = (type, theme) => {
    switch (type) {
        case 'primary':
            return theme.colors.black100
        case 'primaryGrey':
            return theme.colors.black54
        case 'secondary':
            return theme.colors.black54
        case 'alert':
            return theme.colors.red
        default:
            return null
    }
}

const TextWrapper = styled.div`
  ${({theme, type}) => type === 'alert' ? 
    theme.mixins.robotoCondensedBold({color: renderColor(type, theme), size: renderSize(type, theme), lineheight: renderLineHeight(type, theme)}) : 
    theme.mixins.robotoCondensedRegular({color: renderColor(type, theme), size: renderSize(type, theme), lineheight: renderLineHeight(type, theme)})
};
  padding: ${({paddings}) => paddings ? paddings : '11 0'};
  ${({type}) => type !== 'primaryGrey' && `text-overflow: ellipsis`};
  ${({type}) => type !== 'primaryGrey' && `overflow: hidden`};
  white-space: ${({wrap}) => wrap ? wrap : 'nowrap'}; nowrap;
`

TableText.propTypes = {
    text: PropTypes.oneOf(['string', 'number']).isRequired,
    type: PropTypes.oneOf(['primary', 'secondary', 'alert'])
};

export default TableText;
