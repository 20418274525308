import {createSlice} from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {isEmpty, get, isNumber, find, cloneDeep} from "lodash";
import { findInTypeBySystem, getTrimesterByWeeks } from 'utils';
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';

const initialState = {
    tableSettings: {
        fieldsFilter: {
        },
        paging: {
            startIndex: 0,
            maxItems: 25,
        },
        sorting: {
            default: [
                {
                    propertyName: "patient",
                    direction: 0,
                }
            ],
            practitioner: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ]
        },
        searchString: ''
    },
    loading:{
        fullPage: true,
        practitioner: true,
        data: true,
        genders: true,
        conditions: true,
        updateCarePlans: true,
        practitionerRole: true
    },
    practitioner: null,
    practitionerRole: null,
    data: null,
    totalItems: null,
    channelsCount: 0,
    activeChannelsCount: 0,
    patientsCount: 0,
    type: null,
    organization: null,
    saveThisPageFilter: false,
}


const reducers = {
    setPractitioner: (state, action) => {
        state.practitioner = action.payload;
    },
    setPractitionerPhoto: (state, action) => {
        if(state.practitioner.practitionerData)
            state.practitioner.practitionerData.photo = action.payload
    },
    setPractitionerRole: (state, action) => {
        state.practitionerRole = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    resetLoadingState: (state, action) => {
        state.loading = initialState?.loading;
    },
    setChannelsCount: (state, action) => {
        state.channelsCount = action.payload;
    },
    setActiveChannelsCount: (state, action) => {
        state.activeChannelsCount = action.payload;
    },
    setPatientsCount: (state, action) => {
        state.patientsCount = action.payload;
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setOrganization: (state, action) => {
        state.organization = action.payload;
    },
    setSorting: (state, { payload: { propertyName, profile = 'default' }}) => {
        let targetObject = state.tableSettings.sorting[profile].find(item => item.propertyName === propertyName);
        if(targetObject){
            targetObject.direction = (targetObject.direction === 1) ? 0 : 1;
        }else{
            state.tableSettings.sorting[profile] = [{
                propertyName,
                direction: 1
            }]
        }
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    clearDataState: (state, action) => {
        state.practitioner = null;
        state.practitionerRole = null;
        state.data = null;
        state.totalItems = null;
        state.channelsCount = 0;
        state.activeChannelsCount = 0;
        state.patientsCount = 0;
        state.type = null;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectPractitioner: state => get(state, statePath)?.practitioner,
    selectPractitionerRole: state => get(state, statePath)?.practitionerRole,
    selectData: state => get(state, statePath)?.data,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting.default,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectPractitionerSorting: state => get(state, statePath)?.tableSettings.sorting.practitioner,
    selectLoading: state => get(state, statePath)?.loading,
    selectChannelsCount: state => get(state, statePath)?.channelsCount,
    selectTotalItems: state => get(state, statePath)?.totalItems,
    selectActiveChannelsCount: state => get(state, statePath)?.activeChannelsCount,
    selectPatientsCount: state => get(state, statePath)?.patientsCount,
    selectType: state => get(state, statePath)?.type,
    selectOrganization: state => get(state, statePath)?.organization,
    selectPaging: state => get(state, statePath)?.tableSettings.paging,
});

export const getThunks = (actions) => {

    const getPractitioner = ({practitionerRole, fieldsFilter, sorting}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'practitioner',
                value: true
            }))

            fetchInstance({
                method: "POST",
                url: `${api.practitioners}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            practitionerId: practitionerRole?.practitioner?.id,
                            organizationId: practitionerRole?.organization?.id,
                            statType: 0
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');
                
                const activeChannelsCount = get(item, 'statistics.active');
                const archiveChannelsCount = get(item, 'statistics.archive');
                const inactiveChannelsCount = get(item, 'statistics.inActive');
                const channelsCount =
                    (isNumber(activeChannelsCount) ? activeChannelsCount : 0) +
                    (isNumber(archiveChannelsCount) ? archiveChannelsCount : 0)+
                    (isNumber(inactiveChannelsCount) ? inactiveChannelsCount : 0);

                dispatch(actions.setChannelsCount(channelsCount));
                dispatch(actions.setActiveChannelsCount(activeChannelsCount));

                dispatch(actions.setPractitioner({
                    id: get(item, "practitioner.id"),
                    roleId: get(item, `practitionerRole.id`),
                    name: get(item, "practitioner.name[0].text"),
                    practitionerData: {
                        active:get(item, "practitioner.active"),
                        speciality: findInTypeBySystem(get(item, `practitionerRole.specialty`), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display,
                        status: {
                            text: find(get(item, `practitionerRole.extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.display,
                            value: find(get(item, `practitionerRole.extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.code
                        },
                        gender: get(item, "practitioner.gender"),
                        code: find(get(item, `practitionerRole.identifier`), {system: "urn:mgfoms:szl:entity:practitionerrole:id"})?.value,
                        email: find(get(item, `practitionerRole.telecom`), {system: "email"})?.value,
                        phone: find(get(item, `practitionerRole.telecom`), {system: "phone"})?.value,
                        photo: get(item, "practitioner.photo[0].url"),
                        created: find(get(item, `practitionerRole.meta.extension`), {url: "ex:createdAt"})?.valueInstant
                    },
                    statistics: get(item, 'statistics'),
                    rawData: get(item, "practitioner")
                }));

                dispatch(actions.setLoading({
                    type: 'practitioner',
                    value: false
                }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-practitioner-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить информацию о враче', closable: true}))
                dispatch(showMessage('channels-loading-practitioner-message'))
                dispatch(closeMessage({name: 'channels-loading-practitioner-message', delay: systemMessageDelay}))

                dispatch(actions.setLoading({
                    type: 'practitioner',
                    value: false
                }))

                console.log(err);
            });
        };
    };


    const getPractitionerRole = ({practitionerRoleId, fieldsFilter, sorting}) => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'practitionerRole',
                value: true
            }))

            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            id: practitionerRoleId,
                            _include: 'practitioner,organization'
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');

                const organization = get(data, `resources[${get(item, "organization.reference")}]`);
                const type = findInTypeBySystem(get(data, `resources[${get(item, "organization.reference")}].type`), 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code === '2' ? 'gynecology' : 'oncology';

                dispatch(actions.setType(type));
                dispatch(actions.setOrganization(organization));

                dispatch(actions.setPractitionerRole(item))
                dispatch(actions.setLoading({
                    type: 'practitionerRole',
                    value: false
                }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-practitioner-role-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить роль врача', closable: true}))
                dispatch(showMessage('channels-loading-practitioner-role-message'))
                dispatch(closeMessage({name: 'channels-loading-practitioner-role-message', delay: systemMessageDelay}))

                console.log(err)
            });
        };
    };

    const getData = ({roleId, fieldsFilter, sorting = [], paging: {startIndex = 0, maxItems = 10}, searchString, weekOfPregnancy, type, channelStatusReasons}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            dispatch(actions.setData(null));

            fetchInstance({
                method: "POST",
                url: `${api.practitionerStatistic}/${roleId}/EncountersStatistics`,
                data: {
                    paging:{
                        startIndex,
                        maxItems
                    },
                    filtering:{
                        searchString,
                        fieldsFilter
                    },
                    sorting: [...sorting],
                }
            }).then((response) => {
                const data = get(response,'data');
                const items = get(data, 'items');
                let modifiedData = []

                if(!isEmpty(items)) {
                    modifiedData = items && items.map(item => {

                        return {
                            patientId: get(item, 'patientId'),
                            carePlanId: get(item, 'carePlanId'),
                            encounterId: get(item, 'id'),
                            name: get(item, 'patientName'),
                            gender: get(item, 'patientGender'),
                            birthDate: get(item, 'patientBirthDate'),
                            age: get(item, 'patientAge'),
                            protocol: get(item, 'protocolExecution'),
                            diagnosis: get(item, 'conditionName'),
                            trimester: (item.conditionOnSetDateTime && type === 'gynecology' && !isEmpty(weekOfPregnancy)) ?
                                getTrimesterByWeeks(get(item, 'week'), weekOfPregnancy) : undefined,
                            messages: {
                                questions: item?.totalFromPatient,
                                answers: item?.totalFromPractitioner,
                                expired: item?.notAnsweredAndOverdue,
                                allExpired: item?.notAnsweredOrAnsweredOverdue
                            },
                            status: get(item, 'status'),
                            statusReason: get(find(channelStatusReasons, { code: get(item, 'statusReason')}), 'display'),
                        }
                    })

                    dispatch(actions.setData([...modifiedData]));
                }

                dispatch(actions.setPatientsCount(get(data, 'clientQuery.paging.totalItems') || 0));
                dispatch(actions.setTotalItems(get(data, 'clientQuery.paging.totalItems', 1)));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-encounters-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить статистику каналов', closable: true}))
                dispatch(showMessage('channels-loading-encounters-message'))
                dispatch(closeMessage({name: 'channels-loading-encounters-message', delay: systemMessageDelay}))

                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));

                console.log(err);
            });
        };
    };

    const uploadAvatar = ({file, practitioner}) => {
        return (dispatch) => {

            let bodyFormData = new FormData();
            bodyFormData.append('files', file)

            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            }).then((response) => {
                const data = get(response, 'data');
                data && dispatch(putPractitionerAvatar({ url: data[0].url, practitioner }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-upload-avatar-message', type: 'red', title: 'Ошибка', text: 'Не удалось установить аватар', closable: true}))
                dispatch(showMessage('channels-upload-avatar-message'))
                dispatch(closeMessage({name: 'channels-upload-avatar-message', delay: systemMessageDelay}))

                console.log(err)
            });
        };
    };

    const putPractitionerAvatar = ({url, practitioner}) => {
        return (dispatch) => {
            practitioner &&
                fetchInstance({
                    method: "PUT",
                    url: `${api.practitioner}`,
                    data: {
                        ...practitioner?.rawData,
                        photo: [
                            {
                                url
                            }
                        ]
                    }
                }).then((response) => {
                    const photoUrl = get(response, 'data.photo[0].url')
                    photoUrl && dispatch(actions.setPractitionerPhoto(photoUrl))
                }).catch(err => {

                    dispatch(registerMessage({name: 'channels-put-avatar-message', type: 'red', title: 'Ошибка', text: 'Не удалось установить аватар', closable: true}))
                    dispatch(showMessage('channels-put-avatar-message'))
                    dispatch(closeMessage({name: 'channels-put-avatar-message', delay: systemMessageDelay}))

                    console.log(err)
                });
        };
    };

    return { getPractitioner, getPractitionerRole, getData, uploadAvatar, putPractitionerAvatar }
}


export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        ChannelsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
