import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {renderComponent} from "utils/renderComponent";
import {useTooltip} from "../Tooltip/useTooltip";

const Icon = ({icon, size, action, color, opacity, rotate, tooltip}) => {

    const [showTooltip, hideTooltip] = useTooltip({component: tooltip})

    return (
        <IconWrapper size={size} onClick={action} cursor={!!action} color={color} opacity={opacity} rotate={rotate} onMouseEnter={tooltip && showTooltip} onMouseLeave={tooltip && hideTooltip}>
            {renderComponent(icon)}
        </IconWrapper>
    );
};

const IconWrapper = styled.div`
  width: ${({size}) => size + 'px' || '100%'};
  height: ${({size}) => size + 'px' || '100%'};
  cursor: ${({cursor}) => cursor && 'pointer'};
  transform: ${({rotate}) => `rotate(${rotate}deg)`};
  
  svg {
    width: 100%;
    height: 100%;
    fill: ${({theme, color}) => color ? color : theme.colors.black100};
    fill-opacity: ${({color, theme, opacity}) => opacity ? opacity : color ? theme.opacity.o100 : theme.opacity.o54};
  }
`

Icon.propTypes = {
    icon: PropTypes.element.isRequired,
    size: PropTypes.number,
    action: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    opacity: PropTypes.number,
};

Icon.defaultProps = {
    size: 18,
    rotate: 0,
}

export default Icon;
