import React from 'react'; 
import styled from "styled-components";

const NoData = ({image, text, margins, imageMargins}) => {

    return (
        <Container margins={margins} imageMargins={imageMargins}>
            {image && <img alt={''} src={image} />}
            <pre>{text}</pre>
        </Container>
    );
};

const Container = styled.div`
    margin: ${({margins}) => margins};
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img {
        margin: ${({imageMargins}) => imageMargins};
    }
    pre {
        margin: 0;
        text-align: center;
        ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black54})};
    }
`

NoData.defaultProps = {
    margins: '32px 0 0 0',
    imageMargins: '0 0 12px 0'
}

export default NoData;
