import React from 'react';
import styled, {css} from "styled-components";
import {darken} from "polished";

const CalendarCell = ({day, month, year, shaded, disabled, onClick, active, fromDateForBlur, activeField}) => {
    // const selectedActiveEndDate = new Date(year, month, day)

    return (
        <CellWrapper active={active} shaded={shaded} disabled={disabled} onClick={() => onClick({day, month, year})} /*blur={fromDateForBlur >= selectedActiveEndDate && activeField === 'endDate'}*/>
            {day}
        </CellWrapper>
    );
};

const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    content: ${({disabled}) => disabled === true ? 'disabled true' : 'disabled false'};  
    ${({theme, shaded, disabled, blur}) => theme.mixins.robotoRegular({
      color: (shaded || disabled || blur) && theme.colors.black30
    })};
    margin-right: 12px;
    margin-bottom: 4px;
    cursor: ${({disabled, blur}) => !disabled && !blur && 'pointer'};
  
    :nth-child(7n) {
      margin-right: 0;
    }
  
    ${({disabled}) => !disabled && css`
        :hover {
          background-color: ${({theme}) => theme.colors.black04};
        }
        :active {
          background-color: ${({theme}) => theme.colors.black08};
        }
    `}

    ${({active}) => active && css`
        background-color: ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.white100};
          :hover {
            background-color: ${({theme}) => darken(theme.opacity.o04, theme.colors.primary)};
          }
          :active {
            background-color: ${({theme}) => darken(theme.opacity.o04, theme.colors.primary)};
          }
    `};
  
`

CalendarCell.propTypes = {

};

export default CalendarCell;
