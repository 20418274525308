import React, {useContext, useEffect, useState} from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css, useTheme} from "styled-components";
import {useDispatch, useSelector} from 'react-redux';
import {
    selectActiveEvent,
    doctorRightSideActions,
    selectPlanDefinition,
    getPlanDefinition,
    selectProtocolTaskAppointment,
    getAppointment,
    createAppointment,
    deleteAppointment,
    clearStateAfterTaskEditing,
    updateTask,
    cancelTask,
    approveTask,
    backToReadyTask,
    selectTaskSortingPosition,
    selectTaskTrimester,
    getProtocolTasks,
    selectLoading,
    setBirthEvent
} from 'reducers/doctor/rightSide';
import {
    doctorActions,
    getActivePatientStatistics,
    getPatients,
    selectActivePatient,
    selectPatientsTableSettings,
    selectRole,
} from 'reducers/doctor';

import ActionIcon from "components/ActionIcon";
import {useModal} from "components/Modal/useModal";
import SimpleCancelEventModal from "modals/СancelEventModal/SimpleCancelEventModal";
import CancelEventModalFooter from "modals/СancelEventModal/CancelEventModalFooter";
import DiagnosisCancelEventModal from "modals/СancelEventModal/DiagnosisCancelEventModal";
import PatientCancelEventModal from "modals/СancelEventModal/PatientCancelEventModal";
import ActivitiesBox from "components/ActivitiesBox";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import DateAndTime from "../DateAndTime";
import RoundClearIcon from "components/Icons/RoundClearIcon";
import BellIcon from "components/Icons/BellIcon";
import TableSubtext from "components/Table/TableSubtext";
import TailArrowIcon from "components/Icons/TailArrowIcon";
import Text from "components/Text";
import CheckIcon from "components/Icons/CheckIcon";
import ClockIcon from "components/Icons/ClockIcon";
import ClearIcon from "components/Icons/ClearIcon";
import RefreshIcon from 'components/Icons/RefreshIcon';
import Icon from "components/Icon";
import DoubleCheckIcon from "components/Icons/DoubleCheckIcon";

// import {options, actions} from "../options";
import {isEmpty, last, get, isArray, first, find, upperFirst, isNumber, cloneDeep} from 'lodash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import DateInput from 'components/DateInput';
import Label from "components/Label";
import {reminderBlockingTime, systemMessageDelay} from 'consts';
import CircleProgress from 'components/CircleProgress';
import {closeMessage, registerMessage, showMessage} from 'reducers/systemMessages/systemMessagesSlice';
import {selectWeekOfPregnancy} from 'reducers/catalogs';
import SecureLS from 'secure-ls';
import { CustomView, MobileOnlyView, isDesktop, isTablet } from "react-device-detect";
import Button from "components/Button";
import SquaresIcon from "components/Icons/SquaresIcon";
import EventActionsMobile from "../mobile/EventActionsMobile";
import utc from "dayjs/plugin/utc";
import NativeDateInput from 'components/NativeDateInput';
import AppointmentMobile from '../mobile/AppointmentMobile';
import { useConnectionStarter } from 'hooks/useConnectionStarter';
import Spinner from 'components/Spinner';

dayjs.extend(timezone);

const ls = new SecureLS();

const TaskDetails = () => {

    const theme = useTheme();

    const connection = useConnectionStarter();

    const dateTemplate = 'DD MMMM YYYY'

    const activeEvent = useSelector(selectActiveEvent);
    const activePatient = useSelector(selectActivePatient);
    const practitionerRole = useSelector(selectRole);
    const planDefinition = useSelector(selectPlanDefinition);
    const appointment = useSelector(selectProtocolTaskAppointment);
    const sortingPosition = useSelector(selectTaskSortingPosition);
    const taskTrimmester = useSelector(selectTaskTrimester);
    const weekOfPregnancy = useSelector(selectWeekOfPregnancy);
    const loading = useSelector(selectLoading);
    const patientsTableSettings = useSelector(selectPatientsTableSettings);

    const dispatch = useDispatch();

    const [indications, setIndications] = useState();
    const [overdueFlag, setOverdueFlag] = useState();
    const [isBornTask, setIsBornTask] = useState();
    // const [haveOutput, setHaveOutput] = useState();
    const [patientRejection, setPatientRejection] = useState();
    const [required, setRequired] = useState();
    const [periodStart, setPeriodStart] = useState();
    const [periodEnd, setPeriodEnd] = useState();
    const [chatUserId, setChatUserId] = useState();
    const [reminder, setReminder] = useState(false);
    const [reminders, setReminders] = useState({});
    const [reminderSending, setReminderSending] = useState(false);
    const [statusReason, setStatusReason] = useState();
    const [statusReasonType, setStatusReasonType] = useState();
    const [statusReasonDisplay, setStatusReasonDisplay] = useState();
    const [patientTransferred, setPatientTransferred] = useState(false);
    const [patientTransferredDate, setPatientTransferredDate] = useState(null);
    const [timer, setTimer] = useState();
    const [gynecology, setGynecology] = useState(false);
    const [startTimer, setStartTimer] = useState();

    dayjs.extend(utc);

    // useEffect(() => {
    //     activePatient?.id && dispatch(getProtocolTasks({
    //         encounterId: activePatient.id,
    //         sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
    //     }))
    // }, [activePatient?.id])

    const handleOnAcceptCancelEvent = (task) => {
        dispatch(cancelTask({
            task,
            encounterId: activePatient?.id,
            callback: () => deleteReminder()
        }))
    }

    const handleCancelComlpletation = (task) => {
        if(!isBornTask){
            dispatch(backToReadyTask({
                task,
                encounterId: activePatient?.id,
            }))
        }else{
            dispatch(setBirthEvent({
                birthDate: '', //При удалении сохраняем пустую дату
                encounterId: activePatient?.id,
                callback: () => {
                    dispatch(getProtocolTasks({
                        encounterId: activePatient.id,
                        sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
                    }));
                    dispatch(getActivePatientStatistics({roleId: practitionerRole?.id, tableSettings: patientsTableSettings, activePatientId: activePatient.id}));
                    dispatch(getPatients({roleId: practitionerRole?.id, tableSettings: patientsTableSettings}));
                }
            }))
        }
    }

    const handleOnAcceptCancelEventPatient = (task) => {
        // console.log(task)
        dispatch(cancelTask({
            task,
            reason: {
                type: 'patientRejection'
            },
            encounterId: activePatient?.id,
            callback: () => deleteReminder()
        }))
    }

    const handleOnAcceptCancelEventMedical = (task, value) => {
        dispatch(cancelTask({
            task,
            reason: {
                type: 'medical',
                text: value
            },
            encounterId: activePatient?.id,
            callback: () => deleteReminder()
        }))
    }

    const handleRestoreClick = (task) => {
        dispatch(backToReadyTask({
            task,
            encounterId: activePatient?.id,
        }))
    }

    const actions = [
        {
            id: 0,
            label: 'По показаниям',
            modal: {
                component: DiagnosisCancelEventModal,
                modalHeader: 'Отмена мероприятия',
                modalProps: {
                    modalWidth: '560px',
                    onAccept: handleOnAcceptCancelEventMedical
                }
            }
        },
        {
            id: 1,
            label: 'Отказ пациента',
            modal: {
                component: PatientCancelEventModal,
                modalHeader: 'Отменить мероприятие?',
                modalFooter: CancelEventModalFooter,
                modalProps: {
                    modalWidth: '392px',
                    onAccept: handleOnAcceptCancelEventPatient
                }
            }
        }
    ]

    const [showCancelEventModal] = useModal({
        component: SimpleCancelEventModal,
        name: 'simple-cancel-event-modal',
        props: {modalWidth: '392px', onAccept: handleOnAcceptCancelEvent, task: activeEvent},
        header: 'Отмена мероприятия',
        footer: CancelEventModalFooter
    });

    const handleDateAndTimeAccept = ({date, time}) => {
        const dateAndTime = time ? dayjs(date).hour(time?.h).minute(time?.m).local().format() : dayjs(date);

        deleteReminder();

        dispatch(createAppointment({
            date: time ? dateAndTime : dateAndTime.format('YYYY-MM-DD'),
            taskName: activeEvent.description,
            patientId: activePatient?.patientId,
            practitionerId: practitionerRole?.id,
            withTaskUpdate: true,
            taskUpdateData: {
                task: activeEvent,
                formData: {
                    startDate: periodStart,
                    endDate: periodEnd
                },
                encounterId: activePatient?.id
            }
        }))
    }

    const [showAnchorPopup] = useAnchorPopup(
        {
            component: DateAndTime,
            props: {
                width: '328px',
        }
    });

    const setCurrentTaskStateToList = () => {

        const periodStartDifference = dayjs(get(activeEvent, 'restriction.period.start')).diff(activePatient?.onSetDateTime, 'week');
        const periodEndDifference = dayjs(get(activeEvent, 'restriction.period.end')).diff(activePatient?.onSetDateTime, 'week');
        const periodAverge = (isNumber(periodStartDifference) && isNumber(periodEndDifference)) && Math.round((periodStartDifference + periodEndDifference) / 2);
        const avergePreiodTrimesterCode = periodAverge && get(find(weekOfPregnancy, {code: `${periodAverge}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');
        const avergePreiodTrimester = avergePreiodTrimesterCode && parseInt(avergePreiodTrimesterCode?.replace('trimester-', ''));

        const task = {
            id: get(activeEvent, 'id'),
            period: {
                start: get(activeEvent, 'restriction.period.start'),
                end: get(activeEvent, 'restriction.period.end'),
            },
            executionPeriod: {
                start: get(activeEvent, 'executionPeriod.start'),
                end: get(activeEvent, 'executionPeriod.end'),
            },
            status: upperFirst(getStatus(activeEvent)),
            description: get(activeEvent, 'description'),
            isNecessary: required,
            isOverdue: overdueFlag,
            creationPeriod: `${dayjs(get(activeEvent, 'restriction.period.start')).locale('ru').format('DD MMM YYYY г.')} - ${dayjs(get(activeEvent, 'restriction.period.end')).locale('ru').format('DD MMM YYYY г.')}`, // "20 мая 2016 г. - 19 августа 2016 г.",
            instantiatesUri: get(activeEvent, 'instantiatesUri'),
            instantiatesCanonical: get(activeEvent, 'instantiatesCanonical'),
            appointment: appointment,
            isWrong: patientRejection,
            sorting: sortingPosition,
            trimester: get(activeEvent, 'instantiatesUri') ? taskTrimmester || avergePreiodTrimester : avergePreiodTrimester ? avergePreiodTrimester : taskTrimmester
        }

        dispatch(doctorRightSideActions.setTaskToProtocolTaskList(task))
    }

    const handleActiveEvent = (id) => {
        activeEvent?.status !== 'draft' && setCurrentTaskStateToList();
        dispatch(doctorRightSideActions.setActiveEvent(id));
        dispatch(clearStateAfterTaskEditing())
    }

    const handleClearAppointnemt = () => {

        deleteReminder();

        appointment && dispatch(deleteAppointment({
            id: appointment?.id,
            withTaskUpdate: true,
            taskUpdateData: {
                task: activeEvent,
                formData: {
                    startDate: periodStart,
                    endDate: periodEnd
                },
                encounterId: activePatient?.id
            }
        }))
    }

    const handleApproveClick = ({date, time}) => {
        const dateAndTime = dayjs(date).hour(time?.h).minute(time?.m);
        if(!isBornTask){
            dispatch(approveTask({
                task: activeEvent,
                date: dateAndTime,
                encounterId: activePatient?.id,
            }))
        }else{
            dispatch(setBirthEvent({
                birthDate: date,
                encounterId: activePatient?.id,
                callback: () => {
                    dispatch(getProtocolTasks({
                        encounterId: activePatient.id,
                        sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
                    }));
                    dispatch(getActivePatientStatistics({roleId: practitionerRole?.id, tableSettings: patientsTableSettings, activePatientId: activePatient.id}));
                    dispatch(getPatients({roleId: practitionerRole?.id, tableSettings: patientsTableSettings}));
                }
            }))
        }
    }

    //Асинхронный запрос в WS соединение
    const sendMessage = async ({user, message, files, task}) => {
        const messageWithBrs = message ? message.split("\n").join("<br/>") : '';

        if (connection?.connectionStarted) {
            try {
                setReminderSending(true)
                dispatch(doctorActions.setBlockLogoutButton(true));
                connection?.invoke("SendReminder", messageWithBrs, user, files, task).then(() => {
                    setReminderSending(false);
                    dispatch(doctorActions.setBlockLogoutButton(false));
                    addReminder();
                }).catch((err) => {
                    setReminderSending(false);
                    dispatch(doctorActions.setBlockLogoutButton(false));
                    dispatch(registerMessage({
                        name: 'sending-protocol-reminder-error-message',
                        type: 'red',
                        title: 'Ошибка',
                        text: 'Не удалось отправить напоминание',
                        closable: true
                    }))
                    dispatch(showMessage('sending-protocol-reminder-error-message'))
                    dispatch(closeMessage({name: 'sending-protocol-reminder-error-message', delay: systemMessageDelay}))
                })
            } catch (e) {
                dispatch(doctorActions.setBlockLogoutButton(false));
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    const handleRemind = (task) => {
        chatUserId && sendMessage({
            user: chatUserId,
            message: `Напоминание о необходимости пройти мероприятие - ${task?.description}.${
                !gynecology && appointment && appointment?.start ?
                    ` Запись на прием ${dayjs(appointment?.start).locale('ru').format('DD MMMM YYYYг.')} в ${dayjs(appointment?.start).locale('ru').format('HH:mm')}` : ''
            }${
                task?.restriction?.period ? ` Планируемый период проведения ${dayjs(task?.restriction?.period?.start).locale('ru').format('DD MMMM YYYYг.')} - ${dayjs(task?.restriction?.period?.end).locale('ru').format('DD MMMM YYYYг.')}` : ''
            }`,
            task: task?.id
        })
    }

    const renderStatus = (item) => {
        if (item?.status === 'ready' || item?.status === 'in-progress') {
            if (!!activeEvent?.executionPeriod?.start) {
                return <Label text={'Выполнено'} type={'primary'} icon={CheckIcon} maxWidth={'94px'}/>
            }
            if (overdueFlag) {
                return <Label text={'Просрочено'} type={'red'} maxWidth={'94px'}/>
            }
            return <Label text={'Запланировано'} type={'orange'} maxWidth={'94px'}/>
        }
        if (item?.status === 'completed') {
            if (!!activeEvent?.executionPeriod?.end) {
                return <Label text={'Выполнено'} type={'green'} icon={DoubleCheckIcon} maxWidth={'94px'}/>
            }
            return <Label text={'Выполнено'} type={'primary'} maxWidth={'94px'}/>
        }
        if (item?.status === 'cancelled') {
            return <Label text={'Отменено'} type={'red'} maxWidth={'94px'}/>
        }
        // if(item?.status === 'in-progress'){
        //     return <Label text={'Запланировано'} type={'orange'} maxWidth={'94px'} />
        // }
    }

    const getStatus = (item) => {
        if (item?.status === 'ready' || item?.status === 'in-progress') {
            if (!!activeEvent?.executionPeriod?.start) {
                return 'executed'
            }
            if (overdueFlag) {
                return 'overdue'
            }
            return 'planned'
        }
        if (item?.status === 'completed') {
            if (!!activeEvent?.executionPeriod?.end) {
                return 'executed'
            }
            return 'executed'
        }
        if (item?.status === 'cancelled') {
            return 'cancelled'
        }
        // if(item?.status === 'in-progress'){
        //     return 'planned'
        // }
    }

    const getColoration = (item) => {
        if (item?.status === 'ready' && !!activeEvent?.executionPeriod?.start) {
            return 'blue'
        }
        if (item?.status === 'completed' && !!activeEvent?.executionPeriod?.end) {
            return 'green'
        }
    }

    const renderIndications = (indications) => {
        return (
            <>
                <Wrapper paddings={'16px 0 0 0'}>
                    <Text tag={'small'} text={'Показания'} color={theme.colors.black54}/>
                </Wrapper>
                <pre>
                    {
                        indications.map((item, index) => {
                            const text = find(item?.coding, {system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-activity-reason"})?.display
                            return text ? `${index + 1}. ${text}\n` : ''
                        })
                    }
                </pre>
            </>
        )
    }

    const renderReminderTime = (seconds) => {

        const pad2 = (num) => {
            var s = num.toString();
            return (s.length < 2) ? "0" + s : s;
        }

        const m = Math.floor(seconds / 60);
        const s = seconds % 60;

        return `${m > 0 ? `${pad2(m)}:` : ''}${pad2(s)}`
    }

    const runTimer = (seconds) => {
        setStartTimer(seconds);
    }

    const addReminder = () => {
        ls.set('reminders', {
            ...reminders,
            [activeEvent.id]: {
                expire: dayjs().add(reminderBlockingTime, 'second')
            }
        })

        setReminder(reminderBlockingTime)
        runTimer(reminderBlockingTime)
    }

    const deleteReminder = () => {
        let cloneReminders = cloneDeep(reminders);

        delete cloneReminders[activeEvent?.id]

        ls.set('reminders', cloneReminders);

        if (timer) {
            clearInterval(timer)
            setTimer(null);
        }
        setReminder(false);
    }

    const handlePeriodStart = (value) => {
        setPeriodStart(value);
        deleteReminder();
        dispatch(updateTask({
            task: activeEvent,
            formData: {
                startDate: value,
                endDate: periodEnd
            },
            appointment,
            encounterId: activePatient?.id
        }))
    }

    const handlePeriodEnd = (value) => {
        setPeriodEnd(value);
        deleteReminder();
        dispatch(updateTask({
            task: activeEvent,
            formData: {
                startDate: periodStart,
                endDate: value
            },
            appointment,
            encounterId: activePatient?.id,
        }))
    }

    useEffect(() => {
        // Извлекаем PlanDefinition id для Task
        const id = activeEvent?.instantiatesUri && activeEvent?.instantiatesUri?.match(/PlanDefinition\/([\s\S]+)#/)[1];

        // Загружаем PlanDefinition если id был найден
        activeEvent && id && dispatch(getPlanDefinition({
            planDefinitionId: id
        }))

        // Проверяем наличие appointment у Task
        const appointment = isArray(activeEvent?.extension) ? first(activeEvent?.extension.filter(item => get(item, 'valueReference.reference', '').includes('Appointment'))) : null;
        const appointmentId = appointment ? last(appointment?.valueReference?.reference?.split('/')) : null;
        // Загружаем Appointment если он есть у Task
        appointmentId && dispatch(getAppointment({id: appointmentId}))

        let reminders = cloneDeep(ls.get('reminders'));

        Object.keys(reminders).forEach(item => {
            if (dayjs(reminders[item].expire).isBefore(dayjs(), 'second')) delete reminders[item]
        })

        ls.set('reminders', reminders)
        reminders && setReminders(reminders);

        const currentTaskReminder = get(reminders, `${activeEvent?.id}`);

        if (currentTaskReminder) {
            const remindTimeDifference = dayjs(currentTaskReminder?.expire).diff(dayjs(), 'second')

            if (remindTimeDifference < reminderBlockingTime) {
                setReminder(remindTimeDifference)
                runTimer(remindTimeDifference)
            } else {
                setReminder(false)
            }
        }
    }, [activeEvent])

    // useEffect(() => {
    //     if(messages && isArray(messages)){
    //         const lastRemindMessage = last(messages.filter(item => {
    //             const payloadTaskReference = find(item?.payload, (e) => e?.content?.referenceElement?.value.includes('Task/'))?.content?.referenceElement?.value;
    //             const taskCategory = findInTypeBySystemElement(item?.category, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-message-category')?.codeElement?.value;
    //             const payloadTaskId = payloadTaskReference && last(payloadTaskReference.split('/'));
    //             return (payloadTaskId && taskCategory) ? (payloadTaskId === activeEvent?.id && taskCategory === 'reminder') : false
    //         }))

    //         const remindTimeDifference = lastRemindMessage && dayjs().diff(lastRemindMessage?.sentElement?.value, 'second')

    //         if(lastRemindMessage && remindTimeDifference < reminderBlockingTime){
    //             setReminder(reminderBlockingTime - remindTimeDifference)
    //             setSearchLastRemindMessage(false);
    //             runTimer(reminderBlockingTime - remindTimeDifference)
    //         }else{
    //             setSearchLastRemindMessage(false);
    //             setReminder(false)
    //         }
    //     }
    // }, [messages])

    useEffect(() => {

        // Извлекаем action id из instantiatesUri
        const actionDefinitionCanonicalId = last(activeEvent?.instantiatesUri?.split("#"))

        // Ищем action в PlanDefinition соответсвующий action id
        const action = planDefinition && planDefinition?.action?.find(item => {
            // const itemActionDefinitionCanonicalId = last(item?.definitionCanonical?.split("/"));
            return actionDefinitionCanonicalId === item?.id;
        })

        // Устанавливаетм в локальный стейт найденные "показания"
        action?.reason && setIndications(action?.reason)
        // Определяем обязательность Task
        action?.requiredBehavior && setRequired(action?.requiredBehavior === 'must-unless-documented' || !(action?.requiredBehavior === 'could'))

    }, [planDefinition])

    useEffect(() => {

        if (timer) {
            clearInterval(timer)
            setTimer(null);
        }
        let sec = startTimer;
        let newTimer = setInterval(() => {
            sec = sec - 1
            if (!sec || sec < 1) {
                setReminder(false);
                clearInterval(newTimer);
                deleteReminder();
            } else {
                setReminder(sec)
            }
        }, 1000)
        // setTimeout(() => clearInterval(newTimer), seconds * 1000)
        setTimer(newTimer);

        return () => {
            newTimer && clearInterval(newTimer)
        }

    }, [startTimer])

    useEffect(() => {
        setOverdueFlag(!isEmpty(activeEvent) ? activeEvent?.extension?.find(item => item.url === 'http://miramedix.ru/fhir/StructureDefinition/task-overdue-flag')?.valueBoolean : null); // Инициализируем локальный стейт флагом overdue, если он есть у Task
        setIsBornTask(!isEmpty(activeEvent) ? (activeEvent?.instantiatesCanonical === "http://onlinedoc-v2.miramedix.ru/fhir/ActivityDefinition/1-041" || activeEvent?.instantiatesUri === 'http://onlinedoc.miramedix.ru/fhir/PlanDefinition/mgfoms-obstetrics-protocol-standard-v1#78') : null)
        // setHaveOutput(!isEmpty(activeEvent) ? activeEvent?.output?.find(item => find(item?.type?.coding, {system: "http://miramedix.ru/fhir/CodeSystem/task-outputtype"})?.code === 'response') : null); // Инициализируем локальный стейт флагом наличия "Ответа" в output, если он есть у Task
        setPeriodStart(!isEmpty(activeEvent) && dayjs(activeEvent?.restriction?.period?.start)); // Инициализируем локальный стейт датой начала планируемого периода проведения
        setPeriodEnd(!isEmpty(activeEvent) && dayjs(activeEvent?.restriction?.period?.end)); // Инициализируем локальный стейт датой окончания планируемого периода проведения
        setStatusReason(activeEvent?.statusReason?.text); // Текст показаний отмены
        setStatusReasonType(!isEmpty(activeEvent) ? find(activeEvent?.statusReason?.coding, {system: "http://miramedix.ru/fhir/CodeSystem/task-status-reason"})?.code : null); //Код показаний отмены
        setStatusReasonDisplay(!isEmpty(activeEvent) ? find(activeEvent?.statusReason?.coding, {system: "http://miramedix.ru/fhir/CodeSystem/task-status-reason"})?.display : null); //Текст показаний отмены
        setPatientRejection(!isEmpty(activeEvent) ? activeEvent?.extension?.find(item => item.url === 'http://miramedix.ru/fhir/StructureDefinition/szl-is-wrong')?.valueBoolean : null) // Инициализируем локальный стейт флагом patientRejection, если он есть у Task
    }, [activeEvent])

    useEffect(() => {
        setPatientTransferred(!isEmpty(appointment) ? appointment?.extension?.find(item => item.url === 'http://miramedix.ru/fhir/StructureDefinition/szl-is-transferred-by-patient')?.valueBoolean : null);
        setPatientTransferredDate(!isEmpty(appointment) ? appointment?.extension?.find(item => item.url === 'http://miramedix.ru/fhir/StructureDefinition/szl-transferred-date')?.valueDateTime : null);
    }, [appointment])

    useEffect(() => {
        setChatUserId(activePatient?.id || '');
    }, [activePatient])

    useEffect(() => {
        setGynecology(activePatient?.code === 'Z33');
    }, [activePatient?.code])

    const [showAppointmentModal] = useModal({
        component: AppointmentMobile,
        name: 'task-appointment-modal',
        header: 'Время записи на прием',
        mode: 'mobile-bottom-bar',
        props: {
            appointmentDate: dayjs(appointment?.start),
            onAccept: (value) => {
                handleDateAndTimeAccept({ date: value, time: { h: dayjs(value).get('hour'), m: dayjs(value).get('minute') }})
            }
        },
    });

    const [showSelectActionEventModal] = useModal({
        component: EventActionsMobile,
        name: 'select-action-event-modal',
        header: 'Действия',
        mode: 'mobile-bottom-bar',
        props: {
            getStatus,
            activePatient,
            activeEvent,
            reminder,
            reminderSending,
            renderReminderTime,
            handleRemind,
            gynecology,
            appointment,
            showAppointmentModal,
            showCancelEventModal,
            handleCancelComlpletation,
            handleApproveClick,
            handleRestoreClick,
            connection,
            handleOnAcceptCancelEventMedical,
            handleOnAcceptCancelEventPatient,
        },
    });

    const handleAddTaskClick = (time, required) => {
        showSelectActionEventModal({data: {time, required}})
    }

    return (
        /*Отдельное мероприятие*/
        <ContainerEvent>
            {loading.birthEvent && <Spinner/>}
            <CustomView condition={isDesktop || isTablet}>
                <Title>
                    <ActionIconWrapperLeft>
                        <ActionIcon action={() => handleActiveEvent(null)} icon={TailArrowIcon} rotate={'90'}/>
                    </ActionIconWrapperLeft>
                    Описание мероприятия
                </Title>
                <Body>
                    <>
                        <Wrapper direction={'row'} align={'center'} justify={'space-between'}>
                            {renderStatus(activeEvent)}
                            {required !== undefined &&
                            <TableSubtext text={required ? 'Обязательное мероприятие' : 'Дополнительное мероприятие'}/>
                            }
                        </Wrapper>
                        <Wrapper paddings={'10px 0 0 0'}>
                            <Text tag={'h5'} text={activeEvent?.description}/>
                        </Wrapper>
                        {indications && isArray(indications) && renderIndications(indications)}
                        {
                            <Wrapper paddings={'13px 0 4px 0'}>
                                <Text tag={'small'} text={'Планируемый период проведения'} color={theme.colors.black54}/>
                            </Wrapper>
                        }
                        {activePatient?.status === 'planned' || activeEvent?.status === 'completed' || activeEvent?.status === 'cancelled' || (activeEvent?.status === 'ready' && !!activeEvent?.executionPeriod?.start) ?
                            <>
                                {activeEvent?.restriction?.period ?
                                    <Text text={
                                        activeEvent?.restriction?.period?.end ?
                                            `${dayjs(activeEvent?.restriction?.period?.start).locale('ru').format(dateTemplate)} — ${dayjs(activeEvent?.restriction?.period?.end).locale('ru').format(dateTemplate)}`
                                            :
                                            dayjs(activeEvent?.restriction?.period?.start).locale('ru').format(dateTemplate)}
                                          font={'robotoRegular'}/>
                                    :
                                    <Text text={'—'} font={'robotoRegular'}/>
                                }
                                {(activeEvent?.executionPeriod?.end || (activeEvent?.status === 'ready' && !!activeEvent?.executionPeriod?.start)) &&
                                <>
                                    <Wrapper paddings={'12px 0 4px 0'}>
                                        <Text tag={'small'} text={'Фактическая дата проведения'}
                                              color={theme.colors.black54}/>
                                    </Wrapper>
                                    <Wrapper direction={'row'} justify={'space-between'}>
                                        {(!!activeEvent?.executionPeriod?.end) &&
                                        <Wrapper maxWidth={'calc(50% - 4px)'} justify={'flex-end'}>
                                            <Text
                                                text={dayjs(activeEvent?.executionPeriod?.end).locale('ru').format(dateTemplate)}
                                                font={'robotoRegular'}/>
                                            <RequiredLabel coloration={getColoration(activeEvent)} margins={'8px 0 0 0'}>Подтверждено
                                                врачом</RequiredLabel>
                                        </Wrapper>
                                        }
                                        {(!!activeEvent?.executionPeriod?.start) &&
                                        <Wrapper maxWidth={'calc(50% - 4px)'} justify={'flex-end'}>
                                            <Text
                                                text={dayjs(activeEvent?.executionPeriod?.start).locale('ru').format(dateTemplate)}
                                                font={'robotoRegular'}/>
                                            <RequiredLabel coloration={getColoration(activeEvent)} margins={'8px 0 0 0'}>Подтверждено
                                                пациентом</RequiredLabel>
                                        </Wrapper>
                                        }
                                        {patientRejection &&
                                        <Wrapper maxWidth={'calc(50% - 4px)'} justify={'flex-end'}>
                                            <RequiredLabel coloration={'red'} margins={'8px 0 0 0'}>Не подтверждено
                                                пациентом</RequiredLabel>
                                        </Wrapper>
                                        }
                                    </Wrapper>
                                </>
                                }
                            </>
                            :
                            <Wrapper direction={'row'} align={'center'}>
                                <DateInput
                                    value={periodStart}
                                    toDate={periodEnd}
                                    onChange={handlePeriodStart}
                                />
                                <Separator>
                                    –
                                </Separator>
                                <DateInput
                                    value={periodEnd}
                                    fromDate={periodStart}
                                    onChange={handlePeriodEnd}
                                />
                            </Wrapper>
                        }
                        {activeEvent?.status === 'cancelled' && statusReasonType &&
                        <>
                            {activeEvent?.lastModified &&
                            <>
                                <Wrapper paddings={'13px 0 4px 0'}>
                                    <Text
                                        tag={'small'}
                                        text={'Дата отмены'}
                                        color={theme.colors.black54}/>
                                </Wrapper>
                                <Text text={dayjs(activeEvent?.lastModified).locale('ru').format(dateTemplate)}
                                      font={'robotoRegular'}/>
                            </>
                            }
                            <Wrapper paddings={'13px 0 4px 0'}>
                                <Text
                                    tag={'small'}
                                    text={statusReasonType === 'indication' ? 'Показание для отмены мероприятия' : 'Причина отмены'}
                                    color={theme.colors.black54}/>
                            </Wrapper>
                            <Text text={statusReasonType === 'indication' ?
                                statusReason || statusReasonDisplay
                                : statusReasonType === 'patient-rejection' ?
                                    statusReason || "Письменный отказ пациента"
                                    : ''
                            } font={'robotoRegular'}/>
                        </>
                        }
                        {!gynecology && appointment && appointment?.start &&
                        <Wrapper paddings={'12px 0 0 0'} direction={'row'} align={'center'}>
                            <Wrapper>
                                <Text tag={'small'} text={'Запись на прием'} color={theme.colors.black54}/>
                                <Text
                                    text={dayjs(appointment?.start).locale('ru').format(`DD MMMM YYYY${gynecology ? '' : ', HH:mm'}`)}
                                    font={'robotoRegular'} color={theme.colors.primary}/>
                            </Wrapper>
                            {getStatus(activeEvent) !== 'cancelled' &&
                            <Icon size={27} action={handleClearAppointnemt} icon={ClearIcon} color={theme.colors.black30}/>}
                        </Wrapper>
                        }
                        {patientTransferred && !!patientTransferredDate &&
                        <Wrapper paddings={'12px 0 0 0'} direction={'column'}>
                            <Text tag={'small'} text={'Перенесенная пациентом запись на прием'}
                                  color={theme.colors.black54}/>
                            <Text
                                text={dayjs(patientTransferredDate).tz('UTC', true).locale('ru').format('DD MMMM YYYY, HH:mm')}
                                font={'robotoRegular'}/> {/* tz - костыль для перевода таймзоны, т.к. с бека приходит время по Москве, но с часовым поясом +00:00 */}
                        </Wrapper>
                        }

                        {(get(activePatient, "status") !== 'planned') ?
                            (getStatus(activeEvent) !== 'cancelled') ?
                                <ButtonsBox>
                                    {getStatus(activeEvent) !== 'executed' &&
                                    <>
                                        {reminder || reminderSending ?
                                            <ButtonLine className={'disabled'}><Icon icon={BellIcon}
                                                                                     color={theme.colors.black30}/>
                                                <Wrapper paddings={'0 0 0 16px'} flexGrow={'1'} flexBasis={'0'}>Напомнить
                                                    о
                                                    мероприятии</Wrapper>
                                                {!reminderSending &&
                                                <TableSubtext color={'black30'}
                                                              text={`Повтор через: ${renderReminderTime(reminder)}`}/>
                                                }
                                                {reminderSending &&
                                                <Icon icon={CircleProgress} />
                                                }
                                            </ButtonLine>
                                            :
                                            <ButtonLine onClick={() => handleRemind(activeEvent)}><Icon icon={BellIcon}
                                                                                                        color={theme.colors.primary}/><Wrapper
                                                paddings={'0 0 0 16px'}>Напомнить о мероприятии</Wrapper></ButtonLine>
                                        }
                                    </>
                                    }
                                    {!gynecology && !appointment && getStatus(activeEvent) !== 'executed' &&
                                    <ButtonLine onClick={(e) => showAnchorPopup(e, null, {
                                        onAccept: handleDateAndTimeAccept,
                                        label: 'Время записи на прием'
                                    })}>
                                        <Icon icon={ClockIcon} color={theme.colors.primary}/>
                                        <Wrapper paddings={'0 0 0 16px'}>Указать время записи на прием</Wrapper>
                                    </ButtonLine>}
                                    {getStatus(activeEvent) !== 'executed' &&
                                    <>
                                        {required !== undefined && required ?
                                            <ActivitiesBox data={{task: activeEvent}} actions={actions}
                                                           icon={RoundClearIcon} size={18} text={'Отменить мероприятие'}
                                                           color={theme.colors.primary} height={'32px'}/>
                                            :
                                            <ButtonLine onClick={() => showCancelEventModal()}>
                                                <Icon icon={RoundClearIcon} color={theme.colors.primary}/>
                                                <Wrapper paddings={'0 0 0 16px'}>Отменить мероприятие</Wrapper>
                                            </ButtonLine>
                                        }
                                    </>
                                    }
                                    {(getStatus(activeEvent) === 'executed' && !!activeEvent?.executionPeriod?.end) &&
                                    <ButtonLine onClick={() => handleCancelComlpletation(activeEvent)}>
                                        <Icon icon={RoundClearIcon} color={theme.colors.primary}/>
                                        <Wrapper paddings={'0 0 0 16px'}>Отменить выполнение</Wrapper>
                                    </ButtonLine>
                                    }
                                    {(getStatus(activeEvent) !== 'executed' || (!!activeEvent?.executionPeriod?.start && !activeEvent?.executionPeriod?.end)) &&
                                    <ButtonLine onClick={(e) => showAnchorPopup(e, null, {
                                        onAccept: handleApproveClick,
                                        toDate: dayjs().utc(),
                                        label: 'Время подтверждения выполнения мероприятия'
                                    })}>
                                        <Icon icon={CheckIcon} color={theme.colors.primary}/>
                                        <Wrapper paddings={'0 0 0 16px'}>Подтвердить выполнение</Wrapper>
                                    </ButtonLine>}
                                </ButtonsBox>
                                :
                                <ButtonsBox>
                                    <ButtonLine onClick={() => handleRestoreClick(activeEvent)}>
                                        <Icon icon={RefreshIcon} color={theme.colors.primary}/>
                                        <Wrapper paddings={'0 0 0 16px'}>Восстановить</Wrapper>
                                    </ButtonLine>
                                </ButtonsBox>
                            : <></>}
                    </>
                </Body>
            </CustomView>
            <MobileOnlyView>
                <Wrapper justify={'space-between'} fullsize>
                    {!loading?.protocolTask ?
                        <>
                            <Body>
                                <>
                                    <Wrapper direction={'row'} align={'center'} justify={'space-between'}>
                                        {renderStatus(activeEvent)}
                                        {required !== undefined &&
                                        <Text text={required ? 'Обязательное мероприятие' : 'Дополнительное мероприятие'} tag={'span'} font={'robotoCondensedRegular'} color={theme.colors.black54}/>
                                        }
                                    </Wrapper>
                                    <Wrapper paddings={'10px 0 0 0'}>
                                        <Text tag={'h5'} text={activeEvent?.description}/>
                                    </Wrapper>
                                    {indications && isArray(indications) && renderIndications(indications)}
                                    {
                                        <Wrapper paddings={'13px 0 4px 0'}>
                                            <Text tag={'small'} text={'Планируемый период проведения'} color={theme.colors.black54}/>
                                        </Wrapper>
                                    }
                                    {activePatient?.status === 'planned' || activeEvent?.status === 'completed' || activeEvent?.status === 'cancelled' || (activeEvent?.status === 'ready' && !!activeEvent?.executionPeriod?.start) ?
                                        <>
                                            {activeEvent?.restriction?.period ?
                                                <Text text={
                                                    activeEvent?.restriction?.period?.end ?
                                                        `${dayjs(activeEvent?.restriction?.period?.start).locale('ru').format(dateTemplate)} — ${dayjs(activeEvent?.restriction?.period?.end).locale('ru').format(dateTemplate)}`
                                                        :
                                                        dayjs(activeEvent?.restriction?.period?.start).locale('ru').format(dateTemplate)}
                                                    font={'robotoRegular'} tag={'h5'}/>
                                                :
                                                <Text text={'—'} font={'robotoRegular'}/>
                                            }
                                            {(activeEvent?.executionPeriod?.end || (activeEvent?.status === 'ready' && !!activeEvent?.executionPeriod?.start)) &&
                                            <>
                                                <Wrapper paddings={'12px 0 4px 0'}>
                                                    <Text tag={'small'} text={'Фактическая дата проведения'}
                                                        color={theme.colors.black54}/>
                                                </Wrapper>
                                                <Wrapper direction={'row'} justify={'space-between'}>
                                                    {(!!activeEvent?.executionPeriod?.end) &&
                                                    <Wrapper width={!!activeEvent?.executionPeriod?.start ? 'calc(50% - 4px)' : '100%'} justify={'flex-end'}>
                                                        <Text
                                                            text={dayjs(activeEvent?.executionPeriod?.end).locale('ru').format(dateTemplate)}
                                                            font={'robotoRegular'}  tag={'h5'}/>
                                                        <RequiredLabel coloration={getColoration(activeEvent)} margins={'8px 0 0 0'}>Подтверждено
                                                            врачом</RequiredLabel>
                                                    </Wrapper>
                                                    }
                                                    {(!!activeEvent?.executionPeriod?.start) &&
                                                    <Wrapper
                                                        width={!!activeEvent?.executionPeriod?.end ? 'calc(50% - 4px)' : '100%'}
                                                        justify={'flex-end'}>
                                                        <Text
                                                            text={dayjs(activeEvent?.executionPeriod?.start).locale('ru').format(dateTemplate)}
                                                            font={'robotoRegular'} tag={'h5'}/>
                                                        <RequiredLabel coloration={getColoration(activeEvent)}
                                                                    margins={'8px 0 0 0'}>Подтверждено
                                                            пациентом</RequiredLabel>
                                                    </Wrapper>
                                                    }
                                                    {patientRejection &&
                                                    <Wrapper width={'100%'} justify={'flex-end'}>
                                                        <RequiredLabel coloration={'red'} margins={'8px 0 0 0'}>Не подтверждено
                                                            пациентом</RequiredLabel>
                                                    </Wrapper>
                                                    }
                                                </Wrapper>
                                            </>
                                            }
                                        </>
                                        :
                                        <Wrapper>
                                            <Wrapper paddings={'13px 0 4px 0'}>
                                                <NativeDateInput label={'Дата начала'} onChange={handlePeriodStart} defaultValue={periodStart} dateTemplate={'D MMMM YYYY г.'} />
                                            </Wrapper>
                                            <Wrapper paddings={'13px 0 4px 0'}>
                                                <NativeDateInput label={'Дата окончания'} onChange={handlePeriodEnd} defaultValue={periodEnd} dateTemplate={'D MMMM YYYY г.'} />
                                            </Wrapper>
                                        </Wrapper>
                                    }
                                    {activeEvent?.status === 'cancelled' && statusReasonType &&
                                    <>
                                        {activeEvent?.lastModified &&
                                        <>
                                            <Wrapper paddings={'13px 0 4px 0'}>
                                                <Text
                                                    tag={'small'}
                                                    text={'Дата отмены'}
                                                    color={theme.colors.black54}/>
                                            </Wrapper>
                                            <Text text={dayjs(activeEvent?.lastModified).locale('ru').format(dateTemplate)}
                                                font={'robotoRegular'}/>
                                        </>
                                        }
                                        <Wrapper paddings={'13px 0 4px 0'}>
                                            <Text
                                                tag={'small'}
                                                text={statusReasonType === 'indication' ? 'Показание для отмены мероприятия' : 'Причина отмены'}
                                                color={theme.colors.black54}/>
                                        </Wrapper>
                                        <Text text={statusReasonType === 'indication' ?
                                            statusReason || statusReasonDisplay
                                            : statusReasonType === 'patient-rejection' ?
                                                statusReason || "Письменный отказ пациента"
                                                : ''
                                        } font={'robotoRegular'}/>
                                    </>
                                    }
                                    {!gynecology && appointment && appointment?.start &&
                                    <Wrapper paddings={'12px 0 0 0'} direction={'row'} align={'center'}>
                                        <Wrapper>
                                            <Text tag={'small'} text={'Запись на прием'} color={theme.colors.black54}/>
                                            <Text
                                                text={dayjs(appointment?.start).locale('ru').format(`DD MMMM YYYY${gynecology ? '' : ', HH:mm'}`)}
                                                font={'robotoRegular'} color={theme.colors.black}/>
                                        </Wrapper>
                                        {getStatus(activeEvent) !== 'cancelled' &&
                                        <Icon size={27} action={handleClearAppointnemt} icon={ClearIcon} color={theme.colors.black30}/>}
                                    </Wrapper>
                                    }
                                    {patientTransferred && !!patientTransferredDate &&
                                    <Wrapper paddings={'12px 0 0 0'} direction={'column'}>
                                        <Text tag={'small'} text={'Перенесенная пациентом запись на прием'}
                                            color={theme.colors.black54}/>
                                        <Text
                                            text={dayjs(patientTransferredDate).tz('UTC', true).locale('ru').format('DD MMMM YYYY, HH:mm')}
                                            font={'robotoRegular'}/> {/* tz - костыль для перевода таймзоны, т.к. с бека приходит время по Москве, но с часовым поясом +00:00 */}
                                    </Wrapper>
                                    }
                                </>
                            </Body>
                            <Wrapper paddings={'16px'}>
                                <Button buttonType={'rounded'} disabled={activePatient?.status === 'planned'}
                                        onClick={()=> handleAddTaskClick(reminder, required)}
                                        label={'Выбрать действие'}
                                        fullWidth icon={SquaresIcon} iconPosition={'left'} reverse/>
                            </Wrapper>
                        </>
                        :
                        <Spinner/>
                    }
                </Wrapper>
            </MobileOnlyView>
        </ContainerEvent>
    );
};

const RequiredLabel = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${({theme, coloration}) => {
    return coloration === 'blue' ? theme.colors.primary12 : coloration === 'green' ? theme.colors.green12 : coloration === 'red' ? theme.colors.red12 : theme.colors.primary12
  }};
  ${({theme, coloration}) => theme.mixins.robotoCondensedRegular({
    color: coloration === 'blue' ? theme.colors.primary : coloration === 'green' ? theme.colors.green : coloration === 'red' ? theme.colors.red : theme.colors.primary,
    size: theme.fonts.sizes.label
  })};
  margin: ${({margins}) => margins}
`

const ActionIconWrapperLeft = styled.div`
  position: absolute;
  top: 4px;
  left: 9px;
`

const ContainerEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  
  & > div {
    width: 100%;
  }
  ${({theme}) => theme.isMobile && css`
      height: calc(100vh - 50px);
      height: calc(var(--vh, 1vh) * 100 - 50px);
      
      & > div {
        height: 100%;
      }
  `}
`

const Separator = styled.div`
  padding: 0 8px;
  ${({theme}) => theme.mixins.robotoRegular()};
`

const ButtonsBox = styled.div`
  margin: 22px -15px 0 -15px;
`

const ButtonLine = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary, size: theme.fonts.sizes.text})};

  &:hover {
    background: ${({theme}) => theme.colors.primary12};
  }

  &.disabled {
    color: ${({theme}) => theme.colors.black30};
  }
`

const Title = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mixins.robotoMedium({size: '16px'})};
  ${({theme}) => theme.mixins.innerShadow()};
`

const Body = styled.div`
  padding: 16px;
  width: 100%;

  pre {
    white-space: break-spaces;
    margin: 0;
    ${({theme}) => theme.mixins.robotoRegular()};
    
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})};
    `}
  }
`

TaskDetails.propTypes = {};

export default TaskDetails;
