import axios from "axios";
import { BASE_URL } from "consts";
import { get } from "lodash";
import { authLogout, getMaintenanceState, AppActions } from "reducers/app";

export const fetchInstance = axios.create({
    baseURL: BASE_URL,
});

export const setAuthToken = (token) => {
    if (token) {
        //applying token
        fetchInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        //deleting the token from header
        delete fetchInstance.defaults.headers.common["Authorization"];
    }
};

fetchInstance.interceptors.request.use(
    (req) => {
        return req;
    },
    (error) => {
        return Promise.reject(error);
    }
);

fetchInstance.interceptors.response.use(
    (response) => {
        if (response.data.error) return Promise.reject(response.data.error);
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//Обработчик ошибок от бека

export const setErrorsInterceptor = ({ dispatch }) => {
    fetchInstance.interceptors.response.use(
        (response) => {
            if(response?.status === 200 && response?.config?.url !== '/SuperAdmin/GetOnMaintenanceState'){
                dispatch(AppActions.setMaintenanceState())
            }
            if (response.data.error) return Promise.reject(response.data.error);
            return response;
        },
        (error) => {
            // if(error?.response){
                if (get(error.response, "status") === 401 || get(error.response, "status") === 403) {
                    dispatch(authLogout());
                }
                if (
                  (get(error.response, "status") === undefined && get(error, "response.config.url") !== "/SuperAdmin/GetOnMaintenanceState") ||
                  (!error?.response && get(error, "config.url") !== "/SuperAdmin/GetOnMaintenanceState") ||
                  get(error.response, "status") === 503
                ) {
                  if (!axios.isCancel(error)) dispatch(getMaintenanceState());
                }
            // }
            return Promise.reject(error);
        }
    );
}