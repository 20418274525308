import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from 'styled-components'
import Icon from "components/Icon";
import {useTooltip} from "components/Tooltip/useTooltip";
import {useModal} from "components/Modal/useModal";

const ActionIcon = ({icon, action, rotate, boxShadow, tooltip, disabled, size, modal, color, colorHover, iconColor, iconSize, borderRadius, hoverAction, iconColorHover, active, tooltipZIndex, tooltipWrap}) => {
    const [showModal] = useModal({name: modal?.name})

    const handleItemAction = (e) => {
        hideTooltip();
        e.stopPropagation();
        if(modal) {
            showModal({modalComponent: modal?.component, modalHeader: modal?.modalHeader, modalFooter: modal?.modalFooter, modalProps: modal?.modalProps})
        }
    }

    const theme = useTheme()

    const [showTooltip, hideTooltip] = useTooltip({component: tooltip, props: {tooltipZIndex: tooltipZIndex, tooltipWrap: tooltipWrap}})

    useEffect(() => {
        return () => hideTooltip()
    }, [])
    // Закомментировал из-за утечки памяти
    // useEffect(() => {
    //     return () => hideTooltip()
    // }, [hideTooltip])

    return (<>
            <ActionIconWrapper
                size={size}
                disabled={disabled}
                onClick={(!disabled && action) ? action : (e) => handleItemAction(e)}
                onMouseEnter={!hoverAction ? tooltip && showTooltip : (!disabled && action) ? action : (e) => handleItemAction(e)}
                onMouseLeave={hideTooltip}
                color={color}
                colorHover={colorHover}
                iconColorHover={iconColorHover}
                borderRadius={borderRadius}
                active={active}
                boxShadow={boxShadow}
            >
                <Icon icon={icon} size={iconSize || 24} rotate={rotate} color={disabled ? theme.colors.black30 : iconColor} />
            </ActionIconWrapper>
        </>
    );
};

const ActionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'};
  width: ${({size}) => size};
  min-width: ${({size}) => size};
  height: ${({size}) => size};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '50%'};
  background-color: ${({color}) => color};
  ${({boxShadow}) => boxShadow};

  ${({disabled}) => !disabled && css`
      :hover {
        background-color: ${({theme, colorHover}) => colorHover ? colorHover : theme.colors.black04};
        svg {
            fill: ${({iconColorHover}) => iconColorHover && iconColorHover};
        }
      }
      
      :active {
        background-color: ${({theme}) => theme.colors.primary12};
        svg {
          fill: ${({theme}) => theme.colors.primary};
        }
      }
      
      ${({active}) => active && css`
        opacity: 1;
        background-color: ${({theme, colorHover}) => colorHover ? colorHover : theme.colors.black04};
        svg {
            fill: ${({iconColorHover}) => iconColorHover && iconColorHover};
        }
      `}
  `};
`

ActionIcon.propTypes = {
    icon: PropTypes.element.isRequired,
    action: PropTypes.func.isRequired,
    rotate: PropTypes.number,
    size: PropTypes.string,
    color: PropTypes.string,
};

ActionIcon.defaultProps = {
    size: '40px',
    color: 'transparent',
    iconColor: 'rgba(0, 0, 0, 0.54)'
}

export default ActionIcon;
