import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import UserImage from "components/UserImage";
import Text from "components/Text";
import {Wrapper} from "styled/Wrapper";
import styled, {useTheme} from "styled-components";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from 'react-device-detect';

const UserCard = forwardRef(({image, name, type, role, nameFontSize, nameLineHeight, onChange, disabled=true}, ref) => {

    const theme = useTheme()

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper direction={'row'} align={'center'}>
                    <UserImageWrapper>
                        <UserImage ref={ref} img={image} type={type} onChange={onChange} disabled={disabled} />
                    </UserImageWrapper>
                    <User>
                        <Text font={'robotoRegular'} color={theme.colors.black54}>
                            {role}
                        </Text>
                        <Name size={nameFontSize} lineheight={nameLineHeight}>
                            {name}
                        </Name>
                    </User>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper direction={'row'} align={'center'}>
                    <UserImageWrapper>
                        <UserImage ref={ref} img={image} type={type} onChange={onChange} disabled={disabled} size={72} />
                    </UserImageWrapper>
                    <User>
                        <Name size={nameFontSize} lineheight={nameLineHeight}>
                            {name}
                        </Name>
                        <Text font={'robotoRegular'} color={theme.colors.black54}>
                            {role}
                        </Text>
                    </User>
                </Wrapper>
            </MobileOnlyView>
        </>
    );
});

const UserImageWrapper = styled.div`
    margin-right: 16px;
`

const User = styled.div`
`

const Name = styled.div`
  ${({theme, size, lineheight}) => theme.mixins.robotoMedium({size: size ? size + 'px' : theme.fonts.sizes.h3, lineheight: lineheight ? lineheight + 'px' : theme.fonts.lineHeight.h3})};
`

UserCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
};

export default UserCard;
