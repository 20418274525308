import {rgba} from "polished";

export const renderBackground = (theme, type) => {
    switch(type) {
        case 'secondary':
            return rgba(theme.colors.secondary, theme.opacity.o12)
        case 'green':
            return rgba(theme.colors.green, theme.opacity.o12)
        case 'yellow':
            return rgba(theme.colors.orange, theme.opacity.o12)
        case 'blue':
            return rgba(theme.colors.primary, theme.opacity.o12)
        case 'purple':
            return rgba(theme.colors.purple, theme.opacity.o12)
        case 'black':
            return rgba(theme.colors.black54, theme.opacity.o04)
        case 'red':
            return rgba(theme.colors.red, theme.opacity.o12)
        case 'orange':
            return rgba(theme.colors.orange, theme.opacity.o12)
        default:
            return rgba(theme.colors.primary, theme.opacity.o12)
    }
}
