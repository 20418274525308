import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'styled/Wrapper';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import CalendarIcon from "../Icons/CalendarIcon";
import Icon from "../Icon";
import Text from 'components/Text';
import { useTheme } from 'styled-components';

const NativeDateInput = ({ dateTemplate, onChange, defaultValue, from, to, type='string', inputType='date', disabled, onFocus, onBlur, component, label }) => {

    const theme = useTheme();

    const [value, setValue] = useState();

    useEffect(() => {
        if(!!defaultValue){
            setValue(dayjs(defaultValue).locale("ru").format(dateTemplate))
        }
    }, [defaultValue])
    

    const handleDateChange = (e) => {
        if(e?.target?.value && !disabled){
            onChange && onChange(dayjs(e.target.value));
            setValue(dayjs(e.target.value).locale("ru").format(dateTemplate));
        }
    }

    const Component = component;

    return  <Wrapper>
                {component ?
                    <Component disabled={disabled} />
                    :
                    <Wrapper>
                        {label &&
                            <Wrapper paddings={'0 0 4px 0'}>
                                <Text tag={'small'} text={label}
                                    color={theme.colors.black54}/>
                            </Wrapper>
                        }
                        <DateLabel disabled={disabled} >
                            {
                                type === 'string' &&
                                    value
                            }
                            {
                                type === 'icon' &&
                                    <Icon icon={CalendarIcon}/>
                            }
                        </DateLabel>
                    </Wrapper>
                } 
                <HiddenDateInput
                    type={inputType}
                    value={defaultValue ? dayjs(defaultValue).format('YYYY-MM-DD') : null}
                    disabled={disabled}
                    onChange={handleDateChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    min={from ? dayjs(from).format(inputType === 'date' ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm") : null}
                    max={to ? dayjs(to).format(inputType === 'date' ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm") : null}
                />
            </Wrapper>
};

const DateLabel = styled.label`
    ${({theme, disabled}) => theme.mixins.robotoRegular({
        color: !disabled ? theme.colors.primary : theme.colors.black,
        size: theme.fonts.sizes.h5,
        lineheight: theme.fonts.lineHeight.normal
  })};
`

const HiddenDateInput = styled.input`
    position: absolute;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    ${({disabled}) => disabled && css`
        display: none;
    `}

    ::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        margin: 0;
        color: transparent;
        background: transparent;
    }

    ::-webkit-inner-spin-button {
        z-index: 1;
    }

    ::-webkit-clear-button {
        z-index: 1;
    }

    ::-webkit-datetime-edit { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-fields-wrapper { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-text { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-month-field { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-day-field { color: transparent; background: transparent; }
    ::-webkit-datetime-edit-year-field { color: transparent; background: transparent; }
`

NativeDateInput.propTypes = {
    dateTemplate: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    type: PropTypes.string
};

export default NativeDateInput;
