import styled, { css } from "styled-components";

const renderDirection = (direction) => {
  return direction === "row" ? "row" : "column";
};

export const Wrapper = styled.div`
  position: relative;
  display: ${({ inline }) => (inline ? "inline" : "flex")};
  flex-direction: ${({ direction }) => renderDirection(direction)};
  flex: ${({ flex }) => flex};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width || "100%"};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
  min-width: ${({ minWidth }) => minWidth || "auto"};
  height: ${({ fullsize }) => fullsize && "100%"};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ paddings }) => paddings};
  margin: ${({ margins }) => margins};
  overflow: ${({ overflow }) => overflow};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  white-space: ${({ whiteSpace }) => whiteSpace};
  gap: ${({ gap }) => gap};

  ${({ contentCentered }) =>
    contentCentered &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
