import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { findElementByElementId } from "utils/organizations";
import {get, cloneDeep} from "lodash";
import { organizationType, systemMessageDelay } from 'consts';
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";
// import {routes} from "consts/routes";

const initialState = {
    data: [],
    filials: {
        data: [],
        tableSettings: {
            fieldsFilter: {
                partof: null,
                type: organizationType.clinic,
                'type:missing': false
            },
            searchString: '',
            sorting: [
                {
                    propertyName: "name", 
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0, 
                maxItems: 25
            },
        },
    },
    branches: {
        data: [],
        tableSettings: {
            fieldsFilter: {
                partof: null,
                profile: '',
                type: organizationType.department,
                'type:missing': false
            },
            searchString: '',
            sorting: [
                {
                    propertyName: "name", 
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0, 
                maxItems: 25
            },
        },
    },
    tableSettings: {
        fieldsFilter: {
            partof: null,
            'type:missing': false
        },
        searchString: '',
        sorting: [
            {
                propertyName: "name", 
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0, 
            maxItems: 25
        },
    },
    childCheckedOrganizations: undefined,
    organizationData: [],
    childOrganizations: [],
    loading: {
        fullPage: true,
        organizationFullPage: true,
        data: true,
        organizationData: true
    },
    saveThisPageFilter: false,
};

const reducers = {
    setFieldsFilterInitial: (state, action) => {
        state.tableSettings.fieldsFilter = {
            partof: null,
            profile: ''
        }
        state.filials.tableSettings.fieldsFilter = {
            partof: null,
            type: organizationType.clinic
        }
        state.branches.tableSettings.fieldsFilter = {
            partof: null,
            profile: '',
            type: organizationType.department
        }
    },
    resetOrganizationData: (state, action) => {
        state.organizationData = []
    },
    setOrganizationFieldsFilterInitial: (state, action) => {
        state.filials.tableSettings.fieldsFilter = {
            partof: null,
            type: organizationType.clinic
        }
        state.branches.tableSettings.fieldsFilter = {
            partof: null,
            profile: '',
            type: organizationType.department
        }
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setFilialsData: (state, action) => {
        state.filials.data = action.payload;
    },
    setBranchesData: (state, action) => {
        state.branches.data = action.payload;
    },
    setProfile: (state, action) => {
        state.tableSettings.fieldsFilter.profile = action.payload;
        state.tableSettings.paging.startIndex = 0;
    },
    setBranchesProfile: (state, action) => {
        state.branches.tableSettings.fieldsFilter.profile = action.payload
    },
    setFilialsProfile: (state, action) => {
        state.filials.tableSettings.fieldsFilter.profile = action.payload
    },
    setFilialsNameFilter: (state, action) => {
        if (action.payload) {
            state.filials.tableSettings.fieldsFilter.ids = action.payload;
        } else {
            delete state.filials.tableSettings.fieldsFilter.ids;
        }
    },
    setBranchesNameFilter: (state, action) => {
        if (action.payload) {
            state.branches.tableSettings.fieldsFilter.ids = action.payload;
        } else {
            delete state.branches.tableSettings.fieldsFilter.ids;
        }
    },
    setOrganizationsToFilter: (state, action) => {
        if (action.payload) {
            state.tableSettings.fieldsFilter.ids = action.payload;
            state.tableSettings.paging.startIndex = 0;
        } else {
            delete state.tableSettings.fieldsFilter.ids;
            state.tableSettings.paging.startIndex = 0;
        }
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setBranchesSorting: (state, action) => {
        state.branches.tableSettings.sorting[0].propertyName = action.payload;
        state.branches.tableSettings.sorting[0].direction = state.branches.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setFilialsSorting: (state, action) => {
        state.filials.tableSettings.sorting[0].propertyName = action.payload;
        state.filials.tableSettings.sorting[0].direction = state.filials.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
        state.tableSettings.paging.startIndex = 0;
    },
    setPartOfData: (state, action) => {
        const targetElement = findElementByElementId(state.filials.data.items, action.payload.partof);
        if (targetElement) targetElement.content = action.payload.data.items;
    },
    setPartOfFilialsData: (state, action) => {
        const targetElement = findElementByElementId(get(state, "filials.data.items", []), action.payload.partof);
        if (targetElement) targetElement.content = action.payload.data.items;
    },
    setOpen: (state, action) => {
        const targetElement = findElementByElementId(state.filials.data.items, action.payload.part);
        if (targetElement) targetElement.open = action.payload.open;
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setBranchesPage: (state, action) => {
        state.branches.tableSettings.paging.startIndex = (action.payload - 1) * state.branches.tableSettings.paging.maxItems
    },
    setFilialsPage: (state, action) => {
        state.filials.tableSettings.paging.startIndex = (action.payload - 1) * state.filials.tableSettings.paging.maxItems
    },
    setOrganizationData: (state, action) => {
        state.organizationData = action.payload;
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) state.tableSettings = initialState.tableSettings;
    }, 
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    setProfilesToFilter: (state, action) => {
        action.payload ? state.tableSettings.fieldsFilter.profile = action.payload : delete state.tableSettings.fieldsFilter.profile;
    },
    setFilialProfilesToFilter: (state, action) => {
        action.payload ? state.filials.tableSettings.fieldsFilter.profile = action.payload : delete state.filials.tableSettings.fieldsFilter.profile;
    },
    setBranchProfilesToFilter: (state, action) => {
        action.payload ? state.branches.tableSettings.fieldsFilter.profile = action.payload : delete state.branches.tableSettings.fieldsFilter.profile;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
    setOrganizationsFilterForChildElements: (state, action) => {
        state.childCheckedOrganizations = action.payload;
    },
    setChildOrganizations: (state, action) => {
        state.childOrganizations = action.payload;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectOrganizationData: state => get(state, statePath)?.organizationData,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectBranchesData: state => get(state, statePath)?.branches.data,
    selectFilialsData: state => get(state, statePath)?.filials.data,
    selectFilialsFieldsFilter: state => get(state, statePath)?.filials.tableSettings.fieldsFilter,
    selectBranchesFieldsFilter: state => get(state, statePath)?.branches.tableSettings.fieldsFilter,
    selectFilialsSorting: state => get(state, statePath)?.filials.tableSettings.sorting,
    selectBranchesSorting: state => get(state, statePath)?.branches.tableSettings.sorting,
    selectFilialsTableSettings: state => get(state, statePath)?.filials.tableSettings,
    selectBranchesTableSettings: state => get(state, statePath)?.branches.tableSettings,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectChildCheckedOrganization: state => get(state, statePath)?.childCheckedOrganizations,
    selectChildOrganizations: state => get(state, statePath)?.childOrganizations,
    selectLoading: state => get(state, statePath)?.loading
})

export const getThunks = (actions) => {

    const getOrganizationsList = ({tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }))      
            fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: tableSettings.paging,
                filtering: {
                    searchString: tableSettings.searchString,
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting,
            }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }))               
                dispatch(actions.setData(response.data));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }))   
            });
        };
    };

    const getOrganizationFilials = ({filialsSorting, filialsFieldsFilter, childCheckedOrganizations}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationFilials',
                value: true
            }))
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: null,
                    fieldsFilter: filialsFieldsFilter
                },
                sorting: filialsSorting
            }
            }).then((response) => {
                dispatch(actions.setFilialsData(response.data));
                if (get(response, "data.items", []).length) {
                    get(response, "data.items", []).forEach(item => {
                        let fieldsFilterData = {...filialsFieldsFilter};
                        fieldsFilterData.partof = item.id;
                        fieldsFilterData.type = 'department';
                        fieldsFilterData.ids = childCheckedOrganizations;

                        dispatch(getOrganizationFilialsPartOf({
                            sorting: filialsSorting,
                            fieldsFilter: fieldsFilterData
                        }));
                    })
                    dispatch(actions.setLoading({
                        type: 'organizationFilials',
                        value: false
                    }))
                } else {
                    dispatch(actions.setLoading({
                        type: 'organizationFilials',
                        value: false
                    }))
                }
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationFilials',
                    value: false
                }))
            });
        };
    };

    const getOrganizationBranches = ({branchesSorting, branchesFieldsFilter}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationBranches',
                value: true
            }))
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: null,
                    fieldsFilter: branchesFieldsFilter
                },
                sorting: branchesSorting
            }
            }).then((response) => {
                dispatch(actions.setBranchesData(response.data));
                dispatch(actions.setLoading({
                    type: 'organizationBranches',
                    value: false
                }))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationBranches',
                    value: false
                }))
            });
        };
    };

    const getOrganizationFilialsPartOf = ({fieldsFilter, sorting}) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                    paging: {
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering: {
                        searchString: null,
                        fieldsFilter: fieldsFilter,
                    },
                    sorting,
                }
            }).then((response) => {
                dispatch(actions.setPartOfData({
                    data: response.data,
                    partof: fieldsFilter.partof,
                }));
            }).catch(err => {

            });
        };
    };

    const createOrganization = ({formData, tableSettings}) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}`,
            data: {
                id: formData.id,
                resourceType: "Organization",
                active: true,
                type: [
                    {
                        coding: [
                            {
                                code: "organization",
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Организация"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.profile.code,
                                display: formData.profile.display,
                                system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.fullTitle,
                alias: [
                    formData.title
                ],
                address: [
                    {
                        text: formData.address
                    }
                ]
            }}).then((response) => {
                dispatch(registerMessage({name: 'add-new-organization-message', type: 'primary', title: 'Организация создана', text: formData.title, closable: true, url: '/organizations/organization-' + formData.id}))
                dispatch(showMessage('add-new-organization-message'))
                dispatch(closeMessage({name: 'add-new-organization-message', delay: systemMessageDelay}))
                dispatch(getOrganizationsList({tableSettings}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const deleteOrganization = ({id, tableSettings, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.organization}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'delete-organization-message', type: 'primary', title: 'Организация удалена', closable: true}))
                dispatch(showMessage('delete-organization-message'))
                dispatch(closeMessage({name: 'delete-organization-message', delay: systemMessageDelay}))
                dispatch(getOrganizationsList({tableSettings}));
                callback && callback();
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'delete-organization-error-message', type: 'red', title: 'Не удалось удалить организацию', text: err?.response?.data, closable: true}));
                dispatch(showMessage('delete-organization-error-message'));
                dispatch(closeMessage({name: 'delete-organization-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const editOrganization = ({formData, tableSettings, filialPage}) => {
        return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.organization}`,
            data: {
                id: formData.id,
                resourceType: "Organization",
                active: true,
                identifier: formData.identifier,
                type: [
                    {
                        coding: [
                            {
                                code: "organization",
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Организация"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.profile.code,
                                display: formData.profile.display,
                                system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.fullTitle,
                alias: [
                    formData.title
                ],
                address: [
                    {
                        text: formData.address
                    }
                ]
            }}).then((response) => {
                if (filialPage) {
                    dispatch(getOrganizationById(formData.id));
                } else {
                    dispatch(getOrganizationsList({tableSettings}));
                }
                dispatch(registerMessage({name: 'edit-organization-message', type: 'primary', title: 'Данные обновлены', closable: true, url: filialPage ? 'organizations/organization-' + formData.id : null}))
                dispatch(showMessage('edit-organization-message'))
                dispatch(closeMessage({name: 'edit-organization-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationById = id => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationData',
                value: true
            }))
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex:0,
                    maxItems:10
            },
            filtering:{
                searchString: null,
                fieldsFilter: {
                    id : id,
                    'type:missing': false
                }
            },
            sorting: [
                    {
                        direction: 0,
                        propertyName: "name"
                    }
                ]
            } 
            }).then((response) => {
                dispatch(actions.setOrganizationData(response.data));
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }))
            });
        };
    };

    const getChildOrganiztions = ({ partOfId }) => {
        return (dispatch) => {
    
            dispatch(actions.setLoading({
                type: 'childOrganizations',
                value: true
            }));
    
            fetchInstance({
                method: "POST",
                url: `${api.organization}/_search`,
                data: {
                    paging: {
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering: {
                        fieldsFilter: {
                            partof: partOfId,
                            profile: "",
                            'type:missing': false
                        }
                    },
                }
            }).then((response) => {

                const items = get(response, 'data.items');

                items && dispatch(actions.setChildOrganizations(items));
    
                dispatch(actions.setLoading({
                    type: 'childOrganizations',
                    value: false
                }));
    
            }).catch(err => {
    
                dispatch(actions.setLoading({
                    type: 'childOrganizations',
                    value: false
                }));
    
                console.log(err)
            });
        };
    };

    const createChild = ({formData, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}) => {
        const formDataCopy = cloneDeep(formData);
        if (formData.organizationImage) {
            let imageData = new FormData();
            imageData.append("files", formData.organizationImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    delete formDataCopy.organizationImage;
                    delete formDataCopy.organizationExtension;
                    formDataCopy.organizationExtension = [
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                            valueUrl: response.data[0].url
                        },
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                            valueUrl: response.data[0].url
                        }
                    ]
                    dispatch(createChildOrganizaton({formData: formDataCopy, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else {
            delete formDataCopy.organizationImage;
            delete formDataCopy.organizationExtension;
            formDataCopy.organizationExtension = [];

            return (dispatch) => {
                dispatch(createChildOrganizaton({formData: formDataCopy, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}));
            };
        }
    };

    const createChildOrganizaton = ({formData, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}) => {
        // console.log("formData", formData);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                formData.organizationRank
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'add-new-child-message', type: 'primary', title: type === 'filial' ? 'Филиал создан' : 'Отделение создано', text: formData.organizationName, closable: true}))
                    dispatch(showMessage('add-new-child-message'))
                    dispatch(closeMessage({name: 'add-new-child-message', delay: systemMessageDelay}))

                    type === 'filial' && dispatch(getOrganizationFilials({filialsSorting, filialsFieldsFilter: {...filialsFieldsFilter, partof: formData.organizationPartOf}, childCheckedOrganizations}))
                    type === 'branch' && dispatch(getOrganizationBranches({branchesSorting, branchesFieldsFilter: {...branchesFieldsFilter, partof: formData.organizationPartOf}}))

                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const editChild = ({formData, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}) => {
        // console.log("formData", formData);
        if (formData.organizationImage) {
            let imageData = new FormData();
            imageData.append("files", formData.organizationImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.organizationImage;
                    delete formDataCopy.organizationExtension;
                    formDataCopy.organizationExtension = [
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                            valueUrl: response.data[0].url
                        },
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                            valueUrl: response.data[0].url
                        }
                    ]
                    dispatch(editChildOrganization({formData: formDataCopy, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else if (formData.organizationImage === null) {
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.organizationExtension = [];
                dispatch(editChildOrganization({formData: formDataCopy, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}));
            };
        } else {
            return (dispatch) => {
                dispatch(editChildOrganization({formData, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}));
            };
        }
    };

    const editChildOrganization = ({formData, filialsSorting, branchesSorting, filialsFieldsFilter, branchesFieldsFilter, childCheckedOrganizations, type, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "PUT",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                formData.organizationRank
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'edit-child-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                    dispatch(showMessage('edit-child-message'))
                    dispatch(closeMessage({name: 'edit-child-message', delay: systemMessageDelay}))

                    type === 'filial' && dispatch(getOrganizationFilials({filialsSorting, filialsFieldsFilter: {...filialsFieldsFilter, partof: formData.organizationPartOf}, childCheckedOrganizations}))
                    type === 'branch' && dispatch(getOrganizationBranches({branchesSorting, branchesFieldsFilter: {...branchesFieldsFilter, partof: formData.organizationPartOf}}))

                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    return { getOrganizationsList, getOrganizationFilials, getOrganizationBranches, getOrganizationFilialsPartOf, createOrganization, deleteOrganization, editOrganization, getOrganizationById, getChildOrganiztions, createChild, editChild }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        OrganizationsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };