import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import styled, {css} from "styled-components";

const Group = ({children, gap, direction, align, width, justify}) => {
    return (
        <Wrapper direction={direction} width={width} align={align} justify={justify && justify}>
            {React.Children.map(children, child => {
                return child && React.cloneElement(child, {
                    gap, direction
            })})}
        </Wrapper>
    );
};

const Item = ({children, gap, direction, flex}) => {
    return (
        <ItemWrapper gap={gap} direction={direction} flex={flex}>
            {children}
        </ItemWrapper>
    )
}

Group.Item = Item

const calculateGap = (direction, gap) => {
    switch (direction) {
        case 'row':
            return css`
              margin-right: ${gap}px;
            `
        case 'column':
            return css`
              margin-bottom: ${gap}px;
            `
        default:
            return css`
              margin-right: ${gap}px;
            `
    }
}

const ItemWrapper = styled.div`
  ${({direction, gap}) => calculateGap(direction, gap)};
  flex: ${({flex}) => flex};
  
  :last-child {
    margin: 0;
  }
`

Group.propTypes = {
    children: PropTypes.element.isRequired,
    gap: PropTypes.number,
    direction: PropTypes.string.isRequired,
    align: PropTypes.string,
    width: PropTypes.string,
};

Item.propTypes = {
    children: PropTypes.element.isRequired,
    gap: PropTypes.number,
    flex: PropTypes.string,
};

Group.defaultProps = {
    gap: 4,
    direction: 'row',
    align: 'flex-start',
    width: 'auto',
}

Item.defaultProps = {
    gap: 4,
}

export default Group;
