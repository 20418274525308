import React, {useEffect, useRef, useState} from 'react';
import {Wrapper} from "styled/Wrapper";
import {mainOnko} from './data'
import {mainReg} from './data'
import styled, {css, useTheme} from "styled-components";
import Icon from "components/Icon";
import ArrowIcon from "components/Icons/ArrowIcon";
import ActionIcon from "components/ActionIcon";
import TailArrowIcon from "components/Icons/TailArrowIcon";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import {get, isString} from "lodash";
import {
    clearPeriodFilterState,
    doctorActions,
    getActivePatientData,
    selectActivePatient,
    selectActivePatientData,
    selectLoading
} from "reducers/doctor";
import {useDispatch, useSelector} from "react-redux";
import PeriodFilter from "./PeriodFilter";
import SelectButton from "components/Button/SelectButton";
import {selectFieldsFilter, selectPeriodFilteringLabel} from "reducers/doctor";
import dayjs from "dayjs";
import { CustomView, isDesktop, isTablet, MobileOnlyView } from 'react-device-detect';
import HeaderDoctorMobile from 'pages/doctor/mobile/HeaderDoctorMobile';
import PeriodFilterAction from './mobile/PeriodFilterAction';

const DataFromSIZL = ({setShowMainHeader}) => {
    const mainData = mainOnko;
    const [data, setData] = useState(mainData);
    const [title, setTitle] = useState(false);
    const [filter, setFilter] = useState(false);
    const [level, setLevel] = useState(0);
    const [prevLevel, setPrevLevel] = useState(0);
    const [scrollbarHeight, setScrollbarHeight] = useState(0);
    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const loading = useSelector(selectLoading);
    const ref = useRef();
    const dispatch = useDispatch();
    const fieldsFilter = useSelector(selectFieldsFilter);
    const periodFilteringLabel = useSelector(selectPeriodFilteringLabel);
    const currentYear = dayjs().format("YYYY");
    const startYear = dayjs(fieldsFilter.startDate).format("YYYY");
    const endYear = dayjs(fieldsFilter.endDate).format("YYYY");

    useEffect(() => {
        let id = get(activePatient, "patientId");
        dispatch(getActivePatientData(id));
        let code = get(activePatient, "code"); //узнаем код диагноза
        if (code === 'Z33') {
            setData(mainReg)
        }
        else {
            setData(mainOnko)
        }
        setTitle(false);
        setFilter(false);
    }, [activePatientData.UKL, activePatient]);

    const theme = useTheme()

    useEffect(() => {
        if (ref.current) {
            setScrollbarHeight(ref.current.offsetHeight + 90);
        }
        setShowMainHeader && setShowMainHeader(!title)
    }, [title]);

    const handlePage = (data, title, filter, prevLevel) => {
        setPrevLevel(prevLevel);
        if (title) {
            setData(data);
            setTitle(title);
            setFilter(filter);
            setLevel(prevLevel+1);
        }
        else {
            setLevel(prevLevel-1);
            dispatch(doctorActions.setLoading({
                type: 'SIZLData',
                value: false
            }));
            let code = get(activePatient, "code"); //узнаем код диагноза
            if (data?.props?.parentId !== undefined) {
                if (code === 'Z33') {
                    setData(mainReg[data.props.parentId].list)
                    setTitle(mainReg[data.props.parentId].title);
                }
                else {
                    setData(mainOnko[data.props.parentId].list)
                    setTitle(mainOnko[data.props.parentId].title);
                }
                if (data?.props?.showFilterForParent) {
                    setFilter(true);
                }
            }
            else {
                if (code === 'Z33') {
                    setData(mainReg)
                }
                else {
                    setData(mainOnko)
                }
                setTitle(false);
                dispatch(clearPeriodFilterState());
                setFilter(filter);
            }
        }
    }

    const getStartDateToShow = () => {
        if (startYear !== currentYear || startYear !== endYear) {
            return dayjs(fieldsFilter.startDate).locale("ru").format("D MMMM YYYY");
        } else {
            return dayjs(fieldsFilter.startDate).locale("ru").format("D MMMM");
        }
    }

    const getEndDateToShow = () => {
        if (endYear !== currentYear || startYear !== endYear) {
            return dayjs(fieldsFilter.endDate).locale("ru").format("D MMMM YYYY")
        } else {
            return dayjs(fieldsFilter.endDate).locale("ru").format("D MMMM")
        }
    }

    const showPeriod = () => {
        return periodFilteringLabel === "Другое..." ? getStartDateToShow() + " - " + getEndDateToShow() : periodFilteringLabel
    }

    const renderPeriodFilterAction = () => {
        return (
            <PeriodFilterAction/>
        )
    }

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper paddings={'8px 0'}>
                    {
                        title ?
                            filter ?
                                <Title ref={ref}>
                                    <ActionIconWrapperLeft>
                                        <ActionIcon action={() => handlePage(data, false, false, level)} icon={TailArrowIcon} rotate={'90'} />
                                    </ActionIconWrapperLeft>
                                    <Wrapper align={'center'} justify={'center'} paddings={'6px 0 0px 0'}>
                                        {title}
                                        <SelectButton
                                            component={PeriodFilter}
                                            label={showPeriod()}
                                            onChange={() => {}}
                                        />
                                    </Wrapper>
                                </Title>
                                :
                                <Title ref={ref}>
                                    <ActionIconWrapperLeft>
                                        <ActionIcon action={() => handlePage(data, false, false, level)} icon={TailArrowIcon} rotate={'90'} />
                                    </ActionIconWrapperLeft>
                                    {title}
                                </Title>
                            :
                            ''
                    }
                    <Wrapper maxHeight={'calc(100vh - ' + scrollbarHeight + 'px)'}>
                        <PerfectScrollbarWrapper>
                            {data instanceof Array ?
                                <>
                                    {
                                        data.map(item => {
                                            let nextData = item.list ? item.list : <item.info {...item.infoProps} />;
                                            return (
                                                <ListItem onClick={() => !loading?.SIZLData && handlePage(nextData, item.title, item.filter, level)} listInComponent={item.listInComponent && item.listInComponent}>
                                                    {item.icon &&
                                                        <IconWrapper>
                                                            <Icon icon={item.icon} size={24} color={theme.colors.primary} />
                                                        </IconWrapper>
                                                    }
                                                    <ListItemWrapper isComponent={!isString(item.text)} listInComponent={item.listInComponent && item.listInComponent} loadingData={loading?.SIZLData}>
                                                        {!isString(item.text) ?
                                                            <item.text level={level} prevLevel={prevLevel}/>
                                                            :
                                                            item.text
                                                        }
                                                        {item.showIcon &&
                                                            <Icon icon={ArrowIcon} size={24} rotate={-90} color={theme.colors.black24}/>
                                                        }
                                                    </ListItemWrapper>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </>
                                :
                                <Wrapper>{data}</Wrapper>
                            }
                        </PerfectScrollbarWrapper>
                    </Wrapper>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                {title ?
                    <HeaderDoctorMobile
                        center={title}
                        action={() => handlePage(data, false, false, level)}
                        subtitle={filter && showPeriod()}
                        rightSide={filter ? renderPeriodFilterAction : null}
                    />
                    :
                    ''
                }
                <Wrapper>
                    <Wrapper maxHeight={'calc(var(--vh, 1vh) * 100 - 100px)'}>
                        <PerfectScrollbarWrapper>
                            {data instanceof Array ?
                                <>
                                    {
                                        data.map(item => {
                                            let nextData = item.list ? item.list : <item.info {...item.infoProps} />;
                                            return (
                                                <ListItem onClick={() => !loading?.SIZLData && handlePage(nextData, item.title, item.filter, level)} listInComponent={item.listInComponent && item.listInComponent}>
                                                    {item.icon &&
                                                        <IconWrapper>
                                                            <Icon icon={item.icon} size={24} color={theme.colors.primary} />
                                                        </IconWrapper>
                                                    }
                                                    <ListItemWrapper isComponent={!isString(item.text)} listInComponent={item.listInComponent && item.listInComponent}>
                                                        {!isString(item.text) ?
                                                            <item.text level={level} prevLevel={prevLevel}/>
                                                            :
                                                            item.text
                                                        }
                                                    </ListItemWrapper>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </>
                                :
                                <Wrapper>{data}</Wrapper>
                            }
                        </PerfectScrollbarWrapper>
                    </Wrapper>
                </Wrapper>
            </MobileOnlyView>
        </>
    );
};

const IconWrapper = styled.div`
  margin-right: 16px;
`

const ListItemWrapper = styled.div`
    min-height: ${({isComponent}) => isComponent ? 'unset;' : '40px;'}
    padding: 8px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.mixins.innerShadow()}
    
    ${({listInComponent}) => listInComponent && css`
        box-shadow: none;
        flex-direction: column;
        height: auto;
        width: 100%;
    `}
    ${({loadingData}) => loadingData && css`
        padding: 0;
    `}
    ${({theme}) => theme.isMobile && css`
        min-height: ${({isComponent}) => isComponent ? 'unset;' : '50px;'}
        padding: 0;
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineHeight: theme.fonts.lineHeight.h5})};
    `}
`

const ListItem = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    position: relative;
    
    ${({listInComponent}) => !listInComponent && css`
        padding: 0 16px;
        cursor: pointer;
        
        &:hover {
            background: ${({theme}) => theme.colors.black04};
            ${({theme}) => theme.mixins.innerShadow()}
            
            ${ListItemWrapper} {
                box-shadow: none;
            }
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: #e0e0e0;
                position: absolute;
                top: -1px;
                left: 0;
            }
        }
    `}
`

const ActionIconWrapperLeft = styled.div`
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 2;
`

const Title = styled.div`
    padding-bottom: 8px;
    margin-bottom: 8px;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({theme}) => theme.mixins.robotoMedium({size: '16px'})};
    ${({theme}) => theme.mixins.innerShadow()};
`

export default DataFromSIZL;
