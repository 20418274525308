import {isNumber} from "lodash";
import dayjs from "dayjs";

export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

export const requiredValidator = value => {
    if (!value || (typeof value === 'string' && !value.trim()) || (!(typeof value === 'object' && value !== null) && (value && (!value.length > 0)))) {
        return 'Поле является обязательным для заполнения';
    } else {
        return undefined;
    }}

export const numericValidator = value => (value && !isNumber(value))
    ? 'Поле должно быть числом'
    : undefined;

export const numericStringValidator = value => (value && !/^[0-9]*$/i.test(value))
    ? 'Поле должно содержать только цифры'
    : undefined;

export const emailValidator = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? 'Невалидный email'
    : undefined;

// export const phoneValidator = value => (value && !/^(\+7|7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/i.test(value))
export const phoneValidator = value => (value && !/^(\+7|7|8)?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/i.test(value))
    ? 'Неверный формат номера телефона.'
    : undefined;

export const snilsValidator = value => {
    // var re = /[\+]|[\-]|[\ ]|[\_]\w+/g; //Регулярное выражение, которое находит разделительные символы, если такие есть в получаемом значении
    var re = /[+]|[-]|[ ]|[_]\w+/g; //Регулярное выражение, которое находит разделительные символы, если такие есть в получаемом значении
    var checkSum = parseInt(value.split(re).join('').slice(9), 10);
    value = value.split(re).join('');

    var sum = (value[0] * 9 + value[1] * 8 + value[2] * 7 + value[3] * 6 + value[4] * 5 + value[5] * 4 + value[6] * 3 + value[7] * 2 + value[8] * 1);

    if (sum < 100 && sum === checkSum) {
        return undefined;
    } else if ((sum === 100 || sum === 101) && checkSum === 0) {
        return undefined;
    } else if (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0))) {
        return undefined;
    } else {
        return 'Введен некорректный СНИЛС';
    }
}
export const min6Validator = value => (value.length < 6)
    ? 'Поле должно содержать не менее 6 символов'
    : undefined;

export const moreThanDateFrom = startDate => value => (dayjs(value).isBefore(startDate, 'd'))
        ? 'Дата окончания не должна быть меньше даты начала'
        : undefined;

export const lessThanDateBefore = endDate => value => (dayjs(value).isAfter(endDate, 'd'))
        ? 'Дата начала не должна быть больше даты окончания'
        : undefined;

export const passwordsMustDiffer = values => value => values.newPassword === values.password 
        ? 'Пароль должен отличаться от существующего' 
        : undefined

export const passwordsMustMatch = values => value => value !== values.newPassword 
        ? 'Пароли не совпадают' 
        : undefined