import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { get } from "lodash";
import dayjs from "dayjs";

import { closeMessage, registerMessage, showMessage } from "reducers/systemMessages/systemMessagesSlice";
import { routes } from "consts/routes";
import { systemMessageDelay } from 'consts';

const initialState = {
    data: {},
    loading: {
        data: true,
        resendMessages: false
    }
}

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    resetState: (state, action) => {
        return initialState
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
};

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});


export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectLoading: state => get(state, statePath)?.loading,
})

export const getThunks = (actions) => {

    const getData = ({ mailingId }) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            fetchInstance({
                method: "GET",
                url: `${api.mailingList}/Get/${mailingId}`,
            }).then((response) => {
                dispatch(actions.setData(response?.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const resendMessage = ({messageId}) => {
        return dispatch => {
            dispatch(actions.setLoading({
                type: 'resendMessage',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.mailing}/Resend/${messageId}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'resend-mailing-list-message', type: 'primary', title: 'Сообщение отправлено повторно', closable: true}))
                dispatch(showMessage('resend-mailing-list-message'))
                dispatch(closeMessage({name: 'resend-mailing-list-message', delay: systemMessageDelay}))
                dispatch(actions.setLoading({
                    type: 'resendMessage',
                    value: false
                }));
            }).catch(err => {
                dispatch(registerMessage({name: 'resend-mailing-list-message-error', type: 'red', title: 'Не удалось повторно отправить сообщение', closable: true}))
                dispatch(showMessage('resend-mailing-list-message-error'))
                dispatch(closeMessage({name: 'resend-mailing-list-message-error', delay: systemMessageDelay}))
                dispatch(actions.setLoading({
                    type: 'resendMessage',
                    value: false
                }));
            });
        }
    }

    const resendAllMessages = ({mailingListId, callback}) => {
        return dispatch => {
            dispatch(actions.setLoading({
                type: 'resendAllMessages',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.mailingList}/ResendErrorMessages/${mailingListId}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'resend-mailing-list-message', type: 'primary', title: 'Сообщения отправлены повторно', closable: true}))
                dispatch(showMessage('resend-mailing-list-message'))
                dispatch(closeMessage({name: 'resend-mailing-list-message', delay: systemMessageDelay}))
                callback && callback();
                dispatch(actions.setLoading({
                    type: 'resendAllMessages',
                    value: false
                }));
            }).catch(err => {
                dispatch(registerMessage({name: 'resend-mailing-list-message-error', type: 'red', title: 'Не удалось повторно отправить сообщения', closable: true}))
                dispatch(showMessage('resend-mailing-list-message-error'))
                dispatch(closeMessage({name: 'resend-mailing-list-message-error', delay: systemMessageDelay}))
                dispatch(actions.setLoading({
                    type: 'resendAllMessages',
                    value: false
                }));
            });
        }
    }

    const deleteMailing = ({mailingId, callback}) => {

        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.mailingList}/Delete/${mailingId}`,
        }).then((response) => {
                callback && callback();
                dispatch(registerMessage({name: 'delete-mailing-message', type: 'primary', title: 'Рассылка успешно удалена', closable: true}))
                dispatch(showMessage('delete-mailing-message'))
                dispatch(closeMessage({name: 'delete-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'delete-mailing-error-message', type: 'red', title: 'Не удалось удалить рассылку', closable: true}))
                dispatch(showMessage('delete-mailing-error-message'))
                dispatch(closeMessage({name: 'delete-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    const cancelMailing = ({mailingId, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/Cancel/${mailingId}`,
        }).then((response) => {
                dispatch(getData({ mailingId }));
                callback && callback();
                dispatch(registerMessage({name: 'delete-mailing-message', type: 'primary', title: 'Рассылка успешно отменена', closable: true}))
                dispatch(showMessage('delete-mailing-message'))
                dispatch(closeMessage({name: 'delete-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'delete-mailing-error-message', type: 'red', title: 'Не удалось отменить рассылку', closable: true}))
                dispatch(showMessage('delete-mailing-error-message'))
                dispatch(closeMessage({name: 'delete-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    const resendMailing = ({mailingId, callback}) => {

        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/ResendAllMessages/${mailingId}`,
        }).then((response) => {
                dispatch(getData({ mailingId }));
                callback && callback();
                dispatch(registerMessage({name: 'resend-mailing-message', type: 'primary', title: 'Рассылка успешно отправлена повторно', closable: true}))
                dispatch(showMessage('resend-mailing-message'))
                dispatch(closeMessage({name: 'resend-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'resend-mailing-error-message', type: 'red', title: 'Не удалось повторно отправить рассылку', closable: true}))
                dispatch(showMessage('resend-mailing-error-message'))
                dispatch(closeMessage({name: 'resend-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    return { getData, resendMessage, deleteMailing, cancelMailing, resendAllMessages, resendMailing }

}

export const build = (name, storePath, thunksFromAnotherReducer) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions, thunksFromAnotherReducer);

    return {
        mailActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
