import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icon";
import styled, {css} from "styled-components";
import Error from "../Error";
import {Wrapper} from "styled/Wrapper";

const Input = ({value, active, disabled, defaultValue, onChange, onFocus, onBlur, label, placeholder, icon, iconSize, iconPosition, iconAction, error, type, showError, iconShowOnType, focus, required, onKeyDown, textAlign, focusUnderline, focused}) => {

    const [showIcon, setShowIcon] = useState(true);
    const [inputValue, changeInputValue] = useState(defaultValue);
    const ref = useRef();

    useEffect(() => {
        changeInputValue(value);
    }, [value])

    useEffect(() => {
        onChange(inputValue);

        if(iconShowOnType) {
            setShowIcon(!!inputValue)
        }
    }, [iconShowOnType, inputValue])

    const clearValue = () => {
        changeInputValue('');
        ref.current.focus();
    }

    return (
        <Wrapper paddings={label && '22px 0 0 0'}>
            <Field active={active} underline={focused && focusUnderline}>
                {label && <Label>
                    {label}
                    {required &&
                    <i> *</i>
                    }
                </Label>}
                {icon && showIcon && <IconWrapper
                    position={iconPosition}>
                    <Icon
                        icon={icon}
                        size={iconSize}
                        action={iconAction ? iconAction : () => clearValue()}
                    />
                </IconWrapper>}
                <InputWrapper position={iconPosition} textAlign={textAlign} icon={icon}>
                    <input
                        autoFocus={focus}
                        type={type}
                        value={inputValue}
                        onChange={!disabled ? e => changeInputValue(e.target.value) : () => {}}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        ref={ref}
                    />
                </InputWrapper>
            </Field>
            <ErrorWrapper>
                <Error message={error} show={showError} />
            </ErrorWrapper>
        </Wrapper>
    );
};

const Field = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.black04};
  ${({theme}) => theme.mixins.borderRadius()};
  box-shadow: ${({theme, active}) => active ? `0px 2px 0px ${theme.colors.primary}` : 'none'};
  border-bottom-left-radius: ${({theme, active}) => active ? '0' : theme.mixins.borderRadius()};
  border-bottom-right-radius: ${({theme, active}) => active ? '0' : theme.mixins.borderRadius()};
  ${({underline}) => underline && css`
    ${({theme}) => theme.mixins.borderRadius(`${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`)};
  `};

  box-shadow: inset ${({theme, underline}) => underline ? `0px -2px 0px ${theme.colors.primary}` : 'none'};
`

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};
  position: absolute;
  left: 0;
  top: -22px;

  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  ${({position}) => renderIcon(position)};
  width: 18px;
  height: 18px;
  ${({theme}) => theme.isMobile && css`top: 11px`};
`

const calculatePadding = (position, icon, isMobile) => {
    if (icon) {
        if (isMobile) {
            return position === 'left' ? '10px 10px 10px 24px' : '10px 30px 10px 10px'
        }
        else {
            return position === 'left' ? '6px 10px 6px 24px' : '6px 30px 6px 10px'
        }
    }
    else {
        if (isMobile) {
            return position === 'left' ? '10px 10px 10px 24px' : '10px 10px 10px 10px'
        }
        else {
            return position === 'left' ? '6px 10px 6px 24px' : '6px 10px 6px 10px'
        }
    }
}

/*const autofill = keyframes`
  to {
    color: red;
    background-color: red !important;;
    border: 1px solid red;
  }
  :-webkit-autofill, :-internal-autofill-selected {
      animation-delay: 10s;
      animation-name: ${autofill};
      animation-fill-mode: both;
    }
`;*/

const InputWrapper = styled.div`
  width: 100%;

  input {
    filter: none;
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
    padding: ${({position, icon, theme}) => calculatePadding(position, icon, theme.isMobile)};
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5})};
    `};
    ::placeholder {
      color: ${({theme}) => theme.colors.black30};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #0000 inset;
      transition: background-color 50000s ease-in-out 0s;
    }
    &::-ms-reveal {
      display: none;
    }
  }
`

const ErrorWrapper = styled.div`

`

const renderIcon = (position) => {
    switch (position) {
        case 'left':
            return css`
                left: 8px;
                top: 7px;
            `
        case 'right':
            return css`
                right: 8px;
                top: 7px;
            `
        default:
            return css`
                left: 8px;
                top: 7px;
            `
    }
}

Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.element,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconAction: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    error: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    iconShowOnType: PropTypes.bool,
};

Input.defaultProps = {
    type: 'text',
    iconPosition: 'right',
    iconAction: undefined,
    iconShowOnType: false,
}

export default Input;
