import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {findInTypeBySystem } from "utils";
import {find, get, cloneDeep} from "lodash";
import dayjs from "dayjs";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import {routes} from "consts/routes";
import { systemMessageDelay } from 'consts';

const initialState = {
    data: [],
    all: {
        tableSettings: {
            fieldsFilter: {
                extended: true,
                approle: 'AppRole/organization-admin',
                practitionerRoleStatus: "active,awaiting,archived",
                'type:missing': false
            },
            searchString: '',
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    active: {
        tableSettings: {
            fieldsFilter: {
                extended: true,
                approle: 'AppRole/organization-admin',
                practitionerRoleStatus: "active,awaiting",
                'type:missing': false
            },
            searchString: '',
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    archived: {
        tableSettings: {
            fieldsFilter: {
                extended: true,
                approle: 'AppRole/organization-admin',
                practitionerRoleStatus: "archived",
                'type:missing': false
            },
            searchString: '',
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    managersType: {},
    catalogs: {
        genders: [],
        organizations: [],
        roles: [],
    },
    manager: {
        data: {},
        details: {}
    },
    loading: {
        fullPage: true,
        data: true,
        managerFullPage: true,
        managerDetails: true
    },
    saveThisPageFilter: false,
    managerRoleToArchive: {},
    adminLogin: null
};

export const managersSlice = createSlice({
    name: 'managers',
    initialState,
    reducers: {
        managersType: (state, action) => {
            state.managersType = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setSearchString: (state, action) => {
            state[action.payload.table].tableSettings.searchString = action.payload.value;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setOrganizationsToFilter: (state, action) => {
            if (action.payload.value) {
                state[action.payload.table].tableSettings.fieldsFilter.organizationId = action.payload.value;
                state[action.payload.table].tableSettings.paging.startIndex = 0;
            } else {
                delete state[action.payload.table].tableSettings.fieldsFilter.organizationId;
                state[action.payload.table].tableSettings.paging.startIndex = 0;
            }
        },
        setGenderCatalog: (state, action) => {
            var genders = action.payload.filter(item => (item.code === 'male' || item.code === 'female'))
            state.catalogs.genders = genders;
        },
        setOrganizationsCatalog: (state, action) => {
            state.catalogs.organizations = action.payload;
        },
        setRolesCatalog: (state, action) => {
            state.catalogs.roles = action.payload;
        },
        setSorting: (state, action) => {
            state[action.payload.table].tableSettings.sorting[0].propertyName = action.payload.propertyName;
            state[action.payload.table].tableSettings.sorting[0].direction = state[action.payload.table].tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setStatusesToFilter: (state, action) => {
            state[action.payload.table].tableSettings.fieldsFilter.practitionerRoleStatus = action.payload.value;
        },
        setManagersToFilter: (state, action) => {
            action.payload.value ? state[action.payload.table].tableSettings.fieldsFilter.practitionerId = action.payload.value : delete state[action.payload.table].tableSettings.fieldsFilter.practitionerId;
        },
        setRolesToFilter: (state, action) => {
            action.payload.value ? state[action.payload.table].tableSettings.fieldsFilter.positionCode = action.payload.value : delete state[action.payload.table].tableSettings.fieldsFilter.positionCode;
        },
        setPage: (state, action) => {
            state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
        },
        setMaxItems: (state, action) => {
            state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
        },
        clearTableSettings: (state, action) => {
            if (!state.saveThisPageFilter) {
                state.active.tableSettings = initialState.active.tableSettings;
                state.archived.tableSettings = initialState.archived.tableSettings;
            }
        },
        setSaveThisPageFilter: (state, action) => {
            state.saveThisPageFilter = action.payload;
        },
        setManagerRoleToArchive: (state, action) => {
            state.managerRoleToArchive = action.payload;
        },
        setManagerDetails: (state, action) => {
            state.manager.details = action.payload;
        },
        setManagerData: (state, action) => {
            state.manager.data = action.payload;
        },
        resetManager: (state, action) => {
            state.manager = initialState.manager;
        },
        resetStateExceptTableSettings: (state, action) => {
            const initialStateCopy = cloneDeep(initialState);
            const tableSettingsActiveCopy = cloneDeep(state.active.tableSettings);
            const tableSettingsArchievedCopy = cloneDeep(state.archived.tableSettings);
            delete initialStateCopy.active.tableSettings;
            delete initialStateCopy.archived.tableSettings;
            initialStateCopy.active.tableSettings = tableSettingsActiveCopy;
            initialStateCopy.archived.tableSettings = tableSettingsArchievedCopy;
            return initialStateCopy;
        },
        setAdminLogin: (state, action) => {
            state.adminLogin = action.payload;
        },
        resetState: (state, action) => {
            return initialState
        },
    },
});

export const selectType = state => state.fundAdmin.managers.managersType;
export const selectTableSettings = mode => state => state.fundAdmin.managers[mode]?.tableSettings;
export const selectFieldsFilter = state => state.fundAdmin.managers.tableSettings.fieldsFilter;
export const selectSorting = state => state.fundAdmin.managers.tableSettings.sorting;
export const selectData = state => state.fundAdmin.managers.data;
export const selectCatalogs = state => state.fundAdmin.managers.catalogs;
export const selectSearchString = mode => state => state.fundAdmin.managers[mode]?.tableSettings.searchString;
export const selectLoading = state => state.fundAdmin.managers.loading;
export const selectManagerRoleToArchive = state => state.fundAdmin.managers.managerRoleToArchive;
export const selectManagerDetails = state => state.fundAdmin.managers.manager.details;
export const selectAdminLogin = state => state.fundAdmin.managers.adminLogin;


export const ManagersActions = managersSlice.actions;

export const getManagers = ({tableSettings}) => {
    return (dispatch) => {
        dispatch(ManagersActions.setLoading({
            type: 'data',
            value: true
        }))
      fetchInstance({
        method: "POST",
        url: `${api.organizationHeads}`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,
                fieldsFilter: tableSettings.fieldsFilter
            },
            sorting: tableSettings.sorting,
        }}).then((response) => {
            dispatch(ManagersActions.setData(response.data));
            dispatch(ManagersActions.setLoading({
                type: 'data',
                value: false
            }))
        }).catch(err => {
            dispatch(ManagersActions.setLoading({
                type: 'data',
                value: false
            }))
        });
    };
};

export const deleteManager = ({id, tableSettings}) => {
    return (dispatch) => {
      fetchInstance({
        method: "DELETE",
        url: `${api.practitionerRole}/${id}`,
    }).then((response) => {
            dispatch(registerMessage({name: 'delete-manager-message', type: 'primary', title: 'Руководитель удален', closable: true, url: routes.private.fund_admin.managers.path}))
            dispatch(showMessage('delete-manager-message'))
            dispatch(closeMessage({name: 'delete-manager-message', delay: systemMessageDelay}))
            dispatch(getManagers({tableSettings}));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const archiveManager = ({item, tableSettings}) => {
    return (dispatch) => {
      fetchInstance({
        method: "PUT",
        url: api.practitionerRole,
        data: item
    }).then((response) => {
            dispatch(getManagers({tableSettings}));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const restoreManager = ({id, tableSettings, restoreOrganizationId}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${id}/RestoreFromArchive`,
        params: {
            organizationId: restoreOrganizationId
        }
    }).then((response) => {
            dispatch(getManagers({tableSettings}));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPractitionerRole = (id) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
            dispatch(ManagersActions.setManagerRoleToArchive(response.data));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const createOrUpdateManagerData = ({formData, tableSettings, callback, isEdit}) => {
    return (dispatch) => {
        dispatch(ManagersActions.setLoading({
            type: 'createOrUpdateManager',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/createorupdateorgmanager`,
            data: {
                practitionerInfo: {
                    practitionerId: formData?.practitionerId,
                    fio: formData?.name,
                    gender: formData?.gender,
                    isActive: true,
                    photoUrl: formData?.practitionerPhoto || null,
                },
                practitionerRoleInfo: {
                    positionCode: formData?.role,
                    positionDisplay: formData?.roleName,
                    practitionerRoleId: formData?.id,
                    statusCode: formData?.status?.valueCoding?.code,
                    statusDisplayCode: formData?.status?.valueCoding?.display,
                    isActive: true,
                    organizationId: formData?.organization,
                    notifyWithEmail: true,
                    contactEmail: formData?.email,
                    phone: formData?.phone,
                    // loginEmail: formData?.loginEmail
                },
                appRole: 'organization-admin'
            }
        }).then((response) => {
            dispatch(ManagersActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            tableSettings && dispatch(getManagers({ tableSettings }));
            callback && callback();

            dispatch(registerMessage({
                name: 'create-update-doctor-message',
                type: 'primary',
                title: `Руководитель МО успешно ${isEdit ? 'обновлен' : 'создан'}`,
                closable: true,
            }))
            dispatch(showMessage('create-update-doctor-message'))
            dispatch(closeMessage({name: 'create-update-doctor-message', delay: systemMessageDelay}))
        }).catch(err => {
            dispatch(ManagersActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            dispatch(registerMessage({
                name: 'create-update-doctor-error-message',
                type: 'red',
                title: `Не удалось ${isEdit ? 'обновить' : 'создать'} руководителя МО`,
                closable: true,
            }))
            dispatch(showMessage('create-update-doctor-error-message'))
            dispatch(closeMessage({name: 'create-update-doctor-error-message', delay: systemMessageDelay}))

            console.log(err)
        });
    }
}

export const getGenders = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.gender,
    }).then((response) => {
            // const items = [...response.data.items];
            // items.map(item => {
            //     item.label = capitalizeFirstLetter(item.display);
            // });
            dispatch(ManagersActions.setGenderCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPractitionRoles = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.practitionRoles,
    }).then((response) => {
            // console.log("response.data.items", response.data.items);
            // const items = [...response.data.items];
            // items.map(item => {
            //     item.label = capitalizeFirstLetter(item.display);
            // });
            dispatch(ManagersActions.setRolesCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const sendMessage = ({text, subject, recipientPractitionerRoleId, senderPractitionerRoleId, hideModal}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/fomsnotification`,
            data: {
                messageText: text,
                subject,
                recipientPractitionerRoleId: recipientPractitionerRoleId,
                senderPractitionerRoleId: senderPractitionerRoleId
            }
        }).then((response) => {
            dispatch(hideModal);
            dispatch(registerMessage({name: 'send-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
            dispatch(showMessage('send-message'))
            dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'send-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
            dispatch(showMessage('send-message'))
            dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
        });
    };
};

export const getOrganizations = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.organization}?fg=type:missing::false`,
    }).then((response) => {
            // const items = [...response.data.items];
            // items.map(item => {
            //     item.label = capitalizeFirstLetter(item.name);
            // });
            dispatch(ManagersActions.setOrganizationsCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const sendActivationNotification = id => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.activationNotification}/${id}`
        }).then((response) => {
            dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
            dispatch(showMessage('activation-notification-message'))
            dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
            dispatch(showMessage('activation-notification-error-message'))
            dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
        });
    };
};

export const getManager = (id) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}?fg=practitioner::${id}:x:_include::practitioner,organization`
        }).then((response) => {

            let managerDetails = {};

            if (response.data.items) {
                const item = response.data.items.find(item => item.practitioner.id === id);
                // console.log("item", item);
                managerDetails = {
                    id,
                    name: get(response.data, `resources[${get(item, "practitioner.reference")}].name[0].text`),
                    statusObj: find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"}),
                    gender: get(response.data, `resources[${get(item, "practitioner.reference")}].gender`),
                    email: find(item?.telecom, {system: "email"})?.value,
                    organization: get(response.data, `resources[${get(item, "organization.reference")}].name`),
                    phone: find(item?.telecom, {system: "phone"})?.value,
                    role: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.display,
                    roleCode: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.code,
                    created: dayjs(find(item?.meta?.extension, {url: "ex:createdAt"})?.valueInstant).locale("ru").format("D MMMM YYYY"),
                };
            }

            dispatch(ManagersActions.setManagerDetails(managerDetails));
            dispatch(ManagersActions.setManagerData(response.data));
            // dispatch(ManagerActions.setLoading({
            //     type: 'managerFullPage',
            //     value: false
            // }))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const resetPassword = (email, userType) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.resetPassword}/${email}`,
        params: {
            usertype: userType
        }
    }).then((response) => {
          dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
          dispatch(showMessage('send-email-message'))
          dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
    }).catch((error) => {
          dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
          dispatch(showMessage('reset-password-error-message'));
          dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
    })
    };
};

export const getAdminLogin = ({roleId}) => {
    return (dispatch) => {
    fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
        }).then((response) => {
            const login = get(response, 'data.value');
            login && dispatch(ManagersActions.setAdminLogin(login));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default managersSlice.reducer;
