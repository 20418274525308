import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import {Row} from "styled/Row";
import styled, {useTheme} from "styled-components";

const TableChannelsTooltip = ({amount, doesNotMatch, match}) => {

    const theme = useTheme()

    return (
        <Wrapper>
            <Row marginBottom={'0px'}>
                <Number color={theme.colors.white100}>
                    {amount}
                </Number>
                <Text>
                    ・Активных каналов
                </Text>
            </Row>
            <Row marginBottom={'0px'}>
                <Number color={theme.colors.secondary}>
                    {match}
                </Number>
                <Text>
                    ・Соответствуют протоколу
                </Text>
            </Row>
            <Row marginBottom={'0px'}>
                <Number color={theme.colors.red}>
                    {doesNotMatch}
                </Number>
                <Text>
                    ・Не соответствуют протоколу
                </Text>
            </Row>
        </Wrapper>
    );
};

const Number = styled.div`
    ${({theme, color}) => theme.mixins.robotoCondensedBold({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small, color})};
`

const Text = styled.div`
  ${({theme}) => theme.mixins.robotoCondensedRegular({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small, color: theme.colors.white100})};
  white-space: nowrap;
`

TableChannelsTooltip.propTypes = {
    stats: PropTypes.object.isRequired,
};

export default TableChannelsTooltip;
