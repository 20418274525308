import React from 'react';
import PropTypes from 'prop-types';
import { formatSize, getFileByUrl } from 'utils';
import { get } from 'lodash';
import styled, { useTheme } from 'styled-components';
import ActivitiesBox from 'components/ActivitiesBox';
import fileDownload from 'react-file-download';
import Text from 'components/Text';
import {isMobileOnly} from "react-device-detect";

const FileMobile = ({file, actions, customDownload}) => {

    const theme = useTheme();

    const handleDownloadConsentClick = (file) => {
        getFileByUrl(file?.url).then(response => {
            const buffer = Buffer.from(response.data, 'binary');
            fileDownload(buffer, file?.title, response?.headers["content-type"])
        })
    }

    return (
        <FileBox>
            <LeftWrapper 
                onClick={() => customDownload ? customDownload(file) : handleDownloadConsentClick(file)}
            >
                <FileType>{get(file, 'title', '').slice(get(file, 'title', '').lastIndexOf('.')+1)}</FileType>
                <TextWrapper>
                    <Text tag={isMobileOnly ? 'h5' : 'text'} text={get(file, 'title', '')} font={'robotoRegular'}/>
                    <Text tag={isMobileOnly ? 'text' : 'small'} text={formatSize(get(file, 'size', ''))} color={theme.colors.black54} />
                </TextWrapper>
            </LeftWrapper>
            <ActivitiesBoxWrapper>
                {actions && 
                    <ActivitiesBox
                        data={{file}}
                        actions={actions} 
                        colorHover={theme.colors.primary12}
                        iconColorHover={theme.colors.primary}
                        popupPosition="left"
                    />
                }
            </ActivitiesBoxWrapper>
        </FileBox>
    )
};

const TextWrapper = styled.div``
const LeftWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const ActivitiesBoxWrapper = styled.div``

const FileBox = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
`

const FileType = styled.div`
    background: ${({theme}) => theme.colors.primary12};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 44px;
    height: 44px;
    border-radius: 6px;
    margin-right: 12px; 
    ${({theme}) => theme.mixins.robotoCondensedBold({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.h5, color: theme.colors.primary})};
`

FileMobile.propTypes = {
    file: PropTypes.object.isRequired,
    actions: PropTypes.object
};

export default FileMobile;
