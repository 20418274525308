import { createSlice } from '@reduxjs/toolkit';

export const tableSlice = createSlice({
    name: 'table',
    initialState: {
        index: -1,
    },
    reducers: {
        setIndex: (state, action) => {
            return {
                index: action.payload
            }
        },
        removeIndex: (state) => {
            return {
                index: -1,
            }
        },
    },
});

export const { setIndex, removeIndex } = tableSlice.actions;

export default tableSlice.reducer;
