import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { findElementByElementId } from "utils/organizations";
import { organizationType, systemMessageDelay } from 'consts';
import {get, cloneDeep} from "lodash";
import { findInTypeBySystem } from 'utils';
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";

const initialState = {
    filials: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                partof: null,
                profile: "",
                type: organizationType.clinic,
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "name", 
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0, 
                maxItems: 10
            },
        },
        show: false,
    },
    branches: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                partof: null,
                profile: "",
                type: organizationType.department,
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "name", 
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0, 
                maxItems: 10
            },
        },
        show: false,
    },
    organizationName: '',
    organizationProfile: '',
    organizationObj: {},
    loading: {
        fullPage: true,
        filialsData: true,
        branchesData: true,
        organizationDetails: true
    },
    childCheckedOrganizations: undefined,
}

export const mainOrganizationAdminSlice = createSlice({
    name: 'mainOrganizationAdmin',
    initialState,
    reducers: {
        setOrganizationName: (state, action) => {
            state.organizationName = action.payload;
        },
        setOrganizationProfile: (state, action) => {
            state.organizationProfile = action.payload;
        },
        setFilialsPartOf: (state, action) => {
            state.filials.tableSettings.fieldsFilter = {
                ...state.filials.tableSettings.fieldsFilter,
                partof: action.payload
            };
        },
        setBranchesPartOf: (state, action) => {
            state.branches.tableSettings.fieldsFilter = {
                ...state.branches.tableSettings.fieldsFilter,
                partof: action.payload
            };
        },
        setFilialsData: (state, action) => {
            state.filials.data = action.payload;
        },
        setBranchesData: (state, action) => {
            state.branches.data = action.payload;
        },
        setFilialsSorting: (state, action) => {
            state.filials.tableSettings.sorting[0].propertyName = action.payload;
            state.filials.tableSettings.sorting[0].direction = state.filials.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setBranchesSorting: (state, action) => {
            state.branches.tableSettings.sorting[0].propertyName = action.payload;
            state.branches.tableSettings.sorting[0].direction = state.branches.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setPartOfFilialsData: (state, action) => {
            const targetElement = findElementByElementId(state.filials.data.items, action.payload.partof);
            if (targetElement) targetElement.content = action.payload.data.items;
        },
        setBranchesOrganizationsToFilter: (state, action) => {
            if (action.payload) {
                state.branches.tableSettings.fieldsFilter.ids = action.payload;
            } else {
                delete state.branches.tableSettings.fieldsFilter.ids;
            }
        },
        setBranchesProfilesToFilter: (state, action) => {
            state.branches.tableSettings.fieldsFilter.profile = action.payload
        },
        setFilialsOrganizationsToFilter: (state, action) => {
            if (action.payload) {
                state.filials.tableSettings.fieldsFilter.ids = action.payload;
            } else {
                delete state.filials.tableSettings.fieldsFilter.ids;
            }
        },
        setFilialsProfilesToFilter: (state, action) => {
            state.filials.tableSettings.fieldsFilter.profile = action.payload
        },
        setShowFilials: (state, action) => {
            state.filials.show = action.payload;
        },
        setShowBranches: (state, action) => {
            state.branches.show = action.payload;
        },
        setOrganizationObj: (state, action) => {
            state.organizationObj = action.payload;
        },
        setFilialOpen: (state, action) => {
            const targetElement = findElementByElementId(state.filials.data.items, action.payload.part);
            if (targetElement) targetElement.open = action.payload.open;
        },
        setFilialsPage: (state, action) => {
            state.filials.tableSettings.paging.startIndex = (action.payload - 1) * state.filials.tableSettings.paging.maxItems
        },
        setFilialsMaxItems: (state, action) => {
            state.filials.tableSettings.paging.maxItems = action.payload;
        },
        setBranchesPage: (state, action) => {
            state.branches.tableSettings.paging.startIndex = (action.payload - 1) * state.branches.tableSettings.paging.maxItems
        },
        setMaxItems: (state, action) => {
            state.branches.tableSettings.paging.maxItems = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setFilialProfilesToFilter: (state, action) => {
            action.payload ? state.filials.tableSettings.fieldsFilter.profile = action.payload : delete state.filials.tableSettings.fieldsFilter.profile;
        },
        setBranchProfilesToFilter: (state, action) => {
            action.payload ? state.branches.tableSettings.fieldsFilter.profile = action.payload : delete state.branches.tableSettings.fieldsFilter.profile;
        },
        resetStateExceptTableSettings: (state, action) => {
            const initialStateCopy = cloneDeep(initialState);
            delete initialStateCopy.filials.tableSettings;
            delete initialStateCopy.branches.tableSettings;
            const tableSettingsFilialsCopy = cloneDeep(state.filials.tableSettings);
            const tableSettingsBranchesCopy = cloneDeep(state.branches.tableSettings);
            initialStateCopy.filials.tableSettings = tableSettingsFilialsCopy;
            initialStateCopy.branches.tableSettings = tableSettingsBranchesCopy;
            return initialStateCopy;
        },
        resetState: (state, action) => {
            return initialState;
        },
        setOrganizationsFilterForChildElements: (state, action) => {
            state.childCheckedOrganizations = action.payload;
        },
    },
});

export const selectFieldsFilter = state => state.organizationAdmin.main.filials.tableSettings.fieldsFilter;
export const selectSorting = state => state.organizationAdmin.main.filials.tableSettings.sorting;
export const selectFilialsData = state => state.organizationAdmin.main.filials.data;
export const selectBranchesData = state => state.organizationAdmin.main.branches.data;
export const selectFilialsFieldsFilter = state => state.organizationAdmin.main.filials.tableSettings.fieldsFilter;
export const selectBranchesFieldsFilter = state => state.organizationAdmin.main.branches.tableSettings.fieldsFilter;
export const selectShowBranches = state => state.organizationAdmin.main.branches.show;
export const selectShowFilials = state => state.organizationAdmin.main.filials.show;
export const selectOrganizationObj = state => state.organizationAdmin.main.organizationObj;

export const selectFilialsTableSettings = state => state.organizationAdmin.main.filials.tableSettings;
export const selectBranchesTableSettings = state => state.organizationAdmin.main.branches.tableSettings;

export const selectLoading = state => state.organizationAdmin.main.loading;

export const selectChildCheckedOrganization = state => state.organizationAdmin.main.childCheckedOrganizations;

export const mainOrganizationAdminActions = mainOrganizationAdminSlice.actions;

export const getOrganizationDetails = organizationId => {
    return (dispatch) => {
        dispatch(mainOrganizationAdminActions.setLoading({
            type: 'organizationDetails',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: {
                startIndex: 0,
                maxItems: 0
            },
            filtering: {
                searchString: '',   
                fieldsFilter: {
                    'type:missing': false
                }
            },
            sorting: [],
        }
        }).then((response) => {
            const item = response.data.items.find(item => item.id === organizationId);
            dispatch(mainOrganizationAdminActions.setOrganizationName(get(item, 'name')));
            dispatch(mainOrganizationAdminActions.setOrganizationProfile(findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.display));
            dispatch(mainOrganizationAdminActions.setOrganizationObj(item));
            dispatch(mainOrganizationAdminActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        }).catch(err => {
            dispatch(mainOrganizationAdminActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        });
    };
};

export const getFilialsList = ({tableSettings, show, childCheckedOrganizations}) => {
    return (dispatch) => {
        dispatch(mainOrganizationAdminActions.setLoading({
            type: 'filialsData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,  
                fieldsFilter: tableSettings.fieldsFilter,
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            const items = response.data.items;
            // const filtered = items.filter(item => get(item, "type[0].coding[0].code") === organizationType.clinic);
            if (items.length && !show) dispatch(mainOrganizationAdminActions.setShowFilials(true));
            dispatch(mainOrganizationAdminActions.setFilialsData(response.data));

            if (items.length) {
                items.forEach(item => {
                    let fieldsFilterData = {...tableSettings.fieldsFilter};
                    fieldsFilterData.partof = item.id;
                    fieldsFilterData.type = 'department';
                    fieldsFilterData.ids = childCheckedOrganizations;

                    dispatch(getOrganizationBranchesPartOf({
                        sorting: tableSettings.sorting,
                        fieldsFilter: fieldsFilterData
                    }));
                })
                dispatch(mainOrganizationAdminActions.setLoading({
                    type: 'filialsData',
                    value: false
                }));
            } else {
                dispatch(mainOrganizationAdminActions.setLoading({
                    type: 'filialsData',
                    value: false
                }));
            }
        }).catch(err => {
            dispatch(mainOrganizationAdminActions.setLoading({
                type: 'filialsData',
                value: false
            }));
        });
    };
};

export const getBranchesList = ({tableSettings, show}) => {
    return (dispatch) => {
        dispatch(mainOrganizationAdminActions.setLoading({
            type: 'branchesData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,   
                fieldsFilter: tableSettings.fieldsFilter
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            // console.log("response.data", response.data);
            const items = response.data.items;
            // const filtered = items.filter(item => get(item, "type[0].coding[0].code") === organizationType.department);
            if (items.length && !show) dispatch(mainOrganizationAdminActions.setShowBranches(true));
            dispatch(mainOrganizationAdminActions.setBranchesData(response.data));
            dispatch(mainOrganizationAdminActions.setLoading({
                type: 'branchesData',
                value: false
            }));
        }).catch(err => {
            dispatch(mainOrganizationAdminActions.setLoading({
                type: 'branchesData',
                value: false
            }));
        });
    };
};

export const getOrganizationBranchesPartOf = ({fieldsFilter, sorting}) => {
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
                "paging":{
                    "startIndex":0,
                    "maxItems":10
                },
                "filtering":{
                    "searchString": null,
                    "fieldsFilter": fieldsFilter,
                },
                sorting,
            }
        }).then((response) => {
            dispatch(mainOrganizationAdminActions.setPartOfFilialsData({
                data: response.data,
                partof: fieldsFilter.partof,
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const editOrganization = ({formData}) => {
    return (dispatch) => {
      fetchInstance({
        method: "PUT",
        url: `${api.organization}`,
        data: {
            id: formData.id,
            resourceType: "Organization",
            active: true,
            identifier: formData.identifier,
            type: [
                {
                    coding: [
                        {
                            code: "organization",
                            system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                            display: "Организация"
                        }
                    ]
                },
                {
                    coding: [
                        {
                            code: formData.profile.code,
                            display: formData.profile.display,
                            system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                        }
                    ]
                }
            ],
            name: formData.fullTitle,
            alias: [
                formData.title
            ],
            address: [
                {
                    text: formData.address
                }
            ]
        }}).then((response) => {
          dispatch(registerMessage({name: 'edit-organization-message', type: 'primary', title: 'Данные обновлены', closable: true}))
          dispatch(showMessage('edit-organization-message'))
          dispatch(closeMessage({name: 'edit-organization-message', delay: systemMessageDelay}))
          dispatch(getOrganizationDetails(formData.id));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default mainOrganizationAdminSlice.reducer;