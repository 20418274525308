import {useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActivePatientData} from "reducers/doctor";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {isMobileOnly} from "react-device-detect";

export const ComplicationDescription = () => {
    const theme = useTheme()

    const activePatientData = useSelector(selectActivePatientData);
    const [data, setData] = useState([]);
    const [element, setElement] = useState([]);

    useEffect(() => {
        if (activePatientData.resComplication) {
            setData(activePatientData?.resComplication?.activeItem?.item);
            setElement(activePatientData?.resComplication?.activeItem?.element);
        }
    }, [activePatientData.resComplication]);

    return (
        <Wrapper paddings={'8px 16px'}>
            <Text text={element && element} tag={'h5'} font={'robotoMedium'} />
            <Wrapper paddings={'8px 0 0 0'}>
                <Text tag={'small'} text={'Дата'} color={theme.colors.black54} />
                <Text tag={isMobileOnly ? 'h5' : 'text'} text={dayjs(data.date).locale(ru).format('D MMMM YYYY') + ' г.'} font={'robotoRegular'} />
            </Wrapper>
            <Wrapper paddings={'8px 0 0 0'}>
                <Text tag={'small'} text={'Комментарий'} color={theme.colors.black54} />
                <Text tag={isMobileOnly ? 'h5' : 'text'} text={data.comment} font={'robotoRegular'} />
            </Wrapper>
        </Wrapper>
    )
}
