import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from "styled/Error";

const Error = ({message, show}) => {
    if(!show) return null
    return (
        <ErrorMessage>
            {message}
        </ErrorMessage>
    );
};

Error.propTypes = {
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
};

export default Error;
