import styled, {useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    // getActivePatientData,
    selectActivePatient,
    selectActivePatientData,
    selectLoading
} from "reducers/doctor";
import { get } from "lodash";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {doctorActions} from "reducers/doctor";
import {getResComplication} from "reducers/doctor";
import {isMobileOnly} from "react-device-detect";
import Spinner from "components/Spinner";

export const ComplicationsList = ({level, prevLevel}) => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState([]);

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, "id");
        // dispatch(getActivePatientData(patientId));
        let dateFrom = dayjs(get(activePatient, "createdAt")).format('YYYY-MM-DD');
        let dateTo = dayjs().format('YYYY-MM-DD');
        if (activePatientData.UKL && prevLevel < level) {
            dispatch(getResComplication(id, dateFrom, dateTo));
        }
    }, [activePatientData.UKL]);

    useEffect(() => {
        if (activePatientData.resComplication) {
            console.log('ddddd', activePatientData)
            setData(activePatientData.resComplication.complications);
        }
    }, [activePatientData.resComplication]);

    const setActiveItem = (item, element) => {
        dispatch(doctorActions.setResComplicationItem({item, element}));
    }

    return (
        <>
            {!loading?.SIZLData ? data && data.map(item =>
                    item.kinds.map(element =>
                        <Content onClick={() => setActiveItem(item, element)}>
                            <ListItemWrapper>
                                <ListItemBox>
                                    <Wrapper direction={'row'} justify={'space-between'}>
                                        <Text tag={'small'} text={dayjs(item.date).locale(ru).format('D MMMM YYYY') + ' г.'} color={theme.colors.black54} />
                                    </Wrapper>
                                    <Text tag={isMobileOnly ? 'h5' : 'text'} font={'robotoRegular'} text={element} />
                                    {item.comment &&
                                        <Wrapper paddings={'4px 0 0 0'}>
                                            <Text font={'robotoRegular'} text={item.comment} color={theme.colors.black54} overflow />
                                        </Wrapper>
                                    }
                                </ListItemBox>
                            </ListItemWrapper>
                        </Content>
                    )
                )
                :
                <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                    <Spinner/>
                </Wrapper>
            }
        </>
    )
}

const Content = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    min-height: 40px;
    padding: 0 16px;
    
    &:hover {
        background: ${({theme}) => theme.colors.black04};
        ${({theme}) => theme.mixins.innerShadow()}
        
        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: #e0e0e0;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }
`

const ListItemWrapper = styled.div`
    min-height: 40px;
    padding: 8px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.mixins.innerShadow()}
`

const ListItemBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
`
