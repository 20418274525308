import React, {useState, useRef, useEffect} from 'react';
import TextField from "components/TextField";
import {Row} from "styled/Row";
import {Wrapper} from "styled/Wrapper";
import CustomLabel from "./CustomLabel";
import Label from "components/Label";
import UserCard from "components/UserCard";
import {
    selectPractitioner,
    selectRole,
    getActiveChannelsCount,
    selectActiveChannelsCount,
    selectOrganization,
    editDoctor, changeDoctorPhoto, setNotificationEnabled, selectType
} from "reducers/doctor";
import {useSelector, useDispatch} from "react-redux";
import {
    selectCatalogs,
    getGendersCatalog,
} from "reducers/catalogs";
import {find, get, isEmpty} from "lodash";
import {findInTypeBySystem, getImageUrl} from "utils";
import {closeMessage, registerMessage, showMessage} from 'reducers/systemMessages/systemMessagesSlice';
import ActivitiesBox from "components/ActivitiesBox";
import {Separator} from "styled/Separator";
import styled, {useTheme} from "styled-components";
import {useModal} from "components/Modal/useModal";
import DoctorEditProfile from "./DoctorEditProfile";
import ResetPassword from "components/UserMenu/ResetPassword";
import {systemMessageDelay} from 'consts';
import {CustomView, isDesktop, isTablet, MobileOnlyView} from 'react-device-detect';
import DisableNotificationsModal from "modals/DisableNotificationsModal";
import DisableNotificationsModalFooter
    from "modals/DisableNotificationsModal/DisableNotificationsModalFooter";
import rules from "assets/files/Правила работы с сервисом.docx";
import steps_preg from "assets/files/steps-preg.pdf";
import steps_onco from "assets/files/steps-onco.pdf";
import Text from "../../../../../../components/Text";
import EnableNotificationsModal from "../../../../../../modals/EnableNotificationsModal";
import EnableNotificationsModalFooter
    from "../../../../../../modals/EnableNotificationsModal/EnableNotificationsModalFooter";
import { useHistory } from 'react-router-dom';

const DoctorProfile = ({hideModal}) => {
    const dispatch = useDispatch();
    const token = useSelector(state => get(state, "app.access_token"));
    const catalogs = useSelector(selectCatalogs);
    const practitioner = useSelector(selectPractitioner);
    const role = useSelector(selectRole);
    const [practitionerGender, setPractitionerGender] = useState('');
    const [doctorImageUrl, setDoctorImageUrl] = useState('');
    const activeChannelsCount = useSelector(selectActiveChannelsCount);
    const organization = useSelector(selectOrganization);
    const needChangePhoto = useSelector(state => state.doctor.doctorChat.doctor.needChangePhoto);
    const fileInput = useRef();
    const type = useSelector(selectType);
    const theme = useTheme();
    const history = useHistory();

    const notificationEnabled = find(get(role, 'extension'), {url: "http://miramedix.ru/fhir/StructureDefinition/notify-with-email"})?.valueBoolean;

    useEffect(() => {
        if (needChangePhoto) {
            fileInput.current.click();
            dispatch(changeDoctorPhoto(false));
        }
    }, [needChangePhoto])

    useEffect(() => {
        if (isEmpty(catalogs.genders)) dispatch(getGendersCatalog());
        dispatch(getActiveChannelsCount({id: practitioner.id, organizationId: organization?.id}));
    }, []);

    useEffect(() => {
        const gender = catalogs.genders.find(item => item.code === get(practitioner, "gender"));
        setPractitionerGender(get(gender, "display"));
    }, [practitioner, catalogs, practitionerGender]);

    useEffect(() => {
        setDoctorImageUrl(get(practitioner, `photo[0].url`) ? getImageUrl(get(practitioner, "photo[0].url", ''), token) : "");
    }, [practitioner]);

    const handleFileOnChange = (file, url) => {
        // console.log("url", url);
        setDoctorImageUrl(url);

        dispatch(editDoctor({
            image: file,
            practitioner,
            practitionerRole: role
        }));
    }

    const handleChangePhoto = () => {
        fileInput.current.click();
    }

    const [showEditModal] = useModal({
        component: DoctorEditProfile, header: 'Редактирование',
        // footer: DoctorEditProfileFooter,
        props: {modalWidth: '560px'}, name: 'doctor-profile-edit'
    })
    const [showPasswordModal] = useModal({
        component: ResetPassword,
        props: {modalWidth: '560px'},
        header: 'Изменение пароля'
    })

    const handleEnableNotification = () => {
        role && dispatch(setNotificationEnabled({enabled: true, roleId: role?.id}))
    }

    const handleDisableNotification = () => {
        role && dispatch(setNotificationEnabled({enabled: false, roleId: role?.id}))
    }

    const [showEnableNotificationsModal] = useModal({
        component: EnableNotificationsModal,
        header: 'Включить уведомления на эл. почту?',
        footer: EnableNotificationsModalFooter,
        name: 'enable-notifications-modal',
        props: {
            modalWidth: '560px',
            onAccept: handleEnableNotification
        }
    });

    const [showDisableNotificationsModal] = useModal({
        component: DisableNotificationsModal,
        header: 'Отключить уведомления на эл. почту?',
        footer: DisableNotificationsModalFooter,
        name: 'disable-notifications-modal',
        props: {
            modalWidth: '560px',
            onAccept: handleDisableNotification
        }
    });

    const menuList = [
        {
            id: 0,
            label: 'Редактировать данные',
            action: (e) => {
                e?.hideModal();
                showEditModal();
            },
        },
        {
            id: 1,
            label: 'Сменить фотографию',
            action: () => handleChangePhoto()
        },
        {
            id: 1,
            label: 'Изменить пароль',
            action: () => showPasswordModal()
        },
    ]

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper paddings={'0 24px'}>
                    <Row>
                        <MenuWrapper>
                            <ActivitiesBox
                                actions={menuList}
                                data={{hideModal}}
                                anchorZIndex={99999}/>
                            <Separator vertical left={8} right={16}/>
                        </MenuWrapper>
                        <Wrapper direction={'row'} align={'center'} justify={'space-between'}>
                            <UserCard
                                type={'user'}
                                image={doctorImageUrl}
                                name={get(practitioner, "name[0].text")}
                                role={findInTypeBySystem(role?.specialty, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display}
                                nameFontSize={16}
                                nameLineHeight={24}
                                ref={fileInput}
                                onChange={(file, url) => {
                                    handleFileOnChange(file, url);
                                    // form.mutators.setImageTouched();
                                    if (!(file && url)) {
                                        dispatch(registerMessage({
                                            name: 'uploading-profile-image-error-message',
                                            type: 'red',
                                            title: 'Файл не поддерживается',
                                            text: '',
                                            closable: true
                                        }))
                                        dispatch(showMessage('uploading-profile-image-error-message'))
                                        dispatch(closeMessage({
                                            name: 'uploading-profile-image-error-message',
                                            delay: systemMessageDelay
                                        }))
                                    }
                                }}
                                disabled={false}
                            />
                            <Wrapper flex={'0 0 auto'} width={'100px'} direction={'row'} justify={'flex-end'}>
                                <Label
                                    text={find(role?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.display}
                                    type={'green'} maxWidth={'94px'}/>
                            </Wrapper>
                        </Wrapper>
                    </Row>
                    <Row>
                        <TextField label={'Пол'} text={practitionerGender}/>
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Код'} subtext={'(не отображается у пациента)'}/>}
                                   text={find(role?.identifier, {system: "urn:mgfoms:szl:entity:practitionerrole:id"})?.value}/>
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Номер телефона'} subtext={'(не отображается у пациента)'}/>}
                                   text={find(role?.telecom, {system: "phone"})?.value}/>
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Электронная почта'} subtext={'(не отображается у пациента)'}/>}
                                   text={find(role?.telecom, {system: "email"})?.value}/>
                    </Row>
                    <Wrapper margins={'0 0 12px 0'}>
                        <TextField label={<CustomLabel text={'Активные каналы'} subtext={'(не отображается у пациента)'}/>}
                                   text={activeChannelsCount}/>
                    </Wrapper>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper paddings={'16px'} maxHeight={'calc(var(--vh, 1vh) * 100 - 50px)'} overflow={'auto'}>
                    <Wrapper direction={'row'} align={'center'} justify={'space-between'} margins={'0 0 12px 0'}>
                        <UserCard
                            type={'user'}
                            image={doctorImageUrl}
                            name={get(practitioner, "name[0].text")}
                            role={findInTypeBySystem(role?.specialty, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display}
                            nameFontSize={18}
                            nameLineHeight={24}
                            ref={fileInput}
                            onChange={(file, url) => {
                                handleFileOnChange(file, url);
                                // form.mutators.setImageTouched();
                                if (!(file && url)) {
                                    dispatch(registerMessage({
                                        name: 'uploading-profile-image-error-message',
                                        type: 'red',
                                        title: 'Файл не поддерживается',
                                        text: '',
                                        closable: true
                                    }))
                                    dispatch(showMessage('uploading-profile-image-error-message'))
                                    dispatch(closeMessage({
                                        name: 'uploading-profile-image-error-message',
                                        delay: systemMessageDelay
                                    }))
                                }
                            }}
                            disabled={false}
                        />
                    </Wrapper>
                    <Row>
                        <TextField label={'Пол'} text={practitionerGender} textTag={'h5'} />
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Код'} subtext={'(Скрыто для пациента)'} />}
                                   text={find(role?.identifier, {system: "urn:mgfoms:szl:entity:practitionerrole:id"})?.value} textTag={'h5'}/>
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Номер телефона'} subtext={'(Скрыто для пациента)'} />}
                                   text={find(role?.telecom, {system: "phone"})?.value} textTag={'h5'}/>
                    </Row>
                    <Row>
                        <TextField label={<CustomLabel text={'Электронная почта'} subtext={'(Скрыто для пациента)'} />}
                                   text={find(role?.telecom, {system: "email"})?.value} textTag={'h5'}/>
                    </Row>
                    <Wrapper margins={'0 0 32px 0'}>
                        <TextField label={<CustomLabel text={'Активные каналы'} subtext={'(Скрыто для пациента)'} />}
                                   text={activeChannelsCount} textTag={'h5'}/>
                    </Wrapper>
                    <Row marginBottom={'18px'}>
                        <Text text={'Дополнительно'} tag={'h4'} />
                    </Row>
                    <Wrapper onClick={notificationEnabled ? showDisableNotificationsModal : showEnableNotificationsModal } direction={'row'} justify={'space-between'} margins={'0 0 20px 0'}>
                        <Text tag={'h5'} font={'robotoRegular'} text={'Уведомление на эл. почту'} />
                        <Text tag={'h5'} font={'robotoRegular'} text={notificationEnabled ? 'Вкл.' : 'Откл.'} color={theme.colors.black54} />
                    </Wrapper>
                    <Row marginBottom={'20px'} onClick={() => window.open(rules, '_blank')}>
                        <Text tag={'h5'} font={'robotoRegular'} text={'Правила работы с сервисом'} />
                    </Row>
                    <Row marginBottom={'20px'} onClick={() => {window.open(type === 'gynecology' ? steps_preg : steps_onco, '_blank')}}>
                        <Text tag={'h5'} font={'robotoRegular'} text={'Шаги для подключения'} />
                    </Row>
                    <Row marginBottom={'20px'} onClick={() => {window.open('https://onlinedoc.mgfoms.ru/mobile-app')}}>
                        <Text tag={'h5'} font={'robotoRegular'} text={'Скачать мобильное приложение'} />
                    </Row>
                    <Row marginBottom={'20px'} onClick={() => history.push('/support')}>
                        <Text tag={'h5'} font={'robotoRegular'} text={'Поддержка'} />
                    </Row>
                </Wrapper>
            </MobileOnlyView>
        </>
    );
};

const MenuWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: absolute;
  top: -52px;
  right: 56px;
`

DoctorProfile.propTypes = {};

export default DoctorProfile;
