import React from 'react';
import {Wrapper} from "styled/Wrapper";
import styled from "styled-components";

const DoctorProfileHeader = () => {
    return (
        <Wrapper direction={'row'} align={'center'}>
            <Title>
                Профиль
            </Title>
        </Wrapper>
    );
};

const Title = styled.div`
  flex: 1 1 auto;
`

DoctorProfileHeader.propTypes = {

};

export default DoctorProfileHeader;
