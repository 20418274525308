import styled, {useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    // getActivePatientData,
    getChannelChronicDiseases,
    selectActivePatient,
    selectActivePatientData,
    selectLoading
} from "reducers/doctor";
import {get} from "lodash";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {isMobileOnly} from "react-device-detect";
import Spinner from "components/Spinner";

export const ChronicList = () => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState([]);

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, 'id');
        // dispatch(getActivePatientData(patientId));
        dispatch(getChannelChronicDiseases(id));
    }, [activePatientData.UKL]);

    useEffect(() => {
        if (activePatientData.channelChronicDiseases) {
            setData(activePatientData.channelChronicDiseases.chronicDiseases);
        }
    }, [activePatientData.channelChronicDiseases]);

    return (
        <>
            {!loading?.SIZLData ? data && data.map(item =>
                    <Wrapper paddings={'0 16px'}>
                        <Element>
                            <Wrapper direction={'row'} justify={'space-between'}>
                                <Text tag={'small'} text={dayjs(item.firstServiceDate).locale(ru).format('D MMM YYYY') + (item.lastServiceDate && ' — ' + dayjs(item.lastServiceDate).locale(ru).format('D MMM YYYY'))} color={theme.colors.black54} />
                                <Wrapper direction={'row'} justify={'flex-end'} width={'auto'}>
                                    <Text tag={'small'} text={'Обращений:'} color={theme.colors.black30} />&nbsp;
                                    <Text tag={'small'} text={item.servicesCount} color={theme.colors.black54} />
                                </Wrapper>
                            </Wrapper>
                            <Text tag={isMobileOnly ? 'h5' : 'text'} text={item.mkbCodes + ' - ' + item.name} font={'robotoRegular'} />
                        </Element>
                    </Wrapper>
                )
                :
                <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                    <Spinner/>
                </Wrapper>
            }
        </>
    )
}

const Element = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
    ${({theme}) => theme.mixins.innerShadow()};
`
