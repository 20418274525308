import LeaderboardIcon from "components/Icons/LeaderboardIcon";
import ReceiptLongIcon from "components/Icons/ReceiptLongIcon";
import PillIcon from "components/Icons/PillIcon";
import РeopleIcon from "components/Icons/РeopleIcon";
import PersonalInfoIcon from "components/Icons/PersonalInfoIcon";
import {AnalysisDescription} from "./AnalysisDescription";
import {Info} from "./Info";
import {MeasuringTable} from "./MeasuringTable";
import {ServiceList} from "./ServiceList";
import {ServiceDescription} from "./ServiceDescription";
import {AnalysisList} from "./AnalysisList";
import {DrugList} from "./DrugList";
import {ChronicList} from "./ChronicList";
import BiotechIcon from "components/Icons/BiotechIcon";
import LightIcon from "components/Icons/LightIcon";
import {ComplicationsList} from "./ComplicationsList";
import {ComplicationDescription} from "./ComplicationDescription";
import { DrugDescription } from "./DrugDescription";

export const mainOnko = [
    {
        id: 0,
        text: 'Измерения',
        title: 'Измерения',
        showIcon: true,
        icon: LeaderboardIcon,
        list: [
            {
                id: 0,
                title: 'Масса тела',
                text: 'Масса тела',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'WEIGHT',
                    parentId: 0
                },
            },
            {
                id: 1,
                title: 'Давление',
                text: 'Давление',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'PRESSURE',
                    parentId: 0
                },
            },
            {
                id: 2,
                title: 'Температура',
                text: 'Температура',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'TEMPERATURE',
                    parentId: 0
                },
            },
        ]
    },
    {
        id: 1,
        text: 'Оказанные услуги',
        title: 'Оказанные услуги',
        filter: true,
        icon: ReceiptLongIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Описание услуги',
                text: ServiceList,
                info: ServiceDescription,
                infoProps: {
                    parentId: 1,
                    showFilterForParent: true
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 2,
        text: 'Исследования',
        title: 'Исследования',
        icon: BiotechIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Описание исследования',
                text: AnalysisList,
                info: AnalysisDescription,
                infoProps: {
                    parentId: 2
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 3,
        text: 'Лекарства',
        title: 'Лекарства',
        filter: true,
        icon: PillIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Информация о приеме',
                text: DrugList,
                info: DrugDescription,
                infoProps: {
                    parentId: 3,
                    showFilterForParent: true
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 4,
        text: 'Хронические состояния',
        title: 'Хронические состояния',
        icon: РeopleIcon,
        showIcon: true,
        info: ChronicList,
    },
    {
        id: 5,
        text: 'Информация от пациента',
        title: 'Информация от пациента',
        icon: PersonalInfoIcon,
        showIcon: true,
        info: Info
    }
]

export const mainReg = [
    {
        id: 0,
        text: 'Измерения',
        title: 'Измерения',
        showIcon: true,
        icon: LeaderboardIcon,
        list: [
            {
                id: 0,
                title: 'Масса тела',
                text: 'Масса тела',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'WEIGHT',
                    parentId: 0
                },
            },
            {
                id: 1,
                title: 'Давление',
                text: 'Давление',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'PRESSURE',
                    parentId: 0
                },
            },
            {
                id: 2,
                title: 'Глюкоза',
                text: 'Глюкоза',
                showIcon: true,
                info: MeasuringTable,
                infoProps: {
                    type: 'GLUCOSE',
                    parentId: 0
                },
            },
        ]
    },
    {
        id: 1,
        text: 'Оказанные услуги',
        title: 'Оказанные услуги',
        filter: true,
        icon: ReceiptLongIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Описание услуги',
                text: ServiceList,
                info: ServiceDescription,
                infoProps: {
                    parentId: 1,
                    showFilterForParent: true
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 2,
        text: 'Исследования',
        title: 'Исследования',
        icon: BiotechIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Описание исследования',
                text: AnalysisList,
                info: AnalysisDescription,
                infoProps: {
                    parentId: 2
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 3,
        text: 'Осложнения',
        title: 'Осложнения',
        icon: LightIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Описание осложнения',
                text: ComplicationsList,
                info: ComplicationDescription,
                infoProps: {
                    parentId: 3
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 4,
        text: 'Лекарства',
        title: 'Лекарства',
        filter: true,
        icon: PillIcon,
        showIcon: true,
        list: [
            {
                id: 0,
                title: 'Информация о приеме',
                text: DrugList,
                info: DrugDescription,
                infoProps: {
                    parentId: 4,
                    showFilterForParent: true
                },
                listInComponent: true,
            },
        ]
    },
    {
        id: 4,
        text: 'Хронические состояния',
        title: 'Хронические состояния',
        icon: РeopleIcon,
        showIcon: true,
        info: ChronicList,
    },
    {
        id: 5,
        text: 'Информация от пациента',
        title: 'Информация от пациента',
        icon: PersonalInfoIcon,
        showIcon: true,
        info: Info
    }
]
