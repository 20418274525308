import dayjs from "dayjs";
import { filter, get } from "lodash";

export const getLabelColorByStatus = (status) => {
    //Encounter statuses - planned | arrived | triaged | in-progress | onleave | finished | cancelled
    switch (status){
        case 3:
        case 'in-progress':
        case 'InProgress':
        case 'Active':
        case 'active': return 'green';
        case 'Planned':
        case 'Awaiting':
        case 'awaiting': return 'orange';
        case 5:
        case 'Finished':
        case 'finished': return 'primary';
        case 'Archived':
        case 'archived': return 'black';
        case 6:
        case 'Cancelled':
        case 'cancelled': return 'red';
        case 4:
        case 'Onleave':
        case 'onleave': return 'black';
        case 0:
        case 'planned': return 'orange';

        default: return 'primary';
    }
}

// planned - ожидает
// onleave  - приостановлен
// in-progress - активен
// finished - завершен

export const getLabelByStatus = (status) => {
    switch (status){
        case 3:
        case 'Active':
        case 'InProgress':
        case 'in-progress': return  'Активен';
        case 5:
        case 'Finished':
        case 'finished': return 'Завершен';
        case 6:
        case 'Cancelled':
        case 'cancelled': return 'Отменен';
        case 4:
        case 'Onleave':
        case 'onleave': return 'Приостановлен';
        case 0:
        case 'Planned':
        case 'Awaiting':
        case 'planned': return 'Ожидает';
        case 'archived':
        case 'Archived': return 'Архив';
        default: return 'Неизвестен';
    }
}

export const getItemGenderLabel = (code) => {
    switch (code){
        case 1:
        case 'male': return 'М';
        case 2:
        case 'female': return 'Ж';
        default: return '';
    }
}

export const getGenderLabel = (code) => {
    switch (code){
        case 'male': return 'Мужской';
        case 'female': return 'Женский';
        case 'other': return 'Другой';
        case 'unknown': return 'Неизвестен';
        default: return 'Неизвестен';
    }
}

export const getOnsetDateChangesByExtensions = (condition) => {

    const extensions = get(condition, 'extension', []);
    const changesExtensions = filter(extensions, { url: 'http://mgfoms.ru/fhir/StructureDefinition/onset-datetime-change-history' });

    const sortedCahngesExtensions = changesExtensions.sort((a, b) => {
        const first = dayjs(a?.valuePeriod?.start);
        const second = dayjs(b?.valuePeriod?.start);
        if(first.isAfter(second)) return -1;
        if(first.isBefore(second)) return 1;
        return 0
    })

    let changes = []

    for(let count = 0; count < sortedCahngesExtensions.length; count++){
        if(count === 0){
            changes.push({
                date: sortedCahngesExtensions[count]?.valuePeriod?.start,
                from: sortedCahngesExtensions[count]?.valuePeriod?.end,
                to: condition?.onsetDateTime
            })
        }else{
            changes.push({
                date: sortedCahngesExtensions[count]?.valuePeriod?.start,
                from: sortedCahngesExtensions[count]?.valuePeriod?.end,
                to: sortedCahngesExtensions[count-1]?.valuePeriod?.end,
            })
        }
    }

    return changes;
}

export const getPersonFio = (person) => {
    return `${person?.lastName ? `${person?.lastName} ` : ''}${person?.firstName ? `${person?.firstName} ` : ''}${person?.middleName ? `${person?.middleName}` : ''}`
}
