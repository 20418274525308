import {useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActivePatientData} from "reducers/doctor";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {isMobileOnly} from "react-device-detect";
import { filter, get, isEmpty } from "lodash";

export const DrugDescription = () => {
    const theme = useTheme()

    const activePatientData = useSelector(selectActivePatientData);
    // const [data, setData] = useState(activePatientData?.channelDrugs?.activeItem);
    const [intakes, setIntakes] = useState([]);

    useEffect(() => {
        if (activePatientData.channelDrugs) {

            // setData(activePatientData?.channelDrugs?.activeItem);

            const intakes = filter(get(activePatientData, 'channelDrugs.intakes', []), { courseId: activePatientData?.channelDrugs?.activeItem?.id });

            const sortedIntakes = !isEmpty(intakes) ? intakes.sort((a, b) => {
                const first = dayjs(a?.date);
                const second = dayjs(b?.date);
                if(first.isAfter(second)) return 1;
                if(first.isBefore(second)) return -1;
                return 0
            }) : [];

            setIntakes(sortedIntakes);
        }
    }, [activePatientData?.channelDrugs]);

    const activeItem = get(activePatientData, 'channelDrugs.activeItem');

    return (
        <Wrapper paddings={isMobileOnly ? '16px' : '8px 16px'}>
            <Text text={activeItem?.name ? activeItem?.name : ''} tag={'h5'} font={'robotoMedium'} />
            {activeItem?.date &&
                <Wrapper paddings={'8px 0 0 0'}>
                    <Text tag={'small'} text={'Время приема'} color={theme.colors.black54} />
                    <Text tag={isMobileOnly ? 'h5' : 'text'} text={dayjs(activeItem?.date).locale(ru).format('D MMMM YYYY, HH:mm')} font={'robotoRegular'} />
                </Wrapper>   
            }
            {!activeItem?.isCourse && activeItem?.date &&
                <Wrapper paddings={'8px 0 0 0'}>
                    <Text tag={'small'} text={'Доза'} color={theme.colors.black54} />
                    <Text tag={isMobileOnly ? 'h5' : 'text'} text={activeItem?.quantity} font={'robotoRegular'} />
                </Wrapper>
            }
            {activeItem?.isCourse &&
                <Wrapper margins="30px 0 0 0">
                    <Text text={'Курс приема'} tag={'h5'} font={'robotoMedium'} />
                    {activeItem?.quantity &&
                        <Wrapper paddings={'8px 0 0 0'}>
                            <Text tag={'small'} text={'Доза'} color={theme.colors.black54} />
                            <Text tag={isMobileOnly ? 'h5' : 'text'} text={activeItem?.quantity} font={'robotoRegular'} />
                        </Wrapper>
                    }
                    {activeItem?.schedule &&
                        <Wrapper paddings={'8px 0 0 0'}>
                            <Text tag={'small'} text={'Периодичность'} color={theme.colors.black54} />
                            <Text tag={isMobileOnly ? 'h5' : 'text'} text={activeItem?.schedule} font={'robotoRegular'} />
                        </Wrapper>
                    }
                    {activeItem?.conditions &&
                        <Wrapper paddings={'8px 0 0 0'}>
                            <Text tag={'small'} text={'Условие приема'} color={theme.colors.black54} />
                            <Text tag={isMobileOnly ? 'h5' : 'text'} text={activeItem?.conditions} font={'robotoRegular'} />
                        </Wrapper>
                    }
                </Wrapper>
            }
        </Wrapper>
    )
}
