import React from 'react';

const SquaresIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8H2C0.9 8 0 7.1 0 6V2C0 0.9 0.9 0 2 0H6C7.1 0 8 0.9 8 2V6C8 7.1 7.1 8 6 8ZM6 2H2V6H6V2Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 18H2C0.9 18 0 17.1 0 16V12C0 10.9 0.9 10 2 10H6C7.1 10 8 10.9 8 12V16C8 17.1 7.1 18 6 18ZM6 12H2V16H6V12Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2V6C10 7.1 10.9 8 12 8H16C17.1 8 18 7.1 18 6V2C18 0.9 17.1 0 16 0H12C10.9 0 10 0.9 10 2ZM16 6H12V2H16V6Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16V12C10 10.9 10.9 10 12 10H16C17.1 10 18 10.9 18 12V16C18 17.1 17.1 18 16 18H12C10.9 18 10 17.1 10 16ZM12 16H16V12H12V16Z" fill="white"/>
    </svg>
)

export default SquaresIcon;
