import React, {useContext, useEffect, useState} from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css, useTheme} from "styled-components";
import {useDispatch, useSelector} from 'react-redux';
import {
    selectAddWindowElement,
    getPlanDefinition,
    selectPlanDefinition,
    createAppointment,
    updateTask,
    clearStateAfterTaskEditing
} from 'reducers/doctor/rightSide';
import ActionIcon from "components/ActionIcon";
import Text from "components/Text";
import CheckIcon from "components/Icons/CheckIcon";
import ClockIcon from "components/Icons/ClockIcon";
import ClearIcon from "components/Icons/ClearIcon";
import Icon from "components/Icon";
import {Field, Form} from "react-final-form";
// import {requiredValidator, composeValidators, moreThanDateFrom, lessThanDateBefore} from "utils/validators";
import DateInput from "components/DateInput";
import dayjs from "dayjs";
import {find, isArray, last} from 'lodash';
import {useAnchorPopup} from 'components/AnchorPopup/useAnchorPopup';
import DateAndTime from '../DateAndTime';
import {selectActivePatient, selectRole, doctorActions} from 'reducers/doctor';
import {protocolNotificationTexts, systemMessageDelay} from 'consts';
import {registerMessage, showMessage, closeMessage} from 'reducers/systemMessages/systemMessagesSlice';
import {isMobileOnly, MobileOnlyView, CustomView, isDesktop, isTablet} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {routes} from "consts/routes";
import NativeDateInput from "components/NativeDateInput";
import PrimaryButton from "../../../Button/PrimaryButton";
import Button from "components/Button";
import Spinner from 'components/Spinner';
import { useConnectionStarter } from 'hooks/useConnectionStarter';

const CreateTask = () => {

    const theme = useTheme()
    const history = useHistory()

    const connection = useConnectionStarter();

    const addWindowElement = useSelector(selectAddWindowElement);
    const planDefinition = useSelector(selectPlanDefinition);
    const activePatient = useSelector(selectActivePatient);
    const practitionerRole = useSelector(selectRole);

    const [appointment, setAppointment] = useState();

    const dispatch = useDispatch();

    const [indications, setIndications] = useState();
    const [chatUserId] = useState(activePatient?.id || '');
    const [gynecology, setGynecology] = useState(true);
    const [reminderSending, setReminderSending] = useState(false);

    const handleClear = () => {
        dispatch(clearStateAfterTaskEditing())
        if (isMobileOnly) {
            history.push(routes.private.mobile_doctor.rightSide.path)
        }
    }

    const handleDateAndTimeAccept = ({date, time}) => {
        const dateAndTime = dayjs(date).hour(time?.h).minute(time?.m)
        setAppointment(dateAndTime)
    }

    const [showAnchorPopup] = useAnchorPopup(
        {
            component: DateAndTime,
            props: {
                width: '328px',
                onAccept: handleDateAndTimeAccept,
                label: 'Время записи на прием'
            }
        });

    const handleClearAppointnemt = () => {
        setAppointment(null)
    }

    //Асинхронный запрос в WS соединение
    const sendMessage = async ({user, message, files, taskId}) => {
        const messageWithBrs = message ? message.split("\n").join("<br/>") : '';
        if (connection?.connectionStarted) {
            try {
                setReminderSending(true);
                dispatch(doctorActions.setBlockLogoutButton(true));
                connection.invoke("SendNotification", messageWithBrs, user, files, taskId).then(() => {
                    //Если необходимо обработать результат успешной отправки
                    setReminderSending(false);
                    dispatch(doctorActions.setBlockLogoutButton(false));
                    handleClear();
                }).catch(() => {
                    //Если необходимо обработать ошибку при отправке
                    setReminderSending(false);
                    dispatch(doctorActions.setBlockLogoutButton(false));
                    handleClear();
                    dispatch(registerMessage({
                        name: 'sending-protocol-creating-task-error-message',
                        type: 'red',
                        title: 'Ошибка',
                        text: 'Не удалось отправить оповещение о создании мероприятия',
                        closable: true
                    }))
                    dispatch(showMessage('sending-protocol-creating-task-error-message'))
                    dispatch(closeMessage({
                        name: 'sending-protocol-creating-task-error-message',
                        delay: systemMessageDelay
                    }))
                })
            } catch (e) {
                dispatch(doctorActions.setBlockLogoutButton(false));
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    const handleSendNotification = ({task, text}, e) => {
        chatUserId && sendMessage({
            user: chatUserId,
            message: text,
            taskId: task?.id
        })
    }

    //activePatient?.code === 'Z33' ? listGynecology : listOnco

    const handleCallback = (formData) => {
        handleSendNotification({
            task: addWindowElement,
            text: activePatient?.code === 'Z33' ? protocolNotificationTexts.getGynecologyText(addWindowElement, formData) : protocolNotificationTexts.getOncoText(addWindowElement, formData)
        })

        if(isMobileOnly){
            history.push(routes.private.mobile_doctor.rightSide.path)
        }

        dispatch(clearStateAfterTaskEditing());
    }

    const handleFormSubmit = (formData) => {

        appointment ?
            dispatch(createAppointment({
                date: appointment,
                taskName: addWindowElement.description,
                patientId: activePatient?.patientId,
                practitionerId: practitionerRole?.id,
                withTaskUpdate: true,
                taskUpdateData: {
                    task: {...addWindowElement, status: 'in-progress'},
                    formData,
                    encounterId: activePatient?.id,
                    needReloadList: true,
                    fromCreating: true,
                    // clear: true
                },
                callback: () => handleCallback(formData)
            }))
            :
            dispatch(updateTask({
                task: {...addWindowElement, status: 'in-progress'},
                formData,
                encounterId: activePatient?.id,
                needReloadList: true,
                fromCreating: true,
                // clear: true,
                callback: () => handleCallback(formData)
            }))
    }

    const renderIndications = (indications) => {
        return (
            <>
                <Wrapper paddings={'16px 0 0 0'}>
                    <Text tag={'small'} text={'Показания'} color={theme.colors.black54}/>
                </Wrapper>
                <pre>
                    {
                        indications.map((item, index) => {
                            const text = find(item?.coding, {system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-activity-reason"})?.display
                            return text ? `${index + 1}. ${text}\n` : ''
                        })
                    }
                </pre>
            </>
        )
    }

    useEffect(() => {

        // Извлекаем PlanDefinition id для Task
        const id = addWindowElement?.instantiatesUri && addWindowElement?.instantiatesUri?.match(/PlanDefinition\/([\s\S]+)#/)[1];

        // Загружаем PlanDefinition если id был найден
        addWindowElement && id && dispatch(getPlanDefinition({
            planDefinitionId: id
        }))
    }, [addWindowElement])

    useEffect(() => {

        // Извлекаем action id из instantiatesUri
        const actionDefinitionCanonicalId = last(addWindowElement?.instantiatesUri?.split("#"))

        // Ищем action в PlanDefinition соответсвующий action id
        const action = planDefinition && planDefinition?.action?.find(item => {
            // const itemActionDefinitionCanonicalId = last(item?.definitionCanonical?.split("/"));
            return actionDefinitionCanonicalId === item?.id;
        })

        // Устанавливаетм в локальный стейт найденные "показания"
        action?.reason && setIndications(action?.reason)

    }, [planDefinition])

    useEffect(() => {
        setGynecology(activePatient?.code === 'Z33');
    }, [activePatient?.code])

    return (
        /*Создание мероприятия*/
        <ContainerEvent>
            {reminderSending && <Spinner/>}
            <Title>
                <CustomView condition={isDesktop || isTablet}>
                    <ActionIconWrapperLeft>
                        <ActionIcon action={() => handleClear()} icon={ClearIcon}/>
                    </ActionIconWrapperLeft>
                    Запланируйте проведение
                </CustomView>
                <MobileOnlyView>
                    <ActionIconWrapperLeft>
                        <ActionIcon action={() => handleClear()} icon={ClearIcon}/>
                    </ActionIconWrapperLeft>
                    Новое мероприятие
                </MobileOnlyView>
            </Title>

            {addWindowElement &&
                <Body>
                    <>
                        <Text tag={'h5'} text={addWindowElement?.description}/>
                        {indications && isArray(indications) && renderIndications(indications)}
                        <Form
                            initialValues={{
                                startDate: addWindowElement?.restriction?.period?.start || dayjs(),
                                endDate: addWindowElement?.restriction?.period?.end || dayjs().add(30, 'day')
                            }}
                            validate={values => {
                                const errors = {}

                                if (dayjs(values?.endDate).isBefore(values?.startDate, 'd')) {
                                    errors.endDate = 'Дата окончания не должна быть меньше даты начала'
                                }
                                if (dayjs(values?.startDate).isAfter(values?.endDate, 'd')) {
                                    errors.startDate = 'Дата начала не должна быть больше даты окончания'
                                }

                                return errors
                            }}
                            onSubmit={values => {
                                handleFormSubmit(values)
                            }}
                        >
                            {({handleSubmit, pristine, form, submitting, invalid, values}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <CustomView condition={isDesktop || isTablet}>
                                            <Wrapper paddings={'13px 0 4px 0'}>
                                                <Text tag={'small'} text={'Планируемый период проведения'}
                                                    color={theme.colors.black54}/>
                                            </Wrapper>
                                            <Wrapper direction={'row'} align={'flex-start'}>
                                                <Field name={"startDate"}>
                                                    {({input, meta}) =>
                                                        <DateInput
                                                            {...input}
                                                            required
                                                            onBlur={e => input.onBlur(e)}
                                                            toDate={values?.endDate && dayjs(values?.endDate)}
                                                            showError={meta.touched && meta.error}
                                                            error={meta.touched && meta.error}
                                                        />}
                                                </Field>
                                                <Separator>
                                                    –
                                                </Separator>
                                                <Field name={"endDate"}>
                                                    {({input, meta}) =>
                                                        <DateInput
                                                            {...input}
                                                            required
                                                            onBlur={e => input.onBlur(e)}
                                                            fromDate={values?.startDate && dayjs(values?.startDate)}
                                                            showError={meta.touched && meta.error}
                                                            error={meta.touched && meta.error}
                                                        />}
                                                </Field>
                                            </Wrapper>
                                        </CustomView>
                                        <MobileOnlyView>
                                            <Wrapper margins="13px 0 0 0">
                                                <Field name={"startDate"}>
                                                    {({input, meta}) =>
                                                        <NativeDateInput {...input} label={'Дата начала'} defaultValue={dayjs(input?.value)} dateTemplate={'D MMMM YYYY г.'} />
                                                    }
                                                </Field>
                                            </Wrapper>
                                            <Wrapper margins="13px 0 0 0">
                                                <Field name={"endDate"}>
                                                    {({input, meta}) =>
                                                        <NativeDateInput {...input} label={'Дата окончания'} defaultValue={dayjs(input?.value)} dateTemplate={'D MMMM YYYY г.'} />
                                                    }
                                                </Field>
                                            </Wrapper>
                                        </MobileOnlyView>
                                        {!gynecology && appointment &&
                                            <Wrapper paddings={'12px 0 0 0'} direction={'row'} align={'center'}>
                                                <Wrapper>
                                                    <Text tag={'small'} text={'Запись на прием'} color={theme.colors.black54}/>
                                                    <Text text={dayjs(appointment).locale('ru').format('DD MMMM YYYY, HH:mm')}
                                                        font={'robotoRegular'} color={isMobileOnly ? theme.colors.black : theme.colors.primary}/>
                                                </Wrapper>
                                                <Icon action={handleClearAppointnemt} icon={ClearIcon}
                                                    color={theme.colors.black30}/>
                                            </Wrapper>
                                        }
                                        <ButtonsBox>
                                            {!gynecology && !appointment &&
                                                <>
                                                    <CustomView condition={isDesktop || isTablet}>
                                                        <ButtonLine onClick={showAnchorPopup}>
                                                            <Icon icon={ClockIcon} color={theme.colors.primary}/>
                                                            <Wrapper paddings={'0 0 0 16px'} width={'100%'}>Указать время записи</Wrapper>
                                                        </ButtonLine>
                                                    </CustomView>
                                                </>
                                            }
                                            {!gynecology &&
                                                <MobileOnlyView>
                                                    <NativeDateInput
                                                        component={() => (
                                                            <ButtonLine>
                                                                <Icon icon={ClockIcon} color={theme.colors.primary}/>
                                                                <Wrapper paddings={'0 0 0 10px'} width={'auto'}>{`${!appointment ? 'Указать' : 'Изменить'} время записи`}</Wrapper>
                                                            </ButtonLine>
                                                        )}
                                                        from={dayjs()}
                                                        onChange={(value) => {
                                                            handleDateAndTimeAccept({ date: value, time: { h: dayjs(value).get('hour'), m: dayjs(value).get('minute') }})
                                                        }}
                                                        inputType="datetime-local"
                                                    />
                                                </MobileOnlyView>
                                            }
                                            <CustomView condition={isDesktop || isTablet}>
                                                <ButtonLine onClick={handleSubmit} disabled={submitting || invalid}>
                                                    <Icon icon={CheckIcon}
                                                        color={(submitting || invalid) ? theme.colors.black30 : theme.colors.primary}/>
                                                    <Wrapper paddings={'0 0 0 16px'}>Запланировать</Wrapper>
                                                </ButtonLine>
                                            </CustomView>
                                            <MobileOnlyView>
                                                <MobileButtonWrapper>
                                                    <Button label={'Сохранить'} buttonType={'rounded'} fullWidth/>
                                                </MobileButtonWrapper>
                                            </MobileOnlyView>
                                        </ButtonsBox>
                                    </form>
                                )
                            }}
                        </Form>
                    </>
                </Body>
            }
        </ContainerEvent>
    );
};

const ActionIconWrapperLeft = styled.div`
  position: absolute;
  top: 4px;
  left: 9px;
`

const ContainerEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
`

const Separator = styled.div`
  padding: 0 8px;
  height: 32px;
  display: flex;
  align-items: center;
  ${({theme}) => theme.mixins.robotoRegular()};
`

const ButtonsBox = styled.div`
  margin: 22px -15px 0 -15px;
`

const ButtonLine = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  ${({theme, disabled}) => theme.mixins.robotoMedium({
    color: !disabled ? theme.colors.primary : theme.colors.black30,
    size: theme.fonts.sizes.text
  })};
  ${({theme}) => theme.isMobile && css`
    width: calc(100% - 32px);
    justify-content: center;
    background: ${({theme}) => theme.colors.primary12};
    border-radius: 6px;
    margin: 0 16px;
    height: 36px;
  `}

  &:hover {
    background: ${({theme}) => theme.colors.primary12};
  }
`

const Title = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mixins.robotoMedium({size: '16px'})};
  ${({theme}) => theme.mixins.innerShadow()};
`

const Body = styled.div`
  padding: 16px;
  width: 100%;
  ${({theme}) => theme.isMobile && css`
    height: calc(100vh - 50px);
    height: calc(var(--vh, 1vh) * 100 - 50px);
    padding-bottom: 76px;
    overflow: auto;
  `}

  pre {
    white-space: break-spaces;
    margin: 0;
    ${({theme}) => theme.mixins.robotoRegular()};
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineheight:  theme.fonts.lineHeight.h5})};
    `}
  }
`

const MobileButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 16px;
`

CreateTask.propTypes = {};

export default CreateTask;
