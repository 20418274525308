import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";

const PageBaseDoctorMobile = ({children}) => {

    return (
        <Wrapper fullsize direction={'row'} align={'flex-start'}>
            {children}
        </Wrapper>
    );
};

PageBaseDoctorMobile.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    selectors: PropTypes.bool,
    backAction: PropTypes.bool,
    menu: PropTypes.array.isRequired,
};

export default PageBaseDoctorMobile;
