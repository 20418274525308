import React, {useState} from 'react';
import styled, {css} from "styled-components";
import Input from "components/Input/Input";
import HideIcon from "components/Icons/HideIcon";
import ShowIcon from "components/Icons/ShowIcon";
import {Row} from "styled/Row";
import Group from "components/Group";
import PrimaryButton from "components/Button/PrimaryButton";
import SecondaryButton from "components/Button/SecondaryButton";
import {Form, Field} from 'react-final-form';
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordLoddenIn} from "reducers/app";
import {CustomView, isDesktop, isMobileOnly, isTablet, MobileOnlyView} from 'react-device-detect';
import {Wrapper} from "styled/Wrapper";

const ResetPassword = ({hideModal}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    let email = useSelector(state => state.user.email);
    const dispatch = useDispatch();

    const togglePassword = () => {
        setShowPassword(state => !state);
    }
    const toggleNewPassword = () => {
        setShowNewPassword(state => !state);
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(state => !state);
    }

    const renderType = () => {
        if (showPassword) {
            return 'text'
        } else {
            return 'password'
        }
    }

    const renderIcon = () => {
        if (showPassword) {
            return HideIcon
        } else {
            return ShowIcon
        }
    }

    const renderNewPasswordType = () => {
        if (showNewPassword) {
            return 'text'
        } else {
            return 'password'
        }
    }

    const renderNewPasswordIcon = () => {
        if (showNewPassword) {
            return HideIcon
        } else {
            return ShowIcon
        }
    }

    const renderConfirmPasswordType = () => {
        if (showConfirmPassword) {
            return 'text'
        } else {
            return 'password'
        }
    }

    const renderConfirmPasswordIcon = () => {
        if (showConfirmPassword) {
            return HideIcon
        } else {
            return ShowIcon
        }
    }

    return (
        <Wrapper maxHeight={'calc(var(--vh,1vh) * 100 - 50px)'} overflow={'auto'}>
        <Form
            initialValues={{}}
            onSubmit={values => {
                if (!isMobileOnly) {
                    hideModal();
                }
                dispatch(resetPasswordLoddenIn({
                    username: email,
                    password: values.password,
                    newPassword: values.newPassword,
                    newPasswordConfirm: values.newPasswordConfirm,
                }));
            }}
            validate={values => {
                const errors = {};
                if (!values.password) {
                    errors.password = "Поле является обязательным для заполнения";
                }
                if (!values.newPassword) {
                    errors.newPassword = "Поле является обязательным для заполнения";
                } else if (values.password === values.newPassword) {
                    errors.newPassword = "Пароль должен отличаться от существующего";
                }
                if (!values.newPasswordConfirm) {
                    errors.newPasswordConfirm = "Поле является обязательным для заполнения";
                } else if (values.newPassword !== values.newPasswordConfirm) {
                    errors.newPasswordConfirm = "Пароли не совпадают";
                }
                return errors;
            }}
        >
            {({handleSubmit, pristine, form, submitting, invalid, values}) => {
                return (<form onSubmit={handleSubmit}>
                        <ResetPasswordWrapper>
                            <Row marginBottom={'16px'}>
                                <Field name={"password"}
                                    // validate={composeValidators(requiredValidator)}
                                >
                                    {({input, meta}) => <Input
                                        {...input}
                                        required
                                        onBlur={e => input.onBlur(e)}
                                        placeholder={'Введите текущий пароль'}
                                        label={'Текущий пароль'}
                                        type={renderType()}
                                        icon={renderIcon()}
                                        iconAction={togglePassword}
                                        showError={meta.touched && meta.error}
                                        error={meta.touched && meta.error}
                                    />}
                                </Field>
                            </Row>
                            <Row marginBottom={'16px'}>
                                <Field name={"newPassword"}
                                    // validate={composeValidators(requiredValidator, passwordsMustDiffer(values), min6Validator)}
                                >
                                    {({input, meta}) =>
                                        <Input
                                            {...input}
                                            required
                                            onBlur={e => input.onBlur(e)}
                                            placeholder={'Введите новый пароль'}
                                            label={'Новый пароль'}
                                            type={renderNewPasswordType()}
                                            icon={renderNewPasswordIcon()}
                                            iconAction={toggleNewPassword}
                                            showError={meta.touched && meta.error}
                                            error={meta.touched && meta.error}
                                        />}
                                </Field>
                            </Row>
                            <Row marginBottom={'16px'}>
                                <Field name={"newPasswordConfirm"}
                                    // validate={composeValidators(requiredValidator, passwordsMustMatch(values))}
                                >
                                    {({input, meta}) =>
                                        <Input
                                            {...input}
                                            required
                                            onBlur={e => input.onBlur(e)}
                                            placeholder={'Повторите новый пароль'}
                                            label={'Подтверждение пароля'}
                                            type={renderConfirmPasswordType()}
                                            icon={renderConfirmPasswordIcon()}
                                            iconAction={toggleConfirmPassword}
                                            showError={meta.touched && meta.error}
                                            error={meta.touched && meta.error}
                                        />}
                                </Field>
                            </Row>
                        </ResetPasswordWrapper>
                        <ResetPasswordFooterWrapper>
                            <CustomView condition={isDesktop || isTablet}>
                                <Group>
                                    <Group.Item>
                                        <PrimaryButton
                                            label={'Сохранить'}
                                            disabled={pristine || submitting || invalid}
                                            type={'submit'}
                                        />
                                    </Group.Item>
                                    <Group.Item>
                                        <SecondaryButton onClick={e => {
                                            e.stopPropagation();
                                            hideModal(e);
                                        }}
                                                         label={'Отмена'}/>
                                    </Group.Item>
                                </Group>
                            </CustomView>
                            <MobileOnlyView>
                                <PrimaryButton
                                    label={'Сохранить'}
                                    disabled={pristine || submitting || invalid}
                                    type={'submit'}
                                    fullWidth
                                />
                            </MobileOnlyView>
                        </ResetPasswordFooterWrapper>
                    </form>
                )
            }}
        </Form>
        </Wrapper>
    );
};

const ResetPasswordWrapper = styled.div`
  padding: 0 24px;
  ${({theme}) => theme.mixins.robotoRegular()};
  margin-bottom: 12px;
  
  ${({theme}) => theme.isMobile && css`
    padding: 16px;
  `}
`

const ResetPasswordFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-right: 12px;
  ${({theme}) => theme.isMobile && css`
    padding: 0 16px;
    margin-right: 0;
  `}
  
  & > div {
   ${({theme}) => theme.isMobile && css`
    width: 100%;
  `}
  }
`

ResetPassword.propTypes = {};

export default ResetPassword;
