import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import {useTooltip} from "components/Tooltip/useTooltip";

const TableSubtext = ({text, textOverflow, tooltip, color, activeText, activeTextColor, whiteSpace}) => {

    // const theme = useTheme();

    const [showTooltip, hideTooltip] = useTooltip({component: tooltip})

    return (
        <TableSubtextWrapper
            textOverflow={textOverflow}
            onMouseEnter={tooltip && showTooltip}
            onMouseLeave={hideTooltip}
            color={color}
            activeTextColor={activeTextColor}
            whiteSpace={whiteSpace}
        >
            {text}
            {activeText && <span>{activeText}</span>}
        </TableSubtextWrapper>
    );
};

const TableSubtextWrapper = styled.div`
  ${({theme}) => theme.mixins.robotoCondensedRegular({size: theme.fonts.sizes.label, color: theme.colors.black54, lineheight: theme.fonts.lineHeight.small})};
  color: ${({color, theme}) => color && theme.colors[color]};
  ${({textOverflow}) => textOverflow && css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}

  ${({whiteSpace, textOverflow}) => whiteSpace && !textOverflow && css`
    white-space: ${whiteSpace};
  `}
  
  span {
    color: ${({activeTextColor, theme}) => activeTextColor && theme.colors[activeTextColor]};
  }
`

TableSubtext.propTypes = {
    text: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    textOverflow: PropTypes.bool,
};

export default TableSubtext;
