import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import MenuItem from "./MenuItem";

const Menu = ({items, isOpen, adaptiveIsOpen, adaptiveMenuOn}) => {

    const [hover, setHover] = useState(false)

    return (
        <MenuWrapper
            isOpen={isOpen}
            adaptiveIsOpen={adaptiveIsOpen}
            adaptiveMenuOn={adaptiveMenuOn}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {Object.keys(items).map(item => items[item].menu && (
                <MenuItem
                    key={'menu-item-' + items[item].id}
                    item={items[item]}
                    isOpen={isOpen}
                    adaptiveIsOpen={adaptiveIsOpen}
                    hover={hover}
                    adaptiveMenuOn={adaptiveMenuOn}
                />
                )
            )}
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 256px;
  padding: 12px 8px;
  white-space: nowrap;
  ${({theme}) => theme.mixins.innerShadow({
    x: 'right'
  })};
  overflow: hidden;
  
  a {
    text-decoration: none;
  }
  
  transition: width, box-shadow, ${({theme}) => theme.animations.speed} ${({theme}) => theme.animations.curve};

  ${({isOpen}) => isOpen && css`
      width: 72px;
      background-color: ${({theme}) => theme.colors.white100};
      box-shadow: 1px 0 0 rgba(0,0,0,.12);

        :hover {
          width: 256px;
          ${({theme}) => theme.mixins.shadowDark16dp()};
        }
  `};

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
    width: 72px;
    background-color: ${({theme}) => theme.colors.white100};
    box-shadow: 1px 0 0 rgba(0,0,0,.12);
    
    :hover {
      width: 256px;
      ${({theme}) => theme.mixins.shadowDark16dp()};
    }
  `};

  ${({adaptiveMenuOn}) => adaptiveMenuOn && css`
    width: 256px;
    background-color: ${({theme}) => theme.colors.white100};
    box-shadow: 1px 0 0 rgba(0,0,0,.12);
    ${({theme}) => theme.mixins.shadowDark16dp()};
    
    :hover {
      width: 256px;
    }
  `};
`

Menu.propTypes = {
    items: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    adaptiveIsOpen: PropTypes.bool.isRequired,
};

export default Menu;
