import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { get, cloneDeep, isEmpty, find, join, map, filter } from "lodash";
import { systemMessageDelay, tripleCheckboxState } from 'consts';
import { findInTypeBySystem } from 'utils';
import dayjs from 'dayjs';
import { closeMessage, registerMessage, showMessage } from './systemMessages/systemMessagesSlice';

const initialState = {
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            status: "Planned,InProgress,Sent,Canceled,Error",
            recipientRole: "AppRole/fund-admin,AppRole/organization-admin,AppRole/registrator,AppRole/doctor,AppRole/analyst,Patient"
        },
        sorting: [
            {
                propertyName: "scheduledSendTime",
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0,
            maxItems: 25
        },
    },
    loading: {
        // fullPage: true,
        data: true,
        recipientsForModal: false
    },
    totalItems: null,
    recipientsForModal: false
};

const reducers = {
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setRecipientsForModal: (state, action) => {
        state.recipientsForModal = action.payload;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload.value;
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.active.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectData: state => get(state, statePath)?.data,
    selectLoading: state => get(state, statePath)?.loading,
    selectTotalItems: state => get(state, statePath)?.totalItems,
    selectRecipientsForModal: state => get(state, statePath)?.recipientsForModal,
})


export const getThunks = (actions) => {

    const getData = ({tableSettings}) => {

        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/GetCollection`,
            data: {
                paging: tableSettings?.paging,
                filtering:{
                    searchString: tableSettings?.searchString,
                    fieldsFilter: tableSettings?.fieldsFilter,
                },
                sorting: [...tableSettings?.sorting],
            }
        }).then((response) => {
                dispatch(actions.setData(get(response, 'data.items')));
                dispatch(actions.setTotalItems(get(response?.data, 'clientQuery.paging.totalItems', 1)));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getRecipientsForModal = () => {

        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'recipientsForModal',
                value: true
            }));

            const managerPositions = fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}`,
                params: {
                    fg: `_include::practitioner`
                }
            })

            const patients = fetchInstance({
                method: "GET",
                url: `${api.patient}`,
            })
    
            Promise.all([managerPositions, patients]).then((values) => {
                // const items = flatten(map(values, 'data.items'))
                const practitionerRoles = get(values, '[0].data.items', []);
                const patients = get(values, '[1].data.items', []);
                const resources = get(values, '[0].data.resources', []);
                const result = [];
                const fundAdmins = [];
                const organizationAdmins = [];
                const registrators = [];
                const doctors = [];

                // console.log(patients);
    
                practitionerRoles.forEach((item) => {

                    const appRole = find(item?.extension, { url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference" });
                    const codeName = findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.display;
                    const specialityName = findInTypeBySystem(item?.specialty, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display;

                    switch (appRole?.valueReference?.reference) {
                        case 'AppRole/fund-admin':
                            fundAdmins.push({
                                ...item,
                                label: `${get(resources, `${item?.practitioner?.reference}.name[0].text`)} ${codeName ? `(${codeName})` : ''}`,
                                checked: tripleCheckboxState.UNCHECKED,
                                practitioner: get(resources, item?.practitioner?.reference),
                            })
                        break;
                        case 'AppRole/organization-admin':
                            organizationAdmins.push({
                                ...item,
                                label: `${get(resources, `${item?.practitioner?.reference}.name[0].text`)} ${codeName ? `(${codeName})` : ''}`,
                                checked: tripleCheckboxState.UNCHECKED,
                                practitioner: get(resources, item?.practitioner?.reference)
                            })
                        break;
                        case 'AppRole/registrator':
                            registrators.push({
                                ...item,
                                label: `${get(resources, `${item?.practitioner?.reference}.name[0].text`)} ${codeName ? `(${codeName})` : ''}`,
                                checked: tripleCheckboxState.UNCHECKED,
                                practitioner: get(resources, item?.practitioner?.reference)
                            })
                        break;
                        case 'AppRole/doctor':
                            doctors.push({
                                ...item,
                                label: `${get(resources, `${item?.practitioner?.reference}.name[0].text`)} ${specialityName ? `(${specialityName})` : ''}`,
                                checked: tripleCheckboxState.UNCHECKED,
                                practitioner: get(resources, item?.practitioner?.reference)
                            })
                        break;
                    }
                });
    
                !isEmpty(fundAdmins) && result.push({
                    id: 'hierarchy_category_fund_admins',
                    label: 'Сотрудники фонда',
                    content: fundAdmins,
                    checked: tripleCheckboxState.UNCHECKED,
                    appRole: 'AppRole/fund-admin'
                })

                !isEmpty(organizationAdmins) && result.push({
                    id: 'hierarchy_category_organization_admins',
                    label: 'Руководители',
                    content: organizationAdmins,
                    checked: tripleCheckboxState.UNCHECKED,
                    appRole: 'AppRole/organization-admin'
                })

                !isEmpty(registrators) && result.push({
                    id: 'hierarchy_category_registrators',
                    label: 'Администраторы',
                    content: registrators,
                    checked: tripleCheckboxState.UNCHECKED,
                    appRole: 'AppRole/registrator'
                })

                !isEmpty(doctors) && result.push({
                    id: 'hierarchy_category_doctors',
                    label: 'Врачи',
                    content: doctors,
                    checked: tripleCheckboxState.UNCHECKED,
                    appRole: 'AppRole/doctor'
                })

                !isEmpty(doctors) && result.push({
                    id: 'hierarchy_category_patients',
                    label: 'Пациенты',
                    content: patients?.map((item, index) => ({
                        ...item,
                        label: get(item, 'name[0].text'),
                        checked: tripleCheckboxState.UNCHECKED,
                    })),
                    checked: tripleCheckboxState.UNCHECKED,
                    appRole: 'Patient'
                })
    
                dispatch(actions.setRecipientsForModal(result));

                dispatch(actions.setLoading({
                    type: 'recipientsForModal',
                    value: false
                }));
            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'recipientsForModal',
                    value: false
                }));
            });
        };
    };

    const createMailing = ({formData, tableSettings, callback}) => {

        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'creatingMailing',
                value: true
            }));

            const practitionerRoles = filter(formData?.recipients, item => item?.appRole !== 'patient');
            const patients = filter(formData?.recipients, item => item?.appRole === "patient");

            const recipientsTypes = filter(formData?.recipientsType, item => {
                const matched = find(formData?.recipients, { appRole: item?.code?.replace('AppRole/', '').toLowerCase() })
                return !matched
            })

            fetchInstance({
                method: "POST",
                url: `${api.mailingList}/Create`,
                data: {
                    subject: formData?.subject,
                    body: formData?.message,
                    // templateName: "string",
                    // comment: "",
                    scheduledSendTime: formData?.sheduleDateTime,
                    practitionerRoleIds: !isEmpty(practitionerRoles) ? join(map(practitionerRoles, 'id')) : undefined,
                    patientIds: !isEmpty(patients) ? join(map(patients, 'id')) : undefined,
                    recipientsTypes: !isEmpty(recipientsTypes) ? map(recipientsTypes, 'code') : undefined
                    // deliveryType: "string"
                }
            }).then((response) => {

                dispatch(getMailingUpdateStatus({ mailingId: get(response, 'data'), iteration: 60, tableSettings, callback }))

            }).catch(err => {
                dispatch(registerMessage({name: 'create-mailing-error-message', type: 'red', title: 'Не удалось создать рассылку', closable: true}))
                dispatch(showMessage('create-mailing-error-message'))
                dispatch(closeMessage({name: 'create-mailing-error-message', delay: systemMessageDelay}))
                dispatch(actions.setLoading({
                    type: 'creatingMailing',
                    value: false
                }));
            });
        };
    };

    const getMailingUpdateStatus = ({mailingId, iteration = 20, tableSettings, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.mailingUpdateStatus}/${mailingId}`,
            }).then((response) => {
                if (response.data !== "Creating" || !iteration) {
                    if (response.data !== "Creating") {
                        dispatch(getData({tableSettings}));
                        callback && callback();

                        dispatch(registerMessage({name: 'create-mailing-message', type: 'primary', title: 'Рассылка успешно создана', closable: true}))
                        dispatch(showMessage('create-mailing-message'))
                        dispatch(closeMessage({name: 'create-mailing-message', delay: systemMessageDelay}))

                        dispatch(actions.setLoading({
                            type: 'creatingMailing',
                            value: false
                        }));
                    }
                    if (!iteration) {
                        dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                        dispatch(showMessage('check-status-error-message'))
                        dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                        dispatch(actions.setLoading({
                            type: 'creatingMailing',
                            value: false
                        }));
                    }
                } else {
                    setTimeout(() => dispatch(getMailingUpdateStatus({mailingId, iteration: --iteration, tableSettings, callback})), 1000);
                }
            }).catch(err => {
                console.log(err);
                dispatch(actions.setLoading({
                    type: 'creatingMailing',
                    value: false
                }));
            });
        };
    };

    const deleteMailing = ({mailingId, tableSettings, callback}) => {

        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.mailingList}/Delete/${mailingId}`,
        }).then((response) => {
                dispatch(getData({tableSettings}));
                callback && callback();
                dispatch(registerMessage({name: 'delete-mailing-message', type: 'primary', title: 'Рассылка успешно удалена', closable: true}))
                dispatch(showMessage('delete-mailing-message'))
                dispatch(closeMessage({name: 'delete-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'delete-mailing-error-message', type: 'red', title: 'Не удалось удалить рассылку', closable: true}))
                dispatch(showMessage('delete-mailing-error-message'))
                dispatch(closeMessage({name: 'delete-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    const cancelMailing = ({mailingId, tableSettings, callback}) => {

        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/Cancel/${mailingId}`,
        }).then((response) => {
                dispatch(getData({tableSettings}));
                callback && callback();
                dispatch(registerMessage({name: 'delete-mailing-message', type: 'primary', title: 'Рассылка успешно отменена', closable: true}))
                dispatch(showMessage('delete-mailing-message'))
                dispatch(closeMessage({name: 'delete-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'delete-mailing-error-message', type: 'red', title: 'Не удалось отменить рассылку', closable: true}))
                dispatch(showMessage('delete-mailing-error-message'))
                dispatch(closeMessage({name: 'delete-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    const resendMailing = ({mailingId, tableSettings, callback}) => {

        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/ResendAllMessages/${mailingId}`,
        }).then((response) => {
                dispatch(getData({tableSettings}));
                callback && callback();
                dispatch(registerMessage({name: 'resend-mailing-message', type: 'primary', title: 'Рассылка успешно отправлена повторно', closable: true}))
                dispatch(showMessage('resend-mailing-message'))
                dispatch(closeMessage({name: 'resend-mailing-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(registerMessage({name: 'resend-mailing-error-message', type: 'red', title: 'Не удалось повторно отправить рассылку', closable: true}))
                dispatch(showMessage('resend-mailing-error-message'))
                dispatch(closeMessage({name: 'resend-mailing-error-message', delay: systemMessageDelay}))
            });
        };

    };

    return { getData, getRecipientsForModal, createMailing, deleteMailing, cancelMailing, resendMailing }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        mailingListActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
