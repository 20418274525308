import React, {useEffect, useRef, useState} from 'react'; 
import PropTypes from 'prop-types';
import {withButtonStyle} from "hocs/withButtonStyle";
import Button from "../index";
import styled, {useTheme} from 'styled-components'
import ArrowIcon from "components/Icons/ArrowIcon";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import {useSelector} from "react-redux";

const SelectButton = ({onChange, label, component, componentProps, popupWidth, popupHeight, buttonType, withoutPaddings, withoutIcon, anchorZIndex}) => {

    const ref = useRef()
    // const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    // const [elPosition, setElPosition] = useState({x: 0, y: 0})

    const theme = useTheme()

    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    const anchorPopupVisible = useSelector(state => state.anchorPopup.visible)

    // Отключил parentPosition, т.к. он ломает позицию попапа при повторном его открытии, и при условии что изменилась высота контента
    // Вместо parentPosition, AnchorPopup прокидывает дочернему компоненту свой elementPosition, и дочерние попапы ориентируются относительно него
    // useEffect(() => {
    //     setElPosition({
    //         x: get(ref, "current") ? ref.current.getBoundingClientRect().x : 0,
    //         y: get(ref, "current") ? ref.current.getBoundingClientRect().y : 0,
    //     })
    // }, [])

    const [showAnchorPopup, hideAnchorPopup] = useAnchorPopup(
        {
            component,
            props: {
                onClick: onChange,
                componentProps,
                width: popupWidth,
                height: popupHeight,
                anchorZIndex: anchorZIndex
                // parentPositionForSubPopup: {
                //     ...elPosition
                // }
            }
        })

    useEffect(() => {
        if (!anchorPopupVisible && visible) {
            setVisible(false);
        }
        // const setPosition = () => {
        //     setTimeout(() => {
        //         setElPosition({
        //             x: get(ref, "current") ? ref.current.getBoundingClientRect().x : 0,
        //             y: get(ref, "current") ? ref.current.getBoundingClientRect().y : 0,
        //         })
        //     }, theme.animations.animationSpeed)
        //     dispatch(hide())
        // }
        // window.addEventListener('resize', setPosition)
        // return () => {
        //     window.removeEventListener('resize', setPosition)
        // }
    }, [menuIsOpen, anchorPopupVisible, visible, theme.animations.animationSpeed])

    const handleChange = (e) => {
        if(anchorPopupVisible && visible) {
            hideAnchorPopup(e).then(() => setVisible(false))
        } else {
            showAnchorPopup(e).then(() => setVisible(true))
        }
    }

    return (
        <ButtonWrapper ref={ref}>
            {withButtonStyle(buttonType ? buttonType : 'secondary', {
                onClick: handleChange,
                label,
                icon: !withoutIcon && ArrowIcon,
                rotate: visible && 180,
                active: visible,
                withoutPaddings: withoutPaddings
            })(Button)}
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div`
  position: relative;
`

SelectButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    component: PropTypes.element.isRequired,
    componentProps: PropTypes.any,
};

export default SelectButton;
