import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {cloneDeep, get} from "lodash";
import dayjs from "dayjs";
import { organizationType, systemMessageDelay } from 'consts';
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";
import {routes} from "consts/routes";

const initialState = {
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            partof: null,
            profile: "",
            type: organizationType.clinic,
            'type:missing': false
        },
        sorting: [
            {
                propertyName: "name",
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0,
            maxItems: 25
        },
    },
    temp: {
        allOrganizationRoles: [],
        organizationsForFilter: [],
        organizationsForFilterCheckedAll: 1,
    },
    loading: {
        fullPage: true,
        data: true,
    },
    saveThisPageFilter: false,
};

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    setPartOf: (state, action) => {
        state.tableSettings.fieldsFilter = {
            ...state.tableSettings.fieldsFilter,
            partof: action.payload
        };
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setAllOrganizationRoles: (state, action) => {
        state.temp.allOrganizationRoles = action.payload;
    },
    setProfile: (state, action) => {
        state.tableSettings.fieldsFilter.profile = action.payload
    },
    setOrganizationsToFilter: (state, action) => {
        if (action.payload) {
            state.tableSettings.fieldsFilter.ids = action.payload;
        } else {
            delete state.tableSettings.fieldsFilter.ids;
        }
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    resetFilters: (state, action) => {
        state.tableSettings.fieldsFilter = {
            partof: null,
            profile: "",
            type: organizationType.clinic,
            'type:missing': false
        }
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) state.tableSettings = initialState.tableSettings;
    },
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    setProfilesToFilter: (state, action) => {
        action.payload ? state.tableSettings.fieldsFilter.profile = action.payload : delete state.tableSettings.fieldsFilter.profile;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectFieldsFilter: state => get(state, statePath)?.fieldsFilter,
    selectSearchString: state => get(state, statePath)?.searchString,
    selectSorting: state => get(state, statePath)?.sorting,
    selectAllOrganizationRoles: state => get(state, statePath)?.temp.allOrganizationRoles,
    selectLoading: state => get(state, statePath)?.loading,
})

export const getThunks = (actions) => {

    const getOrganizationsList = ({tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: tableSettings.paging,
                filtering: {
                    searchString: tableSettings.searchString,
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting
            }
            }).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getGenders = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.gender,
        }).then((response) => {
                dispatch(actions.setGenderCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPractitionRoles = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.practitionRoles,
        }).then((response) => {
                dispatch(actions.setRolesCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizations = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
        }).then((response) => {
                dispatch(actions.setOrganizationsCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationProfileList = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organizationProfiles,
            }).then((response) => {
                dispatch(actions.setOrganizationProfilesCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const createFilial = ({formData, tableSettings, callback}) => {
        const formDataCopy = cloneDeep(formData);
        if (formData.organizationImage) {
            let imageData = new FormData();
            imageData.append("files", formData.organizationImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    delete formDataCopy.organizationImage;
                    delete formDataCopy.organizationExtension;
                    formDataCopy.organizationExtension = [
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                            valueUrl: response.data[0].url
                        },
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                            valueUrl: response.data[0].url
                        }
                    ]
                    dispatch(createOrganization({formData: formDataCopy, tableSettings, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else {
            delete formDataCopy.organizationImage;
            delete formDataCopy.organizationExtension;
            formDataCopy.organizationExtension = [];
                // {
                //     url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                //     valueUrl: "c38c6ca7-02c7-4033-96d6-21ea5228b702"
                // },
                // {
                //     url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                //     valueUrl: "c38c6ca7-02c7-4033-96d6-21ea5228b702"
                // }
            return (dispatch) => {
                dispatch(createOrganization({formData: formDataCopy, tableSettings, callback}));
            };
        }
    };

    const createOrganization = ({formData, tableSettings, callback}) => {
        // console.log("formData", formData);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                {
                                    code: 'clinic',
                                    system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                    display: "Филиал"
                                }
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'add-new-filial-message', type: 'primary', title: 'Филиал создан', text: formData.organizationName, closable: true, url: '/filials/filial-' + formData.organizationId}))
                    dispatch(showMessage('add-new-filial-message'))
                    dispatch(closeMessage({name: 'add-new-filial-message', delay: systemMessageDelay}))
                    // dispatch(createOrUpdateManagerData({formData, tableSettings, callback}));

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));
                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const createOrUpdateManagerData = ({formData, tableSettings, callback}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData?.practitionerId,
                        fio: formData?.practitionerName,
                        gender: formData?.practitionerGender,
                        isActive: true,
                        photoUrl: formData?.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.practitionerRole,
                        positionDisplay: formData?.practitionerRoleName,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'registrator'
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                tableSettings && dispatch(getOrganizationsList({tableSettings}));
                callback && callback();

            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                console.log(err)
            });
        }
    }

    const deleteOrganization = ({id, tableSettings}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.organization}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'delete-filial-message', type: 'primary', title: 'Филиал удален', closable: true}))
                dispatch(showMessage('delete-filial-message'))
                dispatch(closeMessage({name: 'delete-filial-message', delay: systemMessageDelay}))
                dispatch(getOrganizationsList({tableSettings}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'delete-filial-error-message', type: 'red', title: 'Не удалось удалить филиал', text: err?.response?.data, closable: true}));
                dispatch(showMessage('delete-filial-error-message'));
                dispatch(closeMessage({name: 'delete-filial-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getOrganizationRoles = id => {
        return (dispatch) => {
            fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        organization: id,
                        extended: true,
                    }
                },
                sorting: [],
            }}).then((response) => {
                    dispatch(actions.setAllOrganizationRoles(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    }

    const editFilial = ({formData, tableSettings, callback}) => {
        // console.log("formData", formData);
        if (formData.organizationImage) {
            let imageData = new FormData();
            imageData.append("files", formData.organizationImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.organizationImage;
                    delete formDataCopy.organizationExtension;
                    formDataCopy.organizationExtension = [
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                            valueUrl: response.data[0].url
                        },
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                            valueUrl: response.data[0].url
                        }
                    ]
                    dispatch(editOrganization({formData: formDataCopy, tableSettings, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else if (formData.organizationImage === null) {
            // console.log("Нужно сбросить");
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.organizationExtension = [];
                dispatch(editOrganization({formData: formDataCopy, tableSettings, callback}));
            };
        } else {
            // console.log("Обновлять не нужно");
            return (dispatch) => {
                dispatch(editOrganization({formData, tableSettings, callback}));
            };
        }
    };

    const editOrganization = ({formData, tableSettings, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "PUT",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                {
                                    code: 'clinic',
                                    system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                    display: "Филиал"
                                }
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'edit-filial-message', type: 'primary', title: 'Данные обновлены', closable: true, url: '/filials/filial-' + formData.organizationId}))
                    dispatch(showMessage('edit-filial-message'))
                    dispatch(closeMessage({name: 'edit-filial-message', delay: systemMessageDelay}))
                    // dispatch(createOrUpdateManagerData({formData, tableSettings, callback}));

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));
                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    return { getOrganizationsList, getGenders, getPractitionRoles, getOrganizations, getOrganizationProfileList, createFilial, createOrganization, deleteOrganization, getOrganizationRoles, editFilial, editOrganization, createOrUpdateManagerData }

}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        filialsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
