import React from 'react';

const MegafonIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9728 4.00641C19.6204 3.55251 18.9899 3.48954 18.5647 3.86575C18.1395 4.24196 18.0805 4.91489 18.433 5.36878C19.7245 7.03209 20.5 9.16552 20.5 11.4951C20.5 13.8247 19.7245 15.9581 18.433 17.6215C18.0805 18.0753 18.1395 18.7483 18.5647 19.1245C18.9899 19.5007 19.6204 19.4377 19.9728 18.9838C21.5507 16.9517 22.5 14.3403 22.5 11.4951C22.5 8.6499 21.5507 6.03854 19.9728 4.00641Z" />
        <path d="M16.828 5.8606C16.4393 5.44181 15.8061 5.43867 15.4138 5.8536C15.0215 6.26852 15.0185 6.94438 15.4072 7.36318C16.3929 8.42518 17 9.88343 17 11.4951C17 13.1068 16.3929 14.5651 15.4072 15.6271C15.0185 16.0459 15.0215 16.7217 15.4138 17.1366C15.8061 17.5516 16.4393 17.5484 16.828 17.1296C18.1696 15.6841 19 13.6921 19 11.4951C19 9.29814 18.1696 7.3061 16.828 5.8606Z" />
        <path d="M14 7.9191C14.92 8.79441 15.5 10.0753 15.5 11.495C15.5 12.9148 14.92 14.1957 14 15.0603V7.9191Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 15.8042V19.3735C9 21.083 6.69552 21.627 5.93103 20.098L3.32328 14.8825C2.55174 14.6052 2 13.867 2 12.9999V9.99992C2 8.89535 2.89543 7.99992 4 7.99992H7.7132L11.4547 5.66148C12.1274 5.24105 13 5.72466 13 6.51796V16.4819C13 17.2752 12.1274 17.7588 11.4547 17.3384L9 15.8042ZM7 17.7638V15H5.6181L7 17.7638ZM8.7732 9.69592L11 8.30417V14.6957L8.7732 13.3039C8.45534 13.1053 8.08804 12.9999 7.7132 12.9999H4V9.99992H7.7132C8.08804 9.99992 8.45534 9.89458 8.7732 9.69592Z" />
    </svg>
)

export default MegafonIcon;
