import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Avatar from "components/Avatar";
import styled from "styled-components";
import Item from "./Item";

const Message = ({sender, data, showSenderData, onMeasureChanged, reducer, encounterId}) => {
    return (
        <Wrapper direction={'row'} paddings={'0 24px'}>
            {showSenderData &&
                <AvatarWrapper>
                    <Avatar fullname={data.name} />
                </AvatarWrapper>
            }
            <MessageWrapper showSenderData={showSenderData}>
                <ItemWrapper highlight={data?.highlight} showSenderData={showSenderData}>
                    <ItemBox>
                        <Item 
                            id={data?.id}
                            key={data?.id} 
                            name={data.name}
                            showSenderData={showSenderData}
                            time={data?.time} 
                            message={data?.message} 
                            sender={sender} 
                            status={data?.status} 
                            files={data?.files} 
                            images={data?.images}
                            category={data?.category}
                            expired={data?.expired}
                            onMeasureChanged={onMeasureChanged}
                            reducer={reducer}
                            encounterId={encounterId}
                            replyMessage={data?.replyMessage}
                            replyMessageId={data?.replyMessageId}
                            replyAuthorName={data?.replyAuthorName}
                            replyMessageContentAttachment={data?.replyMessageContentAttachment}
                        />
                    </ItemBox>
                </ItemWrapper>
            </MessageWrapper>
        </Wrapper>
    );
};

const ItemBox = styled.div`
    position: relative;
    z-index: 2;
`

const ItemWrapper = styled.div`
    max-width: 100%;
    ${({showSenderData}) => showSenderData ? 'padding-top: 8px;' : ''}
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: ${({highlight}) =>  highlight ? 'rgba(0, 157, 232, 0.08)' : 'transparent'};       
    }
`

const AvatarWrapper = styled.div`
  margin: 4px 8px 0 0;
  position: relative;
  z-index: 2;
`

const MessageWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: calc(100% - 48px);
  ${({showSenderData}) => !showSenderData ? 'margin-left: 48px;' : ''}
`

Message.propTypes = {
    sender: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};

export default Message;
