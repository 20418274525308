import React, {useEffect, useRef, useState} from 'react'; 
import PropTypes from 'prop-types';
import PerfectScrollbar from "react-perfect-scrollbar";
import './index.css'
import {useDispatch} from "react-redux";
import {hide} from "reducers/anchorPopup/anchorPopupSlice";

const PerfectScrollbarWrapper = ({children, id, paddings, ignoringHandleScroll, minWidth, height, onYReachEnd, onYReachStart, onScrollUp, onScrollDown}) => {

    const watchHandleScroll = ignoringHandleScroll ? ignoringHandleScroll : false;

    const dispatch = useDispatch()

    const ref = useRef()

    const [refHeight, setRefHeight] = useState(ref.current ? ref.current.offsetHeight : 0);
    const [containerRef, setContainerRef] = useState();
    const [activeY, setActiveY] = useState(false); //Защита от срабатывания reachYEnd и reachYStart при отстутсвии скролла

    const handleScrollY = () => {
        dispatch(hide())
        setRefHeight(ref?.current?.offsetHeight)
    }

    const reCalculateScroll = () => {
        if (refHeight !== 0 && ref?.current?.offsetHeight >  refHeight) {
            ref.current.parentNode.scrollTop += ref.current.offsetHeight - refHeight;
        }
        else if (refHeight !== 0 && ref?.current?.offsetHeight <  refHeight) {
            ref.current.parentNode.scrollTop -= refHeight - ref.current.offsetHeight;
        }
    }

    useEffect(() => {
        if (!watchHandleScroll) {
            reCalculateScroll();
        }
    }, [refHeight])

    useEffect(() => {
        if(containerRef && containerRef?.classList){
            setActiveY(containerRef?.classList?.value?.includes('ps--active-y'))
        }
    }, [containerRef?.classList?.value])

    const handleClick = () => {
        setRefHeight(ref?.current?.offsetHeight);
        if (!watchHandleScroll) {
            setTimeout(() => {
                reCalculateScroll();
            }, 100)
        }
    }

    const reachYEnd = () => {
        if(refHeight && refHeight !== 0 && activeY && !!onYReachEnd) onYReachEnd();
    }

    const reachYStart = () => {
        if(refHeight && refHeight !== 0 && activeY && !!onYReachStart) onYReachStart();
    }

    const scrollUp = () => {
        if(refHeight && refHeight !== 0 && !!onScrollUp) onScrollUp();
    }

    const scrollDown = () => {
        if(refHeight && refHeight !== 0 && !!onScrollDown) onScrollDown();
    }

    return (
        <PerfectScrollbar containerRef={(ref) => setContainerRef(ref)} id={id} onScrollY={handleScrollY} onYReachEnd={reachYEnd} onYReachStart={reachYStart} onScrollUp={scrollUp} onScrollDown={scrollDown} style={minWidth && {minWidth: minWidth}}>
            <div ref={ref} onClick={() => handleClick()} style={paddings && {padding: paddings, height: height}}>{/* onClick={() => setRefHeight(ref.current.offsetHeight)}*/}
            {children}
            </div>
        </PerfectScrollbar>
    );
};

PerfectScrollbarWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

PerfectScrollbarWrapper.defaultProps = {
    watchHandleScroll: true,
}

export default PerfectScrollbarWrapper;
