import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { cloneDeep, find, forEach, get, isNumber } from "lodash";
import { registerMessage, showMessage, closeMessage } from "reducers/systemMessages/systemMessagesSlice";
import { routes } from "consts/routes";
import { findInTypeBySystem } from 'utils';
import { roles } from 'pages/AccountsPage/modals/AddAccountModal/options';
import { getAccountModel } from 'adapters/super_admin/accounts';
import axios from 'axios';
import { systemMessageDelay } from 'consts';

const initialState = {
    roles: [],
    accountsItems: [],
    clientQuery: [],
    loading: {
        fullPage: true,
        data: true,
        createOrUpdateDoctor: false,
        createOrUpdateFundAdmin: false,
        createOrUpdateManager: false,
        editDoctor: false,
        createDoctor: false
    },
    all: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                statuses: "active,awaiting,archived,not-available",
                approles: "doctor,fund-admin,organization-admin,registrator"
            },
            sorting: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    active: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                statuses: "active,awaiting,not-available",
                approles: "doctor,fund-admin,organization-admin,registrator"
            },
            sorting: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    archived: {
        tableSettings: {
            fieldsFilter: {
                statuses: "archived",
                approles: "doctor,fund-admin,organization-admin,registrator"
            },
            paging: {
                startIndex: 0,
                maxItems: 25
            },
            sorting: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ],
            searchString: ''
        },
    },
    mode: {},
    modalAnswerTimingRule: null,
    fundOrganization: null,
    options:{
        organization: {
            filialOptions: [],
            branchOptions: []
        },
        filial: {
            branchOptions: []
        }
    },
    statisticsForModal: {},
    accountLoginForModal: null,
}

const reducers = {
    setPage: (state, action) => {
        state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
    },
    setRoles: (state, action) => {
        state.roles = action.payload;
    },
    setAccountsCatalog: (state, action) => {
        state.accountsItems = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setModalAnswerTimingRule: (state, action) => {
        state.modalAnswerTimingRule = action.payload;
    },
    setClientQuery: (state, action) => {
        state.clientQuery = action.payload;
    },
    setMode: (state, action) => {
        state.mode  = action.payload;
    },
    setSorting: (state, action) => {
        state[action.payload.table].tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state[action.payload.table].tableSettings.sorting[0].direction = state[action.payload.table].tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setFundOrganization: (state, action) => {
        state.fundOrganization = action.payload
    },
    setOptions: (state, action) => {
        state.options[action.payload.type] = action.payload.value
    },
    cleanOptions: (state, action) => {
        state.options = initialState.options;
    },
    setStatisticsForModal: (state, action) => {
        state.statisticsForModal = action.payload;
    },
    setFieldsFilter: (state, action) => {
        if(action?.payload && action?.payload?.table){
            if(action?.payload?.params) {
                state[action.payload.table].tableSettings.fieldsFilter = {...state[action.payload.table].tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state[action.payload.table].tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setAccountLoginForModal: (state, action) => {
        state.accountLoginForModal = action.payload;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectAccounts: state => get(state, statePath)?.accountsItems,
    selectRoles: state => get(state, statePath)?.roles,
    selectLoading: state => get(state, statePath)?.loading,
    selectTableSettings: mode => state => get(state, statePath)[mode]?.tableSettings,
    selectModalAnswerTimingRule: state => get(state, statePath)?.modalAnswerTimingRule,
    selectClientQuery: state => get(state, statePath)?.clientQuery,
    selectOrganizationData: state => get(state, statePath)?.organizationData,
    selectMode: state => get(state, statePath)?.mode,
    selectFundOrganization: state => get(state, statePath)?.fundOrganization,
    selectOptions: state => get(state, statePath)?.options,
    selectStatisticsForModal: state => get(state, statePath)?.statisticsForModal,
    selectAccountLoginForModal: state => get(state, statePath)?.accountLoginForModal
})

export const getThunks = (actions, thunksFromAnotherReducer) => {

    const getAccounts = ({tableSettings, cancelTokenSource}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRoleWithQuery}`,
                data: {
                    paging: {
                        ...tableSettings?.paging
                    },
                    filtering: {
                        // searchString: tableSettings?.searchString,
                        fieldsFilter: {
                            ...tableSettings?.fieldsFilter
                        },
                    },
                    sorting: [...tableSettings?.sorting]
                },
                // cancelToken: cancelTokenSource?.token
            }).then((response) => {
                const data = get(response,'data');
                const items = get(response,'data.items');
                const resources = get(response, 'data.resources')
                const accountItems = items?.map(item => {
                    return getAccountModel({
                        practitioner: get(resources, `${item?.practitioner?.reference}`),
                        practitionerRole: item,
                        organization: get(resources, `${item?.organization?.reference}`)
                    })
                })

                dispatch(actions.setAccountsCatalog(accountItems));
                dispatch(actions.setClientQuery(data.clientQuery));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                if(!axios.isCancel(err)){
                    dispatch(actions.setLoading({
                        type: 'data',
                        value: false
                    }));
                }
            });
        };
    };

    const getRoles = () => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.appRole}`,
            }).then((response) => {
                dispatch(actions.setRoles(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const createDoctor = ({formData, tableSettings, appRole, callback}) => {
        const formDataCopy = cloneDeep(formData);
        if (formData.practitionerImage) {
            let imageData = new FormData();
            imageData.append("files", formData.practitionerImage);
            return (dispatch) => {
                dispatch(actions.setLoading({
                    type: 'createDoctor',
                    value: true
                }));
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    delete formDataCopy.practitionerImage;
                    delete formDataCopy.practitionerPhoto;
                    formDataCopy.practitionerPhoto = response.data[0].url;

                    dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, appRole, callback}));
                    dispatch(actions.setLoading({
                        type: 'createDoctor',
                        value: false
                    }));
                }).catch(err => {
                    dispatch(actions.setLoading({
                        type: 'createDoctor',
                        value: false
                    }));

                    dispatch(registerMessage({
                        name: 'create-doctor-error-message',
                        type: 'red',
                        title: `Не удалось загрузить аватар врача`,
                        closable: true,
                    }))
                    dispatch(showMessage('create-doctor-error-message'))
                    dispatch(closeMessage({name: 'create-doctor-error-message', delay: systemMessageDelay}))

                    console.log(err)

                });
            };
        } else {
            delete formDataCopy.practitionerImage;
            delete formDataCopy.practitionerPhoto;
            return (dispatch) => {
                dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, appRole, callback}));
            };
        }
    };

    const editDoctor = ({formData, tableSettings, appRole, updateElement, callback}) => {

        if (formData.practitionerImage) {
            let imageData = new FormData();
            imageData.append("files", formData.practitionerImage);
            return (dispatch) => {
                dispatch(actions.setLoading({
                    type: 'editDoctor',
                    value: true
                }));
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.practitionerImage;
                    delete formDataCopy.practitionerPhoto;
                    formDataCopy.practitionerPhoto = response.data[0].url;

                    dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, appRole, updateElement, isEdit: true, callback}));
                    dispatch(actions.setLoading({
                        type: 'editDoctor',
                        value: false
                    }));
                }).catch(err => {
                    dispatch(registerMessage({
                        name: 'edit-doctor-error-message',
                        type: 'red',
                        title: `Не удалось отредактировать аватар врача`,
                        closable: true,
                    }))
                    dispatch(showMessage('edit-doctor-error-message'))
                    dispatch(closeMessage({name: 'edit-doctor-error-message', delay: systemMessageDelay}))
                    dispatch(actions.setLoading({
                        type: 'editDoctor',
                        value: false
                    }));
                    console.log(err)
                });
            };
        } else if (formData.practitionerImage === null) {
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.practitionerPhoto = null;
                dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, appRole, updateElement, isEdit: true, callback}));
            };
        } else {
            return (dispatch) => {
                dispatch(createOrUpdateDoctorData({formData, tableSettings, appRole, updateElement, isEdit: true, callback}));
            };
        }
    };

    const createOrUpdateDoctorData = ({formData, tableSettings, appRole, callback, isEdit, updateElement}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatedoctor`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto
                    },
                    practitionerRoleInfo: {
                        specialtyCode: formData?.practitionerProfile?.code,
                        specialtyDisplay: formData?.practitionerProfile?.display,
                        practitionerCode: formData?.practitionerCode,
                        availableStartTime: formData?.availableStartTime,
                        availableEndTime: formData?.availableEndTime,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole,
                    answeringTimeRuleInfo: {
                        id: formData.answerTimingRule.id,
                        timeoutMinutes: formData.answerTimingRule.timeoutMinutes.value,
                        derivedFrom: null,
                        exceptNonWorkingTime: formData.answerTimingRule.exceptNonWorkingTime,
                        exceptNonWorkingDates: formData.answerTimingRule.exceptNonWorkingDates,
                    }
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                !updateElement && tableSettings && dispatch(getAccounts({tableSettings}));
                updateElement && dispatch(thunksFromAnotherReducer?.getPractitionerRole({practitionerRoleId: formData.practitionerRoleId}))
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-doctor-message',
                    type: 'primary',
                    title: `Врач успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-doctor-message'))
                dispatch(closeMessage({name: 'create-update-doctor-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-doctor-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} врача`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-doctor-error-message'))
                dispatch(closeMessage({name: 'create-update-doctor-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const createOrUpdateFundAdminData = ({formData, tableSettings, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateFundAdmin',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatefundadmin`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateFundAdmin',
                    value: false
                }));

                tableSettings && dispatch(getAccounts({tableSettings}));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-fund-admin-message',
                    type: 'primary',
                    title: `Сотрудник фонда успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-fund-admin-message'))
                dispatch(closeMessage({name: 'create-update-fund-admin-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateFundAdmin',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-fund-admin-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} сотрудника фонда`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-fund-admin-error-message'))
                dispatch(closeMessage({name: 'create-update-fund-admin-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const createOrUpdateAnalystData = ({formData, tableSettings, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateAnalyst',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateanalyst`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateAnalyst',
                    value: false
                }));

                tableSettings && dispatch(getAccounts({tableSettings}));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-analyst-message',
                    type: 'primary',
                    title: `Аналитик успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-analyst-message'))
                dispatch(closeMessage({name: 'create-update-analyst-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateAnalyst',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-analyst-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} аналитика`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-analyst-error-message'))
                dispatch(closeMessage({name: 'create-update-analyst-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const createOrUpdateManagerData = ({formData, tableSettings, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.practitionerPosition?.code,
                        positionDisplay: formData?.practitionerPosition?.display,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: false,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                tableSettings && dispatch(getAccounts({tableSettings}));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-manager-message',
                    type: 'primary',
                    title: `${appRole === 'registrator' ? 'Администратор МО' : appRole === 'organization-admin' ? 'Руководитель МО' : 'Сотрудник'} успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-manager-message'))
                dispatch(closeMessage({name: 'create-update-manager-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-manager-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} ${appRole === 'registrator' ? 'администратора МО' : appRole === 'organization-admin' ? 'руководителя МО' : 'сотрудника'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-manager-error-message'))
                dispatch(closeMessage({name: 'create-update-manager-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const deleteDoctor = ({id, tableSettings, appRole}) => {
        return (dispatch) => {
            fetchInstance({
                method: "DELETE",
                url: `${api.practitionerRole}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({
                    name: 'delete-account-message',
                    type: 'primary',
                    title: `${find(roles, {value: appRole})?.label} удален`,
                    closable: true,
                    url: routes.private.root.accounts.path
                }))
                dispatch(showMessage('delete-account-message'))
                dispatch(closeMessage({name: 'delete-account-message', delay: systemMessageDelay}))
                dispatch(getAccounts({tableSettings}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({
                    name: 'delete-account-error-message',
                    type: 'red',
                    title: `Не удалось удалить учетную запись`,
                    text: get(err, 'response.data'),
                    closable: true,
                }))
                dispatch(showMessage('delete-account-error-message'))
                dispatch(closeMessage({name: 'delete-account-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const getAnswerTimingRule = id => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/AnswerTimingRule`
                }).then((response) => {
                    dispatch(dispatch(actions.setModalAnswerTimingRule(response.data)));
                    // if (get(response.data, "rule.timeoutMinutes")) dispatch(dispatch(actions.setTimeoutMinutes(get(response.data, "rule.timeoutMinutes", ''))));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getFundOrganization = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
                start: 0,
                max: 0,
                fg: "name::МГФОМС"
            }
        }).then((response) => {
                const items = get(response, 'data.items')
                const item = find(items, {name: "МГФОМС"})
                dispatch(actions.setFundOrganization(item));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPartOfOrganizations = ({partOf, type, appRole}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
            start: 0,
            max: 0,
            fg: `partof::${partOf}`
            }
        }).then((response) => {
                const items = get(response, 'data.items');
                const filials = [];
                const branches = [];
                forEach(items, (value) => {
                    if(findInTypeBySystem(get(value, "type"), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === 'clinic'){
                        filials.push(value)
                    }else if(findInTypeBySystem(get(value, "type"), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === 'department'){
                        branches.push(value)
                    }
                })

                const result = {
                    filialOptions: [...filials],
                    branchOptions: [...branches]
                }

                dispatch(actions.setOptions({
                    type,
                    value: result
                }))
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const sendMessage = ({text, subject, recipientPractitionerRoleId, senderPractitionerRoleId, hideModal}) => {
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/fomsnotification`,
                data: {
                    messageText: text,
                    subject,
                    recipientPractitionerRoleId: recipientPractitionerRoleId,
                    senderPractitionerRoleId: senderPractitionerRoleId
                }
            }).then((response) => {
                dispatch(hideModal);
                dispatch(registerMessage({name: 'send-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'send-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            });
        };
    };

    const archiveAccount = ({item, tableSettings}) => {
        return (dispatch) => {
        fetchInstance({
                method: "PUT",
                url: api.practitionerRole,
                data: item
            }).then((response) => {
                dispatch(getAccounts({tableSettings}));
            }).catch(error => {
                console.log("error", error);
            });
        };
    };

    const restoreAccount = ({id, tableSettings}) => {
        return (dispatch) => {
        fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/RestoreFromArchive`
            }).then((response) => {
                dispatch(getAccounts({tableSettings}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getAccountStatisticsForModal = ({organizationId, practitionerId}) => {
        return (dispatch) => {

            fetchInstance({
                method: "POST",
                url: `${api.practitioners}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            practitionerId,
                            organizationId,
                            statType: 0
                        }
                    },
                    sorting: [],
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');
                const activeChannelsCount = get(item, 'statistics.active');
                const archiveChannelsCount = get(item, 'statistics.archive');
                const inactiveChannelsCount = get(item, 'statistics.inActive');
                const channelsCount =
                    (isNumber(activeChannelsCount) ? activeChannelsCount : 0) +
                    (isNumber(archiveChannelsCount) ? archiveChannelsCount : 0)+
                    (isNumber(inactiveChannelsCount) ? inactiveChannelsCount : 0);

                dispatch(actions.setStatisticsForModal({
                    channels: channelsCount,
                    active: activeChannelsCount,
                    archive: archiveChannelsCount,
                    inactive: inactiveChannelsCount
                }));

            }).catch(err => {
                console.log(err);
            });
        };
    };

    const getAccountLogin = ({practitionerRoleId}) => {
        return (dispatch) => {

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${practitionerRoleId}/GetUserLogin`
            }).then((response) => {
                dispatch(actions.setAccountLoginForModal(response?.data?.value))
            }).catch(err => {
                console.log(err);
            });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(error => {
                console.log("error", error);
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    return { getAccounts, getRoles, createDoctor, editDoctor, deleteDoctor, getAnswerTimingRule, getFundOrganization, getPartOfOrganizations, sendMessage, archiveAccount, restoreAccount, getAccountStatisticsForModal, getAccountLogin, sendActivationNotification, resetPassword, createOrUpdateFundAdminData, createOrUpdateManagerData, createOrUpdateAnalystData }
}

export const build = (name, storePath, thunksFromAnotherReducer) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions, thunksFromAnotherReducer);

    return {
        accountsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };