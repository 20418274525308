import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Group from "components/Group";
import styled, {css, useTheme} from "styled-components";
import Text from "components/Text";
import TableSubtext from "components/Table/TableSubtext";
import ActivitiesBox from "components/ActivitiesBox";
import DiagnosisCancelEventModal from "modals/СancelEventModal/DiagnosisCancelEventModal";
import CancelEventModalFooter from "modals/СancelEventModal/CancelEventModalFooter";
import SimpleCancelEventModal from "modals/СancelEventModal/SimpleCancelEventModal";
import {useDispatch, useSelector} from 'react-redux';
import {
    selectProtocolTaskList,
    getTaskForUpdateFromList,
    doctorRightSideActions,
    selectReminderSendingMap,
    getProtocolTasks
} from 'reducers/doctor/rightSide';
import {isArray, isEmpty, get, cloneDeep} from 'lodash';
import {getStatusLabelByTask, getPeriodElement} from 'adapters/channel';
import {getActivePatientStatistics, getPatients, selectActivePatient, selectPatientsTableSettings, selectRole} from 'reducers/doctor';
import dayjs from 'dayjs';
import {reminderBlockingTime} from 'consts';
import {selectWeekOfPregnancy, getWeekOfPregnancy} from 'reducers/catalogs';
import PatientCancelEventModal from 'modals/СancelEventModal/PatientCancelEventModal';
import DateAndTime from '../DateAndTime';
import {useAnchorPopup} from 'components/AnchorPopup/useAnchorPopup';
import megafon from "assets/images/megafon.png"
import emptyTasks from "assets/images/empty-tasks.svg"
import NoData from 'components/NoData';
import PerfectScrollbarWrapper from 'components/PerfectScrollbarWrapper';
import SecureLS from 'secure-ls';
import {CustomView, MobileOnlyView, isMobileOnly, isDesktop, isTablet} from 'react-device-detect';
import {ConnectionContext} from "../../../../index";

const ls = new SecureLS();

const List = ({statuses, trimester, onChange, onListScrolledToBottom}) => {
    const theme = useTheme()

    const context = useContext(ConnectionContext);
    const state = context?.state;
    const connection = state?.connection;

    const dispatch = useDispatch();

    const protocolTasksList = useSelector(selectProtocolTaskList);
    const activePatient = useSelector(selectActivePatient);
    const weekOfPregnancy = useSelector(selectWeekOfPregnancy);
    const reminderSendingMap = useSelector(selectReminderSendingMap);

    const patientsTableSettings = useSelector(selectPatientsTableSettings);
    const doctorRole = useSelector(selectRole);

    const [chatUserId] = useState(activePatient?.id || '');
    const [showingAnchorItemId, setShowingAnchorItemId] = useState(null);
    const [reminders, setReminders] = useState({});

    const [showAnchorPopup] = useAnchorPopup(
        {
            component: DateAndTime,
            props: {
                width: '328px',
                label: 'Время подтверждения выполнения мероприятия'
            }
        });

    const handleOnCancelEventMedical = (task, value) => {
        dispatch(getTaskForUpdateFromList({
            taskId: task?.id,
            mode: 'cancel',
            updateData: {
                reason: {
                    type: 'medical',
                    text: value
                }
            },
            encounterId: activePatient?.id,
            callback: () => deleteReminder(task?.id)
        }))
    }

    const handleOnCancelEventPatient = (task) => {
        dispatch(getTaskForUpdateFromList({
            taskId: task?.id,
            mode: 'cancel',
            updateData: {
                reason: {
                    type: 'patientRejection',
                }
            },
            encounterId: activePatient?.id,
            callback: () => deleteReminder(task?.id)
        }))
    }

    const handleOnCancelEvent = (task) => {
        dispatch(getTaskForUpdateFromList({
            taskId: task?.id,
            mode: 'cancel',
            encounterId: activePatient?.id,
            callback: () => deleteReminder(task?.id)
        }))
    }

    const handleOnApproveEvent = ({task, isBornTask}, e, elementPosition) => {
        showAnchorPopup(e, null, {
            onAccept: ({date, time}) => approveEvent({task, date, time, isBornTask}),
            toDate: dayjs().utc(),
            parentPosition: {
                x: elementPosition?.x,
                y: elementPosition?.y
            }
        })
    }

    const approveEvent = ({task, date, time, isBornTask}) => {
        const dateAndTime = dayjs(date).hour(time?.h).minute(time?.m);
        dispatch(getTaskForUpdateFromList({
            taskId: task?.id,
            mode: 'approve',
            isBornTask,
            encounterId: activePatient?.id,
            date: dayjs(dateAndTime).utc().format("YYYY-MM-DD[T]hh:mm:ssZ"),
            callback: () => {
                if(isBornTask){
                    dispatch(getProtocolTasks({
                        encounterId: activePatient.id,
                        sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
                    }));
                    dispatch(getActivePatientStatistics({roleId: doctorRole?.id, tableSettings: patientsTableSettings, activePatientId: activePatient.id}));
                    dispatch(getPatients({roleId: doctorRole?.id, tableSettings: patientsTableSettings}));
                }
            }
        }))
    }

    const handleCancelComlpletation = ({task, isBornTask}, e) => {
        dispatch(getTaskForUpdateFromList({
            taskId: task?.id,
            mode: 'backToReady',
            isBornTask,
            encounterId: activePatient?.id,
            callback: () => {
                if(isBornTask){
                    dispatch(getProtocolTasks({
                        encounterId: activePatient.id,
                        sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
                    }));
                    dispatch(getActivePatientStatistics({roleId: doctorRole?.id, tableSettings: patientsTableSettings, activePatientId: activePatient.id}));
                    dispatch(getPatients({roleId: doctorRole?.id, tableSettings: patientsTableSettings}));
                }
            }
        }))
    }

    //Асинхронный запрос в WS соединение
    const sendMessage = async ({user, message, files, taskId}) => {
        const messageWithBrs = message ? message.split("\n").join("<br/>") : '';
        if (connection.connectionStarted) {
            try {
                dispatch(doctorRightSideActions.addReminderSending(taskId))
                connection.invoke("SendReminder", messageWithBrs, user, files, taskId).then(() => {
                    addReminder(taskId)
                    dispatch(doctorRightSideActions.removeReminderSending(taskId))
                }).catch(() => {
                    dispatch(doctorRightSideActions.removeReminderSending(taskId))
                })
            } catch (e) {
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    const handleSendReminder = ({task}, e) => {
        chatUserId && sendMessage({
            user: chatUserId,
            message: `Напоминание о необходимости пройти мероприятие - ${task?.description}.${
                task?.appointment ? ` Запись на прием ${dayjs(task?.appointment?.start).locale('ru').format('DD MMMM YYYYг.')} в ${dayjs(task?.appointment?.start).locale('ru').format('HH:mm')}` : ''
            }${
                task?.period ? ` Планируемый период проведения ${dayjs(task?.period?.start).locale('ru').format('DD MMMM YYYYг.')} - ${dayjs(task?.period?.end).locale('ru').format('DD MMMM YYYYг.')}` : ''
            }`,
            taskId: task?.id
        })
    }

    const addReminder = (id) => {
        const expire = dayjs().add(reminderBlockingTime, 'second');
        ls.set('reminders', {
            ...reminders,
            [id]: {
                expire
            }
        })
        setReminders({...reminders, [id]: {expire}})
    }

    const deleteReminder = (id) => {
        let cloneReminders = cloneDeep(reminders);
        delete cloneReminders[id]

        ls.set('reminders', cloneReminders);
    }

    const actions = [
        {
            id: 1,
            label: 'Отменить',
            modal: {
                component: SimpleCancelEventModal,
                modalHeader: 'Отмена мероприятия',
                modalFooter: CancelEventModalFooter,
                modalProps: {
                    modalWidth: '392px',
                    onAccept: handleOnCancelEvent
                }
            }
        },
        {
            id: 2,
            label: 'Подтвердить выполнение',
            action: handleOnApproveEvent
        }
    ]
    const actionsRequired = [
        {
            id: 1,
            label: 'Отменить по показаниям',
            modal: {
                component: DiagnosisCancelEventModal,
                modalHeader: 'Отмена мероприятия',
                modalProps: {
                    modalWidth: '560px',
                    onAccept: handleOnCancelEventMedical
                }
            }
        },
        {
            id: 2,
            label: 'Отменить из-за отказа пациента',
            modal: {
                component: PatientCancelEventModal,
                modalHeader: 'Отменить мероприятие?',
                modalFooter: CancelEventModalFooter,
                modalProps: {
                    modalWidth: '560px',
                    onAccept: handleOnCancelEventPatient
                }
            }
        },
        {
            id: 3,
            label: 'Подтвердить выполнение',
            action: handleOnApproveEvent
        }
    ]

    const actionsRemind = [
        {
            id: 0,
            label: 'Напомнить',
            action: handleSendReminder
        }
    ]

    const actionsCompleted = [
        {
            id: 0,
            label: 'Отменить выполнение',
            action: handleCancelComlpletation
        }
    ]

    const actionsCancelled = [
        {
            id: 0,
            label: 'Восстановить',
            action: handleCancelComlpletation
        }
    ]

    const actionsCompletedByPatient = [
        {
            id: 2,
            label: 'Подтвердить выполнение',
            action: handleOnApproveEvent
        }
    ]

    const checkSendingReminder = (taskId, sendingMap) => {
        // Есть в списке сендинга то true
        return !!sendingMap?.find(item => item === taskId);
    }

    const haveTaskReminder = (taskId) => {
        return !!reminders[taskId];
    }

    const checkItemByTrimester = (item, trimester, onSetDateTime, weekOfPregnancy) => {

        const itemTrimester = get(item, 'trimester');

        return itemTrimester === trimester;

        // const instantiatesUri = get(item, 'instantiatesUri');
        // const actionData = last(instantiatesUri?.split('/'))?.split('#');
        // const protocol = actionData && !isEmpty(actionData) && find(planDefinitions, { id: actionData[0]});
        // const action = actionData && protocol && !isEmpty(actionData) && find(get(protocol, 'action'), { id: actionData[1]});
        // const timing = action && get(find(get(action, 'timingTiming.extension'), { url: "http://miramedix.ru/fhir/StructureDefinition/weeks-of-pregnance" }), 'valueRange')

        // const low = get(timing, 'low.value');
        // const high = get(timing, 'high.value');
        // const averge = (isNumber(low) && isNumber(high)) && Math.round((low + high)/2)

        // // console.log(instantiatesUri, actionData, protocol, action, timing, low, high, averge);
        // // console.log(low, high, averge);
        // // console.log(instantiatesUri, actionData, protocol, action, timing);

        // let lowValueTrimester = get(find(weekOfPregnancy, { code: `${low === 0 ? low + 1 : low}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');
        // let highValueTrimester = get(find(weekOfPregnancy, { code: `${high}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');
        // let avergeValueTrimester = averge && get(find(weekOfPregnancy, { code: `${averge}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');

        // // console.log(lowValueTrimester, highValueTrimester, avergeValueTrimester);

        // if(!instantiatesUri){
        //     const periodStartDifference = dayjs(get(item, 'period.start')).diff(onSetDateTime, 'week');
        //     const periodEndDifference = dayjs(get(item, 'period.end')).diff(onSetDateTime, 'week');
        //     const periodAverge = (isNumber(periodStartDifference) && isNumber(periodEndDifference)) && Math.round((periodStartDifference + periodEndDifference)/2)

        //     if((periodStartDifference || periodEndDifference) && (periodStartDifference <= 43 || periodEndDifference <= 43)){
        //         let startPeriodTrimester = get(find(weekOfPregnancy, { code: `${periodStartDifference === 0 ? periodStartDifference + 1 : periodStartDifference}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');
        //         let endPeriodTrimester = get(find(weekOfPregnancy, { code: `${periodEndDifference}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');
        //         let avergePreiodTrimester = periodAverge && get(find(weekOfPregnancy, { code: `${periodAverge}`}), 'property["week-of-pregnancy"]["trimester-of-pregnancy"].code');

        //         if(startPeriodTrimester && endPeriodTrimester){
        //             if(startPeriodTrimester === endPeriodTrimester){
        //                 lowValueTrimester = startPeriodTrimester;
        //                 highValueTrimester = startPeriodTrimester;
        //                 avergeValueTrimester = startPeriodTrimester;
        //             }else{
        //                 lowValueTrimester = avergePreiodTrimester;
        //                 highValueTrimester = avergePreiodTrimester;
        //                 avergeValueTrimester = avergePreiodTrimester;
        //             }
        //         }else{
        //             if(startPeriodTrimester) lowValueTrimester = startPeriodTrimester;
        //             if(endPeriodTrimester) highValueTrimester = endPeriodTrimester;
        //         }
        //     }else{
        //         lowValueTrimester = 'trimester-3'
        //         highValueTrimester = 'trimester-3'
        //         avergeValueTrimester = 'trimester-3'
        //     }
        // }

        // if(lowValueTrimester && highValueTrimester){
        //     if(lowValueTrimester === highValueTrimester){
        //         return `trimester-${trimester}` === highValueTrimester
        //     }else{
        //         return `trimester-${trimester}` === avergeValueTrimester
        //     }
        // }else{
        //     if(!lowValueTrimester && !highValueTrimester){
        //         return false;
        //     }else{
        //         if(lowValueTrimester) return `trimester-${trimester}` === lowValueTrimester;
        //         if(highValueTrimester) return `trimester-${trimester}` === highValueTrimester;
        //     }
        // }
    }

    useEffect(() => {
        isEmpty(weekOfPregnancy) && dispatch(getWeekOfPregnancy())
    }, [weekOfPregnancy])

    useEffect(() => {
        let reminders = cloneDeep(ls.get('reminders'));

        Object.keys(reminders).forEach(item => {
            if (dayjs(reminders[item].expire).isBefore(dayjs(), 'second')) delete reminders[item]
        })

        ls.set('reminders', reminders)
        reminders && setReminders(reminders);
    }, [])

    const renderItemByTrimester = ({item}) => {
        return (activePatient?.onSetDateTime && checkItemByTrimester(item, trimester, activePatient?.onSetDateTime, weekOfPregnancy)) && renderItem({item})
    }

    const renderItemByStatuses = ({item}) => {
        return (isArray(statuses) && statuses.some(si => item.status.toLowerCase() === si)) && renderItem({item})
    }

    const renderItem = ({item}) => {
        const isBornTask = item?.instantiatesCanonical === 'http://onlinedoc-v2.miramedix.ru/fhir/ActivityDefinition/1-041' || item?.instantiatesUri === 'http://onlinedoc.miramedix.ru/fhir/PlanDefinition/mgfoms-obstetrics-protocol-standard-v1#78'
        return (
            <ProtocolListItem onClick={() => onChange(item.id, item.sorting, item.trimester)}
                              showAnchor={item?.id === showingAnchorItemId}>
                <CustomView condition={isDesktop || isTablet}>
                    <Wrapper direction={'row'} paddings={'0 0 10px 0'} align={'center'} justify={'space-between'}>
                        <Group gap={12} align={'center'}>
                            <Group.Item>
                                <LabelWrapper>
                                    {getStatusLabelByTask(item)}
                                </LabelWrapper>
                            </Group.Item>
                            <Group.Item>
                                {getPeriodElement(item, 'DD MMM YYYY')}
                            </Group.Item>
                        </Group>
                        <>
                            {item.isNecessary &&
                            <TableSubtextWrapper>
                                <TableSubtext text={'обяз.'}/>
                            </TableSubtextWrapper>
                            }
                            {(get(activePatient, "status") !== 'planned') && <ActivitiesBoxWrapper>
                                <ActivitiesBox
                                    data={{task: item, isBornTask}}
                                    actions={
                                        item?.status.toLowerCase() === 'executed' ?
                                            (!!item?.executionPeriod?.start && !item?.executionPeriod?.end) ?
                                                actionsCompletedByPatient :
                                                actionsCompleted
                                            :
                                            item?.status.toLowerCase() === 'cancelled' ?
                                                actionsCancelled
                                                :
                                                (haveTaskReminder(item?.id) || checkSendingReminder(item?.id, reminderSendingMap)) ?
                                                    item.isNecessary ? actionsRequired : actions
                                                    :
                                                    [...actionsRemind, ...(item.isNecessary ? actionsRequired : actions)]
                                    }
                                    colorHover={theme.colors.primary12}
                                    iconColorHover={theme.colors.primary}
                                    handleButtonStatus={(flag) => flag ? setShowingAnchorItemId(item?.id) : setShowingAnchorItemId(null)}
                                />
                            </ActivitiesBoxWrapper>}
                        </>
                    </Wrapper>
                    <Text tag={'span'} font={'robotoRegular'} text={item.description}/>
                </CustomView>
                <MobileOnlyView>
                    <Wrapper direction={'row'} justify={'space-between'} margins={'0 0 8px 0'}>
                        <LabelWrapper>
                            {getStatusLabelByTask(item)}
                        </LabelWrapper>
                        {getPeriodElement(item, 'DD MMM YYYY')}
                    </Wrapper>
                    <TableSubtextWrapper>
                        {
                            item.isNecessary &&
                            <TableSubtext text={'Обязательное мероприятие'}/>
                        }
                    </TableSubtextWrapper>
                    <Text tag={'h5'} font={'robotoRegular'} text={item.description}/>
                </MobileOnlyView>
            </ProtocolListItem>
        )
    }

    return (
        <WrapperBox>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper fullsize maxHeight={'calc(100vh - 161px)'}>
                    {!isEmpty(protocolTasksList) ?
                        <PerfectScrollbarWrapper onYReachEnd={() => {onListScrolledToBottom && onListScrolledToBottom(true)}} onScrollUp={() => {onListScrolledToBottom && onListScrolledToBottom(false)}} paddings={'6px 0'}>
                            {
                                (trimester ? !isEmpty(weekOfPregnancy) : true) && protocolTasksList.map(item => (
                                        statuses ? renderItemByStatuses({item}) : trimester && renderItemByTrimester({item})
                                    )
                                )
                            }
                        </PerfectScrollbarWrapper>
                        :
                        <Wrapper fullsize align={'center'} justify={'center'}>
                            <NoData image={megafon} text={'Мероприятия отсутствуют'} margins={'0 0 64px 0'}
                                    imageMargins={'0 0 24px 0'}/>
                        </Wrapper>
                    }
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper fullsize maxHeight={'calc(100vh - 150px)'}>
                    {!isEmpty(protocolTasksList) ?
                        <PerfectScrollbarWrapper onYReachEnd={() => {onListScrolledToBottom && onListScrolledToBottom(true)}} onScrollUp={() => {onListScrolledToBottom && onListScrolledToBottom(false)}} paddings={'6px 0'}>
                            {
                                (trimester ? !isEmpty(weekOfPregnancy) : true) && protocolTasksList.map(item => (
                                        statuses ? renderItemByStatuses({item}) : trimester && renderItemByTrimester({item})
                                    )
                                )
                            }
                        </PerfectScrollbarWrapper>
                        :
                        <Wrapper fullsize align={'center'} justify={'center'}>
                            <NoData image={emptyTasks} text={'Добавляйте необходимые мероприятия'} margins={'0 0 64px 0'}
                                    imageMargins={'0 0 24px 0'}/>
                        </Wrapper>
                    }
                </Wrapper>
            </MobileOnlyView>
        </WrapperBox>
    );
};

const WrapperBox = styled.div`
    height: 100%;
    
    & > div {
        height: 100%;
    }
`

const TableSubtextWrapper = styled.div``

const ActivitiesBoxWrapper = styled.div`
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  background: #fff;
`

const hoverStyles = css`
  background: ${({theme}) => theme.colors.black04};

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: -1px;
    left: 0;
    box-shadow: inset 0px -1px 0px #E0E0E0;
  }

  &:after {
    width: 100%;
    left: 0;
  }

  ${ActivitiesBoxWrapper} {
    display: block;
    background: transparent;
  }

  ${TableSubtextWrapper} {
    display: none;
    
    ${({theme}) => theme.isMobile && css`
        display: block;
    `
    }
  }
`

const ProtocolListItem = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  position: relative;

  &:after {
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 16px;
    box-shadow: inset 0px -1px 0px #E0E0E0;
  }

  :hover {
    ${hoverStyles}
  }

  ${({showAnchor}) => showAnchor ? hoverStyles : ''}
`

const LabelWrapper = styled.div`
  width: 94px;
`

List.propTypes = {
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default List;
