import React, {useState} from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css, useTheme} from "styled-components";
import TaskSearch from '../TaskSearch';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectShowSearchWindow,
    doctorRightSideActions,
    selectLoading,
    getTask,
} from 'reducers/doctor/rightSide';

import List from "../List";
import TabsHeader from "components/Tabs/TabsHeader";
import TabsBody from "components/Tabs/TabsBody";
import PrintIcon from "components/Icons/PrintIcon";
import Button from "components/Button";
import ActionIcon from "components/ActionIcon";
import Spinner from 'components/Spinner';
import {selectActivePatient} from 'reducers/doctor'
import PlusIcon from "../../../Icons/PlusIcon";
import {api} from "consts/api";
import {BASE_URL, systemMessageDelay} from "consts";
import printJS from 'print-js';
import {closeMessage, registerMessage, showMessage} from 'reducers/systemMessages/systemMessagesSlice';
import {detect} from 'detect-browser';
import {CustomView, MobileOnlyView, isMobileOnly, isDesktop, isTablet} from 'react-device-detect';
import {useHistory} from "react-router-dom";
import {routes} from "../../../../consts/routes";
import PrimaryButton from "../../../Button/PrimaryButton";
import {useMediaQuery} from "react-responsive";

const browser = detect();

const Tasks = () => {

    const showSearchWindow = useSelector(selectShowSearchWindow);
    const loading = useSelector(selectLoading);
    const activePatient = useSelector(selectActivePatient);

    const [loadingPdf, setLoadingPdf] = useState(false);
    const [listScrolledToBottom, setListScrolledToBottom] = useState(false);

    const history = useHistory()
    const smallDesktop = useMediaQuery({ maxWidth: 1215 });

    const dispatch = useDispatch();

    const handleActiveEvent = (id, sortingPosition, trimester) => {
        !isMobileOnly && dispatch(getTask({id}));
        dispatch(doctorRightSideActions.setTaskSortingPosition(sortingPosition))
        dispatch(doctorRightSideActions.setTaskTrimester(trimester))
        if(isMobileOnly){
            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTask',
                value: true
            }))
            history.push(`/task_details-${id}`);
        }
    }

    const handleAddTaskClick = (e) => {
        e.stopPropagation();
        dispatch(doctorRightSideActions.setShowSearchWindow(!showSearchWindow));
    }

    let listOnco = [
        {
            id: 0,
            label: 'В работе',
            activity: true,
            component: List,
            props: {
                statuses: ['planned', 'overdue'],
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        },
        {
            id: 1,
            label: 'Выполнено',
            activity: false,
            component: List,
            props: {
                statuses: ['executed'],
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        },
        {
            id: 2,
            label: 'Отменено',
            activity: false,
            component: List,
            props: {
                statuses: ['cancelled'],
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        }
    ]

    let listGynecology = [
        {
            id: 0,
            label: '1 триместр',
            activity: true,
            component: List,
            props: {
                trimester: 1,
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        },
        {
            id: 1,
            label: '2 триместр',
            activity: false,
            component: List,
            props: {
                trimester: 2,
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        },
        {
            id: 2,
            label: '3 триместр',
            activity: false,
            component: List,
            props: {
                trimester: 3,
                onListScrolledToBottom: (flag) => {
                    setListScrolledToBottom(flag);
                }
            }
        }
    ]

    const onPrintFileLoadingError = () => {
        dispatch(registerMessage({
            name: 'loading-protocol-pdf-error-message',
            type: 'red',
            title: 'Ошибка',
            text: 'Не удалось загрузить документ для печати',
            closable: true
        }))
        dispatch(showMessage('loading-protocol-pdf-error-message'))
        dispatch(closeMessage({name: 'loading-protocol-pdf-error-message', delay: systemMessageDelay}))
        setLoadingPdf(false);
    }

    const handlePrintClick = () => {
        switch (browser?.name) {
            case 'safari':
                // Для Safari открываем в отдельном окне из за https://github.com/crabbly/Print.js/issues/528
                window.open(`${BASE_URL}${api.patientTaskReport}/${activePatient?.id}/Pdf`, '_blank');
                // Если необходима кастомизация заголовка окна, то возможно вот такое решение, но при этом начинает документ выходить за границы листа при печати
                // var newWindow = window.open();
                // newWindow.document.write(`<iframe src="${BASE_URL}${api.patientTaskReport}/${activePatient?.id}/Pdf" height="100%" width="100%" frameborder="0" allowfullscreen></iframe>`);
                // newWindow.document.title = "Your Title Here";

                break;
            case 'firefox':
                setLoadingPdf(true);
                printJS({
                    printable: `${BASE_URL}${api.patientTaskReport}/${activePatient?.id}/Pdf`,
                    type: 'pdf',
                    onError: onPrintFileLoadingError,
                    onLoadingEnd: () => setTimeout(() => setLoadingPdf(false), 3000)
                });
                break;
            default: {
                setLoadingPdf(true);
                printJS({
                    printable: `${BASE_URL}${api.patientTaskReport}/${activePatient?.id}/Pdf`,
                    type: 'pdf',
                    onPrintDialogClose: () => setLoadingPdf(false),
                    onError: onPrintFileLoadingError
                });
            }
        }
    }

    return (
        <>
            {
                <Container isTablet={isTablet || (isDesktop && smallDesktop)}>
                    <TabsHeaderWrapper>
                        <TabsHeader list={activePatient?.code === 'Z33' ? listGynecology : listOnco}
                                    key={`protocol-tabs-header-${activePatient?.code}`} type={'lined'}
                                    name={'protocol-tabs'} justifyContent={isMobileOnly ? 'center' : 'flex-start'}
                                    margin={'0 8px'}
                                    height={'48px'} dontClearTabsState/>
                    </TabsHeaderWrapper>
                    {loading.protocolTaskList ? <Spinner/> :
                        <Wrapper flexBasis={'auto'} flexGrow={'1'} maxHeight={isTablet || (isDesktop && smallDesktop) ? 'calc(var(--vh,1vh) * 100 - 271px)' : 'calc(var(--vh,1vh) * 100 - 148px)'}>
                            {loading.birthEvent && <Spinner/>}
                            <TabsBody list={activePatient?.code === 'Z33' ? listGynecology : listOnco}
                                      onChange={handleActiveEvent} name={'protocol-tabs'}/>
                        </Wrapper>
                    }
                    <CustomView condition={isDesktop || isTablet}>
                        <ActionIconWrapper>
                            <ActionIcon disabled={loadingPdf} action={handlePrintClick} icon={PrintIcon}
                                        tooltip={'Распечатать'}/>
                        </ActionIconWrapper>
                    </CustomView>
                    <ButtonWrapper listScrolledToBottom={listScrolledToBottom}>
                        <CustomView condition={isDesktop || isTablet}>
                            <Button buttonType={'secondary'} disabled={activePatient?.status === 'planned'}
                                    active={showSearchWindow} onClick={handleAddTaskClick}
                                    label={'Добавить мероприятие'}
                                    fullWidth icon={PlusIcon} iconPosition={'left'} reverse/>
                        </CustomView>
                        <MobileOnlyView>
                            <Button buttonType={'rounded'} disabled={activePatient?.status === 'planned'}
                                    active={showSearchWindow} onClick={() => {history.push(routes.private.mobile_doctor.searchTask.path)}}
                                    label={'Добавить'}
                                    fullWidth icon={PlusIcon} iconPosition={'left'} reverse/>
                        </MobileOnlyView>
                    </ButtonWrapper>
                    {showSearchWindow &&
                    /*Строка поиска*/
                    <SearchEvent>
                        <TaskSearch/>
                    </SearchEvent>
                    }
                </Container>
            }
        </>
    );
};

const TabsHeaderWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  ${({theme}) => theme.mixins.innerShadow()};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: calc(100vh - 64px);
  ${({theme}) => theme.isMobile && css`
    height: calc(100vh - 114px);
    height: calc(var(--vh, 1vh) * 100 - 100px);
  `};
  ${({isTablet}) => isTablet && css`
    height: calc(100vh - 125px);
    height: calc(var(--vh, 1vh) * 100 - 174px);
  `}
  flex-direction: column;
`

const ActionIconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 12px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 8.5px 19px;
  height: 49px;
  display: flex;
  ${({theme}) => theme.mixins.innerShadow({y: 'top'})};
  ${({theme, listScrolledToBottom}) => theme.isMobile && css`
    box-shadow: none;
    align-items: center;
    justify-content: center;
    width: auto;
    position: fixed;
    z-index: 9;
    bottom: 62px;
    opacity: ${listScrolledToBottom ? '0' : '1'};
    transform: ${listScrolledToBottom ? 'translateY(65px)' : 'translateY(0)'};
    transition: transform .3s ease-in-out, opacity .3s ease;
  `};
  & > div {
    width: 100%;
  }
`

const SearchEvent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  width: calc(100% - 16px);
  height: calc(100% - 63px);
  margin: 8px;
  box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 6px;
  z-index: 2;
  border: 1px solid ${({theme}) => theme.colors.black04};
`

Tasks.propTypes = {};

export default Tasks;
