import {useDispatch} from "react-redux";
import {hide, show, registerCarouselModal, unregisterCarouselModal} from "reducers/modalCarousel/modalCarouselSlice";

export const useModalCarousel = ({component, name, props} = {}) => {
    const dispatch = useDispatch();

    const showModalCarousel = ({data = {}, modalComponent, modalProps} = {}) => { 
        dispatch(registerCarouselModal({
            name,
            component: component ? component : modalComponent,
            data,
            props: props ? props : modalProps,
        }))
        dispatch(show(name))
    }

    const hideModal = () => {
        dispatch(hide(name))
        dispatch(unregisterCarouselModal(name))
    }

    return [showModalCarousel, hideModal]
}
