import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {renderColor} from "utils/renderColor";
import {authLogout, changeRole} from "reducers/app";
import {useModal} from "components/Modal/useModal";
import ResetPassword from "components/UserMenu/ResetPassword";
import {useDispatch} from "react-redux";

const UserMenuList = ({handleHide}) => {

    const [showModal] = useModal({component: ResetPassword, name: 'reset-password', props: {modalWidth: '560px'}, header: 'Изменить пароль'})
    const dispatch = useDispatch();

    const handleResetPassword = () => {
        showModal()
        handleHide()
    }

    const handleChangeRole = () => {
        dispatch(changeRole());
    }

    const handleLogout = () => {
        dispatch(authLogout());
    }

    const list = [
        {
            id: 0,
            label: 'Сменить профиль',
            type: 'primary',
            action: handleChangeRole,
        },
        {
            id: 1,
            label: 'Изменить пароль',
            type: 'primary',
            action: handleResetPassword,
        },
        {
            id: 2,
            label: 'Выйти',
            type: 'red',
            action: handleLogout,
        },
    ]

    return (
            list && list.map(item => (
                <Item key={item.id} color={item.type} onClick={item.action}>
                    {item.label}
                </Item>
            ))
        );
};

const Item = styled.div`
    cursor: pointer;
    padding: 6px 12px;
    text-align: center;
    ${({theme, color}) => theme.mixins.robotoRegular({color: renderColor(theme, color)})};
  
  :hover {
    background-color: ${({theme}) => theme.colors.black04};
  }
`

UserMenuList.propTypes = {
    handleHide: PropTypes.func.isRequired,
};

export default UserMenuList;
