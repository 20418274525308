import styled, {css, useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {
    // getActivePatientData,
    selectActivePatient,
    selectActivePatientData,
    getIndicators,
    selectLoading
} from "reducers/doctor";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {get} from "lodash";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import Spinner from "components/Spinner";

export const MeasuringTable = (props) => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState([]);
    const [caption, setCaption] = useState();

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, "id");
        // dispatch(getActivePatientData(patientId));
        // let code = get(activePatient, "code");
        let dateFrom = dayjs(get(activePatient, "createdAt")).format('YYYY-MM-DD');
        let dateTo = dayjs().format('YYYY-MM-DD');
        dispatch(getIndicators(id, dateFrom, dateTo));//тестовый UKL: 29121580
    }, [activePatientData.UKL]);

    useEffect(() => {
        if (activePatientData.indicators) {
            for (let item in activePatientData.indicators.indicators) {
                if (activePatientData.indicators.indicators[item].type === props.type) {
                    setData(activePatientData.indicators.indicators[item].values);
                }
            }
            switch (props.type) {
                case 'WEIGHT':
                    setCaption('кг');
                    break;
                case 'PRESSURE':
                    setCaption('мм.рт.ст');
                    break;
                case 'TEMPERATURE':
                    setCaption('°C');
                    break;
                case 'GLUCOSE':
                    setCaption('ммоль/л');
                    break;
                default: return '';
            }
        }
    }, [activePatientData.indicators]);

    return (
        <>
            {!loading?.SIZLData ?
                <>
                    <CustomView condition={isDesktop || isTablet}>
                        <Wrapper paddings={'8px 16px'}>
                            {data &&
                            data.map(item =>
                                <Element>
                                    <Wrapper width={'auto'} direction={'row'}>
                                        {props.type === 'PRESSURE' ?
                                            <><Text text={item.systolic + '/' + item.diastolic} font={'robotoRegular'} />&nbsp;</>
                                            :
                                            <><Text text={item.value} font={'robotoRegular'} />&nbsp;</>
                                        }
                                        <Text text={caption + (item.hand ? (item.hand === 'LEFT_HAND' ? '・Лев.' : item.hand === 'RIGHT_HAND' ? '・Прав.' : '') : '')} font={'robotoCondensedRegular'} color={theme.colors.black54} />
                                    </Wrapper>
                                    <Text text={dayjs(item.date).locale(ru).format('D MMM YYYY')} font={'robotoCondensedRegular'} color={theme.colors.black54} />
                                </Element>
                            )
                            }
                        </Wrapper>
                    </CustomView>
                    <MobileOnlyView>
                        <Wrapper paddings={'0 16px'}>
                            {data &&
                            data.map(item =>
                                <Element>
                                    <Wrapper width={'auto'} direction={'row'} >
                                        {props.type === 'PRESSURE' ?
                                            <Wrapper direction={'column'}>
                                                {item.hand &&
                                                    <Wrapper width={'100%'}>
                                                        <Text tag={'small'}
                                                            text={item.hand === 'LEFT_HAND' ? 'Левая рука' : item.hand === 'RIGHT_HAND' ? 'Правая рука' : ''}
                                                            font={'robotoCondensedRegular'} color={theme.colors.black54}/>
                                                    </Wrapper>
                                                }
                                                <Wrapper direction={'row'} align={'baseline'}>
                                                    <Text tag={'h5'} text={item.systolic + '/' + item.diastolic} font={'robotoRegular'} />&nbsp;
                                                    <Text tag={'text'} text={caption} font={'robotoCondensedRegular'} color={theme.colors.black54} />
                                                </Wrapper>
                                            </Wrapper>
                                            :
                                            <><Text tag={'h5'} text={item.value} font={'robotoRegular'} />&nbsp;</>
                                        }
                                    </Wrapper>
                                    <Text tag={'h5'} text={dayjs(item.date).locale(ru).format('D MMM YYYY')} font={'robotoCondensedRegular'} color={theme.colors.black54} />
                                </Element>
                            )
                            }
                        </Wrapper>
                    </MobileOnlyView>
                </>
                :
                <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                    <Spinner/>
                </Wrapper>
            }
        </>
    )
}

const Element = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    ${({theme}) => theme.mixins.innerShadow()}; 
    
    ${({theme}) => theme.isMobile && css`
        height: 50px;
    `}
`
