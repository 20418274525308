import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { get, isEmpty } from "lodash";
import { organizationType, systemMessageDelay } from 'consts';
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';

const initialState = {
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            partof: null,
            profile: "",
            type: organizationType.department
        },
        sorting: [
            {
                propertyName: "name", 
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0, 
            maxItems: 10
        },
    },
    temp: {
        allOrganizationRoles: [],
        organizationsForFilter: [],
        organizationsForFilterCheckedAll: 1,
    },
    timeExceeded: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "answerDate",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    endOfCounseling: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                daysToEnd: 14,
                status: "in-progress,planned",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "period",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    organizationData: [],
    loading: {
        data: true,
        organizationData: true
    },
    saveThisPageFilter: false,
};

const reducers = {
    setAllOrganizationRoles: (state, action) => {
        state.temp.allOrganizationRoles = action.payload;
    }, 
    setOrganizationData: (state, action) => {
        state.organizationData = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setPatientsResponseTimeExceededData: (state, action) => {
        state.timeExceeded.data = action.payload
    },
    setPatientsEndOfCounselingPeriodData: (state, action) => {
        state.endOfCounseling.data = action.payload
    },
    setPatientsResponseTimeExceededPage: (state, action) => {
        state.timeExceeded.tableSettings.paging.startIndex = (action.payload - 1) * state.timeExceeded.tableSettings.paging.maxItems
    },
    setPatientsEndOfCounselingPeriodPage: (state, action) => {
        state.endOfCounseling.tableSettings.paging.startIndex = (action.payload - 1) * state.endOfCounseling.tableSettings.paging.maxItems
    },
    setPatientsResponseTimeExceededMaxItems: (state, action) => {
        state.timeExceeded.tableSettings.paging.maxItems = action.payload;
    },
    setPatientsEndOfCounselingPeriodMaxItems: (state, action) => {
        state.endOfCounseling.tableSettings.paging.maxItems = action.payload;
    },
    setSortingTimeExceeded: (state, action) => {
        state.timeExceeded.tableSettings.sorting[0].propertyName = action.payload;
        state.timeExceeded.tableSettings.sorting[0].direction = state.timeExceeded.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setTimeExceededPatientsIdToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.patientId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.patientId;
    },
    setTimeExceededPractitionerRoleIdToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId;
    },
    setTimeExceededAnswerStartDateToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerStartDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerStartDate;
    },
    setTimeExceededAnswerEndDateToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerEndDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerEndDate;
    },
    setSortingEndOfCounseling: (state, action) => {
        state.endOfCounseling.tableSettings.sorting[0].propertyName = action.payload;
        state.endOfCounseling.tableSettings.sorting[0].direction = state.endOfCounseling.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setEndOfCounselingPatientsIdToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.patientId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.patientId;
    },
    setEndOfCounselingPractitionerRoleIdToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId;
    },
    setEndOfCounselingPeriodStartDateToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodStart = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodStart;
    },
    setEndOfCounselingPeriodEndDateToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodEnd = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodEnd;
    },
    setEndOfCounselinStatusesToFilter: (state, action) => {
       state.endOfCounseling.tableSettings.fieldsFilter.status = action.payload;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectAllOrganizationRoles: state => get(state, statePath)?.temp.allOrganizationRoles,
    selectOrganizationData: state => get(state, statePath)?.organizationData,
    selectLoading: state => get(state, statePath)?.loading,
    selectTimeExceededTableSettings: state => get(state, statePath)?.timeExceeded.tableSettings,
    selectEndTableSettings: state => get(state, statePath)?.endOfCounseling.tableSettings,
    selectPatientsResponseTimeExceededData: state => get(state, statePath)?.timeExceeded.data,
    selectPatientsEndOfCounselingPeriodData: state => get(state, statePath)?.endOfCounseling.data,
})


export const getThunks = (actions) => {

    const getOrganizationsForFilter = partOf => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        partof: partOf,
                        'type:missing': false
                    }
                },
            }
            }).then((response) => {
                const initialItems = response.data.items;
                let items = [];
                initialItems.forEach(item => {
                    items.push({organization: item});
                });

                const setAllElementsChecked = (dataObj, level) => {
                    const objLevel = level ? level : 1;
                    dataObj.forEach(item => {
                        item.checked = 1;
                        item.level = objLevel;
                        if (!isEmpty(item.childElements)) setAllElementsChecked(item.childElements, objLevel + 1);
                    });
                }
                setAllElementsChecked(items);
                dispatch(actions.setOrganizationsForFilter(items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationRoles = (id) => {
        return (dispatch) => {
            fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        organization: id,
                        extended: true,
                    }
                },
                sorting: [],
            }}).then((response) => {
                    dispatch(actions.setAllOrganizationRoles(response.data));
            }).catch(err => {
                console.log(err)
            });
        };   
    }

    const getOrganizationById = id => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationData',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 10
            },
            filtering:{
                searchString: null,
                fieldsFilter: {
                    id : id
                }
            },
            sorting: [
                    {
                        direction: 0,
                        propertyName: "name"
                    }
                ]
            } 
            }).then((response) => {
                dispatch(actions.setOrganizationData(response.data));
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }));
            });
        };
    };

    const deleteOrganization = ({id, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.organization}/${id}`,
            }).then((response) => {
            dispatch(registerMessage({name: 'delete-branch-message', type: 'primary', title: 'Отделение удалено', closable: true}))
            dispatch(showMessage('delete-branch-message'))
            dispatch(closeMessage({name: 'delete-branch-message', delay: systemMessageDelay}));
            callback && callback();
            }).catch(err => {
                console.log(err);
                dispatch(registerMessage({name: 'delete-branch-message', type: 'red', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Ошибка запроса', closable: true}))
                dispatch(showMessage('delete-branch-message'))
                dispatch(closeMessage({name: 'delete-branch-message', delay: systemMessageDelay}))
            });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getPatientsResponseTimeExceeded = ({organizationId, tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'timeExceededData',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsResponseTimeExceeded`,
                data: {
                    paging: tableSettings.paging,
                    filtering: {
                        searchString: tableSettings.searchString,
                        fieldsFilter: tableSettings.fieldsFilter,
                    },
                    sorting: tableSettings.sorting,
                }
            }).then((response) => {
                dispatch(actions.setPatientsResponseTimeExceededData(response.data));
                dispatch(actions.setLoading({
                    type: 'timeExceededData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'timeExceededData',
                    value: false
                }));
            });
        };
    };

    const getPatientsEndOfCounselingPeriod = ({organizationId, tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'endOfCounselingData',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsEndOfCounselingPeriod`,
                data: {
                    paging: tableSettings.paging,
                    filtering: {
                        searchString: tableSettings.searchString,
                        fieldsFilter: tableSettings.fieldsFilter,
                    },
                    sorting: tableSettings.sorting,
                }
            }).then((response) => {
                dispatch(actions.setPatientsEndOfCounselingPeriodData(response.data));
                dispatch(actions.setLoading({
                    type: 'endOfCounselingData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'endOfCounselingData',
                    value: false
                }));
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.resetPassword}/${email}`,
                params: {
                    usertype: userType
                }
            }).then((response) => {
                dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
                dispatch(showMessage('send-email-message'))
                dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
            }).catch((error) => {
                dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
                dispatch(showMessage('reset-password-error-message'));
                dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
            })
        };
    };

    return { getOrganizationsForFilter, getOrganizationRoles, getOrganizationById, deleteOrganization, sendActivationNotification, getPatientsResponseTimeExceeded, getPatientsEndOfCounselingPeriod, resetPassword }

}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        filialBranchActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };