import React, {useEffect, useState} from 'react';
import {Wrapper} from "styled/Wrapper";
import {Row} from "styled/Row";
import Select from "components/Select/Select";
import Input from "components/Input/Input";
import {Form, Field} from 'react-final-form';
import {
    selectCatalogs,
    getGendersCatalog,
} from "reducers/catalogs";
import {composeValidators, requiredValidator, phoneValidator, emailValidator} from "utils/validators";
import {useSelector, useDispatch} from "react-redux";
import {get, isEmpty, cloneDeep, find} from "lodash";
import PhoneInput from "components/PhoneInput/PhoneInput";
import UserImage from "components/UserImage";
import styled from "styled-components";
import Group from "components/Group";
import PrimaryButton from "components/Button/PrimaryButton";
import SecondaryButton from "components/Button/SecondaryButton";
import {selectPractitioner, selectRole, editDoctor} from "reducers/doctor";
import {getImageUrl} from "utils";
import {useModal} from "components/Modal/useModal";
import DoctorProfile from "../../DoctorProfile";
import DoctorProfileHeader from "../../DoctorProfileHeader";
import DoctorProfileFooter from "../../DoctorProfileFooter";
import {closeMessage, registerMessage, showMessage} from 'reducers/systemMessages/systemMessagesSlice';
import {systemMessageDelay} from 'consts';
import {CustomView, isDesktop, isMobileOnly, isTablet, MobileOnlyView} from 'react-device-detect';
import ClearIcon from "../../../../../../../components/Icons/ClearIcon";

const DoctorEditProfile = ({
                               item,
                               hideModal
                           }) => {
    const dispatch = useDispatch();
    const token = useSelector(state => get(state, "app.access_token"));
    const catalogs = useSelector(selectCatalogs);
    const practitioner = useSelector(selectPractitioner);
    const role = useSelector(selectRole);
    const [practitionerGender, setPractitionerGender] = useState('');
    const [doctorImageUrl, setDoctorImageUrl] = useState('');
    const [doctorImageFile, setDoctorImageFile] = useState('');

    const [showProfileModal] = useModal({
        component: DoctorProfile,
        header: DoctorProfileHeader,
        footer: DoctorProfileFooter,
        name: 'doctor-profile',
        props: {modalWidth: '560px'}
    })

    useEffect(() => {
        setPractitionerGender(catalogs.genders.find(item => item.code === get(practitioner, "gender")));
    }, [practitioner, catalogs, practitionerGender]);

    useEffect(() => {
        if (isEmpty(catalogs.genders)) dispatch(getGendersCatalog());
    }, []);

    useEffect(() => {
        setDoctorImageUrl(get(practitioner, "photo[0].url", '') ? getImageUrl(get(practitioner, "photo[0].url", ''), token) : '');
    }, [practitioner]);

    const handleFileOnChange = (file, url) => {
        // console.log("url", url);
        setDoctorImageUrl(url);
        setDoctorImageFile(file);
    }

    const handleCancel = () => {
        hideModal();
        showProfileModal();
    }

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper paddings={'0 24px 12px'}>
                    <Form
                        mutators={{
                            setImageTouched: (args, state, utils) => {
                                utils.changeValue(state, 'image', () => 1)
                            },
                        }}
                        initialValues={{
                            practitionerName: get(practitioner, "name[0].text"),
                            practitionerEmail: find(role?.telecom, {system: "email"})?.value,
                            practitionerPhone: find(role?.telecom, {system: "phone"})?.value,
                            practitionerGender,
                        }}
                        onSubmit={(values) => {
                            //console.log("onSubmit", values);

                            let practitionerObj = cloneDeep(practitioner);
                            let nameArr = values.practitionerName.split(" ");
                            practitionerObj.name[0].text = values.practitionerName;
                            practitionerObj.name[0].family = get(nameArr, "[0]", "");
                            practitionerObj.name[0].given = [];
                            practitionerObj.name[0].given[0] = get(nameArr, "[1]", "");
                            practitionerObj.name[0].given[1] = get(nameArr, "[2]", "");
                            practitionerObj.gender = values.practitionerGender.code;

                            let practitionerRoleObj = cloneDeep(role);
                            let phone = find(practitionerRoleObj?.telecom, {system: "phone"});
                            let email = find(practitionerRoleObj?.telecom, {system: "email"});
                            email.value = values.practitionerEmail;
                            phone.value = values.practitionerPhone;

                            dispatch(editDoctor({
                                image: doctorImageFile,
                                practitioner: practitionerObj,
                                practitionerRole: practitionerRoleObj
                            }));
                            if (!isMobileOnly) {
                                hideModal();
                            }
                        }}
                    >
                        {({handleSubmit, pristine, invalid, submitting, form}) => (
                            <>
                                {/* <PerfectScrollbarWrapper paddings={'0 16px 0 0'}> */}
                                <form onSubmit={handleSubmit}>
                                    <Row marginBottom={'16px'}>
                                        <CustomView condition={isDesktop || isTablet}>
                                            <ImageWrapper>
                                                <UserImage
                                                    img={doctorImageUrl}
                                                    type={"user"}
                                                    onChange={(file, url) => {
                                                        handleFileOnChange(file, url);
                                                        // form.mutators.setImageTouched();
                                                        if (file && url) {
                                                            form.mutators.setImageTouched();
                                                        } else {
                                                            dispatch(registerMessage({
                                                                name: 'uploading-profile-image-error-message',
                                                                type: 'red',
                                                                title: 'Файл не поддерживается',
                                                                text: '',
                                                                closable: true
                                                            }))
                                                            dispatch(showMessage('uploading-profile-image-error-message'))
                                                            dispatch(closeMessage({
                                                                name: 'uploading-profile-image-error-message',
                                                                delay: systemMessageDelay
                                                            }))
                                                        }
                                                    }}
                                                />
                                            </ImageWrapper>
                                            <HiddenField>
                                                <Field
                                                    name={"image"}
                                                >
                                                    {({input, meta}) => <Input
                                                        {...input}
                                                    />}
                                                </Field>
                                            </HiddenField>
                                        </CustomView>
                                        <Field name={"practitionerName"}
                                               validate={requiredValidator}
                                        >
                                            {({input, meta}) => <Input
                                                {...input}
                                                onBlur={e => input.onBlur(e)}
                                                placeholder={"Введите ФИО врача"}
                                                label={"ФИО"}
                                                showError={meta.touched && meta.error}
                                                error={meta.touched && meta.error}
                                                required={true}
                                            />}
                                        </Field>
                                    </Row>
                                    <Wrapper margins={'0 0 16px 0'} width={'170px'}>
                                        <Field
                                            name={"practitionerGender"}
                                            component={"select"}
                                            options={catalogs.genders}
                                            validate={requiredValidator}
                                        >
                                            {({input, meta, options}) => {
                                                return <Select
                                                    {...input}
                                                    placeholder={"Выберите пол"}
                                                    active={input.value.code ? input.value.code : ''}
                                                    options={options}
                                                    label={"Пол"}
                                                    required={true}
                                                    onChange={e => input.onChange(e)}
                                                    showError={meta.touched && meta.error}
                                                    error={meta.touched && meta.error}
                                                />
                                            }}
                                        </Field>
                                    </Wrapper>
                                    <Wrapper margins={'0 0 16px 0'} width={'170px'}>
                                        <Field
                                            name="practitionerPhone"
                                            validate={composeValidators(requiredValidator, phoneValidator)}
                                        >{({input, meta}) => <PhoneInput
                                            label={"Номер телефона"}
                                            placeholder={"Введите телефон"}
                                            disabled={false}
                                            mask="+7 (999) 999-99-99"
                                            {...input}
                                            meta={meta}
                                            required={true}
                                            showError={meta.touched && meta.error}
                                            error={meta.touched && meta.error}
                                        />}
                                        </Field>
                                    </Wrapper>
                                    <Wrapper margins={'0 0 12px 0'}>
                                        <Field name={"practitionerEmail"}
                                               validate={composeValidators(requiredValidator, emailValidator)}
                                        >
                                            {({input, meta}) => <Input
                                                {...input}
                                                onBlur={e => input.onBlur(e)}
                                                placeholder={"Введите электронную почту врача"}
                                                label={"Электронная почта"}
                                                showError={meta.touched && meta.error}
                                                error={meta.touched && meta.error}
                                                required={true}
                                            />}
                                        </Field>
                                    </Wrapper>
                                </form>
                                {/* </PerfectScrollbarWrapper> */}
                                <ButtonsWrapper>
                                    <Wrapper direction={'row'} justify={'flex-end'} width={'100%'} paddings={'0'}>
                                        <Group align={'center'}>
                                            <Group.Item>
                                                <PrimaryButton onClick={handleSubmit}
                                                               disabled={pristine || invalid || submitting}
                                                               label={get(practitioner, "id") || (item && item.practitionerId) ? 'Сохранить' : 'Создать'}/>
                                            </Group.Item>
                                            <Group.Item>
                                                <SecondaryButton onClick={() => handleCancel()} label={'Отмена'}/>
                                            </Group.Item>
                                        </Group>
                                    </Wrapper>
                                </ButtonsWrapper>
                            </>
                        )}
                    </Form>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper paddings={'16px'} maxHeight={'calc(var(--vh, 1vh) * 100 - 50px)'} overflow={'auto'}>
                    <Form
                        mutators={{
                            setImageTouched: (args, state, utils) => {
                                utils.changeValue(state, 'image', () => 1)
                            },
                        }}
                        initialValues={{
                            practitionerName: get(practitioner, "name[0].text"),
                            practitionerEmail: find(role?.telecom, {system: "email"})?.value,
                            practitionerPhone: find(role?.telecom, {system: "phone"})?.value,
                            practitionerGender,
                        }}
                        onSubmit={(values) => {
                            //console.log("onSubmit", values);

                            let practitionerObj = cloneDeep(practitioner);
                            let nameArr = values.practitionerName.split(" ");
                            practitionerObj.name[0].text = values.practitionerName;
                            practitionerObj.name[0].family = get(nameArr, "[0]", "");
                            practitionerObj.name[0].given = [];
                            practitionerObj.name[0].given[0] = get(nameArr, "[1]", "");
                            practitionerObj.name[0].given[1] = get(nameArr, "[2]", "");
                            practitionerObj.gender = values.practitionerGender.code;

                            let practitionerRoleObj = cloneDeep(role);
                            let phone = find(practitionerRoleObj?.telecom, {system: "phone"});
                            let email = find(practitionerRoleObj?.telecom, {system: "email"});
                            email.value = values.practitionerEmail;
                            phone.value = values.practitionerPhone;

                            dispatch(editDoctor({
                                image: doctorImageFile,
                                practitioner: practitionerObj,
                                practitionerRole: practitionerRoleObj
                            }));
                            if (!isMobileOnly) {
                                hideModal();
                            }
                        }}
                    >
                        {({handleSubmit, pristine, invalid, submitting, form}) => (
                            <>
                                {/* <PerfectScrollbarWrapper paddings={'0 16px 0 0'}> */}
                                <form onSubmit={handleSubmit}>
                                    <Row marginBottom={'16px'}>
                                        <CustomView condition={isDesktop || isTablet}>
                                            <ImageWrapper>
                                                <UserImage
                                                    img={doctorImageUrl}
                                                    type={"user"}
                                                    onChange={(file, url) => {
                                                        handleFileOnChange(file, url);
                                                        // form.mutators.setImageTouched();
                                                        if (file && url) {
                                                            form.mutators.setImageTouched();
                                                        } else {
                                                            dispatch(registerMessage({
                                                                name: 'uploading-profile-image-error-message',
                                                                type: 'red',
                                                                title: 'Файл не поддерживается',
                                                                text: '',
                                                                closable: true
                                                            }))
                                                            dispatch(showMessage('uploading-profile-image-error-message'))
                                                            dispatch(closeMessage({
                                                                name: 'uploading-profile-image-error-message',
                                                                delay: systemMessageDelay
                                                            }))
                                                        }
                                                    }}
                                                />
                                            </ImageWrapper>
                                            <HiddenField>
                                                <Field
                                                    name={"image"}
                                                >
                                                    {({input, meta}) => <Input
                                                        {...input}
                                                    />}
                                                </Field>
                                            </HiddenField>
                                        </CustomView>
                                        <Field name={"practitionerName"}
                                               validate={requiredValidator}
                                        >
                                            {({input, meta}) => <Input
                                                {...input}
                                                onBlur={e => input.onBlur(e)}
                                                placeholder={"Введите ФИО врача"}
                                                label={"ФИО"}
                                                showError={meta.touched && meta.error}
                                                error={meta.touched && meta.error}
                                                required={true}
                                            />}
                                        </Field>
                                    </Row>
                                    <Wrapper margins={'0 0 16px 0'}>
                                        <Field
                                            name={"practitionerGender"}
                                            component={"select"}
                                            options={catalogs.genders}
                                            validate={requiredValidator}
                                        >
                                            {({input, meta, options}) => {
                                                return <Select
                                                    {...input}
                                                    placeholder={"Выберите пол"}
                                                    active={input.value.code ? input.value.code : ''}
                                                    options={options}
                                                    label={"Пол"}
                                                    required={true}
                                                    iconSize={24}
                                                    onChange={e => input.onChange(e)}
                                                    showError={meta.touched && meta.error}
                                                    error={meta.touched && meta.error}
                                                />
                                            }}
                                        </Field>
                                    </Wrapper>
                                    <Wrapper margins={'0 0 16px 0'}>
                                        <Field
                                            name="practitionerPhone"
                                            validate={composeValidators(requiredValidator, phoneValidator)}
                                        >{({input, meta}) => <PhoneInput
                                            label={"Номер телефона"}
                                            placeholder={"Введите телефон"}
                                            disabled={false}
                                            mask="+7 (999) 999-99-99"
                                            {...input}
                                            meta={meta}
                                            required={true}
                                            showError={meta.touched && meta.error}
                                            error={meta.touched && meta.error}
                                        />}
                                        </Field>
                                    </Wrapper>
                                    <Wrapper margins={'0 0 12px 0'}>
                                        <Field name={"practitionerEmail"}
                                               validate={composeValidators(requiredValidator, emailValidator)}
                                        >
                                            {({input, meta}) => <Input
                                                {...input}
                                                onBlur={e => input.onBlur(e)}
                                                placeholder={"Введите электронную почту врача"}
                                                label={"Электронная почта"}
                                                showError={meta.touched && meta.error}
                                                error={meta.touched && meta.error}
                                                required={true}
                                            />}
                                        </Field>
                                    </Wrapper>
                                </form>
                                <ButtonsWrapper>
                                    <Wrapper direction={'row'} justify={'flex-end'} width={'100%'} paddings={'0'}>
                                        <PrimaryButton onClick={handleSubmit}
                                                       disabled={pristine || invalid || submitting}
                                                       fullWidth
                                                       label={get(practitioner, "id") || (item && item.practitionerId) ? 'Сохранить' : 'Создать'}/>
                                    </Wrapper>
                                </ButtonsWrapper>
                            </>
                        )}
                    </Form>
                </Wrapper>
            </MobileOnlyView>
        </>
    );
};

const ButtonsWrapper = styled.div`
  margin-top: 12px;
`

const ImageWrapper = styled.div`
  margin-right: 12px;
`

const HiddenField = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  flex-basis: 0;
`

DoctorEditProfile.propTypes = {};

export default DoctorEditProfile;
