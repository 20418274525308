import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {find, get} from "lodash";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import { systemMessageDelay } from 'consts';

const initialState = {
    data: [],
    loading: {
        fullPage: true,
        data: true,
    },
    clientQuery: []
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setClientQuery: (state, action) => {
            state.clientQuery = action.payload;
        },
        setEventsCatalog: (state, action) => {
            state.data = action.payload;
        },
    }
});

export const selectDataEvents = state => state.superAdmin.events.data;
export const selectLoading = state => state.superAdmin.events.loading;
export const selectClientQuery = state => state.superAdmin.events.clientQuery;
export const eventsActions = eventsSlice.actions;

export const getEvents = ({tableSettings}) => {
    return (dispatch) => {
        dispatch(eventsActions.setLoading({
            type: 'data',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.activityDefinition}/_search`,
            data: {
                paging: {
                    ...tableSettings?.paging
                },
                filtering: {
                    searchString: tableSettings?.searchString,
                    fieldsFilter: {
                        ...tableSettings?.filtering?.fieldsFilter,
                    },
                },
                sorting: [...tableSettings?.sorting]
            }
        }).then((response) => {
            const eventsItems = response.data.items?.map(item => {
                return {
                    id: get(item, "id"),
                    code: get(item, `identifier`) ? find(get(item, `identifier`), {system: "urn:mmdx:onlinedoc:entity:activitydefinition:id"})?.value : '--',
                    title: get(item, "title"),
                    codeTpp: get(item, `identifier`) ? find(get(item, `identifier`), {system: "urn:mmdx:onlinedoc:entity:activitydefinition:code"})?.value : '--',
                }
            })
            dispatch(eventsActions.setEventsCatalog(eventsItems));
            dispatch(eventsActions.setClientQuery(response.data.clientQuery));
            dispatch(eventsActions.setLoading({
                type: 'data',
                value: false
            }));
        }).catch(err => {
            dispatch(eventsActions.setLoading({
                type: 'data',
                value: true
            }));
        });
    };
};

export const deleteEvent = ({id, tableSettings, hideModal}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.activityDefinition}/${id}`,
        }).then((response) => {
            dispatch(registerMessage({name: 'delete-event-message', type: 'primary', title: 'Мероприятие удалено', closable: true}))
            dispatch(showMessage('delete-event-message'))
            dispatch(closeMessage({name: 'delete-event-message', delay: systemMessageDelay}))
            dispatch(hideModal);
            dispatch(getEvents({tableSettings}));
        }).catch(err => {
            dispatch(registerMessage({
                name: 'delete-event-error-message',
                type: 'red',
                title: `Не удалось удалить мероприятие`,
                text: get(err, 'response.data', ""),
                closable: true,
            }))
            dispatch(showMessage('delete-event-error-message'))
            dispatch(closeMessage({name: 'delete-event-error-message', delay: systemMessageDelay}))
        });
    };
};

export const addEvent = ({formData, tableSettings, hideModal}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.activityDefinition}`,
            data: {
                resourceType: "ActivityDefinition",
                title: formData.title,
                status: "active",
                kind: "Task",
                identifier: [
                    {
                        use: "usual",
                        value: formData.codeTpp,
                        system: "urn:mmdx:onlinedoc:entity:activitydefinition:code"
                    },
                ]
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'add-event-message', type: 'primary', title: 'Мероприятие создано', closable: true}))
            dispatch(showMessage('add-event-message'))
            dispatch(closeMessage({name: 'add-event-message', delay: systemMessageDelay}))
            dispatch(hideModal);
            dispatch(getEvents({tableSettings}));
        }).catch(err => {
        });
    };
};

export const editEvent = ({formData, tableSettings, hideModal}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.activityDefinition}`,
            data: {
                resourceType: "ActivityDefinition",
                id: formData.id,
                title: formData.title,
                status: "active",
                kind: "Task",
                identifier: [
                    {
                        use: "usual",
                        value: formData.codeTpp,
                        system: "urn:mmdx:onlinedoc:entity:activitydefinition:code"
                    },
                    {
                        use: "usual",
                        value: formData.id,
                        system: "urn:mmdx:onlinedoc:entity:activitydefinition:id"
                    }
                ]
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'edit-event-message', type: 'primary', title: 'Данные обновлены', closable: true}))
            dispatch(showMessage('edit-event-message'))
            dispatch(closeMessage({name: 'edit-event-message', delay: systemMessageDelay}))
            dispatch(hideModal);
            dispatch(getEvents({tableSettings}));
        }).catch(err => {
        });
    };
};

export default eventsSlice.reducer;