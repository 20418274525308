import {get, isEmpty} from "lodash";
import React from "react";
import TableText from "components/Table/TableText";
import TableChannels from "components/Table/TableChannels";
import TablePrimaryText from "components/Table/TablePrimaryText";
import ArrowIcon from "components/Icons/ArrowIcon";
import Icon from "components/Icon";
import { findInTypeBySystem } from "utils";
import CircleProgress from "components/CircleProgress";
import { Wrapper } from "styled/Wrapper";

export const findElementByElementId = (arr, elementId, childObjectName) => {
    let targetElement;
    let firstLevelElement = arr.find(item => (item.organization && item.organization.id === elementId) || (item.practitioner && item.practitioner.id === elementId));
    if (firstLevelElement) {
        targetElement = firstLevelElement;
    } else {
        arr.forEach(item => {
            if (item.content) {
                const element = findElementByElementId(item.content, elementId);
                if(!!element) targetElement = element;
            }
        })
    }
    return targetElement;
}

export const findOrganizationsByElementId = (arr, elementId) => {
    // console.log("arr", arr);
    // console.log("elementId", elementId);
    let targetElement;
    let firstLevelElement = arr.find(item => (item.organization && item.organization.id === elementId) || (item.practitioner && item.practitioner.id === elementId));
    if (firstLevelElement) {
        // console.log("firstLevelElement");
        targetElement = firstLevelElement;
    } else {
        // console.log("else childElements");
        arr.forEach(item => {
            if (item.childElements && !targetElement) {
                targetElement = findOrganizationsByElementId(item.childElements, elementId);
            }
        })
    }
    // console.log("targetElement", targetElement);
    return targetElement;
}

export const findCheckedElementsOnFirstLevel = arr => {
    let checkedStr = '';
    arr.forEach(item => {
        if (item.checked) checkedStr = checkedStr ? `${checkedStr},${item.organization.id}` : `${item.organization.id}`;
    });
    return checkedStr;
}

export const findAllCheckedElementsIds = arr => {
    let result = [];

    arr.forEach(item => {
        if (item.checked){
            result.push(item.organization?.id);
            if(!isEmpty(item?.childElements)) result = result.concat(findAllCheckedElementsIds(item?.childElements));
        }
    });

    return result;
}

export const adaptObjectForMessageModeTable = (item) => {
    
    const type = get(item, "organization") ?
        findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code :
        findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code;
    const profile = get(item, "organization") ?
        findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code :
        findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code;
        
    const getFilials = (type, item) => {
        if (type === "organization") {
            return (item?.clinicCount ? `${item?.clinicCount} фил.` : '') +
                    (item?.clinicCount && item?.departmentCount ? ` ・ ` : '') +
                    (item?.departmentCount ? `${item?.departmentCount} отд.`: '')
        } else if (type === "clinic") {
            if(profile !== "multiprofile"){
                return (item?.departmentCount ? `${item?.departmentCount} отд.`: '') +
                (item?.departmentCount && item?.doctorCount ? ` ・ ` : '') +
                (item?.doctorCount ? `${item?.doctorCount} вр.`: '')
            }else{
                return item?.departmentCount ? `${item?.departmentCount} отд.`: ''
            }
        } else if (type === "department") {
            return item?.doctorCount ? `${item?.doctorCount} вр.`: ''
        } else {
            return null
        }
    }
    return (
        {
            id: get(item, "statistics.organizationId") || get(item, "statistics.practitionerId"),
            name: type ? (
                <>
                    <TablePrimaryText
                        text={get(item, "organization.name") || get(item, "practitioner.name")}
                        tooltip={get(item, "organization.name") || get(item, "practitioner.name")}
                    />
                    <TableText text={getFilials(type, item)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                    {
                        item?.loading ? 
                        <Wrapper margins='0 0 0 8px' width='initial'>
                            <Icon icon={CircleProgress} />
                        </Wrapper>
                        : 
                        (item?.clinicCount > 0 || item?.departmentCount > 0 || item?.doctorCount > 0) &&
                        <Wrapper margins='0 0 0 8px' width='initial'>
                            <Icon icon={ArrowIcon} rotate={item.open ? '180' : ''}/>
                        </Wrapper>
                    }
                </>
            ) : (
                <>
                    <TablePrimaryText
                        text={get(item, "organization.name") || get(item, "practitioner.name")}
                        tooltip={get(item, "organization.name") || get(item, "practitioner.name")}
                    />
                    <TableText text={getFilials(type, item)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                </>
            ),
            overall: <TableText text={item.statistics.totalFromPatient} type={'secondary'} />,
            answered: <TableText text={item.statistics.totalFromPractitioner} type={'secondary'} />,
            expired: <TableText text={item.statistics.notAnsweredAndOverdue} type={'alert'} />,
            totalExpired: <TableText text={item.statistics.notAnsweredOrAnsweredOverdue} type={'secondary'} />,
            part: get(item, "organization.id") || get(item, "practitioner.id"),
            partof: get(item, "partof"),
            open: item.open,
            practitioner: {
                id: get(item, "practitioner.id"),
                name: get(item, "practitioner.name"),
                practitionerData: get(item, "practitioner.practitionerData"),
            },
            practitionerRole: get(item, "practitionerRole"),
            clinicCount: item?.clinicCount,
            departmentCount: item?.departmentCount,
            doctorCount: item?.doctorCount,
            organizationType: type,
            organizationProfile: profile,
            loading: item?.loading
        }
    )
}

export const adaptObjectForChannelsModeTable = (item) => {
    const type = get(item, "organization") ?
        findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code :
        findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code;
    const profile = get(item, "organization") ?
        findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code :
        findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code;

    const getFilials = (type, item) => {
        if (type === "organization") {
            return (item?.clinicCount ? `${item?.clinicCount} фил.` : '') +
                    (item?.clinicCount && item?.departmentCount ? ` ・ ` : '') +
                    (item?.departmentCount ? `${item?.departmentCount} отд.`: '')
        } else if (type === "clinic") {
            return (item?.departmentCount ? `${item?.departmentCount} отд.`: '') +
            (item?.departmentCount && item?.doctorCount ? ` ・ ` : '') +
            (item?.doctorCount ? `${item?.doctorCount} вр.`: '')
        } else if (type === "department") {
            return item?.doctorCount ? `${item?.doctorCount} вр.`: ''
        } else {
            return null
        }
    }
    let profileName = findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.display
    profileName = profileName ? profileName.charAt(0).toUpperCase() + profileName.slice(1) : '';
    return (
        {
            id: get(item, "statistics.organizationId") || get(item, "statistics.practitionerId"),
            name: type ? (
                <>
                    <TablePrimaryText
                        text={get(item, "organization.name") || get(item, "practitioner.name")}
                        tooltip={get(item, "organization.name") || get(item, "practitioner.name")}
                    />
                    <TableText text={getFilials(type, item)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                    {
                        // type !== "department" && 
                        item?.loading ? 
                        <Wrapper margins='0 0 0 8px' width='initial'>
                            <Icon icon={CircleProgress} />
                        </Wrapper>
                        : 
                        (item?.clinicCount > 0 || item?.departmentCount > 0 || item?.doctorCount > 0) &&
                        <Wrapper margins='0 0 0 8px' width='initial'>
                            <Icon icon={ArrowIcon} rotate={item.open ? '180' : ''}/>
                        </Wrapper>
                    }
                </>
            ) : (
                <>
                    <TablePrimaryText
                        text={get(item, "organization.name") || get(item, "practitioner.name")}
                        tooltip={get(item, "organization.name") || get(item, "practitioner.name")}
                    />
                    <TableText text={getFilials(type, item)} type={'primaryGrey'} paddings={'0 0 0 12px'} />
                </>
            ),
            profile: <TableText text={profileName} type={'secondary'} />,
            active: <TableChannels stats={{
                amount: item.statistics.active ?? '—',
                doesNotMatch: item.statistics.active - item.statistics.activeCompliance ?? '—',
                match: item.statistics.activeCompliance ?? '—'
            }} />,
            inactive: <TableChannels stats={{
                amount: item.statistics.inActive ?? '—',
                doesNotMatch: item.statistics.inActive - item.statistics.inActiveCompliance ?? '—',
                match: item.statistics.inActiveCompliance ?? '—'
            }} />,
            archive: <TableChannels stats={{
                amount: item.statistics.archive ?? '—',
                doesNotMatch: item.statistics.archive - item.statistics.archiveCompliance ?? '—',
                match: item.statistics.archiveCompliance ?? '—'
            }} />,
            part: get(item, "organization.id") || get(item, "practitioner.id"),
            partof: get(item, "partof"),
            open: item.open,
            practitioner: {
                id: get(item, "practitioner.id"),
                name: get(item, "practitioner.name"),
                practitionerData: get(item, "practitioner.practitionerData"),
            },
            practitionerRole: get(item, "practitionerRole"),
            clinicCount: item?.clinicCount,
            departmentCount: item?.departmentCount,
            doctorCount: item?.doctorCount,
            organizationType: type,
            organizationProfile: profile,
            loading: item?.loading
        }
    )
}

export const getAdaptedContentByStatType = (type, content) => {
    switch (type) {
        case 0: return adaptObjectForChannelsModeTable(content);
        case 1: return adaptObjectForMessageModeTable(content);
        default: adaptObjectForChannelsModeTable(content);
    }
}

export const createProfilesFilterParams = obj => {
    // if ((obj.allItems.length === obj.checkedItems.length) || (obj.checkedItems.length === 0)) {
    if (obj.length === 0) {
        return ''; 
    } else {
        let result = [];
        obj.forEach(item => {
            result.push(item.code);
        });
        return result.join();
    }
}