import React from "react";

const renderComponent = (component) => {
    const Component = component;

    if (typeof component === "function") {
        return <Component />;
    } else if (typeof component === "string") {
        return component;
    }

    return null;
};

export {renderComponent}
