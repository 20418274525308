import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {cloneDeep, find, forEach, get} from "lodash";
import { getAccountModel } from 'adapters/super_admin/accounts';
// import { getAccounts } from './accounts';
import { roles } from 'pages/AccountsPage/modals/AddAccountModal/options';
import { routes } from 'consts/routes';
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';
import { findInTypeBySystem } from 'utils';

const initialState = {
    clientQuery: [],
    loading: {
        fullPage: true,
        data: true,
        createOrUpdateFundAdmin: false,
        createOrUpdateManager: false,
        editDoctor: false,
        createDoctor: false,
        authHistory: true
    },
    patients: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {

            },
            sorting: [
                {
                    propertyName: "practitioner",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    accountData: {},
    accountLoginForModal: null,
    modalAnswerTimingRule: null,
    options:{
        organization: {
            filialOptions: [],
            branchOptions: []
        },
        filial: {
            branchOptions: []
        }
    },
    fundOrganization: null,
    authHistory: null
}

const reducers = {
    setPage: (state, action) => {
        state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setClientQuery: (state, action) => {
        state.clientQuery = action.payload;
    },
    setSorting: (state, action) => {
        state[action.payload.table].tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state[action.payload.table].tableSettings.sorting[0].direction = state[action.payload.table].tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setAccountData: (state, action) => {
        state.accountData = action.payload;
    },
    setAccountLoginForModal: (state, action) => {
        state.accountLoginForModal = action.payload;
    },
    setModalAnswerTimingRule: (state, action) => {
        state.modalAnswerTimingRule = action.payload;
    },
    setAuthHistory: (state, action) => {
        state.authHistory = action.payload;
    },
    setOptions: (state, action) => {
        state.options[action.payload.type] = action.payload.value
    },
    setFundOrganization: (state, action) => {
        state.fundOrganization = action.payload
    },
    cleanOptions: (state, action) => {
        state.options = initialState.options;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectLoading: state => get(state, statePath)?.loading,
    selectPatientsTableSettings: state => get(state, statePath)?.patients.tableSettings,
    selectClientQuery: state => get(state, statePath)?.clientQuery,
    selectAccountData: state => get(state, statePath)?.accountData,
    selectAccountLoginForModal: state => get(state, statePath)?.accountLoginForModal,
    selectModalAnswerTimingRule: state => get(state, statePath)?.modalAnswerTimingRule,
    selectOptions: state => get(state, statePath)?.options,
    selectFundOrganization: state => get(state, statePath)?.fundOrganization,
    selectAuthHistory: state => get(state, statePath)?.authHistory,
})

export const getThunks = (actions) => {

    const getAccountData = ({practitionerRoleId}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}`,
                params: {
                    fg: `id::${practitionerRoleId}:x:_include::practitioner,organization`
                }
            }).then((response) => {

                const item = find(get(response,'data.items'), {id: practitionerRoleId});
                const resources = get(response, 'data.resources')

                dispatch(actions.setAccountData(getAccountModel({
                    practitioner: get(resources, `${item?.practitioner?.reference}`),
                    practitionerRole: item,
                    organization: get(resources, `${item?.organization?.reference}`)
                })))

                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    const archiveAccount = ({item, practitionerRoleId}) => {
        return (dispatch) => {
        fetchInstance({
                method: "PUT",
                url: api.practitionerRole,
                data: item
            }).then((response) => {
                dispatch(getAccountData({practitionerRoleId}));
            }).catch(error => {
                console.log("error", error);
            });
        };
    };

    const restoreAccount = ({id}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${id}/RestoreFromArchive`
            }).then((response) => {
                dispatch(getAccountData({practitionerRoleId: id}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const deleteAccount = ({id, appRole, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "DELETE",
                url: `${api.practitionerRole}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({
                    name: 'delete-account-message',
                    type: 'primary',
                    title: `${find(roles, {value: appRole})?.label} удален`,
                    closable: true,
                    url: routes.private.root.accounts.path
                }))
                dispatch(showMessage('delete-account-message'))
                dispatch(closeMessage({name: 'delete-account-message', delay: systemMessageDelay}))
                callback && callback();
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({
                    name: 'delete-account-error-message',
                    type: 'red',
                    title: `Не удалось удалить учетную запись`,
                    text: get(err, 'response.data'),
                    closable: true,
                }))
                dispatch(showMessage('delete-account-error-message'))
                dispatch(closeMessage({name: 'delete-account-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(error => {
                console.log("error", error);
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getAccountLogin = ({practitionerRoleId}) => {
        return (dispatch) => {

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${practitionerRoleId}/GetUserLogin`
            }).then((response) => {
                dispatch(actions.setAccountLoginForModal(response?.data?.value))
            }).catch(err => {
                console.log(err);
            });
        };
    };

    const sendMessage = ({text, subject, recipientPractitionerRoleId, senderPractitionerRoleId, hideModal}) => {
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/fomsnotification`,
                data: {
                    messageText: text,
                    subject,
                    recipientPractitionerRoleId: recipientPractitionerRoleId,
                    senderPractitionerRoleId: senderPractitionerRoleId
                }
            }).then((response) => {
                dispatch(registerMessage({name: 'send-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
                dispatch(hideModal);
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'send-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            });
        };
    };

    const createOrUpdateFundAdminData = ({formData, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateFundAdmin',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatefundadmin`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateFundAdmin',
                    value: false
                }));

                dispatch(getAccountData({practitionerRoleId: formData.practitionerRoleId}))
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-fund-admin-message',
                    type: 'primary',
                    title: `Сотрудник фонда успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-fund-admin-message'))
                dispatch(closeMessage({name: 'create-update-fund-admin-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateFundAdmin',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-fund-admin-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} сотрудника фонда`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-fund-admin-error-message'))
                dispatch(closeMessage({name: 'create-update-fund-admin-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const createOrUpdateAnalystData = ({formData, tableSettings, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateAnalyst',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateanalyst`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateAnalyst',
                    value: false
                }));

                dispatch(getAccountData({practitionerRoleId: formData.practitionerRoleId}))
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-analyst-message',
                    type: 'primary',
                    title: `Аналитик успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-analyst-message'))
                dispatch(closeMessage({name: 'create-update-analyst-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateAnalyst',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-analyst-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} аналитика`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-analyst-error-message'))
                dispatch(closeMessage({name: 'create-update-analyst-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const createOrUpdateManagerData = ({formData, appRole, callback, isEdit}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.practitionerPosition?.code,
                        positionDisplay: formData?.practitionerPosition?.display,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: false,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        loginEmail: formData?.loginEmail
                    },
                    appRole
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                dispatch(getAccountData({practitionerRoleId: formData.practitionerRoleId}));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-manager-message',
                    type: 'primary',
                    title: `${appRole === 'registrator' ? 'Администратор МО' : appRole === 'organization-admin' ? 'Руководитель МО' : 'Сотрудник'} успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-manager-message'))
                dispatch(closeMessage({name: 'create-update-manager-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-manager-error-message',
                    type: 'red',
                    title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Не удалось ${isEdit ? 'обновить' : 'создать'} ${appRole === 'registrator' ? 'администратора МО' : appRole === 'organization-admin' ? 'руководителя МО' : 'сотрудника'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-manager-error-message'))
                dispatch(closeMessage({name: 'create-update-manager-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const getAnswerTimingRule = id => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/AnswerTimingRule`
                }).then((response) => {
                    dispatch(dispatch(actions.setModalAnswerTimingRule(response.data)));
                    // if (get(response.data, "rule.timeoutMinutes")) dispatch(dispatch(actions.setTimeoutMinutes(get(response.data, "rule.timeoutMinutes", ''))));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPartOfOrganizations = ({partOf, type, appRole}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
            start: 0,
            max: 0,
                fg: `partof::${partOf}`
            }
        }).then((response) => {
                const items = get(response, 'data.items');
                const filials = [];
                const branches = [];
                forEach(items, (value) => {
                    if(findInTypeBySystem(get(value, "type"), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === 'clinic'){
                        filials.push(value)
                    }else if(findInTypeBySystem(get(value, "type"), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === 'department'){
                        branches.push(value)
                    }
                })

                const result = {
                    filialOptions: [...filials],
                    branchOptions: [...branches]
                }

                dispatch(actions.setOptions({
                    type,
                    value: result
                }))
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getFundOrganization = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
            start: 0,
            max: 0,
            fg: "name::МГФОМС"
            }
        }).then((response) => {
                const items = get(response, 'data.items')
                const item = find(items, {name: "МГФОМС"})
                dispatch(actions.setFundOrganization(item));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getAuthHistory = ({userLogin, period}) => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'authHistory',
                value: true
            }));

            fetchInstance({
                method: "POST",
                url: api.authHistory,
                data: {
                    paging: {
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering: {
                        searchString: null,
                        fieldsFilter: {
                            userLogin,
                            startDate: period?.from,
                            endDate: period?.to,
                        }
                    },
                    // sorting: [
                    //     {
                    //         direction: 0,
                    //         propertyName: null
                    //     }
                    // ]
                }
            }).then((response) => {
                dispatch(actions.setAuthHistory(response?.data?.value?.items))

                dispatch(actions.setLoading({
                    type: 'authHistory',
                    value: false
                }));
            }).catch(err => {
                console.log(err)

                dispatch(actions.setLoading({
                    type: 'authHistory',
                    value: false
                }));

                dispatch(registerMessage({name: 'auth-history-error-message', type: 'red', title: 'Не удалось получить историю посещений', closable: true}))
                dispatch(showMessage('auth-history-error-message'))
                dispatch(closeMessage({name: 'auth-history-error-message', delay: systemMessageDelay}))
            });
        };
    };

    return { getAccountData, resetPassword, archiveAccount, restoreAccount, deleteAccount, sendActivationNotification, getAccountLogin, sendMessage, getPartOfOrganizations, getAnswerTimingRule, getFundOrganization, createOrUpdateFundAdminData, createOrUpdateManagerData, getAuthHistory, createOrUpdateAnalystData }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        AccountActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
