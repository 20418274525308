import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doctorActions, selectActivePeriodItem } from 'reducers/doctor';
import { useModal } from 'components/Modal/useModal';
import { Wrapper } from 'styled/Wrapper';
import Group from 'components/Group';
import ActionIcon from 'components/ActionIcon';
import BottomBarMenu from 'components/BottomBarMenu';
import dayjs from 'dayjs';
import CalendarIcon from 'components/Icons/CalendarIcon';
import PeriodSelect from '../PeriodSelect';
import DoneIcon from "components/Icons/DoneIcon";

const PeriodFilterAction = props => {

    const dispatch = useDispatch();
    const activePeriodItem = useSelector(selectActivePeriodItem);

    const mobileFIlterActinons = [
        {
            id: 0,
            label: 'За месяц',
            action: e => {
                handleDate(e, -1);
                dispatch(doctorActions.setActivePeriodItem(0));
                dispatch(doctorActions.setPeriodFilteringLabel('За месяц'));
            },
        },
        {
            id: 1,
            label: 'За 3 месяца',
            action: e => {
                handleDate(e, -3);
                dispatch(doctorActions.setActivePeriodItem(1));
                dispatch(doctorActions.setPeriodFilteringLabel('За 3 месяца'));
            },
        },
        {
            id: 2,
            label: 'За 6 месяцев',
            action: e => {
                handleDate(e, -6);
                dispatch(doctorActions.setActivePeriodItem(2));
                dispatch(doctorActions.setPeriodFilteringLabel('За 6 месяцев'));
            },
        },
        {
            id: 3,
            label: 'Другое...',
            action: (e) => showPeriodSelectMobile(),
        },
    ];

    const [showPeriodFilterMobile] = useModal({component: BottomBarMenu, name: 'period-filter-mobile', header: 'Выбор периода', mode: 'mobile-bottom-bar', props: { actions: mobileFIlterActinons, activeItem: mobileFIlterActinons[activePeriodItem], activeIconMobile:  DoneIcon}});
    const [showPeriodSelectMobile] = useModal({component: PeriodSelect, name: 'period-select-mobile', header: 'Выбор периода', mode: 'mobile-bottom-bar', props: { }});

    const handleDate = (e, months) => {
        dispatch(doctorActions.setStartDateToFilter(dayjs().add(months, "month").format("YYYY-MM-DD")));
        dispatch(doctorActions.setEndDateToFilter(dayjs().format("YYYY-MM-DD")));
        // hideAnchorPopup(e);
    }

    return (
            <Wrapper direction="row">
                <Group>
                    <Group.Item>
                        <ActionIcon
                            action={() => showPeriodFilterMobile()}
                            icon={CalendarIcon}
                            tooltip={'Период'}
                        />
                    </Group.Item>
                </Group>
            </Wrapper>
        );
};

PeriodFilterAction.propTypes = {};

export default PeriodFilterAction;
