import PropTypes from 'prop-types';
import {withButtonStyle} from "hocs/withButtonStyle";
import Button from "../index";

const PrimaryButton = (props) => {
    return withButtonStyle('primary', {...props})(Button)
};

PrimaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
};

export default PrimaryButton;
