import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {cloneDeep, get} from "lodash";
import dayjs from "dayjs";
import { organizationType, systemMessageDelay } from 'consts';
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";

const initialState = {
    parentOrganization: {},
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            partof: null,
            profile: "",
            type: organizationType.department,
            'type:missing': false
        },
        sorting: [
            {
                propertyName: "name",
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0,
            maxItems: 25
        },
    },
    timeExceeded: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "answerDate",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    endOfCounseling: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                daysToEnd: 14,
                status: "in-progress,planned",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "period",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    temp: {
        allOrganizationRoles: [],
    //     organizationsForFilter: [],
    //     organizationsForFilterCheckedAll: 1,
    },
    adminLogin: null,
    loading: {
        fullPage: true,
        data: true,
        organizationList: true
    }
}

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    setPartOf: (state, action) => {
        state.tableSettings.fieldsFilter = {
            ...state.tableSettings.fieldsFilter,
            partof: action.payload
        };
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setProfile: (state, action) => {
        state.tableSettings.fieldsFilter.profile = action.payload
    },
    setOrganizationsToFilter: (state, action) => {
        if (action.payload) {
            state.tableSettings.fieldsFilter.ids = action.payload;
        } else {
            delete state.tableSettings.fieldsFilter.ids;
        }
    },
    resetFilters: (state, action) => {
        state.tableSettings.fieldsFilter = {
            partof: null,
            profile: "",
            type: organizationType.department,
            'type:missing': false
        }
    },
    setParentOrganization: (state, action) => {
        state.parentOrganization = action.payload;
    },
    setAllOrganizationRoles: (state, action) => {
        state.temp.allOrganizationRoles = action.payload;
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setProfilesToFilter: (state, action) => {
        action.payload ? state.tableSettings.fieldsFilter.profile = action.payload : delete state.tableSettings.fieldsFilter.profile;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    setPatientsResponseTimeExceededData: (state, action) => {
        state.timeExceeded.data = action.payload
    },
    setPatientsEndOfCounselingPeriodData: (state, action) => {
        state.endOfCounseling.data = action.payload
    },
    setPatientsResponseTimeExceededPage: (state, action) => {
        state.timeExceeded.tableSettings.paging.startIndex = (action.payload - 1) * state.timeExceeded.tableSettings.paging.maxItems
    },
    setPatientsEndOfCounselingPeriodPage: (state, action) => {
        state.endOfCounseling.tableSettings.paging.startIndex = (action.payload - 1) * state.endOfCounseling.tableSettings.paging.maxItems
    },
    setPatientsResponseTimeExceededMaxItems: (state, action) => {
        state.timeExceeded.tableSettings.paging.maxItems = action.payload;
    },
    setPatientsEndOfCounselingPeriodMaxItems: (state, action) => {
        state.endOfCounseling.tableSettings.paging.maxItems = action.payload;
    },
    setSortingTimeExceeded: (state, action) => {
        state.timeExceeded.tableSettings.sorting[0].propertyName = action.payload;
        state.timeExceeded.tableSettings.sorting[0].direction = state.timeExceeded.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setTimeExceededPatientsIdToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.patientId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.patientId;
    },
    setTimeExceededPractitionerRoleIdToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId;
    },
    setTimeExceededAnswerStartDateToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerStartDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerStartDate;
    },
    setTimeExceededAnswerEndDateToFilter: (state, action) => {
        action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerEndDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerEndDate;
    },
    setSortingEndOfCounseling: (state, action) => {
        state.endOfCounseling.tableSettings.sorting[0].propertyName = action.payload;
        state.endOfCounseling.tableSettings.sorting[0].direction = state.endOfCounseling.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setEndOfCounselingPatientsIdToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.patientId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.patientId;
    },
    setEndOfCounselingPractitionerRoleIdToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId;
    },
    setEndOfCounselingPeriodStartDateToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodStart = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodStart;
    },
    setEndOfCounselingPeriodEndDateToFilter: (state, action) => {
        action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodEnd = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodEnd;
    },
    setEndOfCounselinStatusesToFilter: (state, action) => {
        state.endOfCounseling.tableSettings.fieldsFilter.status = action.payload;
    },
    setAdminLogin: (state, action) => {
        state.adminLogin = action.payload
    },
    resetState: (state, action) => {
        return initialState
    },
};

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});


export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectSearchString: state => get(state, statePath)?.tableSettings.searchString,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting,
    selectCatalogs: state => get(state, statePath)?.catalogs,
    selectAllOrganizationRoles: state => get(state, statePath)?.temp.allOrganizationRoles,
    selectRegistratorMainBranchesFilters: state => state.tableFilters.registrator_main_branches,
    selectParentOrganization: state => get(state, statePath)?.parentOrganization,
    selectLoading: state => get(state, statePath)?.loading,
    selectTimeExceededTableSettings: state => get(state, statePath)?.timeExceeded.tableSettings,
    selectEndTableSettings: state => get(state, statePath)?.endOfCounseling.tableSettings,
    selectPatientsResponseTimeExceededData: state => get(state, statePath)?.timeExceeded.data,
    selectPatientsEndOfCounselingPeriodData: state => get(state, statePath)?.endOfCounseling.data,
    selectAdminLogin: state => get(state, statePath)?.adminLogin,
})

export const getThunks = (actions, thunksFromAnotherReducer) => {

    const getParentOrganization = filialId => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "GET",
            url: `${api.organization}/${filialId}?fg=type:missing::false`,
            }).then((response) => {
                dispatch(actions.setParentOrganization(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getOrganizationRoles = id => {
        return (dispatch) => {
            fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        organization: id,
                        extended: true,
                    }
                },
                sorting: [],
            }}).then((response) => {
                    dispatch(actions.setAllOrganizationRoles(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    }

    const getOrganizationsList = ({tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationList',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: tableSettings.paging,
                filtering: {
                    searchString: tableSettings.searchString,
                    fieldsFilter: tableSettings.fieldsFilter,
                },
                sorting: tableSettings.sorting,
            }
            }).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'organizationList',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationList',
                    value: false
                }));
            });
        };
    };

    const editFilial = ({formData, callback}) => {
        // console.log("formData", formData);
        if (formData.organizationImage) {
            let imageData = new FormData();
            imageData.append("files", formData.organizationImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.organizationImage;
                    delete formDataCopy.organizationExtension;
                    formDataCopy.organizationExtension = [
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                            valueUrl: response.data[0].url
                        },
                        {
                            url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                            valueUrl: response.data[0].url
                        }
                    ]
                    dispatch(editOrganization({formData: formDataCopy, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else if (formData.organizationImage === null) {
            // console.log("Нужно сбросить");
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.organizationExtension = [];
                dispatch(editOrganization({formData: formDataCopy, callback}));
            };
        } else {
            // console.log("Обновлять не нужно");
            return (dispatch) => {
                dispatch(editOrganization({formData, callback}));
            };
        }
    };

    const editOrganization = ({formData, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "PUT",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                {
                                    code: 'clinic',
                                    system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                    display: "Филиал"
                                }
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'edit-filial-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                    dispatch(showMessage('edit-filial-message'))
                    dispatch(closeMessage({name: 'edit-filial-message', delay: systemMessageDelay}))
                    // dispatch(createOrUpdateManagerData({formData, callback}));

                    formData?.organizationId && dispatch(getParentOrganization(formData.organizationId));
                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    //Задействовать, если появится необходимость создавать администратора дочернего отделения
    const createOrUpdateManagerData = ({formData, tableSettings, isBranchCreating, callback}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData?.practitionerId,
                        fio: formData?.practitionerName,
                        gender: formData?.practitionerGender,
                        isActive: true,
                        photoUrl: formData?.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.practitionerRole,
                        positionDisplay: formData?.practitionerRoleName,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'registrator'
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                if(!isBranchCreating){
                    formData?.organizationId && dispatch(getParentOrganization(formData.organizationId));
                }else{
                    tableSettings && dispatch(getOrganizationsList({tableSettings}));
                }
                
                callback && callback();

            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                console.log(err)
            });
        }
    }

    const deleteOrganization = ({id, tableSettings, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.organization}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'delete-filial-message', type: 'primary', title: 'Филиал удален', closable: true}))
                dispatch(showMessage('delete-filial-message'))
                dispatch(closeMessage({name: 'delete-filial-message', delay: systemMessageDelay}))
                thunksFromAnotherReducer && tableSettings && dispatch(thunksFromAnotherReducer?.getOrganizationsList({tableSettings}));
                callback && callback();
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'delete-filial-error-message', type: 'red', title: 'Не удалось удалить филиал', text: err?.response?.data, closable: true}));
                dispatch(showMessage('delete-filial-error-message'));
                dispatch(closeMessage({name: 'delete-filial-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const createBranch = ({formData, tableSettings, callback}) => {
        // console.log("formData", formData);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                formData.organizationRank
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'add-new-child-message', type: 'primary', title: 'Отделение создано', text: formData.organizationName, closable: true}))
                    dispatch(showMessage('add-new-child-message'))
                    dispatch(closeMessage({name: 'add-new-child-message', delay: systemMessageDelay}))

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));

                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const editBranch = ({formData, tableSettings, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "PUT",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: [
                        {
                            use: "usual",
                            value: formData.organizationCode,
                            system: "urn:mgfoms:szl:entity:organization:id"
                        }
                    ],
                    type: [
                        {
                            coding: [
                                formData.organizationRank
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    address: [
                        {
                            text: formData.organizationAddress,
                        }
                    ],
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ],
                    extension: formData.organizationExtension
                }}).then((response) => {
                    dispatch(registerMessage({name: 'edit-child-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                    dispatch(showMessage('edit-child-message'))
                    dispatch(closeMessage({name: 'edit-child-message', delay: systemMessageDelay}))

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));

                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getPatientsResponseTimeExceeded = ({organizationId, tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'timeExceededData',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsResponseTimeExceeded`,
                data: {
                    paging: tableSettings.paging,
                    filtering: {
                        searchString: tableSettings.searchString,
                        fieldsFilter: tableSettings.fieldsFilter,
                    },
                    sorting: tableSettings.sorting,
                }
            }).then((response) => {
                dispatch(actions.setPatientsResponseTimeExceededData(response.data));
                dispatch(actions.setLoading({
                    type: 'timeExceededData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'timeExceededData',
                    value: false
                }));
            });
        };
    };

    const getPatientsEndOfCounselingPeriod = ({organizationId, tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'endOfCounselingData',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsEndOfCounselingPeriod`,
                data: {
                    paging: tableSettings.paging,
                    filtering: {
                        searchString: tableSettings.searchString,
                        fieldsFilter: tableSettings.fieldsFilter,
                    },
                    sorting: tableSettings.sorting,
                }
            }).then((response) => {
                dispatch(actions.setPatientsEndOfCounselingPeriodData(response.data));
                dispatch(actions.setLoading({
                    type: 'endOfCounselingData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'endOfCounselingData',
                    value: false
                }));
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    const getAdminLogin = ({roleId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
            }).then((response) => {
                const login = get(response, 'data.value');
                login && dispatch(actions.setAdminLogin(login));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getParentOrganization, getOrganizationRoles, getOrganizationsList, editFilial, editOrganization, deleteOrganization, createBranch, editBranch, sendActivationNotification, getPatientsResponseTimeExceeded, getPatientsEndOfCounselingPeriod, resetPassword, getAdminLogin }

}

export const build = (name, storePath, thunksFromAnotherReducer) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions, thunksFromAnotherReducer);

    return {
        filialActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
