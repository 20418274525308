import TableText from "../components/Table/TableText";
import Label from "../components/Label";
import React from "react";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import DoubleCheckIcon from "components/Icons/DoubleCheckIcon";
import CheckIcon from "../components/Icons/CheckIcon";
import Message from "modals/PatientChat/Message";

export const adaptItemForTable = (item, type) => {
    return {
        date: getPeriodElement(item, 'DD MMM YYYY'),
        status: !!item && getStatusLabelByTask(item),
        event: <TableText text={item?.description} type={'primary'} />,
        mandatory: item?.isNecessary && <TableText text={'обяз.'} type={'secondary'} />,
        item
    }
}

const checkIfNeededToShowYear = ({
    dates
}) => {
    const currentYear = dayjs().get('year');
    let needShowYear;

    dates.forEach(item => {
        if (!needShowYear) {
            const executionYear = !!item ? dayjs(item).get('year') : undefined;
            if (executionYear !== currentYear) {
                needShowYear = true;
            }
        }
    });

    return needShowYear;
}

export const getPeriodElement = (task, template) => {
    const type = task?.isOverdue ? 'alert' : 'secondary';

    const currentTemplateStart = !checkIfNeededToShowYear({
        dates: [task?.executionPeriod?.start]
    }) ? template.replace('YYYY', '') : template;

    const currentTemplateEnd = !checkIfNeededToShowYear({
        dates: [task?.executionPeriod?.end]
    }) ? template.replace('YYYY', '') : template;

    if(task && !!task?.executionPeriod?.end){
        return task?.executionPeriod?.end &&
            <TableText
                text={dayjs(task?.executionPeriod?.end).locale(ru).format(currentTemplateEnd)}
                type={type}
            />
    }

    if(task && !!task?.executionPeriod?.start){
        return task?.executionPeriod?.start &&
            <TableText
                text={dayjs(task?.executionPeriod?.start).locale(ru).format(currentTemplateStart)}
                type={type}
            />
    }

    return <TableText text={createPeriodString(task, template)} type={type} />;
}

export const createPeriodString = (task, template) => {

    const currentTemplate = !checkIfNeededToShowYear({
        dates: [task?.period?.start, task?.period?.end]
    }) ? template.replace('YYYY', '') : template;

    let periodString = '';
    periodString += !!task?.period?.start ? dayjs(task?.period?.start).locale(ru).format(currentTemplate) : '';
    periodString += (!!task?.period?.start && !!task?.period?.end) ? ' — ' : ''
    periodString += !!task?.period?.end ? dayjs(task?.period?.end).locale(ru).format(currentTemplate) : ''
    return periodString;
}

export const getStatusLabelByTask = (task) => {

    if(task?.status.toLowerCase() === 'overdue')
        return <Label
            text={'Просрочено'}
            type={'red'}
            tooltip={'Просрочено'}
        />

    if(task?.status.toLowerCase() === 'planned')
        return <Label
            text={'Запланировано'}
            type={'orange'}
            tooltip={'Запланировано'}
        />

    if(task?.status.toLowerCase() === 'cancelled')
        return <Label
            text={'Отменено'}
            type={'red'}
            tooltip={'Отменено'}
        />

    if(task?.status.toLowerCase() === 'executed' && !!task?.executionPeriod?.end && !!task?.executionPeriod?.start)
        return <Label
            text={'Выполнено'}
            type={'green'}
            icon={DoubleCheckIcon}
            tooltip={'Подтверждено пациентом и врачом'}
        />

    if(task?.status.toLowerCase() === 'executed' && !!task?.executionPeriod?.end)
        return <Label
            text={'Выполнено'}
            type={'green'}
            icon={DoubleCheckIcon}
            tooltip={'Подтверждено врачом'}
        />

    if(task?.status.toLowerCase() === 'executed' && !!task?.executionPeriod?.start)
        return <Label
            text={'Выполнено'}
            type={'primary'}
            icon={CheckIcon}
            tooltip={'Подтверждено пациентом'}
        />

    if(task?.status.toLowerCase() === 'executed')
        return <Label
            text={'Выполнено'}
            type={'primary'}
            tooltip={'Выполнено'}
        />
        
}


export const adaptMessages = (communication, communicationIndex, reducer, encounterId) => {
    const messagesElements = communication?.messages && communication?.messages?.map((message, index, arr) => {
        return ({highlightData, onMeasureChanged}) => {
            return (
                <Message
                    sender={message?.sender?.type}
                    onMeasureChanged={onMeasureChanged}
                    showSenderData={(index === 0 || arr[index - 1].senderId !== message.senderId)}
                    reducer={reducer}
                    encounterId={encounterId}
                    data={{
                        id: message?.id,
                        name: message?.sender?.name,
                        message: message.text,
                        time: dayjs(message?.sent).locale(ru).format('HH:mm'),
                        status: getMessageStatusByCode(message?.status),
                        highlight: communicationIndex === highlightData?.communicationIndex && highlightData?.messageIndex === index,
                        files: message.files && message.files.length ? message.files : null,
                        images: message.images && message.images.length ? message.images : null,
                        category: message.category,
                        expired: message?.overdue,
                        replyMessageId: message?.replyMessageId,
                        replyAuthor: message?.replyAuthor,
                        replyAuthorName:  message?.replyAuthorName,
                        replyMessage: message?.replyMessage,
                        replyMessageContentAttachment: message?.replyMessageContentAttachment,
                    }}
                />
            )
        }
    })

    return messagesElements;
}

const getMessageStatusByCode = (code) => {
    switch (code){
        case 'on-hold':
        case 'completed': return 'received'
        case 'in-progress': return 'sent'
        case 'preparation':
        case 'not-done': return 'error'
        default: return ''
    }
}