import styled, {css, useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    // getActivePatientData,
    selectActivePatient,
    selectActivePatientData,
    selectLoading
} from "reducers/doctor";
import {get, isEmpty} from "lodash";
import {getChannelVitalInformation} from "reducers/doctor";
import { Row } from "styled/Row";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import Spinner from "components/Spinner";

export const Info = () => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState({});

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, "id");
        // dispatch(getActivePatientData(patientId));
        dispatch(getChannelVitalInformation(id));//тестовый UKL: 16416128
    }, [activePatientData.UKL]);

    useEffect(() => {
        if (activePatientData.channelVitalInformation) {
            setData(activePatientData.channelVitalInformation);
        }
    }, [activePatientData.channelVitalInformation]);

    return (
        <>
            {!loading?.SIZLData ? !isEmpty(data) &&
                <Wrapper paddings={'0 16px'}>
                    <CustomView condition={isDesktop || isTablet}>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Артериальное давление, к которому адаптирован'} color={theme.colors.black54} />
                            <Pressure>
                                Систолическое (верхнее):&nbsp;
                                {data.normalSystolicPressure ? data.normalSystolicPressure : '-'}
                                <span> мм.рт.ст</span>
                            </Pressure>
                            <Pressure>
                                Диастолическое (нижнее):&nbsp;
                                {data.normalDiastolicPressure ? data.normalDiastolicPressure : '-'}
                                <span> мм.рт.ст</span>
                            </Pressure>
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Состоит на диспансерном учете'} color={theme.colors.black54} />
                            <Text text={data.dispensaryRegistration ? data.dispensaryRegistration : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        {/* <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Медицинская организация'} color={theme.colors.black54} />
                            <Text text={activePatientData.organization} font={'robotoRegular'} />
                        </Wrapper> */}
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Телефон(ы) контактного лица/лиц для связи в случае нарушения сознания'} color={theme.colors.black54} />
                            <Text text={data.emergencyContactPhones ? data.emergencyContactPhones : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Группа крови'} color={theme.colors.black54} />
                            <Text text={data.bloodType ? data.bloodType : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Резус фактор'} color={theme.colors.black54} />
                            <Text text={data.rhFactor ? data.rhFactor : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Операции, травмы и гемотрансфузии'} color={theme.colors.black54} />
                            <Text text={data.surgeryAndTrauma ? data.surgeryAndTrauma : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Импланты'} color={theme.colors.black54} />
                            <Text text={data.implants ? data.implants : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Постоянно принимаемые лекарственные препараты'} color={theme.colors.black54} />
                            <Text text={data.regularDrugs ? data.regularDrugs : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Наличие хронических и перенесенных заболеваний'} color={theme.colors.black54} />
                            {data.diseases ? data.diseases.map(item =>
                                <Row>
                                    <Code margins='0 7px 0 0'>{item.code}</Code>
                                    <Text text={item.name} font={'robotoRegular'}/>
                                </Row>
                                )
                                :
                                <Text text={'-'} font={'robotoRegular'}/>
                            }
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Аллергологический анамнез'} color={theme.colors.black54} />
                            {data.allergens ? data.allergens.map(item =>
                                <Row>
                                    <Code margins='0 7px 0 0'>{item.code}</Code>
                                    <Text text={item.name} font={'robotoRegular'}/>
                                </Row>
                                )
                                :
                                <Text text={'-'} font={'robotoRegular'}/>
                            }
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Иное'} color={theme.colors.black54} />
                            <Text text={data.other ? data.other : '-'} font={'robotoRegular'} />
                        </Wrapper>
                    </CustomView>
                    <MobileOnlyView>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Артериальное давление, к которому адаптирован'} color={theme.colors.black54} />
                            <Pressure>
                                Систолическое (верхнее):&nbsp;
                                {data.normalSystolicPressure ? data.normalSystolicPressure : '-'}
                                <span> мм.рт.ст</span>
                            </Pressure>
                            <Pressure>
                                Диастолическое (нижнее):&nbsp;
                                {data.normalDiastolicPressure ? data.normalDiastolicPressure : '-'}
                                <span> мм.рт.ст</span>
                            </Pressure>
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Состоит на диспансерном учете'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.dispensaryRegistration ? data.dispensaryRegistration : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        {/* <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Медицинская организация'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={activePatientData.organization} font={'robotoRegular'} />
                        </Wrapper> */}
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Телефон(ы) контактного лица/лиц для связи в случае нарушения сознания'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.emergencyContactPhones ? data.emergencyContactPhones : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Группа крови'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.bloodType ? data.bloodType : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Резус фактор'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.rhFactor ? data.rhFactor : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Операции, травмы и гемотрансфузии'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.surgeryAndTrauma ? data.surgeryAndTrauma : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Импланты'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.implants ? data.implants : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Постоянно принимаемые лекарственные препараты'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.regularDrugs ? data.regularDrugs : '-'} font={'robotoRegular'} />
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Наличие хронических и перенесенных заболеваний'} color={theme.colors.black54} />
                            {data.diseases ? data.diseases.map(item =>
                                    <Row>
                                        <Code margins='0 7px 0 0'>{item.code}</Code>
                                        <Text text={item.name} font={'robotoRegular'}/>
                                    </Row>
                                )
                                :
                                <Text tag={'h5'} text={'-'} font={'robotoRegular'}/>
                            }
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Аллергологический анамнез'} color={theme.colors.black54} />
                            {data.allergens ? data.allergens.map(item =>
                                    <Row>
                                        <Code margins='0 7px 0 0'>{item.code}</Code>
                                        <Text text={item.name} font={'robotoRegular'}/>
                                    </Row>
                                )
                                :
                                <Text tag={'h5'} text={'-'} font={'robotoRegular'}/>
                            }
                        </Wrapper>
                        <Wrapper paddings={'7px 0 0 0'}>
                            <Text tag={'small'} text={'Иное'} color={theme.colors.black54} />
                            <Text tag={'h5'} text={data.other ? data.other : '-'} font={'robotoRegular'} />
                        </Wrapper>
                    </MobileOnlyView>
                </Wrapper>
                :
                <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                    <Spinner/>
                </Wrapper>
            }
        </>
    )
}

const Pressure = styled.div`
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.text})};

    span {
        ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54, size: theme.fonts.sizes.text})};
    }
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.h5})};
    
        span {
            ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.h5})};
        }
    `}
`

const Code = styled.span`
      ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.primary})};
      ${({margins}) => margins ? `margin: ${margins}` : ''};
      
      ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.primary, size: theme.fonts.sizes.h5})};
      `}
`
