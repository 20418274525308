import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {isEmpty, cloneDeep, get} from "lodash";
import {findOrganizationsByElementId} from "utils/statistics";
import { organizationType, systemMessageDelay } from 'consts';
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";

const initialState = {
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            partof: null,
            profile: "",
            type: organizationType.department
        },
        sorting: [
            {
                propertyName: "name", 
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0, 
            maxItems: 25
        },
    },
    temp: {
        allOrganizationRoles: [],
        organizationsForFilter: [],
        organizationsForFilterCheckedAll: 1,
    },
    organizationData: [],
    loading: {
        fullPage: true,
        data: true,
        organizationData: true
    },
    saveThisPageFilter: false,
};

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    setPartOf: (state, action) => {
        state.tableSettings.fieldsFilter = {
            ...state.tableSettings.fieldsFilter,
            partof: action.payload
        };
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
    },
    setAllOrganizationRoles: (state, action) => {
        state.temp.allOrganizationRoles = action.payload;
    }, 
    setOrganizationsForFilter: (state, action) => {
        state.temp.organizationsForFilter = action.payload;
    },  
    checkOrganization: (state, action) => {
        const id = action.payload.id;
        const checked = action.payload.checked;
        const targetElement = findOrganizationsByElementId(state.temp.organizationsForFilter, id);
        const checkElementsInside = (el, checked) => {
            el.checked = checked;
            if (el.childElements) el.childElements.map(item => checkElementsInside(item, checked));
        }
        checkElementsInside(targetElement, checked);
        const checkElementsOutside = (el, checked) => {
            let parent;
            if (el.organization.partOf) {
                parent = findOrganizationsByElementId(state.temp.organizationsForFilter, el.organization.partOf.id);
                if (parent) {
                    if (checked) {
                        // console.log("1");
                        if (parent.childElements.find(item => (item.checked === 0 || item.checked === 2))) {
                            // console.log("2");
                            parent.checked = 2;
                        } else {
                            // console.log("3");
                            parent.checked = 1;
                        }
                    } else {
                        // console.log("4");
                        if (parent.childElements.find(item => (item.checked === 1 || item.checked === 2))) {
                            // console.log("5");
                            parent.checked = 2;
                        } else {
                            // console.log("6");
                            parent.checked = 0;
                        }
                    }
                    if (parent.organization.partOf) checkElementsOutside(parent, checked);
                }
            }
        }
        checkElementsOutside(targetElement, checked);

        // Устанавливаем значение Выбрать все
        if (state.temp.organizationsForFilter.find(item => (item.checked === 0) || (item.checked === 2))){
            if (!state.temp.organizationsForFilter.find(item => (item.checked === 1) || (item.checked === 2))) {
                state.temp.organizationsForFilterCheckedAll = 0;
            } else {
                state.temp.organizationsForFilterCheckedAll = 2;
            }
        } else {
            state.temp.organizationsForFilterCheckedAll = 1;
        }
    },
    setOrganizationsToFilter: (state, action) => {
        if (action.payload) {
            state.tableSettings.fieldsFilter.ids = action.payload;
        } else {
            delete state.tableSettings.fieldsFilter.ids;
        }
    },
    checkOrganizationsAll: (state, action) => {
        const changeChecked = (arr, value) => {
            arr.forEach(item => {
                item.checked = value;
                if (item.childElements) changeChecked(item.childElements, value);
            })
        }
        changeChecked(state.temp.organizationsForFilter, action.payload);
        state.temp.organizationsForFilterCheckedAll = action.payload;
    },
    setProfile: (state, action) => {
        state.tableSettings.fieldsFilter.profile = action.payload
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    resetFilters: (state, action) => {
        state.tableSettings.fieldsFilter = {
            partof: null,
            profile: "",
            type: organizationType.department
        }
    },
    setOrganizationData: (state, action) => {
        state.organizationData = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) state.tableSettings = initialState.tableSettings;
    }, 
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    setProfilesToFilter: (state, action) => {
        action.payload ? state.tableSettings.fieldsFilter.profile = action.payload : delete state.tableSettings.fieldsFilter.profile;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectOrganizationData: state => get(state, statePath)?.organizationData,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectFieldsFilter: state => get(state, statePath)?.fieldsFilter,
    selectSearchString: state => get(state, statePath)?.searchString,
    selectSorting: state => get(state, statePath)?.sorting,
    selectAllOrganizationRoles: state => get(state, statePath)?.temp.allOrganizationRoles,
    selectOrganizationsForFilter: state => get(state, statePath)?.temp.organizationsForFilter,
    selectOrganizationsForFilterCheckedAll: state => get(state, statePath)?.temp.organizationsForFilterCheckedAll,
    selectLoading: state => get(state, statePath)?.loading,
})

export const getThunks = (actions) => {

    const getOrganizationsList = ({tableSettings}) => {
        // var profileCodes = profileList.filter((item) => {
        //     return item.checked === 1;
        // }).map((item) => {
        //     return item.code;
        // })
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: tableSettings.paging,   
                filtering: {
                    searchString: tableSettings.searchString,   
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting,  
            }
            }).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getGenders = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.gender,
        }).then((response) => {
                dispatch(actions.setGenderCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPractitionRoles = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.practitionRoles,
        }).then((response) => {
                dispatch(actions.setRolesCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizations = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
        }).then((response) => {
                dispatch(actions.setOrganizationsCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationProfileList = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organizationProfiles,
            }).then((response) => {
                dispatch(actions.setOrganizationProfilesCatalog(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const createBranch = ({formData, tableSettings, callback}) => {
        // console.log("formData", formData);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    type: [
                        {
                            coding: [
                                {
                                    code: "department",
                                    system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                    display: "Подразделение"
                                }
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ]
                }}).then((response) => {
                    dispatch(registerMessage({name: 'add-new-branch-message', type: 'primary', title: 'Отделение создано', text: formData.organizationName, closable: true, url: '/branches/branch-' + formData.organizationId}))
                    dispatch(showMessage('add-new-branch-message'))
                    dispatch(closeMessage({name: 'add-new-branch-message', delay: systemMessageDelay}))
                    // dispatch(createOrUpdateManagerData({formData, tableSettings, callback}));

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));
                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const editBranch = ({formData, tableSettings, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "PUT",
                url: `${api.organization}`,
                data: {
                    id: formData.organizationId,
                    resourceType: "Organization",
                    active: true,
                    partOf: {
                        reference: `Organization/${formData.organizationPartOf}`,
                    },
                    identifier: formData.identifier,
                    type: [
                        {
                            coding: [
                                {
                                    code: "department",
                                    system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                    display: "Подразделение"
                                }
                            ]
                        },
                        {
                            coding: [
                                {
                                    code: formData.organizationProfile.code,
                                    display: formData.organizationProfile.display,
                                    system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                                }
                            ]
                        }
                    ],
                    name: formData.organizationName,
                    contact: [
                        {
                            purpose: {
                                text: "Телефон для пациентов"
                            },
                            telecom: [
                                {
                                    system: "phone",
                                    value: formData.organizationPhone,
                                    use: "work"
                                }
                            ]
                        }
                    ]
                }}).then((response) => {
                    dispatch(registerMessage({name: 'edit-branch-message', type: 'primary', title: 'Данные обновлены', closable: true, url: '/branches/branch-' + formData.organizationId}))
                    dispatch(showMessage('edit-branch-message'))
                    dispatch(closeMessage({name: 'edit-branch-message', delay: systemMessageDelay}))
                    // dispatch(createOrUpdateManagerData({formData, tableSettings, callback}));

                    tableSettings && dispatch(getOrganizationsList({tableSettings}));
                    callback && callback();
                }).catch(err => {
                    console.log(err)
                });
        };
    };

    const createOrUpdateManagerData = ({formData, tableSettings, callback}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData?.practitionerId,
                        fio: formData?.practitionerName,
                        gender: formData?.practitionerGender,
                        isActive: true,
                        photoUrl: formData?.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.practitionerRole,
                        positionDisplay: formData?.practitionerRoleName,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'registrator'
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                tableSettings && dispatch(getOrganizationsList({tableSettings}));
                callback && callback();

            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                console.log(err)
            });
        }
    }

    const deleteOrganization = ({id, tableSettings}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.organization}/${id}`,
            }).then((response) => {
                dispatch(registerMessage({name: 'delete-branch-message', type: 'primary', title: 'Отделение удалено', closable: true, url: '/branches'}))
                dispatch(showMessage('delete-branch-message'))
                dispatch(closeMessage({name: 'delete-branch-message', delay: systemMessageDelay}))
                dispatch(getOrganizationsList({tableSettings}));
            }).catch(err => {
                console.log(err);
                dispatch(registerMessage({name: 'delete-branch-message', type: 'red', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Ошибка запроса', closable: true}))
                dispatch(showMessage('delete-branch-message'))
                dispatch(closeMessage({name: 'delete-branch-message', delay: systemMessageDelay}))
            });
        };
    };

    const getOrganizationRoles = (id) => {
        return (dispatch) => {
            fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        organization: id,
                        extended: true,
                    }
                },
                sorting: [],
            }}).then((response) => {
                    dispatch(actions.setAllOrganizationRoles(response.data));
            }).catch(err => {
                console.log(err)
            });
        };   
    }

    const getOrganizationById = id => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organizationData',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex:0,
                    maxItems:10
            },
            filtering:{
                searchString: null,
                fieldsFilter: {
                    id : id
                }
            },
            sorting: [
                    {
                        direction: 0,
                        propertyName: "name"
                    }
                ]
            } 
            }).then((response) => {
                dispatch(actions.setOrganizationData(response.data));
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'organizationData',
                    value: false
                }));
            });
        };
    };

    const getOrganizationsForFilter = partOf => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        partof: partOf,
                        'type:missing': false
                    }
                },
            }
            }).then((response) => {
                const initialItems = response.data.items;
                let items = [];
                initialItems.forEach(item => {
                    items.push({organization: item});
                });

                const setAllElementsChecked = (dataObj, level) => {
                    const objLevel = level ? level : 1;
                    dataObj.forEach(item => {
                        item.checked = 1;
                        item.level = objLevel;
                        if (!isEmpty(item.childElements)) setAllElementsChecked(item.childElements, objLevel + 1);
                    });
                }
                setAllElementsChecked(items);
                dispatch(actions.setOrganizationsForFilter(items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getOrganizationsList, getGenders, getPractitionRoles, getOrganizations, getOrganizationProfileList, createBranch, editBranch, deleteOrganization, getOrganizationRoles, getOrganizationById, getOrganizationsForFilter, createOrUpdateManagerData }

}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        branchesActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };