import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import modalCarouselReducer from "reducers/modalCarousel/modalCarouselSlice";
import modalReducer from "reducers/modal/modalSlice";
import tooltipReducer from "reducers/tooltip/tooltipSlice";
import appReducer from "reducers/app";
import tableReducer from "reducers/table/tableSlice";
import anchorPopupReducer from "reducers/anchorPopup/anchorPopupSlice";
import systemMessagesReducer from "reducers/systemMessages/systemMessagesSlice";
import managersReducer from "reducers/fund_admin/managers";
import managerReducer from "reducers/fund_admin/manager";
import organizationsReducer from "reducers/organizations";
import tableFiltersReducer from "reducers/tableFilters";
import userReducer from "reducers/user";
import tabsReducer from "reducers/tabs/tabsSlice";
import chatReducer from "reducers/chat/chatSlice";
import statisticsOrganizationAdminReducer from "reducers/organization_admin/statistics";
import mainOrganizationAdminReducer from "reducers/organization_admin/main";
import catalogsReducer from "reducers/catalogs";
import mainRegistratorReducer from "reducers/registrator/main";
import doctorsReducer from "reducers/doctors";
import statisticsRegistratorReducer from "reducers/registrator/statistics";
import doctorManagerReducer from "reducers/doctorManager.js";
import channelsRegistratorReducer from "reducers/registrator/channels";
import channelRegistratorReducer from "reducers/registrator/channel";
import branchRegistratorReducer from "reducers/registrator/branch";
import doctorReducer from "reducers/doctor";
import doctorRightSideReducer from "reducers/doctor/rightSide";
import protocolsReducer from "reducers/super_admin/protocols";
import protocolReducer from "reducers/super_admin/protocol";
import {axiosMiddlewareResponse} from "reducers/app";
import accountsReducer from "reducers/accounts";
import settingsReducer from "reducers/super_admin/settings"
import eventsReducer from "reducers/super_admin/events"
import accountChannelsReducer from 'reducers/account_channels';
import accountReducer from 'reducers/account';
import globalSearchReducer from 'reducers/globalSearch/search';
import globalSearchChannelsReducer from 'reducers/globalSearch/channels';
import globalSearchOrganizationsReducer from 'reducers/globalSearch/organizations';
import globalSearchEmployeesReducer from 'reducers/globalSearch/employees';
import channelRootReducer from 'reducers/channel';
import mailingReducer from "reducers/mailing";
import channelsReducer from "reducers/channels";
import statisticsReducer from "reducers/statistics";
import filialsReducer from "reducers/filials"
import filialReducer from "reducers/filial";
import filialBranchReducer from 'reducers/filialBranch';
import branchesReducer from 'reducers/branches';
import branchReducer from 'reducers/branch';
import mailingListReducer from 'reducers/mailingList';
import mailReducer from 'reducers/mail';
import administratorsReducer from 'reducers/administrators';
import eventLogsReducer from 'reducers/eventLogs';

export default configureStore({
  reducer: {
    fundAdmin: combineReducers({
      statistics: statisticsReducer.build('fundAdminStatistics', 'fundAdmin.statistics')?.reducer,
      managers: managersReducer,
      manager: managerReducer,
      organizations: organizationsReducer.build('organizations', 'fundAdmin.organizations').reducer,
      filial: filialReducer.build('fundAdminFilial', 'fundAdmin.filial')?.reducer,
      filialChannel: channelRootReducer.build('fundAdminFilialChannel', 'fundAdmin.filialChannel')?.reducer,
      filialBranch: branchReducer.build('fundAdminFilialBranch', 'fundAdmin.filialBranch')?.reducer,
      filialBranchChannel: channelRootReducer.build('fundAdminFilialBranchChannel', 'fundAdmin.filialBranchChannel')?.reducer,
      branch: branchReducer.build('fundAdminBranch', 'fundAdmin.branch')?.reducer,
      branchChannel: channelRootReducer.build('fundAdminBranchChannel', 'fundAdmin.branchChannel')?.reducer,
      mailingArchive: mailingReducer.build('mailingArchive', 'fundAdmin.mailingArchive').reducer,
      channels: channelsReducer.build('fundAdminChannels', 'fundAdmin.channels')?.reducer,
      channel: channelRootReducer.build('fundAdminChannel', 'fundAdmin.channel')?.reducer,
    }),
    organizationAdmin: combineReducers({
      statistics: statisticsOrganizationAdminReducer,
      filials: filialsReducer.build('organizationAdminFilials', 'organizationAdmin.filials')?.reducer,
      filial: filialReducer.build('organizationAdminFilial', 'organizationAdmin.filial')?.reducer,
      filialBranch: filialBranchReducer.build('organizationAdminFilialBranch', 'organizationAdmin.filialBranch')?.reducer,
      filialBranchChannel: channelRootReducer.build('organizationAdminFilialBranchChannel', 'organizationAdmin.filialBranchChannel')?.reducer,
      filialChannel: channelRootReducer.build('organizationAdminFilialChannel', 'organizationAdmin.filialChannel')?.reducer,
      branches: branchesReducer.build('organizationAdminBranches', 'organizationAdmin.branches')?.reducer,
      branch: branchReducer.build('organizationAdminBranch', 'organizationAdmin.branch')?.reducer,
      branchChannel: channelRootReducer.build('organizationAdminBranchChannel', 'organizationAdmin.branchChannel')?.reducer,
      main: mainOrganizationAdminReducer,
      statisticChannel: channelRootReducer.build('organizationAdminStatisticChannel', 'organizationAdmin.statisticChannel')?.reducer,
      statisticChannels: channelsReducer.build('organizationAdminStatisticChannels', 'organizationAdmin.statisticChannels')?.reducer,
      administrators: administratorsReducer.build('organizationAdminAdministrators', 'organizationAdmin.administrators')?.reducer
    }),
    registrator: combineReducers({
      main: mainRegistratorReducer,
      mainChannel: channelRootReducer.build('registratorMainChannel', 'registrator.mainChannel')?.reducer,
      statistics: statisticsRegistratorReducer,
      doctors: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors')?.reducer,
      doctor: doctorManagerReducer.build('doctorRegistrator', 'registrator.doctor')?.reducer,
      channels: channelsRegistratorReducer,
      channel: channelRegistratorReducer,
      branch: branchRegistratorReducer,
      branchChannel: channelRootReducer.build('registratorBranchChannel', 'registrator.branchChannel')?.reducer,
      statisticChannel: channelRootReducer.build('registratorStatisticChannel', 'registrator.statisticChannel')?.reducer,
      statisticChannels: channelsReducer.build('registratorStatisticChannels', 'registrator.statisticChannels')?.reducer,
    }),
    doctor: combineReducers({
      doctorChat: doctorReducer,
      doctorRightSide: doctorRightSideReducer
    }),
    superAdmin: combineReducers({
      protocols: protocolsReducer,
      protocol: protocolReducer,
      events: eventsReducer,
      accounts: accountsReducer.build('accounts', 'superAdmin.accounts')?.reducer,
      mailingList: mailingListReducer.build('superAdminMailingList', 'superAdmin.mailingList')?.reducer,
      mail: mailReducer.build('superAdminMail', 'superAdmin.mail')?.reducer,
      organizations: organizationsReducer.build('organizations', 'superAdmin.organizations').reducer,
      filial: filialReducer.build('superAdminFilial', 'superAdmin.filial')?.reducer,
      filialChannel: channelRootReducer.build('superAdminFilialChannel', 'superAdmin.filialChannel')?.reducer,
      filialBranch: branchReducer.build('superAdminFilialBranch', 'superAdmin.filialBranch')?.reducer,
      filialBranchChannel: channelRootReducer.build('superAdminFilialBranchChannel', 'superAdmin.filialBranchChannel')?.reducer,
      branch: branchReducer.build('superAdminBranch', 'superAdmin.branch')?.reducer,
      branchChannel: channelRootReducer.build('superAdminBranchChannel', 'superAdmin.branchChannel')?.reducer,
      statistics: statisticsReducer.build('superAdminStatistics', 'superAdmin.statistics')?.reducer,
      statisticChannel: channelRootReducer.build('superAdminChannel', 'superAdmin.statisticChannel')?.reducer,
      statisticChannels: channelsReducer.build('superAdminChannels', 'superAdmin.statisticChannels')?.reducer,
      settings: settingsReducer,
      accountChannels: accountChannelsReducer.build('superAdminAccountChannels', 'superAdmin.accountChannels')?.reducer,
      accountChannel: channelRootReducer.build('superAdminAccountChannel', 'superAdmin.accountChannel')?.reducer,
      account: accountReducer.build('account', 'superAdmin.account')?.reducer,
      eventLogs: eventLogsReducer.build('eventLogs', 'superAdmin.eventLogs')?.reducer,
    }),
    analyst: combineReducers({
      statistics: statisticsReducer.build('analystStatistics', 'analyst.statistics')?.reducer,
      channels: channelsReducer.build('analystChannels', 'analyst.channels')?.reducer,
      channel: channelRootReducer.build('analystChannel', 'analyst.channel')?.reducer
    }),
    catalogs: catalogsReducer,
    // filters: filtersReducer,
    tableFilters: tableFiltersReducer,
    modal: modalReducer,
    modalCarousel: modalCarouselReducer,
    tabs: tabsReducer,
    chat: chatReducer,
    tooltip: tooltipReducer,
    anchorPopup: anchorPopupReducer,
    systemMessages: systemMessagesReducer,
    user: userReducer,
    app: appReducer,
    table: tableReducer,
    globalSearch: combineReducers({
      search: globalSearchReducer,
      channels: globalSearchChannelsReducer,
      organizations: globalSearchOrganizationsReducer,
      employees: globalSearchEmployeesReducer
    }),
    globalChannel: channelRootReducer.build('globalChannel', 'globalChannel')?.reducer,
    globalOrganizations: organizationsReducer.build('globalOrganizations', 'globalOrganizations')?.reducer,
    globalFilial: filialReducer.build('globalFilial', 'globalFilial')?.reducer,
    globalFilialChannel: channelRootReducer.build('globalFilialChannel', 'globalFilialChannel')?.reducer,
    globalFilialBranch: filialBranchReducer.build('globalFilialBranch', 'globalFilialBranch')?.reducer,
    globalBranch: branchReducer.build('globalBranch', 'globalBranch')?.reducer,
    globalBranchChannel: channelRootReducer.build('globalBranchChannel', 'globalBranchChannel')?.reducer,
    globalFilialBranchChannel: channelRootReducer.build('globalFilialBranchChannel', 'globalFilialBranchChannel')?.reducer,
    globalDoctor: doctorManagerReducer.build('globalDoctor', 'globalDoctor')?.reducer,
    globalDoctorChannel: channelRootReducer.build('globalDoctorChannel', 'globalDoctorChannel')?.reducer
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosMiddlewareResponse),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({immutableCheck: false, serializableCheck: false})
});
