import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import {useTheme} from "styled-components";

const PatientCancelEventModal = () => {
    const theme = useTheme();

    return (
        <Wrapper paddings={'0 8px 12px 24px'} maxHeight={'calc(100vh - 124px)'}>
            <PerfectScrollbarWrapper paddings={'0 16px 0 0'}>
                <CustomView condition={isDesktop || isTablet}>
                    <Text text={'Отменить мероприятие можно только при наличии письменного отказа от пациента'} />
                </CustomView>
                <MobileOnlyView>
                    <Text tag={'h5'} color={theme.colors.black54} font={'robotoRegular'} text={'Отменить мероприятие можно только при наличии письменного отказа от пациента'} />
                </MobileOnlyView>
            </PerfectScrollbarWrapper>
        </Wrapper>
    );
};

PatientCancelEventModal.propTypes = {
    hide: PropTypes.func.isRequired,
};

export default PatientCancelEventModal;
