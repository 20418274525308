import React from 'react';

const ReceiptLongIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8C15 8.55 14.55 9 14 9H10C9.45 9 9 8.55 9 8C9 7.45 9.45 7 10 7H14C14.55 7 15 7.45 15 8Z" />
        <path d="M15 11C15 11.55 14.55 12 14 12H10C9.45 12 9 11.55 9 11C9 10.45 9.45 10 10 10H14C14.55 10 15 10.45 15 11Z" />
        <path d="M17 9C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7C16.4477 7 16 7.44772 16 8C16 8.55228 16.4477 9 17 9Z" />
        <path d="M18 11C18 11.5523 17.5523 12 17 12C16.4477 12 16 11.5523 16 11C16 10.4477 16.4477 10 17 10C17.5523 10 18 10.4477 18 11Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 2L19.5 3.5L21 2V19C21 20.66 19.66 22 18 22H6C4.34 22 3 20.66 3 19V17C3 16.45 3.45 16 4 16H6V2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2ZM5 19C5 19.55 5.45 20 6 20H15V18H5V19ZM18 20C18.55 20 19 19.55 19 19V5H8V16H16C16.55 16 17 16.45 17 17V19C17 19.55 17.45 20 18 20Z" />
    </svg>
)

export default ReceiptLongIcon;
