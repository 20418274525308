import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {},
    reducers: {
        setReplyMessage: (state, action) => {
            state.replyMessage = {};
            state.replyMessage.name = action.payload.name;
            state.replyMessage.message = action.payload.message;
            state.replyMessage.type = action.payload.type;
            state.replyMessage.image = action.payload.image;
        },
        resetReplyMessage: (state, action) => {
            state.replyMessage = {};
        },
    },
});

export const { setReplyMessage, resetReplyMessage } = chatSlice.actions;

export default chatSlice.reducer;
