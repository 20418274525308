export const api = {
    authLogin: "/request",
    resetPassword: "/resetpassword",
    changePassword: "/ChangePassword",
    statistics: "/OrganizationWithStatistics",
    practitioners: "/PractitionerWithStatistics",
    organizationHierarchy: "/Organization/Hierarchy",
    trimesters: "/TrimesterOfPregnancy",
    mkb10: "/Icd10ru",
    organization: "/Organization",
    organizationHeads: "/Organization/Heads",
    organizationsWithHeads: "/Organization/GetAllOrganizationsWithHeads",
    practitionerRole: "/PractitionerRole",
    practitioner: "/Practitioner",
    practitionRoles: "/PractitionroleCode",
    managerPositionCode: "/ManagerPositionCode",
    gender: "/Gender",
    getCurrentUserManagerRoles: "/PractitionerRole/currentuser/manager",
    getCurrentUserDoctorRoles: "/PractitionerRole/currentuser/doctor",
    encounter: "/Encounter",
    encounters: "/Encounter/_search",
    encounterClose: "/Encounter/CloseChannel",
    encounterDeletePlannedChannel: "/Encounter/DeletePlannedChannel",
    patient: "/Patient",
    patients: "/Patient/_search",
    setNewPassword: "/setnewpassword",
    carePlans: "/CarePlan/_search",
    carePlan: "/CarePlan",
    careTeam: "/CareTeam",
    appRole: "/appRole",
    organizationProfiles: "/OrganizationProfile",
    weekOfPregnancy: "/WeekOfPregnancy",
    conditions: "/Condition/_search",
    condition: "/Condition",
    imageUpload: "/MinioFile",
    tasks: "/Task/_search",
    task: "/Task",
    communications: "/Communication/_search",
    communication: "/Communication",
    organizationPatientsStatistic: "/OrganizationPatientsStatistic",
    practitionerStatistic: "/PractitionerRoleStatistic",
    activationNotification: "/PractitionerRole/Notification",
    findPersonByPolicy: "/sizl/client/FindPersonByPolicy",
    answerTimingRule: "/AnswerTimingRule",
    doctorsSpecialities: "/PractitionroleSpeciality",
    planDefinition: "/PlanDefinition",
    changepractitioner: "/CareTeam/changepractitioner",
    resumepractitioner: "/CareTeam/resumepractitioner",
    managersMO: "/ValueSetBase?start=0&max=0&fg=valueset::onlinedoc-manager-position-code",
    onco: "/Onco",
    res: "/Res",
    sizl: "/Sizl",
    getname: "/getname",
    acceptinvite: "/acceptinvite",
    appointment: "/Appointment",
    channelStatusReason: "/ChannelStatusReason",
    exportStatistic: "/ExportStatistic/excel",
    calculateAnswerTime: "/AnswerTime/Calculate",
    closingChannelStatusReason: "/sizl/client/ClosingReasons",
    statisticUpdateStatus: "/StatisticUpdateStatus",
    mailingUpdateStatus: "/MailingList/GetStatus",
    deleteChannel: "/Encounter/DeletePlannedChannel",
    chatReport: "/ChatReport",
    patientTaskReport: "/PatientTaskReport",
    consent: "/Consent",
    profiletoken: "/profiletoken",
    agreement: "/Agreement",
    confirmPersonalDataUsage: "/Contract/ConfirmPersonalDataUsage",
    setBirthEvent: "/CarePlan/SetBirthEvent",
    superAdmin: "/SuperAdmin",
    activityDefinition: "/ActivityDefinition",
    activityReason: "/ActivityReason",
    preference: "/Preference",
    practitionerRoleWithQuery: '/PractitionerRole/SearchWithQuery',
    globalSearch: '/Search/GlobalSearch',
    search: '/Search',
    mailing: '/Mailing',
    mailingList: '/MailingList',
    authHistory: '/GetAuthenticationHistory',
    eventLogsList: '/actionlogrecords',
    eventLogsExcel: 'export',
}
