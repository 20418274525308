import {createSlice} from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {isEmpty, get, cloneDeep, toLower} from "lodash";
import dayjs from "dayjs";
import {registerMessage, showMessage, closeMessage} from "reducers/systemMessages/systemMessagesSlice"
import { systemMessageDelay } from 'consts';
import { getPersonFio } from 'adapters';

const initialState = {
    active: {
        tableSettings: {
            fieldsFilter: {
                status: "in-progress,planned,onleave"
            },
            paging: {
                startIndex: 0,
                maxItems: 25
            },
            sorting: {
                default: [
                    {
                        propertyName: "patient",
                        direction: 0,
                    }
                ]
            },
            searchString: ''
        },
    },
    archived: {
        tableSettings: {
            fieldsFilter: {
                status: "finished,cancelled"
            },
            paging: {
                startIndex: 0,
                maxItems: 25
            },
            sorting: {
                default: [
                    {
                        propertyName: "patient",
                        direction: 0,
                    }
                ]
            },
            searchString: ''
        },
    },
    loading:{
        fullPage: true,
        data: true,
        genders: true,
        conditions: true,
        updateCarePlans: true,
        person: null,
        createChannelModal: null,
        deleteChannelModal: null,
    },
    data: null,
    totalItems: null,
    channelsCount: 0,
    mode: {},
    type: 'oncology',
    person: null,
    saveThisPageFilter: false,
    planDefinition: [],
    personConsent: null
}

export const channelsSlice = createSlice({
    name: 'registrator_channels',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        resetLoadingState: (state, action) => {
            state.loading = initialState?.loading;
        },
        setChannelsCount: (state, action) => {
            state.channelsCount = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setSorting: (state, { payload: { propertyName, profile = 'default', table }}) => {
            let targetObject = state[table].tableSettings.sorting[profile].find(item => item.propertyName === propertyName);
            if(targetObject){
                targetObject.direction = (targetObject.direction === 1) ? 0 : 1;
            }else{
                state[table].tableSettings.sorting[profile] = [{
                    propertyName,
                    direction: 1
                }]
            }
        },
        setFieldsFilter: (state, action) => {
            if(action.payload){
                if(action.payload.params) {
                    if (action.payload.params !== state[action.payload.table].tableSettings.fieldsFilter[action.payload.field]) { // Костыль чтобы предотвратить лишние запросы
                        state[action.payload.table].tableSettings.fieldsFilter = {...state[action.payload.table].tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
                    }
                }else{
                    delete state[action.payload.table].tableSettings.fieldsFilter[action.payload.field]
                }
            }
        },
        setPage: (state, action) => {
            state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
        },
        setMaxItems: (state, action) => {
            state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
        },
        setTotalItems: (state, action) => {
            state.totalItems = action.payload
        },
        setMode: (state, action) => {
            state.mode  = action.payload;
        },
        setPerson: (state, action) => {
            state.person = action.payload;
        },
        clearPerson: (state, action) => {
            state.person = null;
        },
        setSearchString: (state, action) => {
            state[action.payload.table].tableSettings.searchString = action.payload.value;
        },
        clearTableSettings: (state, action) => {
            if (!state.saveThisPageFilter) {
                state.active.tableSettings = initialState.active.tableSettings;
                state.archived.tableSettings = initialState.archived.tableSettings;
            }
        },
        setSaveThisPageFilter: (state, action) => {
            state.saveThisPageFilter = action.payload;
        },
        resetStateExceptTableSettings: (state, action) => {
            const initialStateCopy = cloneDeep(initialState);
            const tableSettingsActiveCopy = cloneDeep(state.active.tableSettings);
            const tableSettingsArchievedCopy = cloneDeep(state.archived.tableSettings);
            delete initialStateCopy.active.tableSettings;
            delete initialStateCopy.archived.tableSettings;
            initialStateCopy.active.tableSettings = tableSettingsActiveCopy;
            initialStateCopy.archived.tableSettings = tableSettingsArchievedCopy;
            return initialStateCopy;
        },
        resetState: (state, action) => {
            return initialState
        },
        setPlanDefinition: (state, action) => {
            state.planDefinition = action.payload;
        },
        setConsent: (state, action) => {
            state.personConsent = action.payload;
        },
    }
});

export const selectData = state => state.registrator.channels.data;
export const selectFieldsFilter = state => state.registrator.channels.tableSettings.fieldsFilter;
export const selectSorting = state => state.registrator.channels.tableSettings.sorting.default;
export const selectPractitionerSorting = state => state.registrator.channels.tableSettings.sorting.practitioner;
export const selectTableSettings = state => state.registrator.channels.active.tableSettings;
export const selectTableSettingsArchived = state => state.registrator.channels.archived.tableSettings;
export const selectLoading = state => state.registrator.channels.loading;
export const selectChannelsCount = state => state.registrator.channels.channelsCount;
export const selectType = state => state.registrator.channels.type;
export const selectPaging = state => state.registrator.channels.tableSettings.paging;
export const selectTotalItems = state => state.registrator.channels.totalItems;
export const selectMode = state => state.registrator.channels.mode;
export const selectPerson = state => state.registrator.channels.person;
export const selectPlanDefinition = state => state.registrator.channels.planDefinition;
export const selectPersonConsent = state => state.registrator.channels.personConsent;
export const ChannelsActions = channelsSlice.actions;

export const getData = ({organizationId, fieldsFilter, sorting = [], paging: {startIndex = 0, maxItems = 10}, searchString}) => {
    return (dispatch) => {

        dispatch(ChannelsActions.setLoading({
            type: 'data',
            value: true
        }));
        dispatch(ChannelsActions.setData(null));

        organizationId &&
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${organizationId}/Encounters`,
            data: {
                "paging":{
                    "startIndex": startIndex,
                    "maxItems": maxItems
                },
                "filtering":{
                    searchString,
                    "fieldsFilter": {
                        ...fieldsFilter,
                    }
                },
                sorting: [...sorting],
            }
        }).then((response) => {
            const data = get(response,'data');
            const items = get(data, 'items');
            let modifiedData = []

            if(!isEmpty(items)) {
                modifiedData = items && items.map(item => {

                    const diagnosis = get(item, 'conditionName');

                    return {
                        patientId: get(item, 'patientId'),
                        carePlanId: get(item, 'carePlanId'),
                        encounterId: get(item, 'id'),
                        name: get(item, 'patientName'),
                        diagnosis: diagnosis,
                        trimester: get(item, 'trimester'),
                        weekOfPregnancy: get(item, 'week'),
                        dateOfChildbirth: get(item, 'dateOfChildbirth'),
                        messages: {
                            questions: item?.totalFromPatient,
                            answers: item?.totalFromPractitioner,
                            expired: item?.notAnsweredAndOverdue,
                            allExpired: item?.notAnsweredOrAnsweredOverdue
                        },
                        practitioner: {
                            practitionerRoleId: get(item, 'practitionerRoleId'),
                            name: get(item, 'practitionerName'),
                        },
                        status: get(item, 'status'),
                        creationDate:  get(item, 'createdAt'),
                    }
                })

                dispatch(ChannelsActions.setData([...modifiedData]));
            }

            dispatch(ChannelsActions.setTotalItems(get(data, 'clientQuery.paging.totalItems', 1)));
            dispatch(ChannelsActions.setChannelsCount(get(data, 'clientQuery.paging.totalItems', 0)));
            dispatch(ChannelsActions.setLoading({
                type: 'data',
                value: false
            }));
        }).catch(err => {

            dispatch(registerMessage({name: 'channels-loading-encounters-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить статистику каналов', closable: true}))
            dispatch(showMessage('channels-loading-encounters-message'))
            dispatch(closeMessage({name: 'channels-loading-encounters-message', delay: systemMessageDelay}))

            dispatch(ChannelsActions.setLoading({
                type: 'data',
                value: false
            }));

            console.log(err);
        });
    };
};

export const getPlanDefinition = pregnancy => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: pregnancy ? `${api.planDefinition}?fg=context::Z33:x:status::active` : `${api.planDefinition}?fg=context:not::Z33:x:status::active`,
        }).then((response) => {
            dispatch(ChannelsActions.setPlanDefinition(response.data.items));
        }).catch(err => {

            dispatch(registerMessage({name: 'channels-loading-plan-definition-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить план', closable: true}))
            dispatch(showMessage('channels-loading-plan-definition-message'))
            dispatch(closeMessage({name: 'channels-loading-plan-definition-message', delay: systemMessageDelay}))

            console.log(err)
        });
    };
};

export const findSIZLPerson = ({policy}) => {
    return (dispatch) => {

        dispatch(ChannelsActions.setLoading({
            type: 'person',
            value: true
        }));

        fetchInstance({
            method: "GET",
            url: `${api.findPersonByPolicy}/${policy}`,
        }).then((response) => {

            const data = get(response,'data');
            console.log(data?.lastName);
            data && data?.lastName ? dispatch(ChannelsActions.setPerson(data)) : dispatch(ChannelsActions.clearPerson()) //Проверяю по lastName, т.к. если ничего не нашлось, приходит не заполненный объект

            dispatch(ChannelsActions.setLoading({
                type: 'person',
                value: false
            }));
        }).catch(err => {

            dispatch(registerMessage({name: 'channels-find-person-message', type: 'red', title: 'Ошибка', text: 'Ошибка при выполнении поиска', closable: true}))
            dispatch(showMessage('channels-find-person-message'))
            dispatch(closeMessage({name: 'channels-find-person-message', delay: systemMessageDelay}))

            dispatch(ChannelsActions.setLoading({
                type: 'person',
                value: false
            }));

            console.log(err);
        });
    };
};

// export const createConsent = ({formData, person, organizationId, pregnancy, handleHide, tableSettings}) => {
//     let imageData = new FormData();
//     imageData.append("files", formData.document);
//     return (dispatch) => {
//         dispatch(ChannelsActions.setLoading({
//             type: 'createChannelModal',
//             value: true
//         }));
//         if (formData.document) {
//             fetchInstance({
//                 method: "POST",
//                 url: `${api.imageUpload}`,
//                 data: imageData
//             }).then((response) => {
//                 const formDataCopy = cloneDeep(formData);
//                 delete formDataCopy.document;
//                 formDataCopy.document = {
//                     fileName: response.data[0]?.fileName,
//                     url: response.data[0]?.url,
//                 };
//                 dispatch(createPerson({formData: formDataCopy, person, organizationId, pregnancy, handleHide, tableSettings}));
//             }).catch(err => {
//                 dispatch(ChannelsActions.setLoading({
//                     type: 'createChannelModal',
//                     value: false
//                 }));
//                 dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data :  'Ошибка выполнения запроса', closable: true}))
//                 dispatch(showMessage('create-person-error-message'))
//                 dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))
//             });
//         } else {
//             dispatch(createPerson({formData, person, organizationId, pregnancy, handleHide, tableSettings}));
//         }
//     }
// };

export const createPerson = ({formData, person, organizationId, pregnancy, handleHide, tableSettings}) => {
    return (dispatch) => {
        dispatch(ChannelsActions.setLoading({
            type: 'createChannelModal',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.patient}/createorupdate`,
            data: {
                organizationId,
                fio : getPersonFio(person),
                birthDate : dayjs(person.birthDate).format('YYYY-MM-DD'),
                gender : person?.sex ? toLower(person?.sex) : null,
                police : person.policyNumber,
                ukl : person.ukl,
                document: formData?.document,
            }
        }).then((response) => {
            const data = get(response, 'data');
            if (data) {
                dispatch(createChannel({formData, patientId: data.id, patientUKL: person.ukl, pregnancy, organizationId, handleHide, tableSettings}));
            }
        }).catch(err => {

            dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data :  'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('create-person-error-message'))
            dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))

            dispatch(ChannelsActions.setLoading({
                type: 'createChannelModal',
                value: false
            }));
        });
    };
};

export const createChannel = ({formData, patientId, patientUKL, pregnancy, organizationId, handleHide, tableSettings}) => {
    return (dispatch) => {
        // console.log(formData);
        fetchInstance({
            method: "POST",
            url: `${api.encounter}/CreateChannel`,
            data: {
                patientId,
                diagnosisCode: !pregnancy ? formData?.diagnosis?.code : "Z33",
                diagnosisDescription: !pregnancy ? formData?.diagnosis?.display : "Состояние, свойственное беременности",
                onSet: formData?.pregnancy,
                practitionerRoleId: formData.doctor.id,
                planDefinitionId: formData.protocol.id,
                phone: formData?.patientPhone,
                email: formData?.email,
                preferredCommunication: 1,
                patientUKL,
                organizationId,
                firstVisitDate: formData?.firstVisitDate
            }
        }).then((response) => {
            dispatch(getStatisticUpdateStatus({id: response.data.id, handleHide: handleHide, iteration: 30, organizationId, tableSettings}));
        }).catch(err => {
            dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: (pregnancy && err?.response?.data) ? "У этого пациента уже открыт канал" : (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data :  'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('create-channel-error-message'))
            dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))

            dispatch(ChannelsActions.setLoading({
                type: 'createChannelModal',
                value: false
            }));
        });

    };
};

export const getStatisticUpdateStatus = ({id, handleHide, iteration, organizationId, tableSettings}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.statisticUpdateStatus}/${id}`,
        }).then((response) => {
            if (response.data === "Completed" || !iteration) {
                handleHide && handleHide();
                dispatch(ChannelsActions.setLoading({
                    type: 'deleteChannelModal',
                    value: false
                }));
                dispatch(ChannelsActions.setLoading({
                    type: 'createChannelModal',
                    value: false
                }));
                dispatch(ChannelsActions.setPerson(null));
                dispatch(ChannelsActions.setLoading({
                    type: 'person',
                    value: null
                }));

                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                }

                dispatch(getData({
                    ...tableSettings,
                    sorting: tableSettings.sorting.default,
                    organizationId
                }));
            } else {
                setTimeout(() => dispatch(getStatisticUpdateStatus({id, handleHide, iteration: --iteration, organizationId, tableSettings})), 1000);
            }
        }).catch(err => {
            dispatch(ChannelsActions.setLoading({
                type: 'deleteChannelModal',
                value: false
            }));
            dispatch(ChannelsActions.setLoading({
                type: 'createChannelModal',
                value: false
            }));
            dispatch(ChannelsActions.setLoading({
                type: 'person',
                value: null
            }));
            dispatch(registerMessage({name: 'statistics-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('statistics-error-message'))
            dispatch(closeMessage({name: 'statistics-error-message', delay: systemMessageDelay}))
        });
    };
};

export const deleteChannel = ({
    encounterId,
    tableSettings,
    organizationId,
    handleHide
}) => {
    return (dispatch) => {
        dispatch(ChannelsActions.setLoading({
            type: 'deleteChannelModal',
            value: true
        }));
      fetchInstance({
        method: "GET",
        url: `${api.deleteChannel}?encounterId=${encounterId}`
        }).then((response) => {
            dispatch(getStatisticUpdateStatus({id: encounterId, handleHide: handleHide, iteration: 30, organizationId, tableSettings}));
        }).catch(err => {

            dispatch(registerMessage({name: 'channels-deleting-message', type: 'red', title: 'Ошибка', text: 'Не удалось удалить канал', closable: true}))
            dispatch(showMessage('channels-deleting-message'))
            dispatch(closeMessage({name: 'channels-deleting-message', delay: systemMessageDelay}))

            console.log(err);
        });
    };
};

export const getConsentBlankEmpty = ({
    dsCode,
    callback
}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.agreement}/EmptyPdf?dsCode=${dsCode}`,
        responseType: 'blob'
        }).then((response) => {
            callback(response.data);
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getConsentBlankFilled = ({
    patientId,
    organizationId,
    dsCode,
    callback
}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.agreement}/PrefilledPdf`,
            data: {
                patientId,
                organizationId,
                dsCode
                },
            responseType: 'blob'
            }).then((response) => {
                callback(response.data);
            }).catch(err => {
                console.log(err)
            });
    };
};

export const getPatientId = ({policy, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.patient}?fg=identifier::${policy}`
        }).then((response) => {
            callback(get(response.data, "items[0].id"));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getConsent = ({patientId, organizationId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.consent}/getactiveconsent?patientId=${patientId}&organizationId=${organizationId}`
        }).then((response) => {
            dispatch(ChannelsActions.setConsent(response.data));
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const deleteConsent = ({id, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.consent}/${id}`
        }).then((response) => {
            callback();
        }).catch(error => {
            console.log("error", error);
        });
    };
};

export const createConsent = ({files, person, organizationId, patientId}) => {
    let imageData = new FormData();
    imageData.append("files", files);
    return (dispatch) => {
        dispatch(ChannelsActions.setLoading({
            type: 'patientInfoModal',
            value: true
        }));

        if (files) {
            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                data: imageData
            }).then((response) => {
                const filesCopy = {
                    fileName: response.data[0]?.fileName,
                    url: response.data[0]?.url,
                };
                dispatch(createPersonWithConsent({files: filesCopy, person, organizationId, patientId}));
            }).catch(err => {
                dispatch(ChannelsActions.setLoading({
                    type: 'patientInfoModal',
                    value: false
                }));
                dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                dispatch(showMessage('create-person-error-message'))
                dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))
            });
        } else {
            dispatch(createPersonWithConsent({files: {}, person, organizationId, patientId}));
        }
    }
};

export const createPersonWithConsent = ({files, person, organizationId, patientId}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.patient}/createorupdate`,
            data: {
                organizationId,
                document: files,
                fio : getPersonFio(person),
                birthDate : dayjs(person.birthDate).format('YYYY-MM-DD'),
                gender : person?.sex ? toLower(person?.sex) : null,
                police : person.policyNumber,
                ukl : person.ukl,
            }
        }).then((response) => {
            dispatch(ChannelsActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));
            dispatch(getConsent({
                patientId,
                organizationId
            }));
        }).catch(err => {
            dispatch(registerMessage({name: 'create-person-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('create-person-error-message'))
            dispatch(closeMessage({name: 'create-person-error-message', delay: systemMessageDelay}))

            dispatch(ChannelsActions.setLoading({
                type: 'patientInfoModal',
                value: false
            }));

            console.log(err);
        });
    };
};

export default channelsSlice.reducer;
