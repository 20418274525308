import React from 'react';
import PropTypes from 'prop-types';
import FloatingMenu from "components/FloatingMenu";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import PeriodSelect from "../PeriodSelect";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {doctorActions, selectActivePeriodItem} from "reducers/doctor";

const PeriodFilter = ({elementPosition}) => {

    const [showAnchorPopup, hideAnchorPopup] = useAnchorPopup(
        {
            component: PeriodSelect,
            props: {
                width: '328px',
                parentPosition: {
                    x: elementPosition.x,
                    y: elementPosition.y,
                }
            }
        });
    const dispatch = useDispatch();
    const activePeriodItem = useSelector(selectActivePeriodItem);
    const list = [
        {
            id: 0,
            label: 'За месяц',
            action: e => {
                handleDate(e, -1);
                dispatch(doctorActions.setActivePeriodItem(0));
                dispatch(doctorActions.setPeriodFilteringLabel('За месяц'));
            },
        },
        {
            id: 1,
            label: 'За 3 месяца',
            action: e => {
                handleDate(e, -3);
                dispatch(doctorActions.setActivePeriodItem(1));
                dispatch(doctorActions.setPeriodFilteringLabel('За 3 месяца'));
            },
        },
        {
            id: 2,
            label: 'За 6 месяцев',
            action: e => {
                handleDate(e, -6);
                dispatch(doctorActions.setActivePeriodItem(2));
                dispatch(doctorActions.setPeriodFilteringLabel('За 6 месяцев'));
            },
        },
        {
            id: 3,
            label: 'Другое...',
            action: (e) => showAnchorPopup(e).then(),
        },
    ];

    const handleDate = (e, months) => {
        dispatch(doctorActions.setStartDateToFilter(dayjs().add(months, "month").format("YYYY-MM-DD")));
        dispatch(doctorActions.setEndDateToFilter(dayjs().format("YYYY-MM-DD")));
        hideAnchorPopup(e);
    }

    return (
        <FloatingMenu
        active={activePeriodItem}
        list={list} />
    );
};

PeriodFilter.propTypes = {
    parentPosition: PropTypes.object,
};

export default PeriodFilter;
