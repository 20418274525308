import {createSlice} from "@reduxjs/toolkit";
import { systemMessageDelay } from "consts";
import { api } from "consts/api";
import { get } from "lodash";
import { closeMessage, registerMessage, showMessage } from "reducers/systemMessages/systemMessagesSlice";
import { fetchInstance } from "wrappers/axios";
import commonReducers from "./commonReducers";

const initialState = {
    tableSettings: {
        fieldsFilter: {
            status: 'archived',
        },
        paging: {
            startIndex: 0,
            maxItems: 25
        },
        sorting: {
            default: [
                {
                    propertyName: "employee",
                    direction: 0,
                }
            ]
        },
        searchString: ''
    },
    loading:{
        data: true,
    },
    data: null,
    totalItems: null,
    channelsCount: 0,
}

export const globalSearchEmployeesSlice = createSlice({
    name: "globalSearchEmployees",
    initialState,
    reducers: {
        ...commonReducers(initialState)
    }
});

export const selectData = state => state.globalSearch.employees.data;
export const selectLoading = state => state.globalSearch.employees.loading;
export const selectFieldsFilter = state => state.globalSearch.employees.tableSettings.fieldsFilter;
export const selectSorting = state => state.globalSearch.employees.tableSettings.sorting.default;
export const selectTableSettings = state => state.globalSearch.employees.tableSettings;
export const selectPaging = state => state.globalSearch.employees.tableSettings.paging;
export const selectTotalItems = state => state.globalSearch.employees.totalItems;
export const GlobalSearchEmployeesActions = globalSearchEmployeesSlice.actions;

export default globalSearchEmployeesSlice.reducer;

export const getData = ({fieldsFilter, sorting = [], paging: {startIndex = 0, maxItems = 10}, searchString, organizationId}) => {
    return (dispatch) => {

        dispatch(GlobalSearchEmployeesActions.setLoading({
            type: 'data',
            value: true
        }));
        dispatch(GlobalSearchEmployeesActions.setData(null));

        fetchInstance({
            method: "POST",
            url: `${api.search}/GlobalPractitionerRolesSearch`,
            data: {
                paging: {
                    startIndex,
                    maxItems,
                },
                filtering: {
                    searchString,
                    fieldsFilter: {
                        ...fieldsFilter,
                        requestingOrganizationId: organizationId
                    }
                },
                sorting: [...sorting],
            }
        }).then((response) => {
            const data = get(response,'data.practitionerInfos');

            dispatch(GlobalSearchEmployeesActions.setData(data));
            dispatch(GlobalSearchEmployeesActions.setTotalItems(get(data, 'clientQuery.paging.totalItems', 1)));
            dispatch(GlobalSearchEmployeesActions.setLoading({
                type: 'data',
                value: false
            }));
        }).catch(err => {

            dispatch(registerMessage({name: 'global-search-employees-loading-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить статистику каналов', closable: true}))
            dispatch(showMessage('global-search-employees-loading-message'))
            dispatch(closeMessage({name: 'global-search-employees-loading-message', delay: systemMessageDelay}))

            dispatch(GlobalSearchEmployeesActions.setLoading({
                type: 'data',
                value: false
            }));

            console.log(err);
        });
    };
};
