import styled, {css, useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActivePatientData} from "reducers/doctor";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";

export const ServiceDescription = () => {
    const theme = useTheme()

    const activePatientData = useSelector(selectActivePatientData);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (activePatientData.services) {
            setData(activePatientData.services.activeItem);
        }
    }, [activePatientData.services]);

    return (
        <Wrapper paddings={'8px 16px'}>
            <CustomView condition={isDesktop || isTablet}>
                <Text text={data.name} tag={'h5'} font={'robotoMedium'} />
                <Wrapper paddings={'9px 0 0 0'}>
                    <Text tag={'small'} text={'Дата проведения'} color={theme.colors.black54} />
                    <Text text={dayjs(data.date).locale(ru).format('D MMMM YYYY г.')} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'9px 0 0 0'}>
                    <Text tag={'small'} text={'Лечащий врач'} color={theme.colors.black54} />
                    <Text text={data.doctorLastName + ' ' + data.doctorFirstName + ' ' + data.doctorMiddleName} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'9px 0 0 0'}>
                    <Text tag={'small'} text={'Медицинская организация'} color={theme.colors.black54} />
                    <Text text={data.medicalOrganizationName} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'9px 0 0 0'}>
                    <Text tag={'small'} text={'Диагноз'} color={theme.colors.black54} />
                    <Diagnosis>
                        {data.diagnosisCode}
                        <span>&emsp;{data.diagnosisName}</span>
                    </Diagnosis>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Text text={data.name} tag={'h5'} font={'robotoMedium'} />
                <Wrapper paddings={'12px 0 0 0'}>
                    <Text tag={'small'} text={'Дата проведения'} color={theme.colors.black54} />
                    <Text tag={'h5'} text={dayjs(data.date).locale(ru).format('D MMMM YYYY г.')} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'12px 0 0 0'}>
                    <Text tag={'small'} text={'Лечащий врач'} color={theme.colors.black54} />
                    <Text tag={'h5'} text={data.doctorLastName + ' ' + data.doctorFirstName + ' ' + data.doctorMiddleName} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'12px 0 0 0'}>
                    <Text tag={'small'} text={'Медицинская организация'} color={theme.colors.black54} />
                    <Text tag={'h5'} text={data.medicalOrganizationName} font={'robotoRegular'} />
                </Wrapper>
                <Wrapper paddings={'12px 0 0 0'}>
                    <Text tag={'small'} text={'Диагноз'} color={theme.colors.black54} />
                    <Diagnosis>
                        {data.diagnosisCode}
                        <span>&emsp;{data.diagnosisName}</span>
                    </Diagnosis>
                </Wrapper>
            </MobileOnlyView>
        </Wrapper>
    )
}

const Diagnosis = styled.div`
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.text})};
    
    span {
        ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54, size: theme.fonts.sizes.text})};
    }
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})};
        
        span {
            ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54, size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})};
        }
    `}
`
