import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "components/Icon";
import styled, {css} from "styled-components";
import Error from "components/Error";
import {Wrapper} from "styled/Wrapper";
import ArrowIcon from "components/Icons/ArrowIcon";
import FloatingMenu from "components/FloatingMenu";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import {get} from "lodash";
import {debounce} from "utils/debounce";
import { useOutsideClickHandler } from 'hooks/useOutsideClickHandler';

const Select = ({value, label, placeholder, iconSize, iconPosition, iconAction, error, showError, required, options, onChange, active, disabled, valueColor, valueFont, selectWrapperColor}) => {
    const ref = useRef();

    const [focused, setFocused] = useState(false)
    const [currentValue, setCurrentValue] = useState(value?.display || value?.label || value?.name || get(options, "[0].label"));
    const [selectWidth, setSelectWidth] = useState(0);
    const [showedValue, setShowedValue] = useState('');

    const [isPopupVisible, setPopupVisible] = useState(false);

    useOutsideClickHandler({ref, callback: () => setPopupVisible(false)});

    useEffect(() => {
        setSelectWidth(ref.current.getBoundingClientRect().width)
    }, [ref])

    useEffect(() => {
        const debouncedElementCoords = debounce(() => {
            updatePosition()
        }, 10)

        window.addEventListener('resize', debouncedElementCoords)
        return () => {
            window.removeEventListener('resize', debouncedElementCoords)
        }
    })

    useEffect(() => {
      setCurrentValue(value?.display || value?.label || value?.name || get(options, "[0].label"))
    }, [options, value])

    useEffect(() => {
        if(!isPopupVisible) {
            setFocused(false)
        }
    }, [isPopupVisible])

    const handleChange = (item) => {
        setCurrentValue(item.display || item.label || item.name)
        setFocused(false)
        onChange && onChange(item)
    }


    const [showAnchorPopup, hideAnchorPopup, updatePosition] = useAnchorPopup({
        component: FloatingMenu,
        props: {
            width: selectWidth + 'px',
            list: options,
            active,
            onClick: handleChange
        }
    })

    const handleFocus = (e) => {
        if(!disabled){
            if(focused) {
              setFocused(false);
              setPopupVisible(false);
              hideAnchorPopup(e);
            } else {
              setFocused(true);
              setPopupVisible(true);
              showAnchorPopup(e);
            }
        }
    }

    useEffect(() => {
      const maxLength = Math.round(selectWidth / 8.8);
      if (selectWidth && currentValue && (currentValue.length > maxLength)) {
        setShowedValue(currentValue.slice(0, maxLength) + '...');
      } else {
        setShowedValue(currentValue);
      }
  }, [currentValue]);

    return (
        <Wrapper paddings={label && '22px 0 0 0'}>
            <Field>
                {label && <Label error={showError}>
                    {label}
                    {required &&
                        <i> *</i>
                    }
                </Label>}
                <SelectWrapper onClick={handleFocus} onBlur={handleFocus} position={iconPosition} focused={focused} ref={ref} disabled={disabled} selectWrapperColor={selectWrapperColor}>
                    <Content>
                        {!currentValue && placeholder && <PlaceHolder>
                            {placeholder}
                        </PlaceHolder>}
                        {currentValue && <Value valueColor={valueColor} valueFont={valueFont}>
                            {showedValue}
                        </Value>}
                    </Content>
                    <IconWrapper position={iconPosition}>
                        <Icon icon={ArrowIcon} size={iconSize} action={iconAction} rotate={focused && 180}/>
                    </IconWrapper>
                </SelectWrapper>
            </Field>
            <ErrorWrapper>
                <Error message={error} show={showError} />
            </ErrorWrapper>
        </Wrapper>
    );
};

const PlaceHolder = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black30})};
  
  ${({theme}) => theme.isMobile && css`
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black30, size: theme.fonts.sizes.h5})};
  `}
`

const Value = styled.div`
  ${({theme, valueColor, valueFont}) => theme.mixins[valueFont]({color: theme.colors[valueColor]})};
  ${({theme}) => theme.isMobile && css`
    ${({theme, valueColor, valueFont}) => theme.mixins[valueFont]({color: theme.colors[valueColor], size: theme.fonts.sizes.h5})};
  `}
`

const Content = styled.div`
  padding: 0 10px;
`

const Field = styled.div`
  position: relative;
`

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};
  position: absolute;
  left: 0;
  top: -22px;

  ${({error}) => error && css`
    color: ${({theme}) => theme.colors.red};
  `};

  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  ${({position}) => renderIcon(position)};
  width: 18px;
  height: 18px;
  pointer-events: none;
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 32px;
  outline: none;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  padding: 6px 0;
  background-color: ${({theme, selectWrapperColor}) => selectWrapperColor ? selectWrapperColor : theme.colors.black04};
  ${({theme}) => theme.mixins.borderRadius()};
  ${({theme}) => theme.isMobile && css`
    height: 40px;
    padding: 10px 0;
  `}

  :hover {
    background-color: ${({theme}) => theme.colors.black08};
  }
  
  ${({focused}) => focused && css`
    box-shadow: 0 2px 0 #009DE8;
    border-radius: 6px 6px 0 0;
  `}
  }
`

const ErrorWrapper = styled.div`

`

const renderIcon = (position) => {
    switch (position) {
        case 'left':
            return css`
              left: 8px;
              top: 7px;
            `
        case 'right':
            return css`
              right: 8px;
              top: 7px;
            `
        default:
            return css`
              left: 8px;
              top: 7px;
            `
    }
}

Select.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.element,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconAction: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    iconShowOnType: PropTypes.bool,
};

Select.defaultProps = {
    type: 'text',
    iconPosition: 'right',
    iconAction: 'undefined',
    iconShowOnType: false,
    valueColor: 'black',
    valueFont: 'robotoRegular'
}

export default Select;
