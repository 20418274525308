import React, {useEffect} from 'react';
import {Wrapper} from "styled/Wrapper";
import Protocol from "components/DoctorRigthSide/Protocol";
import DataFromSIZL from "components/DoctorRigthSide/DataFromSIZL";
import Notes from "components/DoctorRigthSide/Notes";
import TabsHeader from "components/Tabs/TabsHeader";
import TabsBody from "components/Tabs/TabsBody";
import styled, {useTheme} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {clearPeriodFilterState, selectActivePatient} from "../../reducers/doctor";
import Text from "../Text";
import { isDesktop, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

const DoctorRightSide = () => {
    const activePatient = useSelector(selectActivePatient);
    const theme = useTheme();
    const dispatch = useDispatch();

    const smallDesktop = useMediaQuery({ maxWidth: 1215 });

    useEffect(() => {
        dispatch(clearPeriodFilterState());
    }, [activePatient])

    let list = [
        {
            id: 0,
            label: 'Протокол',
            activity: true,
            component: Protocol
        },
        {
            id: 1,
            label: 'Данные из СЗЛ',
            activity: false,
            component: DataFromSIZL
        },
        {
            id: 2,
            label: 'Заметки',
            activity: false,
            component: Notes
        }
    ]

    return (
        <Wrapper fullsize minHeight={(isTablet || (isDesktop && smallDesktop)) ? 'calc(100vh - 60px)' : null}>
            <TabsHeaderWrapper>
                <TabsHeader list={list} type={'buttons'} name={'doctor-right-side-tabs'} justifyContent={'center'} margin={'0 4px'} height={'64px'} />
            </TabsHeaderWrapper>
            {activePatient?.status && activePatient?.status === 'planned' ?
                <Wrapper align={'center'} margins={'32px 0'}>
                    <Text text={'Информация недоступна'} tag={'span'} font={'robotoRegular'} color={theme.colors.black54} />
                </Wrapper>
                :
                <TabsBody list={list} name={'doctor-right-side-tabs'}/>
            }
        </Wrapper>
    );
};

const TabsHeaderWrapper = styled.div`
    padding: 0 12px;
    ${({theme}) => theme.mixins.innerShadow()};
`

export default DoctorRightSide;
