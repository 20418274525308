import React from 'react';

const TabletIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 15C10.4477 15 10 15.4477 10 16C10 16.5523 10.4477 17 11 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15H11Z" />
        <path d="M11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H11Z" />
        <path d="M11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7H11Z" />
        <path d="M8 9C8.55229 9 9 8.55229 9 8C9 7.44772 8.55229 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9Z" />
        <path d="M8 13C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z" />
        <path d="M9 16C9 16.5523 8.55229 17 8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15C8.55229 15 9 15.4477 9 16Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.82 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C4.86 21 4.73 20.99 4.6 20.97C4.21 20.89 3.86 20.69 3.59 20.42C3.41 20.23 3.26 20.02 3.16 19.78C3.06 19.54 3 19.27 3 19V5C3 4.72 3.06 4.46 3.16 4.23C3.26 3.99 3.41 3.77 3.59 3.59C3.86 3.32 4.21 3.12 4.6 3.04C4.73 3.01 4.86 3 5 3H9.18C9.6 1.84 10.7 1 12 1C13.3 1 14.4 1.84 14.82 3ZM12.75 3.5C12.75 3.09 12.41 2.75 12 2.75C11.59 2.75 11.25 3.09 11.25 3.5C11.25 3.91 11.59 4.25 12 4.25C12.41 4.25 12.75 3.91 12.75 3.5ZM5 19H19V5H5V19Z" />
    </svg>
)

export default TabletIcon;
