import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {isEmpty, get, find, isArray, first, last, remove, filter} from "lodash";
import dayjs from "dayjs";
import { findInTypeBySystem } from 'utils';
import { cloneDeep } from 'lodash';
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';

const initialState = {
    tableSettings: {
        fieldsFilter: {
        },
        paging: {
            startIndex: 0,
            maxItems: 25
        },
        sorting: {
            default: [
                {
                    propertyName: "restrictionPeriod",
                    direction: 0,
                }
            ],
            practitioner: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ],
            communication: [
                {
                    propertyName: "sent",
                    direction: 0,
                }
            ]
        },
        searchString: ''
    },
    carousel: {             // Объект для галлереи фоток
        carouselImages: [],
        activeMessageId: '',
        activeImageIndex: 0,
        activeSender: null,
        carouselLength: 0,
        imageSlideId: ''
    },
    loading:{
        fullPage: true,
        practitionerRole: true,
        tasks: true,
        communications: false,
        nextPageCommunication: false,
        encounterStatistics: true,
        counselingHistory: true,
        condition: false
    },
    totalItems: null,
    practitioner: null,
    organization: null,
    practitionerRole: null,
    tasks: null,
    condition: null,
    protocolCount: 0,
    communications: [],
    messageTotalCount: null,
    encounterStatistics: null,
    communicationLoadedPageCount: 0,
    allCommunicationsLoaded: false,
    scrollToCommunication: null,
    findedCommunication: [],
    counselingHistory: [],
    findedCommunicationCount: 0,
    type: null
}

const reducers = {
    setPractitioner: (state, action) => {
        state.practitioner = action.payload;
    },
    setOrganization: (state, action) => {
        state.organization = action.payload;
    },
    setPractitionerRole: (state, action) => {
        state.practitionerRole = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    resetLoadingState: (state, action) => {
        state.loading = initialState.loading;
    },
    setLoadingsByName: (state, action) => {
        if(!isEmpty(action.payload) && isArray(action.payload)){
            action.payload.forEach(item => {
                state.loading[item] = true;
            })
        }
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setTasks: (state, action) => {
        state.tasks = action.payload;
    },
    setCommunications: (state, action) => {
        state.communications = action.payload;
    },
    setMessageTotalCount: (state, action) => {
        state.messageTotalCount = action.payload;
    },
    addCommunication: (state, action) => {
        const lastCurrentCommunication = last(state.communications);
        const startNewCommunication = first(action?.payload);

        if(lastCurrentCommunication?.date === startNewCommunication?.date) {
            lastCurrentCommunication?.messages.push(...startNewCommunication?.messages)

            let currentCommunicationsCopy = cloneDeep(state.communications);
            let newCommunicationsCopy = cloneDeep(action?.payload);

            remove(currentCommunicationsCopy, { date: lastCurrentCommunication?.date})
            remove(newCommunicationsCopy, {date: startNewCommunication?.date})

            state.communications = [
                ...currentCommunicationsCopy,
                lastCurrentCommunication,
                ...newCommunicationsCopy
            ]
        }else{
            state.communications = state.communications.concat(action.payload)
        }
    },
    setCommunicationLoadedPageCount: (state, action) => {
        state.communicationLoadedPageCount = action.payload;
    },
    setProtocolCount: (state, action) => {
        state.protocolCount = action.payload;
    },
    setSorting: (state, { payload: { propertyName, profile = 'default' }}) => {
        let targetObject = state.tableSettings.sorting[profile].find(item => item.propertyName === propertyName);
        if(targetObject){
            targetObject.direction = (targetObject.direction === 1) ? 0 : 1;
        }else{
            state.tableSettings.sorting[profile] = [{
                propertyName,
                direction: 1
            }]
        }
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    setEncounterStatistics: (state, action) => {
        state.encounterStatistics = action.payload;
    },
    setAllCommunicationsLoaded: (state, action) => {
        state.allCommunicationsLoaded = action.payload;
    },
    setScrollToCommunication: (state, action) => {
        state.scrollToCommunication = action.payload;
    },
    setScrollToCommunicationWithId: (state, action) => {

        let findedCommunication = {};
        let listIndexCounter = -1;

        if(!isEmpty(state.communications) && action.payload){
            state.communications.forEach((communication, index) => {
                listIndexCounter++;
                communication.messages.forEach((message, messageIndex) => {
                    listIndexCounter++;
                    if(message.id === action.payload) findedCommunication = {listIndex: listIndexCounter};
                })
            })
        }

        if(!isEmpty(findedCommunication)) state.scrollToCommunication = findedCommunication;
    },
    findCommunicationsByString: (state, action) => {

        let findedCommunications = [];
        let listIndexCounter = -1;

        if(!isEmpty(state.communications) && action.payload){
            state.communications.forEach((communication, index) => {
                listIndexCounter++;
                communication.messages.forEach((message, messageIndex) => {
                    listIndexCounter++;
                    message.text.toLowerCase().includes(action.payload.toLowerCase()) && findedCommunications.push({communication, communicationIndex: index , messageIndex, listIndex: listIndexCounter});
                })
            })
        }

        state.findedCommunication = findedCommunications;
        state.findedCommunicationCount = findedCommunications.length;
    },
    findCommunicationsByDate: (state, action) => {

        let findedCommunications = [];
        let listIndexCounter = 0;

        const date = dayjs(new Date(action.payload.year, action.payload.month, action.payload.day));

        if(!isEmpty(state.communications) && action.payload){
            state.communications.forEach((communication, index) => {
                const communicationDate = dayjs(communication.date);
                date.isSame(communicationDate, 'day') && findedCommunications.push({communication, index, messageIndex: null, listIndex: listIndexCounter});
                listIndexCounter += (communication?.messages?.length + 1)
            })
        }

        state.findedCommunication = findedCommunications;
    },
    clearFindedCommunicationsData: (state, action) => {
        state.findedCommunication = [];
        state.findedCommunicationCount = 0;
    },
    setCounselingHistory: (state, action) => {
        state.counselingHistory = action.payload;
    },
    resetCommunicationsState: (state, action) => {
        state.communications = [];
        state.messageTotalCount = null;
        state.communicationLoadedPageCount = 0;
        state.allCommunicationsLoaded = false;
        state.scrollToCommunication = null;
        state.findedCommunication = [];
        state.findedCommunicationCount = 0;
    },
    setCarouselImages:  (state, action) => {
        const communictionsCopy = cloneDeep(state.communications);
        const filteredImages = [];
        let imageIndex = 0;
        communictionsCopy.forEach(item => {
            item.messages.forEach(message => {
                if(!isEmpty(message?.images)){
                    // Формируем объект с файлами
                    const arr = [];
                    let i = 0;
                    message?.images.forEach(itm => {
                        if (get(itm, "contentAttachment")) {
                            if (
                                get(itm, "contentAttachment.contentType") === "image/jpeg"
                            ) {
                                arr.push({
                                    ...itm?.contentAttachment,
                                    index: imageIndex,
                                    indexInArray: i,
                                    parentId: message?.id,
                                });
                                imageIndex++;
                                i++;
                            }
                        }
                    })
                    if (!isEmpty(arr)) filteredImages.push({
                        images: arr,
                        id: message?.id,
                        date: dayjs(message?.sent).locale('ru').format('D MMMM YYYY') + " г.",
                        time: dayjs(message?.sent).format('H:mm'),
                        senderName: message?.sender?.name
                    });
                }
            });
        });
        state.carousel.carouselImages = filteredImages;
        state.carousel.carouselLength = imageIndex;
    },
    setPreviousImage: (state, action) => {
        const currentId = state.carousel.activeMessageId;
        const currentIndexInArray = state.carousel.activeImageIndex;
        const currentMessage = state.carousel.carouselImages.find(item => item.id === currentId);
        const currentIndex = currentMessage.images[currentIndexInArray].index;
        let prevIndex = (currentIndex - 1) < 0 ? (state.carousel.carouselLength - 1) : currentIndex - 1;

        let prevIndexInArray = null;
        let prevMessageId = null;

        state.carousel.carouselImages.forEach(item => {
            if (!prevMessageId) {
                const found = item.images.find(itm => {
                    return itm.index === prevIndex;
                });

                if (found) {
                    prevIndexInArray = found.indexInArray;
                    prevMessageId = item.id;
                }
            }
        });

        state.carousel.activeMessageId = prevMessageId;
        state.carousel.activeImageIndex = prevIndexInArray;
    },
    setNextImage: (state, action) => {
        const currentId = state.carousel.activeMessageId;
        const currentIndexInArray = state.carousel.activeImageIndex;
        const currentMessage = state.carousel.carouselImages.find(item => item.id === currentId);
        const currentIndex = currentMessage.images[currentIndexInArray].index;
        let nextIndex = (currentIndex + 1) > (state.carousel.carouselLength - 1) ? 0 : currentIndex + 1;

        console.log(currentId, currentIndexInArray, currentMessage, currentIndex);

        let nextIndexInArray = null;
        let nextMessageId = null;

        state.carousel.carouselImages.forEach(item => {
            if (!nextMessageId) {
                const found = item.images.find(itm => {
                    return itm.index === nextIndex;
                });

                if (found) {
                    nextIndexInArray = found.indexInArray;
                    nextMessageId = item.id;
                }
            }
        });

        state.carousel.activeMessageId = nextMessageId;
        state.carousel.activeImageIndex = nextIndexInArray;
    },
    setImageSlideId: (state, action) => {
        state.carousel.imageSlideId = action.payload;
    },
    setCarouselActiveMessageId: (state, action) => {
        state.carousel.activeMessageId = action.payload;
    },
    setCarouselActiveImageIndex: (state, action) => {
        state.carousel.activeImageIndex = action.payload;
    },
    setCarouselActiveSender: (state, action) => {
        state.carousel.activeSender = action.payload;
    },
    clearDataState: (state, action) => {
        state.totalItems = null;
        state.practitioner = null;
        state.organization = null;
        state.practitionerRole = null;
        state.tasks = null;
        state.condition = null;
        state.protocolCount = 0;
        state.encounterStatistics = null;
        state.type = null
    },
    clearCounselingHistoryState: (state, action) => {
        state.counselingHistory = [];
        state.loading.counselingHistory = true;
    },
    resetState: (state, action) => {
        return initialState
    },
    setCondition: (state, action) => {
        state.condition = action.payload;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectPractitioner: state => get(state, statePath)?.practitioner,
    selectOrganization: state => get(state, statePath)?.organization,
    selectPractitionerRole: state => get(state, statePath)?.practitionerRole,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting.default,
    selectPractitionerSorting: state => get(state, statePath)?.tableSettings.sorting.practitioner,
    selectCommunicationSorting: state => get(state, statePath)?.tableSettings.sorting.communication,
    selectLoading: state => get(state, statePath)?.loading,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectType: state => get(state, statePath)?.type,
    selectCondition: state => get(state, statePath)?.condition,
    selectTasks: state => get(state, statePath)?.tasks,
    selectEncounterStatistics: state => get(state, statePath)?.encounterStatistics,
    selectCommunications: state => get(state, statePath)?.communications,
    selectMessageTotalCount: state => get(state, statePath)?.messageTotalCount,
    selectCommunicationLoadedPageCount: state => get(state, statePath)?.communicationLoadedPageCount,
    selectProtocolCount: state => get(state, statePath)?.protocolCount,
    selectAllCommunicationsLoaded: state => get(state, statePath)?.allCommunicationsLoaded,
    selectScrollToCommunication: state => get(state, statePath)?.scrollToCommunication,
    selectFindedCommunication: state => get(state, statePath)?.findedCommunication,
    selectFindedCommunicationCount: state => get(state, statePath)?.findedCommunicationCount,
    selectTotalItems: state => get(state, statePath)?.totalItems,
    selectCounselingHistory: state => get(state, statePath)?.counselingHistory,
    selectPaging: state => get(state, statePath)?.tableSettings.paging,
    selectCarousel: state => get(state, statePath)?.carousel,
})

export const getThunks = (actions) => {

    const getPractitionerRole = ({practitionerRoleId, fieldsFilter, sorting}) => {
        return (dispatch) => {
    
            dispatch(actions.setLoading({
                type: 'practitionerRole',
                value: true
            }))
    
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            id: practitionerRoleId,
                            _include: 'practitioner, organization'
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');
    
                const type = findInTypeBySystem(get(data, `resources[${get(item, "organization.reference")}].type`), 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code === '2' ? 'gynecology' : 'oncology';
                dispatch(actions.setType(type));
    
                dispatch(actions.setPractitioner({
                    name: get(data, `resources[${get(item, "practitioner.reference")}].name[0].text`)
                }));
    
                dispatch(actions.setPractitionerRole(item))
                dispatch(actions.setLoading({
                    type: 'practitionerRole',
                    value: false
                }))
            }).catch(err => {
    
                dispatch(registerMessage({name: 'channel-loading-practitioner-role-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить роль врача', closable: true}))
                dispatch(showMessage('channel-loading-practitioner-role-message'))
                dispatch(closeMessage({name: 'channel-loading-practitioner-role-message', delay: systemMessageDelay}))
    
                dispatch(actions.setLoading({
                    type: 'practitionerRole',
                    value: false
                }))
    
                console.log(err)
            });
        };
    };

    const getEncounter = ({encounterId}) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.encounter}`,
                params: {
                    fg: `id::${encounterId}:x:_include::Encounter:service-provider:Organization`,
                }
            }).then((response) => {

                const data = get(response,'data.items[0]');
                const resources = get(response,'data.resources');
                const organization = get(data, 'serviceProvider.id');
                const organizationReference = get(data, 'serviceProvider.reference');
                const patient = get(data, 'subject.id')
                const statusReason = find(get(data, 'extension'), { url: "http://miramedix.ru/fhir/StructureDefinition/channel-status-reason" })?.valueCoding?.display

                const serviceProvider = get(resources, organizationReference);

                dispatch(actions.setOrganization({
                    active: serviceProvider?.active,
                    name: serviceProvider?.name,
                    id: serviceProvider?.id
                }))
    
                fetchInstance({
                    method: "POST",
                    url: `${api.organizationPatientsStatistic}/${organization}/Encounters`,
                    data: {
                        paging:{
                            startIndex: 0,
                            maxItems: 10
                        },
                        filtering:{
                            searchString: null,
                            fieldsFilter: {
                                patientId: patient,
                                ids: encounterId
                            }
                        },
                    }
                }).then(response => {
                    const data = get(response,'data');
                    const item = find(data?.items, {id: encounterId});
    
                    dispatch(actions.setEncounterStatistics({...item, statusReason}))
                    dispatch(actions.setLoading({
                        type: 'encounterStatistics',
                        value: false
                    }))
                }).catch(err => {
    
                    dispatch(registerMessage({name: 'channel-loading-encounter-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить информацию о канале', closable: true}))
                    dispatch(showMessage('channel-loading-encounter-message'))
                    dispatch(closeMessage({name: 'channel-loading-encounter-message', delay: systemMessageDelay}))
        
                    dispatch(actions.setLoading({
                        type: 'encounterStatistics',
                        value: false
                    }))
    
                    console.log(err)
                })
            }).catch(err => {
    
                dispatch(registerMessage({name: 'channel-loading-encounter-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить информацию о канале', closable: true}))
                dispatch(showMessage('channel-loading-encounter-message'))
                dispatch(closeMessage({name: 'channel-loading-encounter-message', delay: systemMessageDelay}))
    
                dispatch(actions.setLoading({
                    type: 'encounterStatistics',
                    value: false
                }))
    
                console.log(err)
            });
        };
    };

    const getTasks = ({encounterId, fieldsFilter, sorting, paging: {startIndex = 0, maxItems = 10}}) => {
        return (dispatch) => {
    
            dispatch(actions.setLoading({
                type: 'tasks',
                value: true
            }))
    
            fetchInstance({
                method: "POST",
                url: `${api.encounter}/${encounterId}/Tasks`,
                data: {
                    paging:{
                        startIndex,
                        maxItems
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                        }
                    },
                    sorting,
                }
            }).then((response) => {
    
                const data = get(response,'data');
                const items = get(data, 'items');
    
                dispatch(actions.setProtocolCount(get(data, 'clientQuery.paging.totalItems', 0)));
                dispatch(actions.setTotalItems(get(data, 'clientQuery.paging.totalItems', 1)));
                dispatch(actions.setTasks(items));
                dispatch(actions.setLoading({
                    type: 'tasks',
                    value: false
                }))
            }).catch(err => {
    
                dispatch(registerMessage({name: 'channel-loading-tasks-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить мероприятия', closable: true}))
                dispatch(showMessage('channel-loading-tasks-message'))
                dispatch(closeMessage({name: 'channel-loading-tasks-message', delay: systemMessageDelay}))
    
                dispatch(actions.setLoading({
                    type: 'tasks',
                    value: false
                }))
    
                console.log(err)
            });
        };
    };

    const getCommunications = ({encounterId, fieldsFilter, sorting, startIndex = 0, maxItems = 100}) => {
        return (dispatch) => {
    
            maxItems === 0 ?
            dispatch(actions.setLoading({
                type: 'communications',
                value: true
            }))
            :
            dispatch(actions.setLoading({
                type: 'nextPageCommunication',
                value: true
            }))
    
            fetchInstance({
                method: "POST",
                url: `${api.encounter}/${encounterId}/Communications`,
                data: {
                    paging:{
                        startIndex,
                        maxItems
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const items = get(data, 'items');
                const practitioners = get(data, 'practitionerByRoleId');
                const patient = get(data, 'patients[0]');
    
                const communications = handleCommunications(items, practitioners, patient)
    
                //Если загружаем все элементы(maxItems === 0), то не добавляем, а перезаписываем список
                maxItems === 0 ? dispatch(actions.setCommunications(communications)) : dispatch(actions.addCommunication(communications));
                dispatch(actions.setMessageTotalCount(get(data, 'clientQuery.paging.totalItems')));
    
                if(maxItems === 0 || (get(data, 'clientQuery.paging.totalItems') <= (startIndex + maxItems))){
                    dispatch(actions.setAllCommunicationsLoaded(true))
                }
    
                dispatch(actions.setCarouselImages()); //Обновляем изображения карусели при подгрузке/полной загрузке сообщений
    
                maxItems === 0 ?
                dispatch(actions.setLoading({
                    type: 'communications',
                    value: false
                }))
                :
                dispatch(actions.setLoading({
                    type: 'nextPageCommunication',
                    value: false
                }))
            }).catch(err => {
    
                dispatch(registerMessage({name: 'channel-loading-communication-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить сообщения', closable: true}))
                dispatch(showMessage('channel-loading-communication-message'))
                dispatch(closeMessage({name: 'channel-loading-communication-message', delay: systemMessageDelay}))
    
                dispatch(actions.setLoading({
                    type: 'communications',
                    value: false
                }))
                dispatch(actions.setLoading({
                    type: 'nextPageCommunication',
                    value: false
                }))
    
                console.log(err);
            });
        };
    };

    const handleCommunications = (communications, practitioners, patient) => {
    
        let modifiedData = [];
    
        const cloneCommunications = cloneDeep(communications)
    
        for(let item of cloneCommunications){
    
            const recipient = item?.recipient[0].reference.includes('Patient') ?
            {
                name: get(patient, 'name[0].text'),
                type: 'patient'
            } : item?.recipient[0].reference.includes('PractitionerRole') ?
            {
                name: get(practitioners, `${[item?.recipient[0].id]}.name[0].text`),
                photo: get(practitioners, `${[item?.recipient[0].id]}.photo[0].url`),
                type: 'doctor'
            } : undefined;
    
            const sender = item?.sender?.reference.includes('Patient') ?
            {
                name: get(patient, 'name[0].text'),
                type: 'patient'
            } : item?.sender?.reference.includes('PractitionerRole') ?
            {
                name: get(practitioners, `${[item?.sender?.id]}.name[0].text`),
                photo: get(practitioners, `${[item?.sender?.id]}.photo[0].url`),
                type: 'doctor'
            } : undefined;
    
            const sent = get(item, 'sent');
            const date = sent && dayjs(sent).format('YYYY-MM-DD');
    
            const files = filter(item?.payload, {contentAttachment: {contentType: "application/pdf"}})
            const images = filter(item?.payload, (itm) => (!!itm.contentAttachment && itm?.contentAttachment?.contentType !== "application/pdf"))
    
            let replyMessage;
            const replyMessageId = get(item, "inResponseTo[0].reference", '').replace("Communication/", '');
            if (replyMessageId) {
                replyMessage = cloneCommunications.find(item => get(item, "id") === replyMessageId);
            }
    
            const message = {
                id: get(item, 'id'),
                category: findInTypeBySystem(get(item, 'category'), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-message-category')?.code,
                text: find(item?.payload, 'contentString')?.contentString || '',
                recipientId: get(item, 'recipient[0].id'),
                senderId: get(item, 'sender.id'),
                recipient,
                sender,
                status: get(item, 'status'),
                sent,
                files,
                images,
                payload: get(item, 'payload'),
                overdue: find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/message-overdue-flag"})?.valueBoolean,
                replyMessageId: get(item, "inResponseTo[0].reference"),
                replyMessageContentAttachment: get(replyMessage, "payload"),
                replyAuthor: get(item, "sender.reference") ? get(item, "sender.reference").indexOf("PractitionerRole") === -1 ? "patient" : "doctor" : false,
                replyAuthorName: get(practitioners, `${[item?.sender?.id]}.name[0].text`),
                replyMessage: get(replyMessage, "payload[0].contentString")
            }
    
            const dateExists = find(modifiedData, {date: date})
    
            if(!dateExists){
                modifiedData.push(
                    {
                        date,
                        messages:[{...message}]
                    }
                )
            }else{
                dateExists?.messages?.push(message)
            }
    
        }
    
        return modifiedData;
    };

    const getCounselingHistory = ({encounterId}) => {
        return (dispatch) => {
    
            dispatch(actions.setLoading({
                type: 'counselingHistory',
                value: true
            }))
    
            fetchInstance({
                method: "POST",
                url: `${api.encounter}/${encounterId}/Consultations`,
                data: {
                    paging: {
                      startIndex: 0,
                      maxItems: 0,
                    },
                    filtering: {
                      searchString: null,
                      fieldsFilter: {}
                    }
                  }
            }).then((response) => {
                const data = get(response,'data');
                const items = get(data, 'items');
    
                dispatch(actions.setCounselingHistory(items));
                dispatch(actions.setLoading({
                    type: 'counselingHistory',
                    value: false
                }))
            }).catch(err => {
                
                dispatch(registerMessage({name: 'channel-loading-counseling-history-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить историю консультирования', closable: true}))
                dispatch(showMessage('channel-loading-counseling-history-message'))
                dispatch(closeMessage({name: 'channel-loading-counseling-history-message', delay: systemMessageDelay}))
    
                dispatch(actions.setLoading({
                    type: 'counselingHistory',
                    value: false
                }))
    
                console.log(err);
            });
        };
    }

    const getCondition = ({ conditionId }) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'condition',
                value: true
            }));
            fetchInstance({
                method: "GET",
                url: `${api.condition}/${conditionId}`,
            }).then((response) => {
    
                dispatch(actions.setCondition(response?.data))
    
                dispatch(actions.setLoading({
                    type: 'condition',
                    value: false
                }));
            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'condition',
                    value: false
                }));
            });
        };
    };

    return { getPractitionerRole, getEncounter, getTasks, getCommunications, handleCommunications, getCounselingHistory, getCondition }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        ChannelActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };