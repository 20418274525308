import styled, {css} from "styled-components";

const Separator = styled.div`
  ${({vertical}) => vertical && css`
    width: 1px;
    height: 20px;
    background-color: ${({theme}) => theme.colors.black12};
    margin-left: ${({left}) => left}px;
    margin-right: ${({right}) => right}px;
  `};
  
  ${({horizontal}) => horizontal && css`

  `};
`

export {Separator}
