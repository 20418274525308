import React from 'react'; 
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import Error from "../Error";
import {Wrapper} from "styled/Wrapper";
import InputMask from "react-input-mask";

const PhoneInput = (props) => {
    return (
        <Wrapper paddings={props.label && '22px 0 0 0'}>
            <Field>
                {props.label && <Label
                    error={props.showError}
                    >
                    {props.label}
                    {props.required &&
                        <i> *</i>
                    }
                </Label>}
                <InputWrapper>
                    <InputMask
                        mask={props.mask}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        {...props}
                    >
                    </InputMask>
                </InputWrapper>
            </Field>
            <ErrorWrapper>
                <Error
                    message={props.error} show={props.showError}
                />
            </ErrorWrapper>
        </Wrapper>
    );
};

const Field = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.black04};
  ${({theme}) => theme.mixins.borderRadius()};
  padding: 6px 10px;
  
  ${({underline}) => underline && css`
    ${({theme}) => theme.mixins.borderRadius(`${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`)};
  `};

  box-shadow: ${({theme, underline}) => underline ? `0px 2px 0px ${theme.colors.primary}` : 'none'};

  :hover {
    background-color: ${({theme}) => theme.colors.black08};
  }
`

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};
  position: absolute;
  left: 0;
  top: -22px;

  ${({error}) => error && css`
    color: ${({theme}) => theme.colors.red};
  `};
  
  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const calculatePadding = (position, decorationPosition) => {
    return `0 ${position === 'right' || decorationPosition === 'right' ? '24px' : 0} 0 ${position === 'left' || decorationPosition === 'left' ? '24px' : 0}`
}

const InputWrapper = styled.div`
  width: 100%;
  
  input {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: ${({position, decorationPosition}) => calculatePadding(position, decorationPosition)};
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.isMobile && css`
        min-height: 28px;
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5})}
    `}
    ::placeholder {
      color: ${({theme}) => theme.colors.black30};
    }
  }
`

const ErrorWrapper = styled.div`
  
`

// const renderIcon = (position) => {
//     switch (position) {
//         case 'left':
//             return css`
//                 left: 8px;
//                 top: 7px;
//             `
//         case 'right':
//             return css`
//                 right: 8px;
//                 top: 7px;
//             `
//         default:
//             return css`
//                 left: 8px;
//                 top: 7px;
//             `
//     }
// }

PhoneInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.element,
    decorationIcon: PropTypes.element,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    decorationIconPosition: PropTypes.oneOf(['left', 'right']),
    iconAction: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    iconShowOnType: PropTypes.bool,
};

PhoneInput.defaultProps = {
    type: 'text',
    iconPosition: 'right',
    decorationIconPosition: 'left',
    iconAction: 'undefined',
    iconShowOnType: false,
}

export default PhoneInput;
