import React from 'react';

const PrintIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 11.5C19 12.0523 18.5523 12.5 18 12.5C17.4477 12.5 17 12.0523 17 11.5C17 10.9477 17.4477 10.5 18 10.5C18.5523 10.5 19 10.9477 19 11.5Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18 8H19C20.66 8 22 9.34 22 11V15C22 16.1046 21.1046 17 20 17H18V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V17H4C2.89543 17 2 16.1046 2 15V11C2 9.34 3.34 8 5 8H6V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V8ZM16 5H8V8H16V5ZM8 19H16V15H8V19ZM18 13V15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18Z"/>
    </svg>
)

export default PrintIcon;
