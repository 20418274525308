import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'styled/Wrapper';
import dayjs from 'dayjs';
import Group from 'components/Group';
import Button from 'components/Button';
import NativeDateInput from 'components/NativeDateInput';

const AppointmentMobile = ({hideModal, onAccept, appointmentDate}) => {

    const [date, setDate] = useState(appointmentDate);

    const handleApply = () => {
        onAccept && onAccept(date);
        hideModal();
    }

  return (
        <Wrapper>
            <Wrapper paddings={'0 16px'}>
                <NativeDateInput dateTemplate='D MMMM YYYY, HH:mm' inputType="datetime-local" onChange={(value) => setDate(dayjs(value))} from={dayjs()} defaultValue={appointmentDate}/>
            </Wrapper>
            <Wrapper paddings={'14px 4px 8px 4px'}>
                <Group gap={8}  justify="space-between">
                    <Group.Item>
                        <Button type="link" buttonType="link" onClick={() => hideModal()} label={'Отмена'} />
                    </Group.Item>
                    <Group.Item>
                        <Button type="link" buttonType="link" onClick={handleApply} label={'Выбрать'} />
                    </Group.Item>
                </Group>
            </Wrapper>
        </Wrapper>
    )
};

AppointmentMobile.propTypes = {
    hideModal: PropTypes.func
};

export default AppointmentMobile;
