import { cloneDeep } from "lodash";

const commonReducers = (initialState) => ({
    setData: (state, action) => {
        state.data = action.payload;
    },
    clearData: (state, action) => {
        state.data = initialState.data;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setSorting: (state, { payload: { propertyName, profile = 'default' }}) => {
        let targetObject = state.tableSettings.sorting[profile].find(item => item.propertyName === propertyName);
        if(targetObject){
            targetObject.direction = (targetObject.direction === 1) ? 0 : 1;
        }else{
            state.tableSettings.sorting[profile] = [{
                propertyName,
                direction: 1
            }]
        }
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
})

export default commonReducers;