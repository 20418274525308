import React from 'react'; 
import {useDispatch, useSelector} from "react-redux";
import styled, {css, keyframes, useTheme} from "styled-components";
import Icon from "components/Icon";
import {renderColor} from "utils/renderColor";
import CheckIcon from "components/Icons/CheckIcon";
import AlertIcon from "components/Icons/AlertIcon";
import ClearIcon from "components/Icons/ClearIcon";
import {hideMessage} from "reducers/systemMessages/systemMessagesSlice";
import {useHistory} from "react-router-dom";
import { isNumber } from 'lodash';
import { systemMessagesAnimationDuration } from 'consts';
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import CheckInRoundIcon from "../Icons/CheckInRoundIcon";

const SystemMessages = () => {

    const messages = useSelector(state => state.systemMessages)
    const dispatch = useDispatch()
    const history = useHistory();

    const theme = useTheme();

    const renderIcon = (type) => {
       switch (type) {
           case 'red':
               return AlertIcon
           case 'primary':
               if (theme.isMobile) {
                   return CheckInRoundIcon
               }
               else {
                   return CheckIcon
               }
           default:
               return null
       }
    }

    const handleClick = (url) => {
        if (url) {
            history.push({
                pathname: url,
            });
        }
    }

//{console.log("%crender", 'color: white; background-color: green;')}
//{console.log('Name:', item.name, 'toOpen:', item.toOpen, 'toHide:', item.toHide, 'Position:', item.position, 'PrevPosition:', item.prevPosition)}
    return (
        <>
            {messages.map((item, idx) => (
                <>
                    <CustomView condition={isDesktop || isTablet}>
                        <SystemMessageWrapper position={item.position} type={item.type} onClick={() => handleClick(item.url)} url={item.url} toOpen={item.toOpen} toHide={item.toHide} prevPosition={item.prevPosition}>
                            <Border type={item.type} />
                            <IconWrapper type={item.type}>
                                <Icon icon={renderIcon(item.type)} color={renderColor(theme, item.type)} size={24} />
                            </IconWrapper>
                            <Content>
                                <Title>
                                    {item.data.title}
                                </Title>
                                <Message>
                                    {item.data.text}
                                </Message>
                            </Content>
                            {item.closable &&
                            <Close>
                                <Icon icon={ClearIcon} size={24} action={() => dispatch(hideMessage(item.name))} />
                            </Close>
                            }
                        </SystemMessageWrapper>
                    </CustomView>
                    <MobileOnlyView>
                        <SystemMessageWrapper position={item.position} type={item.type} onClick={() => handleClick(item.url)} url={item.url} toOpen={item.toOpen} toHide={item.toHide} prevPosition={item.prevPosition}>
                            <IconWrapper type={item.type}>
                                <Icon icon={renderIcon(item.type)} color={renderColor(theme, item.type === 'primary' && theme.isMobile ? 'green' : item.type)} size={24} />
                            </IconWrapper>
                            <Content>
                                <Title>
                                    {item.data.title}
                                </Title>
                                <Message>
                                    {item.data.text}
                                </Message>
                            </Content>
                            {item.closable &&
                            <Close>
                                <Icon icon={ClearIcon} size={24} color={theme.colors.white100} action={() => dispatch(hideMessage(item.name))} />
                            </Close>
                            }
                        </SystemMessageWrapper>
                    </MobileOnlyView>
                </>
            ))}
        </>
    );
};

const Close = styled.div`
    margin-right: 16px;
    
    ${({theme}) => theme.isMobile && css`
        margin: 9px;
    `}
`

const IconWrapper = styled.div`
    margin-left: 16px;
    margin-right: 12px;
    
    ${({theme}) => theme.isMobile && css`
        margin: 12px 14px;
        position: relative;
        min-width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
            
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: ${({theme, type}) => renderColor(theme, type === 'primary' && theme.isMobile ? 'green' : type)};
            opacity: 0.12;
        }
  `}
`

const Content = styled.div`
    padding: 12px 16px 12px 0;
    flex: 1 1 auto;
`

const Title = styled.div`
    ${({theme}) => theme.mixins.robotoMedium()};
    
    ${({theme}) => theme.isMobile && css`
        color: ${({theme}) => theme.colors.white100};
        font-weight: normal;
  `}
`

const Message = styled.div`
  ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54, size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small})};
  
  ${({theme}) => theme.isMobile && css`
        color: ${({theme}) => theme.colors.white100};
        ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.white70, size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small})};
  `}
`

const Border = styled.div`
    width: 5px;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: ${({theme, type}) => renderColor(theme, type)};
`

const inAnimation = keyframes`
    0% { right: -350px; }
    100% { right: 16px;}
`

const outAnimation = keyframes`
    0% { right: 16px;}
    100% { right: -350px;}
`

const moveAnimation = (prevPosition, position) => keyframes`
    0% { ${(isNumber(prevPosition) && isNumber(position)) ? `top: ${78 + (64 * prevPosition) + (16 * prevPosition)}px}` : ''} }
    100% { ${(isNumber(prevPosition) && isNumber(position)) ? `top: ${78 + (64 * position) + (16 * position)}px}` : ''} }
`

const SystemMessageWrapper = styled.div`
    position: fixed;
    width: 320px;
    right: 16px;
    top: ${({theme, position}) => 78 + (64 * position) + (16 * position)}px;
    display: flex;
    align-items: center;
    background-color: ${({theme}) => theme.colors.white100};
    border: 1px solid rgba(0,0,0,.02);
    border-radius: ${({theme}) => theme.other.borderRadius};
    ${({theme}) => theme.mixins.shadowDark8dp()};
    z-index: 100000;
    overflow: hidden;
    cursor: ${({url}) => url ? 'pointer' : 'default'};
    animation-name: ${({toOpen, toHide, prevPosition, position}) => (!toOpen && !toHide) ? moveAnimation(prevPosition, position) : toOpen ? inAnimation : outAnimation};
    animation-duration: ${systemMessagesAnimationDuration}ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
    ${({theme}) => theme.isMobile && css`
        width: calc(100% - 32px);
        background-color: ${({theme}) => theme.colors.tooltip};
        bottom: 16px;
        right: 16px;
        top: unset;
        align-items: flex-start;
    `}
`

export default SystemMessages;
