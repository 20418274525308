import {createSlice} from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {isEmpty, get, isNumber, last, find, cloneDeep} from "lodash";
// import dayjs from "dayjs";
import { findInTypeBySystem, getTrimesterByWeeks } from 'utils';
import { closeMessage, registerMessage, showMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { routes } from 'consts/routes';
// import { getAccounts } from './accounts';
import { responseTime, systemMessageDelay } from 'consts';

const initialState = {
    tableSettings: {
        fieldsFilter: {
        },
        paging: {
            startIndex: 0,
            maxItems: 25,
        },
        sorting: {
            default: [
                {
                    propertyName: "patient",
                    direction: 0,
                }
            ],
            practitioner: [
                {
                    propertyName: "Practitioner.name",
                    direction: 0,
                }
            ]
        },
        searchString: ''
    },
    loading:{
        fullPage: true,
        practitioner: true,
        data: true,
        genders: true,
        conditions: true,
        updateCarePlans: true,
        practitionerRole: true,
        organization: true,
        answerTimingRule: true
    },
    modalAnswerTimingRule: null,
    modalPractitionerData: null,
    timeoutMinutes: responseTime[0].value,
    practitioner: null,
    practitionerRole: null,
    organization: null,
    data: null,
    totalItems: null,
    channelsCount: 0,
    activeChannelsCount: 0,
    archiveChannelsCount: 0,
    patientsCount: 0,
    type: null,
    patientsForModal: [],
    doctorsForModal: [],
}

const reducers = {
    setPractitioner: (state, action) => {
        state.practitioner = action.payload;
    },
    setPractitionerPhoto: (state, action) => {
        if(state.practitioner.practitionerData)
            state.practitioner.practitionerData.photo = action.payload
    },
    setPractitionerRole: (state, action) => {
        state.practitionerRole = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    resetLoadingState: (state, action) => {
        state.loading = initialState?.loading;
    },
    setChannelsCount: (state, action) => {
        state.channelsCount = action.payload;
    },
    setActiveChannelsCount: (state, action) => {
        state.activeChannelsCount = action.payload;
    },
    setArchiveChannelsCount: (state, action) => {
        state.archiveChannelsCount = action.payload;
    },
    setPatientsCount: (state, action) => {
        state.patientsCount = action.payload;
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setSorting: (state, { payload: { propertyName, profile = 'default' }}) => {
        let targetObject = state.tableSettings.sorting[profile].find(item => item.propertyName === propertyName);
        if(targetObject){
            targetObject.direction = (targetObject.direction === 1) ? 0 : 1;
        }else{
            state.tableSettings.sorting[profile] = [{
                propertyName,
                direction: 1
            }]
        }
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    clearDataState: (state, action) => {
        state.practitioner = null;
        state.practitionerRole = null;
        state.data = null;
        state.totalItems = null;
        state.channelsCount = 0;
        state.activeChannelsCount = 0;
        state.patientsCount = 0;
        state.type = null;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
    setOrganization: (state, action) => {
        state.organization = action.payload
    },
    setPatientsForModal: (state, action) => {
        state.patientsForModal = action.payload;
    },
    setDoctorsForModal: (state, action) => {
        state.doctorsForModal = action.payload;
    },
    setAnswerTimeInterval: (state, action) => {
        state.answerTimeInterval = action.payload;
    },
    setModalAnswerTimingRule: (state, action) => {
        state.modalAnswerTimingRule = action.payload;
    },
    setModalPractitionerData: (state, action) => {
        state.modalPractitionerData = action.payload;
    },
    setTimeoutMinutes: (state, action) => {
        state.timeoutMinutes = action.payload;
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectPractitioner: state => get(state, statePath)?.practitioner,
    selectPractitionerRole: state => get(state, statePath)?.practitionerRole,
    selectPractitionerOrganization: state => get(state, statePath)?.organization,
    selectData: state => get(state, statePath)?.data,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting.default,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectPractitionerSorting: state => get(state, statePath)?.tableSettings.sorting.practitioner,
    selectLoading: state => get(state, statePath)?.loading,
    selectChannelsCount: state => get(state, statePath)?.channelsCount,
    selectTotalItems: state => get(state, statePath)?.totalItems,
    selectActiveChannelsCount: state => get(state, statePath)?.activeChannelsCount,
    selectArchiveChannelsCount: state => get(state, statePath)?.archiveChannelsCount,
    selectPatientsCount: state => get(state, statePath)?.patientsCount,
    selectType: state => get(state, statePath)?.type,
    selectPaging: state => get(state, statePath)?.tableSettings.paging,
    selectPatientsForModal: state => get(state, statePath)?.patientsForModal,
    selectDoctorsForModal: state => get(state, statePath)?.doctorsForModal,
    selectModalAnswerTimingRule: state => get(state, statePath)?.modalAnswerTimingRule,
    selectModalPractitionerData: state => get(state, statePath)?.modalPractitionerData
})

export const getThunks = (actions, thunksFromAnotherReducer) => {

    const getPractitioner = ({practitionerRole, organizationId, practitionerId, fieldsFilter, sorting}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'practitioner',
                value: true
            }))

            fetchInstance({
                method: "POST",
                url: `${api.practitioners}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            practitionerId: practitionerRole?.practitioner?.id || practitionerId,
                            organizationId: practitionerRole?.organization?.id || organizationId,
                            statType: 0
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');
                const activeChannelsCount = get(item, 'statistics.active');
                const archiveChannelsCount = get(item, 'statistics.archive');
                const inactiveChannelsCount = get(item, 'statistics.inActive');
                const channelsCount =
                    (isNumber(activeChannelsCount) ? activeChannelsCount : 0) +
                    (isNumber(archiveChannelsCount) ? archiveChannelsCount : 0)+
                    (isNumber(inactiveChannelsCount) ? inactiveChannelsCount : 0);

                dispatch(actions.setChannelsCount(channelsCount));
                dispatch(actions.setActiveChannelsCount(activeChannelsCount));
                dispatch(actions.setArchiveChannelsCount(archiveChannelsCount));

                dispatch(actions.setPractitioner({
                    id: get(item, "practitioner.id"),
                    roleId: get(item, `practitionerRole.id`),
                    name: get(item, "practitioner.name[0].text"),
                    practitionerData: {
                        active:get(item, "practitioner.active"),
                        speciality: findInTypeBySystem(get(item, `practitionerRole.specialty`), 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display,
                        status: {
                            text: find(get(item, `practitionerRole.extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.display,
                            value: find(get(item, `practitionerRole.extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-physician-status"})?.valueCoding?.code
                        },
                        gender: get(item, "practitioner.gender"),
                        code: find(get(item, `practitionerRole.identifier`), {system: "urn:mgfoms:szl:entity:practitionerrole:id"})?.value,
                        email: find(get(item, `practitionerRole.telecom`), {system: "email"})?.value,
                        phone: find(get(item, `practitionerRole.telecom`), {system: "phone"})?.value,
                        photo: get(item, "practitioner.photo[0].url"),
                        created: find(get(item, `practitionerRole.meta.extension`), {url: "ex:createdAt"})?.valueInstant,
                        edited: get(item, `practitionerRole.meta.lastUpdated`),
                        activatingDate: find(get(item, `practitionerRole.extension`), {url: "http://miramedix.ru/fhir/StructureDefinition/activation-date"})?.valueDateTime,
                    },
                    statistics: get(item, 'statistics'),
                    rawData: get(item, "practitioner")
                }));

                dispatch(actions.setLoading({
                    type: 'practitioner',
                    value: false
                }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-practitioner-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить информацию о враче', closable: true}))
                dispatch(showMessage('channels-loading-practitioner-message'))
                dispatch(closeMessage({name: 'channels-loading-practitioner-message', delay: systemMessageDelay}))

                dispatch(actions.setLoading({
                    type: 'practitioner',
                    value: false
                }))

                console.log(err);
            });
        };
    };


    const getPractitionerRole = ({practitionerRoleId, fieldsFilter, sorting}) => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'practitionerRole',
                value: true
            }))

            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            id: practitionerRoleId,
                            _include: 'practitioner,organization'
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');

                const type = findInTypeBySystem(get(data, `resources[${get(item, "organization.reference")}].type`), 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code === '2' ? 'gynecology' : 'oncology';
                dispatch(actions.setType(type));

                dispatch(actions.setPractitionerRole(item))
                dispatch(actions.setLoading({
                    type: 'practitionerRole',
                    value: false
                }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-practitioner-role-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить роль врача', closable: true}))
                dispatch(showMessage('channels-loading-practitioner-role-message'))
                dispatch(closeMessage({name: 'channels-loading-practitioner-role-message', delay: systemMessageDelay}))

                console.log(err)
            });
        };
    };

    const getData = ({roleId, fieldsFilter, sorting = [], paging: {startIndex = 0, maxItems = 10}, searchString, weekOfPregnancy, type, channelStatusReasons}) => {

        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            dispatch(actions.setData(null));

            fetchInstance({
                method: "POST",
                url: `${api.practitionerStatistic}/${roleId}/EncountersStatistics`,
                data: {
                    paging:{
                        startIndex,
                        maxItems
                    },
                    filtering:{
                        searchString,
                        fieldsFilter
                    },
                    sorting: [...sorting],
                }
            }).then((response) => {
                const data = get(response,'data');
                const items = get(data, 'items');
                let modifiedData = []

                if(!isEmpty(items)) {
                    modifiedData = items && items.map(item => {

                        return {
                            patientId: get(item, 'patientId'),
                            carePlanId: get(item, 'carePlanId'),
                            encounterId: get(item, 'id'),
                            name: get(item, 'patientName'),
                            gender: get(item, 'patientGender'),
                            birthDate: get(item, 'patientBirthDate'),
                            age: get(item, 'patientAge'),
                            protocol: get(item, 'protocolExecution'),
                            diagnosis: get(item, 'conditionName'),
                            trimester: (item.conditionOnSetDateTime && type === 'gynecology' && !isEmpty(weekOfPregnancy)) ?
                                getTrimesterByWeeks(get(item, 'week'), weekOfPregnancy) : undefined,
                            messages: {
                                questions: item?.totalFromPatient,
                                answers: item?.totalFromPractitioner,
                                expired: item?.notAnsweredAndOverdue,
                                allExpired: item?.notAnsweredOrAnsweredOverdue
                            },
                            status: get(item, 'status'),
                            statusReason: get(find(channelStatusReasons, { code: get(item, 'statusReason')}), 'display'),
                        }
                    })

                    dispatch(actions.setData([...modifiedData]));
                }

                dispatch(actions.setPatientsCount(get(data, 'clientQuery.paging.totalItems') || 0));
                dispatch(actions.setTotalItems(get(data, 'clientQuery.paging.totalItems', 1)));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-encounters-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить статистику каналов', closable: true}))
                dispatch(showMessage('channels-loading-encounters-message'))
                dispatch(closeMessage({name: 'channels-loading-encounters-message', delay: systemMessageDelay}))

                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));

                console.log(err);
            });
        };
    };

    const uploadAvatar = ({file, practitioner}) => {
        return (dispatch) => {

            let bodyFormData = new FormData();
            bodyFormData.append('files', file)

            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            }).then((response) => {
                const data = get(response, 'data');
                data && dispatch(putPractitionerAvatar({ url: data[0].url, practitioner }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-upload-avatar-message', type: 'red', title: 'Ошибка', text: 'Не удалось установить аватар', closable: true}))
                dispatch(showMessage('channels-upload-avatar-message'))
                dispatch(closeMessage({name: 'channels-upload-avatar-message', delay: systemMessageDelay}))

                console.log(err)
            });
        };
    };

    const putPractitionerAvatar = ({url, practitioner}) => {
        return (dispatch) => {
            practitioner &&
                fetchInstance({
                    method: "PUT",
                    url: `${api.practitioner}`,
                    data: {
                        ...practitioner?.rawData,
                        photo: [
                            {
                                url
                            }
                        ]
                    }
                }).then((response) => {
                    const photoUrl = get(response, 'data.photo[0].url')
                    photoUrl && dispatch(actions.setPractitionerPhoto(photoUrl))
                }).catch(err => {

                    dispatch(registerMessage({name: 'channels-put-avatar-message', type: 'red', title: 'Ошибка', text: 'Не удалось установить аватар', closable: true}))
                    dispatch(showMessage('channels-put-avatar-message'))
                    dispatch(closeMessage({name: 'channels-put-avatar-message', delay: systemMessageDelay}))

                    console.log(err)
                });
        };
    };

    const getOrganization = ({organizationId}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'organization',
                value: true
            }))

            fetchInstance({
                method: "GET",
                url: `${api.organization}/${organizationId}`,
            }).then((response) => {
                const data = get(response,'data');

                dispatch(actions.setOrganization(data));

                dispatch(actions.setLoading({
                    type: 'organization',
                    value: false
                }))
            }).catch(err => {

                dispatch(registerMessage({name: 'channels-loading-practitioner-organization-message', type: 'red', title: 'Ошибка', text: 'Не удалось загрузить информацию о организации врача', closable: true}))
                dispatch(showMessage('channels-loading-practitioner-organization-message'))
                dispatch(closeMessage({name: 'channels-loading-practitioner-organization-message', delay: systemMessageDelay}))

                dispatch(actions.setLoading({
                    type: 'organization',
                    value: false
                }))

                console.log(err);
            });
        };
    };

    const getPatientsForModal = (id) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerStatistic}/${id}/EncountersStatistics`,
            data: {
                paging: {
                startIndex: 0,
                maxItems: 0
            },
            filtering:{
                searchString: null,
                fieldsFilter: {
                    status: "planned, in-progress, onleave",
                }
            },
            sorting: []
            }}).then((response) => {
                dispatch(actions.setPatientsForModal(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getDoctorsForModal = ({
        organizationId,
        practitionerId
    }) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                searchString: '',
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        extended: true,
                        organization: organizationId,
                        approle: 'AppRole/doctor',
                        physicianstatus: "active",
                    }
                },
                sorting: [],
                paging: {
                    startIndex: 0, 
                    maxItems: 0
                }
            }}).then((response) => {
                const doctors = [];

                response.data.items.forEach(item => {
                    doctors.push({
                        id: response.data.resources[item.practitioner.reference].id,
                        practitionerRoleId: item.id,
                        display: response.data.resources[item.practitioner.reference].name[0].text,
                    })
                });

                const filtered = doctors.filter(item => {
                    return item.id !== practitionerId;
                })

                dispatch(actions.setDoctorsForModal(filtered));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const changePractitioner = ({formData, doctorRoleId, doctorId, handleHide, tableSettings, withDoctorDeletation, history, accountsTableSettings, organizationId, weekOfPregnancy, type, channelStatusReasons, accountsList}) => {
        // console.log("formData", formData);
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'replaceDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.changepractitioner}`,
                data: {
                    practitionerRoleId: formData.practitionerRoleId,
                    practitionerId: formData.practitionerId,
                    authorPractitionerRoleId: formData.authorPractitionerRoleId,
                    nonAvailabilityReason: formData.nonAvailabilityReason,
                    newPractitionerRoleId: formData.newPractitionerRoleId,
                    doctorSubstitution: formData.doctorSubstitution,
                }}).then((response) => {
                    const encounters = response.data.encounters;

                    dispatch(getStatisticUpdateStatus({
                        encounters, 
                        iteration: 30,
                        handleHide,
                        handleStopLoading: () => dispatch(actions.setLoading({
                            type: 'replaceDoctor',
                            value: false
                        })),
                        onCompleted: () => {
                            withDoctorDeletation && dispatch(deleteDoctor({
                                id: formData.practitionerId,
                                accountsTableSettings,
                                organizationId
                            }))
                            withDoctorDeletation && history.push({
                                pathname: routes.private.root.accounts.path,
                            });
                            accountsList && dispatch(thunksFromAnotherReducer?.getAccounts({tableSettings: accountsTableSettings}))
                        },
                        doctorRoleId,
                        doctorId,
                        tableSettings,
                        weekOfPregnancy,
                        type,
                        channelStatusReasons,
                        organizationId
                    }));
                }).catch((error) => { 
                    dispatch(actions.setLoading({
                        type: 'replaceDoctor',
                        value: false
                    }));
                    dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                    dispatch(showMessage('create-channel-error-message'))
                    dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const getStatisticUpdateStatus = ({encounters, iteration, handleHide, handleStopLoading, doctorRoleId, doctorId, tableSettings, onCompleted, weekOfPregnancy, type, channelStatusReasons, organizationId}) => {
        return (dispatch) => {
            const promises = [];

            encounters.forEach(item => {
                promises.push(
                    fetchInstance({
                        method: "GET",
                        url: `${api.statisticUpdateStatus}/${item}`,
                    })
                );
            });

            Promise.all(promises).then((values) => {
                const completed = values.every(elem => elem.data === "Completed");
                if (completed || !iteration) {
                    handleHide && handleHide();
                    handleStopLoading && handleStopLoading();
                    dispatch(getPractitionerRole({practitionerRoleId: doctorRoleId}));
                    // dispatch(getPractitioner({practitionerId: doctorId, organizationId}));
                    if (completed) {
                        onCompleted && onCompleted();
                        dispatch(getData({
                            fieldsFilter: tableSettings.fieldsFilter, 
                            sorting: tableSettings.sorting.default, 
                            paging: tableSettings.paging,
                            searchString: tableSettings.searchString,
                            roleId: doctorRoleId,
                            weekOfPregnancy,
                            type,
                            channelStatusReasons,
                        }));
                    }
                    if (!iteration) {
                        dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                        dispatch(showMessage('check-status-error-message'))
                        dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    }
                } else {
                    setTimeout(() => dispatch(getStatisticUpdateStatus({
                        encounters, 
                        iteration: --iteration, 
                        handleHide,
                        handleStopLoading, 
                        doctorRoleId,
                        doctorId,
                        tableSettings,
                        onCompleted,
                        weekOfPregnancy,
                        type,
                        channelStatusReasons,
                        organizationId
                    })), 1000);
                }
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const deleteDoctor = ({id, accountsTableSettings, organizationId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
                dispatch(registerMessage({name: 'delete-doctor-message', type: 'primary', title: 'Доктор удален', closable: true, url: routes.private.root.accounts.path}))
                dispatch(showMessage('delete-doctor-message'))
                dispatch(closeMessage({name: 'delete-doctor-message', delay: systemMessageDelay}))
                dispatch(thunksFromAnotherReducer?.getAccounts({tableSettings: accountsTableSettings}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({
                    name: 'delete-account-error-message',
                    type: 'red',
                    title: `Не удалось удалить учетную запись`,
                    text: get(err, 'response.data'),
                    closable: true,
                }))
                dispatch(showMessage('delete-account-error-message'))
                dispatch(closeMessage({name: 'delete-account-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const resumeWork = ({
        formData
    }) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'resumeDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.resumepractitioner}`,
                data: {
                    practitionerRoleId: formData.practitionerRoleId,
                    practitionerId: formData.practitionerId,
                    authorPractitionerRoleId: formData.authorPractitionerRoleId,
                }}).then((response) => {
                    dispatch(actions.setLoading({
                        type: 'resumeDoctor',
                        value: false
                    }));

                    dispatch(getPractitionerRole({practitionerRoleId: formData.practitionerRoleId}));
                }).catch((error) => { 
                    dispatch(actions.setLoading({
                        type: 'resumeDoctor',
                        value: false
                    }));
                    dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                    dispatch(showMessage('create-channel-error-message'))
                    dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const archiveAccount = ({item, tableSettings, weekOfPregnancy, type, channelStatusReasons}) => {
        return (dispatch) => {
        fetchInstance({
                method: "PUT",
                url: api.practitionerRole,
                data: item
            }).then((response) => {
                dispatch(getPractitionerRole({practitionerRoleId: item?.id}));
                dispatch(getData({
                    roleId: item?.id,
                    fieldsFilter: tableSettings?.fieldsFilter,
                    sorting: tableSettings?.sorting?.default,
                    paging: tableSettings?.paging,
                    searchString: tableSettings?.searchString,
                    weekOfPregnancy,
                    type,
                    channelStatusReasons
                }));
            }).catch(error => {
                console.log("error", error);
            });
        };
    };

    const restoreAccount = ({id, tableSettings, weekOfPregnancy, type, channelStatusReasons}) => {
        return (dispatch) => {
        fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/RestoreFromArchive`
            }).then((response) => {
                dispatch(getPractitionerRole({practitionerRoleId: id}));
                dispatch(getData({
                    roleId: id,
                    fieldsFilter: tableSettings?.fieldsFilter,
                    sorting: tableSettings?.sorting?.default,
                    paging: tableSettings?.paging,
                    searchString: tableSettings?.searchString,
                    weekOfPregnancy,
                    type,
                    channelStatusReasons
                }));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getAnswerTimingRule = id => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'answerTimingRule',
                value: true
            }));

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/AnswerTimingRule`
                }).then((response) => {
                    dispatch(dispatch(actions.setModalAnswerTimingRule(response.data)));
                    if (get(response.data, "rule.timeoutMinutes")) dispatch(dispatch(actions.setTimeoutMinutes(get(response.data, "rule.timeoutMinutes", ''))));

                    dispatch(actions.setLoading({
                        type: 'answerTimingRule',
                        value: false
                    }));

            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'answerTimingRule',
                    value: false
                }));
            });
        };
    };

    const getPractitionerDataForModal = id => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'practitionerDataForModal',
                value: true
            }));

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}`,
                }).then((response) => {
                    dispatch(actions.setModalPractitionerData(get(response, 'data')));

                    dispatch(actions.setLoading({
                        type: 'practitionerDataForModal',
                        value: false
                    }));

            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'practitionerDataForModal',
                    value: false
                }));
            });
        };
    };

    const editAnswerTimingRule = ({formData, callback}) => {
        return (dispatch) => {
            console.log(formData);
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatedoctor`,
                data: {
                    practitionerInfo: formData?.practitionerInfo,
                    practitionerRoleInfo: {
                        availableStartTime: formData?.practitionerRole?.availableStartTime,
                        availableEndTime: formData?.practitionerRole?.availableEndTime,
                        practitionerRoleId: formData.practitionerRoleId,
                        contactEmail: formData?.practitionerRole?.contactEmail,
                    },
                    answeringTimeRuleInfo: {
                        id: formData.answerTimingRule.id,
                        timeoutMinutes: formData?.answerTimingRule?.timeoutMinutes?.value,
                        exceptNonWorkingTime: formData.answerTimingRule.exceptNonWorkingTime,
                        exceptNonWorkingDates: formData.answerTimingRule.exceptNonWorkingDates,
                    },
                    appRole: "doctor"
                }
            }).then((response) => {
                dispatch(registerMessage({name: 'answer-time-rule-doctor-message', type: 'primary', title: 'Правила времени на ответ успешно обновлены', closable: true, url: routes.private.root.accounts.path}))
                dispatch(showMessage('answer-time-rule-doctor-message'))
                dispatch(closeMessage({name: 'answer-time-rule-doctor-message', delay: systemMessageDelay}))
                dispatch(getPractitionerRole({practitionerRoleId: formData.practitionerRoleId}));
                callback && callback();
            }).catch((error) => {
                dispatch(registerMessage({name: 'answer-time-rule-doctor-error-message', type: 'red', title: 'Не удалось обновить правила времени на ответ', closable: true, url: routes.private.root.accounts.path}))
                dispatch(showMessage('answer-time-rule-doctor-error-message'))
                dispatch(closeMessage({name: 'answer-time-rule-doctor-error-message', delay: systemMessageDelay}))
                // Все равно загружаем врача
                dispatch(getPractitionerRole({practitionerRoleId: formData.practitionerRoleId}));
            });
        };
    };

    return { getPractitioner, getPractitionerRole, getData, uploadAvatar, putPractitionerAvatar, getOrganization, getPatientsForModal, getDoctorsForModal, changePractitioner, getStatisticUpdateStatus, deleteDoctor, resumeWork, archiveAccount, restoreAccount, getAnswerTimingRule, editAnswerTimingRule, getPractitionerDataForModal }
}

export const build = (name, storePath, thunksFromAnotherReducer) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions, thunksFromAnotherReducer);

    return {
        ChannelsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
