import React from 'react';

const ProgressIcon = ({size = 18}) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <title>Layer 1</title>
            <path id="svg_1" fill-opacity="0.54" d="m9,4.5c0.82843,0 1.5,-0.67157 1.5,-1.5c0,-0.82843 -0.67157,-1.5 -1.5,-1.5c-0.82843,0 -1.5,0.67157 -1.5,1.5c0,0.82843 0.67157,1.5 1.5,1.5z"/>
            <path id="svg_2" fill-opacity="0.54" d="m12.1815,5.81798c0.5858,0.58579 1.5356,0.58579 2.1213,0c0.5858,-0.58578 0.5858,-1.53553 0,-2.12132c-0.5857,-0.58578 -1.5355,-0.58578 -2.1213,0c-0.5858,0.58579 -0.5858,1.53554 0,2.12132z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.09"/>
            <path id="svg_3" fill-opacity="0.54" d="m13.5,9c0,0.82843 0.6716,1.5 1.5,1.5c0.8284,0 1.5,-0.67157 1.5,-1.5c0,-0.82843 -0.6716,-1.5 -1.5,-1.5c-0.8284,0 -1.5,0.67157 -1.5,1.5z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.22"/>
            <path id="svg_4" fill-opacity="0.54" d="m12.1815,12.182c-0.5858,0.5858 -0.5858,1.5355 0,2.1213c0.5858,0.5858 1.5356,0.5858 2.1213,0c0.5858,-0.5858 0.5858,-1.5355 0,-2.1213c-0.5857,-0.5858 -1.5355,-0.5858 -2.1213,0z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.35"/>
            <path id="svg_5" fill-opacity="0.54" d="m9,13.5c-0.82843,0 -1.5,0.6716 -1.5,1.5c0,0.8284 0.67157,1.5 1.5,1.5c0.82843,0 1.5,-0.6716 1.5,-1.5c0,-0.8284 -0.67157,-1.5 -1.5,-1.5z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.48"/>
            <path id="svg_6" fill-opacity="0.54" d="m5.81847,12.182c-0.58578,-0.5858 -1.53553,-0.5858 -2.12132,0c-0.58578,0.5858 -0.58578,1.5355 0,2.1213c0.58579,0.5858 1.53554,0.5858 2.12132,0c0.58579,-0.5858 0.58579,-1.5355 0,-2.1213z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.61"/>
            <path id="svg_7" fill-opacity="0.54" d="m4.5,9c0,-0.82843 -0.67157,-1.5 -1.5,-1.5c-0.82843,0 -1.5,0.67157 -1.5,1.5c0,0.82843 0.67157,1.5 1.5,1.5c0.82843,0 1.5,-0.67157 1.5,-1.5z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.74"/>
            <path id="svg_8" fill-opacity="0.54" d="m5.81847,5.81798c0.58579,-0.58578 0.58579,-1.53553 0,-2.12132c-0.58578,-0.58578 -1.53553,-0.58578 -2.12132,0c-0.58578,0.58579 -0.58578,1.53554 0,2.12132c0.58579,0.58579 1.53554,0.58579 2.12132,0z" clip-rule="evenodd" fill-rule="evenodd" opacity="0.87"/>
        </g>
    </svg>
)

export default ProgressIcon;