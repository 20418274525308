import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from 'styled-components'
import Icon from "components/Icon";
import {NavLink} from "react-router-dom";

const MenuItem = ({item, isOpen, hover, adaptiveIsOpen, adaptiveMenuOn}) => {

    const theme = useTheme();

    const [active, setActive] = useState(false);

    return (
        <NavLink to={item.path} isActive={(match) => setActive(match?.url === item.path)}>
            <ItemWrapper active={active} >
                <IconWrapper>
                    <Icon icon={item.icon} size={24} color={active && theme.colors.primary} />
                </IconWrapper>
                <Title active={active} isOpen={isOpen} hover={hover} adaptiveIsOpen={adaptiveIsOpen} adaptiveMenuOn={adaptiveMenuOn}>
                    {item.name}
                </Title>
            </ItemWrapper>
        </NavLink>
    );
};

const Title = styled.div`
  display: block;
  opacity: 1;
  pointer-events: none;
  ${({active}) => active ? css`
      ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary})};
    ` : css`
      ${({theme}) => theme.mixins.robotoRegular()};
    `}
  
  transition: opacity ${({theme}) => theme.animations.speed} ${({theme}) => theme.animations.curve};

  ${({isOpen}) => isOpen && css`
    opacity: 0;
  `};

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
    opacity: 0;
  `};
  
  ${({hover}) => hover && css`
    opacity: 1;
  `};

  ${({adaptiveMenuOn}) => adaptiveMenuOn && css`
    opacity: 1;
  `};
`

const IconWrapper = styled.div`
  margin-right: 18px;
`

const ItemWrapper = styled.div`
  border-radius: ${({theme}) => theme.other.borderRadius};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({active, theme}) => active ? theme.colors.primary12 : 'transparent'};
  
  :hover {
    ${({active}) => active ? css`
      background-color: ${({theme}) => theme.colors.primary12};
    ` : css`
      background-color: ${({theme}) => theme.colors.black04};
    `};
  }

  ${({active}) => active && css`
    background-color: ${({theme}) => theme.colors.primary12};
    ${Title} {
      ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary})};
    }
  `};
  
  :active {
    background-color: ${({theme}) => theme.colors.black08};
  }
`



MenuItem.propTypes = {
    item: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    adaptiveIsOpen: PropTypes.bool.isRequired,
    hover: PropTypes.bool.isRequired,
};

export default MenuItem;
