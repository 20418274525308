import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icon";
import styled, {css, useTheme} from "styled-components";
import Error from "../Error";
import {Wrapper} from "styled/Wrapper";
import dayjs from "dayjs";
import NativeDateInput from "../NativeDateInput";

const Input = (
    {
        input,
        onBlur,
        onFocus,
        value,
        onChange,
        label,
        placeholder,
        icon,
        decorationIcon,
        iconSize,
        iconPosition,
        decorationIconPosition,
        iconAction,
        error,
        type,
        showError,
        iconShowOnType,
        required,
        focus,
        focusUnderline,
        transparent,
        underline,
        textAlign,
        cursor,
        clear,
        readonly,
        dontDisableText,
        autoFocused,
        selectedForFocus,
        useSelectedForFocus,
        withoutPaddings,
        onClick,
        pointered,
        dataPicker,
        onDatePickerChange,
        datePickerFrom,
        datePickerTo
    }) => {

    // console.log("value", value);

    const theme = useTheme()

    const [focused, setFocused] = useState(false)
    const [blurClick, setBlurClick] = useState(false)

    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = (val) => {
            if (val === true) {
                htmlElRef.current &&  htmlElRef.current.focus()
            }
            else {
                htmlElRef.current && htmlElRef.current.blur()
            }
        }

        return [ htmlElRef, setFocus ]
    }
    const [inputRef, setInputFocus] = useFocus(true)


    useEffect(() => {
        if (useSelectedForFocus && !selectedForFocus) {
            setInputFocus(true);
        }
    }, [selectedForFocus]);

    const handleChange = (e) => {
        if (type === "file") {
            return onChange(e)
        }
        return onChange(e.target.value)
    }
    useEffect(() => {
        if (autoFocused && blurClick === false) {
            setInputFocus(true);
        }
    })

    useEffect(() => {
        if(iconShowOnType) {
            setShowIcon(!!value)
        }
    }, [iconShowOnType, value])

    const [showIcon, setShowIcon] = useState(true);

    const handleFocus = (focused) => {
        setFocused(focused)
        setBlurClick(true);
        if (focused === false && autoFocused) {
            setInputFocus(false)
        }
        onFocus && onFocus();
    }

    const handleIconAction = (e) => {
        iconAction(e)
        if(iconShowOnType) {
            setInputFocus(true)
        }
    }

    return (
        <Wrapper paddings={label && '22px 0 0 0'} onClick={onClick}>
            <Field focus={focused} underline={(focused && focusUnderline) || underline} transparent={transparent} withoutPaddings={withoutPaddings} pointered={pointered}>
                {label && <Label error={showError} onClick={(e) => e.stopPropagation()}>
                    {label}
                    {required &&
                    <i> *</i>
                    }
                </Label>}

                {decorationIcon && <IconWrapper position={decorationIconPosition}>
                    <Icon icon={decorationIcon} size={iconSize} color={decorationIconPosition === 'left' && theme.colors.black30} action={(e) => e.preventDefault()} />
                </IconWrapper>}

                {
                    dataPicker && <IconWrapper position={iconPosition}>
                    <NativeDateInput onChange={onDatePickerChange} from={datePickerFrom} to={datePickerTo} defaultValue={dayjs()} type={'icon'}/>
                    </IconWrapper>
                }

                {icon && showIcon && <IconWrapper position={iconPosition}>
                    <Icon icon={icon} size={iconSize} action={iconAction ? (e) => handleIconAction(e) : (e) => e.preventDefault()} color={iconPosition === 'left' && theme.colors.black30} />
                </IconWrapper>}
                <InputWrapper pointered={pointered} position={icon && iconPosition} decorationPosition={decorationIcon && decorationIconPosition} textAlign={textAlign} cursor={cursor} readonly={readonly}  withoutPaddings={withoutPaddings} dontDisableText={dontDisableText}>
                    <input autoFocus={focus} ref={inputRef} type={type} value={!clear ? value : ''} onChange={handleChange} placeholder={placeholder} readonly={readonly} disabled={readonly} onFocus={() => handleFocus(true)}
                           onBlur={e => {
                               onBlur && onBlur(e);
                               handleFocus(false);
                           }}
                    />
                </InputWrapper>
            </Field>
            <ErrorWrapper>
                <Error message={error} show={showError} />
            </ErrorWrapper>
        </Wrapper>
    );
};

const Field = styled.div`
  position: relative;
  background-color: ${({theme, transparent}) => transparent ? 'transparent' : theme.colors.black04};
  ${({theme}) => theme.mixins.borderRadius()};
  cursor: ${({pointered}) => pointered ? 'pointer' : 'default'};
  padding: ${({withoutPaddings}) => withoutPaddings ? '0' : '6px 10px;'};
  
  ${({underline}) => underline && css`
    ${({theme}) => theme.mixins.borderRadius(`${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`)};
  `};

  box-shadow: inset ${({theme, underline}) => underline ? `0px -2px 0px ${theme.colors.primary}` : 'none'};

  :hover {
    background-color: ${({theme, transparent}) => transparent ? 'transparent' : theme.colors.black08};
  }
`

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};
  position: absolute;
  left: 0;
  top: -22px;

  ${({error}) => error && css`
    color: ${({theme}) => theme.colors.red};
  `};
  
  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  ${({position}) => renderIcon(position)};
  width: 18px;
  height: 18px;
  
  ${({theme}) => theme.isMobile && css`
    width: auto;
    height: auto;
    top: 50%;
    transform: translate(0, -50%);
  `}
`

const calculatePadding = (position, decorationPosition) => {
    return `0 ${position === 'right' || decorationPosition === 'right' ? '24px' : 0} 0 ${position === 'left' || decorationPosition === 'left' ? '24px' : 0}`
}

const InputWrapper = styled.div`
  width: 100%;
  
  input {
    cursor: ${({pointered}) => pointered ? 'pointer' : 'default'};
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: ${({position, decorationPosition, withoutPaddings}) => withoutPaddings ? '6px 10px' : calculatePadding(position, decorationPosition)};
    text-align: ${({textAlign}) => textAlign};
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({cursor}) => cursor && css`
      cursor: ${({cursor}) => cursor};
    `};
    ${({readonly, dontDisableText}) => readonly === true && !dontDisableText && css`
      color: ${({theme}) => theme.colors.black30};
    `};
    ${({theme}) => theme.isMobile && css`
        ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5})};
        min-height: 28px;
    `};
    ::placeholder {
      color: ${({theme}) => theme.colors.black30};
    }
  }
`

const ErrorWrapper = styled.div`
  
`

const renderIcon = (position) => {
    switch (position) {
        case 'left':
            return css`
                left: 8px;
                top: 7px;
            `
        case 'right':
            return css`
                right: 8px;
                top: 7px;
            `
        default:
            return css`
                left: 8px;
                top: 7px;
            `
    }
}

Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.element,
    decorationIcon: PropTypes.element,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    decorationIconPosition: PropTypes.oneOf(['left', 'right']),
    iconAction: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number']),
    iconShowOnType: PropTypes.bool,
};

Input.defaultProps = {
    type: 'text',
    iconPosition: 'right',
    decorationIconPosition: 'left',
    iconAction: 'undefined',
    iconShowOnType: false,
    readonly: false,
    withoutPaddings: false
}

export default Input;
