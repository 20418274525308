import React from 'react';

const PersonalInfoIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 2C10.4477 2 10 2.44772 10 3V5C10 5.55228 10.4477 6 11 6H13C13.5523 6 14 5.55228 14 5V3C14 2.44772 13.5523 2 13 2H11Z" />
            <path d="M15 4H21C22.1 4 23 4.9 23 6V19C23 20.1 22.1 21 21 21H3C1.9 21 1 20.1 1 19V6C1 4.9 1.9 4 3 4H9V6H3V19H21V6H15V4Z" />
            <path d="M16 8C15.4477 8 15 8.44772 15 9C15 9.55228 15.4477 10 16 10H18C18.5523 10 19 9.55228 19 9C19 8.44772 18.5523 8 18 8H16Z" />
            <path d="M18 11.5C18.5523 11.5 19 11.9477 19 12.5C19 13.0523 18.5523 13.5 18 13.5H16C15.4477 13.5 15 13.0523 15 12.5C15 11.9477 15.4477 11.5 16 11.5H18Z" />
            <path d="M15 16C15 15.4477 15.4477 15 16 15H18C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17H16C15.4477 17 15 16.5523 15 16Z" />
            <path d="M11 10C11 11.1 10.1 12 9 12C7.9 12 7 11.1 7 10C7 8.9 7.9 8 9 8C10.1 8 11 8.9 11 10Z" />
            <path d="M9 13C8.01 13 7.07 13.21 6.22 13.58C5.48 13.9 5 14.62 5 15.43V16C5 16.5523 5.44772 17 6 17H12C12.5523 17 13 16.5523 13 16V15.43C13 14.62 12.52 13.9 11.78 13.58C10.93 13.21 9.99 13 9 13Z" />
    </svg>
)

export default PersonalInfoIcon;
