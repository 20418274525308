import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import {renderBackground} from "utils/renderBackground";
import Icon from "components/Icon";
import CheckIcon from "components/Icons/CheckIcon";
import DoubleCheckIcon from "components/Icons/DoubleCheckIcon";
import ErrorIcon from "components/Icons/ErrorIcon";
import {clearFileTypeName, getImageUrl, getFileSizeInMb, getFileByUrl} from "utils";
import {get} from "lodash";
import fileDownload from 'react-file-download';
import {useSelector, useDispatch} from "react-redux";
import Text from "components/Text";
import { useModalCarousel } from 'components/ModalCarousel/useModalCarousel';
import ChatHistoryImagesCarousel from 'modals/ChatHistoryImagesCarousel';
import {Wrapper} from "../../../../styled/Wrapper";

const Item = ({sender, name, showSenderData, message, time, status, index, expired, files, images, category, onMeasureChanged, reducer, id, encounterId, replyMessageId, replyMessage, replyMessageContentAttachment, replyAuthorName}) => {

    const {
        selectAllCommunicationsLoaded,
        selectCommunicationSorting,
        getCommunications,
        ChannelActions
    } = reducer;

    const token = useSelector(state => get(state, "app.access_token"));
    const allComuunicationsLoaded = useSelector(selectAllCommunicationsLoaded);
    const sorting = useSelector(selectCommunicationSorting);
    const theme = useTheme();
    const [type, setType] = useState('');
    const [elHeight, setElementHeight] = useState(null);
    const [imagesArray] = useState(false)
    const [imagesActiveId] = useState(false)
    const elRef = useRef();
    const [adaptedReplyMessageType, setAdaptedReplyMessageType] = useState(false)
    const [activeReplyMessageElement, setActiveReplyMessageElement] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        if (files) {
            setType('file');
        } else if (images) {
            setType('image');
        } else if (message.indexOf('http') !== -1) {
            setType('link');
        }
        /*определяем тип reply*/
        if (replyMessageContentAttachment && replyMessageContentAttachment.length > 1) {
            if (replyMessageContentAttachment?.[1].contentAttachment?.contentType) {
                setAdaptedReplyMessageType(replyMessageContentAttachment?.[1].contentAttachment?.contentType.substring(0, replyMessageContentAttachment?.[1].contentAttachment?.contentType.lastIndexOf('/')));
                setActiveReplyMessageElement(1);
            }
            else {
                setAdaptedReplyMessageType(false);
            }
        }
        else {
            if (replyMessageContentAttachment?.[0].contentAttachment?.contentType) {
                setAdaptedReplyMessageType(replyMessageContentAttachment?.[0].contentAttachment?.contentType.substring(0, replyMessageContentAttachment?.[0].contentAttachment?.contentType.lastIndexOf('/')));
                setActiveReplyMessageElement(0);
            }
            else {
                setAdaptedReplyMessageType(false);
            }
        }
    }, []);

    useEffect(() => {
        elHeight && elRef?.current?.clientHeight !== elHeight && onMeasureChanged && onMeasureChanged(); // Обновляем закешированную высоту строки если по какой то причине изменилась высота элемента.
        setElementHeight(elRef?.current?.clientHeight)
    });


    // let linkedMessage = message.replace(/(http|https)\:\/\/(.+?(?= |<|$))/g, '<a href="$1://$2" target="_blank" rel="nofollow">$1://$2</a>');
    let linkedMessage = message.replace(/(http|https):\/\/(.+?(?= |<|$))/g, '<a href="$1://$2" target="_blank" rel="nofollow">$1://$2</a>');

    let litera = message;

    if (type === 'link') {
        litera = litera.replace("https", "http");
        litera = litera.substring(litera.indexOf("http://"));
        litera = litera.replace("http://", "").charAt(0);
    }

    const imgTxt = (number) => {
        let txt;
        if (adaptedReplyMessageType === 'application') {
            txt = ['файл', 'файла', 'файлов']
        }
        if (adaptedReplyMessageType === 'image') {
            txt = ['изображение', 'изображения', 'изображений']
        }
        let cases = [2, 0, 1, 1, 1, 2];
        return number + ' ' + txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    const renderSender = (sender) => {
        switch (sender) {
            case 'patient':
                return 'Пациент'
            case 'doctor':
                return 'Лечащий врач'
            default:
                return null
        }
    }

    const renderStatus = (status) => { // Текущие статусы получаем в adapters/channel.js
        switch (status) {
            case 'sent':
                return CheckIcon
            case 'received':
                return DoubleCheckIcon
            case 'error':
                return ErrorIcon
            default:
                return null
        }
    }

    let [showModalCarousel] = useModalCarousel({name: 'chat-history-images-carousel-modal', component: ChatHistoryImagesCarousel, props: {reducer: {...reducer, actions: reducer?.ChannelActions}, imageItem: 0, imagesActiveId: imagesActiveId, imagesArray: imagesArray ? imagesArray : false}})

    const handleShowModalCarousel = (index) => {

        dispatch(ChannelActions.setCarouselActiveMessageId(id));
        dispatch(ChannelActions.setCarouselActiveImageIndex(index));
        showModalCarousel();

        if(!allComuunicationsLoaded){
            dispatch(getCommunications({
                encounterId,
                startIndex: 0,
                maxItems: 0,
                sorting
            }))
        }
    }

    const handleDownloadClick = (item) => {
        item?.url && item?.title &&
            getFileByUrl(item.url).then(response => {
                const buffer = Buffer.from(response.data, 'binary');
                fileDownload(buffer, item.title, response?.headers["content-type"])
            })
    }

    const isError = status === 'error';

    const cleanMessage = (srcMessage) => {
        let resultMessage;
        resultMessage = srcMessage ? srcMessage.replace(/<br[^>]*>/g, '\n') : srcMessage;
        return resultMessage;
    }

    // console.log("files", files);
    // console.log("images", images);

    return (
        <MessageWrapper ref={elRef} msgType={sender} category={category}>
            {name && sender && showSenderData && (<Information msgType={type}>
                <Left>
                    {name && <Name>
                        {name}
                    </Name>}
                    {sender && <Sender>
                        {renderSender(sender)}
                    </Sender>}
                </Left>
                <Right>
                    {status && sender !== 'patient' && <Status>
                        <Icon icon={renderStatus(status)} color={isError && theme.colors.red} />
                    </Status>}
                    <Time color={expired && theme.colors.red}>
                        {time}
                    </Time>
                </Right>
            </Information>)}
             <MessageBody msgType={type} sender={name && sender && showSenderData} images={images ? images.length : 0} files={files ? files.length : 0} status={status} message={message}>
                <Flex>
                    <Message msgType={type}>
                        {replyMessageId &&
                            <ReplyMessageWrapper msgType={type}>
                                {adaptedReplyMessageType === 'image' &&
                                /*<ReplyMessageImage type={replyType}><img src={getImageUrl(replyImage, token)} /></ReplyMessageImage>*/
                                    <ReplyMessageImages>
                                        <ReplyMessageImage type="image"><img src={getImageUrl(replyMessageContentAttachment[activeReplyMessageElement].contentAttachment.url, token)} alt={''} /></ReplyMessageImage>
                                        <Wrapper justify={'center'} width={'100%'}>
                                            <ReplyMessageText color={theme.colors.black54}>
                                                <AuthorName>{replyAuthorName}</AuthorName>
                                                <ReplyMessageText color={theme.colors.black54}>
                                                    {cleanMessage(replyMessage) ?
                                                        cleanMessage(replyMessage)
                                                        :
                                                        replyMessageContentAttachment.length === 1 ?
                                                            'Изображение'
                                                            :
                                                            imgTxt(replyMessageContentAttachment.length)
                                                    }
                                                </ReplyMessageText>
                                            </ReplyMessageText>
                                        </Wrapper>
                                    </ReplyMessageImages>
                                }
                                {adaptedReplyMessageType === 'application' &&
                                    <ReplyMessageImages>
                                        <ReplyMessageImage type="file">{replyMessageContentAttachment[activeReplyMessageElement].contentAttachment.contentType.replace('application/', '')}</ReplyMessageImage>
                                        <Wrapper justify={'center'} width={'100%'}>
                                            <ReplyMessageText color={theme.colors.black54}>
                                                <AuthorName>{replyAuthorName}</AuthorName>
                                                <ReplyMessageText color={theme.colors.black54}>
                                                    {replyMessageContentAttachment.length === 1 ?
                                                            replyMessageContentAttachment[activeReplyMessageElement].contentAttachment.title
                                                            :
                                                            imgTxt(replyMessageContentAttachment.length)
                                                    }
                                                </ReplyMessageText>
                                            </ReplyMessageText>
                                        </Wrapper>
                                    </ReplyMessageImages>
                                }
                                {adaptedReplyMessageType === false &&
                                    <Wrapper justify={'center'} width={'100%'}>
                                        <AuthorName>{replyAuthorName}</AuthorName>
                                        <ReplyMessageText
                                            color={theme.colors.black54}>{cleanMessage(replyMessage)}</ReplyMessageText>
                                    </Wrapper>
                                }
                            </ReplyMessageWrapper>
                        }
                        {type === 'image' ?
                            images.map((item, index) => {
                                if (images.length === 1) {
                                    return (
                                        <>
                                            <ImgWrapper onClick={() => handleShowModalCarousel(index)} ><img
                                            src={getImageUrl(item.contentAttachment.url, token)} alt={''} onLoad={onMeasureChanged}/></ImgWrapper>
                                            <ImgWrapperText>{cleanMessage(message)}</ImgWrapperText>
                                        </>
                                        );
                                } else if (images.indexOf(item) < 4) {
                                    return (<ImgWrapper onClick={() => handleShowModalCarousel(index)} ><img
                                        src={getImageUrl(item.contentAttachment.url, token)} alt={''} onLoad={onMeasureChanged} /></ImgWrapper>);
                                } else {
                                    return <></>
                                }
                            })
                            : type === 'file' ?
                            files.map(item => {
                                if (files.length === 1) {
                                return (
                                    <>
                                        <FilesWrapper onClick={() => handleDownloadClick(item?.contentAttachment)} msgType={type}>
                                            <MessageImage>{clearFileTypeName(item.contentAttachment.contentType)}</MessageImage>
                                            {item.contentAttachment.title}
                                            <Text text={getFileSizeInMb(item.contentAttachment.size)} tag={'small'} color={theme.colors.black54}
                                                font={'robotoRegular'}/>
                                        </FilesWrapper>
                                        {message && <FilesWrapperText>{cleanMessage(message)}</FilesWrapperText>}
                                    </>
                                );
                                } else {
                                    return (
                                        <FilesWrapper onClick={() => handleDownloadClick(item?.contentAttachment)} msgType={type}>
                                            <MessageImage>{clearFileTypeName(item.contentAttachment.contentType)}</MessageImage>
                                            {item.contentAttachment.title}
                                            <Text text={getFileSizeInMb(item.contentAttachment.size)} tag={'small'} color={theme.colors.black54}
                                                font={'robotoRegular'}/>
                                        </FilesWrapper>
                                    );
                                }
                            })
                            : <TextWrapper msgType={type} replyMessageIsset={replyMessageId}>
                                {type === 'link' &&
                                <MessageImage>
                                    {litera}
                                </MessageImage>
                                }
                                {type === 'link' ? <div dangerouslySetInnerHTML={{__html: linkedMessage}} /> : cleanMessage(message)}
                            </TextWrapper>
                        }
                    </Message>
                </Flex>
                {!showSenderData && (
                    <MessageStatusAndTime msgType={type} images={images ? images.length : 0} message={message} replyMessageIsset={replyMessageId}>
                        {status && sender !== 'patient' && <MessageStatus>
                            <Icon icon={renderStatus(status)} color={type !== 'image' || (images.length === 1 && message) ? (isError ? theme.colors.red : theme.colors.black54) : theme.colors.white100} />
                        </MessageStatus>}
                        <MessageTime color={expired && theme.colors.red}>
                            {time}
                        </MessageTime>
                    </MessageStatusAndTime>
                    )
                }
            </MessageBody>
        </MessageWrapper>
    );
};

const calculateType = (type) => {
    switch (type) {
        case 'doctor':
            return 'blue'
        case 'patient':
            return 'black'
        default:
            return null
    }
}

const ReplyMessageWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: calc(100% + 24px);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 12px 8px 12px;
  margin: 0 -12px 8px -12px;
  flex-direction: column;
  ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black54})};
  ${({theme}) => theme.mixins.innerShadow()};
  ${({msgType}) => msgType === 'file' && css`
      width: 100%;
      margin: 0;
      padding-top: 8px;
  `}
`

// const ReplyMessageName = styled.div`
//   ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small, color: theme.colors.primary})};
// `

const ReplyMessageText = styled.div`
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: ${({theme, color}) => color ? color : theme.colors.black100};
`

const AuthorName = styled.div`
    color: ${({theme}) => theme.colors.primary};
`

const Flex = styled.div`
    display: flex;
    width: 100%;
`

const TextWrapper = styled.div`
    ${({msgType}) => msgType === 'link' && css`
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        
        a {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${({theme}) => theme.colors.primary};
        }
    `}
    ${({replyMessageIsset}) => replyMessageIsset && css`
        padding-right: 75px;
    `}
    white-space: pre-wrap;
    word-break: break-word;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Status = styled.div`
    flex: 0 0 auto;
    margin-right: 4px;
`

const MessageStatus = styled.div`
    margin-right: 4px;
`

const MessageTime = styled.div`
    ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54, lineheight: theme.fonts.lineHeight.small})};
    color: ${({color}) =>  color};
`

const MessageBody = styled.div`
  display: flex;
  padding: 8px 12px;
  width: 100%;

    ${({status}) => status === 'result' && css`
    ${Message} {
        ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.red})};
    }
  `};
  ${({msgType}) => msgType === 'image' && css`
    border-radius: 16px;
    overflow: hidden;
    width: auto;
    height: ${({reply, message}) => message ? 'auto' : reply ? '192px' : '136px'};
    display: flex;
    flex-direction: column;
    max-width: ${({direction}) => direction === 'left' ? '448px' : '544px'};
    position: relative;
    padding: 0;
    
    ${ReplyMessageWrapper} {
        width: 100%;
        padding-top: 8px;
        margin: 0 0 0 0;
    }
    ${({sender, msgType}) => msgType === 'image' && sender && css`
        border-radius: 0 0 16px 16px;
    `};
    
        ${TextWrapper} {
            height: auto !important;
            padding: 8px 12px 28px 12px;
        }
        ${Message} {
        display: flex;
        flex-direction: ${({images}) => images > 1 ? 'row' : 'column'};
        flex: 1 1 100%;
        
        ${({message}) => !message && css`
            line-height: 0;
        `};
        
        ${ImgWrapper} {
            width: ${({reply, images, message}) => reply ? '100%' : images === 1 ? '100%' : '136px'};
            flex: 1 0 136px;
            height: 136px;
            position: relative;
            
            img {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: inset 0px 0px 0px 1px ${({theme}) => theme.colors.black12};
                border-radius: 0;
                z-index: 2;
            }
            &:first-child {
                &:after {
                    border-radius: ${({images, reply, message}) => message ? 0 : images === 1 ? reply ? '0 0 16px 16px' : '16px' : '16px 0 0 16px'};
                }
            }
            &:last-child {
                &:after {
                    border-radius: ${({images, reply, message}) => message ? 0 : images === 1 ? reply ? '0 0 16px 16px' : '16px' : '0 16px 16px 0'};
                }
            }
        }
    }
  `};
  ${({msgType}) => msgType === 'file' && css`
    padding: 0;
  `}
`

const MessageWrapper = styled.div`
  border-radius: ${({theme}) => theme.other.chatBorderRadius};
  background-color: ${({theme, msgType}) => renderBackground(theme, calculateType(msgType))};
  ${({category}) => category === 'critical' && css`
    background-color: rgba(255,59,48,0.12) !important;
  `};
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`

const ImgWrapper = styled.div`
    cursor: pointer;
`

const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 8px 12px 0 12px;

    ${({msgType}) => msgType === 'image' && css`
        margin-bottom: 8px;
    `};
    
    & + ${MessageBody} {
        ${ImgWrapper}:first-child:after, ${ImgWrapper}:last-child:after {
           border-radius: 0;
        }
    }
`

const Name = styled.div`
  ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.label, color: theme.colors.primary, lineheight: theme.fonts.lineHeight.small})};
  margin-right: 6px;
`

const Sender = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black30, lineheight: theme.fonts.lineHeight.small})};
  margin-right: 12px;
`

const Time = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54, lineheight: theme.fonts.lineHeight.small})};
  color: ${({color}) =>  color};
`

const Message = styled.div`
  width: 100%;
  ${({theme}) => theme.mixins.robotoRegular()};
  margin-right: 12px;
  :last-child {
    margin-right: 0;
  }
`

const ImgWrapperText = styled.div`
    padding: 10px 65px 10px 12px;
    min-width: 136px;
    word-break: break-word;
`

const FilesWrapper = styled.div`
    padding: 10px 30px 10px 12px;
    box-shadow: inset 0px -1px 0px rgb(0 0 0 / 12%);
    cursor: pointer;

    :last-child {
        box-shadow: none;
    }
`

const FilesWrapperText = styled.div`
    padding: 10px 65px 10px 12px;
    min-width: 136px;
    word-break: break-word;
`

const MessageImage = styled.div`
    float: left;
    margin-right: 12px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background: ${({theme}) => theme.colors.primary12};
    display: flex;
    align-items: center;
    justify-content: center;
    ${({theme}) => theme.mixins.robotoCondensedBold({color: theme.colors.primary})};
    text-transform: uppercase;
    
    img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
`

const MessageStatusAndTime = styled.div`
    display: flex;
    margin-left: 15px;
    
    ${({replyMessageIsset}) => replyMessageIsset && css`
        position: absolute;
        right: 12px;
        bottom: 8px;
        z-index: 1;
    `}
    
    ${({msgType}) => msgType === 'file' && css`
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 1;
        padding: 0 6px;
    `};
    ${({msgType}) => msgType === 'image' && css`
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 1;
        padding: 0 6px;
        height: 22px;
        border-radius: 50px;
        align-items: center;
        background: ${({theme}) => theme.colors.black40};
        
        ${MessageTime} {
            color: ${({theme}) => theme.colors.white100};
        }
    `};
    ${({msgType, message, images}) => msgType === 'image' && images === 1 && message && css`
        position: absolute;
        right: 12px;
        bottom: 8px;
        z-index: 1;
        padding: 0;
        height: 22px;
        border-radius: 50px;
        align-items: center;
        background: none;
        
        ${MessageTime} {
            color: ${({theme}) => theme.colors.black100};
        }
    `};
    ${({images}) => images > 4 && css`
        width: 136px;
        height: 136px;
        border-radius: 0 16px 16px 0;
        bottom: 0;
        right: 0;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 7px 11px;
        
        &:after {
            content: ${({images}) => '"+ '+(Number(images)-4)+'"'};
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            ${({theme}) => theme.mixins.robotoCondensedBold({size: theme.fonts.sizes.big, color: theme.colors.white100, lineheight: theme.fonts.lineHeight.h2})};
        }
    `};
`

const ReplyMessageImages = styled.div`
    display: flex;
    width: 100%;
    padding: 4px 0;
    
    ${({files}) => files > 1 && css`
        ${({theme}) => theme.mixins.innerShadow({y: 'top'})};
    `};
    
   &:first-child {
        box-shadow: none;
   }
`

const ReplyMessageImage = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    position: relative;
    margin-right: 12px;
    overflow: hidden;
    border-radius: 6px;
    background: ${({theme}) => theme.colors.primary12};
    display: flex;
    align-items: center;
    justify-content: center;
    ${({theme}) => theme.mixins.robotoCondensedBold({color: theme.colors.primary})};
    text-transform: uppercase;
    
    img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
    ${({type}) => type === 'image' && css`
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.12);
            border-radius: 6px;
            z-index: 2;
        }
    `};
`

Item.propTypes = {
    sender: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string.isRequired,
    time: PropTypes.string,
};

export default Item;
