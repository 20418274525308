import React, {useEffect, useRef, useState} from 'react'; 
import EmptyNotes from "assets/images/empty-notes.png"
import EmptyNotesMobile from "assets/images/notes_nodata.svg"
import NoData from "components/NoData";
import styled, {css, useTheme} from "styled-components";
import Input from "components/Input/Input";
import ActionIcon from "components/ActionIcon";
import SendIcon from "components/Icons/SendIcon";
import TableSubtext from "components/Table/TableSubtext";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import {useDispatch, useSelector} from "react-redux";
import {addNote, selectNotes, getNotes, selectActivePatient, selectLoading, doctorActions} from "reducers/doctor";
import dayjs from "dayjs";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import {selectRole} from "reducers/doctor";
import {selectAuthorNotes} from "reducers/doctor";
import { CustomView, isDesktop, isTablet, MobileOnlyView } from 'react-device-detect';
import Button from 'components/Button';
import PlusIcon from 'components/Icons/PlusIcon';
import { useModal } from 'components/Modal/useModal';
import TextAreaMobileModal from 'modals/TextAreaMobileModal';
import {useMediaQuery} from "react-responsive";
import Spinner from 'components/Spinner';

const Notes = () => {
    const theme = useTheme()
    const dispatch = useDispatch();
    const notes = useSelector(selectNotes);
    const loading = useSelector(selectLoading);
    const role = useSelector(selectRole);
    const [note, setNote] = useState('');
    const [clearInput, setClearInput] = useState(false);
    const activePatient = useSelector(selectActivePatient);
    const authorNotes = useSelector(selectAuthorNotes);
    const ref = useRef();
    const [activeSendButton, setActiveSendButton] = useState(false)
    const smallDesktop = useMediaQuery({ maxWidth: 1215 });
    const [listScrolledToBottom, setListScrolledToBottom] = useState(false);

    const [showWriteNoteModal] = useModal({
        component: TextAreaMobileModal,
        name: 'cancel-doctor-event-modal',
        mode: 'mobile-bottom-bar',
        props: {
            hideHeader: true,
            maxLength: 500,
            onAccept: value => {
                if (value.length > 0 && value.trim().length > 0) {
                    dispatch(addNote(activePatient.carePlanId, value, role.id));
                }
            },
            title: 'Новая заметка',
            placeholder: 'Введите текст заметки'
        }
    });

    useEffect( () => {
        if (activePatient !== null) {
            dispatch(getNotes(activePatient.carePlanId, role.id));
        }
    }, [selectNotes, activePatient])

    useEffect( () => {
        if (ref.current) {
            setTimeout( () => {
                if(ref.current && ref.current.offsetParent){
                    ref.current.offsetParent.scrollTop = ref?.current?.parentNode?.offsetHeight;
                }
            }, 200)
        }
    }, [notes])

    useEffect(() => {
        return () => {
            dispatch(doctorActions.setLoading({
                type: 'notes',
                value: true
            }))
        }
    }, [])

    const handleChangeInput = (e) => {
        setClearInput(false);
        setNote(e);
        if (e.length === 0 || e.trim().length === 0) {
            setActiveSendButton(false)
        }
        else {
            setActiveSendButton(true)
        }
    }

    const getAuthor = (id) => {
        for (let item in authorNotes) {
            if (authorNotes[item].id === id) {
                return authorNotes[item].authorName;
            }
        }
    }

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleAddNote();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [note]);

    const handleAddNote = () => {
        if (note.length > 0 && note.trim().length > 0) {
            dispatch(addNote(activePatient.carePlanId, note, role.id));
            setClearInput(true);
            setActiveSendButton(false);
            setNote('')
        }
    }

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Container isTablet={isTablet || (isDesktop && smallDesktop)}>
                    <Content>
                        {!loading.notes ?
                            <PerfectScrollbarWrapper height={'100%'} paddings={'0'}>
                                {notes && notes.length > 0 ?
                                    notes.map(item => (
                                        <CommentsListItem ref={ref}>
                                            <Wrapper direction={'row'} align={'center'} justify={'space-between'}>
                                                <Text tag={'small'} text={getAuthor(item.authorReference.id)} color={theme.colors.black54} font={'robotoRegular'} />
                                                <TableSubtext text={dayjs(item.time).locale("ru").format("DD MMM YYYY, HH:mm")} color={theme.colors.black30} />
                                            </Wrapper>
                                            <CommentText>{item.text}</CommentText>
                                        </CommentsListItem>
                                    ))
                                    :
                                    <WrapperNoData>
                                        <NoData image={EmptyNotes} text={'Здесь Вы можете оставить\nзаметки по выбранному пациенту'} />
                                    </WrapperNoData>
                                }
                            </PerfectScrollbarWrapper>
                            :
                            <Spinner/>
                    }
                    </Content>
                    <InputWrapper>
                        <Input placeholder={'Текст заметки'} transparent onChange={handleChangeInput} clear={clearInput} />
                        <ActionIcon action={() => handleAddNote()} icon={SendIcon} iconColor={activeSendButton ? theme.colors.primary : theme.colors.black54} />
                    </InputWrapper>
                </Container>
            </CustomView>
            <MobileOnlyView>
                <Container>
                    <Content>
                        {!loading.notes ?
                            <PerfectScrollbarWrapper onYReachEnd={() => {setListScrolledToBottom(true)}} onScrollUp={() => {setListScrolledToBottom(false)}} height={'100%'} paddings={'0'}>
                                {notes && notes.length > 0 ?
                                    notes.map(item => (
                                        <CommentsListItem ref={ref}>
                                            <Wrapper direction={'row'} align={'center'} justify={'space-between'}>
                                                <Text tag={'small'} text={getAuthor(item.authorReference.id)} color={theme.colors.black54} font={'robotoRegular'} />
                                                <TableSubtext text={dayjs(item.time).locale("ru").format("DD MMM YYYY, HH:mm")} color={theme.colors.black30} />
                                            </Wrapper>
                                            <CommentText>{item.text}</CommentText>
                                        </CommentsListItem>
                                    ))
                                    :
                                    <WrapperNoData>
                                        <NoData image={EmptyNotesMobile} text={'Здесь Вы можете оставить заметки по\nвыбранному пациенту'} />
                                    </WrapperNoData>
                                }
                            </PerfectScrollbarWrapper>
                            :
                            <Spinner/>
                        }
                    </Content>
                    <FloatingButtonWrapper hide={listScrolledToBottom || loading.notes}>
                        <Button
                            // buttonType={'secondary'}
                            disabled={activePatient?.status === 'planned'}
                            onClick={() => showWriteNoteModal()}
                            buttonType="rounded"
                            label={'Добавить'}
                            icon={PlusIcon}
                            iconPosition={'left'}
                            reverse
                        />
                    </FloatingButtonWrapper>
                </Container>
            </MobileOnlyView>
        </>
    );
};

const FloatingButtonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .3s ease-in-out, opacity .3s ease;
    z-index: 1000;
    ${({hide}) => css`
        opacity: ${hide ? '0' : '1'};
        transform: ${hide ? 'translateY(65px)' : 'translateY(0)'};
    `}
`

const Container = styled.div`
    position: relative;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    ${({theme}) => theme.isMobile && css`
        height: calc(100vh - 114px);
        height: calc(var(--vh,1vh) * 100 - 100px);
    `};
    ${({isTablet}) => isTablet && css`
        height: calc(100vh - 125px);
        height: calc(var(--vh, 1vh) * 100 - 125px);
    `};
`
const Content = styled.div`
    padding: 8px 4px 8px 16px;
    overflow: hidden;
    min-height: calc(100% - 49px);
    position: relative;
    display: flex;
    flex-direction: column;
    
    ${({theme}) => theme.isMobile && css`
        padding: 0 4px 0 16px;
    `}
`

const InputWrapper = styled.div`
    display: flex;
    height: 49px;
    min-height: 49px;
    align-items: center;
    padding: 0 4px 0 10px;
    ${({theme}) => theme.mixins.innerShadow({y: 'top'})};
`

const WrapperNoData = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CommentsListItem = styled.div`
    padding: 9px 0;
    margin-right: 12px;
    ${({theme}) => theme.mixins.innerShadow()};
`

const CommentText = styled.div`
    white-space: pre-line;
    word-wrap: break-word;
    ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5})};
`

export default Notes;
