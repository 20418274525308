import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";

const DisableNotificationsModal = () => {

    return (
        <Wrapper paddings={'0 8px 0 24px'} maxHeight={'calc(100vh - 124px)'}>
            <PerfectScrollbarWrapper paddings={'0 16px 0 0'}>
                <Wrapper paddings={'0 0 24px 0'}>
                    <Text text={'Отключая почтовые уведомления о сообщениях пациентов, они больше не будут доставляться на вашу электронную почту.'} font={'robotoRegular'} />
                </Wrapper>
                <Text text={'Отключите почтовые уведомления ТОЛЬКО если вы уверены, что и без них не пропустите входящие сообщения.'} font={'robotoRegular'} />
            </PerfectScrollbarWrapper>
        </Wrapper>
    );
};

DisableNotificationsModal.propTypes = {
    hide: PropTypes.func.isRequired,
};

export default DisableNotificationsModal;
