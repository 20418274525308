import { createSlice } from "@reduxjs/toolkit";
import { systemMessageDelay } from "consts";
import { api } from "consts/api";
import { get } from "lodash";
import {
  closeMessage,
  registerMessage,
  showMessage,
} from "reducers/systemMessages/systemMessagesSlice";
import { fetchInstance } from "wrappers/axios";

const initialState = {
  onMaintenance: {
    allowedAccounts: null,
    onMaintenanceHeader: "",
    onMaintenanceDescription: "",
    isOnMaintenance: null,
  },
  editedCalendarYears: [],
  preferences: [],
  loading: {
    settings: false,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setOnMaintenanceState: (state, action) => {
      state.onMaintenance.onMaintenanceHeader =
        action.payload.onMaintenanceHeader || "Сервер недоступен";
      state.onMaintenance.onMaintenanceDescription =
        action.payload.onMaintenanceDescription ||
        "В данный момент сервер недоступен, ведутся технические работы.";
      state.onMaintenance.isOnMaintenance = action.payload.isOnMaintenance;
    },
    setPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setLoading: (state, action) => {
      const { type, value } = action.payload;
      state.loading[type] = value;
    },
    setIsOnMaintenance: (state, action) => {
      state.onMaintenance.isOnMaintenance = action.payload;
    },
    setAllowedAccounts: (state, action) => {
      state.onMaintenance.allowedAccounts = action.payload;
    },
    setEditedCalendarYears: (state, action) => {
      state.editedCalendarYears = action.payload;
    },
  },
});

export const selectOnMaintenance = (state) =>
  state.superAdmin.settings.onMaintenance;
export const selectOnMaintenanceStatus = (state) =>
  state.superAdmin.settings.onMaintenance.isOnMaintenance;
export const selectPreferences = (state) =>
  state.superAdmin.settings.preferences;
export const selectLoading = (state) => state.superAdmin.settings.loading;
export const selectEditedCalendarYears = (state) =>
  state.superAdmin.settings.editedCalendarYears;
export const settingsActions = settingsSlice.actions;

export const getOnMaintenanceState = () => {
  return (dispatch) => {
    dispatch(
      settingsActions.setLoading({
        type: "settings",
        value: true,
      })
    );
    fetchInstance({
      method: "GET",
      url: `${api.superAdmin}/GetOnMaintenanceState`,
    })
      .then((response) => {
        // let obj = JSON.parse(response.data.replaceAll('\n', '\\n'));
        dispatch(settingsActions.setOnMaintenanceState(response.data));
        dispatch(
          settingsActions.setLoading({
            type: "settings",
            value: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          settingsActions.setLoading({
            type: "settings",
            value: false,
          })
        );
      });
  };
};

export const changeOnMaintenanceState = (formData) => {
  return (dispatch) => {
    let description = formData.description.toString();
    fetchInstance({
      method: "PUT",
      url: `${api.superAdmin}/ChangeOnMaintenanceState`,
      data: {
        isOnMaintenance: formData.isOnMaintenance,
        onMaintenanceHeader: formData.title,
        onMaintenanceDescription: description,
        allowedAccounts: formData.allowedAccounts,
      },
    })
      .then((response) => {
        dispatch(getOnMaintenanceState());
      })
      .catch((error) => {});
  };
};

// export const getPreferences = () => {
//     return (dispatch) => {
//         dispatch(settingsActions.setLoading({
//             type: 'settings',
//             value: true
//         }));
//         fetchInstance({
//             method: "GET",
//             url: `${api.preference}`
//         }).then((response) => {
//             dispatch(settingsActions.setPreferences(response?.data));
//             dispatch(settingsActions.setLoading({
//                 type: 'settings',
//                 value: false
//             }));
//         }).catch((error) => {
//             dispatch(settingsActions.setLoading({
//                 type: 'settings',
//                 value: false
//             }));
//         });
//     };
// };

export const getPreferences = ({ tableSettings }) => {
  return (dispatch) => {
    dispatch(
      settingsActions.setLoading({
        type: "settings",
        value: true,
      })
    );
    fetchInstance({
      method: "POST",
      url: `${api.preference}/_search`,
      data: tableSettings,
    })
      .then((response) => {
        dispatch(settingsActions.setPreferences(response?.data));
        dispatch(
          settingsActions.setLoading({
            type: "settings",
            value: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          settingsActions.setLoading({
            type: "settings",
            value: false,
          })
        );
      });
  };
};

export const getAllowedAccounts = () => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: `${api.preference}/_search`,
      data: {
        paging: {
          startIndex: 0,
          maxItems: -1,
        },
        filtering: {
          searchString: null,
          fieldsFilter: {
            key: "OnMaintenanceData",
          },
        },
        sorting: [
          {
            direction: 0,
            propertyName: "key",
          },
        ],
      },
    })
      .then((response) => {
        const data = JSON.parse(get(response.data, "[0].value"));
        dispatch(
          settingsActions.setAllowedAccounts(get(data, "AllowedAccounts"))
        );
      })
      .catch((error) => {});
  };
};

export const updateProductionCalendars = ({ data, editedYears, tableSettings }) => {
  return (dispatch) => {
    dispatch(
      settingsActions.setLoading({
        type: "settings",
        value: true,
      })
    );
    
    const buildValue = (data, year) => {
      const copy = { ...data };
      delete copy.id;

      return Object.entries(copy).reduce((acc, item) => {
        const month = parseInt(item[0]) + 1;
        const value = item[1];
        return [...acc].concat(
          value.map(
            (item) =>
              `${year}-${month < 10 ? `0${month}` : month}-${
                item < 10 ? `0${item}` : item
              }`
          )
        );
      }, []);
    };

    const requests = editedYears?.map((year) => {
      if (data?.[year]?.id) {
        return fetchInstance({
          method: "PUT",
          url: `${api.preference}`,
          data: {
            id: data?.[year]?.id,
            key: `production_calendar_${year}`,
            value: JSON.stringify({ holidays: buildValue(data[year], year) }),
            description: `Производственный календарь ${year}`,
          },
        });
      } else {
        return fetchInstance({
          method: "POST",
          url: `${api.preference}`,
          data: {
            id: null,
            key: `production_calendar_${year}`,
            value: JSON.stringify({ holidays: buildValue(data[year], year) }),
            description: `Производственный календарь ${year}`,
          },
        });
      }
    });

    Promise.all(requests)
      .then((values) => {
        dispatch(settingsActions.setEditedCalendarYears([]));
        dispatch(getPreferences({ tableSettings }));

        dispatch(
          registerMessage({
            name: "update-production-calendars-message",
            type: "primary",
            title: "Производственный календарь сохранен",
            closable: true,
          })
        );
        dispatch(showMessage("update-production-calendars-message"));
        dispatch(
          closeMessage({
            name: "update-production-calendars-message",
            delay: systemMessageDelay,
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          registerMessage({
            name: "update-production-calendars-error-message",
            type: "red",
            title: "Ошибка",
            text: "Не удалось сохранить один из производственных календарей",
            closable: true,
          })
        );
        dispatch(showMessage("update-production-calendars-error-message"));
        dispatch(
          closeMessage({
            name: "update-production-calendars-error-message",
            delay: systemMessageDelay,
          })
        );
      });
  };
};

export const createPreferences = ({ formData, tableSettings }) => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: `${api.preference}`,
      data: {
        id: null,
        key: formData.key,
        value: formData.value,
        description: formData.description,
      },
    })
      .then((response) => {
        dispatch(getPreferences({ tableSettings }));
        dispatch(
          registerMessage({
            name: "create-preference-message",
            type: "primary",
            title: "Ключ создан",
            closable: true,
          })
        );
        dispatch(showMessage("create-preference-message"));
        dispatch(
          closeMessage({
            name: "create-preference-message",
            delay: systemMessageDelay,
          })
        );
      })
      .catch((error) => {
        if (error?.response?.data === "Key already exists") {
          dispatch(
            registerMessage({
              name: "create-preference-error-message",
              type: "red",
              title: "Ошибка",
              text: "Ключ с таким названием уже существует",
              closable: true,
            })
          );
          dispatch(showMessage("create-preference-error-message"));
          dispatch(
            closeMessage({
              name: "create-preference-error-message",
              delay: systemMessageDelay,
            })
          );
        } else {
          dispatch(
            registerMessage({
              name: "create-preference-error-message",
              type: "red",
              title: "Ошибка",
              text: "Не удалось создать ключ",
              closable: true,
            })
          );
          dispatch(showMessage("create-preference-error-message"));
          dispatch(
            closeMessage({
              name: "create-preference-error-message",
              delay: systemMessageDelay,
            })
          );
        }
      });
  };
};

export const editPreferences = ({ formData, tableSettings }) => {
  return (dispatch) => {
    fetchInstance({
      method: "PUT",
      url: `${api.preference}`,
      data: {
        id: formData.id,
        key: formData.key,
        value: formData.value,
        description: formData.description,
      },
    })
      .then((response) => {
        dispatch(getPreferences({ tableSettings }));
        dispatch(
          registerMessage({
            name: "edit-preference-message",
            type: "primary",
            title: "Ключ отредактирован",
            closable: true,
          })
        );
        dispatch(showMessage("edit-preference-message"));
        dispatch(
          closeMessage({
            name: "edit-preference-message",
            delay: systemMessageDelay,
          })
        );
      })
      .catch((error) => {
        if (error?.response?.data === "Key already exists") {
          dispatch(
            registerMessage({
              name: "edit-preference-error-message",
              type: "red",
              title: "Ошибка",
              text: "Ключ с таким названием уже существует",
              closable: true,
            })
          );
          dispatch(showMessage("edit-preference-error-message"));
          dispatch(
            closeMessage({
              name: "edit-preference-error-message",
              delay: systemMessageDelay,
            })
          );
        } else {
          dispatch(
            registerMessage({
              name: "edit-preference-error-message",
              type: "red",
              title: "Ошибка",
              text: "Не удалось создать ключ",
              closable: true,
            })
          );
          dispatch(showMessage("edit-preference-error-message"));
          dispatch(
            closeMessage({
              name: "edit-preference-error-message",
              delay: systemMessageDelay,
            })
          );
        }
      });
  };
};

export const deletePreferences = (key, tableSettings) => {
  return (dispatch) => {
    fetchInstance({
      method: "DELETE",
      url: `${api.preference}?key=${key}`,
    })
      .then((response) => {
        dispatch(getPreferences({ tableSettings }));
        dispatch(
          registerMessage({
            name: "delete-preference-message",
            type: "primary",
            title: "Ключ удален",
            closable: true,
          })
        );
        dispatch(showMessage("delete-preference-message"));
        dispatch(
          closeMessage({
            name: "delete-preference-message",
            delay: systemMessageDelay,
          })
        );
      })
      .catch((error) => {
        dispatch(
          registerMessage({
            name: "delete-preference-error-message",
            type: "red",
            title: "Ошибка",
            text: "Не удалось удалить ключ",
            closable: true,
          })
        );
        dispatch(showMessage("delete-preference-error-message"));
        dispatch(
          closeMessage({
            name: "delete-preference-error-message",
            delay: systemMessageDelay,
          })
        );
      });
  };
};

export default settingsSlice.reducer;
