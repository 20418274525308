import {createSlice} from '@reduxjs/toolkit';
import {api} from "consts/api";
import {fetchInstance} from "wrappers/axios";
import {get, isEmpty, cloneDeep, find, join, map, flatten, isNil, first, findIndex} from "lodash";
import {findOrganizationsByElementId} from "utils/statistics";
import { findInTypeBySystem, /*, createSavedItemsParams*/ 
getHierarchyCheckboxParents,
updateHierarchyCheckboxParentsStatus} from 'utils';
import {catalogsActions} from "./catalogs";
import { tripleCheckboxState } from 'consts';

const initialState = {
    organization_admin_main_filials: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_main_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_statistics: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_filials: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    organization_admin_filial_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    organization_admin_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    organization_admin_branches_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    organization_admin_branches_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_branches_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_administrators: {
        administrators: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_statistics: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organizations: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filials: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_filial_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_branches_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    fund_admin_organization_branches_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_branches_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_organization_branches_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_managers: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_managers_active: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_managers_archived: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_main_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    registrator_main_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    registrator_main_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_main_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    registrator_main_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_doctors: {
        doctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        speciality:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_doctors_active: {
        doctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        speciality:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_doctors_archived: {
        doctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        speciality:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    registrator_doctors_patients: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        doctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        week:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        period:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_channels_archived: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        doctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        week:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        period:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_statistics: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    registrator_statistics_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_statistics_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_main_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    registrator_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_statistics: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_protocols: {
        // Пробуем интергировать tableSettings в этот редьюсер начиная с раздела Суперадмина
        tableSettings: {
            paging: {
                startIndex: 0,
                maxItems: 10
            },
            filtering: {
                fieldsFilter: {
                    status: 'active,draft,retired'
                }
            },
            searchString: '',
            sorting: [
                {
                    propertyName: 'title',
                    direction: 0
                }
            ]
        },
        protocols:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        diagnosis: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_protocol: {
        diagnosis: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_events: {
        tableSettings: {
            paging: {
                startIndex: 0,
                maxItems: 25
            },
            filtering: {
                fieldsFilter: {}
            },
            searchString: '',
            sorting: [
                {
                    propertyName: 'title',
                    direction: 0
                }
            ]
        },
        events:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        codes:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        codesTpp:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_event_logs: {
        events:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        descriptions: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        accounts: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            items:[]
        },
        date: {
            startDate: null,
            endDate: null
        },
        ipAddress:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_accounts: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            items:[]
        },
        email:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_accounts_active: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            items:[]
        },
        email:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_accounts_archived: {
        managers: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        roles: {
            items:[]
        },
        email:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_account_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_account_channels_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_settings: {
        tableSettings: {
            paging: {
                startIndex: 0,
                maxItems: -1
            },
            filtering:{
                searchString: null,
                fieldsFilter: {
                }
            },
            sorting: [
                {
                    direction: 0,
                    propertyName: "key"
                }
            ],
        },
        key:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        description: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
        // status:{
        //     items:[]
        // },
        // diagnosis: {
        //     allItems: [],
        //     checkedItems: [],
        //     savedItems: [],
        // },
    },
    super_admin_mailing_list: {
        date:{
            startDate: null,
            endDate: null
        },
        subject:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        recipient:{
            items:[]
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organizations: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filials: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_filial_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_branches_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    super_admin_organization_branches_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_branches_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    super_admin_organization_branches_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    organization_admin_filial_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    analyst_statistics: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_search_channels: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        profiles:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        period:{
            startDate: null,
            endDate: null
        },
        organizations:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_search_organizations: {
        organizations:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        parentOrganizations:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        profiles:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_search_employees: {
        employee:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        position:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_doctor_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_organization_filials: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_organization_branches: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_doctor: {
        patientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        diagnosis:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        protocol:{
            items:[]
        },
        status:{
            items:[]
        },
        trimester:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    fund_admin_managers_mailing_archive: {
        period:{
            startDate: null,
            endDate: null
        },
        saveThisPageFilter: false,
    },
    global_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    global_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_filial: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    global_filial_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_filial_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_filial_branch: {
        profiles: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        organizations: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        expanded: false,
        saveThisPageFilter: false,
    },
    global_filial_branch_timeexceeded: {
        timeExceededPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        timeExceededDoctors:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        saveThisPageFilter: false,
    },
    global_filial_branch_end: {
        endOfCounselingPatientName:{
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        endOfCounselingDoctors: {
            allItems: [],
            checkedItems: [],
            savedItems: [],
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_filial_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
    global_filial_branch_channel: {
        channelTasksPeriod:{
            startDate: null,
            endDate: null
        },
        status:{
            items:[]
        },
        saveThisPageFilter: false,
    },
}

export const tableFiltersSlice = createSlice({
    name: 'tableFilters',
    initialState,
    reducers: {
        setOrganizationProfileList: (state, action) => {
            state[action.payload.table].profiles.organizationProfileList = action.payload.payload;
        },
        checkProfile: (state, action) => {
            const id = action.payload.payload.id;
            const checked = action.payload.payload.checked;
            const targetElement = state[action.payload.table].profiles.organizationProfileList.find(item => (item.code === id));
            const checkElements = (el, checked) => {
                el.checked = checked;
            }
            checkElements(targetElement, checked);

            if (state[action.payload.table].profiles.organizationProfileList.every(item => (item.checked === 0))){
                state[action.payload.table].profiles.checkedAll = 0;
            } else if (state[action.payload.table].profiles.organizationProfileList.every(item => (item.checked === 1))) {
                state[action.payload.table].profiles.checkedAll = 1;
            } else {
                state[action.payload.table].profiles.checkedAll = 2;
            }
        },
        checkProfilesAll: (state, action) => {
            const changeChecked = (arr, value) => {
                arr.forEach(item => {
                    item.checked = value;
                    if (item.childElements) changeChecked(item.childElements, value);
                })
            }
            changeChecked(state[action.payload.table].profiles.organizationProfileList, action.payload.payload);
            state[action.payload.table].profiles.checkedAll = action.payload.payload;
        },
        setOrganizationsForFilter: (state, action) => {
            state[action.payload.table].organizations.organizationsList = action.payload.payload;
        },
        setOrganizationsAllItems: (state, action) => {
            const { value, table } = action.payload;
            const allItemsCopy = value;
            const savedItemsCopy = state[table].organizations.savedItems;
            const checkElements = arr => {
                arr.forEach(item => {
                    const targetElement = findOrganizationsByElementId(allItemsCopy, item.id);
                    targetElement.checked = item.checked;
                    if (item.childElements) item.childElements.map(item => checkElements(item.childElements));
                });
            }
            if (!isEmpty(savedItemsCopy)) checkElements(savedItemsCopy);
            state[table].organizations.checkedItems = savedItemsCopy;
            state[table].organizations.allItems = allItemsCopy;
        },
        setCheckedOrganizationItem: (state, action) => {
            const { item, table } = action.payload;
            const checked = action.payload.item.checked;
            const allItemsCopy = cloneDeep(state[table].organizations.allItems);
            const targetElement = findOrganizationsByElementId(allItemsCopy, item.id);
            const checkElementsInside = (el, checked) => {
                el.checked = checked;
                if (el.childElements) el.childElements.map(item => checkElementsInside(item, checked));
            }
            checkElementsInside(targetElement, checked);
            const checkElementsOutside = (el, checked) => {
                let parent;
                if (el.organization.partOf) {
                    parent = findOrganizationsByElementId(allItemsCopy, el.organization.partOf.id);
                    if (parent) {
                        if (checked) {
                            // console.log("1");
                            if (parent.childElements.find(item => (item.checked === 0 || item.checked === 2))) {
                                // console.log("2");
                                parent.checked = 2;
                            } else {
                                // console.log("3");
                                parent.checked = 1;
                            }
                        } else {
                            // console.log("4");
                            if (parent.childElements.find(item => (item.checked === 1 || item.checked === 2))) {
                                // console.log("5");
                                parent.checked = 2;
                            } else {
                                // console.log("6");
                                parent.checked = 0;
                            }
                        }
                        if (parent.organization.partOf) checkElementsOutside(parent, checked);
                    }
                }
            }
            checkElementsOutside(targetElement, checked);

            const setCheckedElements = (dataObj) => {
                const arr = [];
                dataObj.forEach(item => {
                    if (item.checked === 1) {
                        arr.push(item);
                    } else if (item.checked === 2) {
                        const itemCopy = cloneDeep(item);
                        const childItems = setCheckedElements(item.childElements);
                        delete itemCopy.childElements;
                        itemCopy.childElements = childItems;
                        arr.push(itemCopy);
                    }
                });
                return arr;
            }
            state[table].organizations.allItems = allItemsCopy;
            state[table].organizations.checkedItems = setCheckedElements(allItemsCopy);
        },
        resetFilters: (state, action) => {
            if(!state[action.payload].saveThisPageFilter){
                state[action.payload] = initialState[action.payload];
            }
        },
        setFilterCheckedItems: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].checkedItems = value;
        },
        setFilterSavedItems: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].savedItems = value;
            // if (state[table].tableSettings) {
            //     state[table][filterName].savedItems = value;
            //     if (!action.payload.autoComplete) isEmpty(value) ? delete state[table].tableSettings.filtering.fieldsFilter[filterName] : state[table].tableSettings.filtering.fieldsFilter[filterName] = createSavedItemsParams(value);

            // }
        },
        setAllFilterItems: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].allItems = value;
        },
        setFilterItems: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].items = value;
        },
        setCheckedItem: (state, action) => {
            const { filterName, item, table } = action.payload;
            const clickedItem = cloneDeep(item);
            const checkedValue = clickedItem.checked;
            const allItemsCopy = cloneDeep(state[table][filterName].allItems);
            const checkedItem = allItemsCopy.find(checked => {
                if (checked.id) {
                    return checked.id === clickedItem.id
                } else {
                    return checked.code === clickedItem.code
                }
            });
            const checkedItemsCopy = cloneDeep(state[table][filterName].checkedItems);
            if (checkedValue) {
                if (checkedItem) checkedItem.checked = false;
                state[table][filterName].allItems = allItemsCopy;
                state[table][filterName].checkedItems = checkedItemsCopy.filter(thisItem => {
                    if (thisItem.id) {
                        return thisItem.id !== clickedItem.id
                    } else {
                        return thisItem.code !== clickedItem.code
                    }
                });
            } else {
                if (checkedItem) checkedItem.checked = true;
                state[table][filterName].allItems = allItemsCopy;
                checkedItemsCopy.push(checkedItem);
                state[table][filterName].checkedItems = checkedItemsCopy;
            }
        },
        setStartDateForPeriodFilter: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].startDate = value;
        },
        setEndDateForPeriodFilter: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].endDate = value;
        },
        // clearFilters: state => initialState,
        setSaveThisPageFilter: (state, action) => {
            const { value, table } = action.payload;
            state[table].saveThisPageFilter = value;
        },
        clearCertainFilter: (state, action) => {
            if (!state[action.payload].saveThisPageFilter) {
                state[action.payload] = initialState[action.payload]
            }
        },
        clearFilters: (state, action) => {
            return initialState
        },
        setExpanded: (state, action) => {
            state[action.payload.table].expanded = action.payload.value
        },
        setPage: (state, action) => {
            state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
        },
        setMaxItems: (state, action) => {
            state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
        },
        setSorting: (state, action) => {
            console.log("action.payload", action.payload);
            state[action.payload.table].tableSettings.sorting[0].propertyName = action.payload.propertyName;
            state[action.payload.table].tableSettings.sorting[0].direction = state[action.payload.table].tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setProtocolsTitlesToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.title = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.title;
        },
        setEventsTitlesToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.title = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.title;
        },
        setEventsCodesToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.id = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.id;
        },
        setEventsCodesTppToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.tppcode = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.tppcode;
        },
        setSearchString: (state, action) => {
            state[action.payload.table].tableSettings.searchString = action.payload.payload;
        },
        setEventsPage: (state, action) => {
            state[action.payload.table].tableSettings.paging.startIndex = (action.payload.payload - 1) * state[action.payload.table].tableSettings.paging.maxItems
        },
        setEventsMaxItems: (state, action) => {
            state[action.payload.table].tableSettings.paging.maxItems = action.payload.payload;
        },
        setProtocolsContextToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.profileCode = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.profileCode;
        },
        setStatusesToFilter: (state, action) => {
            state[action.payload.table].tableSettings.filtering.fieldsFilter.status = action.payload.value;
        },
        setSettingKeysToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.key = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.key;
        },
        setSettingDescriptionToFilter: (state, action) => {
            action.payload.payload ? state[action.payload.table].tableSettings.filtering.fieldsFilter.description = action.payload.payload : delete state[action.payload.table].tableSettings.filtering.fieldsFilter.description;
        },
        setParamsToFilter: (state, action) => {
            state[action.payload.table].tableSettings.filtering.fieldsFilter[action.payload.field] = action.payload.params;
        },
        clearCertainFilterUnit: (state, action) => {
            state[action.payload.table][action.payload.filter] = initialState[action.payload.table][action.payload.filter];
        },
        setDateForDateFilter: (state, action) => {
            const { filterName, value, table } = action.payload;
            state[table][filterName].date = value;
        },
        setCheckedHierarchyItem: (state, action) => {
            const { filterName, item, table } = action.payload;
            let allItemsCopy = cloneDeep(state[table][filterName].allItems);
            let checkItemsCopy = cloneDeep(state[table][filterName].checkedItems);

            const setChildChecked = (item, state) => {
                item?.content && item?.content?.forEach(el => {
                    el.checked = state;
                    if(el?.content){
                        setChildChecked(el, state);
                    }
                })
            }

            const updateCheckedItems = (parents, checkItemsCopy, itemInArr) => {
                
                const rootParent = first(parents);
                const isRoot = isEmpty(parents);
                const existRootIndex = findIndex(checkItemsCopy, el => !isRoot ? el?.id === rootParent?.id : el?.id === itemInArr?.id);

                const changeItemsCopy = (root) => {
                    if(root?.checked === tripleCheckboxState.CHECKED || root?.checked === tripleCheckboxState.PARTICALLY_CHECKED){
                        if(!isNil(existRootIndex)){
                            checkItemsCopy.splice(existRootIndex, 1, root);
                        }else{
                            checkItemsCopy.push(root);
                        }
                    }else{
                        if(!isNil(existRootIndex)){
                            checkItemsCopy.splice(existRootIndex, 1);
                        }
                    }
                }

                if(!isRoot){
                    if(rootParent){
                        changeItemsCopy(rootParent);
                    }
                }else{
                    if(itemInArr){
                        changeItemsCopy(itemInArr);
                    }
                }
            }

            const itemInArr = get(allItemsCopy, item?.path);
            const parents = getHierarchyCheckboxParents(item, allItemsCopy);

            const newState = (isNil(itemInArr?.checked) || itemInArr?.checked === tripleCheckboxState.UNCHECKED) ? tripleCheckboxState.CHECKED : tripleCheckboxState.UNCHECKED
            
            setChildChecked(itemInArr, newState);
            itemInArr.checked = newState;
            updateHierarchyCheckboxParentsStatus(parents);
            updateCheckedItems(parents, checkItemsCopy, itemInArr);

            state[table][filterName].allItems = allItemsCopy;
            state[table][filterName].checkedItems = checkItemsCopy;
        },
    },
});

export const selectOrganizationAdminMainFilialsFilters = state => state.tableFilters.organization_admin_main_filials;
export const selectOrganizationAdminMainBranchesFilters = state => state.tableFilters.organization_admin_main_branches;
export const selectOrganizationAdminStatisticsFilters = state => state.tableFilters.organization_admin_statistics;
export const selectOrganizationAdminFilialsFilters = state => state.tableFilters.organization_admin_filials;
export const selectOrganizationAdminFilialFilters = state => state.tableFilters.organization_admin_filial;
export const selectOrganizationAdminFilialBranchesFilters = state => state.tableFilters.organization_admin_filial_branch;
export const selectOrganizationAdminBranchesFilters = state => state.tableFilters.organization_admin_branches;
export const selectFundAdminStatisticsFilters = state => state.tableFilters.fund_admin_statistics;
export const selectFundAdminOrganizationsFilters = state => state.tableFilters.fund_admin_organizations;
export const selectFundAdminOrganizationFilialsFilters = state => state.tableFilters.fund_admin_organization_filials;
export const selectFundAdminOrganizationBranchesFilters = state => state.tableFilters.fund_admin_organization_branches;
export const selectFundAdminManagersFilters = state => state.tableFilters.fund_admin_managers;
export const selectFundAdminManagersArchivedFilters = state => state.tableFilters.fund_admin_managers_archived;
export const selectFundAdminChannelsFilters = state => state.tableFilters.fund_admin_channels;
export const selectFundAdminChannelsChannelFilters = state => state.tableFilters.fund_admin_channels_channel;
export const selectRegistratorMainBranchesFilters = state => state.tableFilters.registrator_main_branches;
export const selectRegistratorMainTimeexceededFilters = state => state.tableFilters.registrator_main_timeexceeded;
export const selectRegistratorMainEndFilters = state => state.tableFilters.registrator_main_end;
export const selectRegistratorMainBranchTimeexceededFilters = state => state.tableFilters.registrator_main_branch_timeexceeded;
export const selectRegistratorMainBranchEndFilters = state => state.tableFilters.registrator_main_branch_end;
export const selectRegistratorDoctorsFilters = state => state.tableFilters.registrator_doctors;
export const selectRegistratorDoctorsArchievedFilters = state => state.tableFilters.registrator_doctors_archived;
export const selectRegistratorDoctorsPatientsFilters = state => state.tableFilters.registrator_doctors_patients;
export const selectRegistratorChannelsFilters = state => state.tableFilters.registrator_channels;
export const selectRegistratorChannelsArchivedFilters = state => state.tableFilters.registrator_channels_archived;
export const selectRegistratorChannelsChannelFilters = state => state.tableFilters.registrator_channels_channel;
export const selectRegistratorStatisticsFilters = state => state.tableFilters.registrator_statistics;
export const selectSuperAdminAccountsFilters = state => state.tableFilters.super_admin_accounts;
export const selectSuperAdminAccountsArchievedFilters = state => state.tableFilters.super_admin_accounts_archived;
export const selectSaveThisPageFilter = table => state => state.tableFilters[table].saveThisPageFilter;
export const selectProtocolsTableSettings = state => state.tableFilters.super_admin_protocols.tableSettings;
export const selectProtocolsFilters = state => state.tableFilters.super_admin_protocols;
export const selectEventsFilters = state => state.tableFilters.super_admin_events;
export const selectEventsTableSettings = state => state.tableFilters.super_admin_events.tableSettings;
export const getTableSelectorByTableName = table => state => state.tableFilters[table];
export const selectSettingsTableSettings = state => state.tableFilters.super_admin_settings.tableSettings;
export const selectSettingsFilters = state => state.tableFilters.super_admin_settings;
export const TableFiltersActions = tableFiltersSlice.actions;

export const getOrganizationProfileListForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `/OrganizationProfile`,
            cancelToken: cancelTokenSource?.token
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.display,
                    // pre: item?.code,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'profiles',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getOrganizationProfileListForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `/OrganizationProfile/Filter`,
        data: {
            filtering: {
                searchString: searchString,
                fieldsFilter: {
                    ...params,
                    partof: params ? params.partof : null,
                }
            },
            paging: {
                startIndex: 0,
                maxItems: 0
            }
        }}).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.display,
                    // pre: item?.code,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'profiles',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getOrganizationsForFilterStatisticsNew = ({
        partof = '',
        type,
        table,
        ids,
        cancelTokenSource
    }) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.organizationHierarchy}?fg=partof::${partof}:x:type:missing::false${ids ? `:x:ids::${ids}` : ''}`,
        cancelToken: cancelTokenSource?.token
        }).then((response) => {
            // console.log("response", response.data);

            let items;

            if (type) {
                items = response.data.items.filter(item => {
                    return findInTypeBySystem(item?.organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === type;
                });
            } else {
                items = response.data.items;
            }

            const setAllElementsUnchecked = (dataObj, level) => {
                const objLevel = level ? level : 1;
                dataObj.forEach(item => {
                    item.checked = 0;
                    item.level = objLevel;
                    if (!isEmpty(item.childElements)) setAllElementsUnchecked(item.childElements, objLevel + 1);
                });
            }
            setAllElementsUnchecked(items);

            // console.log("items", items);
            dispatch(TableFiltersActions.setOrganizationsAllItems({
                value: items,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getManagersForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationHeads}`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params.tableSettings.fieldsFilter,
                            practitionerName: searchString
                        }
                },
            },
        }).then((response) => {

            const items = response.data.items;
            const adaptedItems = items.map(item => {
                const practitionerId = item?.practitionerId
                return {
                    ...item,
                    id: practitionerId,
                    label: get(item, `practitionerName`),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'managers',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getAdministratorsForFilterNew = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${params?.organizationId}/ChildHeads`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params.tableSettings.fieldsFilter,
                            practitionerName: searchString
                        }
                },
            },
        }).then((response) => {

            const items = response.data.items;
            const adaptedItems = items.map(item => {
                const practitionerId = item?.practitionerId
                return {
                    ...item,
                    id: practitionerId,
                    label: get(item, `practitionerName`),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getAccountsForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRoleWithQuery}`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 10
                },
                filtering: {
                    searchString: null,
                    fieldsFilter: {
                        ...params?.fieldsFilter,
                        [params.searchParam]: searchString
                    }
                },
                sorting: [
                    {
                        direction: 0,
                        propertyName: "Practitioner.name"
                    }
                ]
            },
        }).then((response) => {

            const items = response.data.items;
            const adaptedItems = items.map(item => {
                const practitionerId = item?.practitioner.id
                return {
                    ...item,
                    practitionerid: practitionerId,
                    label: params?.getLabel && params.getLabel({ data: response?.data, practitionerId, item }),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getManagersOrganizationsForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationHeads}`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params.tableSettings.fieldsFilter,
                            organizationName: searchString
                        }
                },
            },
        }).then((response) => {
            const items = response.data.items;
            console.log("items", items)
            const adaptedItems = items.map(item => {
                const organizationId = item?.organizationId
                return {
                    ...item,
                    id: organizationId,
                    label: get(item, `organizationName`),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'organizations',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getAdministratorsOrganizationsForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${params?.organizationId}/ChildHeads`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params.tableSettings.fieldsFilter,
                            organizationName: searchString
                        }
                },
            },
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                const organizationId = item?.organizationId
                return {
                    ...item,
                    id: organizationId,
                    label: get(item, `organizationName`),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getManagerRolesForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationHeads}`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params?.tableSettings?.fieldsFilter,
                            positionName: searchString
                        }
                },
            },
        }).then((response) => {
        const items = response.data.items;
        const adaptedItems = items.map(item => {
            return {
                ...item,
                code: item.practitionerRolePositionCode,
                label: item?.practitionerRolePosition,
                pre: item?.practitionerRolePositionCode,
                checked: false,
            }
        });
        dispatch(TableFiltersActions.setAllFilterItems({
            filterName: 'roles',
            value: adaptedItems,
            table
        }));
    }).catch(err => {
        console.log(err)
    });
    };
};

export const getAdministratorsRolesForFilterNew = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${params?.organizationId}/ChildHeads`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                        fieldsFilter: {
                            ...params?.tableSettings?.fieldsFilter,
                            positionName: searchString
                        }
                },
            },
        }).then((response) => {
        const items = response.data.items;
        const adaptedItems = items.map(item => {
            return {
                ...item,
                code: item.practitionerRolePositionCode,
                label: item?.practitionerRolePosition,
                pre: item?.practitionerRolePositionCode,
                checked: false,
            }
        });
        dispatch(TableFiltersActions.setAllFilterItems({
            filterName,
            value: adaptedItems,
            table
        }));
    }).catch(err => {
        console.log(err)
    });
    };
};

export const getDoctorsForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${params.organizationId}/Doctors`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        practitionerName: searchString,
                },
            }}}).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.practitionerId,
                    label: item?.practitionerName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'doctors',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getSpecialitiesForFilterNew = ({searchString, table, params}) => {

    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${params.organizationId}/Doctors`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        specialtyFilter: searchString,

                },
            }}}).then((response) => {
                const items = response.data.items;
                const adaptedItems = items.map(item => {
                    return {
                        ...item,
                        code: item?.specialtyCode,
                        label: item?.specialtyName,
                        checked: false,
                    }
                });
                dispatch(TableFiltersActions.setAllFilterItems({
                    filterName: 'speciality',
                    value: adaptedItems,
                    table
                }));
            }).catch(err => {
                console.log(err)
            });
    };
};

export const getPatientsForModalNew = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.practitionerStatistic}/${params.practitionerRoleId}/Patient/Filter`,
        data: {
            filtering: {
                searchString: searchString,
                fieldsFilter: {
                    // extended: true,
                    // approle: 'AppRole/doctor',
                    // status: "planned, onleave, in-progress, finished",
                }
            },
            sorting: [
                {
                    propertyName: "name",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 0
            },
            cancelToken: cancelTokenSource?.token
        }}).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.patientId,
                    label: item?.patientName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'patientName',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getMkb10ForFilterNew = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.mkb10}?ss=${searchString}`,
            cancelToken: cancelTokenSource?.token
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.display,
                    pre: item?.code,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'diagnosis',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getWeekOfPregnancyForFilterNew = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.weekOfPregnancy}?ss=${searchString}`,
            cancelToken: cancelTokenSource?.token
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: get(item, 'property["week-of-pregnancy"]["trimester-of-pregnancy"].display'),
                    pre: item?.display,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'week',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRegistratorTimeExceededTableBranchPatientsForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/PatientsResponseTimeExceeded`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        patientName: searchString
                    },
                },
            }
            }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.patientId,
                    label: item?.patientName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: params.filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRegistratorTimeExceededTableBranchDoctorsForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/PatientsResponseTimeExceeded`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        practitionerName: searchString
                    },
                },
            }
            }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.practitionerRoleId,
                    label: item?.practitionerName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: params.filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRegistratorEndOfCounselingTableBranchPatientsForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/PatientsEndOfCounselingPeriod`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        patientName: searchString
                    },
                },
            }
            }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.patientId,
                    label: item?.patientName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: params.filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRegistratorEndOfCounselingTableBranchDoctorsForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/PatientsEndOfCounselingPeriod`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        practitionerName: searchString
                    },
                },
            }
            }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.practitionerRoleId,
                    label: item?.practitionerName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: params.filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPatientsForFilterNew = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/Encounters`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString,
                    fieldsFilter: {
                        ...params.fieldsFilter,
                        patientName: searchString,
                        eliminateDuplicates: true
                    }
                },
            }
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.patientId,
                    label: item?.patientName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'patientName',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getDoctorsForFilterFromEncounters = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${params.organizationId}/Encounters`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    searchString,
                    fieldsFilter: {
                        ...params.fieldsFilter,
                        practitionerName: searchString,
                        eliminateDuplicates: true
                    }
                },
            }
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.practitionerId,
                    label: item?.practitionerName,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'doctors',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getOrganizationsForFilter = ({
    searchString,
    checkedItems = [],
    params = {},
    table,
    cancelTokenSource,
    filterName
}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    // searchString,
                    fieldsFilter: {
                        ...params?.fieldsFilter,
                        partof: params.partof || "",
                        profile: "",
                        type: params.type ? join(params.type, ',') : undefined,
                        'type:missing': params.typeMissing || params.typeMissing === null ? undefined : false,
                        ".name$contains": searchString
                    }
                },
            },
            cancelToken: cancelTokenSource?.token
        }).then((response) => {
            const initialItems = response.data?.items ? response.data.items : [];

            let items = [];
            initialItems.forEach(item => {
                if (params.type){
                    if(params.type.some(itm => findInTypeBySystem(item?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code === itm)){
                        items.push({
                            id: item.id,
                            label: item.name
                        });
                    }
                }else{
                    items.push({
                        id: item.id,
                        label: item.name
                    });
                }
            });

            checkedItems.forEach(checked => {
                const matched = items.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: filterName || 'organizations',
                value: items,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getProtocolTitlesForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}?fg=title::${searchString}`
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.display,
                    pre: item?.code,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'protocols',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getProtocolProfilesForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}?fg=context::${searchString}`
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.display,
                    pre: item?.code,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'profiles',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const setProtocolsAutocompeleteInitialValues = ({diagnosis, filterName, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mkb10}/_search`,
            data: {
                paging: {
                startIndex: 0,
                maxItems: 10
            },
            filtering: {
                searchString: null,
                fieldsFilter: {
                    code: diagnosis
                }
            },
            sorting: [
                    {
                        direction: 0,
                        propertyName: "display"
                    }
                ]
            }
        }).then((response) => {
            const items = response.data.items;
            items.forEach(item => {
                item.checked = 1;
                item.label = item.display;
                item.pre = item.code;
            })
            dispatch(TableFiltersActions.setFilterSavedItems({
                filterName: filterName,
                value: items,
                table: table,
                autoComplete: true,
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getEventsTitlesForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activityDefinition}?fg=title::${searchString}`
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'events',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};
export const getEventsCodesForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activityDefinition}?fg=id::${searchString}`
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item?.id,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'codes',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getEventsCodesTppForFilter = ({searchString, checkedItems = [], params, table, cancelTokenSource}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activityDefinition}?fg=tppcode::${searchString}`
        }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    label: item ? find(get(item, `identifier`), {system: "urn:mmdx:onlinedoc:entity:activitydefinition:code"})?.value : '--',
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'codesTpp',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPreferencesKeysForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    console.log("searchString", searchString);
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.preference}/_search`,
        data: {
            sorting: [],
            filtering: {
                searchString: '',
                fieldsFilter: {
                    key: searchString
                }
            },
            paging: {
                startIndex: 0,
                maxItems: 0
            }
        }}).then((response) => {
            const items = response.data;
            const adaptedItems = items.map((item, index) => {
                return {
                    ...item,
                    label: item?.key,
                    code: item?.key,
                    id: index,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'key',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPreferencesDescriptionsForFilterNew = ({searchString, checkedItems = [], params, table}) => {
    console.log("searchString", searchString);
    return (dispatch) => {
      fetchInstance({
        method: "POST",
        url: `${api.preference}/_search`,
        data: {
            sorting: [],
            filtering: {
                searchString: '',
                fieldsFilter: {
                    description: searchString
                }
            },
            paging: {
                startIndex: 0,
                maxItems: 0
            }
        }}).then((response) => {
            const items = response.data;
            const adaptedItems = items.map((item, index) => {
                return {
                    ...item,
                    label: item?.description,
                    code: item?.description,
                    id: index,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: 'description',
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getEncountersForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: api.encounters,
            data: {
                sorting: [],
                filtering: {
                    fieldsFilter: {
                        ...params?.fieldsFilter,
                        "subject:Patient.name": searchString,
                        "_include": "subject"
                    },
                },
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
            }}).then((response) => {
            const items = get(response, 'data.items');
            const resources =  get(response, 'data.resources');
            console.log(items);
            const adaptedItems = items.map((item, index) => {
                return {
                    ...item,
                    label: get(resources, `${item?.subject?.reference}.name[0].text`),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getEventsLogListForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: api.eventLogsList,
            data: {
                sorting: [{
                    direction: 0,
                    propertyName: params.searchParam
                }],
                filtering: {
                    searchString: "",
                    fieldsFilter: {
                        ...params?.fieldsFilter,
                        [params.searchParam]: searchString
                    },
                },
                paging: {
                    startIndex: 0,
                    maxItems: 25
                }
            }}).then((response) => {
            const items = get(response, 'data.items');
            const adaptedItems = items.map((item) => {
                return {
                    ...item,
                    label: params?.getLabel && params.getLabel({ item }),
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getAllPositionsForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    console.log("searchString", searchString);
    return (dispatch) => {
        const managerPositions = fetchInstance({
            method: "POST",
            url: `${api.practitionRoles}/_search`,
            data: {
                sorting: [],
                filtering: {
                    searchString
                },
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
        }})

        const doctorPositions = fetchInstance({
            method: "POST",
            url: `${api.doctorsSpecialities}/_search`,
            data: {
                sorting: [],
                filtering: {
                    searchString
                },
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
        }})

        Promise.all([doctorPositions, managerPositions]).then((values) => {
            const items = flatten(map(values, 'data.items'))
            const adaptedItems = items.map((item, index) => {
                return {
                    ...item,
                    label: item?.display,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.code === checked.code);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getMailingBySubjectForFilter = ({searchString, checkedItems = [], params, table}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.mailingList}/GetCollection`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 0
                },
                filtering: {
                    fieldsFilter: {
                        ...params.tableSettings.fieldsFilter,
                        subject: searchString
                    },
                },
            }
            }).then((response) => {
            const items = response.data.items;
            const adaptedItems = items.map(item => {
                return {
                    ...item,
                    id: item?.id,
                    label: item?.subject,
                    checked: false,
                }
            });

            checkedItems.forEach(checked => {
                const matched = adaptedItems.find(item => item.id === checked.id);
                if (matched) matched.checked = true;
            });

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName: params.filterName,
                value: adaptedItems,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRecipientsForFilter = ({searchString, checkedItems = [], params, table, filterName}) => {
    // console.log("searchString", searchString);

    return (dispatch) => {
        const managerPositions = fetchInstance({
            method: "POST",
            url: `${api.practitionRoles}/_search`,
            data: {
                sorting: [],
                filtering: {
                    searchString
                },
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
        }})

        // const doctorPositions = fetchInstance({
        //     method: "POST",
        //     url: `${api.doctorsSpecialities}/_search`,
        //     data: {
        //         sorting: [],
        //         filtering: {
        //             searchString
        //         },
        //         paging: {
        //             startIndex: 0,
        //             maxItems: 0
        //         }
        // }})

        Promise.all([managerPositions]).then((values) => {
            // const items = flatten(map(values, 'data.items'))
            const practitionerRoles = get(values, '[0].data.items', []);
            const result = [];
            let haveCheckedPrectitionerRole = 0;
            let checkedPractitionerRoleLength = 0;

            const adaptedRoles = practitionerRoles.map((item, index) => {
                return {
                    ...item,
                    label: item?.display,
                    checked: tripleCheckboxState.UNCHECKED,
                    path: `[0].content[${index}]`,
                    parentPath: '[0]',
                }
            });

            checkedItems.forEach(checked => {
                const matchedRole = adaptedRoles.find(item => item.code === checked.code);
                if (matchedRole){
                    matchedRole.checked = tripleCheckboxState.CHECKED;
                    haveCheckedPrectitionerRole = null; //Частично заполненная группа
                    checkedPractitionerRoleLength++;
                }
            });

            !isEmpty(practitionerRoles) && result.push(                    {
                id: 'hierarchy_category_employees',
                label: 'Сотрудники',
                content: adaptedRoles,
                checked: checkedPractitionerRoleLength === adaptedRoles?.length ? 1 : haveCheckedPrectitionerRole,
                path: '[0]'
            })

            dispatch(TableFiltersActions.setAllFilterItems({
                filterName,
                value: result,
                table
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default tableFiltersSlice.reducer;
