import React from 'react';

const LogoOnlineDocText = ({size}) => (
    <svg width={size?.width || 94} height={size?.height || 32} viewBox="0 0 94 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.40582 31.0848C6.45122 31.0848 5.57827 30.9148 4.78697 30.5748C4.00822 30.2348 3.33624 29.7758 2.77102 29.1978C2.2058 28.6085 1.76619 27.9512 1.45218 27.2258C1.15072 26.4892 1 25.7355 1 24.9648C1 24.1602 1.16329 23.3952 1.48986 22.6698C1.81643 21.9332 2.2686 21.2815 2.84638 20.7148C3.42416 20.1368 4.10242 19.6835 4.88117 19.3548C5.67248 19.0148 6.52659 18.8448 7.4435 18.8448C8.39809 18.8448 9.26476 19.0205 10.0435 19.3718C10.8348 19.7232 11.5068 20.1935 12.0595 20.7828C12.6247 21.3722 13.058 22.0295 13.3595 22.7548C13.6735 23.4802 13.8305 24.2225 13.8305 24.9818C13.8305 25.7865 13.6672 26.5572 13.3406 27.2938C13.0266 28.0192 12.5807 28.6708 12.0029 29.2488C11.4252 29.8155 10.7406 30.2632 9.9493 30.5918C9.17055 30.9205 8.32273 31.0848 7.40582 31.0848ZM3.14783 24.9648C3.14783 25.7242 3.32368 26.4438 3.67537 27.1238C4.02706 27.7925 4.51692 28.3365 5.14494 28.7558C5.78552 29.1638 6.54543 29.3678 7.42466 29.3678C8.09036 29.3678 8.68698 29.2432 9.21451 28.9938C9.74205 28.7445 10.1879 28.4102 10.5522 27.9908C10.9165 27.5602 11.1928 27.0842 11.3812 26.5628C11.5696 26.0415 11.6638 25.5088 11.6638 24.9648C11.6638 24.2055 11.488 23.4915 11.1363 22.8228C10.7971 22.1542 10.3073 21.6158 9.66669 21.2078C9.02611 20.7885 8.27876 20.5788 7.42466 20.5788C6.74639 20.5788 6.14349 20.7035 5.61595 20.9528C5.08842 21.2022 4.64252 21.5365 4.27827 21.9558C3.91402 22.3752 3.63141 22.8455 3.43044 23.3668C3.24204 23.8882 3.14783 24.4208 3.14783 24.9648Z" fill="#009DE8"/>
        <path d="M24.4956 30.9998H22.4231V26.0188C22.4231 25.1802 22.2724 24.5738 21.9709 24.1998C21.6821 23.8145 21.2487 23.6218 20.6709 23.6218C20.269 23.6218 19.8671 23.7125 19.4651 23.8938C19.0758 24.0752 18.7241 24.3245 18.4101 24.6418C18.0961 24.9592 17.87 25.3162 17.7318 25.7128V30.9998H15.6593V22.1088H17.5434V23.8938C17.9202 23.3045 18.4666 22.8342 19.1825 22.4828C19.8985 22.1315 20.6898 21.9558 21.5565 21.9558C22.3352 21.9558 22.9381 22.1258 23.3652 22.4658C23.7922 22.7945 24.0874 23.2308 24.2507 23.7748C24.414 24.3188 24.4956 24.9195 24.4956 25.5768V30.9998Z" fill="#009DE8"/>
        <path d="M26.92 18.5898H28.9925V28.4328C28.9925 28.8862 29.0678 29.1752 29.2186 29.2998C29.3693 29.4245 29.5577 29.4868 29.7838 29.4868C30.035 29.4868 30.2736 29.4642 30.4997 29.4188C30.7258 29.3735 30.9142 29.3168 31.0649 29.2488L31.3664 30.7278C31.0649 30.8412 30.707 30.9375 30.2925 31.0168C29.878 31.0962 29.5074 31.1358 29.1809 31.1358C28.4775 31.1358 27.9248 30.9602 27.5229 30.6088C27.121 30.2462 26.92 29.7475 26.92 29.1128V18.5898Z" fill="#009DE8"/>
        <path d="M32.5681 20.6468V18.5898H34.6405V20.6468H32.5681ZM32.5681 30.9998V22.1088H34.6405V30.9998H32.5681Z" fill="#009DE8"/>
        <path d="M45.9673 30.9998H43.8948V26.0188C43.8948 25.1802 43.7441 24.5738 43.4426 24.1998C43.1538 23.8145 42.7204 23.6218 42.1426 23.6218C41.7407 23.6218 41.3388 23.7125 40.9368 23.8938C40.5475 24.0752 40.1958 24.3245 39.8818 24.6418C39.5678 24.9592 39.3417 25.3162 39.2035 25.7128V30.9998H37.131V22.1088H39.0151V23.8938C39.3919 23.3045 39.9383 22.8342 40.6542 22.4828C41.3702 22.1315 42.1615 21.9558 43.0281 21.9558C43.8069 21.9558 44.4098 22.1258 44.8368 22.4658C45.2639 22.7945 45.5591 23.2308 45.7224 23.7748C45.8856 24.3188 45.9673 24.9195 45.9673 25.5768V30.9998Z" fill="#009DE8"/>
        <path d="M52.8381 31.1698C51.8207 31.1698 50.9289 30.9602 50.1627 30.5408C49.3965 30.1215 48.7999 29.5662 48.3729 28.8748C47.9458 28.1722 47.7323 27.4072 47.7323 26.5798C47.7323 25.7412 47.9458 24.9705 48.3729 24.2678C48.7999 23.5652 49.3965 23.0042 50.1627 22.5848C50.9289 22.1655 51.827 21.9558 52.8569 21.9558C53.8869 21.9558 54.7787 22.1712 55.5323 22.6018C56.2859 23.0212 56.8637 23.5765 57.2656 24.2678C57.6801 24.9592 57.8874 25.7015 57.8874 26.4948C57.8874 26.7782 57.8685 27.0048 57.8309 27.1748H49.9366C49.9743 27.6962 50.1313 28.1552 50.4076 28.5518C50.6965 28.9372 51.0545 29.2432 51.4816 29.4698C51.9212 29.6852 52.3985 29.7928 52.9134 29.7928C53.4661 29.7928 53.9874 29.6682 54.4772 29.4188C54.9671 29.1695 55.3062 28.8408 55.4946 28.4328H57.4598L57.2656 28.8918C56.9265 29.5605 56.3613 30.1102 55.57 30.5408C54.7912 30.9602 53.8806 31.1698 52.8381 31.1698ZM49.8801 25.9338H55.8337C55.7835 25.4238 55.6202 24.9762 55.3439 24.5908C55.0676 24.1942 54.7096 23.8882 54.27 23.6728C53.8429 23.4462 53.3656 23.3328 52.8381 23.3328C52.3231 23.3328 51.8458 23.4462 51.4062 23.6728C50.9791 23.8882 50.6274 24.1942 50.3511 24.5908C50.0748 24.9762 49.9178 25.4238 49.8801 25.9338Z" fill="#009DE8"/>
        <path d="M59.7496 30.9998V18.9298H64.4975C65.9419 18.9298 67.1415 19.2018 68.096 19.7458C69.0632 20.2785 69.7917 21.0038 70.2816 21.9218C70.7714 22.8285 71.0163 23.8372 71.0163 24.9478C71.0163 26.1718 70.7463 27.2372 70.2062 28.1438C69.6661 29.0505 68.9062 29.7532 67.9265 30.2518C66.9593 30.7505 65.8163 30.9998 64.4975 30.9998H59.7496ZM68.8685 24.9478C68.8685 24.0978 68.6927 23.3498 68.341 22.7038C68.0018 22.0578 67.5057 21.5478 66.8526 21.1738C66.212 20.7998 65.427 20.6128 64.4975 20.6128H61.8598V29.3168H64.4975C65.4395 29.3168 66.2308 29.1242 66.8714 28.7388C67.5245 28.3535 68.0207 27.8322 68.3598 27.1748C68.6989 26.5175 68.8685 25.7752 68.8685 24.9478Z" fill="#009DE8"/>
        <path d="M77.3999 31.1698C76.37 31.1698 75.4719 30.9602 74.7057 30.5408C73.9521 30.1102 73.368 29.5492 72.9536 28.8578C72.5391 28.1552 72.3318 27.3902 72.3318 26.5628C72.3318 25.7355 72.5391 24.9762 72.9536 24.2848C73.3806 23.5822 73.9709 23.0212 74.7246 22.6018C75.4908 22.1712 76.3825 21.9558 77.3999 21.9558C78.4173 21.9558 79.3028 22.1712 80.0565 22.6018C80.8227 23.0212 81.413 23.5822 81.8275 24.2848C82.2545 24.9762 82.4681 25.7355 82.4681 26.5628C82.4681 27.3902 82.2608 28.1552 81.8463 28.8578C81.4318 29.5492 80.8415 30.1102 80.0753 30.5408C79.3217 30.9602 78.4299 31.1698 77.3999 31.1698ZM74.4608 26.5798C74.4608 27.1465 74.5927 27.6565 74.8565 28.1098C75.1202 28.5632 75.4719 28.9202 75.9115 29.1808C76.3511 29.4415 76.8473 29.5718 77.3999 29.5718C77.94 29.5718 78.4299 29.4415 78.8695 29.1808C79.3217 28.9088 79.6797 28.5462 79.9434 28.0928C80.2072 27.6395 80.3391 27.1295 80.3391 26.5628C80.3391 25.9962 80.2072 25.4862 79.9434 25.0328C79.6797 24.5795 79.3217 24.2225 78.8695 23.9618C78.4299 23.6898 77.94 23.5538 77.3999 23.5538C76.8598 23.5538 76.3637 23.6898 75.9115 23.9618C75.4719 24.2225 75.1202 24.5852 74.8565 25.0498C74.5927 25.5032 74.4608 26.0132 74.4608 26.5798Z" fill="#009DE8"/>
        <path d="M88.7714 31.1698C87.754 31.1698 86.8622 30.9602 86.0961 30.5408C85.3299 30.1102 84.7333 29.5435 84.3062 28.8408C83.8792 28.1382 83.6656 27.3732 83.6656 26.5458C83.6656 25.7185 83.8729 24.9592 84.2874 24.2678C84.7144 23.5652 85.3048 23.0042 86.0584 22.5848C86.8246 22.1655 87.7289 21.9558 88.7714 21.9558C89.7888 21.9558 90.6618 22.1598 91.3903 22.5678C92.1313 22.9645 92.684 23.4972 93.0483 24.1658L93.2829 24.7438H91.0323C90.8062 24.3698 90.4859 24.0808 90.0714 23.8768C89.6695 23.6615 89.2236 23.5538 88.7337 23.5538C88.1811 23.5538 87.6787 23.6842 87.2265 23.9448C86.7869 24.1942 86.4352 24.5455 86.1714 24.9988C85.9202 25.4522 85.7946 25.9678 85.7946 26.5458C85.7946 27.1238 85.9265 27.6395 86.1903 28.0928C86.4666 28.5462 86.8246 28.9088 87.2642 29.1808C87.7164 29.4415 88.2125 29.5718 88.7526 29.5718C89.255 29.5718 89.7323 29.4528 90.1845 29.2148C90.6367 28.9768 90.9381 28.6878 91.0888 28.3478H93.2829C93.2829 28.5195 93.1236 28.8918 93.1236 28.8918C92.7971 29.5605 92.2507 30.1102 91.4845 30.5408C90.7183 30.9602 89.814 31.1698 88.7714 31.1698Z" fill="#009DE8"/>
        <path d="M4.38698 1.0045L8.41177 10.084L12.4314 1.0045H14.7904V13.0027H12.915V4.32637C11.6555 7.21769 10.4082 10.1185 9.13334 13.0027H7.66932C6.38776 10.1237 5.14638 7.21649 3.88728 4.32637V13.0027H2V1.0045H4.38698Z" fill="#96D065"/>
        <path d="M22.6218 1.0045V2.73578H17.8881V13.0027H16V1.0045H22.6218Z" fill="#96D065"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M72.0986 2.6197C69.7675 2.6197 67.5848 4.57995 67.5848 6.96882C67.5848 9.41346 69.7919 11.4105 72.1703 11.4105C73.6414 11.4105 75.2305 10.6158 76.0119 9.34571H78.0844C76.9161 11.6122 74.7996 13.1999 72.1703 13.1999C68.7261 13.1999 65.7089 10.5785 65.7089 6.96882C65.7089 3.5338 68.7149 0.818848 72.086 0.818848C74.572 0.818848 77.1008 2.22763 77.9673 4.61537H75.9705C75.0819 3.31373 73.6625 2.66058 72.0986 2.6197Z" fill="#96D065"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.0479 13.1999H30.2439C33.6008 13.1999 36.7408 10.5279 36.7408 7.06145C36.7408 3.55378 33.679 0.830379 30.2439 0.830379H29.0479C25.6128 0.830379 22.551 3.55746 22.551 7.06145C22.551 10.525 25.6837 13.1999 29.0479 13.1999ZM28.7082 2.64276C26.4103 2.85036 24.4268 4.65765 24.4268 7.06145C24.4268 9.43787 26.4716 11.143 28.7082 11.399V2.64276ZM30.584 11.399V2.64276C32.8814 2.84986 34.8653 4.67638 34.8653 7.06145C34.8653 9.43895 32.8154 11.1436 30.584 11.399Z" fill="#96D065"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.9998 6.98035C50.9998 10.4956 47.9007 13.1999 44.4789 13.1999C41.1238 13.1999 37.982 10.5219 37.982 7.06145C37.982 3.56276 41.054 0.830379 44.4789 0.830379C47.8426 0.830379 50.9998 3.51396 50.9998 6.98035ZM39.8566 7.06145C39.8566 4.55541 42.0727 2.63166 44.5029 2.63166C46.8815 2.63166 49.1113 4.51699 49.1113 6.98035C49.1113 9.46507 46.9454 11.4105 44.5029 11.4105C42.1373 11.4105 39.8566 9.50962 39.8566 7.06145Z" fill="#96D065"/>
        <path d="M54.387 1.00179L58.4118 10.0813L62.4314 1.00179H64.7904V13H62.915V4.32367C61.6555 7.21498 60.4082 10.1158 59.1333 13H57.6693C56.3878 10.121 55.1464 7.21379 53.8873 4.32367V13H52V1.00179H54.387Z" fill="#96D065"/>
    </svg>

)

export default LogoOnlineDocText;
