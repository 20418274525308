import { useEffect } from "react";

export const useOutsideClickHandler = ({ref, callback}) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            event.stopPropagation();
            callback();
        }
      }
      // Bind the event listener
      document.body.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.body.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
}