import React, {useEffect, Suspense, useState} from 'react';
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {useHistory, useLocation} from 'react-router-dom';
import {routes} from "./consts/routes";
import PageBase from "components/PageBase";
import {useSelector, useDispatch} from "react-redux";
import {checkToken, checkRole, authLogout, AppActions, getDefaultImage} from "reducers/app";
import Page404 from "./pages/Page404";
import Loader from "./components/Loader";
import PageBaseDoctor from "./components/PageBaseDoctor";
import {
    selectOrganizationType,
    selectOrganizationProfileCode,
    UserActions,
    selectLoading as selectUserLoading
} from "reducers/user";
import ConsentPage from "./components/ConsentPage";
import {isEmpty, get} from "lodash";
import { selectMaintenanceState, selectLoading as selectAppLoading } from 'reducers/app';
import {CustomView, isDesktop, isMobileOnly, isTablet, MobileOnlyView} from 'react-device-detect';
import PageBaseDoctorMobile from "./components/PageBaseDoctorMobile";
import { Wrapper } from 'styled/Wrapper';
import { setErrorsInterceptor } from 'wrappers/axios';

const App = () => {
    let isAuth = useSelector(state => state.app.isAuth);
    let isConsent = useSelector(state => state.app.isConsent);
    let role = useSelector(state => state.app.role);
    let organizationProfileCode = useSelector(selectOrganizationProfileCode)
    let organizationType = useSelector(selectOrganizationType)
    let userType = useSelector(state => state.app.userType);
    let roles = useSelector(state => state.user.roles);
    let userId = useSelector(state => state.app.userId);
    let maintenance = useSelector(selectMaintenanceState);
    let appLoading = useSelector(selectAppLoading);
    let userLoading = useSelector(selectUserLoading);
    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const [prevRole, setPrevRole] = useState();

    useEffect(() => {
        dispatch(checkToken());
        dispatch(checkRole());
        setErrorsInterceptor({ dispatch });
    }, [dispatch]);

    useEffect(() => {
        if(isMobileOnly){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }, []);

    useEffect(() => {
        if (role) {
            dispatch(getDefaultImage('filial'));
            dispatch(getDefaultImage('user'));
        }
    }, [dispatch, role]);

    const setVh = () => {
        if(isMobileOnly || isTablet){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }

    useEffect(() => {
        setVh();
    }, []);

    useEffect(() => {
        window.addEventListener('load', setVh);
        window.addEventListener('resize', setVh);
        return () => {
            window.removeEventListener('load', setVh);
            window.removeEventListener('resize', setVh);
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(roles) && userId) {
            const thisRole = roles.find(item => item.id === userId);
            dispatch(UserActions.setRolename(get(thisRole, "specialization", '')));
        }
    }, [roles, role]);

    const redirect = () => {
        if (location.pathname === '/client' || (location.pathname === '/main' && prevRole === "doctor")) {
            history.push({
                pathname: routes.public.loginDoctor.path,
            });
            dispatch(AppActions.setUserType(null));
        } else {
            if (`${window.location.pathname}` !== `${routes.public.loginManager.path}`) {
                window.location.replace(`${window.location.origin}${routes.public.loginManager.path}`);
            }
            dispatch(AppActions.setUserType(null));
        }
    }

    useEffect(() => {
        if (location.pathname.includes('/acceptinvite') ||
            location.pathname.includes('/forgot') ||
            location.pathname.includes('/newpassword')) {
            dispatch(authLogout());
        }

        //Редирект на экран сервисного режима
        if(!appLoading.maintenance && maintenance){
            if(
                (maintenance?.isOnMaintenance === true && location.pathname !== '/manager' && location.pathname !== '/client')
            ){
                history.push(routes.public.techPage.path);
                dispatch(authLogout());
            }
        }

        if (
            (location.pathname.indexOf('/forgot') === -1)
            && (location.pathname.indexOf('/acceptinvite') === -1)
            && (location.pathname.indexOf('/newpassword') === -1)
            && (location.pathname.indexOf('/mobile-app') === -1)
            && (location.pathname.indexOf('/service_in_progress') === -1)
            && (location.pathname.indexOf('/privacy') === -1)
        ) {
            // Редирект на страницы логина
            if (isAuth === false) {
                if(location.pathname === '/client/role' || location.pathname === '/manager/role'){
                    if(!maintenance?.isOnMaintenance){
                        redirect();
                    }
                }else{
                    redirect();
                }
            }
            setPrevRole(role);

            // Редирект на страницу логина если неверный токен
            if (isAuth === true && role === false && !userType) {
                if (`${window.location.pathname}` !== `${routes.public.loginManager.path}` && `${window.location.pathname}` !== `${routes.public.loginDoctor.path}`) {
                    window.location.replace(`${window.location.origin}${routes.public.loginManager.path}`);
                }
                // history.push({
                //     pathname: routes.public.loginManager.path,
                // });
            }

            // Редирект на страницы ролей
            if (isAuth === true && role === false && userType === 'manager') {
                history.push({
                    pathname: routes.public.rolesManager.path,
                });
            }
            if (isAuth === true && role === false && userType === 'doctor') {
                history.push({
                    pathname: routes.public.rolesDoctor.path,
                });
            }

            // Редирект на главные страницы если залогинены
            if (isAuth === true && role && location.pathname === '/client') {
                history.push({
                    pathname: '/',
                });
            }
            if (isAuth === true && role && location.pathname === '/manager') {
                history.push({
                    pathname: '/',
                });
            }
        }
        setPrevRole(role);
    }, [history, isAuth, userType, role, maintenance?.isOnMaintenance, appLoading, userLoading]);

    const renderRoutes = (routes) => {
        return Object.values(routes).map(({component, path, componentProps}) => {
            const Component = component;
            return <Route
                exact={true}
                path={path}
                render={(props) => (
                    <Component {...props} {...componentProps} />
                )}
            />
        });
    }

    const renderMobileRoutes = (routes) => {
        return Object.values(routes).map(({component, path, componentProps, header, headerProps}) => {
            const Component = component;
            const Header = header;
            return <Route
                exact={true}
                path={path}
                render={(props) => (
                    <Wrapper>
                        {
                            Header &&
                            <Header {...headerProps} />
                        }
                        <Component {...props} {...componentProps}/>
                    </Wrapper>
                )}
            />
        });
    }

    // const filter = routes.private[role] && [...Object.keys(routes.private[role]), ...Object.keys(routes.private.globalSearch)].filter(item => {
    //     if(get(routes, `private.global.${item}`)){
    //         return (routes.private.globalSearch[item].path === location.pathname)
    //     }else{
    //         console.log(location.pathname, routes.private[role][item].path);
    //         return (routes.private[role][item].path === location.pathname)
    //             || ((routes.private[role][item].path === '/managers/manager-:id') && (location.pathname.indexOf('manager-') !== -1))
    //             || ((routes.private[role][item].path === '/statistics/channels-:practitionerRoleId') && (location.pathname.indexOf('/statistics/channels-') !== -1))
    //             || ((routes.private[role][item].path === '/organizations/organization-:id') && (location.pathname.indexOf('/organizations/organization-') !== -1))
    //             || ((routes.private[role][item].path === '/filials/filial-:filialId/branch-:branchId') && (location.pathname.indexOf('filials/filial-') !== -1) && (location.pathname.indexOf('branch-') !== -1))
    //             || ((routes.private[role][item].path === '/filials/filial-:filialId/branch-:branchId/channel-:encounterId') && (location.pathname.indexOf('filials/filial-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/filials/filial-:id/channel-:encounterId') && (location.pathname.indexOf('filials/filial-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/branches/branch-:id') && (location.pathname.indexOf('branch-') !== -1))
    //             || ((routes.private[role][item].path === '/branches/branch-:id/channel-:encounterId') && (location.pathname.indexOf('branches/branch-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/filials/filial-:id') && (location.pathname.indexOf('filial-') !== -1))
    //             || ((routes.private[role][item].path === '/doctors/doctor-:id') && (location.pathname.indexOf('doctor-') !== -1))
    //             || ((routes.private[role][item].path === '/statistics/channels-:practitionerRoleId/channel-:encounterId') && (location.pathname.indexOf('/statistics/channels-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/channels/channel-:encounterId') && (location.pathname.indexOf('/channels/channel-') !== -1))
    //             || ((routes.private[role][item].path === '/doctors/channel-:encounterId') && (location.pathname.indexOf('/doctors/channel-') !== -1))
    //             || ((routes.private[role][item].path === '/main/channel-:encounterId') && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/main/branch-:branchId') && (location.pathname.indexOf('branch-') !== -1))
    //             || ((routes.private[role][item].path === '/main/branch-:branchId/channel-:encounterId') && (location.pathname.indexOf('branch-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/protocols/protocol-:id') && (location.pathname.indexOf('protocol-') !== -1))
    //             || ((routes.private[role][item].path === '/accounts/account_fund_admin-:id') && (location.pathname.indexOf('account_fund_admin-') !== -1))
    //             || ((routes.private[role][item].path === '/accounts/account_doctor-:practitionerRoleId') && (location.pathname.indexOf('account_doctor-') !== -1))
    //             || ((routes.private[role][item].path === '/accounts/account_registrator-:id') && (location.pathname.indexOf('account_registrator-') !== -1))
    //             || ((routes.private[role][item].path === '/accounts/account_organization_admin-:id') && (location.pathname.indexOf('account_organization_admin-') !== -1))
    //             || ((routes.private[role][item].path === '/accounts/account_doctor-:practitionerRoleId/channel-:encounterId') && (location.pathname.indexOf('/accounts/account_doctor-') !== -1) && (location.pathname.indexOf('channel-') !== -1))
    //             || ((routes.private[role][item].path === '/channel-:encounterId') && (location.pathname.indexOf('/channel-') !== -1))
    //             || ((routes.private[role][item].path === '/organization-:id') && (location.pathname.indexOf('/organization-') !== -1))
    //     }
    // });

    const firstRoutePath = routes.private[role] && Object.keys(routes.private[role]).map(item => routes.private[role][item].path)[0];

    const filteredRoutesByFlags = routes.private[role] && Object.values(routes.private[role]).filter(item => {
        if (item.dontUseOn) {
            return !(item.dontUseOn.organizationType === organizationType &&
                item.dontUseOn.organizationProfile === organizationProfileCode)
        } else {
            return true
        }
    });

    const roleHaveglobalSearch = !!(routes.private[role] && Object.values(routes.private[role]).find(item => !!item.search))

    // const name = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].name : routes.private.globalSearch[item].name)) : undefined;
    // const Header = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].header : routes.private.globalSearch[item].header)) : undefined;
    // const Search = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].search : routes.private.globalSearch[item].search)) : undefined;
    // const headerProps = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].headerProps : routes.private.globalSearch[item].headerProps)) : undefined;
    // const searchProps = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].searchProps : routes.private.globalSearch[item].searchProps)) : undefined;
    // const backAction = filter ? last(filter.map(item => routes.private[role][item] ? routes.private[role][item].back : routes.private.globalSearch[item].back)) : undefined;

    return isAuth && role && (location.pathname !== '/mobile-app') && (location.pathname !== '/privacy') ?
        location.pathname === '/' ?
            <Redirect to={firstRoutePath}/>
            :
            role !== 'doctor' ?
                <PageBase
                    // title={name}
                    // header={Header ? () => <Header {...headerProps} /> : undefined}
                    // search={Search ? () => <Search {...searchProps} /> : undefined}
                    // backAction={backAction}
                    menu={[...filteredRoutesByFlags, ...(roleHaveglobalSearch ? Object.values(routes.private.globalSearch) : []), ...Object.values(routes.private.general)]}
                >
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {renderRoutes([...filteredRoutesByFlags, ...(roleHaveglobalSearch ? Object.values(routes.private.globalSearch) : []), ...Object.values(routes.private.general)])}
                            <Route component={Page404}/>
                        </Switch>
                    </Suspense>
                </PageBase>
                :
                isConsent ?
                    <>
                        <CustomView condition={isDesktop || isTablet} style={{ height: '100%' }}>
                            {location.pathname !== '/support' ?
                                    <PageBaseDoctor
                                        // title={name}
                                        // header={Header ? () => <Header {...headerProps} /> : undefined}
                                        // backAction={backAction}
                                        menu={[...filteredRoutesByFlags, ...Object.values(routes.private.globalSearch), ...Object.values(routes.private.general)]}
                                    >
                                        <Suspense fallback={<Loader/>}>
                                            <Switch>
                                                {renderRoutes([...filteredRoutesByFlags, ...Object.values(routes.private.general)])}
                                                <Route component={Page404}/>
                                            </Switch>
                                        </Suspense>
                                    </PageBaseDoctor>
                                    :
                                    renderRoutes(filteredRoutesByFlags)
                                }
                        </CustomView>
                        <MobileOnlyView style={{ height: '100%' }}>
                            <PageBaseDoctorMobile>
                                <Suspense fallback={<Loader/>}>
                                    <Switch>
                                        {renderMobileRoutes(routes.private.mobile_doctor)}
                                        <Route component={Page404}/>
                                    </Switch>
                                </Suspense>
                            </PageBaseDoctorMobile>
                        </MobileOnlyView>
                    </>
                    :
                    <ConsentPage/>
        : renderRoutes(routes.public)
}

export default App;
