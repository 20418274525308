import {useDispatch} from "react-redux"; 
import {hide, show, updateElementPosition} from "reducers/anchorPopup/anchorPopupSlice";
import {useCallback, useState} from "react";
import { isEmpty } from "lodash";

export function cloneEvent(e) {
    function ClonedEvent() {
    };
    let clone = new ClonedEvent();
    for (let p in e) {
        let d = Object.getOwnPropertyDescriptor(e, p);
        if (d && (!d.writable || !d.configurable || !d.enumerable || d.get || d.set)) {
            Object.defineProperty(clone, p, d);
        } else {
            clone[p] = e[p];
        }
    }
    Object.setPrototypeOf(clone, e);
    return clone;
}

const getBoundingClientRect = (element) => {
    if(element) {
        const {top, right, bottom, left, width, height, x, y} = element.getBoundingClientRect()
        return {top, right, bottom, left, width, height, x, y}
    }
    return {}
}

export const useAnchorPopup = ({component, props}) => {
    const dispatch = useDispatch();
    const [ev, setEv] = useState(null)

    const hideAnchorPopup = useCallback((e) => {
        return new Promise(resolve => {
            e ? e.stopPropagation() : ev && ev.stopPropagation();
            dispatch(hide())
            const clone = cloneEvent(e || ev);
            // setTarget(null)
            setEv(null)
            resolve({e: clone, elPos: getBoundingClientRect(e?.currentTarget || ev?.currentTarget)});
        })
    }, [dispatch])

    const showAnchorPopup = useCallback((ev, options, customProps) => {
        return new Promise(resolve => {

            const position = getBoundingClientRect(ev?.currentTarget)

            hideAnchorPopup(ev).then(({e, elPos}) => {
                if(!isEmpty(e)){
                    e.preventDefault();
                    e.persist();
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                    // setTarget(e.currentTarget)
                    setEv(cloneEvent(e))
                }
                dispatch(show({component, positionType: options?.positionType, elementPosition: position, props: {...props, ...customProps}}))
                resolve();
            })
        });
    }, [component, dispatch, hideAnchorPopup, props])

    const updatePosition = useCallback(() => {
        // ev && showAnchorPopup(ev)
        // ev && dispatch(hide())
        ev && dispatch(updateElementPosition({elementPosition: getBoundingClientRect(ev.currentTarget)}))
    }, [ev, showAnchorPopup])

    return [showAnchorPopup, hideAnchorPopup, updatePosition]
}
