import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import {useTooltip} from "../Tooltip/useTooltip";

const Text = ({tag, text, children, color, font, tooltip, overflow, textAlign, whiteSpace, LineThrough, wordBreak}) => {

    const [showTooltip, hideTooltip] = useTooltip({component: tooltip})

    const Tag = `${tag}`
    return (
        <TextWrapper
            overflow={overflow}
            color={color}
            font={font}
            onMouseEnter={tooltip && showTooltip}
            onMouseLeave={tooltip && hideTooltip}
            onClick={tooltip && hideTooltip}
            textAlign={textAlign}
            whiteSpace={whiteSpace}
            LineThrough={LineThrough}
            wordBreak={wordBreak}
            tag={tag}
        >
            <Tag>
                {children}
                {text}
            </Tag>
        </TextWrapper>
        )
};

const overflowCss = (overflow) => overflow ? css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
` : ''

const TextWrapper = styled.div`
  white-space: ${({whiteSpace}) => whiteSpace};
  ${({LineThrough}) => LineThrough && css`
    text-decoration: line-through;
    color: ${({color}) => color};
  `};

  h1 {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.h1, color})};
    margin: 0;
    ${({overflow}) => overflowCss(overflow)}
  }
  
  h2 {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.h2, lineheight: theme.fonts.lineHeight.h2, color})};
    margin: 0;
    ${({overflow}) => overflowCss(overflow)}
  }
  
  h3 {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.h3, lineheight: theme.fonts.lineHeight.h3, color})};
    margin: 0;
    ${({overflow}) => overflowCss(overflow)}
  }
  
  h4 {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.h4, lineheight: theme.fonts.lineHeight.h4, color})};
    margin: 0;
    ${({overflow}) => overflowCss(overflow)}
  }
  
  h5 {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.h5, lineheight: theme.fonts.lineHeight.h5, color})};
    margin: 0;
    ${({overflow}) => overflowCss(overflow)}
  }
  
  h6 {
    ${({overflow}) => overflowCss(overflow)}
  }
  
  p {
    ${({overflow}) => overflowCss(overflow)}
  }
  
  span {
    ${({theme, font, color}) => theme.mixins[font]({color})}
    ${({overflow}) => overflowCss(overflow)}
  }
  
  small {
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.middle, color})};
    ${({overflow}) => overflowCss(overflow)}
  }
  text{
    ${({theme, font, color}) => theme.mixins[font]({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.normal, color})};
    ${({overflow}) => overflowCss(overflow)}
  }

  ${({overflow}) => overflow && css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
  ${({textAlign}) => textAlign && css`
     text-align: center;
  `}
  ${({wordBreak}) => wordBreak && css`
     word-break: ${wordBreak};
  `}
`

Text.propTypes = {
    tag: PropTypes.oneOf(['h1', 'h2' ,'h3', 'h4', 'h5', 'h6', 'p', 'span', 'small', 'text']),
    text: PropTypes.string,
    children: PropTypes.any,
    color: PropTypes.string,
    font: PropTypes.oneOf(['robotoRegular', 'robotoMedium', 'robotoCondensedRegular', 'robotoCondensedBold']),
};

Text.defaultProps = {
    tag: 'span',
    color: '#000',
    font: 'robotoMedium',
    whiteSpace: 'none',
}

export default Text;
