import { rgba } from "polished";
import { calculateShadow } from "./utils/calculateShadow";

const opacity = {
    o100: 1,
    o87: 0.87,
    o76: 0.76,
    o54: 0.54,
    o40: 0.40,
    o30: 0.30,
    o24: 0.24,
    o12: 0.12,
    o08: 0.08,
    o04: 0.04,
}

const animationSpeed = 300

const theme = {
    animations: {
        animationSpeed: animationSpeed,
        speed: animationSpeed + 'ms',
        curve: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    },
    colors: {
        border: '#E0E0E0',
        loginBackground: rgba(250, 251, 252, 0.5),
        primary: '#009DE8',
        primary24: rgba('#009DE8', opacity.o24),
        primary12: rgba('#009DE8', opacity.o12),
        primary08: rgba('#009DE8', opacity.o08),
        secondary: '#96D065',
        black100: '#000',
        black87: rgba('#000', opacity.o87),
        black76: rgba('#000', opacity.o76),
        black54: rgba('#000', opacity.o54),
        black40: rgba('#000', opacity.o40),
        black30: rgba('#000', opacity.o30),
        black24: rgba('#000', opacity.o30),
        black12: rgba('#000', opacity.o12),
        black08: rgba('#000', opacity.o08),
        black04: rgba('#000', opacity.o04),
        white100: '#fff',
        white70: rgba('#fff', 0.7),
        white50: rgba('#fff', 0.5),
        white04: rgba('#fff', 0.04),
        orange: '#FD9B10',
        purple: '#AF52DE',
        green: '#34C759',
        green12: rgba('#34C759', opacity.o12),
        red: '#FF3B30',
        red12: rgba('#FF3B30', opacity.o12),
        tooltip: rgba(51, 51, 51, 0.96),
        transparent: 'transparent',
        mark: '#FFCC00'
    },
    opacity,
    fonts: {
        family: {
            robotoRegular: `Roboto, Arial, sans-serif`,
            robotoCondensedRegular: `'Roboto Condensed', Arial, sans-serif`,
        },
        sizes: {
            text: '14px',
            label: '12px',
            tiny: '10px',
            big: '28px',
            h1: '24px',
            h2: '22px',
            h3: '20px',
            h4: '18px',
            h5: '16px',
        },
        lineHeight: {
            h1: '36px',
            h2: '32px',
            h3: '28px',
            h4: '26px',
            h5: '24px',
            tiny: '16px',
            small: '18px',
            normal: '20px',
            middle: '22px',
            big: '38px',
        },
    },
    margins: {
        row: {
            bottom: '12px',
        }
    },
    other: {
        borderRadius: '6px',
        modalBorderRadius: '12px',
        chatBorderRadius: '14px',
    },
    mixins: {
        robotoRegular: ({size = theme.fonts.sizes.text, color = theme.colors.black100, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes.text, color: theme.colors.black100, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.robotoRegular};
            font-weight: normal;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        robotoMedium: ({size = theme.fonts.sizes.text, color = theme.colors.black100, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes.text, color: theme.colors.black100, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.robotoRegular};
            font-weight: 500;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        robotoBold: ({size = theme.fonts.sizes.text, color = theme.colors.black100, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes.text, color: theme.colors.black100, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.robotoRegular};
            font-weight: 700;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        robotoCondensedRegular: ({size = theme.fonts.sizes.text, color = theme.colors.black100, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes.text, color: theme.colors.black100, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.robotoCondensedRegular};
            font-weight: normal;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        robotoCondensedBold: ({size = theme.fonts.sizes.text, color = theme.colors.black100, lineheight = theme.fonts.lineHeight.normal} = {size: theme.fonts.sizes.text, color: theme.colors.black100, lineheight: theme.fonts.lineHeight.normal}) => `
            font-family: ${theme.fonts.family.robotoCondensedRegular};
            font-weight: bold;
            font-size: ${size};
            color: ${color};
            line-height: ${lineheight || (parseInt(size) * 1.5) + 'px'};
        `,
        shadowDark2dp: () => `
            box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24);
        `,
        shadowDark4dp: () => `
            box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.24);
        `,
        shadowDark8dp: () => `
            box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
        `,
        shadowDark16dp: () => `
            box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.16);
        `,
        shadowDark24dp: () => `
            box-shadow: 0px 24px 32px rgba(8, 35, 48, 0.12);
        `,
        borderRadius: (radius) => `
            border-radius: ${radius ? radius : theme.other.borderRadius};
        `,
        fontSize: (value) => `
            font-size: ${theme.fonts.sizes[value]};
            line-height: ${theme.fonts.sizes[value] * 1.5};
        `,
        innerShadow: (props) => calculateShadow(props)
    }
}

export { theme };

