import React from 'react';

const EventIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 4H18V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM12 15C12 13.8954 12.8954 13 14 13H15C16.1046 13 17 13.8954 17 15V16C17 17.1046 16.1046 18 15 18H14C12.8954 18 12 17.1046 12 16V15Z" />
    </svg>

)

export default EventIcon;
