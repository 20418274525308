import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import TableText from "../TableText";
import TableChannelsTooltip from "./TableChannelsTooltip";
import {useTooltip} from "components/Tooltip/useTooltip";

const TableChannels = ({stats}) => {

    const [showTooltip, hideTooltip] = useTooltip({component: TableChannelsTooltip, props: stats})

    return (
        <Wrapper direction={'row'} align={'center'} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} width={'auto'}>
            <TableText  text={stats.amount} type={'secondary'} />
            <TableText text={'・'} type={'secondary'} />
            <TableText text={stats.doesNotMatch} type={'alert'} />
        </Wrapper>
    );
};

TableChannels.propTypes = {
    stats: PropTypes.object.isRequired,
};

export default TableChannels;
