import {createSlice} from "@reduxjs/toolkit";
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {find, get} from "lodash";
import SecureLS from "secure-ls";
import { findInTypeBySystem } from "utils";

const ls = new SecureLS();

const initialState = {
  username: '',
  email: '',
  rolename: '',
  practitionerId: null,
  roles: [],
  organizationId: null,
  organizationType: null,
  organizationProfileCode: null,
  organizationProfileDisplay: null,
  parent: {
    organization: null,
    clinic: null,
  },
  loading: {
    roles: true
  }
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setUsername: (state, action) => {
        state.username = action.payload;
      },
      setEmail: (state, action) => {
        state.email = action.payload;
      },
      setOrganizationId: (state, action) => {
        state.organizationId = action.payload;
      },
      setOrganizationType: (state, action) => {
        state.organizationType = action.payload;
      },
      setOrganizationProfileCode: (state, action) => {
        state.organizationProfileCode = action.payload;
      },
      setOrganizationProfileDisplay: (state, action) => {
        state.organizationProfileDisplay = action.payload;
      },
      setRoles: (state, action) => {
        state.roles = action.payload;
      },
      setRolename: (state, action) => {
        state.rolename = action.payload;
      },
      setParent: (state, action) => {
        const { type, organizationData } = action.payload;
        state.parent[type] = organizationData;
      },
      setPractitionerId: (state, action) => {
        state.practitionerId = action.payload;
      },
      setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
      },
      resetState: (state, action) => {
        return initialState
      },
    },
});
  
export const selectRoles = state => state.user.roles;
export const selectCurrentRole = state => state.app.role;
export const selectUsername = state => state.user.username;
export const selectEmail = state => state.user.email;
export const selectOrganizationId = state => state.user.organizationId;
export const selectOrganizationType = state => state.user.organizationType;
export const selectOrganizationProfileCode = state => state.user.organizationProfileCode;
export const selectOrganizationProfileDisplay = state => state.user.organizationProfileDisplay;
export const selectParentOrganizations = state => state.user.parent;
export const selectPractitionerId = state => state.user.practitionerId;
export const selectLoading = state => state.user.loading;
export const UserActions = userSlice.actions;

export default userSlice.reducer;

export const getCurrentUserManagerRoles = () => {
  return (dispatch) => {
      dispatch(UserActions.setLoading({
        type: 'roles',
        value: true
      }));
      fetchInstance({
          method: "GET",
          url: `${api.appRole}`,
      }).then((response) => {
          const appRoles = response.data.items;
          fetchInstance({
              method: "GET",
              url: api.getCurrentUserManagerRoles,
          }).then((response) => {
              // console.log("response", response.data);
              const organizationId = ls.get('organizationId');
              const fillManagersDataObj = dataObj => {
                const arr = [];
                dataObj.forEach((item) => { 
                    let specialization;

                    const organization = get(response.data, `resources[${get(item, "organization.reference")}]`);

                    if (findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.code) {
                      specialization = findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.display;
                    } else {
                      let ref = find(item?.extension ,{url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference;
                      specialization = appRoles.find(item => item.id === ref.replace("AppRole/", "")).title;
                    }
                    arr.push({
                        id: get(item, "id"),
                        specialization: specialization,
                        department: get(organization, `name`),
                        hospital: findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.display,
                        roleId: find(item?.extension ,{url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference &&
                          find(item?.extension ,{url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference.replace('AppRole/',''),
                        practitionerId: get(item, `practitioner.id`),
                        organizationId: get(item, `organization.id`),
                        organizationType: findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code,
                        organizationProfileCode: findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code,
                        organizationProfileDisplay: findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.display,
                        organizationPartOf: get(organization, `partOf.id`)
                    })
                });
                return arr;
              }
              dispatch(UserActions.setRoles(fillManagersDataObj(get(response.data, "items", []))));
              dispatch(UserActions.setUsername(get(response.data, `resources[${get(response.data, "items[0].practitioner.reference")}].name[0].text`) || ''));
              dispatch(UserActions.setEmail(find(get(response.data, `items[0].telecom`), {system: "email"})?.value || ''));
              if (organizationId) dispatch(UserActions.setOrganizationId(organizationId));
              dispatch(UserActions.setLoading({
                type: 'roles',
                value: false
              }));
          }).catch(err => {
            console.log(err)
            dispatch(UserActions.setLoading({
              type: 'roles',
              value: false
            }));
        });
      }).catch(err => {
        console.log(err)
        dispatch(UserActions.setLoading({
          type: 'roles',
          value: false
        }));
    });
  };
};

export const getCurrentUserDoctorRoles = () => {
  return (dispatch) => {
      dispatch(UserActions.setLoading({
        type: 'roles',
        value: true
      }));
      fetchInstance({
          method: "GET",
          url: api.getCurrentUserDoctorRoles,
      }).then((response) => {
          // console.log("response", response.data);

          const fillManagersDataObj = dataObj => {
            const arr = [];
            dataObj.forEach((item) => { 
              const organization = get(response.data, `resources[${get(item, "organization.reference")}]`)
              
                arr.push({
                    id: get(item, "id"),
                    specialization: findInTypeBySystem(item?.specialty, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-practitioner-role-specialty')?.display,
                    department: get(organization, `name`),
                    hospital: findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.display,
                    roleId: find(item?.extension ,{url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference &&
                      find(item?.extension ,{url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference.replace('AppRole/',''),
                    organizationId: get(item, `organization.id`),
                })
            });
            return arr;
          }

          dispatch(UserActions.setRoles(fillManagersDataObj(get(response.data, "items", []))));
          dispatch(UserActions.setUsername(get(response.data, `resources[${get(response.data, "items[0].practitioner.reference")}].name[0].text`) || ''));
          dispatch(UserActions.setEmail(find(get(response.data, `items[0].telecom`), {system: "email"})?.value || ''));
          dispatch(UserActions.setLoading({
            type: 'roles',
            value: false
          }));
      }).catch(err => {
        console.log(err)
        dispatch(UserActions.setLoading({
          type: 'roles',
          value: false
        }));
    });
  };
};

export const getParentOrganization = ({id}) => {
  return (dispatch) => {
      fetchInstance({
          method: "GET",
          url: `${api.organization}/${id}?fg=type:missing::false`,
      }).then((response) => {

          const organization = get(response, 'data');

          const parentOrganizationType = findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank')?.code
          const parentOrganizationProfile = findInTypeBySystem(organization?.type, 'http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile')?.code

          if(organization && parentOrganizationType === 'organization') dispatch(UserActions.setParent({ type: 'organization', organizationData: {id: organization.id, profile: parentOrganizationProfile}}));
          if(organization && parentOrganizationType === 'clinic'){
            dispatch(UserActions.setParent({ type: 'clinic', organizationData: {id: organization.id, profile: parentOrganizationProfile}}));
            dispatch(UserActions.setParent({ type: 'organization', organizationData: {id: organization.partOf.id }}));
          }
      }).catch(err => {
        console.log(err)
    });
  };
};

export const updateOrganizationProfileData = ({profile}) => {
  return (dispatch) => {
    ls.set('organizationProfileCode', profile?.code);
    ls.set('organizationProfileDisplay', profile?.display);
    dispatch(UserActions.setOrganizationProfileCode(profile?.code))
    dispatch(UserActions.setOrganizationProfileDisplay(profile?.display))
  }
}