import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import SecondaryButton from "components/Button/SecondaryButton";

const DoctorProfileFooter = ({hideModal}) => {
    return (
        <Wrapper direction={'row'} justify={'flex-end'}>
            <SecondaryButton onClick={hideModal} label={'Закрыть'} />
        </Wrapper>
    );
};

DoctorProfileFooter.propTypes = {
    handleHide: PropTypes.func.isRequired,
};

export default DoctorProfileFooter;
