import {useDispatch} from "react-redux";
import {hide, show} from "reducers/tooltip/tooltipSlice";

export const useTooltip = ({component, props}) => {
    const dispatch = useDispatch();

    const showTooltip = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(show({component, elementPosition: e?.currentTarget?.getBoundingClientRect(), props}))
    }

    const hideTooltip = () => {
        dispatch(hide())
    }

    return [showTooltip, hideTooltip]
}
