import React, {Suspense} from 'react'; 
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import ActionIcon from "components/ActionIcon";
import MenuIcon from "components/Icons/MenuIcon";
import LogoOnlineDoc from "./LogoOnlineDoc";
import {Alignment} from "styled/Alignment";
import {Separator} from "styled/Separator";
import Avatar from "components/Avatar";
import TailArrowIcon from "components/Icons/TailArrowIcon";
import {useHistory, useLocation} from 'react-router-dom';
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import UserMenu from "components/UserMenu";
import {useSelector, useDispatch} from "react-redux";
import {AppActions} from "reducers/app";
import {Wrapper} from "styled/Wrapper";
import Loader from "components/Loader";
import UserMenuList from "./UserMenuList";
import SearchIcon from 'components/Icons/SearchIcon';

const Header = ({title, backAction, header, search, isOpen, adaptiveIsOpen, adaptiveMenuOn}) => {
    const username = useSelector(state => state.user.username);
    const rolename = useSelector(state => state.user.rolename);

    let CustomHeader = header
    let Search = search

    const renderSearch = (props) => (
        <Wrapper width="672px">
            <Suspense fallback={<Loader />}>
                <Search {...props} />
            </Suspense>
        </Wrapper>
    )

    const [showAnchorPopup] = useAnchorPopup({component: UserMenu, props: {username: username, role: rolename, menuList: UserMenuList}})
    const [showSearchPopup] = useAnchorPopup({component: renderSearch, props: { }})

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const handleMenu = () => {
        if(adaptiveMenuOn) {
            dispatch(AppActions.switchAdaptiveMenuOn(false))
        } else if (adaptiveIsOpen) {
            dispatch(AppActions.switchAdaptiveMenuOn(true))
        } else {
            dispatch(AppActions.toggleMenu())
        }
    }

    const handleBack = () => {
        location.customBackAction && location.customBackAction();
        history.goBack(-1)
    }

    return (
        <HeaderWrapper>
            <LeftSide>
                <MenuIconWrapper isOpen={isOpen} adaptiveIsOpen={adaptiveIsOpen}>
                    <ActionIcon icon={MenuIcon} action={handleMenu} tooltip={'Меню'} />
                </MenuIconWrapper>
                <LogoWrapper isOpen={isOpen} adaptiveIsOpen={adaptiveIsOpen}>
                    <LogoOnlineDoc />
                </LogoWrapper>
                {backAction && <BackAction>
                    <ActionIcon action={handleBack} icon={TailArrowIcon} rotate={90} tooltip={'Назад'} />
                </BackAction>}
                <Wrapper direction={'row'} width={'auto'} flex={'1'} minHeight={'40px'}>
                    <Alignment>
                        {title &&
                            <PageTitle>
                                {title ? title : location.state ? location.state.name : ''}
                            </PageTitle>
                        }
                        {header && (
                            <Suspense fallback={<Loader />}>
                                <CustomHeader />
                            </Suspense>
                        )}
                    </Alignment>
                </Wrapper>
            </LeftSide>
            <RightSide>
                <Separator vertical left={16} right={16} />
                {/* <IconWrapper>
                    <ActionIcon action={() => {}} icon={BellIcon} tooltip={'Оповещения'} />
                </IconWrapper> */}
                {search &&
                    <IconWrapper>
                        <ActionIcon action={(e) => showSearchPopup(e, { positionType: "left" })} icon={SearchIcon} tooltip={'Поиск'} />
                    </IconWrapper>
                }
                <Avatar fullname={username} onClick={showAnchorPopup} tooltip={'Меню пользователя'} />
            </RightSide>
        </HeaderWrapper>
    );
};

const BackAction = styled.div`
  margin: 0 16px 0 -12px;
`

const IconWrapper = styled.div`
  margin-right: 8px;
`


const MenuIconWrapper = styled.div`
  margin-right: 10px;
  transition: maring-right, ${({theme}) => theme.animations.speed} ${({theme}) => theme.animations.curve};
  
  ${({isOpen}) => isOpen && css`
      margin-right: 40px;
  `}

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
      margin-right: 40px;
  `}
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 16px;
  ${({theme}) => theme.mixins.innerShadow()};
  background-color: ${({theme}) => theme.colors.white100};
  height: 64px;
`

const PageTitle = styled.div`
  ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.h1, lineheight: theme.fonts.lineHeight.big})};
  margin-right: 12px;
  flex: 0 0 auto;
`

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const RightSide = styled.div`
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  width: 214px;
  overflow: hidden;
  transition: opacity, width, margin-right, .3s ${({theme}) => theme.animations.curve};
  
  ${({isOpen}) => isOpen && css`
    opacity: 0;
    width: 0;
  `};

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
    opacity: 0;
    width: 0;
  `};
`

Header.propTypes = {
    title: PropTypes.string.isRequired,
    backAction: PropTypes.bool,
    selectors: PropTypes.bool,
};

Header.defaultProps = {
    backAction: false,
    selectors: false,
}

export default Header;
