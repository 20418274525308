import React, {useState} from 'react'; 
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import {AnchorHeader} from "styled/AnchorHeader";
import {AnchorFooter} from "styled/AnchorFooter";
import styled, {css} from "styled-components";
import Group from "components/Group";
import PrimaryButton from "components/Button/PrimaryButton";
import SecondaryButton from "components/Button/SecondaryButton";
import Calendar from "components/Calendar";
import dayjs from "dayjs";
import Input from "components/Input/Input";
import ArrowIcon from "components/Icons/ArrowIcon";
import ActionIcon from "components/ActionIcon";
import { isNil } from 'lodash';

const DateAndTime = ({handleHide, onAccept, label, fromDate, toDate, fromTime, toTime, showTimePicker = true, acceptButtonLabel, cancelButtonLabel, formObject}) => {

    let fromHour = fromTime && dayjs(fromTime).get('hour');
    let fromMinute = fromTime && dayjs(fromTime).get('minute');
    let toHour = toTime && dayjs(toTime).get('hour');
    let toMinute = toTime && dayjs(toTime).get('minute');

    if(fromMinute > 55) {
        fromMinute = 0;
        fromHour++;
    }

    if(fromHour > 23) {
        fromHour = 0;
    }

    const roundedFromMinute = fromMinute && Math.ceil(fromMinute/5)*5;

    const [date, setDate] = useState(dayjs().set('date', 1).toDate());
    const [periodElement, setPeriodElement] = useState('date');
    const [activeStartDate, setActiveStartDate] = useState(dayjs().toDate());
    const [time, setTime] = useState({h: isNil(fromHour) ? 8 : fromHour, m: fromMinute ? roundedFromMinute : 0});
    const [startH, setStartH] = useState(time.h);
    const [startM, setStartM] = useState(time.m > 30 ? 30 : time.m);

    /*Создаем массивы часов и минут*/
    let arrH = [];
    let arrM = [];

    let i = 0
    let newStartH = startH;
    let newStartM = startM;
    while (i < 6) {
        arrH.push(newStartH);
        arrM.push(newStartM);
        newStartH+=1;
        newStartM+=5;
        i++
    }

    const changeVisibleMonth = (direction, month, year) => {
        setDate(state => {
            return new Date(!isNil(year) ? year : state.getFullYear(), !isNil(month) ? month : state.getMonth() + direction, 1);
        })
    }

    const handleClickDay = (date) => {
        setActiveStartDate(new Date(date.year, date.month, date.day));
    }

    const handleChangeField = () => {
        setDate(activeStartDate);
    }

    const handleOnAccept = (e) => {
        onAccept && onAccept({
            date: activeStartDate,
            time: showTimePicker ? time : undefined 
        }, formObject)
        handleHide();
    }

    const checkDisabledTimeItem = (item, fromValue, toValue) => {
        if(fromValue){
            return item < fromValue
        }
        if(toValue){
            return item > toValue
        }
        return false;
    }

    return (
        <Wrapper>
            <AnchorHeader>
                {label}
            </AnchorHeader>
            <Content>
                <Wrapper paddings={'6px 16px'} margins={'0 0 12px 0'} direction={'row'} justify={'space-between'}>
                    <Wrapper width={showTimePicker ? 'calc(50% - 6px)' : '100%'} onClick={() => setPeriodElement('date')}>
                        <Input underline={periodElement === 'date'} value={dayjs(activeStartDate).locale("ru").format("D MMMM YYYY")} readonly dontDisableText handleChangeField={handleChangeField} textAlign={'center'} />
                    </Wrapper>
                    {showTimePicker && 
                        <Wrapper width={'calc(50% - 6px)'} onClick={() => setPeriodElement('time')}>
                            <Input underline={periodElement === 'time'} value={time.h+':'+time.m.toString().padStart(2, '0')} readonly dontDisableText handleChangeField={handleChangeField} textAlign={'center'} />
                        </Wrapper>
                    }
                </Wrapper>
                {periodElement === 'date' &&
                    <CalendarWrapper>
                        <Calendar onClick={handleClickDay} fromDate={fromDate} toDate={toDate} onChange={changeVisibleMonth} date={date} activeDate={activeStartDate} />
                    </CalendarWrapper>
                }
                {periodElement === 'time' && showTimePicker &&
                    <TimeWrapper>
                        <TimeLine>
                            <ActionIcon icon={ArrowIcon} size={'32px'} rotate={180} action={() => setStartH(state => state > 0 ? state-1 : state)} />
                            <Wrapper paddings={'3px 0'} align={'center'}>
                                {arrH.map(item => <TimeLineItem onClick={() => {!checkDisabledTimeItem(item, fromHour, toHour) && setTime({h: item, m: time.m})}} disabled={checkDisabledTimeItem(item, fromHour, toHour)} active={time.h === item}>{item}</TimeLineItem>)}
                            </Wrapper>
                            <ActionIcon icon={ArrowIcon} size={'32px'} action={() => setStartH(state => state < 18 ? state+1 : state)} />
                        </TimeLine>
                        <TimeLine>
                            <ActionIcon icon={ArrowIcon} size={'32px'} rotate={180} action={() => setStartM(state => state > 0 ? state-5 : state)} />
                            <Wrapper paddings={'3px 0'} align={'center'}>
                                {arrM.map(item => <TimeLineItem onClick={() => {!checkDisabledTimeItem(item, fromMinute, toMinute) && setTime({h: time.h, m: item})}} disabled={checkDisabledTimeItem(item, fromMinute, toMinute)} active={time.m === item}>{item.toString().padStart(2, '0')}</TimeLineItem>)}
                            </Wrapper>
                            <ActionIcon icon={ArrowIcon} size={'32px'} action={() => setStartM(state => state < 30 ? state+5 : state)} />
                        </TimeLine>
                    </TimeWrapper>
                }
            </Content>
            <AnchorFooter>
                <Group gap={8}>
                    <Group.Item>
                        <PrimaryButton onClick={handleOnAccept} label={acceptButtonLabel} />
                    </Group.Item>
                    <Group.Item>
                        <SecondaryButton onClick={handleHide} label={cancelButtonLabel} />
                    </Group.Item>
                </Group>
            </AnchorFooter>
        </Wrapper>
    );
};

const CalendarWrapper = styled.div`
    padding: 0 12px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
`

const TimeWrapper = styled.div`
    display: flex;
    padding: 0 12px;
`

const TimeLine = styled.div`
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    flex-direction: column;
`

const TimeLineItem = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 0;
    ${({theme}) => theme.mixins.robotoRegular({lineheight: 0})}
    
    ${({active, disabled}) => active && !disabled && css`
        background: ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.white100};
    `}

    ${({disabled}) => disabled ?
    css`
        color: ${({theme}) => theme.colors.black54};
        cursor: unset;
    `
    :
    css`
        cursor: pointer;
    `
    }
    
`

DateAndTime.defaultProps = {
    acceptButtonLabel: 'Применить',
    cancelButtonLabel: 'Отмена'
}

DateAndTime.propTypes = {
    label: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    handleHide: PropTypes.func.isRequired,
};

export default DateAndTime;
