import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {Suspense, useReducer} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './font-face.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from "styled-components";
import {theme} from "./theme";
import {GlobalStyle} from "./GlobalStyle";
import {BrowserRouter as Router} from "react-router-dom";
import Modal from "components/Modal";
import ModalCarousel from "components/ModalCarousel";
import Tooltip from "components/Tooltip";
import AnchorPopup from "components/AnchorPopup";
import SystemMessages from "./components/SystemMessages";
import Loader from "./components/Loader";
import { isTablet, isMobileOnly } from "react-device-detect";

export const ConnectionContext = React.createContext();

const ConnectionProviderWrapper = ({children}) => {

    const initialState = { connection: null }

    const reducer = (state, action) => {
        switch (action.type){
            case 'connection_set_up': return { connection: action.payload }
            case 'connection_clear': return { connection: null }
            default: throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ConnectionContext.Provider value={{dispatch, state}}>
            {children}
        </ConnectionContext.Provider>
    )
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <ConnectionProviderWrapper>
            <Router>
                <ThemeProvider theme={{ ...theme, isMobile: isMobileOnly, isTablet }}>
                  <GlobalStyle />
                  <Suspense fallback={<Loader />}>
                      <App />
                  </Suspense>
                  <Modal />
                  <ModalCarousel />
                  <Tooltip />
                  <AnchorPopup />
                  <SystemMessages />
                </ThemeProvider>
            </Router>
        </ConnectionProviderWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
