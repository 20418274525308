import React, { useEffect, useRef, useState } from "react"; 
import styled from "styled-components";
import {
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List
} from 'react-virtualized';
import {isNil, isNumber } from "lodash";
import { AutoSizer } from "react-virtualized";
import Spinner from "components/Spinner";

const InfiniteList = ({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage,

  propHeight,
  propWidth,

  threshold,
  scrollToRow,
  setInfinitieListRefToParent

}) => {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const cache = new CellMeasurerCache({
    // defaultHeight: 100,
    fixedWidth: true,
  });

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => {
    return !hasNextPage || index < items.length
  };

  const infifitieLoaderRef = useRef();

  const [scrollToIndex, setScrollToIndex] = useState(-1);

  // Render an item or a loading indicator.
  const rowRenderer = ({ index, style, key, parent }) => {

    const ContentComponent = items[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        overscanRowCount={3}
      >
        {({measure}) => {
          return (<div key={key} style={style} className="row">
            {!isItemLoaded(index) ? <SpinnerWrapper><Spinner/></SpinnerWrapper> : <ContentComponent highlightData={!isNil(scrollToRow) ? scrollToRow : null} onMeasureChanged={measure}/>}
          </div>)
        }}
      </CellMeasurer>
    );
  };

  const clearScrollToIndex = () => {
    setScrollToIndex(-1);
  }

  useEffect(() => {
    if(!isNil(scrollToRow) && isNumber(scrollToRow.listIndex)) {
      setTimeout(() => setScrollToIndex(scrollToRow.listIndex), 100);
    }
  }, [scrollToRow])

  useEffect(() => {
    setInfinitieListRefToParent && setInfinitieListRefToParent(infifitieLoaderRef)
  }, [])

  return (
    <InfiniteLoader
      isRowLoaded={({index}) => isItemLoaded(index)}
      rowCount={100000}
      loadMoreRows={loadMoreItems}
      threshold={threshold}
      ref={infifitieLoaderRef}
    >
      {({ onRowsRendered, ref }) => (
          <AutoSizer
            disableHeight={!!propHeight}
            disableWidth={!!propWidth}
          >
            {({height, width}) => (
              <List
                ref={ref}
                deferredMeasurementCache={cache}
                className="List"
                width={propWidth || width}
                height={propHeight || height}
                rowCount={itemCount}
                onRowsRendered={onRowsRendered}
                scrollToIndex={scrollToIndex}
                scrollToAlignment="center"
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                onScroll={clearScrollToIndex}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        )
      }
    </InfiniteLoader>
  );
}

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 76px;
`

export default InfiniteList;
