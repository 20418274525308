import React from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from 'styled-components';
import {darken, rgba} from "polished";
import Icon from "components/Icon";

const Button = ({
                    onClick,
                    label,
                    buttonType,
                    disabled,
                    icon,
                    rotate,
                    active,
                    showIcon,
                    fullWidth,
                    reverse,
                    withoutPaddings,
                    iconPosition,
                    type,
                    iconSize
                }) => {

    const theme = useTheme();

    const calculateIconSize = () => {
        if (iconSize) {
            return iconSize;
        } else {
            return buttonType === 'filter' ? 16 : 18;
        }
    }

    return (
        <ButtonWrapper active={active} type={type} buttonType={buttonType} onClick={!disabled ? onClick : undefined}
                       disabled={disabled} icon={!!icon} fullWidth={fullWidth} reverse={reverse}
                       withoutPaddings={withoutPaddings}>
            <Label>
                {label}
            </Label>
            {icon && showIcon && <IconWrapper rotate={rotate} reverse={reverse}>
                <Icon icon={icon} size={calculateIconSize()} color={renderColor(theme, buttonType)}/>
            </IconWrapper>}
        </ButtonWrapper>
    );
};

const renderColor = (theme, type) => {
    switch (type) {
        case 'primary':
            return theme.colors.white100
        case 'primaryLight':
            return theme.colors.primary
        case 'secondary':
            return theme.colors.primary
        case 'red':
            return theme.colors.white100
        case 'redOutline':
            return theme.colors.red
        case 'warning':
            return theme.colors.white100
        case 'grey':
            return theme.colors.black54
        case 'filter':
            return theme.colors.black54
        case 'link':
            return theme.colors.primary
        case 'rounded':
            return theme.colors.white100
        default:
            return null
    }
}

const renderBackground = (theme, type) => {
    switch (type) {
        case 'primary':
            return theme.colors.primary
        case 'primaryLight':
            return theme.colors.primary12
        case 'secondary':
            return theme.colors.white100
        case 'red':
            return theme.colors.red
        case 'redOutline':
            return theme.colors.white100
        case 'warning':
            return theme.colors.red
        case 'grey':
            return theme.colors.white100
        case 'filter':
            return theme.colors.white100
        case 'link':
            return theme.colors.transparent
        case 'rounded':
            return theme.colors.primary
        default:
            return null
    }
}

const ButtonWrapper = styled.button`
  display: flex;
  width: ${({fullWidth}) => fullWidth && '100%'};
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  border: 0;
  outline: none;
  flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
  padding: ${({
                icon,
                buttonType
              }) => icon ? buttonType === 'filter' ? '5px 8px 5px 12px' : '6px 12px 6px 8px' : '6px 12px'};
  ${({theme}) => theme.isMobile && css`
    padding: ${({icon, buttonType}) => buttonType === 'filter' ? '5px 8px 5px 12px' : '8px 12px'};
  `};
  ${({withoutPaddings}) => withoutPaddings && css`
    padding: 0;
  `};
  border-radius: ${({theme, buttonType}) => buttonType !== "rounded" ? theme.other.borderRadius : '50px'};
  background-color: ${({
                         theme,
                         buttonType,
                         disabled
                       }) => disabled ? buttonType === 'link' ? theme.colors.transparent : theme.colors.black04 : renderBackground(theme, buttonType)};
  ${({
       theme,
       buttonType,
       disabled
     }) => theme.mixins.robotoMedium({
    color: disabled ? theme.colors.black30 : renderColor(theme, buttonType),
    size: buttonType === 'filter' ? theme.fonts.sizes.label : theme.fonts.sizes.text,
    lineheight: buttonType === 'filter' ? theme.fonts.lineHeight.small : theme.fonts.lineHeight.normal
  })};
  ${({disabled, buttonType}) => buttonType === 'redOutline' && disabled && css`
    background-color: transparent;
  `}
  ${({buttonType}) => buttonType === 'link' && css`
    ${({
         theme,
         buttonType,
         disabled
       }) => theme.mixins.robotoRegular({
      color: disabled ? theme.colors.black30 : renderColor(theme, buttonType),
      size: theme.isMobile ? theme.fonts.sizes.h5 : theme.fonts.sizes.text,
      lineheight: theme.fonts.lineHeight.normal
    })};
  `};

  ${({active}) => active && css`
    ${({disabled}) => !disabled && css`
      background-color: ${({
                             theme,
                             buttonType
                           }) => buttonType === 'secondary' ? rgba(renderBackground(theme, 'primary'), theme.opacity.o12) : darken(theme.opacity.o08, renderBackground(theme, buttonType))};
    `}
  `};
  
  :hover {
    ${({disabled}) => !disabled && css`
      background-color: ${({theme, buttonType}) => darken(theme.opacity.o04, renderBackground(theme, buttonType))};
    `};

    ${({active}) => active && css`
      background-color: ${({
                             theme,
                             buttonType
                           }) => buttonType === 'secondary' ? rgba(renderBackground(theme, 'primary'), theme.opacity.o12) : darken(theme.opacity.o08, renderBackground(theme, buttonType))};
    `};
  }

  :active {
    ${({disabled}) => !disabled && css`
      background-color: ${({
                             theme,
                             buttonType
                           }) => buttonType === 'secondary' ? rgba(renderBackground(theme, 'primary'), theme.opacity.o12) : darken(theme.opacity.o08, renderBackground(theme, buttonType))};
    `};
  }
`

const Label = styled.div`
`

const IconWrapper = styled.div`
  margin: ${({reverse}) => reverse ? '0 4px 0 0' : '0 0 0 4px'};
  transform-origin: 50% 50%;
  transform: rotate(${({rotate}) => rotate}deg);

  ${({theme}) => theme.isMobile && css`
    margin: ${({reverse}) => reverse ? '0 8px 0 0' : '0 0 0 8px'};
  `};
`

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    showIcon: PropTypes.bool,
    reverse: PropTypes.bool
};

Button.defaultProps = {
    disabled: false,
    showIcon: true,
    iconPosition: 'right'
}

export default Button;
