import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";

const EnableNotificationsModal = () => {

    return (
        <Wrapper paddings={'0 8px 0 24px'} maxHeight={'calc(100vh - 124px)'}>
            <PerfectScrollbarWrapper paddings={'0 16px 0 0'}>
                <Text text={'Включите почтовые уведомления, чтобы не пропустить входящие сообщения от пациентов'} font={'robotoRegular'} />
            </PerfectScrollbarWrapper>
        </Wrapper>
    );
};

EnableNotificationsModal.propTypes = {
    hide: PropTypes.func.isRequired,
};

export default EnableNotificationsModal;
