import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { findInTypeBySystem } from "utils";
import { find, get, cloneDeep } from "lodash";
import dayjs from "dayjs";
import { closeMessage, registerMessage, showMessage } from "reducers/systemMessages/systemMessagesSlice";
import { systemMessageDelay } from 'consts';

const initialState = {
    data: [],
    tableSettings: {
        fieldsFilter: {
            extended: true,
            approle: 'AppRole/registrator',
            practitionerRoleStatus: "active,awaiting,archived",
            'type:missing': false
        },
        searchString: '',
        sorting: [
            {
                propertyName: "practitionerName",
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0,
            maxItems: 25
        },
    },
    managersType: {},
    administrator: {
        data: {},
        details: {}
    },
    loading: {
        fullPage: true,
        data: true,
        managerFullPage: true,
        managerDetails: true
    },
    saveThisPageFilter: false,
    administratorRoleToArchive: {},
    adminLogin: null,
    organizationsForModal: []
};

const reducers = {
    managersType: (state, action) => {
        state.managersType = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload.value;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload.page - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload.maxItems;
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) {
            state.active.tableSettings = initialState.active.tableSettings;
            state.archived.tableSettings = initialState.archived.tableSettings;
        }
    },
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    setAdministratorRoleToArchive: (state, action) => {
        state.administratorRoleToArchive = action.payload;
    },
    setAdministratorDetails: (state, action) => {
        state.administrator.details = action.payload;
    },
    setAdministratorData: (state, action) => {
        state.administrator.data = action.payload;
    },
    resetAdministrator: (state, action) => {
        state.administrator = initialState.administrator;
    },
    setOrganizationsForModal:  (state, action) => {
        state.organizationsForModal = action.payload;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        return initialStateCopy;
    },
    setAdminLogin: (state, action) => {
        state.adminLogin = action.payload;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectType: state => get(state, statePath)?.managersType,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings.fieldsFilter,
    selectSorting: state => get(state, statePath)?.tableSettings.sorting,
    selectData: state => get(state, statePath)?.data,
    selectSearchString: state => get(state, statePath)?.tableSettings.searchString,
    selectLoading: state => get(state, statePath)?.loading,
    selectAdministratorRoleToArchive: state => get(state, statePath)?.administratorRoleToArchive,
    selectAdministratorDetails: state => get(state, statePath)?.administrator.details,
    selectAdminLogin: state => get(state, statePath)?.adminLogin,
    selectOrganizationsForModal: state => get(state, statePath)?.organizationsForModal,
});

export const getThunks = (actions) => {

    const getAdministrators = ({tableSettings, organizationId}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }))
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${organizationId}/ChildHeads`,
            data: {
                paging: tableSettings.paging,
                filtering: {
                    searchString: tableSettings.searchString,
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting,
            }}).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }))
            });
        };
    };

    const deleteAdministrator = ({id, tableSettings, organizationId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
                dispatch(registerMessage({name: 'delete-administrator-message', type: 'primary', title: 'Администратор удален', closable: true}))
                dispatch(showMessage('delete-administrator-message'))
                dispatch(closeMessage({name: 'delete-administrator-message', delay: systemMessageDelay}))
                dispatch(getAdministrators({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const archiveAdministrator = ({item, tableSettings, organizationId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: api.practitionerRole,
            data: item
        }).then((response) => {
                dispatch(getAdministrators({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const restoreAdministrator = ({id, tableSettings, organizationId, restoreOrganizationId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${id}/RestoreFromArchive`,
            params: {
                organizationId: restoreOrganizationId
            }
        }).then((response) => {
                dispatch(getAdministrators({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPractitionerRole = (id) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}`,
            }).then((response) => {
                dispatch(actions.setAdministratorRoleToArchive(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const createOrUpdateAdministratorData = ({formData, tableSettings, callback, isEdit, organizationId}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateManager',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdateorgmanager`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData?.practitionerId,
                        fio: formData?.name,
                        gender: formData?.gender,
                        isActive: true,
                        photoUrl: formData?.practitionerPhoto || null,
                    },
                    practitionerRoleInfo: {
                        positionCode: formData?.role,
                        positionDisplay: formData?.roleName,
                        practitionerRoleId: formData?.id,
                        statusCode: formData?.status?.valueCoding?.code,
                        statusDisplayCode: formData?.status?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData?.organization,
                        notifyWithEmail: true,
                        contactEmail: formData?.email,
                        phone: formData?.phone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'registrator'
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                tableSettings && dispatch(getAdministrators({ tableSettings, organizationId }));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-administrator-message',
                    type: 'primary',
                    title: `Администратор успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-administrator-message'))
                dispatch(closeMessage({name: 'create-update-administrator-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateManager',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-administrator-error-message',
                    type: 'red',
                    title: `Не удалось ${isEdit ? 'обновить' : 'создать'} администратора`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-administrator-error-message'))
                dispatch(closeMessage({name: 'create-update-administrator-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const sendMessage = ({text, subject, recipientPractitionerRoleId, senderPractitionerRoleId, hideModal}) => {
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/fomsnotification`,
                data: {
                    messageText: text,
                    subject,
                    recipientPractitionerRoleId: recipientPractitionerRoleId,
                    senderPractitionerRoleId: senderPractitionerRoleId
                }
            }).then((response) => {
                dispatch(hideModal);
                dispatch(registerMessage({name: 'send-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'send-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getAdministrator = (id) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}?fg=practitioner::${id}:x:_include::practitioner,organization`
            }).then((response) => {

                let administratorDetails = {};

                if (response.data.items) {
                    const item = response.data.items.find(item => item.practitioner.id === id);
                    // console.log("item", item);
                    administratorDetails = {
                        id,
                        name: get(response.data, `resources[${get(item, "practitioner.reference")}].name[0].text`),
                        statusObj: find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/onlinedoc-manager-status"}),
                        gender: get(response.data, `resources[${get(item, "practitioner.reference")}].gender`),
                        email: find(item?.telecom, {system: "email"})?.value,
                        organization: get(response.data, `resources[${get(item, "organization.reference")}].name`),
                        organizationItem: get(response.data, `resources[${get(item, "organization.reference")}]`),
                        phone: find(item?.telecom, {system: "phone"})?.value,
                        roleItem: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002'),
                        role: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.display,
                        roleCode: findInTypeBySystem(item?.code, 'https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1002')?.code,
                        created: dayjs(find(item?.meta?.extension, {url: "ex:createdAt"})?.valueInstant).locale("ru").format("D MMMM YYYY"),
                    };
                }

                dispatch(actions.setAdministratorDetails(administratorDetails));
                dispatch(actions.setAdministratorData(response.data));
                // dispatch(ManagerActions.setLoading({
                //     type: 'managerFullPage',
                //     value: false
                // }))
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    const getAdminLogin = ({roleId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
            }).then((response) => {
                const login = get(response, 'data.value');
                login && dispatch(actions.setAdminLogin(login));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationsForModal = ({parentOrganizationId}) => {
        return (dispatch) => {
          fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
              start: 0,
              max: 0,
              fg: `type:missing::false:x:partof::${parentOrganizationId}`
            }
        }).then((response) => {
                console.log(response);
                dispatch(actions.setOrganizationsForModal(response?.data?.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getAdminLogin, resetPassword, getAdministrator, sendActivationNotification, sendMessage, createOrUpdateAdministratorData, getPractitionerRole, restoreAdministrator, archiveAdministrator, deleteAdministrator, getAdministrators, getOrganizationsForModal }

}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        AdministratorsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
