import React from 'react';

const CloseIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z" fill="black" fill-opacity="0.54"/>
        <path d="M16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.10997 16.3C8.71997 16.69 8.08997 16.69 7.69997 16.3C7.30997 15.91 7.30997 15.28 7.69997 14.89L10.59 12L7.69997 9.10997C7.30997 8.71997 7.30997 8.08997 7.69997 7.69997C8.08997 7.30997 8.71997 7.30997 9.10997 7.69997L12 10.59L14.89 7.69997C15.28 7.30997 15.91 7.30997 16.3 7.69997C16.69 8.08997 16.69 8.71997 16.3 9.10997L13.41 12L16.3 14.89C16.68 15.27 16.68 15.91 16.3 16.3Z" fill="black" fill-opacity="0.54"/>
    </svg>
)

export default CloseIcon;
