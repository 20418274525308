import React, {Suspense, useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import styled, {css} from "styled-components";
import HeaderDoctor from "pages/doctor/MainPage/LeftColumnHeader";
import DoctorRightSide from "components/DoctorRigthSide";
import {useSelector} from "react-redux";
import {selectPatients, selectActivePatient} from "reducers/doctor";
import { Route, Switch } from 'react-router-dom';
import Loader from 'components/Loader';
import { routes } from 'consts/routes';
import {useMediaQuery} from "react-responsive";
import {isDesktop, isTablet} from "react-device-detect";

const PageBaseDoctor = ({/*title, */children/*, header*/}) => {
    const activePatient = useSelector(selectActivePatient);
    const role = useSelector(state => state.app.role);
    const patients = useSelector(selectPatients);
    const [patientsCount, setPatientsCount] = useState(0);
    const [windowWidth, setWindowWidth] = useState();
    const smallDesktop = useMediaQuery({ maxWidth: 1215 });

    useEffect( () => {
        setPatientsCount(patients?.items?.length);
    }, [patients])

    const renderHeaderRoutes = (routes) => {
      return Object.values(routes).map(({path, header, headerProps, name}) => {
          const HeaderCopmonent = header;
          return <Route
              exact={true}
              path={path}
              render={(props) => (
                <HeaderDoctor {...props} title={name} header={header ? () => <HeaderCopmonent {...headerProps} /> : undefined} patientsCount={patientsCount} />
              )}
          />
      });
    }

    return (
        <Wrapper fullsize direction={'row'} align={'flex-start'}>
                <LeftSide patientsCount={patientsCount} isTablet={isTablet || (isDesktop && smallDesktop)}>
                    {/* <HeaderDoctor title={title} header={header} patientsCount={patientsCount} /> */}
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                          {renderHeaderRoutes([...Object.values(routes.private[role])])}
                        </Switch>
                    </Suspense>
                    <Body>
                        <Content>
                            {children}
                        </Content>
                    </Body>
                </LeftSide>
                {patientsCount > 0 && !isTablet && !(isDesktop && smallDesktop) &&
                    <RightSide>
                        <DoctorRightSide key={activePatient?.id} />
                    </RightSide>
                }
        </Wrapper>
    );
};

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  max-width: ${({patientsCount}) => patientsCount > 0 ? 'calc(100vw - 380px)' : '320px'};
  
  ${({isTablet}) => isTablet && css`
    max-width: 100vw;
  `};
`

const RightSide = styled.div`
  ${({theme}) => theme.mixins.innerShadow({x: 'left'})};
  min-width: 380px;
  width: 380px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`

const Body = styled.div`
  display: flex;
  justify-content: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
`

PageBaseDoctor.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    selectors: PropTypes.bool,
    backAction: PropTypes.bool,
    menu: PropTypes.array.isRequired,
};

export default PageBaseDoctor;
