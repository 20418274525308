import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { filter, find, get } from 'lodash';

export const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState: {
        genders: [],
        roles: [],
        profiles: [],
        profilesMultiprofileExcluded: [],
        doctorsSpecialities: [],
        organization: [],
        weekOfPregnancy: [],
        managersMO: [],
        channelStatusReasons: [],
        closingChannelStatusReasons: [],
        organizationsWithHeads: {
            catalog: [],
            resources: {}
        },
        protocols: [],
        loading: {
            protocols: false,
            oncoStatusReason: false,
            closingChannelStatusReason: false
        },
        fundOrganization: null
    },
    reducers: {
        setGendersCatalog: (state, action) => {
            var genders = action.payload.filter(item => (item.code === 'male' || item.code === 'female'));
            genders.find(item => item.code === "male").display = "Мужской";
            genders.find(item => item.code === "female").display = "Женский";
            state.genders = genders;
        },
        setRolesCatalog: (state, action) => {
            state.roles = action.payload;
        },
        setProfilesCatalog: (state, action) => {
            state.profiles = action.payload;
        },
        setDoctorsSpecialitiesCatalog: (state, action) => {
            state.doctorsSpecialities = action.payload;
        },
        setOrganizationsCatalog: (state, action) => {
            state.organization = action.payload;
        },
        setWeekOfPregnancy: (state, action) => {
            state.weekOfPregnancy = action.payload;
        },
        setProfilesCatalogMultiprofileExcluded: (state, action) => {
            state.profilesMultiprofileExcluded = action.payload;
        },
        setManagersMOCatalog: (state, action) => {
            state.managersMO = action.payload;
        },
        setChannelStatusReasonCatalog: (state, action) => {
            state.channelStatusReasons = action.payload;
        },
        setClosingChannelStatusReasonCatalog: (state, action) => {
            state.closingChannelStatusReasons = action.payload;
        },
        setOrganizationsWithHeads: (state, action) => {
            state.organizationsWithHeads = action.payload;
        },
        setProtocolsCatalog: (state, action) => {
            state.protocols = action.payload;
        },
        setFundOrganization: (state, action) => {
            state.fundOrganization = action.payload
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
    },
});

export const selectCatalogs = state => state.catalogs;
export const selectGendersCatalog = state => state.catalogs.genders;
export const selectRolesCatalog = state => state.catalogs.roles;
export const selectProfilesCatalog = state => state.catalogs.profiles;
export const selectProfilesCatalogMultiprofileExcluded = state => state.catalogs.profilesMultiprofileExcluded;
export const selectOrganizationsCatalog = state => state.catalogs.organization;
export const selectWeekOfPregnancy = state => state.catalogs.weekOfPregnancy;
export const selectManagersMOCatalog = state => state.catalogs.managersMO;
export const selectChannelStatusReasonCatalog = state => state.catalogs.channelStatusReasons;
export const selectClosingChannelStatusReasonCatalog = state => state.catalogs.closingChannelStatusReasons;
export const selectOrganizationsWithHeadsCatalog = state => state.catalogs.organizationsWithHeads;
export const selectProtocolsCatalog = state => state.catalogs.protocols;
export const selectCatalogLoading = state => state.catalogs.loading;
export const selectFundOrganization = state => state.catalogs.fundOrganization;
export const catalogsActions = catalogsSlice.actions;

export const getCatalogs = () => {
    return (dispatch) => {
        dispatch(getGendersCatalog());
        dispatch(getRolesCatalog());
        dispatch(getProfilesCatalog());
        dispatch(getOrganizationsCatalog());
        dispatch(getWeekOfPregnancy());
        dispatch(getChannelStatusReasonCatalog());
    };
};

export const getGendersCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.gender,
    }).then((response) => {
            dispatch(catalogsActions.setGendersCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getRolesCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.practitionRoles,
    }).then((response) => {
            dispatch(catalogsActions.setRolesCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getProfilesCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.organizationProfiles,
        }).then((response) => {
            dispatch(catalogsActions.setProfilesCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getDoctorsSpecialitiesCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.doctorsSpecialities,
        }).then((response) => {
            dispatch(catalogsActions.setDoctorsSpecialitiesCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getProfilesCatalogMultiprofileExcluded = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.organizationProfiles,
        }).then((response) => {
            const filtered = response.data.items.filter(item => item.code !== "multiprofile");
            dispatch(catalogsActions.setProfilesCatalogMultiprofileExcluded(filtered));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getOrganizationsCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.organization,
        params: {
          start: 0,
          max: 0,
          fg: "type:missing::false"
        }
    }).then((response) => {
            dispatch(catalogsActions.setOrganizationsCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getWeekOfPregnancy = () => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.weekOfPregnancy,
        }).then((response) => {
            dispatch(catalogsActions.setWeekOfPregnancy(response?.data?.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getManagersMOCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.managersMO,
        }).then((response) => {
            dispatch(catalogsActions.setManagersMOCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getChannelStatusReasonCatalog = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.channelStatusReason,
        }).then((response) => {
            dispatch(catalogsActions.setChannelStatusReasonCatalog(response.data.items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getClosingChannelStatusReason = ({ profileCode, includeAuto }) => {
    return (dispatch) => {
        dispatch(catalogsActions.setLoading({
            type: 'closingChannelStatusReason',
            value: true
        }))
        fetchInstance({
            method: "GET",
            url: `${api.closingChannelStatusReason}`,
            params: {
                profileCode
            }
        }).then((response) => {
            const allReasons = get(response, 'data.reasons', []);
            const reasonsManual = filter(allReasons, item => item?.auto === false && item?.code !== 'NOT_ACTIVATE');
            dispatch(catalogsActions.setClosingChannelStatusReasonCatalog(includeAuto ? allReasons : reasonsManual));
            dispatch(catalogsActions.setLoading({
                type: 'closingChannelStatusReason',
                value: false
            }))
        }).catch(err => {
            console.log(err)
            dispatch(catalogsActions.setLoading({
                type: 'closingChannelStatusReason',
                value: false
            }))
        });
    }
};

export const getOrganizationsWithHeads = () => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.organizationsWithHeads}`,
        }).then((response) => {
            dispatch(catalogsActions.setOrganizationsWithHeads({
                catalog: response?.data?.items,
                resources: response?.data?.resources
            }));
        }).catch(err => {
            console.log(err)
        });
    }
};

export const getProtocolsCatalog = () => {
    return (dispatch) => {
        dispatch(catalogsActions.setLoading({
            type: 'protocols',
            value: true
        }))
        fetchInstance({
            method: "GET",
            url: api.planDefinition,
        }).then(response => {
            dispatch(catalogsActions.setProtocolsCatalog(get(response, 'data.items', [])));
            dispatch(catalogsActions.setLoading({
                type: 'protocols',
                value: false
            }))
        }).catch(err => {
            console.log(err);
            dispatch(catalogsActions.setLoading({
                type: 'protocols',
                value: false
            }))
        });
    };
};

export const getFundOrganization = () => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
            start: 0,
            max: 0,
            fg: "name::МГФОМС"
            }
        }).then((response) => {
                const items = get(response, 'data.items')
                const item = find(items, {name: "МГФОМС"})
                dispatch(catalogsActions.setFundOrganization(item));
            }).catch(err => {
                console.log(err)
            });
        };
};

export default catalogsSlice.reducer;
