import { reloadChatHistoryTimeout } from "consts";
import { ConnectionContext } from "index";
import { find, get, isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { isTablet, isMobileOnly } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { doctorActions, getPatients, selectActivePatient, selectChatHistoryIsLoaded, selectPatientsTableSettings, selectStartIndex, selectPatients, getPlanDefinitionByCarePlan } from "reducers/doctor";
import { getProtocolTasks, selectProtocolTaskList } from "reducers/doctor/rightSide";
import { adaptPatientsForChatList } from "utils";

/**
 * Hook для проверки наличия подключения, и запуска подключения, установки листенеров signalr
 * @param {boolean} params Флаги
 * @returns Объект hubConnection
 */
export const useConnectionStarter = (params) => {

    const context = useContext(ConnectionContext);
    const state = context?.state;

    const [connection, setConnection] = useState(state?.connection ? state?.connection : null);
    const [reloadTimer, setReloadTimer] = useState();
    const [totalMessages, setTotalMessages] = useState({});
    const [prevTotalMessages, setPrevTotalMessages] = useState({});
    const [needUpdateProtocolTaskList, setNeedUpdateProtocolTaskList] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const activePatient = useSelector(selectActivePatient);
    const role = useSelector(state => state.app.userId);
    const startIndex = useSelector(selectStartIndex);
    const patientsTableSettings = useSelector(selectPatientsTableSettings);
    const protocolTasksList = useSelector(selectProtocolTaskList);
    const patients = useSelector(selectPatients);
    const chatHistoryIsLoaded = useSelector(selectChatHistoryIsLoaded);

    const [userName, setUserName] = useState('');

    useEffect(() => {
        if(!connection && !!state?.connection){
            setConnection(state?.connection);
        }
    }, [state?.connection])

    useEffect(() => {
        if(!protocolTasksList && activePatient?.id && (!params?.isChat || (isTablet && params?.isChat))) {
            dispatch(getProtocolTasks({
                encounterId: activePatient?.id,
                sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
            }))
        }
    }, [activePatient?.id])

    useEffect(() => {
        if (connection) connection.activePatient = activePatient?.id;
    }, [connection, activePatient?.id]);

    useEffect(() => {
        if (connection) connection.protocolTasksList = protocolTasksList;
    }, [connection, protocolTasksList]);

    useEffect(() => {
        if (connection && patients) connection.patients = patients;
    }, [connection, patients]);

    useEffect(() => {
        if (connection && params) connection.params = params;
    }, [connection, params]);

    useEffect(() => {
        setUserName(activePatient?.id);
        setNeedUpdateProtocolTaskList(false);
    }, [activePatient?.id]);

    useEffect(() => {
        if(!isEmpty(needUpdateProtocolTaskList) && !find(protocolTasksList, { id: needUpdateProtocolTaskList?.taskId })){
            dispatch(getProtocolTasks({
                encounterId: activePatient?.id,
                sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']
            }))
        } else if(!isEmpty(needUpdateProtocolTaskList) && find(protocolTasksList, { id: needUpdateProtocolTaskList?.taskId })){
            dispatch(doctorActions.addMessagesNew({
                messages: needUpdateProtocolTaskList?.messages,
                tasks: protocolTasksList || []
            }));
            setNeedUpdateProtocolTaskList(false);
        }
    }, [needUpdateProtocolTaskList, protocolTasksList]);

    useEffect(() => {
        if (connection && connection?.connectionStarted && params?.isChat && !chatHistoryIsLoaded && protocolTasksList) {
            startListeningHistory();
            userName && getHistory(userName, startIndex);
        }
    }, [connection, activePatient?.id, protocolTasksList]);

    useEffect(() => {
        if(userName && params?.isChat){
            stopListeningReceiveMessage(); // Предварительно выключаем предыдущий листенер если он есть
            startListeningReceiveMessage(userName);
        }
    }, [userName])

    // Отслеживание сообщений LP
    useEffect(() => {
        if (protocolTasksList && connection && (connection?.state === "Disconnected") && userName) {
            startConnection(false, userName);
        }
    }, [connection, protocolTasksList, userName]);

    // Отслеживание изменений в TotalMessages в зависимости от активного канала, и перезагрузка истории при наличии пропущенных сообщений
    useEffect(() => {
        if (totalMessages) {
            const currentTotalMessages = totalMessages[userName];
            const currentPrevTotalMessages = prevTotalMessages[userName];
            // console.log('Total: ', totalMessages, 'currentTotalMessages: ', currentTotalMessages, 'PrevTotal: ', prevTotalMessages, 'currentPrevTotal: ', currentPrevTotalMessages);
            if(currentTotalMessages > currentPrevTotalMessages + 1){ //Если тотал следующего сообщения больше чем на 1 от тотала предыдущего сообщения
                reloadTimer && clearTimeout(reloadTimer);
                setReloadTimer(setTimeout(() => {
                    startListeningHistory();
                    userName && getHistory(userName, startIndex);
                }, reloadChatHistoryTimeout))
            }else{
                reloadTimer && clearTimeout(reloadTimer);
            }
            userName && setPrevTotalMessages(prevState => ({ ...prevState, [userName]: currentTotalMessages }));
        }
    }, [totalMessages[userName]]);

    /**
     * Старт подключения
     * @param {boolean} historyIsLoaded Флаг, была ли загружена история сообщений ранее
     * @param {string} userName Id юзера
     */
    const startConnection = (historyIsLoaded, userName) => {
        connection.start()
            .then(result => {
                if (!historyIsLoaded) {
                    startListeningHistory();
                    userName && getHistory(userName, startIndex);
                }
                stopListeningReceiveMessage(); // Предварительно выключаем предыдущий листенер если он есть
                startListeningReceiveMessage(userName);
                connection.on('MessagesRead', (user, message) => {
                    // console.log("MessagesRead");
                    dispatch(doctorActions.setMessagesReceived());
                });
                connection.on('EncounterMoved', (user, message) => {
                    // console.log("EncounterMoved");
                    dispatch(getPatients({roleId: role, tableSettings: patientsTableSettings, closeChannel: true}));
                });
                startListeningEncounterStatus();
            })
            .catch(e => {
                console.log('Connection failed: ', e);
            });
    }

    /**
     * Добавление листенера получения новых сообщений
     * @param {string} userName 
     */
    const startListeningReceiveMessage = (userName) => {
        connection.on('ReceiveMessage', (user, message, total) => {

            const parsed = JSON.parse(message);

            userName && setTotalMessages(prevState => ({ ...prevState, [userName]: total}));
            // console.log('Parsed: ', parsed, 'Total: ', total);

            const recipientId = parsed.recipient[0].referenceElement.value.replace('Patient/', '');
            const senderId = parsed.sender.referenceElement.value.replace('Patient/', '');
            const senderType = parsed.sender.referenceElement.value.indexOf('Patient/') > -1 ? 'patient' : 'doctor';
            const encounterId = parsed.encounter.referenceElement.value.replace('Encounter/', '');
            const taskId = get(find(parsed?.payload, item => get(item, 'content.referenceElement.value', '').includes('Task/')), 'content.referenceElement.value', '').replace('Task/', '');

            // Проверяем активного пациента и управляем количеством неотвеченных сообщений
            if (senderType === 'doctor') {
                dispatch(doctorActions.clearNotAnswered(recipientId));
            } else {
                dispatch(doctorActions.addNotAnswered({
                    id: senderId,
                    time: parsed.sentElement.value
                }));
            }

            // console.log("parsed", parsed);

            // Проверяем активный чат и если он соответствует - добавляем сообщение
            if (encounterId === userName) {

                if(taskId && !isEmpty(connection?.protocolTasksList) && !find(connection?.protocolTasksList, { id: taskId })){
                    setNeedUpdateProtocolTaskList({messages: get(parsed, "items") || new Array(parsed), taskId})
                }else{
                    if (get(parsed, "items")) {
                        dispatch(doctorActions.addMessagesNew({
                            messages: get(parsed, "items"),
                            tasks: connection?.protocolTasksList || []
                        }));
                    } else {
                        dispatch(doctorActions.addMessagesNew({
                            messages: new Array(parsed),
                            tasks: connection?.protocolTasksList || []
                        }));
                    }
                }
            }
        });
    }

    /**
     * Установка листенера смены статуса канала
     */
    const startListeningEncounterStatus = () => {

        if(connection?.connectionStarted){
            connection.on('StatusChange', (encounterId, status) => {
                if(encounterId && status && connection?.patients){
                    if(find(connection?.patients?.items, { id: encounterId })){
                        if(status === 'Onleave' || status === 'Finished' || status === 'Cancelled'){
                            dispatch(getPatients({roleId: role, tableSettings: patientsTableSettings}));
                            if(connection?.activePatient === encounterId){

                                //Адаптированные пациенты за исключением текущего
                                const adaptedPatients = adaptPatientsForChatList(get(connection, "patients.items")).filter(item => item?.id !== encounterId);
                                const firstPatient = get(adaptedPatients, "[0]");

                                dispatch(doctorActions.setActivePatient(firstPatient));
                                
                                if (firstPatient) dispatch(getPlanDefinitionByCarePlan({
                                    carePlanId: firstPatient?.carePlanId
                                }))
                                
                                if((isMobileOnly && connection?.params?.isChat) || (isMobileOnly && connection?.params?.isPatientInformationMobile)){
                                    history.replace('/main')
                                }
                            }
                        }else{
                            dispatch(doctorActions.updateLocalChannelStatus({ encounterId, status}));
                        }
                    }else{
                        if(status === 'InProgress' || status === 'Planned'){
                            dispatch(getPatients({roleId: role, tableSettings: patientsTableSettings}));
                        }
                    }
                }
            });
        }
    }

    /**
     * Удаление листенера истории сообщений
     */
    const stopListeningReceiveMessage = () => {
        if(connection?.connectionStarted){
            connection.off('ReceiveMessage');
        }
    }

    /**
     * Установка листенера истории сообщений
     */
    const startListeningHistory = (userName) => {
        if(connection?.connectionStarted){
            connection.on('ReceiveHistory', (user, message) => {
                const parsed = JSON.parse(message);

                dispatch(doctorActions.clearMessages());
                dispatch(doctorActions.setChatHistoryIsLoaded(true));

                if (get(parsed, "items")) {
                    userName && setTotalMessages(prevState => ({ ...prevState, [userName]: get(parsed, "items", [])?.length}));
                    dispatch(doctorActions.addMessagesNew({
                        messages: get(parsed, "items", []),
                        tasks: connection?.protocolTasksList || []
                    }));
                } else {
                    dispatch(doctorActions.addMessagesNew({
                        messages: new Array(parsed),
                        tasks: connection?.protocolTasksList || []
                    }));
                }

                /**
                 * После получения истории, ее прослушивание останавливается,
                 * для предотвращения получения истории повторно, если этот пользователь
                 * запросит ее на другом устройстве
                 */
                stopListeningHistory();
            });
        }
    }

    /**
     * Удаление листенера истории сообщений
     */
    const stopListeningHistory = () => {
        if(connection.connectionStarted){
            connection.off('ReceiveHistory');
        }
    }

    /**
     * Запрос на получение истории сообщений
     * @param {string} user User ID
     * @param {number} startIndex Индекс, начиная с которого получить историю
     */
    const getHistory = async (user, startIndex) => {
        if (connection.connectionStarted) {
            try {
                await connection.invoke("GetHistory", user, startIndex, 0);
            } catch (e) {
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    return connection;
    
}