import React from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css} from "styled-components";
import Group from "components/Group";
import SecondaryButton from "components/Button/SecondaryButton";
import qr from "assets/images/qr_code.png"
import google_play from "assets/images/google_play.svg"
import app_store from "assets/images/app_store.svg"
import ActionIcon from "components/ActionIcon";
import CopyIcon from "components/Icons/CopyIcon";
import { useDispatch, useSelector } from 'react-redux';
import { closeMessage, registerMessage, showMessage, selectMessage } from 'reducers/systemMessages/systemMessagesSlice';
import { systemMessageDelay } from 'consts';

const AppModal = ({hideModal}) => {

    const sysMessage = useSelector(selectMessage('copy-to-clipbord-message'));

    const dispatch = useDispatch();

    const handleClick = (url) => {
        window.open(url, '_blank');
    }

    const str = "https://onlinedoc.mgfoms.ru/mobile-app";

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        if(!sysMessage){
          dispatch(registerMessage({name: 'copy-to-clipbord-message', type: 'primary', title: 'Ссылка скопирована', closable: true}))
          dispatch(showMessage('copy-to-clipbord-message'))
          dispatch(closeMessage({name: 'copy-to-clipbord-message', delay: systemMessageDelay}));
        }
      };

    return (
        <>
            <Wrapper paddings={'0 24px'}>
                <Container>
                    <QrImage>
                        <img src={qr} alt={''} />
                    </QrImage>
                </Container>
                <Container>
                    <QrTitle>Сканируйте QR-код с помощью камеры телефона или специального приложения</QrTitle>
                </Container>
                <LinkContainer>
                    <LinkWrapper>
                        <Label>Ссылка для скачивания</Label>
                        {str}
                    </LinkWrapper>
                    <ActionIcon
                        iconSize={'22'}
                        action={() => copyToClipboard(str)}
                        icon={CopyIcon}
                    />
                </LinkContainer>
                <Container>
                    <Buttons>
                        <img src={app_store} onClick={() => handleClick(`https://apps.apple.com/ru/app/onlinedoc-врач/id1389018634`)} alt={''} />
                        <img src={google_play} onClick={() => handleClick(`https://play.google.com/store/apps/details?id=ru.mgfoms.onlinedoc.doctor`)} alt={''} />
                    </Buttons>
                </Container>
            </Wrapper>
            <ButtonsWrapper>
                <Wrapper direction={'row'} justify={'flex-end'} width={'100%'} paddings={'0 16px 0 0'}>
                    <Group align={'center'}>
                        <Group.Item>
                            <SecondaryButton onClick={hideModal} label={'Закрыть'} />
                        </Group.Item>
                    </Group>
                </Wrapper>
            </ButtonsWrapper>
        </>
    );
};

AppModal.propTypes = {

};

const Label = styled.div`
  ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, color: theme.colors.black54})};

  ${({error}) => error && css`
    color: ${({theme}) => theme.colors.red};
  `};
  
  i {
    color: ${({theme}) => theme.colors.red};
    font-style: normal;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`

const QrImage = styled.div`
    border: 1px solid rgb(0 0 0 / 12%);
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 12px;
    width: 196px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
`

const QrTitle = styled.div`
    margin-bottom: 12px;
    text-align: center;
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100})};
`

const ButtonsWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`

const LinkContainer = styled.div`
  position: relative;
  margin-top: 12px;
  margin-bottom: 25px;
  margin-right: -8px;
  display: flex;
  justify-content: space-between;
  ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100})};
`

const LinkWrapper = styled.div`

`

const Buttons = styled.div`
  display: flex;
  margin-bottom: 10px;

  img {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.8;
  }
`

export default AppModal;
