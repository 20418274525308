import { createSlice } from '@reduxjs/toolkit';
import {api} from "consts/api";
import {fetchInstance} from "wrappers/axios";

export const anchorPopupSlice = createSlice({
    name: 'anchorPopup',
    initialState: {
        visible: false,
        component: null,
        elementPosition: null,
        props: null,
        loading: {
            dependencyChannels: false,
            dependencyPatients: false,
            dependencyDoctors: false
        }
    },
    reducers: {
        show: (state, action) => {
            return {
                ...state,
                visible: true,
                component: action.payload.component,
                positionType: action.payload.positionType,
                elementPosition: action.payload.elementPosition,
                props: action.payload.props,
            }
        },
        hide: (state, action) => {
            return {
                visible: false,
                component: null,
                elementPosition: null,
                props: null,
                loading: {
                    dependencyChannels: false,
                    dependencyPatients: false
                }
            }
        },
        updateElementPosition: (state, action) => {
            state.elementPosition = action.payload.elementPosition;
        },
        setForbidDeletion: (state, action) => {
            state.forbidDeletion = true;
        },
        clearForbidDeletion: (state, action) => {
            delete state.forbidDeletion;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
    },
});

export const selectForbidDeletion = state => state.anchorPopup.forbidDeletion;
export const selectLoading = state => state.anchorPopup.loading;
export const { show, hide, updateElementPosition } = anchorPopupSlice.actions;
export const anchorPopupActions = anchorPopupSlice.actions;

export const getChannels = (id) => {
    return (dispatch) => {

        dispatch(anchorPopupActions.setLoading({
            type: 'dependencyChannels',
            value: true
        }));

        fetchInstance({
            method: "POST",
            url: `${api.patient}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: -1
                },
                filtering:{
                    searchString: null,
                    fieldsFilter:{
                        organization: id,
                        '_has:Encounter:patient:class': "VR"
                    }
                },
                sorting: [],
            }
        }).then((response) => {
            if (response.data.clientQuery.paging.totalItems > 0) dispatch(anchorPopupActions.setForbidDeletion());

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyChannels',
                value: false
            }));    

        }).catch(err => {

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyChannels',
                value: false
            })); 

            console.log(err)
        });
    };
};

export const getChildren = (id) => {
    return (dispatch) => {

        dispatch(anchorPopupActions.setLoading({
            type: 'dependencyPatients',
            value: true
        }));

        fetchInstance({
            method: "POST",
            url: `${api.organization}/_search`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: -1
                },
                filtering: {
                    fieldsFilter: {
                        partof: id,
                        profile: "",
                        'type:missing': false
                    }
                },
            }
        }).then((response) => {
            if (response.data.clientQuery.paging.totalItems > 0) dispatch(anchorPopupActions.setForbidDeletion());

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyPatients',
                value: false
            }));

        }).catch(err => {

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyPatients',
                value: false
            }));

            console.log(err)
        });
    };
};

export const getDoctors = (id) => {
    return (dispatch) => {

        dispatch(anchorPopupActions.setLoading({
            type: 'dependencyDoctors',
            value: true
        }));

        fetchInstance({
            method: "POST",
            url: `${api.organization}/${id}/Doctors`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: -1
                },
                filtering: {
                    fieldsFilter: {
                        practitionerRoleStatus: "awaiting,active,not-available",
                        'type:missing': false
                    }
                },
            }
        }).then((response) => {
            if (response.data.clientQuery.paging.totalItems > 0) dispatch(anchorPopupActions.setForbidDeletion());

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyDoctors',
                value: false
            }));

        }).catch(err => {

            dispatch(anchorPopupActions.setLoading({
                type: 'dependencyDoctors',
                value: false
            }));

            console.log(err)
        });
    };
};

export default anchorPopupSlice.reducer;
