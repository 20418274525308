import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from "styled-components";
import Item from "./Item";
import Text from "components/Text";
// import PerfectScrollbarWrapper from "../PerfectScrollbarWrapper";
import { isFunction, get, findIndex, isNil } from "lodash";
import CircleProgress from 'components/CircleProgress';
import PerfectScrollbar from "react-perfect-scrollbar";
import Icon from 'components/Icon';

const FloatingMenu = ({list, active, onClick, handleHide, pathToLabel, message, spinner, maxWidth, fixedHeightValue, maxContainerHeight, componentProps, scrollToActiveElement, scrollWheelSpeed, nowrapItems}) => {

    const theme = useTheme();

    const [scrollBarRef, setScrollBarRef] = useState();

    const handleClick = (e, item) => {
        onClick && onClick(item)
        item?.action && isFunction(item?.action) && item.action(e)
        handleHide && handleHide(e)
    }

    //Для склола к активному элементу необходимо зафиксировать высоту контейнера с помощью fixedHeightValue
    useEffect(() => {
        if(scrollBarRef && scrollToActiveElement){
            const arr = list || componentProps;
            const length = arr?.length
            const elementHeight = scrollBarRef.scrollHeight / length;
            const activeElementIndex = !isNil(active) ? findIndex(arr, item => (item.id === active || item.code === active || item.value === active)) : undefined;
            if(activeElementIndex){
                scrollBarRef.scrollTop = (elementHeight * activeElementIndex) - ((scrollBarRef.offsetHeight / 2) - (elementHeight / 2));
            }
        }
    }, [scrollBarRef])

    const renderContent = () => {
        if(list) {
            return list.map(item => <Item maxWidth={maxWidth} item={item} key={'floating-menu-item-' + item.id} active={(active || active === 0) && (item.id === active || item.code === active || item.value === active)} pathToLabel={pathToLabel} onClick={handleClick} nowrap={nowrapItems} />)
        } else if (componentProps) {
            return componentProps.map(item => <Item maxWidth={maxWidth} item={item} key={'floating-menu-item-' + item.id} active={(active || active === 0 || get(item, "active")) && (item.id === active || item.code === active || item.value === active)} pathToLabel={pathToLabel} onClick={handleClick} nowrap={nowrapItems} />)
        }
    }

    return (
        <FloatingMenuWrapper maxHeight={maxContainerHeight} fixedHeightValue={fixedHeightValue}>
            <PerfectScrollbar containerRef={(ref) => setScrollBarRef(ref)} options={{ wheelSpeed: scrollWheelSpeed || 0.8 }}>
                { !message 
                    ? spinner 
                    ? <SpinnerWrapper><Icon icon={CircleProgress} /></SpinnerWrapper>
                    : renderContent()
                    : <TextWrapper>
                        <Text color={theme.colors.black54} font={'robotoRegular'}>
                            {message}
                        </Text>
                    </TextWrapper>
                }
            </PerfectScrollbar>
        </FloatingMenuWrapper>
    );
};

const FloatingMenuWrapper = styled.div`
  padding: 8px 0;
  max-height: ${({maxHeight}) => maxHeight ? maxHeight : '300px'};
  overflow-y: auto;

  ${({fixedHeightValue}) => fixedHeightValue && css`
    height: ${fixedHeightValue};
  `}
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 16px 6px;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 16px 6px;
`

FloatingMenu.propTypes = {
    list: PropTypes.array.isRequired,
    active: PropTypes.number,
    onClick: PropTypes.func,
};

export default FloatingMenu;
