import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {get, cloneDeep, remove, isEmpty, isArray, without, unionBy, sortBy} from "lodash";
import dayjs from "dayjs";
import axios from 'axios';
import {setActiveTab} from "reducers/tabs/tabsSlice";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import { systemMessageDelay } from 'consts';

const initialState = {
    loading:{
        searchTaskList: false,
        protocolTaskList: true,
        nextPageSearchTaskList: false,
        activeEvent: false,
        planDefinition: false,
        protocolTask: false
    },
    protocol:{
        searchTaskList: [],
        protocolTaskList: null,
        appointment: null,
        planDefinition: null,
        reminderSendingMap: [],
        taskDetails: {
            sortingPosition: null,
            trimester: null
        }
    },
    searchTasksLoadedPageCount: 0,
    searchTasksTotalCount: null,
    allSearchTasksLoaded: false,
    activeEvent: null,
    showSearchWindow: false,
    addWindowElement: null
};

export const rightSideSlice = createSlice({
    name: 'doctor_rightSide',
    initialState,
    reducers: {
        setSearchTaskList: (state, action) => {
            state.protocol.searchTaskList = action.payload;
        },
        addSearchTasks: (state, action) => {
            state.protocol.searchTaskList = state.protocol.searchTaskList.concat(action.payload);
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setAllSearchTasksLoaded: (state, action) => {
            state.allSearchTasksLoaded = action.payload;
        },
        setSearchTasksLoadedPageCount: (state, action) => {
            state.searchTasksLoadedPageCount = action.payload;
        },
        setSearchTasksTotalCount: (state, action) => {
            state.searchTasksTotalCount = action.payload;
        },
        setActiveEvent: (state, action) => {
            state.activeEvent = action.payload;
        },
        setShowSearchWindow: (state, action) => {
            state.showSearchWindow = action.payload;
        },
        setAddWindowElement: (state, action) => {
            state.addWindowElement = action.payload;
        },
        setProtocolTaskList: (state, action) => {
            state.protocol.protocolTaskList = action.payload;
        },
        setPlanDefinition: (state, action) => {
            state.protocol.planDefinition = action.payload;
        },
        setProtocolTaskAppointment: (state, action) => {
            state.protocol.appointment = action.payload;
        },
        addReminderSending: (state, action) => {
            state.protocol.reminderSendingMap = [...state.protocol.reminderSendingMap, action.payload]
        },
        removeReminderSending: (state, action) => {
            state.protocol.reminderSendingMap = without(state.protocol.reminderSendingMap, action.payload)
        },
        setTaskSortingPosition: (state, action) => {
            state.protocol.taskDetails.sortingPosition = action.payload;
        },
        setTaskTrimester: (state, action) => {
            state.protocol.taskDetails.trimester = action.payload;
        },
        setTaskToProtocolTaskList: (state, action) => {
            state.protocol.protocolTaskList = sortBy(unionBy([action.payload], state.protocol.protocolTaskList, 'id'), ['sorting'])
        },
        resetState: (state, action) => {
            return initialState
        },
    },
});

export const selectSearchTaskList = state => state.doctor.doctorRightSide.protocol.searchTaskList;
export const selectProtocol = state => state.doctor.doctorRightSide.protocol;
export const selectProtocolTaskAppointment = state => state.doctor.doctorRightSide.protocol.appointment;
export const selectLoading = state => state.doctor.doctorRightSide.loading;
export const selectProtocolTasksLoadedPageCount = state => state.doctor.doctorRightSide.searchTasksLoadedPageCount;
export const selectAllProtocolTasksLoaded = state => state.doctor.doctorRightSide.allSearchTasksLoaded;
export const selectProtocolTasksTotalCount = state => state.doctor.doctorRightSide.searchTasksTotalCount;
export const selectActiveEvent = state => state.doctor.doctorRightSide.activeEvent;
export const selectShowSearchWindow = state => state.doctor.doctorRightSide.showSearchWindow;
export const selectAddWindowElement = state => state.doctor.doctorRightSide.addWindowElement;
export const selectProtocolTaskList = state => state.doctor.doctorRightSide.protocol.protocolTaskList;
export const selectPlanDefinition = state => state.doctor.doctorRightSide.protocol.planDefinition;
export const selectReminderSendingMap = state => state.doctor.doctorRightSide.protocol.reminderSendingMap;
export const selectTaskSortingPosition = state => state.doctor.doctorRightSide.protocol.taskDetails.sortingPosition;
export const selectTaskTrimester = state => state.doctor.doctorRightSide.protocol.taskDetails.trimester;

export const doctorRightSideActions = rightSideSlice.actions;

export const getSearchTasks = ({patientId, fieldsFilter, searchString, startIndex = 0, maxItems = 100, cancelTokenSource}) => {

    return (dispatch) => {

        dispatch(doctorRightSideActions.setLoading({
            type: 'nextPageSearchTaskList',
            value: true
        }))

        fetchInstance({
            method: "POST",
            url: `${api.planDefinition}/${patientId}/GetProtocolActivities`,
            cancelToken: cancelTokenSource.token,
            data: {
                paging: {
                    startIndex,
                    maxItems
                },
                filtering: {
                    searchString,
                    fieldsFilter
                }
            }
        }).then((response) => {

            const data = get(response, "data");
            const tasks = get(data, "items");

            dispatch(doctorRightSideActions.addSearchTasks(tasks));

            dispatch(doctorRightSideActions.setSearchTasksTotalCount(get(data, 'clientQuery.paging.totalItems')));

            dispatch(doctorRightSideActions.setLoading({
                type: 'nextPageSearchTaskList',
                value: false
            }))
        }).catch(err => {
            if(axios.isCancel(err)){
                dispatch(doctorRightSideActions.setSearchTasksLoadedPageCount(0));
                dispatch(doctorRightSideActions.addSearchTasks([]));
                dispatch(doctorRightSideActions.setSearchTasksTotalCount(1)); //Почему то infinitieLoader перезагружает список только со значением больше 0
                dispatch(doctorRightSideActions.setLoading({
                    type: 'nextPageSearchTaskList',
                    value: false
                }))
            }
            console.log(err)
        });
    };
};

export const getProtocolTasks = ({encounterId, sortingSheme, fieldsFilter, searchString, startIndex = 0, maxItems = 0, callback}) => {

    return (dispatch) => {

        dispatch(doctorRightSideActions.setLoading({
            type: 'protocolTaskList',
            value: true
        }))

        fetchInstance({
            method: "POST",
            url: `${api.encounter}/${encounterId}/Tasks`,
            data: {
                paging: {
                    startIndex,
                    maxItems
                },
                filtering: {
                    searchString,
                    fieldsFilter
                },
                sorting: [
                    {
                      direction: 0,
                      propertyName: "status"
                    }
                  ]
            }
        }).then((response) => {

            const data = get(response, "data");
            const tasks = get(data, "items");

            if(tasks){
                // if(!sortingSheme){
                //     dispatch(doctorRightSideActions.setProtocolTaskList(tasks))
                // }else{
                //     dispatch(sortProtocolTasks({statusSheme: sortingSheme, protocolTasks: tasks}))
                // }
                dispatch(sortProtocolTasks({protocolTasks: tasks}))
            }else{
                dispatch(doctorRightSideActions.setProtocolTaskList([]))
            }

            callback && callback();

            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTaskList',
                value: false
            }))
        }).catch(err => {
            console.log(err)
        });
    };
};

// export const sortProtocolTasks = ({statusSheme, protocolTasks}) => {

//     return (dispatch) => {
//         const sorted = !isEmpty(protocolTasks) && isArray(protocolTasks) && !isEmpty(statusSheme) ? protocolTasks.sort((a, b) => {
//             const aIndex = statusSheme.findIndex(item => item === a.status.toLowerCase())
//             const bIndex = statusSheme.findIndex(item => item === b.status.toLowerCase())
//             return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0
//         }) : undefined;

//         sorted && dispatch(doctorRightSideActions.setProtocolTaskList(sorted))
//     };
// };

export const sortProtocolTasks = ({protocolTasks}) => {
    
    return (dispatch) => {
        const sorted = !isEmpty(protocolTasks) && isArray(protocolTasks) ? protocolTasks.sort((a, b) => {
            return parseInt(a?.sorting) - parseInt(b?.sorting)
        }) : undefined;
        sorted && dispatch(doctorRightSideActions.setProtocolTaskList(sorted))
    };
};

export const addTaskToCarePlan = ({carePlanId, patientId, practitionerRoleId, task, callback}) => {

    return (dispatch) => {

        fetchInstance({
            method: "POST",
            url: `${api.carePlan}/AddNewTask`,
            data: {
                ...task,
                carePlanId,
                patientId,
                practitionerRoleId,
            }
        }).then((response) => {
            // console.log(response);
            dispatch(doctorRightSideActions.setAddWindowElement(get(response, "data")))
            callback && callback(get(response, "data"));
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'add-task-to-care-plan-error-message', type: 'red', title: 'Ошибка', text: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Не удалось добавить мероприятие', closable: true}))
            dispatch(showMessage('add-task-to-care-plan-error-message'))
            dispatch(closeMessage({name: 'add-task-to-care-plan-error-message', delay: systemMessageDelay}))
        });

    };
};

export const getAddWindowElement = ({id}) => {

    return (dispatch) => {

        dispatch(doctorRightSideActions.setLoading({
            type: 'addWindowElementTask',
            value: true
        }))

        fetchInstance({
            method: "GET",
            url: `${api.task}/${id}`,
        }).then((response) => {

            const data = get(response, "data");
            
            dispatch(doctorRightSideActions.setLoading({
                type: 'addWindowElementTask',
                value: false
            }))
            
            dispatch(doctorRightSideActions.setAddWindowElement(data));
        }).catch(err => {
            console.log(err)
            dispatch(doctorRightSideActions.setLoading({
                type: 'addWindowElementTask',
                value: false
            }))
        });

    };
};

export const getTask = ({id}) => {

    return (dispatch) => {

        dispatch(doctorRightSideActions.setLoading({
            type: 'protocolTask',
            value: true
        }))

        fetchInstance({
            method: "GET",
            url: `${api.task}/${id}`,
        }).then((response) => {

            const data = get(response, "data");
            
            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTask',
                value: false
            }))
            
            dispatch(doctorRightSideActions.setActiveEvent(data));
        }).catch(err => {
            console.log(err)
            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTask',
                value: false
            }))
        });

    };
};

export const getTaskFromChat = ({id, trimester}) => {

    return (dispatch) => {
        dispatch(setActiveTab({name: "doctor-right-side-tabs", value: 0}));
        dispatch(doctorRightSideActions.setLoading({
            type: 'protocolTask',
            value: true
        }))

        fetchInstance({
            method: "GET",
            url: `${api.task}/${id}`,
        }).then((response) => {
            const data = get(response, "data");
            dispatch(setActiveTab({name: "protocol-tabs", value: trimester ? trimester - 1 : 0}))

            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTask',
                value: false
            }))

            dispatch(doctorRightSideActions.setActiveEvent(data));
        }).catch(err => {
            console.log(err)
            dispatch(doctorRightSideActions.setLoading({
                type: 'protocolTask',
                value: false
            }))
        });

    };
};

export const getPlanDefinition = ({planDefinitionId}) => {

    return (dispatch) => {

        dispatch(doctorRightSideActions.setLoading({
            type: 'planDefinition',
            value: true
        }))

        fetchInstance({
            method: "GET",
            url: `${api.planDefinition}/${planDefinitionId}`,
        }).then((response) => {

            const data = get(response, "data");

            dispatch(doctorRightSideActions.setPlanDefinition(data));
            dispatch(doctorRightSideActions.setLoading({
                type: 'planDefinition',
                value: false
            }))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const createAppointment = ({date, taskName, patientId, practitionerId, withTaskUpdate, taskUpdateData, callback}) => {

    return (dispatch) => {

        fetchInstance({
            method: "POST",
            url: `${api.appointment}`,
            data: {
                resourceType: "Appointment",
                description: taskName,
                start: date,
                status: "proposed",
                participant: [
                    {
                        status: "tentative",
                        type: [
                            {
                                text: "Пациент"
                            }
                        ],
                        actor: {
                            reference: `Patient/${patientId}`
                        }
                    },
                    {
                        status: "tentative",
                        type: [
                            {
                                text: "Врач"
                            }
                        ],
                        actor: {
                            reference: `PractitionerRole/${practitionerId}`
                        }
                    }
                ],
            }
        }).then((response) => {

            const data = get(response, "data");

            dispatch(doctorRightSideActions.setProtocolTaskAppointment(data))
            withTaskUpdate && dispatch(updateTask({...taskUpdateData, appointment: data, callback}))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const deleteAppointment = ({id, withTaskUpdate, taskUpdateData}) => {

    return (dispatch) => {

        fetchInstance({
            method: "DELETE",
            url: `${api.appointment}/${id}`,
        }).then((response) => {

            dispatch(doctorRightSideActions.setProtocolTaskAppointment(null))
            withTaskUpdate && dispatch(updateTask({...taskUpdateData}))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getAppointment = ({id}) => {

    return (dispatch) => {

        fetchInstance({
            method: "GET",
            url: `${api.appointment}/${id}`,
        }).then((response) => {

            const data = get(response, "data");

            dispatch(doctorRightSideActions.setProtocolTaskAppointment(data))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const updateTask = ({task, formData, appointment, needReloadList, encounterId, clear, fromList, fromCreating, callback}) => {

    return (dispatch) => {

        let extensions = task?.extension ? cloneDeep(task.extension) : [];
        
        if(appointment){
            extensions = [
                ...extensions,
                {
                    url: "http://miramedix.ru/fhir/StructureDefinition/appointment-scheduled-flag",
                    valueBoolean: true
                },
                {
                    url: "http://miramedix.ru/fhir/StructureDefinition/scheduled-appointment-reference",
                    valueReference: {
                        reference: `Appointment/${appointment?.id}`
                    }
                }
            ]
        }else{
            remove(extensions, item => {
                return item.url === 'http://miramedix.ru/fhir/StructureDefinition/appointment-scheduled-flag' ||
                    item.url === 'http://miramedix.ru/fhir/StructureDefinition/scheduled-appointment-reference'
            })
        }

        fetchInstance({
            method: "PUT",
            url: `${api.task}`,
            data: {
                ...task,
                restriction: {
                    period: {
                        end: dayjs(formData?.endDate).format('YYYY-MM-DD'),
                        start: dayjs(formData?.startDate).format('YYYY-MM-DD'),
                    }
                },
                extension: [
                    ...extensions
                ]
            }
        }).then((response) => {

            const data = get(response, "data");

            clear && dispatch(clearStateAfterTaskEditing())
            !clear && !fromList && !fromCreating && dispatch(doctorRightSideActions.setActiveEvent(data));
            (needReloadList || fromList) && encounterId && dispatch(getProtocolTasks({encounterId, sortingSheme: ['overdue', 'planned', 'executed', 'cancelled'], callback}));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const cancelTask = ({task, reason, encounterId, clear, fromList, callback}) => {

    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.task}`,
            data: {
                ...task,
                status: 'cancelled',
                statusReason: reason ?
                    reason?.type === 'patientRejection' ? 
                        {
                            coding: [
                                {
                                    code: "patient-rejection",
                                    system: "http://miramedix.ru/fhir/CodeSystem/task-status-reason",
                                    display: "Отказ пациента"
                                }
                            ]
                        } 
                        :
                        reason?.type === 'medical' ?
                        {
                            text: reason?.text,
                            coding: [
                                {
                                    code: "indication",
                                    system: "http://miramedix.ru/fhir/CodeSystem/task-status-reason",
                                    display: "По показаниям"
                                }
                            ]
                        } : undefined
                : undefined,
                lastModified: dayjs().toISOString()
            }
        }).then((response) => {
            const data = get(response, "data");

            clear && dispatch(clearStateAfterTaskEditing())
            !clear && !fromList && dispatch(doctorRightSideActions.setActiveEvent(data));
            fromList && encounterId && dispatch(getProtocolTasks({encounterId, sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']}))
            callback && callback();
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'cancel-task-message', type: 'red', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Ошибка запроса', closable: true}))
            dispatch(showMessage('cancel-task-message'))
            dispatch(closeMessage({name: 'cancel-task-message', delay: systemMessageDelay}))
        });
    };
};

export const approveTask = ({task, date, encounterId, clear, fromList}) => {
    const taskCopy = cloneDeep(task);
    if (taskCopy.output) {
        taskCopy.output.push(
            {
                type: {
                    coding: [
                        {
                            code: "date-of-childbirth",
                            system: "http://miramedix.ru/fhir/CodeSystem/task-outputtype",
                            display: "Дата родов"
                        }
                    ]
                },
                valueDateTime: date
            }
        );
    } else {
        taskCopy.output = [];
        taskCopy.output.push(
            {
                type: {
                    coding: [
                        {
                            code: "date-of-childbirth",
                            system: "http://miramedix.ru/fhir/CodeSystem/task-outputtype",
                            display: "Дата родов"
                        }
                    ]
                },
                valueDateTime: date
            }
        );
    }
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.task}`,
            data: {
                ...taskCopy,
                status: 'completed',
                executionPeriod: {
                    start: taskCopy?.executionPeriod?.start,
                    end: date
                }
            }
        }).then((response) => {

            const data = get(response, "data");

            clear && dispatch(clearStateAfterTaskEditing())
            !clear && !fromList && dispatch(doctorRightSideActions.setActiveEvent(data));
            fromList && encounterId && dispatch(getProtocolTasks({encounterId, sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']}))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const backToReadyTask = ({task, encounterId, clear, fromList}) => {

    return (dispatch) => {

        fetchInstance({
            method: "PUT",
            url: `${api.task}`,
            data: {
                ...task,
                status: 'ready',
                executionPeriod: {},
                statusReason: {},
                output: {}
            }
        }).then((response) => {

            const data = get(response, "data");

            clear && dispatch(clearStateAfterTaskEditing())
            !clear && !fromList && dispatch(doctorRightSideActions.setActiveEvent(data));
            fromList && encounterId && dispatch(getProtocolTasks({encounterId, sortingSheme: ['overdue', 'planned', 'executed', 'cancelled']}))
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getTaskForUpdateFromList = ({taskId, encounterId, mode, updateData, clear, date, callback, isBornTask}) => {

    return (dispatch) => {

        fetchInstance({
            method: "GET",
            url: `${api.task}/${taskId}`,
        }).then((response) => {

            const data = get(response, "data");
            if(!isBornTask){
                mode === 'approve' && dispatch(approveTask({task: data, encounterId, clear, date, fromList: true}))
                mode === 'cancel' && dispatch(cancelTask({task: data, reason: updateData?.reason, encounterId, clear, fromList: true}))
                mode === 'backToReady' && dispatch(backToReadyTask({task: data, encounterId, clear, fromList: true}))
            }else{
                mode === 'approve' && dispatch(setBirthEvent({birthDate: date, encounterId, fromList: true, callback}))
                // mode === 'cancel' && dispatch(cancelTask({task: data, reason: updateData?.reason, encounterId, clear, fromList: true}))
                mode === 'backToReady' && dispatch(setBirthEvent({birthDate: '', encounterId, fromList: true, callback}))
            }

            !isBornTask && callback && callback();
        }).catch(err => {
            console.log(err)
        });
    };
};

export const setBirthEvent = ({birthDate, encounterId, childrenAmount = 0, callback, fromList}) => {
    return (dispatch) => {
        dispatch(doctorRightSideActions.setLoading({
            type: 'birthEvent',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.setBirthEvent}`,
        data: {
            birthDate,
            encounterId,
            childrenAmount
          }
        }).then((response) => {
            const data = get(response, "data");
            !fromList && dispatch(doctorRightSideActions.setActiveEvent(data));
            dispatch(getStatisticUpdateStatus({id: encounterId, callback}));
        }).catch(err => {
            // console.log(err.response.data);
            dispatch(registerMessage({name: 'set-birth-event-message', type: 'red', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : `Ошибка запроса`, closable: true}))
            dispatch(showMessage('set-birth-event-message'))
            dispatch(closeMessage({name: 'set-birth-event-message', delay: systemMessageDelay}));
            dispatch(doctorRightSideActions.setLoading({
                type: 'birthEvent',
                value: false
            }));
        });
    };
};

export const getStatisticUpdateStatus = ({id, iteration = 10, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.statisticUpdateStatus}/${id}`,
        }).then((response) => {
            if (response.data === "Completed" || !iteration) {
                if (response.data === "Completed") {
                    callback && callback();
                    dispatch(doctorRightSideActions.setLoading({
                        type: 'birthEvent',
                        value: false
                    }));
                }
                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    //Все равно обновляем

                    callback && callback();

                    dispatch(doctorRightSideActions.setLoading({
                        type: 'birthEvent',
                        value: false
                    }));
                }
            } else {
                setTimeout(() => dispatch(getStatisticUpdateStatus({id, iteration: --iteration, callback})), 1000);
            }
        }).catch(err => {
            console.log(err);
            dispatch(doctorRightSideActions.setLoading({
                type: 'birthEvent',
                value: false
            }));
        });
    };
};

export const clearSearchTasksListState = () => {
    return (dispatch) => {
        dispatch(doctorRightSideActions.setSearchTasksLoadedPageCount(0));
        dispatch(doctorRightSideActions.setSearchTasksTotalCount(null));
        dispatch(doctorRightSideActions.setAllSearchTasksLoaded(false));
        dispatch(doctorRightSideActions.setSearchTaskList([]));
    };
};

export const clearStateAfterTaskEditing = () => {
    return (dispatch) => {
        dispatch(doctorRightSideActions.setAddWindowElement(null));
        dispatch(doctorRightSideActions.setActiveEvent(null))
        dispatch(doctorRightSideActions.setShowSearchWindow(false));
        dispatch(doctorRightSideActions.setPlanDefinition(null));
        dispatch(doctorRightSideActions.setProtocolTaskAppointment(null))
        dispatch(doctorRightSideActions.setTaskSortingPosition(null))
        dispatch(doctorRightSideActions.setTaskTrimester(null))
    };
};

export default rightSideSlice.reducer;
