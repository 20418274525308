export const roles = [
    {
        id: 0,
        label: 'Сотрудник фонда',
        value: 'fund-admin'
    },
    {
        id: 1,
        label: 'Врач',
        value: 'doctor'
    },
    {
        id: 2,
        label: 'Администратор МО',
        value: 'registrator'
    },
    {
        id: 3,
        label: 'Руководитель МО',
        value: 'organization-admin'
    },
    {
        id: 4,
        label: 'Аналитик',
        value: 'analyst'
    },
];

export const gender = [
    {
        id: 0,
        label: 'Мужской',
    },
    {
        id: 1,
        label: 'Женский',
    },
];

export const specialty = [
    {
        id: 0,
        label: 'Онколог',
    },
    {
        id: 1,
        label: 'Хирург',
    },
    {
        id: 2,
        label: 'Терапевт',
    },
];

export const responseTime = [
    {
        id: 0,
        label: '5 минут',
    },
    {
        id: 1,
        label: '1 час',
    },
    {
        id: 2,
        label: '1 день',
    },
]

export const organization = [
    {
        id: 0,
        label: 'ГКБ № 67 им. Л.А. Ворохобова',
    },
    {
        id: 1,
        label: 'МГОБ № 62',
    },
    {
        id: 2,
        label: 'ГБУЗ «ГКБ им. братьев Бахрушиных»',
    },
]

export const practitionerRole = [
    {
        id: 0,
        label: 'Главный врач',
    },
    {
        id: 1,
        label: 'Врач',
    },
]
