import { createSlice } from '@reduxjs/toolkit';

export const tooltipSlice = createSlice({
    name: 'tooltip',
    initialState: {
        visible: false,
        component: null,
        elementPosition: null,
        props: {
            tooltipMaxWidth: '392px',
        },
    },
    reducers: {
        show: (state, action) => {
            return {visible: true, component: action.payload.component, elementPosition: action.payload.elementPosition , props: {...state.props, ...action.payload.props}}
        },
        hide: (state, action) => {
            return {visible: false, component: null, elementPosition: null , props: false}//props: {...state.props}
        },
    },
});

export const { show, hide } = tooltipSlice.actions;

export default tooltipSlice.reducer;
