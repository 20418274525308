import {useDispatch} from "react-redux";
import {hide, show, registerModal, unregisterModal} from "reducers/modal/modalSlice";

export const useModal = ({component, name, props, header, footer, mode} = {}) => {

    const dispatch = useDispatch();

    const showModal = ({data = {}, modalComponent, modalHeader, modalFooter, modalProps, modalMode} = {}) => {
        dispatch(registerModal({
            name,
            component: component ? component : modalComponent,
            data,
            props: props ? props : modalProps,
            header: header ? header : modalHeader,
            footer: footer ? footer : modalFooter,
            mode: mode ? mode : modalMode,
        }))
        dispatch(show(name))
    }

    const hideModal = () => {
        // console.log("name", name);
        dispatch(hide(name))
        dispatch(unregisterModal(name))
    }

    return [showModal, hideModal]
}
