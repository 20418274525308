import styled from "styled-components";

const Row = styled.div`
  display: flex;
  width: ${({width}) => width || '100%'};
  flex: 0 0 auto;
  margin-bottom: ${({marginBottom, theme}) => marginBottom || theme.margins.row.bottom};
  :last-child {
    margin-bottom: 0;
  }
`

export { Row }
