import React, {useState, useEffect} from 'react';
import {Wrapper} from "styled/Wrapper";
import styled, {css} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getImageUrl} from "utils";
import {get} from "lodash";

const ChatHistoryImagesCarousel = (modalProps) => {

    const {
        selectCarousel,
        actions
    } = modalProps?.reducer

    const token = useSelector(state => get(state, "app.access_token"));
    const carousel = useSelector(selectCarousel);
    const [activeMessage, setActiveMessage] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setActiveMessage(carousel.carouselImages.find(item => item.id === carousel.activeMessageId));
    }, [carousel.carouselImages, carousel.activeMessageId]);

    const handlePreviewItemClick = (index) => {
        dispatch(actions?.setCarouselActiveImageIndex(index))
    }

    return (<>
        {carousel.carouselLength && activeMessage && <Wrapper>
            <Title><b>Изображение {get(activeMessage, `images[${carousel.activeImageIndex}].index`) + 1} из {carousel.carouselLength}</b><br/>
                {activeMessage?.senderName}
                <br/>{activeMessage.date} в {activeMessage.time}</Title>
                {(activeMessage.images.length > 1) &&
                    <Previews>
                        {activeMessage.images.map(item =>
                        <PreviewsItem 
                            active={item.indexInArray === carousel.activeImageIndex ? true : false}
                            onClick={() => handlePreviewItemClick(item?.indexInArray)}
                        >
                            <img src={getImageUrl(item.url, token)} alt={''} />
                        </PreviewsItem>)}
                    </Previews>
                }
            <img src={getImageUrl(get(activeMessage, `images[${carousel.activeImageIndex}].url`), token)} alt={''} />
        </Wrapper>}
    </>);
};

const Title = styled.div`
    position: fixed;
    top: 12px;
    left: 24px;
    ${({theme}) => theme.mixins.robotoRegular({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small, color: theme.colors.white70})};
    
    b {
        ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.text, lineheight: theme.fonts.lineHeight.small, color: theme.colors.white70})};
    }
`

const Previews = styled.div`
    display: flex;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 50vw;
    align-items: center;
`

const PreviewsItem = styled.div`
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    margin: 0 4px;
    border-radius: 6px;
    overflow: hidden;
    
    ${({active}) => active === true && css`
        width: 60px;
        height: 60px;
    `
    };
    
    img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 0px 0px 1px ${({theme}) => theme.colors.black12};
        border-radius: 6px;
        z-index: 2;
    }
`

ChatHistoryImagesCarousel.propTypes = {

};

export default ChatHistoryImagesCarousel;
