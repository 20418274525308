import { routes } from "consts/routes";
import { get, isEmpty, find, isObject } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectActivePatient, selectAdaptedMessages, selectPractitioner, doctorActions } from "reducers/doctor";

export const useChatReply = () => {

    const adaptedMessages = useSelector(selectAdaptedMessages);
    const activePatient = useSelector(selectActivePatient);
    const practitioner = useSelector(selectPractitioner);

    const dispatch = useDispatch();

    const history = useHistory();

    const handleReply = (item) => {

        const adaptedMessage = find(adaptedMessages, itm => (isObject(item) ? get(item, 'item.id', null) : item) === get(itm, 'messages[0].id'));

        dispatch(doctorActions.setReplyMessage({
            id: get(adaptedMessage, "messages[0].id"),
            message: get(adaptedMessage, "messages[0].message", '').split("<br/>").join("\n"),
            date: get(adaptedMessage, "messages[0].date"),
            name: get(adaptedMessage, "messages[0].sender") === 'doctor' ? practitioner?.name[0]?.text : get(activePatient, "name"),
            type: get(adaptedMessage, "messages[0].type"),
            imageUrl: !isEmpty(get(adaptedMessage, "messages[0].images")) ? get(adaptedMessage, "messages[0].images[0].src") : '',
            fileType: !isEmpty(get(adaptedMessage, "messages[0].files")) ? get(adaptedMessage, "messages[0].files[0].type") : '',
            replyFilesAmount: get(adaptedMessage, "messages[0].images")?.length || get(adaptedMessage, "messages[0].files")?.length,
        }));

        history.push(routes.private.mobile_doctor.chat.path);
    }

    return handleReply;

}
