import PropTypes from 'prop-types';
import {withButtonStyle} from "hocs/withButtonStyle";
import Button from "../index";

const AlertButton = (props) => {
    return withButtonStyle('red', {...props})(Button)
};

AlertButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default AlertButton;
