import styled, {useTheme} from "styled-components"; 
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSurveyFileData, selectActivePatientData} from "reducers/doctor";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {getStringEnding} from "utils";
import { CustomView, isDesktop, isTablet, MobileOnlyView } from "react-device-detect";
import FileMobile from "components/FileMobile";
import fileDownload from "react-file-download";

export const AnalysisDescription = () => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatientData = useSelector(selectActivePatientData);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (activePatientData.surveys) {
            setData(activePatientData.surveys.activeItem);
        }
    }, [activePatientData.surveys]);

    const handleClick = (bucket, key, contentType, name) => {
        if (bucket && key) {
            dispatch(getSurveyFileData(bucket, key, (data) => handleDownload(data, contentType, name)))
        }
    }

    const handleDownload = (data, contentType, name) => {
        fileDownload(data, name, contentType);
    }

    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper paddings={'8px 16px'}>
                    <Text text={data.title && data.title} tag={'h5'} font={'robotoMedium'} />
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Дата проведения'} color={theme.colors.black54} />
                        <Text text={dayjs(data.date).locale(ru).format('D MMMM YYYY') + ' г.'} font={'robotoRegular'} />
                    </Wrapper>
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Тип'} color={theme.colors.black54} />
                        <Text text={data.kind} font={'robotoRegular'} />
                    </Wrapper>
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Комментарий'} color={theme.colors.black54} />
                        <Text text={data.comment} font={'robotoRegular'} />
                    </Wrapper>
                    {data.files &&
                        <Wrapper paddings={'32px 0 8px 0'} direction={'row'} justify={'space-between'}>
                            <Text tag={'h5'} text={'Вложения'}/>
                            <Text tag={'small'} text={data.files && data.files.length + " файл" + getStringEnding(data?.files?.length)} color={theme.colors.black30}/>
                        </Wrapper>
                    }
                    {data.files &&
                        data.files.map(item =>
                            <File onClick={() => handleClick(item?.bucket, item?.key, item?.contentType, item?.name)}>{item.name.slice(0, item.name.lastIndexOf('.'))}<span>{item.name.slice(item.name.lastIndexOf('.'))}</span></File>//
                        )
                    }
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper paddings={'8px 16px'}>
                    <Text text={data.title && data.title} tag={'h5'} font={'robotoMedium'} />
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Дата проведения'} color={theme.colors.black54} />
                        <Text tag={'h5'} text={dayjs(data.date).locale(ru).format('D MMMM YYYY') + ' г.'} font={'robotoRegular'} />
                    </Wrapper>
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Тип'} color={theme.colors.black54} />
                        <Text tag={'h5'} text={data.kind} font={'robotoRegular'} />
                    </Wrapper>
                    <Wrapper paddings={'8px 0 0 0'}>
                        <Text tag={'small'} text={'Комментарий'} color={theme.colors.black54} />
                        <Text tag={'h5'} text={data.comment} font={'robotoRegular'} />
                    </Wrapper>
                    {data.files &&
                        <Wrapper paddings={'32px 0 8px 0'} direction={'row'} justify={'space-between'} align={'baseline'}>
                            <Text tag={'h4'} text={'Вложения'}/>
                            <Text tag={'small'} text={data.files && data.files.length + " файл" + getStringEnding(data?.files?.length)} color={theme.colors.black30}/>
                        </Wrapper>
                    }
                    {data.files &&
                        data.files.map(item =>
                            <FileMobile file={{ ...item, title: item?.name }} customDownload={(file) => handleClick(file?.bucket, file?.key, file?.contentType, file?.name)} />
                        )
                    }
                </Wrapper>
            </MobileOnlyView>
        </>
    )
}

const File = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    ${({theme}) => theme.mixins.innerShadow()};
    ${({theme}) => theme.mixins.robotoRegular({color: theme.colors.black100, size: theme.fonts.sizes.text})};
    cursor: pointer;
    
    span {
        ${({theme}) => theme.mixins.robotoCondensedRegular({color: theme.colors.black54, size: theme.fonts.sizes.text})};
    }
`
