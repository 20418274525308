import React from 'react';
import LogoSymbol from "components/Icons/Logo/LogoSymbol";
import LogoOnlineDocText from "components/Icons/Logo/LogoOnlineDocText";
import styled from "styled-components";
import {Wrapper} from "styled/Wrapper";

const LogoOnlineDoc = () => {
    return (
        <Wrapper direction={'row'} align={'center'}>
            <SymbolWrapper>
                <LogoSymbol size={{
                    width: 46,
                    height: 38,
                }} />
            </SymbolWrapper>
            <LogoOnlineDocText size={{
                width:94,
                height:32
            }}/>
        </Wrapper>
    );
};

const SymbolWrapper = styled.div`
  margin-right: 8px;
`

export default LogoOnlineDoc;
