const calculateShadow = (props) => {
    if(props?.x) {
        return `box-shadow: inset ${props?.x === 'left' ? '' : '-'}1px 0px 0px rgba(0, 0, 0, 0.12);`
    }
    if(props?.y) {
        return `box-shadow: inset 0px ${props?.y === 'top' ? '' : '-'}1px 0px rgba(0, 0, 0, 0.12);`
    }
    return `box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);`
}

export {calculateShadow}
