import styled, {css, useTheme} from "styled-components";
import {Wrapper} from "styled/Wrapper";
import Text from "components/Text";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    doctorActions,
    // getActivePatientData,
    getServices,
    selectActivePatient,
    selectActivePatientData,
    selectFieldsFilter,
    selectLoading
} from "reducers/doctor";
import {get} from "lodash";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";
import Spinner from "components/Spinner";

export const ServiceList = ({level, prevLevel}) => {
    const theme = useTheme()
    const dispatch = useDispatch();

    const activePatient = useSelector(selectActivePatient);
    const activePatientData = useSelector(selectActivePatientData);
    const fieldsFilter = useSelector(selectFieldsFilter);
    const loading = useSelector(selectLoading);
    const [data, setData] = useState([]);
    const [initialize, setInitialize] = useState(true);

    useEffect(() => {
        // let patientId = get(activePatient, "patientId");
        let id = get(activePatient, "id");
        // dispatch(getActivePatientData(patientId));
        // let code = get(activePatient, "code");
        if (activePatientData.UKL && prevLevel < level && initialize) {
            dispatch(getServices(id, fieldsFilter));
        }
        setInitialize(false);
    }, [activePatientData.UKL]);

    useEffect(() => {
        let id = get(activePatient, "id");
        if (id && !initialize) {
            dispatch(getServices(id, fieldsFilter));
        }
    }, [activePatientData.fieldsFilter]);

    useEffect(() => {
        if (activePatientData.services) {
            setData(activePatientData.services.services);
        }
    }, [activePatientData.services]);

    const setActiveItem = (item) => {
        dispatch(doctorActions.setServicesActiveItem(item));
    }

    return (
        <>
            {!loading?.SIZLData ? data && data.map(item =>
                    <Content onClick={() => setActiveItem(item)}>
                        <CustomView condition={isDesktop || isTablet}>
                            <ListItemWrapper>
                                <ListItemBox>
                                    <Text tag={'small'} text={dayjs(item.date).locale(ru).format('D MMMM YYYY г.')} color={theme.colors.black54} />
                                    <Text text={item.name} />
                                    <Wrapper paddings={'8px 0 0 0'} direction={'row'}>
                                        <Text text={'Поставлен диагноз:'} color={theme.colors.black54} />&nbsp;
                                        <Text text={item.diagnosisCode} color={theme.colors.primary} />
                                    </Wrapper>
                                </ListItemBox>
                            </ListItemWrapper>
                        </CustomView>
                        <MobileOnlyView>
                            <ListItemWrapper>
                                <ListItemBox>
                                    <Text tag={'small'} text={dayjs(item.date).locale(ru).format('D MMMM YYYY г.')} color={theme.colors.black54} />
                                    <Text tag={'h5'} text={item.name} />
                                    <Wrapper paddings={'8px 0 0 0'} direction={'row'}>
                                        <Text text={'Поставлен диагноз:'} color={theme.colors.black54} />&nbsp;
                                        <Text text={item.diagnosisCode} color={theme.colors.primary} />
                                    </Wrapper>
                                </ListItemBox>
                            </ListItemWrapper>
                        </MobileOnlyView>
                    </Content>
                )
                :
                <Wrapper minHeight={'calc(var(--vh, 1vh) * 100 - 180px)'} width={'unset'}>
                    <Spinner/>
                </Wrapper>
            }
        </>
    )
}

const Content = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    min-height: 40px;
    padding: 0 16px;
    
    & > div {
        width: 100%;
    }
    &:hover {
        background: ${({theme}) => theme.colors.black04};
        ${({theme}) => theme.mixins.innerShadow()}
        
        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: #e0e0e0;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }
`

const ListItemWrapper = styled.div`
    min-height: 40px;
    padding: 8px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({theme}) => theme.mixins.robotoRegular()}
    ${({theme}) => theme.mixins.innerShadow()}
`

const ListItemBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
`
