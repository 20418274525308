import { createSlice, current } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {findElementByElementId, findOrganizationsByElementId} from "utils/statistics";
import {isEmpty, cloneDeep, get, isArray, find} from "lodash";
import dayjs from "dayjs";

const initialState = {
    data: [],
    periodFilteringLabel: 'За месяц',
    fieldsFilter: {
        startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        statType: 0,
        partof: null,
        'type:missing': false
    },
    searchString: null,
    sorting: {
        default: [
            {
                propertyName: "name",
                direction: 0,
            }
        ],
        practitioner: [
            {
                propertyName: "Practitioner.name",
                direction: 0,
            }
        ]
    },
    childCheckedOrganizations: null,
    loadingStatisticsPartOf: false,
    organizationsForFilter: [],
    organizationsForFilterCheckedAll: 1,
    trimesters: [],
    mkb10ForFilter: {
        searchList: [],
    },
    z33Item: {},
    activePeriodItem: 0,
    loading: {
        fullPage: true,
        data: true
    },
    exportLoader: false,
    saveThisPageFilter: false,
    fundOrganization: null,
}

const reducers = {
    setFieldsFilterInitial: (state, action) => {
        state.fieldsFilter = {
            startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            statType: 0,
            partof: null,
            profile: ''
        }
    },
    setInitialPartOf: (state, action) => {
        state.fieldsFilter.partof = action.payload.partof
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setStatTypeToFilter: (state, action) => {
        state.fieldsFilter.statType = action.payload;
    },
    setStartDateToFilter: (state, action) => {
        state.fieldsFilter.startDate = action.payload;
    },
    setPeriodFilteringLabel: (state, action) => {
        state.periodFilteringLabel = action.payload;
    },
    setEndDateToFilter: (state, action) => {
        state.fieldsFilter.endDate = action.payload;
    },
    setProfile: (state, action) => {
        state.fieldsFilter.profile = action.payload;
    },
    setSorting: (state, action) => {
        state.sorting['default'][0].propertyName = action.payload.propertyName;
        state.sorting['default'][0].direction = state.sorting['default'][0].direction === 1 ? 0 : 1;
        state.sorting['practitioner'][0].propertyName = `Practitioner.${action.payload.propertyName}`;
        state.sorting['practitioner'][0].direction = state.sorting['practitioner'][0].direction === 1 ? 0 : 1;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setPartOfData: (state, action) => {
        const targetElement = findElementByElementId(state.data.items, action.payload.partof);
        if (targetElement) targetElement.content = action.payload.data.items;
    },
    addToPartOfData: (state, action) => {
        const targetElement = findElementByElementId(state.data.items, action.payload.partof);
        if (targetElement && isArray(targetElement.content)){
            targetElement.content = targetElement.content.concat(action.payload.data.items)
        }else if(targetElement && !targetElement.content){
            targetElement.content = [...action.payload.data.items];
        }
    },
    clearPartOfData: (state, action) => {
        const targetElement = findElementByElementId(state.data.items, action.payload.partof);
        if (targetElement) targetElement.content = [];
    },
    setOpen: (state, action) => {
        const targetElement = findElementByElementId(state.data.items, action.payload.part);
        if (targetElement) targetElement.open = action.payload.open;
    },
    setOrganizationsForFilter: (state, action) => {
        state.organizationsForFilter = action.payload;
    },
    checkOrganization: (state, action) => {
        const id = action.payload.id;
        const checked = action.payload.checked;
        const targetElement = findOrganizationsByElementId(state.organizationsForFilter, id);
        const checkElementsInside = (el, checked) => {
            el.checked = checked;
            if (el.childElements) el.childElements.map(item => checkElementsInside(item, checked));
        }
        checkElementsInside(targetElement, checked);
        const checkElementsOutside = (el, checked) => {
            let parent;
            if (el.organization.partof) {
                parent = findOrganizationsByElementId(state.organizationsForFilter, el.organization.partof.id);
                if (checked) {
                    if (parent.childElements.find(item => (item.checked === 0 || item.checked === 2))) {
                        parent.checked = 2;
                    } else {
                        parent.checked = 1;
                    }
                } else {
                    if (parent.childElements.find(item => (item.checked === 1 || item.checked === 2))) {
                        parent.checked = 2;
                    } else {
                        parent.checked = 0;
                    }
                }
                if (parent.organization.partof) checkElementsOutside(parent, checked);
            }
        }
        checkElementsOutside(targetElement, checked);

        // Устанавливаем значение Выбрать все
        if (state.organizationsForFilter.find(item => (item.checked === 0) || (item.checked === 2))){
            if (!state.organizationsForFilter.find(item => (item.checked === 1) || (item.checked === 2))) {
                state.organizationsForFilterCheckedAll = 0;
            } else {
                state.organizationsForFilterCheckedAll = 2;
            }
        } else {
            state.organizationsForFilterCheckedAll = 1;
        }
    },
    checkOrganizationsAll: (state, action) => {
        const changeChecked = (arr, value) => {
            arr.forEach(item => {
                item.checked = value;
                if (item.childElements) changeChecked(item.childElements, value);
            })
        }
        changeChecked(state.organizationsForFilter, action.payload);
        state.organizationsForFilterCheckedAll = action.payload;
    },
    changeOrganizationsForFilterCheckedAll: (state, action) => {
        state.organizationsForFilterCheckedAll = action.payload;
    },
    setTrimestersList: (state, action) => {
        state.trimesters = action.payload;
    },
    setTrimesterCodeToFilter: (state, action) => {
        if (action.payload) {
            state.fieldsFilter.trimester = action.payload;
        } else {
            delete state.fieldsFilter.trimester;
        }
    },
    setMkb10ListForFilter: (state, action) => {
        state.mkb10ForFilter.searchList = action.payload;
    },
    setMkb10ToFilter: (state, action) => {
        if (action.payload) {
            state.mkb10ForFilter.activeElement = action.payload;
            state.fieldsFilter.conditionCode = action.payload[0].code;
        } else {
            delete state.mkb10ForFilter.activeElement;
            delete state.fieldsFilter.conditionCode;
        }
    },
    setOrganizationsToFilter: (state, action) => {
        if (action.payload) {
            state.fieldsFilter.organizationId = action.payload;
        } else {
            delete state.fieldsFilter.organizationId;
        }
    },
    setOrganizationsFilterForChildElements: (state, action) => {
        state.childCheckedOrganizations = action.payload;
    },
    setActivePeriodItem: (state, action) => {
        state.activePeriodItem = action.payload;
    },
    resetFilters: (state, action) => {
        if(!state.saveThisPageFilter){
            state.fieldsFilter = {
                startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
                statType: 0,
                partof: null,
                profile: '',
                'type:missing': false
            }

            state.childCheckedOrganizations = null;
            state.activePeriodItem = 0;
            state.periodFilteringLabel = 'За месяц';
            state.trimesters = cloneDeep(state.trimesters).map(item => ({ ...item, active: item?.id === 0}));

            if(state.fieldsFilter.trimester){
                delete state.fieldsFilter.trimester;
            }

            if(state.mkb10ForFilter.activeElement) {
                delete state.mkb10ForFilter.activeElement
            }
        }
    },
    setExportLoader: (state, action) => {
        state.exportLoader = action.payload;
    },
    setProfilesToFilter: (state, action) => {
        action.payload ? state.fieldsFilter.profile = action.payload : delete state.fieldsFilter.profile;
    },
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    resetState: (state, action) => {
        return initialState
    },
    setLoadingStatisticsPartOf: (state, action) => {
        const targetElement = findElementByElementId(state.data.items, action.payload.part);
        if (targetElement) targetElement.loading = action.payload.loading;
        // state.loadingStatisticsPartOf = action.payload;
    },
    setSearchString: (state, action) => {
        state.searchString = action.payload;
    },
    setZ33Item:(state, action) => {
        state.z33Item = action.payload;
    },
    setFundOrganization: (state, action) => {
        state.fundOrganization = action.payload
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectPeriodFilteringLabel: state => get(state, statePath)?.periodFilteringLabel,
    selectFieldsFilter: state => get(state, statePath)?.fieldsFilter,
    selectSorting: state => get(state, statePath)?.sorting.default,
    selectPractitionerSorting: state => get(state, statePath)?.sorting.practitioner,
    selectOrganizationsForFilter: state => get(state, statePath)?.organizationsForFilter,
    selectOrganizationsForFilterCheckedAll: state => get(state, statePath)?.organizationsForFilterCheckedAll,
    selectTrimesters: state => get(state, statePath)?.trimesters,
    selectActiveTrimester: state => get(state, statePath)?.activeTrimester,
    selectMkb10ForFilter: state => get(state, statePath)?.mkb10ForFilter.searchList,
    selectMkb10ActiveElementForFilter: state => get(state, statePath)?.mkb10ForFilter.activeElement,
    selectActivePeriodItem: state => get(state, statePath)?.activePeriodItem,
    selectLoading: state => get(state, statePath)?.loading,
    selectExportLoader: state => get(state, statePath)?.exportLoader,
    selectSaveThisPageFilter: state => get(state, statePath)?.saveThisPageFilter,
    selectSearchString: state => get(state, statePath)?.searchString,
    selectChildCheckedOrganization: state => get(state, statePath)?.childCheckedOrganizations,
    selectZ33Item: state => get(state, statePath)?.z33Item,
    selectStatType: state => get(state, statePath)?.fieldsFilter.statType,
    selectFundOrganization: state => get(state, statePath)?.fundOrganization,
})

export const getThunks = (actions) => {

    const getStatistics = ({fieldsFilter, sorting, searchString}) => {
        return (dispatch) => {
        dispatch(actions.setLoading({
                type: 'data',
                value: true
        }));      
        fetchInstance({
            method: "POST",
            url: `${api.statistics}/_search`,
            data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering:{
                        searchString,
                        fieldsFilter: fieldsFilter,
                    },
                    sorting,
                }
            }).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
            }));
            });
        };
    };

    const getStatisticsPartOf = ({fieldsFilter, sorting, addToExistData, searchString}) => {
        const fieldsFilterCopy = cloneDeep(fieldsFilter);
        // delete fieldsFilterCopy.ids;
        return (dispatch) => {
        dispatch(actions.setLoadingStatisticsPartOf({
            part: fieldsFilter.partof,
            loading: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.statistics}/_search`,
            data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering: {
                        searchString,
                        fieldsFilter: fieldsFilterCopy,
                    },
                    sorting,
                }
            }).then((response) => {
                if(addToExistData){
                    dispatch(actions.addToPartOfData({
                        data: response.data,
                        partof: fieldsFilter.partof,
                    }));
                }else{
                    dispatch(actions.setPartOfData({
                        data: response.data,
                        partof: fieldsFilter.partof,
                    }));
                }
                dispatch(actions.setLoadingStatisticsPartOf({
                    part: fieldsFilter.partof,
                    loading: false
                }));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPractitionersPartOf = ({fieldsFilter, sorting, addToExistData, searchString}) => {
        const fieldsFilterCopy = cloneDeep(fieldsFilter);
        delete fieldsFilterCopy.ids;

        return (dispatch) => {
        dispatch(actions.setLoadingStatisticsPartOf({
            part: fieldsFilter.organizationId,
            loading: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.practitioners}/_search`,
            data: {
                    paging: {
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering: {
                        searchString,
                        fieldsFilter: fieldsFilterCopy,
                    },
                    sorting
                }
            }).then((response) => {
                // console.log("response", response.data);
                const data = cloneDeep(response.data);
                let newData = {items: []};

                data.items.forEach(item => {
                    // console.log("item", item);
                    newData.items.push({
                        practitioner: {
                            id: get(item, `practitioner.id`),
                            name: get(item, `practitioner.name[0].text`),
                            practitionerData: {
                                active: get(item, `practitioner.active`),
                                gender: get(item, `practitioner.gender`),
                            }
                        },
                        practitionerRole: get(item, `practitionerRole`),
                        statistics: item.statistics,
                        partof: fieldsFilter.organizationId
                    })
                })

                if(addToExistData){
                    dispatch(actions.addToPartOfData({
                        data: newData,
                        partof: fieldsFilter.organizationId,
                    }));
                }else{
                    dispatch(actions.setPartOfData({
                        data: newData,
                        partof: fieldsFilter.organizationId,
                    }));
                }
                dispatch(actions.setLoadingStatisticsPartOf({
                    part: fieldsFilter.organizationId,
                    loading: false
                }));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getOrganizationsForFilter = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.organizationHierarchy}?fg=type:missing::false`,
            }).then((response) => {
                const items = response.data.items;
                const setAllElementsChecked = (dataObj, level) => {
                    const objLevel = level ? level : 1;
                    dataObj.forEach(item => {
                        item.checked = 1;
                        item.level = objLevel;
                        if (!isEmpty(item.childElements)) setAllElementsChecked(item.childElements, objLevel + 1);
                    });
                }
                setAllElementsChecked(items);
                dispatch(actions.setOrganizationsForFilter(items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getTrimestersOfPregnancyForFilter = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.trimesters,
            }).then((response) => {
                const items = response.data.items
                items.forEach(item => {
                    item.label = item.display;
                });
                items.unshift({id: 0, label: 'Любой триместр', active: true, code: null})
                dispatch(actions.setTrimestersList(items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getMkb10ForFilter = searchString => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.mkb10}?ss=${searchString}`,
            }).then((response) => {
                const initial = response.data.items;
                const items = initial.filter(item => item.code);
                items.forEach(item => {
                    item.label = item.display;
                    item.pre = item.code;
                });
                dispatch(actions.setMkb10ListForFilter(items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getZ33ItemForFilter = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.mkb10}?ss=Z33`,
            }).then((response) => {
                const item = get(response, 'data.items[0]');

                item && dispatch(actions.setZ33Item({
                    ...item,
                    label: item.display,
                    pre: item.code
                }));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getFundOrganization = () => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: api.organization,
            params: {
            start: 0,
            max: 0,
            fg: "name::МГФОМС"
            }
        }).then((response) => {
                const items = get(response, 'data.items')
                const item = find(items, {name: "МГФОМС"})
                dispatch(actions.setFundOrganization(item));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getStatistics, getStatisticsPartOf, getPractitionersPartOf, getOrganizationsForFilter, getTrimestersOfPregnancyForFilter, getMkb10ForFilter, getZ33ItemForFilter, getFundOrganization }

}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        StatisticsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
