import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {get, cloneDeep} from "lodash";
import {responseTime, systemMessageDelay} from "consts";
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";
import {routes} from "consts/routes";

const initialState = {
    data: [],
    type: {},
    all: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                // extended: true,
                // approle: 'AppRole/doctor',
                practitionerRoleStatus: "active,awaiting,archived,not-available",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    active: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                // extended: true,
                // approle: 'AppRole/doctor',
                practitionerRoleStatus: "active,awaiting,not-available",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    archived: {
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                // extended: true,
                // approle: 'AppRole/doctor',
                practitionerRoleStatus: "archived",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "practitionerName",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        },
    },
    modalAnswerTimingRule: null,
    timeoutMinutes: responseTime[0].value,
    loading: {
        fullPage: true,
        data: true,
        replaceDoctor: false,
        answerTimingRule: true
    },
    saveThisPageFilter: false,  // костыли из-за того что tableSettings не вынесены редьюсер tableFilters
    doctorRoleToArchive: {},
    answerTimeInterval: 0,
    patientsForModal: [],
    doctorsForModal: [],
    adminLogin: null
};

const reducers = {
    setOrganizationObj: (state, action) => {
        state.organization.organizationObj = action.payload;
    },
    setPage: (state, action) => {
        state[action.payload.table].tableSettings.paging.startIndex = (action.payload.page - 1) * state[action.payload.table].tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state[action.payload.table].tableSettings.paging.maxItems = action.payload.maxItems;
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setSearchString: (state, action) => {
        state[action.payload.table].tableSettings.searchString = action.payload.value;
    },
    setSorting: (state, action) => {
        state[action.payload.table].tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state[action.payload.table].tableSettings.sorting[0].direction = state[action.payload.table].tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setStatusesToFilter: (state, action) => {
        state[action.payload.table].tableSettings.fieldsFilter.practitionerRoleStatus = action.payload.value
    },
    setDoctorsToFilter: (state, action) => {
        action.payload.value ? state[action.payload.table].tableSettings.fieldsFilter.practitionerId = action.payload.value : delete state[action.payload.table].tableSettings.fieldsFilter.practitionerId;
    },
    setOrganizationIdToFilter: (state, action) => {
        state.active.tableSettings.fieldsFilter.organization = action.payload;
        state.archived.tableSettings.fieldsFilter.organization = action.payload;
    },
    setSpecialityToFilter: (state, action) => {
        action.payload.value ? state[action.payload.table].tableSettings.fieldsFilter.specialtyCode = action.payload.value : delete state[action.payload.table].tableSettings.fieldsFilter.specialtyCode;
    },
    setModalAnswerTimingRule: (state, action) => {
        state.modalAnswerTimingRule = action.payload;
    },
    setTimeoutMinutes: (state, action) => {
        state.timeoutMinutes = action.payload;
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) {
            state.active.tableSettings = initialState.active.tableSettings;
            state.archived.tableSettings = initialState.archived.tableSettings;
        }
    },
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    setDoctorRoleToArchive: (state, action) => {
        state.doctorRoleToArchive = action.payload;
    },
    setAnswerTimeInterval: (state, action) => {
        state.answerTimeInterval = action.payload;
    },
    setPatientsForModal: (state, action) => {
        state.patientsForModal = action.payload;
    },
    setDoctorsForModal: (state, action) => {
        state.doctorsForModal = action.payload;
    },
    setAdminLogin: (state, action) => {
        state.adminLogin = action.payload
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsActiveCopy = cloneDeep(state.active.tableSettings);
        const tableSettingsArchievedCopy = cloneDeep(state.archived.tableSettings);
        delete initialStateCopy.active.tableSettings;
        delete initialStateCopy.archived.tableSettings;
        initialStateCopy.active.tableSettings = tableSettingsActiveCopy;
        initialStateCopy.archived.tableSettings = tableSettingsArchievedCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectTableSettings: mode => state => get(state, statePath)[mode]?.tableSettings,
    selectData: state => get(state, statePath)?.data,
    selectType: state => get(state, statePath)?.type,
    selectModalAnswerTimingRule: state => get(state, statePath)?.modalAnswerTimingRule,
    selectTimeoutMinutes: state => get(state, statePath)?.timeoutMinutes,
    selectLoading: state => get(state, statePath)?.loading,
    selectDoctorRoleToArchive: state => get(state, statePath)?.doctorRoleToArchive,
    selectAnswerTimeInterval: state => get(state, statePath)?.answerTimeInterval,
    selectPatientsForModal: state => get(state, statePath)?.patientsForModal,
    selectDoctorsForModal: state => get(state, statePath)?.doctorsForModal,
    selectAdminLogin: state => get(state, statePath)?.adminLogin,
})


export const getThunks = (actions) => {

    const getDoctors = ({tableSettings, organizationId}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${organizationId}/Doctors`,
            data: {
                paging: tableSettings.paging,
                filtering: {
                    searchString: tableSettings.searchString,
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting,
            }}).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const deleteDoctor = ({id, tableSettings, organizationId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
                dispatch(registerMessage({name: 'delete-doctor-message', type: 'primary', title: 'Доктор удален', closable: true, url: routes.private.registrator.doctors.path}))
                dispatch(showMessage('delete-doctor-message'))
                dispatch(closeMessage({name: 'delete-doctor-message', delay: systemMessageDelay}))

                dispatch(getDoctors({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err);
                dispatch(registerMessage({name: 'delete-doctor-message', type: 'primary', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Ошибка запроса', closable: true, url: routes.private.registrator.doctors.path}))
                dispatch(showMessage('delete-doctor-message'))
                dispatch(closeMessage({name: 'delete-doctor-message', delay: systemMessageDelay}))
            });
        };
    };

    const getPractitionersStatictics = ({practitionerId, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitioners}/_search`,
            data: {
                paging: {
                startIndex: 0,
                maxItems: 10,
                totalItems: 0
                },
                filtering: {
                searchString: null,
                fieldsFilter: {
                    practitionerId
                }
                },
                sorting: [
                {
                    direction: 0,
                    propertyName: "name"
                }
                ]
            }
            }).then((response) => {
                callback(response.data?.items[0]?.statistics?.archive);
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const archiveDoctor = ({item, tableSettings, organizationId}) => {
        // console.log("item", item);

        return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: api.practitionerRole,
            data: item
        }).then((response) => {
                dispatch(getDoctors({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const editDoctor = ({formData, tableSettings, organizationId, callback}) => {
        if (formData.practitionerImage) {
            let imageData = new FormData();
            imageData.append("files", formData.practitionerImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.practitionerImage;
                    delete formDataCopy.practitionerPhoto;
                    formDataCopy.practitionerPhoto = response.data[0].url;
                    dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, organizationId, isEdit: true, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else if (formData.practitionerImage === null) {
            // console.log("Нужно сбросить");
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.practitionerPhoto = null;
                dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, organizationId, isEdit: true, callback}));
            };
        } else {
            // console.log("Обновлять не нужно");
            return (dispatch) => {
                dispatch(createOrUpdateDoctorData({formData, tableSettings, organizationId, isEdit: true, callback}));
            };
        }
    };

    const createDoctor = ({formData, tableSettings, organizationId, callback}) => {
        // console.log("formData", formData);
        const formDataCopy = cloneDeep(formData);
        if (formData.practitionerImage) {
            let imageData = new FormData();
            imageData.append("files", formData.practitionerImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    delete formDataCopy.practitionerImage;
                    delete formDataCopy.practitionerPhoto;
                    formDataCopy.practitionerPhoto = response.data[0].url;
                    dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, organizationId, callback}));
                }).catch(err => {
                    console.log(err)
                });
            };
        } else {
            delete formDataCopy.practitionerImage;
            delete formDataCopy.practitionerPhoto;
            formDataCopy.practitionerPhoto = null;
            return (dispatch) => {
                dispatch(createOrUpdateDoctorData({formData: formDataCopy, tableSettings, organizationId, callback}));
            };
        }
    };

    const createOrUpdateDoctorData = ({formData, tableSettings, organizationId, isEdit, callback}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatedoctor`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto
                    },
                    practitionerRoleInfo: {
                        specialtyCode: formData?.practitionerProfile?.code,
                        specialtyDisplay: formData?.practitionerProfile?.display,
                        practitionerCode: formData?.practitionerCode,
                        availableStartTime: formData?.availableStartTime,
                        availableEndTime: formData?.availableEndTime,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'doctor',
                    answeringTimeRuleInfo: {
                        id: formData.answerTimingRule.id,
                        timeoutMinutes: formData.answerTimingRule.timeoutMinutes.value,
                        derivedFrom: null,
                        exceptNonWorkingTime: formData.answerTimingRule.exceptNonWorkingTime,
                        exceptNonWorkingDates: formData.answerTimingRule.exceptNonWorkingDates,
                    }
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                tableSettings && dispatch(getDoctors({tableSettings, organizationId}));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-doctor-message',
                    type: 'primary',
                    title: `Врач успешно ${isEdit ? 'обновлен' : 'создан'}`,
                    closable: true,
                    url: formData?.practitionerRoleId ? `/doctors/doctor-${formData?.practitionerRoleId}` : undefined
                }))
                dispatch(showMessage('create-update-doctor-message'))
                dispatch(closeMessage({name: 'create-update-doctor-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-doctor-error-message',
                    type: 'red',
                    title: `Не удалось ${isEdit ? 'обновить' : 'создать'} врача`,
                    closable: true,
                }))
                dispatch(showMessage('create-update-doctor-error-message'))
                dispatch(closeMessage({name: 'create-update-doctor-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getAnswerTimingRule = id => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'answerTimingRule',
                value: true
            }));

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/AnswerTimingRule`
                }).then((response) => {
                    dispatch(dispatch(actions.setModalAnswerTimingRule(response.data)));
                    if (get(response.data, "rule.timeoutMinutes")) dispatch(dispatch(actions.setTimeoutMinutes(get(response.data, "rule.timeoutMinutes", ''))));

                    dispatch(actions.setLoading({
                        type: 'answerTimingRule',
                        value: false
                    }));

            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'answerTimingRule',
                    value: false
                }));
            });
        };
    };

    const getPractitionerRole = (id) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}`,
            }).then((response) => {
                dispatch(actions.setDoctorRoleToArchive(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const restoreDoctor = ({id, tableSettings, organizationId}) => {
        return (dispatch) => {
        fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/RestoreFromArchive`
            }).then((response) => {
                dispatch(getDoctors({tableSettings, organizationId}));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const changePractitioner = ({formData, organizationId, handleHide, tableSettings, withDoctorDeletation, reloadList}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'replaceDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.changepractitioner}`,
                data: {
                    practitionerRoleId: formData.practitionerRoleId,
                    practitionerId: formData.practitionerId,
                    authorPractitionerRoleId: formData.authorPractitionerRoleId,
                    nonAvailabilityReason: formData.nonAvailabilityReason,
                    newPractitionerRoleId: formData.newPractitionerRoleId,
                    doctorSubstitution: formData.doctorSubstitution,
                }}).then((response) => {
                    const encounters = response.data.encounters;

                    dispatch(getStatisticUpdateStatus({
                        encounters,
                        iteration: 30,
                        handleHide,
                        handleStopLoading: () => dispatch(actions.setLoading({
                            type: 'replaceDoctor',
                            value: false
                        })),
                        onCompleted: () => {
                            reloadList && dispatch(getDoctors({tableSettings, organizationId}));
                            withDoctorDeletation && dispatch(deleteDoctor({
                                id: formData.practitionerId,
                                tableSettings,
                                organizationId
                            }))
                        },
                        organizationId,
                        tableSettings
                    }));
                }).catch((error) => {
                    dispatch(actions.setLoading({
                        type: 'replaceDoctor',
                        value: false
                    }));
                    dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                    dispatch(showMessage('create-channel-error-message'))
                    dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const getStatisticUpdateStatus = ({encounters, iteration, handleHide, handleStopLoading, organizationId, tableSettings, onCompleted}) => {
        return (dispatch) => {
            const promises = [];

            encounters.forEach(item => {
                promises.push(
                    fetchInstance({
                        method: "GET",
                        url: `${api.statisticUpdateStatus}/${item}`,
                    })
                );
            });

            Promise.all(promises).then((values) => {
                const completed = values.every(elem => elem.data === "Completed");
                if (completed || !iteration) {
                    handleHide && handleHide();
                    handleStopLoading && handleStopLoading();
                    if (completed) {
                        onCompleted && onCompleted();
                    }
                    if (!iteration) {
                        dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                        dispatch(showMessage('check-status-error-message'))
                        dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    }
                } else {
                    setTimeout(() => dispatch(getStatisticUpdateStatus({
                        encounters,
                        iteration: --iteration,
                        handleHide,
                        handleStopLoading,
                        organizationId,
                        tableSettings,
                        onCompleted
                    })), 1000);
                }
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    const calculateAnswerTime = ({
        date,
        ruleInMinutes,
        startWorkTime,
        endWorkTime,
        exceptNonWorkingTime,
        exceptNonWorkingDates
    }) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.calculateAnswerTime}?date=${date}&ruleInMinutes=${ruleInMinutes}&startWorkTime=${startWorkTime}&endWorkTime=${endWorkTime}&exceptNonWorkingTime=${exceptNonWorkingTime}&exceptNonWorkingDates=${exceptNonWorkingDates}`,
            }).then((response) => {
                dispatch(actions.setAnswerTimeInterval(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getPatientsForModal = (id) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerStatistic}/${id}/EncountersStatistics`,
            data: {
                paging:{
                startIndex:0,
                maxItems:0
            },
            filtering:{
                searchString: null,
                fieldsFilter:{
                    status: "planned, in-progress, onleave",
                }
            },
            sorting:[
                ]
            } }).then((response) => {
                dispatch(actions.setPatientsForModal(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getDoctorsForModal = ({
        organizationId,
        practitionerId
    }) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                searchString: '',
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        extended: true,
                        organization: organizationId,
                        approle: 'AppRole/doctor',
                        physicianstatus: "active",
                    }
                },
                sorting: [],
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
            }}).then((response) => {
                const doctors = [];

                response.data.items.forEach(item => {
                    if (response.data.resources[item.practitioner.reference]) {
                        doctors.push({
                            id: response.data.resources[item.practitioner.reference]?.id,
                            practitionerRoleId: item?.id,
                            display: response.data.resources[item.practitioner.reference]?.name[0]?.text,
                        })
                    }
                });

                const filtered = doctors.filter(item => {
                    return item.id !== practitionerId;
                })

                dispatch(actions.setDoctorsForModal(filtered));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getAdminLogin = ({roleId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
            }).then((response) => {
                const login = get(response, 'data.value');
                login && dispatch(actions.setAdminLogin(login));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getDoctors, deleteDoctor, getPractitionersStatictics, archiveDoctor, editDoctor, createDoctor, sendActivationNotification, getAnswerTimingRule, getPractitionerRole, restoreDoctor, changePractitioner, getStatisticUpdateStatus, resetPassword, calculateAnswerTime, getPatientsForModal, getDoctorsForModal, getAdminLogin }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        doctorsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
