import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {setActiveTab, registerTabsGroup, unregisterTabsGroup} from "reducers/tabs/tabsSlice";
import { find } from 'lodash';

const TabsHeader = ({list, type, name, justifyContent, margin, height, dontClearTabsState}) => {



    const [tabs, setTabsActivity] = useState(list)
    const dispatch = useDispatch();

    const tabsState = useSelector(state => state.tabs);

    useEffect(() => {

        const currentTabs = find(tabsState, {name})
        if(dontClearTabsState && currentTabs){
            currentTabs && setActive(currentTabs?.activeTab)
        }else{
            currentTabs && setActive(0)
            dispatch(unregisterTabsGroup(name))
            dispatch(registerTabsGroup({
                name,
                activeTab: 0
            }))
        }
    }, [])

    useEffect(() => {
        const currentTabs = find(tabsState, {name})
        mapTabsActivity(currentTabs?.activeTab)
    }, [tabsState])

    const mapTabsActivity = (id) => {
        setTabsActivity([...tabs].map(object => {
            if(object.id === id) {
                return {
                    ...object,
                    activity: true,
                }
            }
            else {
                return {
                    ...object,
                    activity: false,
                }
            }
        }))
    }

    /*Задаем значение active выбранному табу*/
    const setActive = (id) => {
        mapTabsActivity(id)
        dispatch(setActiveTab({name: name, value: id}));
    }

    return (
        <TabHeader type={type} justifyContent={justifyContent} height={height}>
            {tabs.length > 0 &&
                tabs.map(item => <Tab margin={margin} type={type} className={item.activity && 'active'} onClick={() => setActive(item.id)}>{item.label}</Tab>)
            }
        </TabHeader>
    );
};

const Tab = styled.div`
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    margin: ${({margin}) => margin || 0};
    
    ${({type}) => type === 'buttons' ? css`
        ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.primary})};
        padding: 6px 12px;
    ` : css`
        ${({theme}) => theme.mixins.robotoMedium({color: theme.colors.black54})};
        height: 100%;
        ${({theme}) => theme.isMobile && css`padding: 12px 0;`}
    `}
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: ${({type}) => type === 'buttons' ? '#f5f5f5' : 'transparent'};
        color: ${({type, theme}) => type === 'lined' && theme.colors.black87};
    }
    &.active {
        background: ${({type}) => type === 'buttons' ? ({theme}) => theme.colors.primary12 : 'transparent'};
        color: ${({theme}) => theme.colors.primary};
        
        ${({type}) => type === 'lined' && css`
            &:after {
                content: '';
                width: 100%;
                height: 3px;
                background: ${({theme}) => theme.colors.primary};
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 6px 6px 0 0;
            }
        `
    }
`

const TabHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: ${({justifyContent}) => justifyContent};
    min-height: ${({height}) => height};
    height: ${({height}) => height};
    ${({theme}) => theme.isMobile && css`
      box-shadow: inset 0px -1px 0px #E0E0E0; 
    `}
`

TabsHeader.propTypes = {
    list: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['buttons', 'lined']),
}

TabsHeader.defaultProps = {
    type: 'buttons',
}

export default TabsHeader;
