import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {get, cloneDeep, isNumber} from "lodash";
import {responseTime, systemMessageDelay} from "consts";
import {registerMessage, showMessage, closeMessage} from "reducers/systemMessages/systemMessagesSlice";
import {routes} from "consts/routes";

const initialState = {
    data: [],
    patients: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                // extended: true,
                approle: 'AppRole/doctor',
                status: "planned, onleave, in-progress, finished, cancelled",
                // conditionCode: '',
                protocolExecution: "true,false",
                // patientId: ''
                // diagnosis: ""
            },
            sorting: [
                {
                    propertyName: "patient",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        }
    },
    modalAnswerTimingRule: null,
    modalPractitionerData: null,
    timeoutMinutes: responseTime[0].value,
    patientsForModal: [],
    doctorsForModal: [],
    doctorRoleId: null,
    doctorId: null,
    doctorRole: {},
    activeChannelsCount: null,
    archiveChannelsCount: null,
    channelsCount: null,
    loading: {
        fullPage: true,
        data: true,
        patientsData: true,
        doctorStatistics: true,
        replaceDoctor: false,
        resumeDoctor: false,
        answerTimingRule: true
    },
    adminLogin: null,
};

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    setPatients: (state, action) => {
        state.patients.data = action.payload;
    },
    setPage: (state, action) => {
        state.patients.tableSettings.paging.startIndex = (action.payload - 1) * state.patients.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.patients.tableSettings.paging.maxItems = action.payload;
    },
    setModalAnswerTimingRule: (state, action) => {
        state.modalAnswerTimingRule = action.payload;
    },
    setModalPractitionerData: (state, action) => {
        state.modalPractitionerData = action.payload;
    },
    setTimeoutMinutes: (state, action) => {
        state.timeoutMinutes = action.payload;
    },
    setPatientsForModal: (state, action) => {
        state.patientsForModal = action.payload;
    },
    setDoctorsForModal: (state, action) => {
        state.doctorsForModal = action.payload;
    },
    setDoctorRoleId: (state, action) => {
        state.doctorRoleId = action.payload;
    },
    setDoctorId: (state, action) => {
        state.doctorId = action.payload;
    },
    setDoctorRole: (state, action) => {
        state.doctorRole = action.payload;
    },
    setSorting: (state, action) => {
        state.patients.tableSettings.sorting[0].propertyName = action.payload;
        state.patients.tableSettings.sorting[0].direction = state.patients.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setStatusesToFilter: (state, action) => {
        state.patients.tableSettings.fieldsFilter.status = action.payload;
    },
    setDiagnosisToFilter: (state, action) => {
        action.payload ? state.patients.tableSettings.fieldsFilter.conditionCode = action.payload : delete state.patients.tableSettings.fieldsFilter.conditionCode;
    },
    setPatientsIdToFilter: (state, action) => {
        action.payload ? state.patients.tableSettings.fieldsFilter.patientId = action.payload : delete state.patients.tableSettings.fieldsFilter.patientId;
    },
    setProtocolsToFilter: (state, action) => {
        action.payload ? state.patients.tableSettings.fieldsFilter.protocolExecution = action.payload : state.patients.tableSettings.fieldsFilter.protocolExecution = "true,false";
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.patients.tableSettings.fieldsFilter = {...state.patients.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.patients.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setInitialState: state => initialState,
    setChannelsCount: (state, action) => {
        state.channelsCount = action.payload;
    },
    setActiveChannelsCount: (state, action) => {
        state.activeChannelsCount = action.payload;
    },
    setArchiveChannelsCount: (state, action) => {
        state.archiveChannelsCount = action.payload;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.patients.tableSettings);
        delete initialStateCopy.patients.tableSettings;
        initialStateCopy.patients.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    setAdminLogin: (state, action) => {
        state.adminLogin = action.payload
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectTableSettings: state => get(state, statePath)?.patients.tableSettings,
    selectData: state => get(state, statePath)?.data,
    selectPatientsData: state => get(state, statePath)?.patients.data,
    selectModalAnswerTimingRule: state => get(state, statePath)?.modalAnswerTimingRule,
    selectTimeoutMinutes: state => get(state, statePath)?.timeoutMinutes,
    selectPatientsForModal: state => get(state, statePath)?.patientsForModal,
    selectDoctorsForModal: state => get(state, statePath)?.doctorsForModal,
    selectDoctorRoleId: state => get(state, statePath)?.doctorRoleId,
    selectDoctorId: state => get(state, statePath)?.doctorId,
    selectLoading: state => get(state, statePath)?.loading,
    selectChannelsCount: state => get(state, statePath)?.channelsCount,
    selectActiveChannelsCount: state => get(state, statePath)?.activeChannelsCount,
    selectArchiveChannelsCount: state => get(state, statePath)?.archiveChannelsCount,
    selectDoctorRole: state => get(state, statePath)?.doctorRole,
    selectAdminLogin: state => get(state, statePath)?.adminLogin,
    selectModalPractitionerData: state => get(state, statePath)?.modalPractitionerData
})


export const getThunks = (actions) => {

    const getDoctorRole = (practitionerRoleId) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/_search`,
                data: {
                    paging: {
                        startIndex: 0,
                        maxItems: 0
                    },
                    filtering: {
                        searchString: null,
                        fieldsFilter: {
                            id: practitionerRoleId,
                            _include: "practitioner,organization",
                        }
                    },
                },
            }).then((response) => {
                dispatch(actions.setDoctorRole(get(response, "data.items[0]")));
                dispatch(actions.setData(response.data));
                dispatch(actions.setDoctorId(get(response, "data.practitioner.id")));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getPatients = ({ fieldsFilter, sorting, paging, practitionerRoleId }) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'patientsData',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.practitionerStatistic}/${practitionerRoleId}/Patient`,
            data: {
                filtering: {
                    searchString: '',
                    fieldsFilter: fieldsFilter
                },
                sorting: sorting,
                paging: paging
            } }).then((response) => {
                dispatch(actions.setPatients(response.data));
                dispatch(actions.setLoading({
                    type: 'patientsData',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'patientsData',
                    value: false
                }));
            });
        };
    };


    const getDoctorStatistics = ({practitionerRole, fieldsFilter, sorting}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'doctorStatistics',
                value: true
            }))

            fetchInstance({
                method: "POST",
                url: `${api.practitioners}/_search`,
                data: {
                    paging:{
                        startIndex: 0,
                        maxItems: 10
                    },
                    filtering:{
                        searchString: null,
                        fieldsFilter: {
                            ...fieldsFilter,
                            practitionerId: practitionerRole?.practitioner?.id,
                            organizationId: practitionerRole?.organization?.id,
                            statType: 0,
                            practitionerRoleId: practitionerRole?.id,
                        }
                    },
                    sorting,
                }
            }).then((response) => {
                const data = get(response,'data');
                const item = get(data, 'items[0]');

                const activeChannelsCount = get(item, 'statistics.active');
                const archiveChannelsCount = get(item, 'statistics.archive');
                const inactiveChannelsCount = get(item, 'statistics.inActive');
                const channelsCount =
                    (isNumber(activeChannelsCount) ? activeChannelsCount : 0) +
                    (isNumber(archiveChannelsCount) ? archiveChannelsCount : 0)+
                    (isNumber(inactiveChannelsCount) ? inactiveChannelsCount : 0);

                dispatch(actions.setChannelsCount(channelsCount));
                dispatch(actions.setActiveChannelsCount(activeChannelsCount));
                dispatch(actions.setArchiveChannelsCount(archiveChannelsCount));

                dispatch(actions.setLoading({
                    type: 'doctorStatistics',
                    value: false
                }))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'doctorStatistics',
                    value: false
                }))
            });
        };
    };

    const getAnswerTimingRule = id => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'answerTimingRule',
                value: true
            }));

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}/AnswerTimingRule`
                }).then((response) => {
                    dispatch(dispatch(actions.setModalAnswerTimingRule(response.data)));
                    if (get(response.data, "rule.timeoutMinutes")) dispatch(dispatch(actions.setTimeoutMinutes(get(response.data, "rule.timeoutMinutes", ''))));

                    dispatch(actions.setLoading({
                        type: 'answerTimingRule',
                        value: false
                    }));
            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'answerTimingRule',
                    value: false
                }));
            });
        };
    };

    const getPractitionerDataForModal = id => {
        return (dispatch) => {

            dispatch(actions.setLoading({
                type: 'practitionerDataForModal',
                value: true
            }));

            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${id}`,
                }).then((response) => {
                    dispatch(actions.setModalPractitionerData(get(response, 'data')));

                    dispatch(actions.setLoading({
                        type: 'practitionerDataForModal',
                        value: false
                    }));

            }).catch(err => {
                console.log(err)
                dispatch(actions.setLoading({
                    type: 'practitionerDataForModal',
                    value: false
                }));
            });
        };
    };

    const editAnswerTimingRule = ({formData, callback}) => {
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatedoctor`,
                data: {
                    practitionerInfo: formData?.practitionerInfo,
                    practitionerRoleInfo: {
                        availableStartTime: formData?.practitionerRole?.availableStartTime,
                        availableEndTime: formData?.practitionerRole?.availableEndTime,
                        practitionerRoleId: formData.practitionerRoleId,
                        contactEmail: formData?.practitionerRole?.contactEmail,
                    },
                    answeringTimeRuleInfo: {
                        id: formData.answerTimingRule.id,
                        timeoutMinutes: formData?.answerTimingRule?.timeoutMinutes?.value,
                        exceptNonWorkingTime: formData.answerTimingRule.exceptNonWorkingTime,
                        exceptNonWorkingDates: formData.answerTimingRule.exceptNonWorkingDates,
                    },
                    appRole: "doctor"
                }
            }).then((response) => {
                dispatch(registerMessage({name: 'answer-time-rule-doctor-message', type: 'primary', title: 'Правила времени на ответ успешно обновлены', closable: true, url: routes.private.root.accounts.path}))
                dispatch(showMessage('answer-time-rule-doctor-message'))
                dispatch(closeMessage({name: 'answer-time-rule-doctor-message', delay: systemMessageDelay}))
                dispatch(getAnswerTimingRule(formData.practitionerRoleId));
                callback && callback()
            }).catch((error) => {
                console.log(error);
                dispatch(registerMessage({name: 'answer-time-rule-doctor-error-message', type: 'red', title: 'Не удалось обновить правила времени на ответ', closable: true, url: routes.private.root.accounts.path}))
                dispatch(showMessage('answer-time-rule-doctor-error-message'))
                dispatch(closeMessage({name: 'answer-time-rule-doctor-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const getPatientsForModal = (id) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerStatistic}/${id}/EncountersStatistics`,
            data: {
                paging:{
                startIndex:0,
                maxItems:0
            },
            filtering:{
                searchString: null,
                fieldsFilter:{
                    status: "planned, in-progress, onleave",
                }
            },
            sorting:[
                ]
            } }).then((response) => {
                dispatch(actions.setPatientsForModal(response.data));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const getDoctorsForModal = ({
        organizationId,
        practitionerId
    }) => {
        return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/_search`,
            data: {
                searchString: '',
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        extended: true,
                        organization: organizationId,
                        approle: 'AppRole/doctor',
                        physicianstatus: "active",
                    }
                },
                sorting: [],
                paging: {
                    startIndex: 0,
                    maxItems: 0
                }
            }}).then((response) => {
                const doctors = [];

                response.data.items.forEach(item => {
                    if (response.data.resources[item.practitioner.reference]) {
                        doctors.push({
                            id: response.data.resources[item.practitioner.reference]?.id,
                            practitionerRoleId: item?.id,
                            display: response.data.resources[item.practitioner.reference]?.name[0]?.text,
                        })
                    }
                });

                const filtered = doctors.filter(item => {
                    return item.id !== practitionerId;
                })

                dispatch(actions.setDoctorsForModal(filtered));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const changePractitioner = ({formData, doctorRoleId, doctorId, handleHide, tableSettings, withDoctorDeletation, history, callback }) => {
        // console.log("formData", formData);
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'replaceDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.changepractitioner}`,
                data: {
                    practitionerRoleId: formData.practitionerRoleId,
                    practitionerId: formData.practitionerId,
                    authorPractitionerRoleId: formData.authorPractitionerRoleId,
                    nonAvailabilityReason: formData.nonAvailabilityReason,
                    newPractitionerRoleId: formData.newPractitionerRoleId,
                    doctorSubstitution: formData.doctorSubstitution,
                }}).then((response) => {
                    const encounters = response.data.encounters;

                    dispatch(getStatisticUpdateStatus({
                        encounters,
                        iteration: 30,
                        handleHide,
                        handleStopLoading: () => dispatch(actions.setLoading({
                            type: 'replaceDoctor',
                            value: false
                        })),
                        onCompleted: () => {
                            withDoctorDeletation && dispatch(deleteDoctor({
                                id: formData.practitionerId,
                            }))
                            callback && callback();
                        },
                        doctorRoleId,
                        doctorId,
                        tableSettings
                    }));
                }).catch((error) => {
                    dispatch(actions.setLoading({
                        type: 'replaceDoctor',
                        value: false
                    }));
                    dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                    dispatch(showMessage('create-channel-error-message'))
                    dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const resumeWork = ({
        formData,
        doctorRoleId,
        doctorId,
        handleHide,
        tableSettings
    }) => {
        // console.log("formData", formData);
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'resumeDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.resumepractitioner}`,
                data: {
                    practitionerRoleId: formData.practitionerRoleId,
                    practitionerId: formData.practitionerId,
                    authorPractitionerRoleId: formData.authorPractitionerRoleId,
                }}).then((response) => {
                    dispatch(actions.setLoading({
                        type: 'resumeDoctor',
                        value: false
                    }));

                    dispatch(getDoctorRole(doctorRoleId));

                }).catch((error) => {
                    dispatch(actions.setLoading({
                        type: 'resumeDoctor',
                        value: false
                    }));
                    dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
                    dispatch(showMessage('create-channel-error-message'))
                    dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const getStatisticUpdateStatus = ({encounters, iteration, handleHide, handleStopLoading, doctorRoleId, doctorId, tableSettings, onCompleted}) => {
        return (dispatch) => {
            const promises = [];

            encounters.forEach(item => {
                promises.push(
                    fetchInstance({
                        method: "GET",
                        url: `${api.statisticUpdateStatus}/${item}`,
                    })
                );
            });

            Promise.all(promises).then((values) => {
                const completed = values.every(elem => elem.data === "Completed");
                if (completed || !iteration) {
                    handleHide && handleHide();
                    handleStopLoading && handleStopLoading();
                    dispatch(getDoctorRole(doctorRoleId));
                    // dispatch(getDoctor(doctorId));
                    if (completed) {
                        onCompleted && onCompleted();
                        dispatch(getPatients({
                            fieldsFilter: tableSettings.fieldsFilter,
                            sorting: tableSettings.sorting,
                            paging: tableSettings.paging,
                            practitionerRoleId: doctorRoleId
                        }));
                    }
                    if (!iteration) {
                        dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                        dispatch(showMessage('check-status-error-message'))
                        dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    }
                } else {
                    setTimeout(() => dispatch(getStatisticUpdateStatus({
                        encounters,
                        iteration: --iteration,
                        handleHide,
                        handleStopLoading,
                        doctorRoleId,
                        doctorId,
                        tableSettings,
                        onCompleted
                    })), 1000);
                }
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const editDoctor = ({formData, editOnlyImage, callback}) => {
        if (formData.practitionerImage) {
            let imageData = new FormData();
            imageData.append("files", formData.practitionerImage);
            return (dispatch) => {
                fetchInstance({
                    method: "POST",
                    url: `${api.imageUpload}`,
                    data: imageData
                }).then((response) => {
                    const formDataCopy = cloneDeep(formData);
                    delete formDataCopy.practitionerImage;
                    delete formDataCopy.practitionerPhoto;
                    formDataCopy.practitionerPhoto = response?.data[0]?.url
                    if(!editOnlyImage){
                        dispatch(createOrUpdateDoctorData({formData: formDataCopy, callback}));
                    }else{
                        dispatch(updateDoctorImage({ formData, imageUrl: response?.data[0]?.url }))
                    }
                }).catch(err => {
                    console.log(err)
                });
            };
        } else if (formData.practitionerImage === null) {
            // console.log("Нужно сбросить");
            return (dispatch) => {
                const formDataCopy = cloneDeep(formData);
                formDataCopy.practitionerPhoto = null;
                !editOnlyImage && dispatch(createOrUpdateDoctorData({formData: formDataCopy, callback}));
            };
        } else {
            // console.log("Обновлять не нужно");
            return (dispatch) => {
                !editOnlyImage && dispatch(createOrUpdateDoctorData({formData, callback}));
            };
        }
    };

    const createOrUpdateDoctorData = ({formData, callback}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'createOrUpdateDoctor',
                value: true
            }));
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/createorupdatedoctor`,
                data: {
                    practitionerInfo: {
                        practitionerId: formData.practitionerId,
                        fio: formData.practitionerName,
                        gender: formData.practitionerGender,
                        isActive: true,
                        photoUrl: formData.practitionerPhoto
                    },
                    practitionerRoleInfo: {
                        specialtyCode: formData?.practitionerProfile?.code,
                        specialtyDisplay: formData?.practitionerProfile?.display,
                        practitionerCode: formData?.practitionerCode,
                        availableStartTime: formData?.availableStartTime,
                        availableEndTime: formData?.availableEndTime,
                        practitionerRoleId: formData?.practitionerRoleId,
                        statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                        statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                        isActive: true,
                        organizationId: formData.organizationId,
                        notifyWithEmail: true,
                        contactEmail: formData.practitionerEmail,
                        phone: formData.practitionerPhone,
                        // loginEmail: formData?.loginEmail
                    },
                    appRole: 'doctor',
                    answeringTimeRuleInfo: {
                        id: formData.answerTimingRule.id,
                        timeoutMinutes: formData.answerTimingRule.timeoutMinutes.value,
                        derivedFrom: null,
                        exceptNonWorkingTime: formData.answerTimingRule.exceptNonWorkingTime,
                        exceptNonWorkingDates: formData.answerTimingRule.exceptNonWorkingDates,
                    }
                }
            }).then((response) => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                formData.practitionerRoleId && dispatch(getDoctorRole(formData.practitionerRoleId));
                callback && callback();

                dispatch(registerMessage({
                    name: 'create-update-doctor-message',
                    type: 'primary',
                    title: 'Врач успешно обновлен',
                    closable: true,
                }))
                dispatch(showMessage('create-update-doctor-message'))
                dispatch(closeMessage({name: 'create-update-doctor-message', delay: systemMessageDelay}))
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'createOrUpdateDoctor',
                    value: false
                }));

                dispatch(registerMessage({
                    name: 'create-update-doctor-error-message',
                    type: 'red',
                    title: 'Не удалось обновить врача',
                    closable: true,
                }))
                dispatch(showMessage('create-update-doctor-error-message'))
                dispatch(closeMessage({name: 'create-update-doctor-error-message', delay: systemMessageDelay}))

                console.log(err)
            });
        }
    }

    const updateDoctorImage = ({ formData, imageUrl }) => dispatch => {
        fetchInstance({
            method: "PUT",
            url: `${api.practitioner}/${formData?.practitionerId}/SetImage`,
            data: {
                imageId: imageUrl
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'edit-doctor-message', type: 'primary', title: 'Данные обновлены', closable: true}))
            dispatch(showMessage('edit-doctor-message'))
            dispatch(closeMessage({name: 'edit-doctor-message', delay: systemMessageDelay}))
            dispatch(getDoctorRole(formData.practitionerRoleId));
        }).catch(err => {
            console.log(err)
        });
    }

    const archiveDoctor = ({item, doctorRoleId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: api.practitionerRole,
            data: item
        }).then((response) => {
                dispatch(getDoctorRole(doctorRoleId));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const restoreDoctor = ({doctorRoleId}) => {
        return (dispatch) => {
        fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}/${doctorRoleId}/RestoreFromArchive`
            }).then((response) => {
                dispatch(getDoctorRole(doctorRoleId));
                // dispatch(getDoctor(doctorId));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const resetPassword = (email, userType) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.resetPassword}/${email}`,
            params: {
                usertype: userType
            }
        }).then((response) => {
            dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
            dispatch(showMessage('send-email-message'))
            dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
        }).catch((error) => {
            dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
            dispatch(showMessage('reset-password-error-message'));
            dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
        })
        };
    };

    const deleteDoctor = ({id, callback}) => {
        return (dispatch) => {
        fetchInstance({
            method: "DELETE",
            url: `${api.practitionerRole}/${id}`,
        }).then((response) => {
                callback && callback();
                dispatch(registerMessage({name: 'delete-doctor-message', type: 'primary', title: 'Доктор удален', closable: true}))
                dispatch(showMessage('delete-doctor-message'))
                dispatch(closeMessage({name: 'delete-doctor-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err);
                dispatch(registerMessage({name: 'delete-doctor-message', type: 'primary', title: (err?.response?.data && (typeof err?.response?.data === 'string')) ? err?.response?.data : 'Ошибка запроса', closable: true}))
                dispatch(showMessage('delete-doctor-message'))
                dispatch(closeMessage({name: 'delete-doctor-message', delay: systemMessageDelay}))
            });
        };
    };

    const sendActivationNotification = id => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.activationNotification}/${id}`
            }).then((response) => {
                dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('activation-notification-message'))
                dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('activation-notification-error-message'))
                dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
            });
        };
    };

    const getAdminLogin = ({roleId}) => {
        return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
            }).then((response) => {
                const login = get(response, 'data.value');
                login && dispatch(actions.setAdminLogin(login));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    return { getDoctorRole, getPatients, getDoctorStatistics, getAnswerTimingRule, editAnswerTimingRule, getPatientsForModal, getDoctorsForModal, changePractitioner, resumeWork, getStatisticUpdateStatus, editDoctor, archiveDoctor, restoreDoctor, resetPassword, deleteDoctor, sendActivationNotification, getAdminLogin, updateDoctorImage, createOrUpdateDoctorData, getPractitionerDataForModal }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        doctorActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
