import React, { useState } from 'react';
import styled, {css} from 'styled-components';
import {renderColor} from "utils/renderColor";
import {authLogout, changeRole} from "reducers/app";
import {useDispatch, useSelector} from "react-redux";
import {useModal} from "components/Modal/useModal";
import DoctorProfile from "./DoctorProfile";
import DoctorProfileHeader from "./DoctorProfileHeader";
import DoctorProfileFooter from "./DoctorProfileFooter";
import AppModal from "./AppModal";
import DisableNotificationsModal from "modals/DisableNotificationsModal";
import DisableNotificationsModalFooter
    from "modals/DisableNotificationsModal/DisableNotificationsModalFooter";
import rules from 'assets/files/Правила работы с сервисом.docx';    
import steps_onco from 'assets/files/steps-onco.pdf';
import steps_preg from 'assets/files/steps-preg.pdf';
import { selectRole, setNotificationEnabled, selectType, selectBlockLogoutButton } from 'reducers/doctor';
import { find, get } from 'lodash';
import { useHistory } from 'react-router-dom';

const DoctorMenu = ({handleHide}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const role = useSelector(selectRole);
    const type = useSelector(selectType);
    const blockLogout = useSelector(selectBlockLogoutButton);

    const [notificationEnabled] = useState(find(get(role, 'extension'), {url: "http://miramedix.ru/fhir/StructureDefinition/notify-with-email"})?.valueBoolean);

    const handleLogout = () => {
        dispatch(authLogout());
    }

    const handleChangeRole = () => {
        dispatch(changeRole());
    }

    const handleEnableNotification = () => {
       role && dispatch(setNotificationEnabled({enabled: true, roleId: role?.id}))
    }

    const handleDisableNotification = () => {
        role && dispatch(setNotificationEnabled({enabled: false, roleId: role?.id}))
     }

    const [showModal] = useModal({component: DoctorProfile, header: DoctorProfileHeader, footer: DoctorProfileFooter, name: 'doctor-profile', props: {modalWidth: '560px'}})
    const [showDisableNotificationsModal] = useModal({
        component: DisableNotificationsModal,
        header: 'Отключить уведомления на эл. почту?',
        footer: DisableNotificationsModalFooter,
        name: 'disable-notifications-modal',
        props: {
            modalWidth: '560px',
            onAccept: handleDisableNotification
        }
    });
    const [showAppModal] = useModal({
        component: AppModal, 
        header: 'Скачать мобильное приложение', 
        // footer: DoctorProfileFooter, 
        name: 'app-modal', 
        props: {
            modalWidth: '448px'
        }
    });

    const enableNotificationAction = {
        id: 1,
        label: 'Включить уведомления на эл. почту',
        type: 'primary',
        action: () => {handleHide(); handleEnableNotification()},
    }

    const disableNotificationAction = {
        id: 1,
        label: 'Отключить уведомления на эл. почту',
        type: 'primary',
        action: () => {handleHide(); showDisableNotificationsModal()},
    }
    
    const list = [
        {
            id: 0,
            label: 'Профиль',
            type: 'primary',
            action: () => {handleHide(); showModal()},
        },
        notificationEnabled ? disableNotificationAction : enableNotificationAction,
        {
            id: 2,
            label: 'Правила работы с сервисом',
            type: 'primary',
            action: () => {window.open(rules, '_blank')},
        },
        {
            id: 3,
            label: 'Шаги для подключения',
            type: 'primary',
            bordered: true,
            action: () => {window.open(type === 'gynecology' ? steps_preg : steps_onco, '_blank')},
        },
        {
            id: 6,
            label: 'Скачать мобильное приложение',
            type: 'primary',
            action: () => {handleHide(); showAppModal()},
        },
        {
            id: 7,
            label: 'Поддержка',
            type: 'primary',
            bordered: true,
            action: () => {handleHide(); history.push('/support')},
        },
        {
            id: 4,
            label: 'Сменить профиль',
            type: 'primary',
            disabled: blockLogout,
            action: handleChangeRole,
        },
        {
            id: 5,
            label: 'Выйти',
            type: 'red',
            disabled: blockLogout,
            action: handleLogout,
        },
    ]

    return (
        list && list.map(item => (
            <Item key={item.id} color={item.type} bordered={item.bordered} disabled={item?.disabled} onClick={(e) => !item?.disabled && item.action(e)}>
                {item.label}
            </Item>
        ))
    );
};

const Item = styled.div`
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
    padding: 6px 12px;
    text-align: center;
    ${({theme, color}) => theme.mixins.robotoRegular({color: renderColor(theme, color)})};

    ${({bordered}) => bordered && css`
        border-bottom: 1px solid ${({theme}) => theme.colors.black12};
        padding-bottom: 14px;
        margin-bottom: 8px;
        line-height: 19px;
        box-shadow: inset 0px -8px 0px #fff;
  `}
  
  :hover {
    background-color: ${({theme, disabled}) => !disabled ? theme.colors.black04 : 'unset'};
  }
`

export default DoctorMenu;
