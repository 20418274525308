import {createSlice} from "@reduxjs/toolkit";
import { api } from "consts/api";
import { fetchInstance, setAuthToken } from "wrappers/axios";
import SecureLS from "secure-ls";
import { hide } from "reducers/anchorPopup/anchorPopupSlice";
import {UserActions} from "reducers/user";
import {get} from "lodash";
import {getImageUrl} from "utils";
import { getCurrentUserManagerRoles, getCurrentUserDoctorRoles, getParentOrganization } from "reducers/user";
import {registerMessage, showMessage, hideMessage, unregisterMessage, closeMessage} from "reducers/systemMessages/systemMessagesSlice";
import { systemMessageDelay } from "consts";

const ls = new SecureLS();

export const appSlice = createSlice({
  name: "app",
  initialState: {
    isAuth: null,
    // isConsent: false,
    isConsent: true,
    userType: null,
    role: null,
    userId: null,
    access_token: "",
    refresh_token: "",
    menuIsOpen: true,
    adaptiveMenuIsOpen: false,
    adaptiveMenuOn: false,
    invitation: {},
    defaultImagesUrls: {
      filial: null,
      user: null
    },
    loading: {
      token: false,
      maintenance: false
    },
    maintenance: null,
  },
  reducers: {
    setTokens: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setConsent: (state, action) => {
      state.isConsent = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    toggleMenu: (state) => {
      state.menuIsOpen = !state.menuIsOpen
    },
    toggleAdaptiveMenu: (state, action) => {
        state.adaptiveMenuIsOpen = action.payload
    },
    switchAdaptiveMenuOn: (state, action) => {
        state.adaptiveMenuOn = action.payload
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setInvitationDetails: (state, action) => {
      state.invitation = {
        ...action.payload
      }
    },
    setDefaultImagesUrls: (state, action) => {
      state.defaultImagesUrls[action.payload.type] = action.payload.url;
    },
    setMaintenanceState: (state, action) => {
      state.maintenance = action.payload;
    },
    setIsOnMaintenance: (state, action) => {
      state.maintenance.isOnMaintenance = action.payload;
    },
    setLoading: (state, action) => {
      const { type, value } = action.payload;
      state.loading[type] = value;
    },
  },
});

export const selectUserId = state => state.app.userId;
export const selectInvitation = state => state.app.invitation;
export const selectDefaultImagesUrls = state => state.app.defaultImagesUrls;
export const selectLoading = state => state.app.loading;
export const selectMaintenanceState = state => state.app.maintenance;
export const AppActions = appSlice.actions;

export default appSlice.reducer;

export const authLogin = (values, savePassword, userType) => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: api.authLogin,
      data: values,
      // {
      //     username: 'doctor@test.ru',
      //     username: 'test@test.ru',
      //     password: 'qwe!23Qwe',
      //     0z4e097k@freeml.net
      //     qwe
      //     https://alfapandora.mgfoms.ru/#/auth на пандоре укл 16416128
      //     SIZL
      //     75pegD3Nic5b4nBmBcsu
      // },
  }).then((response) => {
      const {access_token, refresh_token} = response.data;
      dispatch(setTokenFunc({access_token, refresh_token, savePassword, userType}));
    }).catch((error) => {
      dispatch(registerMessage({name: 'login-error-message', type: 'red', title: 'Ошибка', text: 'Неверный логин или пароль', closable: true}))
      dispatch(showMessage('login-error-message'));
      dispatch(closeMessage({name: 'login-error-message', delay: systemMessageDelay}))
    });
  };
};

export const checkToken = () => {
  return (dispatch) => {
    const access_token = ls.get("access_token");
    // const access_token = "access_token";
    const refresh_token = ls.get("refresh_token");
    const userType = ls.get("userType");

    if (access_token) {
      setAuthToken(access_token);
      dispatch(AppActions.setTokens({access_token, refresh_token}));
      dispatch(AppActions.setAuth(true));
      dispatch(AppActions.setUserType(userType));
      if (userType === "manager") {
        dispatch(getCurrentUserManagerRoles());
      } else if (userType === "doctor") {
          dispatch(getCurrentUserDoctorRoles());
      }
    } else {
      dispatch(AppActions.setAuth(false));
      dispatch(AppActions.setUserType(null));
    }
  }
};

export const authLogout = () => {
  return (dispatch) => {
    ls.remove("access_token");
    ls.remove("refresh_token");
    ls.remove("userType");
    ls.remove("role");
    ls.remove('userId');
    ls.remove('organizationId');
    ls.remove('practitionerId');
    dispatch(AppActions.setTokens({access_token: '', refresh_token: ''}));
    dispatch(AppActions.setAuth(false));
    dispatch(AppActions.setRole(false));
    // dispatch(AppActions.setUserType(null));
    dispatch(AppActions.setUserId(null));
    dispatch(UserActions.setOrganizationId(null));
    dispatch(UserActions.setPractitionerId(null));
    dispatch(hide());
  };
};

export const setRoleFunc = ({role, practitionerId, userId, organizationId, organizationProfileCode, organizationProfileDisplay, organizationType, organizationPartOf }) => {
  return (dispatch) => {
    ls.set('role', role);
    ls.set('userId', userId);
    ls.set('organizationId', organizationId);
    ls.set('organizationProfileCode', organizationProfileCode);
    ls.set('organizationProfileDisplay', organizationProfileDisplay);
    ls.set('practitionerId', practitionerId);
    ls.set('organizationType', organizationType);
    ls.set('organizationPartOf', organizationPartOf);
    dispatch(AppActions.setRole(role));
    dispatch(AppActions.setUserId(userId));
    dispatch(UserActions.setOrganizationId(organizationId || undefined));
    dispatch(UserActions.setOrganizationType(organizationType));
    dispatch(UserActions.setOrganizationProfileCode(organizationProfileCode));
    dispatch(UserActions.setOrganizationProfileDisplay(organizationProfileDisplay));
    dispatch(UserActions.setOrganizationId(organizationId));
    dispatch(UserActions.setPractitionerId(practitionerId));
    // console.log(organizationType, organizationPartOf);
    if (organizationPartOf) dispatch(getParentOrganization({ id: organizationPartOf }))
  }
};

export const checkRole = () => {
  return (dispatch) => {
    const role = ls.get("role");
    const userId = ls.get('userId');
    const organizationId = ls.get('organizationId');
    const organizationProfileCode = ls.get('organizationProfileCode');
    const organizationProfileDisplay = ls.get('organizationProfileDisplay');
    const practitionerId = ls.get('practitionerId');
    const organizationType = ls.get('organizationType');
    const organizationPartOf = ls.get('organizationPartOf');

    if (role) {
      dispatch(AppActions.setRole(role));
      dispatch(AppActions.setUserId(userId));
      dispatch(UserActions.setOrganizationId(organizationId || undefined));
      dispatch(UserActions.setOrganizationType(organizationType));
      dispatch(UserActions.setOrganizationProfileCode(organizationProfileCode));
      dispatch(UserActions.setOrganizationProfileDisplay(organizationProfileDisplay));
      dispatch(UserActions.setPractitionerId(practitionerId));
      if (organizationPartOf) dispatch(getParentOrganization({ id: organizationPartOf }))
    } else {
      dispatch(AppActions.setRole(false));
      dispatch(AppActions.setUserId(null));
      dispatch(UserActions.setOrganizationId(null));
      dispatch(UserActions.setOrganizationType(null));
      dispatch(UserActions.setOrganizationProfileCode(null));
      dispatch(UserActions.setOrganizationProfileDisplay(null));
      dispatch(UserActions.setPractitionerId(null));
      dispatch(UserActions.setParent({ type: 'clinic', organizationData: null }));
      dispatch(UserActions.setParent({ type: 'organization', organizationData: null }));
    }
  }
};

export const changeRole = () => {
  return (dispatch) => {
    ls.remove("role");
    ls.remove('userId');
    ls.remove('organizationId');
    ls.remove('organizationProfileCode');
    ls.remove('organizationProfileDisplay');
    ls.remove('organizationType');
    ls.remove('organizationPartOf');
    ls.remove('practitionerId');
    dispatch(AppActions.setRole(false));
    dispatch(AppActions.setUserId(null));
    dispatch(UserActions.setOrganizationId(null));
    dispatch(UserActions.setOrganizationType(null));
    dispatch(UserActions.setOrganizationProfileCode(null));
    dispatch(UserActions.setOrganizationProfileDisplay(null));
    dispatch(UserActions.setPractitionerId(null));
    dispatch(UserActions.setParent({ type: 'clinic', organizationData: null }));
    dispatch(UserActions.setParent({ type: 'organization', organizationData: null }));
    dispatch(hide());
  };
};

export const resetPassword = (email, usertype) => {
  return (dispatch) => {
    fetchInstance({
      method: "GET",
      url: `${api.resetPassword}/${email}`,
      params: {
        usertype: usertype,
      },
  }).then((response) => {
        dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
        dispatch(showMessage('send-email-message'))
        dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
  }).catch((error) => {
        dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
        dispatch(showMessage('reset-password-error-message'));
        dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
  })
  };
};

export const changePassword = ({password, username, changePasswordToken, savePassword = true, userType, callback}) => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: api.changePassword,
      data: {
        username,
        password,
        changePasswordToken
      },
  }).then((response) => {
    console.log("userType", userType);
    const {access_token, refresh_token} = response.data;
    dispatch(getName(changePasswordToken));
    dispatch(setTokenFunc({access_token, refresh_token, savePassword, userType}));
    callback(userType);
  }).catch((error) => {
  })
  };
};

export const resetPasswordLoddenIn = (data) => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: api.setNewPassword,
      data,
  }).then((response) => {
      ls.set("access_token", response.data.access_token);
      ls.set("refresh_token", response.data.refresh_token);
      dispatch(AppActions.setTokens({access_token: response.data.access_token, refresh_token: response.data.refresh_token}));
      dispatch(registerMessage({name: 'password-reset-success-message', type: 'primary', title: 'Изменение пароля', text: 'Пароль успешно изменён', closable: true}))
      dispatch(showMessage('password-reset-success-message'))
      dispatch(closeMessage({name: 'password-reset-success-message', delay: systemMessageDelay}))
    }).catch((error) => {
      dispatch(registerMessage({name: 'password-reset-error-message', type: 'red', title: 'Ошибка', text: 'Неверно введён текущий пароль', closable: true}))
      dispatch(showMessage('password-reset-error-message'))
      dispatch(closeMessage({name: 'password-reset-error-message', delay: systemMessageDelay}))
    })
  };
};

// export const axiosMiddlewareResponse = ({ dispatch }) => {
//     return (next) => (action) => {
//       fetchInstance.interceptors.response.use(
//         (response) => {
//           if (response.data.error) return Promise.reject(response.data.error);
//           return response;
//         },
//         (error) => {
//           if (get(error.response, "status") === 401 || get(error.response, "status") === 403) {
//             dispatch(authLogout());
//           }
//           if (get(error.response, "status") === undefined || get(error.response, "status") === 503) {
//             if (!axios.isCancel(error)) dispatch(settingsActions.setIsOnMaintenance(true));
//           }
//           return Promise.reject(error);
//         }
//       );
//       return next(action);
//     }
// };

export const uploadImageToServer = (files) => {
    let fileData = new FormData();
    files.forEach(item => {
      fileData.append("files", item.file);
    })

    return new Promise((resolve, reject) =>  {
      fetchInstance({
          method: "POST",
          url: `${api.imageUpload}`,
          data: fileData
      }).then((response) => {
          const result = response.data;
          resolve(result);
      }).catch((error) =>{
          reject(error);
      });
    });
};

export const getName = (token) => {
  return (dispatch) => {
    fetchInstance({
        method: "GET",
        url: `${api.getname}/${token}`,
    }).then((response) => {
        dispatch(AppActions.setInvitationDetails(response.data));
    }).catch((error) => {
      console.log("error", error);
    });
  };
};

export const acceptInvite = ({inviteToken, username, password = "", savePassword = true, userType, callback}) => {
  return (dispatch) => {
    fetchInstance({
        method: "POST",
        url: `${api.acceptinvite}`,
        data: {
          inviteToken,
          username,
          password,
          acceptEULA: true
        }
    }).then((response) => {
      console.log("userType", userType);
      const {access_token, refresh_token} = response.data;
      dispatch(setTokenFunc({access_token, refresh_token, savePassword, userType}));
      callback(userType);
    }).catch((error) => {
      console.log("error", error);
    });
  };
};

export const setTokenFunc = ({access_token, refresh_token, savePassword, userType}) => {
  return (dispatch) => {
    setAuthToken(access_token);
    dispatch(AppActions.setTokens({access_token, refresh_token}));
    if (savePassword) {
        ls.set("access_token", access_token);
        ls.set("refresh_token", refresh_token);
        ls.set("userType", userType);
        dispatch(AppActions.setTokens({access_token, refresh_token}));
    } else {
        ls.set("access_token", access_token);
        dispatch(AppActions.setTokens({access_token, refresh_token: null}));
    }
    dispatch(AppActions.setAuth(true));
    dispatch(AppActions.setUserType(userType));
    dispatch(hideMessage('login-error-message'))
    dispatch(unregisterMessage('login-error-message'))
  };
};

export const getPractitionerRoleById = ({id, callback}) => {
  return (dispatch) => {
      fetchInstance({
          method: "GET",
          url: `${api.practitionerRole}/${id}`,
      }).then((response) => {
          callback(response.data);
      }).catch(err => {
        console.log(err)
    });
  };
};

export const getPractitionerById = ({id, callback}) => {
  return (dispatch) => {
      fetchInstance({
          method: "GET",
          url: `${api.practitioner}/${id}`,
      }).then((response) => {
          callback(response.data);
      }).catch(err => {
        console.log(err)
    });
  };
};


export const requestExportStatistics = ({
  fieldsFilter,
  sorting,
  token,
  setExportLoader,
  searchString
}) => {
  return (dispatch) => {
      fetchInstance({
      method: "POST",
      url: `${api.exportStatistic}/Start`,
      data: {
          paging: {
              startIndex: 0,
              maxItems: 0,
              totalItems: 0
          },
          filtering: {
            searchString,
            fieldsFilter: {
              ids: get(fieldsFilter, "ids", ''),
              partof: get(fieldsFilter, "partof", ''),
              status: get(fieldsFilter, "status", ''),
              trimester: get(fieldsFilter, "trimester", ''),
              startDate: get(fieldsFilter, "startDate", ''),
              endDate: get(fieldsFilter, "endDate", ''),
              conditionCode: get(fieldsFilter, "conditionCode", ''),
            }
          },
          sorting: [
            {
              direction: sorting[0].direction,
              propertyName: sorting[0].propertyName
            }
          ]
        }
      }).then((response) => {
          setExportLoader(true)
          // dispatch(StatisticsActions.setExportLoader(true));
          dispatch(checkExportStatus(response.data.id, token, setExportLoader));
      }).catch(err => {
          console.log(err)
      });
  };
};

export const checkExportStatus = (id, token, setExportLoader) => {
  return (dispatch) => {
      fetchInstance({
      method: "GET",
      url: `${api.exportStatistic}/Status/${id}`,
      }).then((response) => {
          console.log("response.data", response.data);
          if (response.data === "Started") {
              setTimeout(() => dispatch(checkExportStatus(id, token, setExportLoader)), 1000);
          } else {
              dispatch(downloadStatistics(id, token, setExportLoader));
          }
      }).catch(err => {
          console.log(err)
      });
  };
};

export const downloadStatistics = (id, token, setExportLoader) => {
  return (dispatch) => {
      fetchInstance({
      method: "GET",
      url: `${api.exportStatistic}/File/${id}`,
      }).then((response) => {
          setExportLoader(false);
          // dispatch(StatisticsActions.setExportLoader(false));
          window.open(getImageUrl(response.data.url, token), '_blank')
      }).catch(err => {
          console.log(err)
      });
  };
};

export const getProfileToken = ({
  practitionerRoleId,
  userType
}) => {
  return (dispatch) => {
      dispatch(AppActions.setLoading({
        type: 'token',
        value: true
      }));
      fetchInstance({
        method: "GET",
        url: `${api.profiletoken}?practitionerRoleId=${practitionerRoleId}`,
      }).then((response) => {
        dispatch(setTokenFunc({access_token: response.data.access_token, savePassword: true, userType}));
        dispatch(AppActions.setLoading({
          type: 'token',
          value: false
        }));
      }).catch(err => {
          console.log(err);
          dispatch(AppActions.setLoading({
            type: 'token',
            value: false
          }));
      });
  };
};

export const confirmPersonalDataUsage = ({value, token, callback}) => {
  return (dispatch) => {
      fetchInstance({
      method: "POST",
      url: `${api.confirmPersonalDataUsage}`,
      data: {
        inviteToken: token,
        isAccepted: value
      }
      }).then((response) => {
          callback(true);
      }).catch(err => {
          callback(false);
          console.log(err)
      });
  };
};

export const getDefaultImage = (type) => {
  return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.imageUpload}/DefaultImage/${type}`,
        responseType: 'blob'
      }).then((response) => {
        dispatch(AppActions.setDefaultImagesUrls({
          type,
          url: URL.createObjectURL(response.data)
        }));
      }).catch(err => {
          console.log(err)
      });
  };
};

export const getMaintenanceState = () => {
  return (dispatch) => {
      dispatch(AppActions.setLoading({
        type: 'maintenance',
        value: true
      }));
      fetchInstance({
        method: "GET",
        url: `${api.superAdmin}/GetOnMaintenanceState`,
      }).then((response) => {
          dispatch(AppActions.setMaintenanceState(response.data));
          dispatch(AppActions.setLoading({
            type: 'maintenance',
            value: false
          }));
      }).catch((error) => {
        dispatch(AppActions.setLoading({
          type: 'maintenance',
          value: false
        }));
      });
  };
};