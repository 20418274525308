import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {get, cloneDeep, find} from "lodash";
import {responseTime, systemMessageDelay} from "consts";
import {closeMessage, registerMessage, showMessage} from "reducers/systemMessages/systemMessagesSlice";

const initialState = {
    data: [],
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            // date: "2020-01-01",
        },
        sorting: [
            {
                propertyName: "stampCreated",
                direction: 0,
            }
        ],
        paging: {
            startIndex: 0,
            maxItems: 25
        },
    },
    loading: {
        // fullPage: true,
        data: true,
        currentPractitioner: true,
    },
    totalItems: null,
    currentPractitioner: null,
};

const reducers = {
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    setData: (state, action) => {
        state.data = action.payload;
    },
    setCurrentPractitioner: (state, action) => {
        state.currentPractitioner = action.payload;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload.value;
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload.propertyName;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setTotalItems: (state, action) => {
        state.totalItems = action.payload
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.active.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectData: state => get(state, statePath)?.data,
    selectCurrentPractitioner: state => get(state, statePath)?.currentPractitioner,
    selectLoading: state => get(state, statePath)?.loading,
    selectTotalItems: state => get(state, statePath)?.totalItems
})


export const getThunks = (actions) => {

    const getData = ({tableSettings, practitionerRoleId}) => {

        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.mailing}/GetPractitionerRoleEmails/${practitionerRoleId}`,
            data: {
                paging: tableSettings?.paging,
                filtering:{
                    searchString: tableSettings?.searchString,
                    fieldsFilter: tableSettings?.fieldsFilter,
                },
                sorting: [...tableSettings?.sorting],
            }
        }).then((response) => {
                dispatch(actions.setData(get(response, 'data.value')));
                dispatch(actions.setTotalItems(get(response?.data, 'clientQuery.paging.totalItems', 1)));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const sendMessage = ({text, subject, recipientPractitionerRoleId, senderPractitionerRoleId, hideModal, tableSettings}) => {
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.practitionerRole}/fomsnotification`,
                data: {
                    messageText: text,
                    subject,
                    recipientPractitionerRoleId: recipientPractitionerRoleId,
                    senderPractitionerRoleId: senderPractitionerRoleId
                }
            }).then((response) => {
                dispatch(hideModal);
                tableSettings && dispatch(getData({
                    tableSettings,
                    practitionerRoleId: recipientPractitionerRoleId
                }))
                dispatch(registerMessage({name: 'send-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'send-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('send-message'))
                dispatch(closeMessage({name: 'send-message', delay: systemMessageDelay}))
            });
        };
    };

    const getCurrentPractitioner = ({ practitionerRoleId }) => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.practitionerRole}`,
                params: {
                    fg: `id::${practitionerRoleId}:x:_include::practitioner`
                }
            }).then((response) => {
                const item = find(get(response, 'data.items'), { id: practitionerRoleId });
                const practitioner = get(response, `data.resources.${item?.practitioner?.reference}`);

                dispatch(actions.setCurrentPractitioner({
                    id: practitioner?.id,
                    practitionerRoleId: item?.id,
                    name: practitioner?.name[0]?.text,
                    email: find(item?.telecom, {system: "email"})?.value,
                }))

            }).catch(err => {
                console.log(err)
            });
        };
    };

    const resendMessage = ({ messageId, tableSettings, practitionerRoleId }) => {

        return (dispatch) => {

            fetchInstance({
                method: "POST",
                url: `${api.mailing}/Resend/${messageId}`,
            }).then((response) => {
                tableSettings && practitionerRoleId && dispatch(getData({ tableSettings, practitionerRoleId }))
                dispatch(registerMessage({name: 'resend-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
                dispatch(showMessage('resend-message'))
                dispatch(closeMessage({name: 'resend-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'resend-error-message', type: 'red', title: 'Не удалось отправить сообщение', closable: true}))
                dispatch(showMessage('resend-error-message'))
                dispatch(closeMessage({name: 'resend-error-message', delay: systemMessageDelay}))
            });
        };
    };

    const deleteMessage = ({ messageId, tableSettings, practitionerRoleId }) => {
        return (dispatch) => {

            fetchInstance({
                method: "DELETE",
                url: `${api.mailing}/Delete/${messageId}`,
            }).then((response) => {
                tableSettings && practitionerRoleId && dispatch(getData({ tableSettings, practitionerRoleId }))
                dispatch(registerMessage({name: 'resend-message', type: 'primary', title: 'Сообщение успешно удалено', closable: true}))
                dispatch(showMessage('resend-message'))
                dispatch(closeMessage({name: 'resend-message', delay: systemMessageDelay}))
            }).catch(err => {
                console.log(err)
                dispatch(registerMessage({name: 'resend-error-message', type: 'red', title: 'Не удалось удалить сообщение', closable: true}))
                dispatch(showMessage('resend-error-message'))
                dispatch(closeMessage({name: 'resend-error-message', delay: systemMessageDelay}))
            });
        };
    };

    return { getData, sendMessage, getCurrentPractitioner, resendMessage, deleteMessage }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        mailingActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };
