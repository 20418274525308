import React from 'react';
import {Wrapper} from 'styled/Wrapper';
import BottomBarMenu from 'components/BottomBarMenu';
import {useModal} from "components/Modal/useModal";
import DiagnosisCancelEventModal from "../../../../modals/СancelEventModal/DiagnosisCancelEventModal";
import PatientCancelEventModal from "../../../../modals/СancelEventModal/PatientCancelEventModal";
import CancelEventModalFooter from "../../../../modals/СancelEventModal/CancelEventModalFooter";
import PersonIcon from "components/Icons/PersonIcon";
import ReceiptIcon from "components/Icons/ReceiptIcon";


const CancelMenuMobile = (props) => {

    const {handleOnAcceptCancelEventMedical, handleOnAcceptCancelEventPatient, task} = props.actions;

    const [showDiagnosisCancelEventModal] = useModal({
        component: DiagnosisCancelEventModal,
        name: 'cancel-doctor-event-modal',
        header: 'Отмена мероприятия',
        mode: 'mobile-bottom-bar'
    });

    const [showPatientCancelEventModal] = useModal({
        component: PatientCancelEventModal,
        name: 'cancel-patient-event-modal',
        header: 'Отменить мероприятие?'
    });

    const actions = [
        {
            id: 0,
            label: 'По показаниям',
            icon: PersonIcon,
            iconSize: 20,
            action: () => showDiagnosisCancelEventModal({
                modalProps: {
                    modalWidth: '560px',
                    onAccept: handleOnAcceptCancelEventMedical,
                    task
                }
            }),
        },
        {
            id: 0,
            label: 'Отказ пациента',
            icon: ReceiptIcon,
            iconSize: 20,
            action: () => showPatientCancelEventModal(
                {
                    modalFooter: CancelEventModalFooter,
                    modalProps: {
                        modalWidth: '392px',
                        onAccept: handleOnAcceptCancelEventPatient,
                        task
                    }
                }
            ),
        },
    ]


    return <Wrapper>
        <BottomBarMenu {...props} actions={actions}/>
    </Wrapper>;
};

CancelMenuMobile.propTypes = {};

export default CancelMenuMobile;
