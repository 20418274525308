import { useRef } from "react"

export const  useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = (val) => {
        if (val === true) {
            htmlElRef.current &&  htmlElRef.current.focus()
        }
        else {
            htmlElRef.current && htmlElRef.current.blur()
        }
    }

    return [ htmlElRef, setFocus ]
}