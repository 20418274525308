import React from 'react';
import {Wrapper} from "styled/Wrapper";
import SecondaryButton from "components/Button/SecondaryButton";
import Button from "components/Button";
import Group from "components/Group";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";

const CancelEventModalFooter = ({hideModal, onAccept, task}) => {
    return (
        <Wrapper direction={'row'} justify={'flex-end'}>
            <CustomView condition={isDesktop || isTablet}>
                <Group gap={8}>
                    <Group.Item>
                        <Button
                            buttonType={'red'}
                            onClick={() => {
                                onAccept && onAccept(task);
                                hideModal && hideModal();
                            }}
                            label={'Подтвердить'} />
                    </Group.Item>
                    <Group.Item>
                        <SecondaryButton onClick={hideModal} label={'Отмена'} />
                    </Group.Item>
                </Group>
            </CustomView>
            <MobileOnlyView>
                <Group gap={8}>
                    <Group.Item>
                        <SecondaryButton onClick={hideModal} label={'Отмена'} />
                    </Group.Item>
                    <Group.Item>
                        <Button
                            buttonType={'redOutline'}
                            onClick={() => {
                                onAccept && onAccept(task);
                                hideModal && hideModal();
                            }}
                            label={'Подтвердить'} />
                    </Group.Item>
                </Group>
            </MobileOnlyView>
        </Wrapper>
    );
};

CancelEventModalFooter.propTypes = {

};

export default CancelEventModalFooter;
