import React, {Suspense, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import Header from "modules/Header";
import styled, {css} from "styled-components";
import Menu from "components/Menu";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "utils/debounce";
import {AppActions} from "reducers/app";
import PerfectScrollbarWrapper from "components/PerfectScrollbarWrapper";
import { Route, Switch } from 'react-router-dom';
import { routes } from 'consts/routes';
import Loader from 'components/Loader';

const PageBase = ({/*title, */children, selectors, /*backAction, header, search,*/ menu}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.app.menuIsOpen)
    const adaptiveIsOpen = useSelector(state => state.app.adaptiveMenuIsOpen)
    const adaptiveMenuOn = useSelector(state => state.app.adaptiveMenuOn)
    const role = useSelector(state => state.app.role);

    const handleAdaptiveSize = () => {
        if(window.screen.width < 1366) {
            dispatch(AppActions.toggleAdaptiveMenu(true))
        } else {
            dispatch(AppActions.toggleAdaptiveMenu(false))
        }
    }

    const handleOverlayClick = () => {
        dispatch(AppActions.switchAdaptiveMenuOn(false))
    }

    useEffect(() => {

        const debouncedHandleResize = debounce(() => {
            handleAdaptiveSize()
        }, 50)

        handleAdaptiveSize()

        window.addEventListener('resize', debouncedHandleResize)
        return () => window.removeEventListener('resize', debouncedHandleResize)
    })

    const renderHeaderRoutes = (routes) => {
        return Object.values(routes).map(({path, header, headerProps, search, searchProps, name, back}) => {
            const HeaderCopmonent = header; 
            const SearchCopmonent = search; 
            return <Route
                exact={true}
                path={path}
                render={(props) => (
                    <Header {...props} title={name} header={header ? () => <HeaderCopmonent {...headerProps} /> : undefined} search={search ? () => <SearchCopmonent {...searchProps} /> : undefined} selectors={selectors} backAction={back} isOpen={!isOpen}
                        adaptiveIsOpen={adaptiveIsOpen} adaptiveMenuOn={adaptiveMenuOn}/>
                )}
            />
        });
    }

    return (
        <Wrapper fullsize>
            <Suspense fallback={<Loader/>}>
                <Switch>
                    {renderHeaderRoutes([...Object.values(routes.private[role]), ...Object.values(routes.private.globalSearch)], ...Object.values(routes.private.general))}
                </Switch>
            </Suspense>
            {/* <Header title={title} header={header} search={search} selectors={selectors} backAction={backAction} isOpen={!isOpen}
                    adaptiveIsOpen={adaptiveIsOpen} adaptiveMenuOn={adaptiveMenuOn}/> */}
            <Body>
                <Menu items={menu} isOpen={!isOpen} adaptiveIsOpen={adaptiveIsOpen} adaptiveMenuOn={adaptiveMenuOn} />
                <Content isOpen={!isOpen} adaptiveIsOpen={adaptiveIsOpen}>
                    <PerfectScrollbarWrapper id={"main-column-scrollbar-container"} ignoringHandleScroll={true}>
                        {children}
                    </PerfectScrollbarWrapper>
                </Content>
            </Body>
            <Overlay adaptiveMenuOn={adaptiveMenuOn} onClick={handleOverlayClick} />
        </Wrapper>
    );
};

const Overlay = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: transparent;
  z-index: 9;

  ${({adaptiveMenuOn}) => adaptiveMenuOn && css`
      display: block;
    pointer-events: all;
  `};
`

const Body = styled.div`
  display: flex;
  justify-content: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  flex: 1 1 auto;
  padding-left: 256px;
  transition: padding-left ${({theme}) => theme.animations.speed} ${({theme}) => theme.animations.curve};
  width: 100%;
  
  ${({isOpen}) => isOpen && css`
    padding-left: 72px;
  `};

  ${({adaptiveIsOpen}) => adaptiveIsOpen && css`
    padding-left: 72px;
  `};
`

PageBase.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    selectors: PropTypes.bool,
    backAction: PropTypes.bool,
    menu: PropTypes.object.isRequired,
};

export default PageBase;
