import { createSlice } from '@reduxjs/toolkit';

export const modalCarouselSlice = createSlice({
    name: 'modalCarousel',
    initialState: [],
    reducers: {
        show: (state, action) => {
            return state.map(item => item.name === action.payload ? {...item, isOpen: true} : {...item, isOpen: false})
        },
        hide: (state, action) => {
            return state.map(item => item.name === action.payload ? {...item, isOpen: false} : {...item, isOpen: false})
        },
        registerCarouselModal: ((state, action) => {
            return [
                    ...state,
                    {
                        name: action.payload.name,
                        isOpen: false,
                        component: action.payload.component,
                        modalProps: {...action.payload.data, ...action.payload.props, modalWidth: action.payload.props?.modalWidth || '672px'},
                    }
                ]
        }),
        unregisterCarouselModal: ((state, action) => {
            return state.filter(item => item.name !== action.payload)
        })
    },
});

export const { show, hide, registerCarouselModal, unregisterCarouselModal } = modalCarouselSlice.actions;

export default modalCarouselSlice.reducer;
