import {lazy} from "react";
import StatisticsIcon from "components/Icons/StatisticsIcon";
import MedBuildingIcon from "components/Icons/MedBuildingIcon";
import PersonIcon from "components/Icons/PersonIcon";
import HomeIcon from "components/Icons/HomeIcon";
import UsersIcon from "components/Icons/UsersIcon";
import DoctorIcon from "components/Icons/DoctorIcon";
import MegafonIcon from "components/Icons/MegafonIcon";
import TabletIcon from "components/Icons/TabletIcon";
// import NewspaperIcon from "components/Icons/NewspaperIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import EventIcon from "components/Icons/EventIcon";
import organizationsReducer from 'reducers/organizations';
import channelReducer from "reducers/channel";
import doctorsReducer from 'reducers/doctors';
import doctorReducer from 'reducers/doctorManager.js';
import accountChannelsReducer from 'reducers/account_channels';
import accountReducer from "reducers/account";
import accountsReducer from "reducers/accounts";
import mailingReducer from "reducers/mailing";
import channelsReducer from "reducers/channels";
import statisticsReducer from "reducers/statistics";
import filialsReducer from 'reducers/filials';
import filialReducer from 'reducers/filial';
import filialBranchReducer from "reducers/filialBranch";
import branchesReducer from "reducers/branches";
import branchReducer from "reducers/branch";
import mailingListReducer from "reducers/mailingList";
import mailReducer from "reducers/mail";
import administratorsReducer from "reducers/administrators";
import eventLogsReducer from "reducers/eventLogs";

import { organizationType } from "consts";
import { tableNames } from "consts/tableNames";
import MailIcon from "components/Icons/MailIcon";
import NewspaperIcon from "components/Icons/NewspaperIcon";

const superAdminAccountReducer = accountReducer.build('account', 'superAdmin.account');
const superAdminAccountsReducer = accountsReducer.build('accounts', 'superAdmin.accounts', {
    getAccountData: accountReducer.build('account', 'superAdmin.account').getAccountData,
    getPractitionerRole: accountChannelsReducer.build('superAdminAccountChannels', 'superAdmin.accountChannels').getPractitionerRole
});
const superAdminAccountChannelsReducer = accountChannelsReducer.build('superAdminAccountChannels', 'superAdmin.accountChannels', {
    getAccounts: accountsReducer.build('accounts', 'superAdmin.accounts').getAccounts,
});

const globalAccountReducer = accountReducer.build('account', 'superAdmin.account');

const organizationAdminFilialsReducer = filialsReducer.build('organizationAdminFilials', 'organizationAdmin.filials');
const organizationAdminFilialReducer = filialReducer.build('organizationAdminFilial', 'organizationAdmin.filial', {
    getOrganizationsList: organizationAdminFilialsReducer?.getOrganizationsList,
});

const StatisticsPage = lazy(() => import('pages/StatisticsPage'));
const StatisticsHeader = lazy(() => import('pages/StatisticsPage/StatisticsHeader'));
const MoExecutiveStatisticsPage = lazy(() => import('pages/organization_admin/StatisticsPage'));
const MoExecutiveStatisticsHeader = lazy(() => import('pages/organization_admin/StatisticsPage/StatisticsHeader'));
const MoAdministratorStatisticsPage = lazy(() => import('pages/registrator/StatisticsPage'));
const MoAdministratorStatisticsHeader = lazy(() => import('pages/registrator/StatisticsPage/StatisticsHeader'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const ChannelsPage = lazy(() => import('pages/ChannelsPage'));
const ChannelsHeader = lazy(() => import('pages/ChannelsPage/ChannelsHeader'));
const ChannelPage = lazy(() => import('pages/ChannelsPage/ChannelPage'));
const ChannelHeader = lazy(() => import('pages/ChannelsPage/ChannelPage/ChannelHeader'));
const MoAdministratorChannelsPage = lazy(() => import('pages/registrator/ChannelsPage'));
const MoAdministratorChannelsHeader = lazy(() => import('pages/registrator/ChannelsPage/ChannelsHeader'));
const MoAdministratorChannelPage = lazy(() => import('pages/registrator/ChannelsPage/ChannelPage'));
const MoAdministratorChannelHeader = lazy(() => import('pages/registrator/ChannelsPage/ChannelPage/ChannelHeader'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const InvitePage = lazy(() => import('pages/InvitePage'));
const TechPage = lazy(() => import('pages/TechPage'));
const OrganizationsHeader = lazy(() => import('pages/OrganizationsPage/OrganizationsHeader'));
const OrganizationsPage = lazy(() => import('pages/OrganizationsPage'));
const ManagersPage = lazy(() => import('pages/fund_admin/ManagersPage'));
const OrganizationPage = lazy(() => import('pages/OrganizationsPage/OrganizationPage'));
const RolesPage = lazy(() => import('pages/RolesPage'));
const ManagersHeader = lazy(() => import('pages/fund_admin/ManagersPage/ManagersHeader'));
const ManagerPage = lazy(() => import('pages/fund_admin/ManagersPage/ManagerPage'));
const MainPage = lazy(() => import('pages/organization_admin/MainPage'));
const MainPageHeader = lazy(() => import('pages/organization_admin/MainPage/MainPageHeader'));
const MoAdministratorMainPage = lazy(() => import('pages/registrator/MainPage'));
const MoAdministratorMainPageHeader = lazy(() => import('pages/registrator/MainPage/MainPageHeader'));
const DoctorMainPage = lazy(() => import('pages/doctor/MainPage'));
const DoctorMainPageHeader = lazy(() => import('pages/doctor/MainPage/RightColumnHeader'));
const BranchesPage = lazy(() => import('pages/BranchesPage'));
const BranchesHeader = lazy(() => import('pages/BranchesPage/BranchesHeader'));
const BranchPage = lazy(() => import('pages/BranchesPage/BranchPage'));
const BranchHeader = lazy(() => import('pages/BranchesPage/BranchPage/BranchHeader'));
const OrganizationHeader = lazy(() => import('pages/OrganizationsPage/OrganizationPage/OrganizationHeader'));
const ManagerHeader = lazy(() => import('pages/fund_admin/ManagersPage/ManagerPage/ManagerHeader'));
const FilialsPage = lazy(() => import('pages/FilialsPage'));
const FilialsHeader = lazy(() => import('pages/FilialsPage/FilialsHeader'));
const FilialPage = lazy(() => import('pages/FilialsPage/FilialPage'));
const FilialHeader = lazy(() => import('pages/FilialsPage/FilialPage/FilialHeader'));
const FilialBranchPage = lazy(() => import('pages/FilialsPage/FilialPage/FilialBranchPage'));
const FilialBranchHeader = lazy(() => import('pages/FilialsPage/FilialPage/FilialBranchPage/FilialBranchHeader'));
const DoctorsPage = lazy(() => import('pages/DoctorsPage'));
const DoctorsHeader = lazy(() => import('pages/DoctorsPage/DoctorsHeader'));
const DoctorPage = lazy(() => import('pages/DoctorsPage/DoctorPage'));
const DoctorHeader = lazy(() => import('pages/DoctorsPage/DoctorPage/DoctorHeader'));
const MoAdministratorBranchPage = lazy(() => import('pages/registrator/MainPage/BranchPage'));
const MoAdministratorBranchHeader = lazy(() => import('pages/registrator/MainPage/BranchPage/BranchPageHeader'));
const AccountsPage = lazy(() => import('pages/AccountsPage'));
const AccountsHeader = lazy(() => import('pages/AccountsPage/AccountsHeader'));
const ProtocolsPage = lazy(() => import('pages/super_admin/ProtocolsPage'));
const ProtocolsHeader = lazy(() => import('pages/super_admin/ProtocolsPage/ProtocolsHeader'));
const ProtocolPage = lazy(() => import('pages/super_admin/ProtocolsPage/ProtocolPage'));
const ProtocolHeader = lazy(() => import('pages/super_admin/ProtocolsPage/ProtocolPage/ProtocolHeader'));
const EventsPage = lazy(() => import('pages/super_admin/EventsPage'));
const EventsHeader = lazy(() => import('pages/super_admin/EventsPage/EventsHeader'));
const EventsLogsPage = lazy(() => import('pages/super_admin/EventsLogsPage'));
const EventsLogsHeader = lazy(() => import('pages/super_admin/EventsLogsPage/EventsLogsHeader'));
const SettingsPage = lazy(() => import('pages/super_admin/SettingsPage'));
const SettingsHeader = lazy(() => import('pages/super_admin/SettingsPage/SettingsHeader'));
const AccountPageDoctor = lazy(() => import('pages/AccountsPage/AccountPageDoctor'));
const AccountHeaderDoctor = lazy(() => import('pages/AccountsPage/AccountPageDoctor/AccountHeaderDoctor'));
const AccountPage = lazy(() => import('pages/AccountsPage/AccountPage'));
const AccountHeader = lazy(() => import('pages/AccountsPage/AccountPage/AccountHeader'));
const MobileAppPage = lazy(() => import('pages/MobileAppPage'));
const PrivacyPage = lazy(() => import('pages/PrivacyPage'));
const DoctorRigthSide = lazy(() => import('components/DoctorRigthSide'));
const CreateTaskMobile = lazy(() => import('components/DoctorRigthSide/Protocol/CreateTask/CreateTaskMobile'))
const SearchTaskMobile = lazy(() => import('components/DoctorRigthSide/Protocol/TaskSearch/SearchTaskMobile'))
const GlobalSearch = lazy(() => import('components/GlobalSearch'));
const GlobalSearchChannels = lazy(() => import('pages/GlobalSearchChannels'));
const GlobalSearchOrganizations = lazy(() => import('pages/GlobalSearchOrganizations'));
const GlobalSearchEmployees = lazy(() => import('pages/GlobalSearchEmployees'));
const GlobalSearchPageHeader = lazy(() => import('components/GlobalSearchPageHeader'));
const MailingArchivePage = lazy(() => import('pages/MailingArchivePage'));
const MailingArchiveHeader = lazy(() => import('pages/MailingArchivePage/MailingArchiveHeader'));
const MailingListPage = lazy(() => import('pages/MailingListPage'));
const MailingListPageHeader = lazy(() => import('pages/MailingListPage/MailingListPageHeader'));
const MailPage = lazy(() => import('pages/MailingListPage/MailPage'));
const MailPageHeader = lazy(() => import('pages/MailingListPage/MailPage/MailPageHeader'));
const AdministratorsPage = lazy(() => import('pages/AdministratorsPage'));
const AdministratorsHeader = lazy(() => import('pages/AdministratorsPage/AdministratorsHeader'));
const SupportPage = lazy(() => import('pages/SupportPage'));

//Mobile
const HeaderDoctorMobile = lazy(() => import('pages/doctor/mobile/HeaderDoctorMobile/index'))
const TaskDetailsMobile = lazy(() => import('components/DoctorRigthSide/Protocol/mobile/TaskDetailsMobile'))
const PatientListMobile = lazy(() => import('pages/doctor/MainPage/PatientsList/mobile/PatientListMobile'));
const ChatWindowMobile = lazy(() => import('pages/doctor/MainPage/ChatWindow/mobile/ChatWindowMobile'));
const ChatWindowHeaderMobile = lazy(() => import('pages/doctor/MainPage/ChatWindow/mobile/ChatWindowHeaderMobile'));
const PatientInformationMobile = lazy(() => import('pages/doctor/mobile/PatientInformationMobile'));
const PatientInformationHeaderMobile = lazy(() => import('pages/doctor/mobile/PatientInformationMobile/PatientInformationHeaderMobile'));
const PatientListHeaderMobile = lazy(() => import('pages/doctor/MainPage/PatientsList/mobile/PatientListHeaderMobile'));
const DoctorRightSideMobile = lazy(() => import('components/DoctorRigthSide/mobile/DoctorRightSideMobile'));
const DoctorProfileHeaderMobile = lazy(() => import('pages/doctor/MainPage/LeftColumnHeader/DoctorMenu/DoctorProfile/mobile/DoctorProfileHeaderMobile'))
const DoctorProfileMobile = lazy(() => import('pages/doctor/MainPage/LeftColumnHeader/DoctorMenu/DoctorProfile/mobile/DoctorProfileMobile'))
const ResetPassword = lazy(() => import('components/UserMenu/ResetPassword/index'))
const DoctorResetPasswordHeader = lazy(() => import('pages/doctor/MainPage/LeftColumnHeader/DoctorMenu/DoctorProfile/mobile/DoctorResetPasswordHeader'))
const DoctorEditProfileHeader = lazy(() => import('pages/doctor/MainPage/LeftColumnHeader/DoctorMenu/DoctorProfile/mobile/DoctorEditProfileHeader'))
const DoctorEditProfile = lazy(() => import('pages/doctor/MainPage/LeftColumnHeader/DoctorMenu/DoctorProfile/DoctorEditProfile'))
const CollectionMobile = lazy(() => import('pages/doctor/mobile/CollectionMobile'))
const CollectionHeaderMobile = lazy(() => import('pages/doctor/mobile/CollectionMobile/CollectionHeaderMobile'))


const routes = {
    private: {
        root: {
            statistics: {
                id: 0,
                name: 'Статистика',
                path: '/statistics',
                component: StatisticsPage,
                header: StatisticsHeader,
                search: GlobalSearch,
                componentProps:{
                    reducer: statisticsReducer.build('superAdminStatistics', 'superAdmin.statistics'),
                    statisticStartFieldName: 'superAdmin.statistics.data.items',
                    tableFiltersName: tableNames.super_admin_statistics
                },
                headerProps: {
                    reducer: statisticsReducer.build('superAdminStatistics', 'superAdmin.statistics'),
                    globalSearch: true
                },
                icon: StatisticsIcon,
                menu: true,
            },
            channels: {
                id: 1,
                name: 'О враче',
                path: '/statistics/channels-:practitionerRoleId',
                component: ChannelsPage,
                header: ChannelsHeader,
                componentProps:{
                    reducer: channelsReducer.build('superAdminChannels', 'superAdmin.statisticChannels'),
                    tableFiltersName: tableNames.super_admin_channels
                },
                headerProps: {
                    reducer: channelsReducer.build('superAdminChannels', 'superAdmin.statisticChannels'),
                },
                menu: false,
                back: true,
            },
            channel: {
                id: 2,
                name: 'О канале',
                path: '/statistics/channels-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('superAdminChannel', 'superAdmin.statisticChannel'),
                    tableFiltersName: tableNames.super_admin_channels_channel,
                    breadcrumbs: {
                        statistics: {
                            name: 'Статистика',
                        },
                        channels: {
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('superAdminChannel', 'superAdmin.statisticChannel')
                },
                menu: false,
                back: true,
            },
            accounts: {
                id: 3,
                name: 'Учетные записи',
                path: '/accounts',
                component: AccountsPage,
                header: AccountsHeader,
                search: GlobalSearch,
                icon: UsersIcon,
                componentProps:{
                    reducer: superAdminAccountsReducer,
                    accountDoctorReducer: superAdminAccountChannelsReducer,
                    tableFiltersName: {
                        all: tableNames.super_admin_accounts,
                        active: tableNames.super_admin_accounts_active,
                        archived: tableNames.super_admin_accounts_archived
                    }
                },
                headerProps: {
                    reducer: superAdminAccountsReducer,
                    globalSearch: true
                },
                menu: true,
            },
            accountsActive: {
                id: 4,
                name: 'Учетные записи',
                path: '/accounts/active',
                component: AccountsPage,
                header: AccountsHeader,
                icon: UsersIcon,
                componentProps:{
                    reducer: superAdminAccountsReducer,
                    accountDoctorReducer: superAdminAccountChannelsReducer,
                    tableFiltersName: {
                        all: tableNames.super_admin_accounts,
                        active: tableNames.super_admin_accounts_active,
                        archived: tableNames.super_admin_accounts_archived
                    }
                },
                headerProps: {
                    reducer: superAdminAccountsReducer
                },
                menu: false,
            },
            accountsArchive: {
                id: 5,
                name: 'Учетные записи',
                path: '/accounts/archived',
                component: AccountsPage,
                header: AccountsHeader,
                componentProps:{
                    reducer: superAdminAccountsReducer,
                    accountDoctorReducer: superAdminAccountChannelsReducer,
                    tableFiltersName: {
                        all: tableNames.super_admin_accounts,
                        active: tableNames.super_admin_accounts_active,
                        archived: tableNames.super_admin_accounts_archived
                    }
                },
                headerProps: {
                    reducer: superAdminAccountsReducer
                },
                icon: UsersIcon,
            },
            account_fund_admin: {
                id: 6,
                name: 'Сотрудник фонда',
                path: '/accounts/account_fund_admin-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'fund-admin',
                    reducer: superAdminAccountReducer,
                    breadcrumbs: {
                        accounts: {
                            name: 'Учетные записи'
                        }
                    }
                },
                headerProps: {
                    appRole: 'fund-admin',
                    reducer: superAdminAccountReducer
                },
                menu: false,
                back: true,
            },
            account_analyst: {
                id: 7,
                name: 'Аналитик',
                path: '/accounts/account_analyst-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'analyst',
                    reducer: superAdminAccountReducer,
                    breadcrumbs: {
                        accounts: {
                            name: 'Учетные записи'
                        }
                    }
                },
                headerProps: {
                    appRole: 'analyst',
                    reducer: superAdminAccountReducer
                },
                menu: false,
                back: true,
            },
            account_doctor: {
                id: 8,
                name: 'О враче',
                path: '/accounts/account_doctor-:practitionerRoleId',
                component: AccountPageDoctor,
                header: AccountHeaderDoctor,
                componentProps:{
                    reducer: superAdminAccountChannelsReducer,
                    tableFiltersName: tableNames.super_admin_account_channels,
                },
                headerProps: {
                    reducer: superAdminAccountChannelsReducer,
                    accountsReducer: superAdminAccountsReducer,
                    accountReducer: superAdminAccountReducer
                },
                menu: false,
                back: true,
            },
            account_channel: {
                id: 9,
                name: 'О канале',
                path: '/accounts/account_doctor-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('superAdminAccountChannel', 'superAdmin.accountChannel'),
                    tableFiltersName: tableNames.super_admin_account_channels_channel,
                    breadcrumbs: {
                        accounts: {
                            name: 'Учетные записи',
                        },
                        account_doctor:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('superAdminAccountChannel', 'superAdmin.accountChannel')
                },
                menu: false,
                back: true,
            },
            account_registrator: {
                id: 10,
                name: 'Об администраторе МО',
                path: '/accounts/account_registrator-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'registrator',
                    reducer: superAdminAccountReducer,
                    breadcrumbs: {
                        accounts: {
                            name: 'Учетные записи'
                        }
                    }
                },
                headerProps: {
                    appRole: 'registrator',
                    reducer: superAdminAccountReducer,
                },
                menu: false,
                back: true,
            },
            account_organization_admin: {
                id: 11,
                name: 'О руководителе МО',
                path: '/accounts/account_organization_admin-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'organization-admin',
                    reducer: superAdminAccountReducer,
                    breadcrumbs: {
                        accounts: {
                            name: 'Учетные записи'
                        }
                    }
                },
                headerProps: {
                    appRole: 'organization-admin',
                    reducer: superAdminAccountReducer,
                },
                menu: false,
                back: true,
            },
            mailingList: {
                id: 12,
                name: 'Почтовые рассылки',
                path: '/mailing',
                component: MailingListPage,
                header: MailingListPageHeader,
                icon: MailIcon,
                componentProps:{
                    reducer: mailingListReducer.build('superAdminMailingList', 'superAdmin.mailingList'),
                    tableFiltersName: tableNames.super_admin_mailing_list,
                },
                headerProps: {
                    reducer: mailingListReducer.build('superAdminMailingList', 'superAdmin.mailingList')
                },
                menu: true,
            },
            organizations: {
                id: 13,
                name: 'Организации',
                path: '/organizations',
                component: OrganizationsPage,
                header: OrganizationsHeader,
                componentProps: {
                    reducer: organizationsReducer.build('organizations', 'superAdmin.organizations'),
                    tableFiltersName: tableNames.super_admin_organizations,
                },
                headerProps: {
                    reducer: organizationsReducer.build('organizations', 'superAdmin.organizations'),
                },
                icon: MedBuildingIcon,
                menu: true,
            },
            organization: {
                id: 14,
                name: 'Об организации',
                path: '/organizations/organization-:id',
                component: OrganizationPage,
                header: OrganizationHeader,
                componentProps: {
                    reducer: organizationsReducer.build('organizations', 'superAdmin.organizations'),
                    statisticStartFieldName: 'superAdmin.organizations.filials.data.items',
                    tableFiltersName: {
                        fillials: tableNames.super_admin_organization_filials,
                        branches: tableNames.super_admin_organization_branches
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        }
                    }
                },
                headerProps: {
                    reducer: organizationsReducer.build('organizations', 'superAdmin.organizations')
                },
                menu: false,
                back: true,
            },
            filial: {
                id: 15,
                name: 'О филиале',
                path: '/organizations/organization-:id/filial-:id',
                component: FilialPage,
                header: FilialHeader,
                componentProps:{
                    reducer: filialReducer.build('superAdminFilial', 'superAdmin.filial'),
                    tableFiltersName: {
                        filial: tableNames.super_admin_organization_filial,
                        timeExceeded: tableNames.super_admin_organization_filial_timeexceeded,
                        endTable: tableNames.super_admin_organization_filial_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        }
                    }
                },
                headerProps: {
                    reducer: filialReducer.build('superAdminFilial', 'superAdmin.filial'),
                },
                menu: false,
                back: true,
            },
            filialChannel: {
                id: 16,
                name: 'О канале',
                path: '/organizations/organization-:id/filial-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('superAdminFilialChannel', 'superAdmin.filialChannel'),
                    tableFiltersName: tableNames.super_admin_organization_filial_channel,
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial:{
                            name: 'О филиале'
                        },
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('superAdminFilialChannel', 'superAdmin.filialChannel')
                },
                menu: false,
                back: true,
            },
            filialBranch: {
                id: 17,
                name: 'Об отделении',
                path: '/organizations/organization-:id/filial-:id/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps: {
                    reducer: branchReducer.build('superAdminFilialBranch', 'superAdmin.filialBranch'),
                    tableFiltersName: {
                        branch: tableNames.super_admin_organization_filial_branch,
                        timeExceeded: tableNames.super_admin_organization_filial_branch_timeexceeded,
                        endTable: tableNames.super_admin_organization_filial_branch_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial: {
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('superAdminFilialBranch', 'superAdmin.filialBranch'),
                },
                menu: false,
                back: true,
            },
            filialBranchChannel: {
                id: 18,
                name: 'О канале',
                path: '/organizations/organization-:id/filial-:id/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('superAdminFilialBranchChannel', 'superAdmin.filialBranchChannel'),
                    tableFiltersName: tableNames.super_admin_organization_filial_branch_channel,
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial: {
                            name: 'О филиале'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('superAdminFilialBranchChannel', 'superAdmin.filialBranchChannel'),
                },
                menu: false,
                back: true,
            },
            branch: {
                id: 19,
                name: 'Об отделении',
                path: '/organizations/organization-:id/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps:{
                    reducer: branchReducer.build('superAdminBranch', 'superAdmin.branch'),
                    tableFiltersName: {
                        branch: tableNames.super_admin_organization_branches_branch,
                        timeExceeded: tableNames.super_admin_organization_branches_branch_timeexceeded,
                        endTable: tableNames.super_admin_organization_branches_branch_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('superAdminBranch', 'superAdmin.branch'),
                },
                menu: false,
                back: true,
            },
            branchChannel: {
                id: 20,
                name: 'О канале',
                path: '/organizations/organization-:id/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('superAdminBranchChannel', 'superAdmin.branchChannel'),
                    tableFiltersName: tableNames.super_admin_organization_branches_branch_channel,
                    breadcrumbs: {
                        organizations:{
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('superAdminBranchChannel', 'superAdmin.branchChannel'),
                },
                menu: false,
                back: true,
            },
            mailingMail: {
                id: 21,
                name: 'Письмо',
                path: '/mailing/mail-:id',
                component: MailPage,
                header: MailPageHeader,
                componentProps:{
                    reducer: mailReducer.build('superAdminMail', 'superAdmin.mail'),
                    rootPath: '/mailing',
                    breadcrumbs: {
                        mailing: {
                            name: 'Почтовые рассылки'
                        }
                    }
                },
                headerProps: {
                    reducer: mailReducer.build('superAdminMail', 'superAdmin.mail')
                },
                menu: false,
                back: true,
            },
            protocols: {
                id: 22,
                name: 'Протоколы',
                path: '/protocols',
                component: ProtocolsPage,
                header: ProtocolsHeader,
                icon: TabletIcon,
                menu: true,
            },
            protocol: {
                id: 23,
                name: 'О протоколе',
                path: '/protocols/protocol-:id',
                component: ProtocolPage,
                header: ProtocolHeader,
                menu: false,
                back: true,
            },
            events: {
                id: 24,
                name: 'Мероприятия',
                path: '/events',
                component: EventsPage,
                header: EventsHeader,
                icon: EventIcon,
                menu: true,
            },
            eventsLogs: {
                id: 25,
                name: 'Журнал событий',
                path: '/events-logs',
                component: EventsLogsPage,
                header: EventsLogsHeader,
                componentProps:{
                    reducer: eventLogsReducer.build('eventLogs', 'superAdmin.eventLogs'),
                    tableFiltersName: tableNames.super_admin_event_logs
                },
                headerProps: {
                    reducer: eventLogsReducer.build('eventLogs', 'superAdmin.eventLogs'),
                },
                icon: NewspaperIcon,
                menu: true,
            },
            settings: {
                id: 26,
                name: 'Настройки',
                path: '/settings',
                component: SettingsPage,
                header: SettingsHeader,
                icon: SettingsIcon,
                menu: true,
            },
        },
        fund_admin: {
            statistics: {
                id: 0,
                name: 'Статистика',
                path: '/statistics',
                component: StatisticsPage,
                header: StatisticsHeader,
                search: GlobalSearch,
                componentProps:{
                    reducer: statisticsReducer.build('fundAdminStatistics', 'fundAdmin.statistics'),
                    statisticStartFieldName: 'fundAdmin.statistics.data.items',
                    tableFiltersName: tableNames.fund_admin_statistics
                },
                headerProps: {
                    reducer: statisticsReducer.build('fundAdminStatistics', 'fundAdmin.statistics'),
                    globalSearch: true
                },
                icon: StatisticsIcon,
                menu: true,
            },
            channels: {
                id: 1,
                name: 'О враче',
                path: '/statistics/channels-:practitionerRoleId',
                component: ChannelsPage,
                header: ChannelsHeader,
                componentProps:{
                    reducer: channelsReducer.build('fundAdminChannels', 'fundAdmin.channels'),
                    tableFiltersName: tableNames.fund_admin_channels
                },
                headerProps: {
                    reducer: channelsReducer.build('fundAdminChannels', 'fundAdmin.channels'),
                },
                menu: false,
                back: true,
            },
            channel: {
                id: 2,
                name: 'О канале',
                path: '/statistics/channels-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('fundAdminChannel', 'fundAdmin.channel'),
                    tableFiltersName: tableNames.fund_admin_channels_channel,
                    breadcrumbs: {
                        statistics: {
                            name: 'Статистика',
                        },
                        channels:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('fundAdminChannel', 'fundAdmin.channel')
                },
                menu: false,
                back: true,
            },
            organizations: {
                id: 3,
                name: 'Организации',
                path: '/organizations',
                component: OrganizationsPage,
                header: OrganizationsHeader,
                componentProps: {
                    reducer: organizationsReducer.build('organizations', 'fundAdmin.organizations'),
                    tableFiltersName: tableNames.fund_admin_organizations
                },
                headerProps: {
                    reducer: organizationsReducer.build('organizations', 'fundAdmin.organizations'),
                },
                icon: MedBuildingIcon,
                menu: true,
            },
            organization: {
                id: 4,
                name: 'Об организации',
                path: '/organizations/organization-:id',
                component: OrganizationPage,
                header: OrganizationHeader,
                componentProps: {
                    reducer: organizationsReducer.build('organizations', 'fundAdmin.organizations'),
                    statisticStartFieldName: 'fundAdmin.organizations.filials.data.items',
                    tableFiltersName: {
                        fillials: tableNames.fund_admin_organization_filials,
                        branches: tableNames.fund_admin_organization_branches
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        }
                    }
                },
                headerProps: {
                    reducer: organizationsReducer.build('organizations', 'fundAdmin.organizations')
                },
                menu: false,
                back: true,
            },
            filial: {
                id: 14,
                name: 'О филиале',
                path: '/organizations/organization-:id/filial-:id',
                component: FilialPage,
                header: FilialHeader,
                componentProps:{
                    reducer: filialReducer.build('fundAdminFilial', 'fundAdmin.filial'),
                    tableFiltersName: {
                        filial: tableNames.fund_admin_organization_filial,
                        timeExceeded: tableNames.fund_admin_organization_filial_timeexceeded,
                        endTable: tableNames.fund_admin_organization_filial_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        }
                    }
                },
                headerProps: {
                    reducer: filialReducer.build('fundAdminFilial', 'fundAdmin.filial'),
                },
                menu: false,
                back: true,
            },
            filialChannel: {
                id: 15,
                name: 'О канале',
                path: '/organizations/organization-:id/filial-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('fundAdminFilialChannel', 'fundAdmin.filialChannel'),
                    tableFiltersName: tableNames.fund_admin_organization_filial_channel,
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial:{
                            name: 'О филиале'
                        },
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('fundAdminFilialChannel', 'fundAdmin.filialChannel')
                },
                menu: false,
                back: true,
            },
            filialBranch: {
                id: 16,
                name: 'Об отделении',
                path: '/organizations/organization-:id/filial-:id/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps: {
                    reducer: branchReducer.build('fundAdminFilialBranch', 'fundAdmin.filialBranch'),
                    tableFiltersName: {
                        branch: tableNames.fund_admin_organization_filial_branch,
                        timeExceeded: tableNames.fund_admin_organization_filial_branch_timeexceeded,
                        endTable: tableNames.fund_admin_organization_filial_branch_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial: {
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('fundAdminFilialBranch', 'fundAdmin.filialBranch'),
                },
                menu: false,
                back: true,
            },
            filialBranchChannel: {
                id: 17,
                name: 'О канале',
                path: '/organizations/organization-:id/filial-:id/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('fundAdminFilialBranchChannel', 'fundAdmin.filialBranchChannel'),
                    tableFiltersName: tableNames.fund_admin_organization_filial_branch_channel,
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        filial: {
                            name: 'О филиале'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('fundAdminFilialBranchChannel', 'fundAdmin.filialBranchChannel'),
                },
                menu: false,
                back: true,
            },
            branch: {
                id: 18,
                name: 'Об отделении',
                path: '/organizations/organization-:id/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps:{
                    reducer: branchReducer.build('fundAdminBranch', 'fundAdmin.branch'),
                    tableFiltersName: {
                        branch: tableNames.fund_admin_organization_branches_branch,
                        timeExceeded: tableNames.fund_admin_organization_branches_branch_timeexceeded,
                        endTable: tableNames.fund_admin_organization_branches_branch_end
                    },
                    breadcrumbs: {
                        organizations: {
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('fundAdminBranch', 'fundAdmin.branch'),
                },
                menu: false,
                back: true,
            },
            branchChannel: {
                id: 19,
                name: 'О канале',
                path: '/organizations/organization-:id/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('fundAdminBranchChannel', 'fundAdmin.branchChannel'),
                    tableFiltersName: tableNames.fund_admin_organization_branches_branch_channel,
                    breadcrumbs: {
                        organizations:{
                            name: 'Организации'
                        },
                        organization: {
                            name: 'Об организации'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('fundAdminBranchChannel', 'fundAdmin.branchChannel'),
                },
                menu: false,
                back: true,
            },
            managers: {
                id: 5,
                name: 'Руководители',
                path: '/managers',
                component: ManagersPage,
                header: ManagersHeader,
                icon: PersonIcon,
                menu: true,
            },
            managersActive: {
                id: 6,
                name: 'Руководители',
                path: '/managers/active',
                component: ManagersPage,
                header: ManagersHeader,
                icon: PersonIcon,
                menu: false,
            },
            managersArchive: {
                id: 7,
                name: 'Руководители',
                path: '/managers/archived',
                component: ManagersPage,
                header: ManagersHeader,
                icon: PersonIcon,
                menu: false,
            },
            mailingArchive: {
                id: 8,
                name: 'Архив писем',
                path: '/managers/mailing-:roleId',
                component: MailingArchivePage,
                header: MailingArchiveHeader,
                componentProps: {
                    reducer: mailingReducer.build('mailingArchive', 'fundAdmin.mailingArchive'),
                    tableFiltersName: tableNames.fund_admin_managers_mailing_archive,
                    breadcrumbs: {
                        managers: {
                            name: 'Руководители',
                        }
                    }
                },
                headerProps: {
                    reducer: mailingReducer.build('mailingArchive', 'fundAdmin.mailingArchive')
                },
                menu: false,
                back: true,
            },
            manager: {
                id: 9,
                name: 'О руководителе',
                path: '/managers/manager-:id',
                component: ManagerPage,
                header: ManagerHeader,
                menu: false,
                back: true,
            },
            mailingArchiveManager: {
                id: 10,
                name: 'Архив писем',
                path: '/managers/manager-:id/mailing-:roleId',
                component: MailingArchivePage,
                header: MailingArchiveHeader,
                componentProps: {
                    reducer: mailingReducer.build('mailingArchive', 'fundAdmin.mailingArchive'),
                    tableFiltersName: tableNames.fund_admin_managers_mailing_archive,
                    breadcrumbs: {
                        managers: {
                            name: 'Руководители',
                        },
                        manager:{
                            name: 'О руководителе'
                        }
                    }
                },
                headerProps: {
                    reducer: mailingReducer.build('mailingArchive', 'fundAdmin.mailingArchive')
                },
                menu: false,
                back: true,
            },
        },
        organization_admin: {
            main: {
                id: 0,
                name: 'Главная',
                path: '/main',
                component: MainPage,
                header: MainPageHeader,
                icon: HomeIcon,
                menu: true,
            },
            statistics: {
                id: 1,
                name: 'Статистика',
                path: '/statistics',
                component: MoExecutiveStatisticsPage,
                header: MoExecutiveStatisticsHeader,
                search: GlobalSearch,
                headerProps: {
                    globalSearch: true
                },
                icon: StatisticsIcon,
                menu: true,
            },
            statistic_channels: {
                id: 3,
                name: 'О враче',
                path: '/statistics/channels-:practitionerRoleId',
                component: ChannelsPage,
                header: ChannelsHeader,
                componentProps:{
                    reducer: channelsReducer.build('organizationAdminStatisticChannels', 'organizationAdmin.statisticChannels'),
                    tableFiltersName: tableNames.organization_admin_channels
                },
                headerProps: {
                    reducer: channelsReducer.build('organizationAdminStatisticChannels', 'organizationAdmin.statisticChannels'),
                },
                menu: false,
                back: true,
            },
            statistic_channel: {
                id: 4,
                name: 'О канале',
                path: '/statistics/channels-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('organizationAdminStatisticChannel', 'organizationAdmin.statisticChannel'),
                    tableFiltersName: tableNames.organization_admin_channels_channel,
                    breadcrumbs: {
                        statistics: {
                            name: 'Статистика',
                        },
                        channels:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('organizationAdminStatisticChannel', 'organizationAdmin.statisticChannel')
                },
                menu: false,
                back: true,
            },
            filials: {
                id: 4,
                name: 'Филиалы',
                path: '/filials',
                component: FilialsPage,
                header: FilialsHeader,
                componentProps:{
                    reducer: organizationAdminFilialsReducer,
                    tableFiltersName: tableNames.organization_admin_filials
                },
                headerProps: {
                    reducer: organizationAdminFilialsReducer,
                },
                icon: MedBuildingIcon,
                menu: true,
            },
            filial: {
                id: 5,
                name: 'О филиале',
                path: '/filials/filial-:id',
                component: FilialPage,
                header: FilialHeader,
                componentProps:{
                    reducer: organizationAdminFilialReducer,
                    tableFiltersName: {
                        filial: tableNames.organization_admin_filial,
                        timeExceeded: tableNames.organization_admin_filial_timeexceeded,
                        endTable: tableNames.organization_admin_filial_end
                    },
                    breadcrumbs: {
                        filials: {
                            name: 'Филиалы'
                        }
                    }
                },
                headerProps: {
                    reducer: organizationAdminFilialReducer,
                    filialsReducer: organizationAdminFilialsReducer,
                },
                menu: false,
                back: true,
            },
            filialChannel: {
                id: 6,
                name: 'О канале',
                path: '/filials/filial-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('organizationAdminFilialChannel', 'organizationAdmin.filialChannel'),
                    tableFiltersName: tableNames.organization_admin_filial_channel,
                    breadcrumbs: {
                        filials: {
                            name: 'Филиалы',
                        },
                        filial:{
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('organizationAdminFilialChannel', 'organizationAdmin.filialChannel'),
                },
                menu: false,
                back: true,
            },
            branches: {
                id: 7,
                name: 'Отделения',
                path: '/branches',
                component: BranchesPage,
                header: BranchesHeader,
                componentProps:{
                    reducer: branchesReducer.build('organizationAdminBranches', 'organizationAdmin.branches'),
                    tableFiltersName: tableNames.organization_admin_branches
                },
                headerProps: {
                    reducer: branchesReducer.build('organizationAdminBranches', 'organizationAdmin.branches'),
                },
                icon: UsersIcon,
                menu: true,
            },
            branch: {
                id: 8,
                name: 'Об отделении',
                path: '/branches/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps:{
                    reducer: branchReducer.build('organizationAdminBranch', 'organizationAdmin.branch'),
                    tableFiltersName: {
                        branch: tableNames.organization_admin_branches_branch,
                        timeExceeded: tableNames.organization_admin_branches_branch_timeexceeded,
                        endTable: tableNames.organization_admin_branches_branch_end
                    },
                    breadcrumbs: {
                        branches: {
                            name: 'Отделения'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('organizationAdminBranch', 'organizationAdmin.branch'),
                },
                menu: false,
                back: true,
            },
            branchChannel: {
                id: 9,
                name: 'О канале',
                path: '/branches/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('organizationAdminBranchChannel', 'organizationAdmin.branchChannel'),
                    tableFiltersName: tableNames.organization_admin_branch_channel,
                    breadcrumbs: {
                        branches:{
                            name: 'Отделения'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('organizationAdminBranchChannel', 'organizationAdmin.branchChannel'),
                },
                menu: false,
                back: true,
            },
            filialBranch: {
                id: 10,
                name: 'Об отделении',
                path: '/filials/filial-:filialId/branch-:branchId',
                component: FilialBranchPage,
                header: FilialBranchHeader,
                componentProps:{
                    reducer: filialBranchReducer.build('organizationAdminFilialBranch', 'organizationAdmin.filialBranch'),
                    tableFiltersName: {
                        branch: tableNames.organization_admin_filial_branch,
                        timeExceeded: tableNames.organization_admin_filial_branch_timeexceeded,
                        endTable: tableNames.organization_admin_filial_branch_end
                    },
                    rootPath: '/filials',
                    breadcrumbs: {
                        filials: {
                            name: 'Филиалы'
                        },
                        filial: {
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: filialBranchReducer.build('organizationAdminFilialBranch', 'organizationAdmin.filialBranch'),
                },
                menu: false,
                back: true,
            },
            filialBranchChannel: {
                id: 11,
                name: 'О канале',
                path: '/filials/filial-:filialId/branch-:branchId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('organizationAdminFilialBranchChannel', 'organizationAdmin.filialBranchChannel'),
                    tableFiltersName: tableNames.organization_admin_filial_branch_channel,
                    breadcrumbs: {
                        filials: {
                            name: 'Филиалы',
                        },
                        filial:{
                            name: 'О филиале'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('organizationAdminFilialBranchChannel', 'organizationAdmin.filialBranchChannel'),
                },
                menu: false,
                back: true,
            },
            administrators: {
                id: 5,
                name: 'Администраторы',
                path: '/administrators',
                component: AdministratorsPage,
                header: AdministratorsHeader,
                componentProps:{
                    reducer: administratorsReducer.build('organizationAdminAdministrators', 'organizationAdmin.administrators'),
                    tableFiltersName: tableNames.organization_admin_administrators,
                },
                headerProps: {
                    reducer: administratorsReducer.build('organizationAdminAdministrators', 'organizationAdmin.administrators'),
                },
                icon: PersonIcon,
                menu: true,
            },
        },
        registrator: {
            main: {
                id: 0,
                name: 'Главная',
                path: '/main',
                component: MoAdministratorMainPage,
                header: MoAdministratorMainPageHeader,
                icon: HomeIcon,
                menu: true,
            },
            mainChannel: {
                id: 1,
                name: 'О канале',
                path: '/main/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('registratorMainChannel', 'registrator.mainChannel'),
                    tableFiltersName: tableNames.registrator_main_channel,
                    breadcrumbs: {
                        main: {
                            name: 'Главная',
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('registratorMainChannel', 'registrator.mainChannel'),
                },
                menu: false,
                back: true,
            },
            branch: {
                id: 2,
                name: 'Об отделении',
                path: '/main/branch-:branchId',
                component: MoAdministratorBranchPage,
                header: MoAdministratorBranchHeader,
                menu: false,
                back: true,
            },
            branchChannel: {
                id: 3,
                name: 'О канале',
                path: '/main/branch-:branchId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('registratorBranchChannel', 'registrator.branchChannel'),
                    tableFiltersName: tableNames.registrator_branch_channel,
                    breadcrumbs: {
                        main: {
                            name: 'Главная',
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('registratorBranchChannel', 'registrator.branchChannel'),
                },
                menu: false,
                back: true,
            },
            statistics: {
                id: 4,
                name: 'Статистика',
                path: '/statistics',
                component: MoAdministratorStatisticsPage,
                header: MoAdministratorStatisticsHeader,
                search: GlobalSearch,
                headerProps: {
                    globalSearch: true
                },
                icon: StatisticsIcon,
                menu: true,
            },
            statistic_channels: {
                id: 5,
                name: 'О враче',
                path: '/statistics/channels-:practitionerRoleId',
                component: ChannelsPage,
                header: ChannelsHeader,
                componentProps:{
                    reducer: channelsReducer.build('registratorStatisticChannels', 'registrator.statisticChannels'),
                    tableFiltersName: tableNames.registrator_statistics_channels
                },
                headerProps: {
                    reducer: channelsReducer.build('registratorStatisticChannels', 'registrator.statisticChannels'),
                },
                menu: false,
                back: true,
            },
            statistic_channel: {
                id: 6,
                name: 'О канале',
                path: '/statistics/channels-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('registratorStatisticChannel', 'registrator.statisticChannel'),
                    tableFiltersName: tableNames.registrator_statistics_channels_channel,
                    breadcrumbs: {
                        statistics: {
                            name: 'Статистика',
                        },
                        channels:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('registratorStatisticChannel', 'registrator.statisticChannel'),
                },
                menu: false,
                back: true,
            },
            channels: {
                id: 7,
                name: 'Каналы',
                path: '/channels',
                component: MoAdministratorChannelsPage,
                header: MoAdministratorChannelsHeader,
                icon: MegafonIcon,
                menu: true,
                dontUseOn: { // Логический оператор условий - &&
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            channelsArchive: {
                id: 8,
                name: 'Каналы',
                path: '/channels/archived',
                component: MoAdministratorChannelsPage,
                header: MoAdministratorChannelsHeader,
                icon: MegafonIcon,
                dontUseOn: { // Логический оператор условий - &&
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            channel: {
                id: 9,
                name: 'О канале',
                path: '/channels/channel-:encounterId',
                component: MoAdministratorChannelPage,
                header: MoAdministratorChannelHeader,
                componentProps:{
                    breadcrumbs: {
                        channels: {
                            name: 'Каналы',
                        }
                    }
                },
                menu: false,
                back: true,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            doctors: {
                id: 10,
                name: 'Врачи',
                path: '/doctors',
                component: DoctorsPage,
                header: DoctorsHeader,
                componentProps:{
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                    tableFiltersName: {
                        doctorsAll: tableNames.registrator_doctors,
                        doctorsActive: tableNames.registrator_doctors_active,
                        doctorsArchived: tableNames.registrator_doctors_archived,
                    }
                },
                headerProps: {
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                },
                icon: DoctorIcon,
                menu: true,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            doctorsActive: {
                id: 11,
                name: 'Врачи',
                path: '/doctors/active',
                component: DoctorsPage,
                header: DoctorsHeader,
                componentProps:{
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                    tableFiltersName: {
                        doctorsAll: tableNames.registrator_doctors,
                        doctorsActive: tableNames.registrator_doctors_active,
                        doctorsArchived: tableNames.registrator_doctors_archived,
                    }
                },
                headerProps: {
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                },
                icon: DoctorIcon,
                menu: false,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            doctorsArchive: {
                id: 12,
                name: 'Врачи',
                path: '/doctors/archived',
                component: DoctorsPage,
                header: DoctorsHeader,
                componentProps:{
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                    tableFiltersName: {
                        doctorsAll: tableNames.registrator_doctors,
                        doctorsActive: tableNames.registrator_doctors_active,
                        doctorsArchived: tableNames.registrator_doctors_archived,
                    }
                },
                headerProps: {
                    reducer: doctorsReducer.build('doctorsRegistrator', 'registrator.doctors'),
                },
                icon: DoctorIcon,
                menu: false,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            doctor: {
                id: 13,
                name: 'О враче',
                path: '/doctors/doctor-:id',
                component: DoctorPage,
                header: DoctorHeader,
                componentProps:{
                    reducer: doctorReducer.build('doctorRegistrator', 'registrator.doctor'),
                    tableFiltersName: tableNames.registrator_doctors_patients,
                    rootPath: '/doctors',
                    breadcrumbs: {
                        doctors: {
                            name: 'Врачи'
                        }
                    }
                },
                headerProps: {
                    reducer: doctorReducer.build('doctorRegistrator', 'registrator.doctor'),
                },
                menu: false,
                back: true,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
            doctorsChannel: {
                id: 14,
                name: 'О канале',
                path: '/doctors/doctor-:id/channel-:encounterId',
                component: MoAdministratorChannelPage,
                header: MoAdministratorChannelHeader,
                componentProps:{
                    breadcrumbs: {
                        doctors: {
                            name: 'Врачи',
                        },
                        doctor: {
                            name: 'О враче',
                        }
                    }
                },
                menu: false,
                back: true,
                dontUseOn: {
                    organizationType: organizationType.clinic,
                    organizationProfile: 'multiprofile'
                }
            },
        },
        doctor: {
            main: {
                id: 0,
                name: 'Главная',
                path: '/main',
                component: DoctorMainPage,
                header: DoctorMainPageHeader,
                menu: false,
            },
            supportPage: {
                id: 106,
                name: 'Поддержка',
                path: '/support',
                component: SupportPage,
            },
        },
        analyst: {
            statistics: {
                id: 0,
                name: 'Статистика',
                path: '/statistics',
                component: StatisticsPage,
                header: StatisticsHeader,
                search: GlobalSearch,
                componentProps:{
                    reducer: statisticsReducer.build('analystStatistics', 'analyst.statistics'),
                    statisticStartFieldName: 'analyst.statistics.data.items',
                    tableFiltersName: tableNames.analyst_statistics,
                    useCurrentUserOrganizaton: true
                },
                headerProps: {
                    reducer: statisticsReducer.build('analystStatistics', 'analyst.statistics'),
                    globalSearch: true
                },
                icon: StatisticsIcon,
                menu: true,
            },
            channels: {
                id: 1,
                name: 'О враче',
                path: '/statistics/channels-:practitionerRoleId',
                component: ChannelsPage,
                header: ChannelsHeader,
                componentProps:{
                    reducer: channelsReducer.build('analystChannels', 'analyst.channels'),
                    tableFiltersName: tableNames.fund_admin_channels
                },
                headerProps: {
                    reducer: channelsReducer.build('analystChannels', 'analyst.channels'),
                },
                menu: false,
                back: true,
            },
            channel: {
                id: 2,
                name: 'О канале',
                path: '/statistics/channels-:practitionerRoleId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('analystChannel', 'analyst.channel'),
                    tableFiltersName: tableNames.fund_admin_channels_channel,
                    breadcrumbs: {
                        statistics: {
                            name: 'Статистика',
                        },
                        channels:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('analystChannel', 'analyst.channel')
                },
                menu: false,
                back: true,
            },
        },
        mobile_doctor: {
            main: {
                id: 0,
                name: 'Список пациентов',
                path: '/main',
                component: PatientListMobile,
                header: PatientListHeaderMobile,
                menu: false,
            },
            chat: {
                id: 1,
                name: 'Чат с пациентом',
                path: '/chat',
                component: ChatWindowMobile,
                header: ChatWindowHeaderMobile,
                headerProps: {
                    //todo дописать если надо
                },
                menu: false,
            },
            rightSide: {
                id: 2,
                name: 'Правая сторона',
                path: '/right_side',
                component: DoctorRightSideMobile,
                menu: false,
            },
            patientInformation: {
                id: 3,
                name: 'Информация',
                path: '/patient_information',
                component: PatientInformationMobile,
                header: PatientInformationHeaderMobile,
                menu: false,
            },
            profile: {
                id: 4,
                name: 'Профиль',
                path: '/profile',
                component: DoctorProfileMobile,
                header: DoctorProfileHeaderMobile,
                menu: false,
            },
            resetPassword: {
                id: 5,
                name: 'Изменение пароля',
                path: '/reset_password',
                component: ResetPassword,
                header: DoctorResetPasswordHeader,
                menu: false,
            },
            editProfile: {
                id: 6,
                name: 'Редактирование данных',
                path: '/edit_profile',
                component: DoctorEditProfile,
                header: DoctorEditProfileHeader,
                menu: false,
            },
            collection: {
                id: 7,
                name: 'Коллекция',
                path: '/collection',
                component: CollectionMobile,
                header: CollectionHeaderMobile,
                menu: false,
            },
            event: {
                id: 8,
                name: 'Описание мероприятия',
                path: '/task_details-:taskId',
                component: TaskDetailsMobile,
                header: HeaderDoctorMobile,
                headerProps: {
                    center: 'Описание мероприятия',
                    path: '/right_side'
                },
                menu: false,
            },
            searchTask: {
                id: 9,
                name: 'Поиск',
                path: '/search_task',
                component: SearchTaskMobile,
                headerProps: {},
                menu: false,
            },
            createTask: {
                id: 10,
                name: 'Создание мероприятия',
                path: '/create_task-:taskId',
                component: CreateTaskMobile,
                headerProps: {},
                menu: false,
            },
            supportPage: {
                id: 106,
                name: 'Поддержка',
                path: '/support',
                component: SupportPage,
            },
        },
        globalSearch: {
            globalSearchChannels: {
                id: 1,
                path: '/global/channels',
                component: GlobalSearchChannels,
                header: GlobalSearchPageHeader,
                menu: false,
                back: true,
            },
            globalSearchOrganizations: {
                id: 2,
                path: '/global/organizations',
                component: GlobalSearchOrganizations,
                header: GlobalSearchPageHeader,
                menu: false,
                back: true,
            },
            globalSearchEmployees: {
                id: 3,
                path: '/global/employees',
                component: GlobalSearchEmployees,
                header: GlobalSearchPageHeader,
                menu: false,
                back: true,
            },
            globalChannel: {
                id: 4,
                name: 'О канале',
                path: '/global/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('globalChannel', 'globalChannel'),
                    tableFiltersName: tableNames.global_channel,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('globalChannel', 'globalChannel'),
                },
                menu: false,
                back: true,
            },
            globalOrganization: {
                id: 5,
                name: 'Об организации',
                path: '/global/organization-:id',
                component: OrganizationPage,
                header: OrganizationHeader,
                componentProps: {
                    reducer: organizationsReducer.build('globalOrganizations', 'globalOrganizations'),
                    statisticStartFieldName: 'globalOrganizations.filials.data.items',
                    tableFiltersName: {
                        fillials: tableNames.global_organization_filials,
                        branches: tableNames.global_organization_branches
                    },
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    reducer: organizationsReducer.build('globalOrganizations', 'globalOrganizations'),
                    hideCreationChildOrganizations: true
                },
                menu: false,
                back: true,
            },
            globalFilial: {
                id: 6,
                name: 'О филиале',
                path: '/global/filial-:id',
                component: FilialPage,
                header: FilialHeader,
                componentProps:{
                    reducer: filialReducer.build('globalFilial', 'globalFilial'),
                    tableFiltersName: {
                        filial: tableNames.global_filial,
                        timeExceeded: tableNames.global_filial_timeexceeded,
                        endTable: tableNames.global_filial_end
                    },
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    reducer: filialReducer.build('globalFilial', 'globalFilial'),
                    hideCreationChildOrganizations: true
                },
                menu: false,
                back: true,
            },
            globalFilialChannel: {
                id: 7,
                name: 'О канале',
                path: '/global/filial-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('globalFilialChannel', 'globalFilialChannel'),
                    tableFiltersName: tableNames.global_filial_channel,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        },
                        filial:{
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('globalFilialChannel', 'globalFilialChannel'),
                },
                menu: false,
                back: true,
            },
            globalFilialBranch: {
                id: 8,
                name: 'Об отделении',
                path: '/global/filial-:filialId/branch-:branchId',
                component: FilialBranchPage,
                header: FilialBranchHeader,
                componentProps:{
                    reducer: filialBranchReducer.build('globalFilialBranch', 'globalFilialBranch'),
                    tableFiltersName: {
                        branch: tableNames.global_filial_branch,
                        timeExceeded: tableNames.global_filial_branch_timeexceeded,
                        endTable: tableNames.global_filial_branch_end
                    },
                    rootPath: "/global",
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        },
                        filial: {
                            name: 'О филиале'
                        }
                    }
                },
                headerProps: {
                    reducer: filialBranchReducer.build('globalFilialBranch', 'globalFilialBranch'),
                },
                menu: false,
                back: true,
            },
            globalFilialBranchChannel: {
                id: 9,
                name: 'О канале',
                path: '/global/filial-:filialId/branch-:branchId/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('globalFilialBranchChannel', 'globalFilialBranchChannel'),
                    tableFiltersName: tableNames.global_filial_branch_channel,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        },
                        filial:{
                            name: 'О филиале'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('globalFilialBranchChannel', 'globalFilialBranchChannel'),
                },
                menu: false,
                back: true,
            },
            globalBranch: {
                id: 10,
                name: 'Об отделении',
                path: '/global/branch-:id',
                component: BranchPage,
                header: BranchHeader,
                componentProps:{
                    reducer: branchReducer.build('globalBranch', 'globalBranch'),
                    tableFiltersName: {
                        branch: tableNames.global_branch,
                        timeExceeded: tableNames.global_branch_timeexceeded,
                        endTable: tableNames.global_branch_end
                    },
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    reducer: branchReducer.build('globalBranch', 'globalBranch'),
                },
                menu: false,
                back: true,
            },
            globalBranchChannel: {
                id: 11,
                name: 'О канале',
                path: '/global/branch-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('globalBranchChannel', 'globalBranchChannel'),
                    tableFiltersName: tableNames.global_branch_channel,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        },
                        branch: {
                            name: 'Об отделении'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('globalBranchChannel', 'globalBranchChannel'),
                },
                menu: false,
                back: true,
            },
            globalDoctor: {
                id: 12,
                name: 'О враче',
                path: '/global/doctor-:id',
                component: DoctorPage,
                header: DoctorHeader,
                componentProps:{
                    reducer: doctorReducer.build('globalDoctor', 'globalDoctor'),
                    tableFiltersName: tableNames.global_doctor,
                    rootPath: "/global",
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    reducer: doctorReducer.build('globalDoctor', 'globalDoctor'),
                },
                menu: false,
                back: true,
            },
            globalDoctorChannel: {
                id: 13,
                name: 'О канале',
                path: '/global/doctor-:id/channel-:encounterId',
                component: ChannelPage,
                header: ChannelHeader,
                componentProps:{
                    reducer: channelReducer.build('globalDoctorChannel', 'globalDoctorChannel'),
                    tableFiltersName: tableNames.global_doctor_channel,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        },
                        doctor:{
                            name: 'О враче'
                        }
                    }
                },
                headerProps: {
                    reducer: channelReducer.build('globalDoctorChannel', 'globalDoctorChannel'),
                },
                menu: false,
                back: true,
            },
            globalFundAdmin: {
                id: 14,
                name: 'Сотрудник фонда',
                path: '/global/account_fund_admin-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'fund-admin',
                    reducer: globalAccountReducer,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    appRole: 'fund-admin',
                    reducer: globalAccountReducer,
                },
                menu: false,
                back: true,
            },
            globalAnalyst: {
                id: 15,
                name: 'Аналитик',
                path: '/global/account_analyst-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'analyst',
                    reducer: globalAccountReducer,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    appRole: 'analyst',
                    reducer: globalAccountReducer,
                },
                menu: false,
                back: true,
            },
            globalRegistrator: {
                id: 16,
                name: 'Об администраторе МО',
                path: '/global/account_registrator-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'registrator',
                    reducer: globalAccountReducer,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    appRole: 'registrator',
                    reducer: globalAccountReducer,
                },
                menu: false,
                back: true,
            },
            globalOrganizationAdmin: {
                id: 17,
                name: 'О руководителе МО',
                path: '/global/account_organization_admin-:id',
                component: AccountPage,
                header: AccountHeader,
                componentProps:{
                    appRole: 'organization-admin',
                    reducer: globalAccountReducer,
                    breadcrumbs: {
                        global: {
                            name: 'Статистика',
                            path: '/statistics'
                        }
                    }
                },
                headerProps: {
                    appRole: 'organization-admin',
                    reducer: globalAccountReducer,
                },
                menu: false,
                back: true,
            },
        },
        general: {
            techPage: {
                id: 1,
                name: 'Технические работы',
                path: '/service_in_progress',
                component: TechPage,
            },
        }
    },
    public: {
        loginManager: {
            id: 100,
            name: 'Логин',
            path: '/manager',
            component: LoginPage,
        },
        loginDoctor: {
            id: 101,
            name: 'Логин',
            path: '/client',
            component: LoginPage,
        },
        forgotPassword: {
            id: 102,
            name: 'Сброс пароля',
            path: '/forgot',
            component: ForgotPassword,
        },
        newPassword: {
            id: 103,
            name: 'Создание пароля',
            path: '/newpassword',
            component: NewPassword,
        },
        rolesManager: {
            id: 104,
            name: 'Выбор профиля',
            path: '/manager/role',
            component: RolesPage,
        },
        rolesDoctor: {
            id: 105,
            name: 'Выбор профиля',
            path: '/client/role',
            component: RolesPage,
        },
        acceptInvite: {
            id: 106,
            name: 'Активировать инвайт',
            path: '/acceptinvite',
            component: InvitePage,
        },
        techPage: {
            id: 107,
            name: 'Технические работы',
            path: '/service_in_progress',
            component: TechPage,
        },
        mobileAppPage: {
            id: 108,
            name: 'Мобильное приложение врача',
            path: '/mobile-app',
            component: MobileAppPage,
        },
        privacyPage: {
            id: 109,
            name: 'Правила для безопасной работы с Сервисом сопровождения пациентов',
            path: '/privacy',
            component: PrivacyPage,
        },
        supportPage: {
            id: 106,
            name: 'Поддержка',
            path: '/support',
            component: SupportPage,
        },
    },
}

export { routes };

