import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {find, get, isEmpty} from "lodash";
import {closeMessage, registerMessage, showMessage} from "../systemMessages/systemMessagesSlice";
import {systemMessageDelay} from "../../consts";

const initialState = {
    timeExceeded: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
            },
            sorting: [
                {
                    propertyName: "answerDate",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    endOfCounseling: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                daysToEnd: 14,
                // status: "finished, in-progress, cancelled",
                status: "in-progress,planned",
            },
            sorting: [
                {
                    propertyName: "period",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    organization: {
        organizationObj: {},
        organizationRole: {},
        manager: {},
        allOrganizationRoles: [],
        typeFilial: null,
        haveDoctors: null,
    },
    loading: {
        fullPage: true,
        organizationDetails: true,
        timeExceededData: true,
        endOfCounselingData: true
    },
    adminLogin: null
}

export const branchRegistratorSlice = createSlice({
    name: 'branchRegistrator',
    initialState,
    reducers: {
        setOrganizationObj: (state, action) => {
            state.organization.organizationObj = action.payload;
        },
        setOrganizationRole: (state, action) => {
            state.organization.organizationRole = action.payload;
        },
        setManager: (state, action) => {
            state.organization.manager = action.payload;
        },
        setAllOrganizationRoles: (state, action) => {
            state.organization.allOrganizationRoles = action.payload;
        },
        setTypeFilial: (state, action) => {
            state.organization.typeFilial = action.payload
        },
        setPatientsResponseTimeExceededData: (state, action) => {
            state.timeExceeded.data = action.payload
        },
        setPatientsEndOfCounselingPeriodData: (state, action) => {
            state.endOfCounseling.data = action.payload
        },
        setPatientsResponseTimeExceededPage: (state, action) => {
            state.timeExceeded.tableSettings.paging.startIndex = (action.payload - 1) * state.timeExceeded.tableSettings.paging.maxItems
        },
        setPatientsEndOfCounselingPeriodPage: (state, action) => {
            state.endOfCounseling.tableSettings.paging.startIndex = (action.payload - 1) * state.endOfCounseling.tableSettings.paging.maxItems
        },
        setPatientsResponseTimeExceededMaxItems: (state, action) => {
            state.timeExceeded.tableSettings.paging.maxItems = action.payload;
        },
        setPatientsEndOfCounselingPeriodMaxItems: (state, action) => {
            state.endOfCounseling.tableSettings.paging.maxItems = action.payload;
        },
        setSortingTimeExceeded: (state, action) => {
            state.timeExceeded.tableSettings.sorting[0].propertyName = action.payload;
            state.timeExceeded.tableSettings.sorting[0].direction = state.timeExceeded.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setTimeExceededPatientsIdToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.patientId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.patientId;
        },
        setTimeExceededPractitionerRoleIdToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId;
        },
        setTimeExceededAnswerStartDateToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerStartDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerStartDate;
        },
        setTimeExceededAnswerEndDateToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerEndDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerEndDate;
        },
        setSortingEndOfCounseling: (state, action) => {
            state.endOfCounseling.tableSettings.sorting[0].propertyName = action.payload;
            state.endOfCounseling.tableSettings.sorting[0].direction = state.endOfCounseling.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setEndOfCounselingPatientsIdToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.patientId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.patientId;
        },
        setEndOfCounselingPractitionerRoleIdToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId;
        },
        setEndOfCounselingPeriodStartDateToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodStart = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodStart;
        },
        setEndOfCounselingPeriodEndDateToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodEnd = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodEnd;
        },
        setEndOfCounselinStatusesToFilter: (state, action) => {
            state.endOfCounseling.tableSettings.fieldsFilter.status = action.payload;
        },
        setHaveDoctors: (state, action) => {
            state.organization.haveDoctors = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setAdminLogin: (state, action) => {
            state.adminLogin = action.payload;
        },
        resetState: (state, action) => {
            return initialState
        },
    },
});


export const selectOrganizationsForBranchesFilter = state => state.registrator.branch.organization.fieldsFilter;
export const selectFieldsFilter = state => state.registrator.branch.organization.fieldsFilter;
export const selectHaveDoctors = state => state.registrator.branch.organization.haveDoctors;
export const selectAllOrganizationRoles = state => state.registrator.branch.organization.allOrganizationRoles;
export const selectOrganizationObj = state => state.registrator.branch.organization.organizationObj;
export const selectOrganizationManager = state => state.registrator.branch.organization.manager;
export const selectOrganizationRole = state => state.registrator.branch.organization.organizationRole;
export const selectTimeExceededTableSettings = state => state.registrator.branch.timeExceeded.tableSettings;
export const selectEndTableSettings = state => state.registrator.branch.endOfCounseling.tableSettings;
export const selectPatientsResponseTimeExceededData = state => state.registrator.branch.timeExceeded.data;
export const selectPatientsEndOfCounselingPeriodData = state => state.registrator.branch.endOfCounseling.data;
export const selectAdminLogin = state => state.registrator.branch.adminLogin;
export const selectLoading = state => state.registrator.branch.loading;

export const branchRegistratorActions = branchRegistratorSlice.actions;

export const getOrganizationDetails = organizationId => {

    return (dispatch) => {
        dispatch(branchRegistratorActions.setLoading({
            type: 'organizationDetails',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: {
                startIndex: 0,
                maxItems: 0
            },
            filtering: {
                searchString: '',
                fieldsFilter: {
                    id: organizationId,
                    'type:missing': false
                }
            },
            sorting: [],
        }
        }).then((response) => {
            const item = response.data.items.find(item => item.id === organizationId);
            dispatch(branchRegistratorActions.setOrganizationObj(item));
            dispatch(getOrganizationRole(organizationId));
            dispatch(branchRegistratorActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        }).catch(err => {
            dispatch(branchRegistratorActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        });
    };
};

export const getOrganizationRole = id => {
    return (dispatch) => {
        fetchInstance({
          method: "POST",
          url: `${api.practitionerRole}/_search`,
          data: {
              paging: {
                  startIndex: 0,
                  maxItems: 0
              },
              filtering: {
                  searchString: '',
                  fieldsFilter: {
                    organization: id,
                    extended: true,
                  }
              },
              sorting: [],
          }}).then((response) => {
                const adminRole = response.data.items.find(item => {
                    return find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference === "AppRole/registrator";
                })
                const doctors = response.data.items.filter(item => {
                    return find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference === "AppRole/doctor";
                })
                // console.log(doctors);
                const admin = response.data.resources[get(adminRole, "practitioner.reference")];
                dispatch(branchRegistratorActions.setOrganizationRole(adminRole));
                dispatch(branchRegistratorActions.setManager(admin));
                dispatch(branchRegistratorActions.setAllOrganizationRoles(response?.data?.items))
                dispatch(branchRegistratorActions.setHaveDoctors(!isEmpty(doctors)))

          }).catch(err => {
            console.log(err)
        });
      };
}

export const getOrganizationRoles = id => {
    return (dispatch) => {
        fetchInstance({
          method: "POST",
          url: `${api.practitionerRole}/_search`,
          data: {
              paging: {
                  startIndex: 0,
                  maxItems: 0
              },
              filtering: {
                  searchString: '',
                  fieldsFilter: {
                    organization: id,
                    extended: true,
                  }
              },
              sorting: [],
          }}).then((response) => {
              console.log(response.data);
                dispatch(branchRegistratorActions.setAllOrganizationRoles(response.data));
          }).catch(err => {
            console.log(err)
        });
      };
}

export const editBranch = ({formData, organizationId, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.organization}`,
            data: {
                id: formData.organizationId,
                resourceType: "Organization",
                active: true,
                partOf: {
                    reference: `Organization/${formData.organizationPartOf}`,
                },
                identifier: formData.identifier,
                type: [
                    {
                        coding: [
                            {
                                code: "department",
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Подразделение"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.organizationProfile.code,
                                display: formData.organizationProfile.display,
                                system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.organizationName,
                contact: [
                    {
                        purpose: {
                            text: "Телефон для пациентов"
                        },
                        telecom: [
                            {
                                system: "phone",
                                value: formData.organizationPhone,
                                use: "work"
                            }
                        ]
                    }
                ]
            }}).then((response) => {
                dispatch(registerMessage({name: 'edit-branch-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                dispatch(showMessage('edit-branch-message'))
                dispatch(closeMessage({name: 'edit-branch-message', delay: systemMessageDelay}))
                dispatch(createOrUpdateManagerData({formData, organizationId, callback}));
            }).catch(err => {
                console.log(err)
            });
    };
};

const createOrUpdateManagerData = ({formData, organizationId, callback}) => {
    return (dispatch) => {
        dispatch(branchRegistratorActions.setLoading({
            type: 'createOrUpdateManager',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/createorupdateorgmanager`,
            data: {
                practitionerInfo: {
                    practitionerId: formData?.practitionerId,
                    fio: formData?.practitionerName,
                    gender: formData?.practitionerGender,
                    isActive: true,
                    photoUrl: formData?.practitionerPhoto || null,
                },
                practitionerRoleInfo: {
                    positionCode: formData?.practitionerRole,
                    positionDisplay: formData?.practitionerRoleName,
                    practitionerRoleId: formData?.practitionerRoleId,
                    statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                    statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                    isActive: true,
                    organizationId: formData.organizationId,
                    notifyWithEmail: true,
                    contactEmail: formData.practitionerEmail,
                    phone: formData.practitionerPhone,
                    // loginEmail: formData?.loginEmail
                },
                appRole: 'registrator'
            }
        }).then((response) => {
            dispatch(branchRegistratorActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            organizationId && dispatch(getOrganizationDetails(organizationId));
            callback && callback();

        }).catch(err => {
            dispatch(branchRegistratorActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            console.log(err)
        });
    }
}

export const getPatientsResponseTimeExceeded = ({organizationId, tableSettings}) => {
    return (dispatch) => {
        dispatch(branchRegistratorActions.setLoading({
            type: 'timeExceededData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsResponseTimeExceeded`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,
                fieldsFilter: tableSettings.fieldsFilter,
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            dispatch(branchRegistratorActions.setPatientsResponseTimeExceededData(response.data));
            dispatch(branchRegistratorActions.setLoading({
                type: 'timeExceededData',
                value: false
            }));
        }).catch(err => {
            dispatch(branchRegistratorActions.setLoading({
                type: 'timeExceededData',
                value: false
            }));
        });
    };
};

export const getPatientsEndOfCounselingPeriod = ({organizationId, tableSettings}) => {
    return (dispatch) => {
        dispatch(branchRegistratorActions.setLoading({
            type: 'endOfCounselingData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsEndOfCounselingPeriod`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,
                fieldsFilter: tableSettings.fieldsFilter,
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            dispatch(branchRegistratorActions.setPatientsEndOfCounselingPeriodData(response.data));
            dispatch(branchRegistratorActions.setLoading({
                type: 'endOfCounselingData',
                value: false
            }));
        }).catch(err => {
            dispatch(branchRegistratorActions.setLoading({
                type: 'endOfCounselingData',
                value: false
            }));
        });
    };
};

export const sendActivationNotification = id => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.activationNotification}/${id}`
        }).then((response) => {
            dispatch(registerMessage({name: 'activation-notification-message', type: 'primary', title: 'Сообщение успешно отправлено', closable: true}))
            dispatch(showMessage('activation-notification-message'))
            dispatch(closeMessage({name: 'activation-notification-message', delay: systemMessageDelay}));
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'activation-notification-error-message', type: 'red', title: 'Ошибка', text: 'Не удалось отправить сообщение', closable: true}))
            dispatch(showMessage('activation-notification-error-message'))
            dispatch(closeMessage({name: 'activation-notification-error-message', delay: systemMessageDelay}));
        });
    };
};

export const resetPassword = (email, userType) => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.resetPassword}/${email}`,
        params: {
            usertype: userType
        }
    }).then((response) => {
          dispatch(registerMessage({name: 'send-email-message', type: 'primary', title: 'Письмо отправлено', text: 'На ' + email + ' отправлено письмо с инструкцией для создания нового пароля', closable: true}))
          dispatch(showMessage('send-email-message'))
          dispatch(closeMessage({name: 'send-email-message', delay: systemMessageDelay}))
    }).catch((error) => {
          dispatch(registerMessage({name: 'reset-password-error-message', type: 'red', title: 'Ошибка', text: `Не удалось сбросить пароль${error?.response?.data ? `: ${error?.response?.data}` : ''}`, closable: true}))
          dispatch(showMessage('reset-password-error-message'));
          dispatch(closeMessage({name: 'reset-password-error-message', delay: systemMessageDelay}))
    })
    };
};

export const deleteOrganization = ({id, callback}) => {
    return (dispatch) => {
      fetchInstance({
        method: "DELETE",
        url: `${api.organization}/${id}`,
        }).then((response) => {
            callback && callback();
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'delete-organization-error-message', type: 'red', title: 'Не удалось удалить организацию', text: err?.response?.data || 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('delete-organization-error-message'));
            dispatch(closeMessage({name: 'delete-organization-error-message', delay: systemMessageDelay}))
        });
    };
};

export const getAdminLogin = ({roleId}) => {
    return (dispatch) => {
    fetchInstance({
        method: "GET",
        url: `${api.practitionerRole}/${roleId}/GetUserLogin`,
        }).then((response) => {
            const login = get(response, 'data.value');
            login && dispatch(branchRegistratorActions.setAdminLogin(login));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default branchRegistratorSlice.reducer;
