import React, {useContext, useEffect, useState} from 'react'; 
import PropTypes from 'prop-types';
import styled, {useTheme} from 'styled-components'
import ActionIcon from "components/ActionIcon";
import DotsIcon from "components/Icons/DotsIcon";
import {useAnchorPopup} from "components/AnchorPopup/useAnchorPopup";
import Item from "components/FloatingMenu/Item";
import {useModal} from "components/Modal/useModal";
import Icon from "components/Icon";
import {Wrapper} from "styled/Wrapper";
import {rgba} from "polished";
import {CellContext} from "components/Table/TableBody/TableRow/TableCell";
import {useDispatch} from "react-redux";
import {setIndex} from "reducers/table/tableSlice";
import {hide as hideAnchorPopup} from 'reducers/anchorPopup/anchorPopupSlice'
import { isMobileOnly } from 'react-device-detect';

const ActivitiesBox = ({actions, handleButtonStatus, data, icon, size, text, color, height, iconSize, iconColor, iconColorHover, colorHover, borderRadius, options, type, tooltip, minWidth, anchorZIndex, popupPosition, fixedPosition}) => {
    const cont = useContext(CellContext)
    const dispatch = useDispatch()
    const [activeIcon, setActiveIcon] = useState(false)
    // const isAnchorOpen = useSelector(state => state.anchorPopup.visible);
    const [mouseLeaveFloatingMenu, setMouseLeaveFloatingMenu] = useState(true)
    const [mouseLeaveButton, setMouseLeaveButton] = useState(true);
    const [showedFloating, setShowedFloating] = useState(false);

    // useEffect(() => {
    //     if (isAnchorOpen === false) {
    //         setActiveIcon(false);
    //         handleButtonStatus && handleButtonStatus(false);
    //     }
    // }, [isAnchorOpen])

    const handleMouseFloatingMenu = (val) => {
        setMouseLeaveFloatingMenu(val);
        // setActiveIcon(!val)
    }
    const handleMouseButton = (val) => {
        setMouseLeaveButton(val);
        // setActiveIcon(!val)
    }

    useEffect(() => {
        setActiveIcon(!mouseLeaveFloatingMenu || !mouseLeaveButton);
        if (mouseLeaveFloatingMenu === true && mouseLeaveButton === true/* && isAnchorOpen*/) {
            activeIcon && dispatch(hideAnchorPopup());
            handleButtonStatus && handleButtonStatus(false);
            setShowedFloating(false);
        }
    }, [mouseLeaveFloatingMenu, mouseLeaveButton])

    const Activities = ({handleHide, elementPosition}) => {
        const [showModal] = useModal()

        const theme = useTheme()

        const handleItemAction = (e, item) => {
            setActiveIcon(false)
            handleHide()
            if(item.modal) {
                showModal({
                    data,
                    modalComponent: item.modal?.component,
                    modalHeader: item.modal?.modalHeader,
                    modalFooter: item.modal?.modalFooter,
                    modalProps: {
                        ...item.modal?.modalProps,
                    },
                    modalMode: item?.modal?.modalMode,
                })
            }
            if(item.action) {
                item.action(data, e, elementPosition)
            }
            handleMouseFloatingMenu(true);
        }
        return (<>
            <FloatingMenuWrapper minWidth={minWidth} onMouseLeave={() => handleMouseFloatingMenu(true)} onMouseEnter={() => handleMouseFloatingMenu(false)}>
                <FloatingMenuBox minWidth={minWidth} type={type}>
                {actions.map(item => {
                    return <Item item={item} key={'floating-menu-item-' + item.id} color={theme.colors[item.color]} onClick={(e, item) => handleItemAction(e, item)} bordered={item.bordered} type={type} nowrap={item.nowrap} icon={item.icon} iconSize={item.iconSize} />
                })}
                </FloatingMenuBox>
            </FloatingMenuWrapper>
            </>
        )
    };

    const [showAnchorPopup] = useAnchorPopup({component: Activities, props: {...options, type: type, namePopup: 'Activities', anchorZIndex: anchorZIndex, fixedPosition: fixedPosition || false}})

    const handleClick = (e) => {
        // setActiveIcon(true);
        setShowedFloating(!showedFloating);
        handleButtonStatus && handleButtonStatus(true);
        dispatch(setIndex(cont))
        e.preventDefault()
        if(!showedFloating){
            showAnchorPopup(e, { positionType: popupPosition })
        }
        if(activeIcon){
            setActiveIcon(false)
        }
    }

    if (text) {
        return (
            <ButtonLine color={color} height={height} onClick={handleClick}>
                <Icon icon={icon || DotsIcon} size={size || 40} color={color} tooltip={tooltip}/>
                <Wrapper paddings={'0 0 0 16px'}>{text}</Wrapper>
            </ButtonLine>
        )
    }
    else {
        return (
            <ButtonWrapper onMouseLeave={() => handleMouseButton(true)} onMouseEnter={() => handleMouseButton(false)}>
                <ActionIcon
                action={handleClick}
                icon={DotsIcon}
                color={color}
                iconSize={iconSize}
                size={size || '40px'}
                iconColor={iconColor}
                colorHover={colorHover}
                iconColorHover={iconColorHover}
                borderRadius={borderRadius}
                tooltip={tooltip}
                hoverAction={false}
                active={activeIcon}
                />
            </ButtonWrapper>
        )
    }

};

ActivitiesBox.propTypes = {
    actions: PropTypes.array.isRequired,
}

const ButtonWrapper = styled.div`
  position: relative;
`

const FloatingMenuWrapper = styled.div`
  padding: 8px 16px 32px 16px;
`

const FloatingMenuBox = styled.div`
  padding: 8px 0;
  border-radius: ${({theme}) => theme.other.borderRadius};
  min-width: ${({minWidth}) => minWidth && minWidth};
  background-color: ${({theme, type}) => type === 'dark' ? theme.colors.black76 : theme.colors.white100};
  ${({theme}) => theme.mixins.shadowDark8dp()};
  border: 1px solid ${({theme}) => theme.colors.black04};
`

const ButtonLine = styled.div`
    height: ${({height}) => height};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    ${({theme, color}) => theme.mixins.robotoMedium({color: color, size: theme.fonts.sizes.text})};
    
    &:hover {
        background: ${({theme, color}) => rgba(color, 0.14)};
    }
`

export default ActivitiesBox;

