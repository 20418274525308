import React from 'react';
import styled, {keyframes, useTheme} from "styled-components";
import Text from 'components/Text';

const Spinner = ({tableHeaderHeight = 0, scale, text}) => {

    const theme = useTheme();

    return (
        <SpinnerWrapper tableHeaderHeight={tableHeaderHeight}>
            <Loader scale={scale}><Dot /></Loader>
            {text &&
                <TextWrapper>
                    <Text text={text} color={theme.colors.primary} font={'robotoRegular'} tag='h2' />
                </TextWrapper>
            }
        </SpinnerWrapper>
    );
};

const TextWrapper = styled.div`
    display: flex;
`

const SpinnerWrapper = styled.div`
    position: absolute;
    top: ${({tableHeaderHeight}) => tableHeaderHeight > 0 ? 'unset' : '0'};
    bottom: ${({tableHeaderHeight}) => tableHeaderHeight > 0 ? '0' : 'unset'};
    left: 0;
    width: 100%;
    height: ${({tableHeaderHeigh}) => tableHeaderHeigh ? `calc(100% - ${tableHeaderHeigh}px)` : '100%'};    
    background: ${({theme}) => theme.colors.white70};
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const pulse = keyframes`
    0% {
        width: 3px;
        height: 3px;
        opacity: 1;
        box-shadow: inset 0 0 0 0.5px #009DE8;
    }
    30% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        width: 76px;
        height: 76px;
        opacity: 0;
        box-shadow: inset 0 0 0 6px #009DE8;
    }
`
const Dot = styled.div`
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 3px;
    animation: ${pulse} 2s infinite ease-in;
    animation-delay: 1.2s;
`

const Loader = styled.div`
    transform: ${({scale}) => scale ? 'scale(' + scale + ')' : 'scale(1)'};
    border-radius: 100%;
    height: 76px;
    width: 76px;
    position: relative;
    
    &:after, &:before {
        content: "";
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 3px;
        width: 3px;
        animation: ${pulse} 2s infinite ease-in;
        animation-delay: 0s;
    }
    &:before {
        animation-delay: 0.6s;
    }
`

export default Spinner;
