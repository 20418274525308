import React from 'react'; 
import styled from "styled-components";
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const TabsBody = ({list, onChange, name}) => {

    let activeTab = useSelector((state) => {
            for (let tab in state.tabs) {
                if (state.tabs[tab].name === name) {
                    return state.tabs[tab].activeTab;
                }
            }
        }
    );

    const Component = list[activeTab] ? list[activeTab].component : false;

    return (
            <TabBody>
                {Component &&
                    <Component key={`protocol-tab-${activeTab}`} {...list[activeTab].props} onChange={onChange}/>
                }
            </TabBody>
    );
};

const TabBody = styled.div`
    height: 100%;
    width: 100%;
`

TabsBody.propTypes = {
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default TabsBody;
