import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {useTooltip} from "components/Tooltip/useTooltip";

const Avatar = ({fullname, size, onClick, tooltip, image}) => {
    const [showTooltip, hideTooltip] = useTooltip({component: tooltip})

    const stringToHslColor = (str, s, l) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        const h = hash % 360;
        return 'hsl('+h+', '+s+'%, '+l+'%)';
    }

    const getInitials = (string) => {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    return (
        <AvatarWrapper size={size} clickable={!!onClick} onClick={onClick && (e => onClick(e))} onMouseEnter={tooltip && showTooltip} onMouseLeave={tooltip && hideTooltip}>
            <OuterCircle>
                <InnerCircle size={size} color={fullname && stringToHslColor(fullname, 30, 80)}>
                    {image && <img src={image} alt="avatar"/>}
                    {fullname && !image && <Name size={size}>
                        {getInitials(fullname)}
                    </Name>}
                </InnerCircle>
            </OuterCircle>
        </AvatarWrapper>
    );
};

const renderWrapperSize = (size) => {
    switch(size) {
        case 'small':
            return '40px'
        case 'medium':
            return '40px'
        case 'big':
            return '56px'
        default:
            return '40px'
    }
}

const renderSize = (size) => {
    switch(size) {
        case 'small':
            return '32px'
        case 'medium':
            return '40px'
        case 'big':
            return '56px'
        default:
            return '32px'
    }
}

const renderFontSize = (size) => {
    switch(size) {
        case 'small':
            return '14px'
        case 'medium':
            return '14px'
        case 'big':
            return '24px'
        default:
            return '14px'
    }
}

const renderLineHeight = (size) => {
    switch(size) {
        case 'small':
            return '20px'
        case 'medium':
            return '24px'
        case 'big':
            return '36px'
        default:
            return '20px'
    }
}

const InnerCircle = styled.div`
  background-color: ${({color}) => color};
  width: ${({size}) => renderSize(size)};
  height: ${({size}) => renderSize(size)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  
  img {
    object-fit: cover;
    width: 100%;
  }
`

const OuterCircle = styled.div`
  ${({hover}) => hover && css`
    
  `};
`

const Name = styled.div`
  ${({theme, size}) => theme.mixins.robotoMedium({color: theme.colors.white100, size: renderFontSize(size), lineheight: renderLineHeight(size)})};
  width: 100%;
  text-align: center;
`

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({size}) => renderWrapperSize(size)};
  height: ${({size}) => renderWrapperSize(size)};
  cursor: ${({clickable}) => clickable ? 'pointer' : 'initial'};
  
  ${({clickable}) => clickable && css`
      :hover {
          ${OuterCircle} {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: ${({theme}) => theme.colors.primary12};
          };
      }
  `}
`

Avatar.propTypes = {
    fullname: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    onClick: PropTypes.func,
};

export default Avatar;
