import React from 'react';
import PrimaryButton from "components/Button/PrimaryButton";
import SecondaryButton from "components/Button/SecondaryButton";
import Group from "components/Group";
import {Wrapper} from "styled/Wrapper";
import {CustomView, isDesktop, isTablet, MobileOnlyView} from "react-device-detect";

const EnableNotificationsModalFooter = ({hideModal, onAccept}) => {
    return (
        <>
            <CustomView condition={isDesktop || isTablet}>
                <Wrapper direction={'row'} justify={'flex-end'} width={'100%'}>
                    <Group align={'center'}>
                        <Group.Item>
                            <PrimaryButton onClick={() => {
                                onAccept && onAccept();
                                hideModal && hideModal();
                            }} label={'Включить'} />
                        </Group.Item>
                        <Group.Item>
                            <SecondaryButton onClick={() => {hideModal && hideModal()}} label={'Отмена'} />
                        </Group.Item>
                    </Group>
                </Wrapper>
            </CustomView>
            <MobileOnlyView>
                <Wrapper direction={'row'} justify={'flex-end'} width={'100%'}>
                    <Group align={'center'}>
                        <Group.Item>
                            <SecondaryButton onClick={() => {hideModal && hideModal()}} label={'Отмена'} />
                        </Group.Item>
                        <Group.Item>
                            <SecondaryButton onClick={() => {
                                onAccept && onAccept();
                                hideModal && hideModal();
                            }} label={'Включить'} />
                        </Group.Item>
                    </Group>
                </Wrapper>
            </MobileOnlyView>
        </>
    );
};

EnableNotificationsModalFooter.propTypes = {

};

export default EnableNotificationsModalFooter;
