import React, {createRef, useCallback, useEffect, useState} from 'react'; 
import styled, {css} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
// import {debounce} from "utils/debounce";
import {hide} from "reducers/anchorPopup/anchorPopupSlice";
import { isNull } from 'lodash';

const AnchorPopup = () => {

    const ref = createRef();
    const dispatch = useDispatch()

    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const popup = useSelector(state => state.anchorPopup)
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)

    // const [refHeight, setRefHeight] = useState(ref.current ? ref.current.offsetHeight : 0)
    // const [childHeight, setChildHeight] = useState(0)

    // console.log('popup props', popup?.props?.parentPosition)

    // const handleKeyDown = () => {
    //     setTimeout( () => {
    //         setRefHeight(ref?.current?.offsetHeight);
    //     }, 1000)
    // }

    //Использован useCallback, т.к. при прямом использовании в eventListener не отрабатывает removeEventListener
    const hidePopup = useCallback(
      () => {
        handlePopupHide();
      },
      [hide])

    const handlePopupHide = () => {
        dispatch(hide())
    }

    const calculatePositionX = (positionX, ref, positionType) => {
        if(positionType === 'centered') {
            return (window.innerWidth / 2) - (ref?.current?.getBoundingClientRect().width / 2)
        }
        if(positionType === 'left'){
            return positionX - ref?.current?.getBoundingClientRect().width + 50
        }
        if (positionX + ref?.current?.getBoundingClientRect().width > window.innerWidth) {
            return window.innerWidth - ref?.current?.getBoundingClientRect().width - 8
        }
        return positionX;
    }

    const calculatePositionY = (positionY, elHeight, ref) => {
        /*console.group()
        console.log('positionY', positionY)
        console.log('elHeight', elHeight)
        console.log('refHeight', ref?.current?.getBoundingClientRect().height)
        console.log('window', window.innerHeight)
        console.log('newPos', positionY - elHeight - ref?.current?.getBoundingClientRect().height - 16)
        console.groupEnd()*/
        if (positionY + elHeight + ref?.current?.getBoundingClientRect().height > window.innerHeight) {
            if(positionY - elHeight - ref?.current?.getBoundingClientRect().height > 0) {
                return positionY - elHeight - ref?.current?.getBoundingClientRect().height
            }else{
                return 0 - elHeight;
            }
        }
        return positionY;
    }

    const calculatePositions = () => {
        setPosX(calculatePositionX(popup?.props?.parentPosition?.x || popup?.elementPosition?.x, ref, popup?.positionType))
        setPosY(calculatePositionY(popup?.props?.parentPosition?.y || popup?.elementPosition?.y, popup?.elementPosition?.height, ref, popup?.positionType))
    }

    useEffect(() => {
        // const debouncedCalculatePositionX = debounce(() => {
        //     setPosX(calculatePositionX(popup?.props?.parentPosition?.x || popup?.elementPosition?.x, ref, popup?.positionType))
        //     setPosY(calculatePositionY(popup?.props?.parentPosition?.y || popup?.elementPosition?.y, popup?.elementPosition?.height, ref, popup?.positionType))
        // }, 50)

        if(popup?.visible){
            window.addEventListener('resize', hidePopup, false);
            document.body.addEventListener('click', hidePopup, false)
        }else{
            window.removeEventListener('resize', hidePopup)
            document.body.removeEventListener('click', hidePopup)
        }
    }, [popup?.visible])

    useEffect(() => {
        calculatePositions();
    }, [menuIsOpen, popup?.elementPosition?.height, popup?.elementPosition?.x, popup?.elementPosition?.y, popup?.props?.parentPosition?.x, popup?.props?.parentPosition?.y, ref, /*refHeight,*/ popup?.positionType])

    const renderContent = () => {
        if(popup.component) {
            if(typeof popup.component === 'string') return popup.component
            return <popup.component recalculatePopupPositions={calculatePositions} handleHide={() => dispatch(hide())} {...popup.props} elementPosition={popup?.elementPosition} />
        }
    }

    return (
        <AnchorPopupWrapper
            visible={popup?.visible}
            positionX={popup?.elementPosition?.x}
            positionY={popup?.elementPosition?.y}
            elementHeight={popup?.elementPosition?.height}
            elementWidth={popup?.elementPosition?.width}
            parentX={popup?.props?.parentPosition?.x}
            parentY={popup?.props?.parentPosition?.y}
            posX={posX}
            posY={posY}
            ref={ref}
            width={popup?.props?.width}
            height={popup?.props?.height}
            anchorZIndex={popup?.props?.anchorZIndex}
            fixedPosition={popup?.props?.fixedPosition}
            type={popup?.props?.type}
            namePopup={popup?.props?.namePopup}
            onClick={e => {e.stopPropagation(); /*handleKeyDown()*/}}
            // onKeyDown={() => handleKeyDown()}
        >
            <AnchorPopupContent namePopup={popup?.props?.namePopup} >
                {renderContent()}
            </AnchorPopupContent>
        </AnchorPopupWrapper>
    );
};

const AnchorPopupWrapper = styled.div`
  position: ${({fixedPosition}) => fixedPosition ? 'fixed' : 'absolute'};
  display: ${({visible, posX, positionY}) => visible && posX && !isNull(positionY) ? 'block' : 'none'};
  left: ${({posX}) => posX}px;
  // left: ${({posX, parentX}) => parentX ? parentX : posX}px;
  top: ${({posY, elementHeight}) => posY + elementHeight}px;
  padding: ${({namePopup}) => namePopup === 'Activities' ? '0' : '8px 0'};
  // top: ${({posY, elementHeight, parentY}) => parentY ? parentY + elementHeight + 8 : posY + elementHeight + 8}px;
  z-index: ${({anchorZIndex}) => anchorZIndex ? anchorZIndex : '10001'};
  width: ${({width}) => width};
`

const AnchorPopupContent = styled.div`
    max-height: ${({height}) => height};
    border-radius: ${({theme}) => theme.other.borderRadius};
    /* overflow: hidden; */
    ${({namePopup}) => namePopup !== 'Activities' && css`
         background-color: ${({theme, type}) => type === 'dark' ? theme.colors.black76 : theme.colors.white100};
         ${({theme}) => theme.mixins.shadowDark8dp()};
    `}
`

export default AnchorPopup;
