import React, {useCallback, useEffect} from 'react';
import styled, {css} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {hide, unregisterModal} from "reducers/modal/modalSlice";
import ActionIcon from "components/ActionIcon";
import ClearIcon from "components/Icons/ClearIcon";
import {Wrapper} from "styled/Wrapper";
import {debounce} from "utils/debounce";
import {hide as hideTooltip} from 'reducers/tooltip/tooltipSlice'
import {hide as hideAnchorPopup} from 'reducers/anchorPopup/anchorPopupSlice'
import CloseIcon from 'components/Icons/CloseIcon';
import { isMobileOnly } from 'react-device-detect';

const Modal = () => {

    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight)

    const modal = useSelector(state => state.modal)
    const dispatch = useDispatch()

    const checkVisible = () => {
        return modal.filter(item => item.isOpen && true).length > 0
    }

    const handleHide = (name) => {
        dispatch(unregisterModal(name))
        dispatch(hide(name))
        dispatch(hideAnchorPopup())
    }

    const calculateScrollHeight = useCallback(() => {
        return windowHeight - 60 - 64 + 'px'
    }, [windowHeight])

    useEffect(() => {

        const debouncedHandleResize = debounce(() => {
            setWindowHeight(window.innerHeight)
        }, 50)

        window.addEventListener('resize', debouncedHandleResize)
        calculateScrollHeight()
        return () => window.removeEventListener('resize', debouncedHandleResize)
    })

    const renderHeader = (item) => {
        if (item.headerComponent) {
            if (typeof item.headerComponent === 'string') return item.headerComponent
            return <item.headerComponent {...item.modalProps} hideModal={() => handleHide(item.name)}/>
        }
    }

    const renderContent = (item) => {
        if (item.component) {
            if (typeof item.component === 'string') return item.component
            return <item.component {...item.modalProps} hideModal={() => handleHide(item.name)}/>
        }
    }

    const handleClose = (name) => {
        dispatch(hideTooltip())
        dispatch(hideAnchorPopup());
        handleHide(name)
    }

    return (
        <Container isVisible={checkVisible()} zIndex={modal[0]?.modalProps?.zIndex}>
            {modal.map(item => item.isOpen && (
                <>
                    {item?.mode === 'mobile-bottom-bar' ?
                        <ModalBottomBarWrapper width={item.modalProps.modalWidth}>
                            {!item.modalProps.hideHeader && <>
                                <Wrapper direction={'row'} paddings={'12px 16px 12px 16px'} justify={'space-between'}
                                         align={'center'} flex={'0 0 auto'}>
                                    {item.headerComponent && <BottomBarHeaderWrapper>
                                        {renderHeader(item)}
                                    </BottomBarHeaderWrapper>}
                                    <ActionIcon action={() => handleClose(item.name)} icon={CloseIcon} size={'24px'}/>
                                </Wrapper>
                            </>}
                            <Wrapper>
                                {renderContent(item)}
                            </Wrapper>
                            {item.footerComponent &&
                            <Wrapper direction={'row'} paddings={'12px 12px 12px 24px'} justify={'flex-end'}
                                     align={'center'} flex={'0 0 auto'}>
                                <item.footerComponent hideModal={() => handleHide(item.name)} {...item.modalProps} />
                            </Wrapper>}
                        </ModalBottomBarWrapper>
                        :
                        <ModalWrapper width={item.modalProps.modalWidth}>
                            <Wrapper direction={'row'} paddings={item.modalProps.headerWithoutPaddings ? 0 : '12px 16px 12px 24px'} justify={'space-between'}
                                     align={'center'} flex={'0 0 auto'}>
                                {!item.modalProps.hideHeader && <>
                                    {item.headerComponent && <HeaderWrapper>
                                        {renderHeader(item)}
                                    </HeaderWrapper>}
                                    <ActionIcon action={() => handleClose(item.name)} icon={ClearIcon}
                                                tooltip={'Закрыть'}/>
                                </>}
                            </Wrapper>
                            <Wrapper>
                                {renderContent(item)}
                            </Wrapper>
                            {item.footerComponent &&
                            <Wrapper direction={'row'} paddings={'12px 12px 12px 24px'} justify={'flex-end'}
                                     align={'center'} flex={'0 0 auto'}>
                                <item.footerComponent hideModal={() => handleHide(item.name)} {...item.modalProps} />
                            </Wrapper>}
                        </ModalWrapper>
                    }
                    <Overlay onClick={(e) => { isMobileOnly && handleHide(item.name) }}/>
                </>
            ))}
        </Container>
    );
};

const HeaderWrapper = styled.div`
  flex: 1 1 auto;
  ${({theme}) => theme.mixins.robotoMedium({lineheight: theme.fonts.lineHeight.h5, color: theme.colors.black54})};
`

const BottomBarHeaderWrapper = styled.div`
  flex: 1 1 auto;
  ${({theme}) => theme.mixins.robotoMedium({lineheight: theme.fonts.lineHeight.h4, size: theme.fonts.sizes.h4})};
`

const ModalBottomBarWrapper = styled.div`
  pointer-events: all;
  background-color: ${({theme}) => theme.colors.white100};
  border-top-left-radius: ${({theme}) => theme.other.modalBorderRadius};
  border-top-right-radius: ${({theme}) => theme.other.modalBorderRadius};
  ${({theme}) => theme.mixins.shadowDark24dp()};
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
`

const ModalWrapper = styled.div`
  pointer-events: all;
  background-color: ${({theme}) => theme.colors.white100};
  border-radius: ${({theme}) => theme.other.modalBorderRadius};
  ${({theme}) => theme.mixins.shadowDark24dp()};
  width: ${({width}) => width};
  position: absolute;
  z-index: 1000;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.isTablet && css`
    max-width: calc(var(--vh,1vh) * 100 - 60px);
  `}

  ${({theme}) => theme.isMobile && css`
    max-width: calc(100% - 24px);
  `}
`

const Container = styled.div`
  display: ${({isVisible}) => isVisible ? 'flex' : 'none'};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: ${({zIndex}) => zIndex ? zIndex : '1000'};

  & *:focus {
    outline: none;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 32, 51, 0.24);
  z-index: 999;
  pointer-events: all;
`

export default Modal;
