import React from 'react';
import {Wrapper} from "styled/Wrapper";

const Loader = () => {
    return (
        <Wrapper fullSize align={'center'} justify={'center'}>
        </Wrapper>
    );
};

export default Loader;
