import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'styled/Wrapper';
import Textarea from 'components/Textarea/Textarea';
import Text from 'components/Text';
import styled, { useTheme } from 'styled-components';
import CloseIcon from 'components/Icons/CloseIcon';
import ActionIcon from 'components/ActionIcon';
import Group from 'components/Group';
import Button from 'components/Button';
import Icon from 'components/Icon';

const TextAreaMobileModal = ({ onAccept, hideModal, maxLength, title, placeholder, showCharCounter, acceptButtonName }) => {

  const theme = useTheme();

  const [value, setValue] = useState('');
  const [charCount, setCharCount] = useState(0);

  const handleOnChange = (val) => {
    setValue(val);
    setCharCount(val?.length)
  }

  const handleClose = (e) => {
    hideModal && hideModal();
  }

  const NoteInputModalHeader = ({charCount}) => {
    return (
        <HeaderWrapper>
            <Wrapper direction="row" align="center">
              <Wrapper flex="0 0 auto" width="unset" margins="0 12px 0 0">
                  <Text tag='h4' font={'robotoMedium'} text={title}/>
              </Wrapper>
              {showCharCounter && 
                <Wrapper flex="0 0 auto" width="unset">
                  <Text tag='small' font={'robotoCondensedRegular'} color={theme.colors.black30} text={`${charCount}/${maxLength}`}/>
                </Wrapper>
              }
            </Wrapper>
            <Icon action={handleClose} icon={CloseIcon} size='24' />
        </HeaderWrapper>
    )
  }


  return (
    <Wrapper>
        <NoteInputModalHeader charCount={charCount} />
        <Textarea onChange={handleOnChange} placeholder={placeholder} value={value} height={60} symbols={maxLength}/>
        <Wrapper >
            <Group gap={8} justify="space-between">
                <Group.Item>
                    <Button type="link" buttonType="link" onClick={() => handleClose()} label={'Отмена'} />
                </Group.Item>
                <Group.Item>
                    <Button
                      type="link"
                      buttonType="link"
                      disabled={charCount < 1 || value.trim().length <= 0}
                      onClick={() => {
                        onAccept && onAccept(value);
                        handleClose();
                      }}
                      label={acceptButtonName}
                    />
                </Group.Item>
            </Group>
        </Wrapper>
    </Wrapper>
  )};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`

TextAreaMobileModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  showCharCounter: PropTypes.bool,
  acceptButtonName: PropTypes.string,
};

TextAreaMobileModal.defaultProps = {
  maxLength: 500,
  showCharCounter: true,
  acceptButtonName: 'Добавить'
}

export default TextAreaMobileModal;
