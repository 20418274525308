import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import {findElementByElementId, findOrganizationsByElementId} from "utils/statistics";
import {isEmpty, cloneDeep, get, isArray} from "lodash";
import dayjs from "dayjs";

const initialState = {
    data: [],
    periodFilteringLabel: 'За месяц',
    fieldsFilter: {
        startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        statType: 0,
        partof: null,
        profile: '',
        'type:missing': false
    },
    searchString: null,
    sorting: {
        default: [
            {
                propertyName: "name",
                direction: 0,
            }
        ],
        practitioner: [
            {
                propertyName: "Practitioner.name",
                direction: 0,
            }
        ]
    },
    childCheckedOrganizations: null,
    loadingStatisticsPartOf: false,
    organizationsForFilter: [],
    organizationsForFilterCheckedAll: 1,
    trimesters: [],
    mkb10ForFilter: {
        searchList: [],
    },
    z33Item: {},
    activePeriodItem: 0,
    loading: {
        fullPage: true,
        data: true,
    },
    exportLoader: false,
    saveThisPageFilter: false,
}

export const statisticsOrganizationAdminSlice = createSlice({
    name: 'statisticsOrganizationAdmin',
    initialState,
    reducers: {
        setStatTypeToFilter: (state, action) => {
            state.fieldsFilter.statType = action.payload;
        },
        setStartDateToFilter: (state, action) => {
            state.fieldsFilter.startDate = action.payload;
        },
        setPeriodFilteringLabel: (state, action) => {
            state.periodFilteringLabel = action.payload;
        },
        setEndDateToFilter: (state, action) => {
            state.fieldsFilter.endDate = action.payload;
        },
        setProfile: (state, action) => {
            state.fieldsFilter.profile = action.payload;
        },
        setSorting: (state, action) => {
            state.sorting['default'][0].propertyName = action.payload.propertyName;
            state.sorting['default'][0].direction = state.sorting['default'][0].direction === 1 ? 0 : 1;
            state.sorting['practitioner'][0].propertyName = `Practitioner.${action.payload.propertyName}`;
            state.sorting['practitioner'][0].direction = state.sorting['practitioner'][0].direction === 1 ? 0 : 1;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setPartOfData: (state, action) => {
            const targetElement = findElementByElementId(state.data.items, action.payload.partof);
            console.log("targetElement", targetElement);
            if (targetElement) targetElement.content = action.payload.data.items;
        },
        addToPartOfData: (state, action) => {
            const targetElement = findElementByElementId(state.data.items, action.payload.partof);
            if (targetElement && isArray(targetElement.content)) targetElement.content = targetElement.content.concat(action.payload.data.items)
        },
        clearPartOfData: (state, action) => {
            const targetElement = findElementByElementId(state.data.items, action.payload.partof);
            if (targetElement) targetElement.content = [];
        },
        setOpen: (state, action) => {
            const targetElement = findElementByElementId(state.data.items, action.payload.part);
            if (targetElement) targetElement.open = action.payload.open;
        },
        setOrganizationsForFilter: (state, action) => {
            state.organizationsForFilter = action.payload;
        },
        checkOrganization: (state, action) => {
            const id = action.payload.id;
            const checked = action.payload.checked;
            const targetElement = findOrganizationsByElementId(state.organizationsForFilter, id);
            const checkElementsInside = (el, checked) => {
                el.checked = checked;
                if (el.childElements) el.childElements.map(item => checkElementsInside(item, checked));
            }
            checkElementsInside(targetElement, checked);
            const checkElementsOutside = (el, checked) => {
                let parent;
                if (el.organization.partof) {
                    parent = findOrganizationsByElementId(state.organizationsForFilter, el.organization.partof.id);
                    if (checked) {
                        console.log("1");
                        if (parent.childElements.find(item => (item.checked === 0 || item.checked === 2))) {
                            console.log("2");
                            parent.checked = 2;
                        } else {
                            console.log("3");
                            parent.checked = 1;
                        }
                    } else {
                        console.log("4");
                        if (parent.childElements.find(item => (item.checked === 1 || item.checked === 2))) {
                            console.log("5");
                            parent.checked = 2;
                        } else {
                            console.log("6");
                            parent.checked = 0;
                        }
                    }
                    if (parent.organization.partof) checkElementsOutside(parent, checked);
                }
            }
            checkElementsOutside(targetElement, checked);

            // Устанавливаем значение Выбрать все
            if (state.organizationsForFilter.find(item => (item.checked === 0) || (item.checked === 2))){
                if (!state.organizationsForFilter.find(item => (item.checked === 1) || (item.checked === 2))) {
                    state.organizationsForFilterCheckedAll = 0;
                } else {
                    state.organizationsForFilterCheckedAll = 2;
                }
            } else {
                state.organizationsForFilterCheckedAll = 1;
            }
        },
        checkOrganizationsAll: (state, action) => {
            const changeChecked = (arr, value) => {
                arr.forEach(item => {
                    item.checked = value;
                    if (item.childElements) changeChecked(item.childElements, value);
                })
            }
            changeChecked(state.organizationsForFilter, action.payload);
            state.organizationsForFilterCheckedAll = action.payload;
        },
        changeOrganizationsForFilterCheckedAll: (state, action) => {
            state.organizationsForFilterCheckedAll = action.payload;
        },
        setTrimestersList: (state, action) => {
            state.trimesters = action.payload;
        },
        setTrimesterCodeToFilter: (state, action) => {
            if (action.payload) {
                state.fieldsFilter.trimester = action.payload;
            } else {
                delete state.fieldsFilter.trimester;
            }
        },
        setMkb10ListForFilter: (state, action) => {
            state.mkb10ForFilter.searchList = action.payload;
        },
        setMkb10ToFilter: (state, action) => {
            if (action.payload) {
                state.mkb10ForFilter.activeElement = action.payload;
                state.fieldsFilter.conditionCode = action.payload[0].code;
            } else {
                delete state.mkb10ForFilter.activeElement;
                delete state.fieldsFilter.conditionCode;
            }
        },
        setOrganizationsToFilter: (state, action) => {
            if (action.payload) {
                state.fieldsFilter.organizationId = action.payload;
            } else {
                delete state.fieldsFilter.organizationId;
            }
        },
        setOrganizationsFilterForChildElements: (state, action) => {
            state.childCheckedOrganizations = action.payload;
        },
        setActivePeriodItem: (state, action) => {
            state.activePeriodItem = action.payload;
        },
        setPartOf: (state, action) => {
            state.fieldsFilter = {
                ...state.fieldsFilter,
                partof: action.payload
            };
        },
        resetFilters: (state, action) => {
            if(!state.saveThisPageFilter){
                state.fieldsFilter = {
                    startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    statType: 0,
                    partof: null,
                    profile: '',
                    'type:missing': false
                }

                state.childCheckedOrganizations = null;
                state.activePeriodItem = 0;
                state.periodFilteringLabel = 'За месяц';
                state.trimesters = cloneDeep(state.trimesters).map(item => ({ ...item, active: item?.id === 0}));

                if(state.fieldsFilter.trimester){
                    delete state.fieldsFilter.trimester;
                }

                if(state.mkb10ForFilter.activeElement) {
                    delete state.mkb10ForFilter.activeElement
                }
            }
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setExportLoader: (state, action) => {
            state.exportLoader = action.payload;
        },
        setProfilesToFilter: (state, action) => {
            action.payload ? state.fieldsFilter.profile = action.payload : delete state.fieldsFilter.profile;
        },
        setSaveThisPageFilter: (state, action) => {
            state.saveThisPageFilter = action.payload;
        },
        resetState: (state, action) => {
            return initialState
        },
        setLoadingStatisticsPartOf: (state, action) => {
            const targetElement = findElementByElementId(state.data.items, action.payload.part);
            if (targetElement) targetElement.loading = action.payload.loading;
            // state.loadingStatisticsPartOf = action.payload;
        },
        setSearchString: (state, action) => {
            state.searchString = action.payload;
        },
        setZ33Item:(state, action) => {
            state.z33Item = action.payload;
        },
    },
});

export const selectData = state => state.organizationAdmin.statistics.data;
export const selectPeriodFilteringLabel = state => state.organizationAdmin.statistics.periodFilteringLabel;
export const selectFieldsFilter = state => state.organizationAdmin.statistics.fieldsFilter;
export const selectSorting = state => state.organizationAdmin.statistics.sorting.default;
export const selectPractitionerSorting = state => state.organizationAdmin.statistics.sorting.practitioner;
export const selectOrganizationsForFilter = state => state.organizationAdmin.statistics.organizationsForFilter;
export const selectOrganizationsForFilterCheckedAll = state => state.organizationAdmin.statistics.organizationsForFilterCheckedAll;
export const selectTrimesters = state => state.organizationAdmin.statistics.trimesters;
export const selectActiveTrimester = state => state.organizationAdmin.statistics.activeTrimester;
export const selectMkb10ForFilter = state => state.organizationAdmin.statistics.mkb10ForFilter.searchList;
export const selectMkb10ActiveElementForFilter = state => state.organizationAdmin.statistics.mkb10ForFilter.activeElement;
export const selectActivePeriodItem = state => state.organizationAdmin.statistics.activePeriodItem;
export const selectExportLoader = state => state.organizationAdmin.statistics.exportLoader;
export const selectLoading = state => state.organizationAdmin.statistics.loading;
export const selectSaveThisPageFilter = state => state.organizationAdmin.statistics.saveThisPageFilter;
export const selectSearchString = state => state.organizationAdmin.statistics.searchString;
export const selectChildCheckedOrganization = state => state.organizationAdmin.statistics.childCheckedOrganizations;
export const selectZ33Item = state => state.organizationAdmin.statistics.z33Item;
// export const selectLoadingStatisticsPartOf = state => state.organizationAdmin.statistics.loadingStatisticsPartOf;
export const StatisticsOrganizationAdminActions = statisticsOrganizationAdminSlice.actions;

export const getStatistics = ({fieldsFilter, sorting, profileList, searchString}) => {

    // var profileCodes = profileList.filter((item) => {
    //     return item.checked === 1;
    // }).map((item) => {
    //     return item.code;
    // })

    return (dispatch) => {
        dispatch(StatisticsOrganizationAdminActions.setLoading({
            type: 'data',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.statistics}/_search`,
        data: {
                paging:{
                    startIndex:0,
                    maxItems:0
                },
                filtering:{
                    searchString,
                    fieldsFilter: { 
                        ...fieldsFilter, 
                        'type:missing': false
                        // profile: profileCodes.join() 
                    },
                },
                sorting,
            }
        }).then((response) => {
            dispatch(StatisticsOrganizationAdminActions.setData(response.data));
            dispatch(StatisticsOrganizationAdminActions.setLoading({
                type: 'data',
                value: false
            }));
        }).catch(err => {
            dispatch(StatisticsOrganizationAdminActions.setLoading({
                type: 'data',
                value: false
            }));
        });
    };
};

export const getStatisticsPartOf = ({fieldsFilter, sorting, addToExistData, searchString}) => {
    const fieldsFilterCopy = cloneDeep(fieldsFilter);
    // delete fieldsFilterCopy.ids;

    return (dispatch) => {
        dispatch(StatisticsOrganizationAdminActions.setLoadingStatisticsPartOf({
            part: fieldsFilter.partof,
            loading: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.statistics}/_search`,
        data: {
                paging:{
                    startIndex:0,
                    maxItems:0
                },
                filtering:{
                    searchString,
                    fieldsFilter: {
                        ...fieldsFilterCopy,
                        'type:missing': false
                    }
                },
                sorting,
            }
        }).then((response) => {
            if(addToExistData){
                dispatch(StatisticsOrganizationAdminActions.addToPartOfData({
                    data: response.data,
                    partof: fieldsFilter.partof,
                }));
            }else{
                dispatch(StatisticsOrganizationAdminActions.setPartOfData({
                    data: response.data,
                    partof: fieldsFilter.partof,
                }));
            }
            dispatch(StatisticsOrganizationAdminActions.setLoadingStatisticsPartOf({
                part: fieldsFilter.partof,
                loading: false
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getPractitionersPartOf = ({fieldsFilter, sorting, addToExistData, searchString}) => {
    const fieldsFilterCopy = cloneDeep(fieldsFilter);
    delete fieldsFilterCopy.ids;

    return (dispatch) => {
        dispatch(StatisticsOrganizationAdminActions.setLoadingStatisticsPartOf({
            part: fieldsFilter.organizationId,
            loading: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.practitioners}/_search`,
        data: {
                paging:{
                    startIndex:0,
                    maxItems:0
                },
                filtering:{
                    searchString,
                    fieldsFilter: fieldsFilterCopy,
                },
                sorting
            }
        }).then((response) => {
            // console.log("response", response.data);
            const data = cloneDeep(response.data);
            let newData = {items: []};

            data.items.forEach(item => {
                // console.log("item", item);
                newData.items.push({
                    practitioner: {
                        id: get(item, `practitioner.id`),
                        name: get(item, `practitioner.name[0].text`),
                        practitionerData: {
                            active: get(item, `practitioner.active`),
                            gender: get(item, `practitioner.gender`),
                        }
                    },
                    practitionerRole: get(item, `practitionerRole`),
                    statistics: item.statistics,
                    partof: fieldsFilter.organizationId
                })
            })

            if(addToExistData){
                dispatch(StatisticsOrganizationAdminActions.addToPartOfData({
                    data: newData,
                    partof: fieldsFilter.organizationId,
                }));
            }else{
                dispatch(StatisticsOrganizationAdminActions.setPartOfData({
                    data: newData,
                    partof: fieldsFilter.organizationId,
                }));
            }
            dispatch(StatisticsOrganizationAdminActions.setLoadingStatisticsPartOf({
                part: fieldsFilter.organizationId,
                loading: false
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getOrganizationsForFilter = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.organizationHierarchy}?fg=type:missing::false`,
        }).then((response) => {
            const items = response.data.items;
            const setAllElementsChecked = (dataObj, level) => {
                const objLevel = level ? level : 1;
                dataObj.forEach(item => {
                    item.checked = 1;
                    item.level = objLevel;
                    if (!isEmpty(item.childElements)) setAllElementsChecked(item.childElements, objLevel + 1);
                });
            }
            setAllElementsChecked(items);
            dispatch(StatisticsOrganizationAdminActions.setOrganizationsForFilter(items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getTrimestersOfPregnancyForFilter = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: api.trimesters,
        }).then((response) => {
            const items = response.data.items
            items.forEach(item => {
                item.label = item.display;
            });
            items.unshift({id: 0, label: 'Любой триместр', active: true, code: null})
            dispatch(StatisticsOrganizationAdminActions.setTrimestersList(items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getMkb10ForFilter = searchString => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.mkb10}?ss=${searchString}`,
        }).then((response) => {
            const initial = response.data.items;
            const items = initial.filter(item => item.code);
            items.forEach(item => {
                item.label = item.display;
                item.pre = item.code;
            });
            dispatch(StatisticsOrganizationAdminActions.setMkb10ListForFilter(items));
        }).catch(err => {
            console.log(err)
        });
    };
};

export const getZ33ItemForFilter = () => {
    return (dispatch) => {
      fetchInstance({
        method: "GET",
        url: `${api.mkb10}?ss=Z33`,
        }).then((response) => {
            const item = get(response, 'data.items[0]');

            item && dispatch(StatisticsOrganizationAdminActions.setZ33Item({
                ...item,
                label: item.display,
                pre: item.code
            }));
        }).catch(err => {
            console.log(err)
        });
    };
};

export default statisticsOrganizationAdminSlice.reducer;