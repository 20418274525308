import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'styled/Wrapper';
import Item from './Item';
import { useTheme } from 'styled-components';

const BottomBarMenu = ({actions, hideModal, data, type, activeItem, activeIconMobile}) => {

    const theme = useTheme();

    const [activeItemLocal, setActiveItem] = useState(activeItem); 

    const handleItemAction = (e, item) => {
        setActiveItem(item);
        if(!item.noHide){
            hideModal()
        }
        if(item.action) {
            item.action(data, e)
        }
    }

    return <Wrapper>
            {actions.map(item => {
                return <Item item={item} key={'bottom-bar-menu-item-' + item.id} color={theme.colors[item.color]} active={activeItemLocal?.id === item?.id} onClick={(e, item) => handleItemAction(e, item)} bordered={item.bordered} type={type} nowrap={item.nowrap} icon={item.icon} iconSize={item.iconSize} activeIconMobile={activeIconMobile} />
            })}
        </Wrapper>
};

BottomBarMenu.propTypes = {};

export default BottomBarMenu;
