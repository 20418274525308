import React, {createRef, useCallback, useEffect, useState} from 'react'; 
import styled, {css} from "styled-components";
import {useSelector} from "react-redux";
import {debounce} from "utils/debounce";

const Tooltip = () => {
    const tooltip = useSelector(state => state.tooltip)
    const ref = createRef()

    // console.log('ttttt', tooltip?.props)

    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [position] = useState({x: 'default', y: 'default'});

    // console.log('positions', tooltip?.elementPosition)
    // console.log('x', tooltip?.elementPosition?.x)
    // console.log('y', tooltip?.elementPosition?.y)

    // console.log('tooltip', tooltip)

    const calculatePositionX = useCallback((positionX, ref) => {

        // console.log('posX', positionX)

        if (positionX + ref?.current?.getBoundingClientRect().width > window.innerWidth) {
            // setPosition(state => ({...state, x: 'right'}))
            return window.innerWidth - ref?.current?.getBoundingClientRect().width - 20
        } else if (positionX === 0) {
            // setPosition(state => ({...state, x: 'left'}))
            return 20;
        } else {
            // setPosition(state => ({...state, x: 'default'}))
            return positionX;
        }
    }, [])

    const calculatePositionY = useCallback((positionY, elHeight, ref) => {
        if (positionY + elHeight + ref?.current?.getBoundingClientRect().height > window.innerHeight) {
            // setPosition(state => ({...state, y: 'bottom'}))
            return positionY - elHeight - ref?.current?.getBoundingClientRect().height - 16
        } else {
            // setPosition(state => ({...state, y: 'default'}))
            return positionY;
        }
    }, [])

    useEffect(() => {
        const debouncedCalculatePositionX = debounce(() => {
            setPosX(calculatePositionX(tooltip?.elementPosition?.x, ref))
            setPosY(calculatePositionY(tooltip?.elementPosition?.y, tooltip?.elementPosition?.height, ref))
        }, 10)

        window.addEventListener('resize', debouncedCalculatePositionX)
        return () => {
            window.removeEventListener('resize', debouncedCalculatePositionX)
        }
    })

    useEffect(() => {
        setPosX(calculatePositionX(tooltip?.elementPosition?.x, ref))
        setPosY(calculatePositionY(tooltip?.elementPosition?.y, tooltip?.elementPosition?.height ,ref))
    }, [tooltip?.elementPosition?.x, ref, tooltip?.elementPosition?.y, tooltip?.elementPosition?.height])

    const renderContent = () => {
        if(tooltip.component) {
            if(typeof tooltip.component === 'string') return tooltip.component
            return <tooltip.component {...tooltip.props} />
        }
    }

    return (
        <TooltipWrapper
            visible={tooltip.visible}
            positionX={tooltip?.elementPosition?.x}
            positionY={tooltip?.elementPosition?.y}
            elementHeight={tooltip?.elementPosition?.height}
            elementWidth={tooltip?.elementPosition?.width}
            posX={posX}
            posY={posY}
            ref={ref}
            globalPosition={position}
            maxWidth={tooltip?.props.tooltipMaxWidth}
            zIndex={tooltip?.props.tooltipZIndex}
            tooltipWrap={tooltip?.props.tooltipWrap ? tooltip?.props.tooltipWrap : 'normal'}
        >
            {renderContent()}
        </TooltipWrapper>
    );
};

const calculatePositionHorizontal = ({globalPosition, posX, elementWidth}) => {
    switch (globalPosition.x) {
        case 'default':
            return css`
              left: ${posX}px;
            `
        case 'left':
            return css`
              left: ${posX}px;
            `
        case 'right':
            return css`
              left: ${posX}px;
            `
        case 'bottom':
            return css`
              left: ${posX}px;
            `
        default:
            return css`
              left: ${posX}px;
            `
    }
}

const calculatePositionVertical = ({globalPosition, posY, elementHeight}) => {
    switch (globalPosition.y) {
        case 'default':
            return css`
              top: ${posY + elementHeight + 8}px;
            `
        case 'left':
            return css`
              top: ${posY + elementHeight + 8}px;
            `
        case 'right':
            return css`
              top: ${posY + elementHeight + 8}px;
            `
        case 'bottom':
            return css`
              top: ${posY + elementHeight + 8}px;
            `
        default:
            return css`
              top: ${posY + elementHeight + 8}px;
            `
    }
}

const TooltipWrapper = styled.div`
  position: absolute;
  display: ${({visible, posX, positionY}) => visible && posX && positionY ? 'block' : 'none'};
  ${({globalPosition, posX, elementWidth}) => calculatePositionHorizontal({globalPosition, posX, elementWidth})};
  ${({globalPosition, posY, elementHeight}) => calculatePositionVertical({globalPosition, posY, elementHeight})};
  ${({theme}) => theme.mixins.robotoCondensedRegular({size: theme.fonts.sizes.label, lineheight: theme.fonts.lineHeight.small})};
  color: #fff;
  border-radius: ${({theme}) => theme.other.borderRadius};
  background-color: ${({theme}) => theme.colors.tooltip};
  max-width: ${({maxWidth}) => maxWidth};
  z-index: ${({zIndex}) => zIndex ? zIndex : '1001'};
  padding: 4px 8px;
  white-space: ${({tooltipWrap}) => tooltipWrap};
`

export default Tooltip;
