import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { cloneDeep, get } from "lodash";
import dayjs from 'dayjs';
import fileDownload from 'react-file-download';

const initialState = {
    data: [],
    roles: [],
    periodFilteringLabel: 'За месяц',
    activePeriodItem: 0,
    tableSettings: {
        searchString: '',
        fieldsFilter: {
            startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
        },
        sorting: [
            {
                propertyName: "datetime", 
                direction: 1,
            }
        ],
        paging: {
            startIndex: 0, 
            maxItems: 25
        },
    },
    loading: {
        fullPage: true,
        data: true,
    },
    exportLoader: false,
    saveThisPageFilter: false,
};

const reducers = {
    setData: (state, action) => {
        state.data = action.payload;
    },
    setRoles: (state, action) => {
        state.roles = action.payload;
    },
    setSorting: (state, action) => {
        state.tableSettings.sorting[0].propertyName = action.payload;
        state.tableSettings.sorting[0].direction = state.tableSettings.sorting[0].direction === 1 ? 0 : 1;
    },
    setSearchString: (state, action) => {
        state.tableSettings.searchString = action.payload;
    },
    setFieldsFilter: (state, action) => {
        if(action.payload){
            if(action.payload.params) {
                state.tableSettings.fieldsFilter = {...state.tableSettings.fieldsFilter, [action.payload.field]: action.payload.params}
            }else{
                delete state.tableSettings.fieldsFilter[action.payload.field]
            }
        }
    },
    setStartDateToFilter: (state, action) => {
        state.tableSettings.fieldsFilter.startDate = action.payload;
    },
    setPeriodFilteringLabel: (state, action) => {
        state.periodFilteringLabel = action.payload;
    },
    setEndDateToFilter: (state, action) => {
        state.tableSettings.fieldsFilter.endDate = action.payload;
    },
    setActivePeriodItem: (state, action) => {
        state.activePeriodItem = action.payload;
    },
    setPage: (state, action) => {
        state.tableSettings.paging.startIndex = (action.payload - 1) * state.tableSettings.paging.maxItems
    },
    setMaxItems: (state, action) => {
        state.tableSettings.paging.maxItems = action.payload;
    },
    resetFilters: (state, action) => {
        state.tableSettings.fieldsFilter = {
            startDate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
        }
    },
    setLoading: (state, action) => {
        const { type, value } = action.payload;
        state.loading[type] = value;
    },
    setExportLoader: (state, action) => {
        state.exportLoader = action.payload;
    },
    setLoadingAll: (state, action) => {
        Object.keys(state.loading).forEach(item => {
            state.loading[item] = true;
        })
    },
    clearTableSettings: (state, action) => {
        if (!state.saveThisPageFilter) state.tableSettings = initialState.tableSettings;
    }, 
    setSaveThisPageFilter: (state, action) => {
        state.saveThisPageFilter = action.payload;
    },
    resetStateExceptTableSettings: (state, action) => {
        const initialStateCopy = cloneDeep(initialState);
        const tableSettingsCopy = cloneDeep(state.tableSettings);
        delete initialStateCopy.tableSettings;
        initialStateCopy.tableSettings = tableSettingsCopy;
        return initialStateCopy;
    },
    resetState: (state, action) => {
        return initialState
    },
}

export const getSlice = (name) => createSlice({
    name,
    initialState,
    reducers
});

export const getSelectors = (statePath) => ({
    selectData: state => get(state, statePath)?.data,
    selectRoles: state => get(state, statePath)?.roles,
    selectTableSettings: state => get(state, statePath)?.tableSettings,
    selectPeriodFilteringLabel: state => get(state, statePath)?.periodFilteringLabel,
    selectFieldsFilter: state => get(state, statePath)?.tableSettings?.fieldsFilter,
    selectActivePeriodItem: state => get(state, statePath)?.activePeriodItem,
    selectSearchString: state => get(state, statePath)?.searchString,
    selectSorting: state => get(state, statePath)?.sorting,
    selectExportLoader: state => get(state, statePath)?.exportLoader,
    selectLoading: state => get(state, statePath)?.loading,
})

export const getThunks = (actions) => {

    const getEventsLogList = ({tableSettings}) => {
        return (dispatch) => {
            dispatch(actions.setLoading({
                type: 'data',
                value: true
            }));
        fetchInstance({
            method: "POST",
            url: `${api.eventLogsList}`,
            data: {
                paging: tableSettings.paging,   
                filtering: {
                    searchString: tableSettings.searchString,   
                    fieldsFilter: tableSettings.fieldsFilter
                },
                sorting: tableSettings.sorting,  
            }
            }).then((response) => {
                dispatch(actions.setData(response.data));
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            }).catch(err => {
                dispatch(actions.setLoading({
                    type: 'data',
                    value: false
                }));
            });
        };
    };

    const getRoles = () => {
        return (dispatch) => {
            fetchInstance({
                method: "GET",
                url: `${api.appRole}`,
            }).then((response) => {
                dispatch(actions.setRoles(response.data.items));
            }).catch(err => {
                console.log(err)
            });
        };
    };

    const downloadExportStatistics = ({
        tableSettings
      }) => {
        return (dispatch) => {
            dispatch(actions.setExportLoader(true))
            fetchInstance({
            method: "POST",
            url: api.eventLogsExcel,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: 50
                },
                filtering: { searchString: tableSettings.searchString, fieldsFilter: tableSettings.fieldsFilter },
                sorting: tableSettings.sorting,
              }
            }).then((response) => {
                dispatch(actions.setExportLoader(false))
                fileDownload(response.data, 'Журнал_событий', response?.headers["content-type"])
            }).catch(err => {
                dispatch(actions.setExportLoader(false))
                console.log(err)
            });
        };
    };

    return { getEventsLogList, getRoles, downloadExportStatistics }
}

export const build = (name, storePath) => {
    const slice = getSlice(name);
    const actions = slice.actions;
    const reducer = slice.reducer;
    const selectors = getSelectors(storePath);
    const thunks = getThunks(actions);

    return {
        eventLogsActions: {...actions},
        reducer,
        ...selectors,
        ...thunks
    }
}

export default { getSlice, getSelectors, getThunks, build };