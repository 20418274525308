import { createSlice } from '@reduxjs/toolkit';
import { api } from "consts/api";
import { fetchInstance } from "wrappers/axios";
import { organizationType, systemMessageDelay } from 'consts';
import {get, cloneDeep, find} from "lodash";
import dayjs from 'dayjs';
import {registerMessage, showMessage, closeMessage} from "reducers/systemMessages/systemMessagesSlice";

const initialState = {
    branches: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                partof: null,
                profile: "",
                type: organizationType.department,
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "name",
                    direction: 0,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 25
            },
        }
    },
    timeExceeded: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "answerDate",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    endOfCounseling: {
        data: [],
        tableSettings: {
            searchString: '',
            fieldsFilter: {
                daysToEnd: 14,
                status: "in-progress,planned",
                'type:missing': false
            },
            sorting: [
                {
                    propertyName: "period",
                    direction: 1,
                }
            ],
            paging: {
                startIndex: 0,
                maxItems: 10
            },
        }
    },
    organization: {
        organizationObj: {},
        organizationRole: {},
        manager: {},
        allOrganizationRoles: [],
        typeFilial: null,
        encountersCount: 0,
        doctorsCount: 0
    },
    loading: {
        fullPage: true,
        organizationDetails: true,
        branchesData: true,
        timeExceededData: true,
        endOfCounselingData: true,
        replaceDoctor: false,
        deleteChannelModal: false,
        prolongChannelModal: false
    }
}

export const mainRegistratorSlice = createSlice({
    name: 'mainRegistrator',
    initialState,
    reducers: {
        resetAll: (state, action) => {
            state = initialState;
        },
        setOrganizationObj: (state, action) => {
            state.organization.organizationObj = action.payload;
        },
        resetOrganizationObj: (state, action) => {
            state.organization.organizationObj = {
                organizationObj: {},
                organizationRole: {},
                manager: {},
                allOrganizationRoles: [],
                typeFilial: null
            }
        },
        setOrganizationEncountersCount: (state, action) => {
            state.organization.encountersCount = action.payload;
        },
        setOrganizationDoctorsCount: (state, action) => {
            state.organization.doctorsCount = action.payload;
        },
        setOrganizationRole: (state, action) => {
            state.organization.organizationRole = action.payload;
        },
        setManager: (state, action) => {
            state.organization.manager = action.payload;
        },
        setAllOrganizationRoles: (state, action) => {
            state.organization.allOrganizationRoles = action.payload;
        },
        setBranchesPartOf: (state, action) => {
            state.branches.tableSettings.fieldsFilter.partof = action.payload;
        },
        setBranchesData: (state, action) => {
            state.branches.data = action.payload;
        },
        setBranchesSorting: (state, action) => {
            state.branches.tableSettings.sorting[0].propertyName = action.payload;
            state.branches.tableSettings.sorting[0].direction = state.branches.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setBranchesOrganizationsToFilter: (state, action) => {
            if (action.payload) {
                state.branches.tableSettings.fieldsFilter.ids = action.payload;
            } else {
                delete state.branches.tableSettings.fieldsFilter.ids;
            }
        },
        setBranchesProfile: (state, action) => {
            state.branches.tableSettings.fieldsFilter.profile = action.payload
        },
        setTypeFilial: (state, action) => {
            state.organization.typeFilial = action.payload
        },
        setPatientsResponseTimeExceededData: (state, action) => {
            state.timeExceeded.data = action.payload
        },
        setPatientsEndOfCounselingPeriodData: (state, action) => {
            state.endOfCounseling.data = action.payload
        },
        setPatientsResponseTimeExceededPage: (state, action) => {
            state.timeExceeded.tableSettings.paging.startIndex = (action.payload - 1) * state.timeExceeded.tableSettings.paging.maxItems
        },
        setPatientsEndOfCounselingPeriodPage: (state, action) => {
            state.endOfCounseling.tableSettings.paging.startIndex = (action.payload - 1) * state.endOfCounseling.tableSettings.paging.maxItems
        },
        setBranchesPage: (state, action) => {
            state.branches.tableSettings.paging.startIndex = (action.payload - 1) * state.branches.tableSettings.paging.maxItems
        },
        setPatientsResponseTimeExceededMaxItems: (state, action) => {
            state.timeExceeded.tableSettings.paging.maxItems = action.payload;
        },
        setPatientsEndOfCounselingPeriodMaxItems: (state, action) => {
            state.endOfCounseling.tableSettings.paging.maxItems = action.payload;
        },
        setBranchesMaxItems: (state, action) => {
            state.branches.tableSettings.paging.maxItems = action.payload;
        },
        setSortingTimeExceeded: (state, action) => {
            state.timeExceeded.tableSettings.sorting[0].propertyName = action.payload;
            state.timeExceeded.tableSettings.sorting[0].direction = state.timeExceeded.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setTimeExceededPatientsIdToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.patientId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.patientId;
        },
        setTimeExceededPractitionerRoleIdToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.practitionerRoleId;
        },
        setTimeExceededAnswerStartDateToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerStartDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerStartDate;
        },
        setTimeExceededAnswerEndDateToFilter: (state, action) => {
            action.payload ? state.timeExceeded.tableSettings.fieldsFilter.answerEndDate = action.payload : delete state.timeExceeded.tableSettings.fieldsFilter.answerEndDate;
        },
        setSortingEndOfCounseling: (state, action) => {
            state.endOfCounseling.tableSettings.sorting[0].propertyName = action.payload;
            state.endOfCounseling.tableSettings.sorting[0].direction = state.endOfCounseling.tableSettings.sorting[0].direction === 1 ? 0 : 1;
        },
        setEndOfCounselingPatientsIdToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.patientId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.patientId;
        },
        setEndOfCounselingPractitionerRoleIdToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.practitionerRoleId;
        },
        setEndOfCounselingPeriodStartDateToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodStart = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodStart;
        },
        setEndOfCounselingPeriodEndDateToFilter: (state, action) => {
            action.payload ? state.endOfCounseling.tableSettings.fieldsFilter.periodEnd = action.payload : delete state.endOfCounseling.tableSettings.fieldsFilter.periodEnd;
        },
        setEndOfCounselinStatusesToFilter: (state, action) => {
            state.endOfCounseling.tableSettings.fieldsFilter.status = action.payload;
        },
        setLoading: (state, action) => {
            const { type, value } = action.payload;
            state.loading[type] = value;
        },
        setLoadingAll: (state, action) => {
            Object.keys(state.loading).forEach(item => {
                state.loading[item] = true;
            })
        },
        setProfilesToFilter: (state, action) => {
            action.payload ? state.branches.tableSettings.fieldsFilter.profile = action.payload : delete state.branches.tableSettings.fieldsFilter.profile;
        },
        setOrganizationsToFilter: (state, action) => {
            if (action.payload) {
                state.branches.tableSettings.fieldsFilter.ids = action.payload;
            } else {
                delete state.branches.tableSettings.fieldsFilter.ids;
            }
        },
        resetStateExceptTableSettings: (state, action) => {
            const initialStateCopy = cloneDeep(initialState);
            const tableSettingsBranchesCopy = cloneDeep(state.branches.tableSettings);
            const tableSettingsTimeExceededCopy = cloneDeep(state.timeExceeded.tableSettings);
            const tableSettingsEndOfCounselingCopy = cloneDeep(state.endOfCounseling.tableSettings);
            delete initialStateCopy.branches.tableSettings;
            delete initialStateCopy.timeExceeded.tableSettings;
            delete initialStateCopy.endOfCounseling.tableSettings;
            initialStateCopy.branches.tableSettings = tableSettingsBranchesCopy;
            initialStateCopy.timeExceeded.tableSettings = tableSettingsTimeExceededCopy;
            initialStateCopy.endOfCounseling.tableSettings = tableSettingsEndOfCounselingCopy;
            return initialStateCopy;
        },
        resetState: (state, action) => {
            return initialState
        },
    },
});


export const selectOrganizationsForBranchesFilter = state => state.registrator.main.organization.fieldsFilter;
export const selectBranchesTableSettings = state => state.registrator.main.branches.tableSettings;
export const selectFieldsFilter = state => state.registrator.main.organization.fieldsFilter;
export const selectAllOrganizationRoles = state => state.registrator.main.organization.allOrganizationRoles;
export const selectOrganizationObj = state => state.registrator.main.organization.organizationObj;
export const selectOrganizationManager = state => state.registrator.main.organization.manager;
export const selectOrganizationRole = state => state.registrator.main.organization.organizationRole;
export const selectTypeFilial = state => state.registrator.main.organization.typeFilial;
export const selectEncountersCount = state => state.registrator.main.organization.encountersCount;
export const selectDoctorsCount = state => state.registrator.main.organization.doctorsCount;
export const selectBranchesSearchString = state => state.registrator.main.branches.searchString;
export const selectBranchesSorting = state => state.registrator.main.branches.sorting;
export const selectBranchesFieldsFilter = state => state.registrator.main.branches.fieldsFilter;
export const selectBranchesData = state => state.registrator.main.branches.data;
export const selectTimeExceededTableSettings = state => state.registrator.main.timeExceeded.tableSettings;
export const selectEndTableSettings = state => state.registrator.main.endOfCounseling.tableSettings;
export const selectPatientsResponseTimeExceededData = state => state.registrator.main.timeExceeded.data;
export const selectPatientsEndOfCounselingPeriodData = state => state.registrator.main.endOfCounseling.data;
export const selectLoading = state => state.registrator.main.loading;

export const mainRegistratorActions = mainRegistratorSlice.actions;

export const getOrganizationDetails = organizationId => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'organizationDetails',
            value: true
        }));

        dispatch(getOrganizationEncountersCount(organizationId))
        dispatch(getOrganizationDoctorsCount(organizationId))

      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: {
                startIndex: 0,
                maxItems: 0
            },
            filtering: {
                searchString: '',
                fieldsFilter: {
                    id: organizationId,
                    'type:missing': false
                }
            },
            sorting: [],
        }
        }).then((response) => {
            // const item = response.data.items.find(item => item.id === organizationId);
            const item = get(response, "data.items[0]");
            dispatch(mainRegistratorActions.setOrganizationObj(item));
            dispatch(getOrganizationRole(organizationId));
            dispatch(mainRegistratorActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'organizationDetails',
                value: false
            }));
        });
    };
};

export const getOrganizationEncountersCount = organizationId => {
    return dispatch => {
        fetchInstance({
            method: "POST",
            url: `${api.organizationPatientsStatistic}/${organizationId}/Encounters`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: -1
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        'type:missing': false
                    }
                },
                sorting: [],
            }
            }).then((response) => {
                dispatch(mainRegistratorActions.setOrganizationEncountersCount(get(response, 'data.clientQuery.paging.totalItems')));
            }).catch(err => {
                console.log(err);
            });
    }
}

export const getOrganizationDoctorsCount = organizationId => {
    return dispatch => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}/${organizationId}/Doctors`,
            data: {
                paging: {
                    startIndex: 0,
                    maxItems: -1
                },
                filtering: {
                    searchString: '',
                    fieldsFilter: {
                        'type:missing': false
                    }
                },
                sorting: [],
            }
            }).then((response) => {
                dispatch(mainRegistratorActions.setOrganizationDoctorsCount(get(response, 'data.clientQuery.paging.totalItems')));
            }).catch(err => {
                console.log(err);
            });
    }
}

export const getOrganizationRole = id => {
    return (dispatch) => {
        fetchInstance({
          method: "POST",
          url: `${api.practitionerRole}/_search`,
          data: {
              paging: {
                  startIndex: 0,
                  maxItems: 0
              },
              filtering: {
                  searchString: '',
                  fieldsFilter: {
                    organization: id,
                    extended: true,
                  }
              },
              sorting: [],
          }}).then((response) => {
                // console.log("response.data.items", response.data.items);
                const adminRole = response.data.items.find(item => {
                    return find(item?.extension, {url: "http://miramedix.ru/fhir/StructureDefinition/app-role-reference"})?.valueReference?.reference === "AppRole/registrator";
                })
                // console.log("adminRole", adminRole);
                const admin = response.data.resources[get(adminRole, "practitioner.reference")];
                dispatch(mainRegistratorActions.setOrganizationRole(adminRole));
                dispatch(mainRegistratorActions.setManager(admin));
          }).catch(err => {
            console.log(err)
        });
      };
}

export const editFilial = ({formData, organizationId, callback}) => {
    // console.log("formData", formData);
    if (formData.organizationImage) {
        let imageData = new FormData();
        imageData.append("files", formData.organizationImage);
        return (dispatch) => {
            fetchInstance({
                method: "POST",
                url: `${api.imageUpload}`,
                data: imageData
            }).then((response) => {
                const formDataCopy = cloneDeep(formData);
                delete formDataCopy.organizationImage;
                delete formDataCopy.organizationExtension;
                formDataCopy.organizationExtension = [
                    {
                        url: "http://miramedix.ru/fhir/StructureDefinition/organization-logo",
                        valueUrl: response.data[0].url
                    },
                    {
                        url: "http://miramedix.ru/fhir/StructureDefinition/organization-full-logo",
                        valueUrl: response.data[0].url
                    }
                ]
                dispatch(editOrganization({formData: formDataCopy, organizationId, callback}));
            }).catch(err => {
                console.log(err)
            });
        };
    } else if (formData.organizationImage === null) {
        // console.log("Нужно сбросить");
        return (dispatch) => {
            const formDataCopy = cloneDeep(formData);
            formDataCopy.organizationExtension = [];
            dispatch(editOrganization({formData: formDataCopy, organizationId, callback}));
        };
    } else {
        // console.log("Обновлять не нужно");
        return (dispatch) => {
            dispatch(editOrganization({formData, organizationId, callback}));
        };
    }
};

export const editOrganization = ({formData, organizationId, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.organization}`,
            data: {
                id: formData.organizationId,
                resourceType: "Organization",
                active: true,
                partOf: {
                    reference: `Organization/${formData.organizationPartOf}`,
                },
                identifier: formData.identifier,
                type: [
                    {
                        coding: [
                            {
                                code: 'clinic',
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Филиал"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.organizationProfile.code,
                                display: formData.organizationProfile.display,
                                "system": "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.organizationName,
                address: [
                    {
                        text: formData.organizationAddress,
                    }
                ],
                contact: [
                    {
                        purpose: {
                            text: "Телефон для пациентов"
                        },
                        telecom: [
                            {
                                system: "phone",
                                value: formData.organizationPhone,
                                use: "work"
                            }
                        ]
                    }
                ],
                extension: formData.organizationExtension
            }}).then((response) => {
                dispatch(registerMessage({name: 'edit-organization-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                dispatch(showMessage('edit-organization-message'))
                dispatch(closeMessage({name: 'edit-organization-message', delay: systemMessageDelay}))
                dispatch(createOrUpdateManagerData({formData, organizationId, isEdit: true, callback}));
            }).catch(err => {
                console.log(err)
            });
    };
};

export const getOrganizationRoles = id => {
    return (dispatch) => {
        fetchInstance({
          method: "POST",
          url: `${api.practitionerRole}/_search`,
          data: {
              paging: {
                  startIndex: 0,
                  maxItems: 0
              },
              filtering: {
                  searchString: '',
                  fieldsFilter: {
                    organization: id,
                    extended: true,
                  }
              },
              sorting: [],
          }}).then((response) => {
                dispatch(mainRegistratorActions.setAllOrganizationRoles(response.data));
          }).catch(err => {
            console.log(err)
        });
      };
}

export const createBranch = ({formData, branchesTableSettings, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "POST",
            url: `${api.organization}`,
            data: {
                id: formData.organizationId,
                resourceType: "Organization",
                active: true,
                partOf: {
                    reference: `Organization/${formData.organizationPartOf}`,
                },
                type: [
                    {
                        coding: [
                            {
                                code: "department",
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Подразделение"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.organizationProfile.code,
                                display: formData.organizationProfile.display,
                                system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.organizationName,
                contact: [
                    {
                        purpose: {
                            text: "Телефон для пациентов"
                        },
                        telecom: [
                            {
                                system: "phone",
                                value: formData.organizationPhone,
                                use: "work"
                            }
                        ]
                    }
                ]
            }}).then((response) => {
                dispatch(registerMessage({name: 'create-branch-message', type: 'primary', title: 'Отделение создано', closable: true}))
                dispatch(showMessage('create-branch-message'))
                dispatch(closeMessage({name: 'create-branch-message', delay: systemMessageDelay}))
                dispatch(createOrUpdateManagerData({formData, tableSettings: branchesTableSettings, callback}));
            }).catch(err => {
                console.log(err)
            });
    };
};

export const editBranch = ({formData, branchesTableSettings, organizationId, branchesTable, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "PUT",
            url: `${api.organization}`,
            data: {
                id: formData.organizationId,
                resourceType: "Organization",
                active: true,
                partOf: {
                    reference: `Organization/${formData.organizationPartOf}`,
                },
                identifier: formData.identifier,
                type: [
                    {
                        coding: [
                            {
                                code: "department",
                                system: "http://miramedix.ru/fhir/CodeSystem/onlinedoc-organization-rank",
                                display: "Подразделение"
                            }
                        ]
                    },
                    {
                        coding: [
                            {
                                code: formData.organizationProfile.code,
                                display: formData.organizationProfile.display,
                                system: "http://miramedix.ru/fhir/ValueSet/onlinedoc-organization-profile",
                            }
                        ]
                    }
                ],
                name: formData.organizationName,
                contact: [
                    {
                        purpose: {
                            text: "Телефон для пациентов"
                        },
                        telecom: [
                            {
                                system: "phone",
                                value: formData.organizationPhone,
                                use: "work"
                            }
                        ]
                    }
                ]
            }}).then((response) => {
                dispatch(registerMessage({name: 'edit-branch-message', type: 'primary', title: 'Данные обновлены', closable: true}))
                dispatch(showMessage('edit-branch-message'))
                dispatch(closeMessage({name: 'edit-branch-message', delay: systemMessageDelay}))
                dispatch(createOrUpdateManagerData({formData, tableSettings: branchesTableSettings, organizationId, branchesTable, isEdit: true, callback}));
            }).catch(err => {
                console.log(err)
            });
    };
};

const createOrUpdateManagerData = ({formData, tableSettings, organizationId, branchesTable, isEdit, callback}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'createOrUpdateManager',
            value: true
        }));
        fetchInstance({
            method: "POST",
            url: `${api.practitionerRole}/createorupdateorgmanager`,
            data: {
                practitionerInfo: {
                    practitionerId: formData?.practitionerId,
                    fio: formData?.practitionerName,
                    gender: formData?.practitionerGender,
                    isActive: true,
                    photoUrl: formData?.practitionerPhoto || null,
                },
                practitionerRoleInfo: {
                    positionCode: formData?.practitionerRole,
                    positionDisplay: formData?.practitionerRoleName,
                    practitionerRoleId: formData?.practitionerRoleId,
                    statusCode: formData?.practitionerRoleStatus?.valueCoding?.code,
                    statusDisplayCode: formData?.practitionerRoleStatus?.valueCoding?.display,
                    isActive: true,
                    organizationId: formData.organizationId,
                    notifyWithEmail: true,
                    contactEmail: formData.practitionerEmail,
                    phone: formData.practitionerPhone,
                    // loginEmail: formData?.loginEmail
                },
                appRole: 'registrator'
            }
        }).then((response) => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            if (!isEdit || branchesTable) {
                tableSettings && dispatch(getBranchesList({branchesTableSettings: tableSettings}));
            } else {
                dispatch(getOrganizationDetails(organizationId));
            }

            callback && callback();

        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'createOrUpdateManager',
                value: false
            }));

            console.log(err)
        });
    }
}

export const getBranchesList = ({branchesTableSettings}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'branchesData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organization}/_search`,
        data: {
            paging: branchesTableSettings.paging,
            filtering: {
                searchString: branchesTableSettings.searchString,
                fieldsFilter: branchesTableSettings.fieldsFilter,
            },
            sorting: branchesTableSettings.sorting,
        }
        }).then((response) => {
            dispatch(mainRegistratorActions.setBranchesData(response.data));
            dispatch(mainRegistratorActions.setLoading({
                type: 'branchesData',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'branchesData',
                value: false
            }));
        });
    };
};

export const getPatientsResponseTimeExceeded = ({organizationId, tableSettings}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'timeExceededData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsResponseTimeExceeded`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,
                fieldsFilter: tableSettings.fieldsFilter,
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            dispatch(mainRegistratorActions.setPatientsResponseTimeExceededData(response.data));
            dispatch(mainRegistratorActions.setLoading({
                type: 'timeExceededData',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'timeExceededData',
                value: false
            }));
        });
    };
};

export const getPatientsEndOfCounselingPeriod = ({organizationId, tableSettings}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'endOfCounselingData',
            value: true
        }));
      fetchInstance({
        method: "POST",
        url: `${api.organizationPatientsStatistic}/${organizationId}/PatientsEndOfCounselingPeriod`,
        data: {
            paging: tableSettings.paging,
            filtering: {
                searchString: tableSettings.searchString,
                fieldsFilter: tableSettings.fieldsFilter,
            },
            sorting: tableSettings.sorting,
        }
        }).then((response) => {
            dispatch(mainRegistratorActions.setPatientsEndOfCounselingPeriodData(response.data));
            dispatch(mainRegistratorActions.setLoading({
                type: 'endOfCounselingData',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'endOfCounselingData',
                value: false
            }));
        });
    };
};

// export const hideEncounter = ({encounterId, organizationId, tableSettings}) => {
//     return (dispatch) => {
//       fetchInstance({
//         method: "GET",
//         url: `${api.encounterClose}?encounterId=${encounterId}`
//         }).then((response) => {
//             dispatch(getPatientsEndOfCounselingPeriod({organizationId, tableSettings}));
//         }).catch(err => {
//         });
//     };
// };

export const hideEncounter = ({encounterId, organizationId, tableSettings}) => {
    return (dispatch) => {
      fetchInstance({
        method: "PUT",
        url: `${api.encounter}/${encounterId}/ChangeChannelVisibility`,
        data: {
            setVisible: false
        }
        }).then((response) => {
            dispatch(getStatisticUpdateStatus({encounterId, organizationId, tableSettings, iteration: 30}));
        }).catch(err => {
        });
    };
};

export const getStatisticUpdateStatus = ({encounterId, organizationId, tableSettings, iteration, callback}) => {
    return (dispatch) => {
        fetchInstance({
            method: "GET",
            url: `${api.statisticUpdateStatus}/${encounterId}`,
        }).then((response) => {
            if (response.data === "Completed" || !iteration) {
                if (response.data === "Completed") {
                    dispatch(getPatientsEndOfCounselingPeriod({organizationId, tableSettings}));
                    callback && callback();
                }
                if (!iteration) {
                    dispatch(registerMessage({name: 'check-status-error-message', type: 'red', title: 'Ошибка', text: 'Время ожидания ответа истекло', closable: true}))
                    dispatch(showMessage('check-status-error-message'))
                    dispatch(closeMessage({name: 'check-status-error-message', delay: systemMessageDelay}))
                    callback && callback();
                }
            } else {
                setTimeout(() => dispatch(getStatisticUpdateStatus({encounterId, organizationId, tableSettings, iteration: --iteration, callback})), 1000);
            }
        }).catch(err => {
            console.log(err);
            callback && callback();
        });
    };
};

export const deletePlannedChannel = ({encounterId, organizationId, tableSettings, hideModal}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'deleteChannelModal',
            value: true
        }));
        fetchInstance({
            method: "GET",
            url: `${api.encounterDeletePlannedChannel}?encounterId=${encounterId}`
        }).then((response) => {
            dispatch(getPatientsEndOfCounselingPeriod({organizationId, tableSettings}));
            dispatch(hideModal);
            dispatch(mainRegistratorActions.setLoading({
                type: 'deleteChannelModal',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'deleteChannelModal',
                value: false
            }));
        });
    };
};

export const deleteOrganization = ({id, tableSettings}) => {
    return (dispatch) => {
      fetchInstance({
        method: "DELETE",
        url: `${api.organization}/${id}`,
        }).then((response) => {
            dispatch(getBranchesList({branchesTableSettings: tableSettings}));
        }).catch(err => {
            console.log(err)
            dispatch(registerMessage({name: 'delete-organization-error-message', type: 'red', title: 'Не удалось удалить организацию', text: err?.response?.data || 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('delete-organization-error-message'))
            dispatch(closeMessage({name: 'delete-organization-error-message', delay: systemMessageDelay}))
        });
    };
};

export const replaceDoctor = ({encounterId, newPractitionerRole, handleHide, organizationId, tableSettings}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'replaceDoctor',
            value: true
        }));
        // console.log(newPractitionerRole);
        fetchInstance({
            method: "PUT",
            url: `${api.careTeam}/switchpractitioner`,
            data: {
                encounterId,
                newPractitionerRole,
                fromDate: dayjs().format('YYYY-MM-DD')
              }
        }).then((response) => {
            dispatch(getPatientsEndOfCounselingPeriod({organizationId, tableSettings}));
            handleHide && handleHide();
            dispatch(mainRegistratorActions.setLoading({
                type: 'replaceDoctor',
                value: false
            }));
        }).catch(err => {
            dispatch(mainRegistratorActions.setLoading({
                type: 'replaceDoctor',
                value: false
            }));
            dispatch(registerMessage({name: 'create-channel-error-message', type: 'red', title: 'Ошибка', text: 'Ошибка выполнения запроса', closable: true}))
            dispatch(showMessage('create-channel-error-message'))
            dispatch(closeMessage({name: 'create-channel-error-message', delay: systemMessageDelay}))
        });
    };
};

export const prolongChannel = ({encounterId, daysToAdd, organizationId, tableSettings, callback}) => {
    return (dispatch) => {
        dispatch(mainRegistratorActions.setLoading({
            type: 'prolongChannelModal',
            value: true
        }));
      fetchInstance({
        method: "PUT",
        url: `${api.encounter}/${encounterId}/ProlongChannel`,
        data: {
            encounterId,
            daysToAdd
        }
        }).then((response) => {
            // dispatch(getPatientsEndOfCounselingPeriod({organizationId, tableSettings}));
            dispatch(getStatisticUpdateStatus({encounterId, organizationId, tableSettings, iteration: 30, callback}));
        }).catch(err => {
            console.log(err);
            dispatch(mainRegistratorActions.setLoading({
                type: 'prolongChannelModal',
                value: false
            }));
        });
    };
};

export default mainRegistratorSlice.reducer;
