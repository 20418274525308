import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'modal',
    initialState: [],
    reducers: {
        show: (state, action) => {
            return state.map(item => item.name === action.payload ? {...item, isOpen: true} : {...item, isOpen: false})
        },
        hide: (state, action) => {
            return state.map(item => item.name === action.payload ? {...item, isOpen: false} : {...item, isOpen: false})
        },
        registerModal: ((state, action) => {
            return [
                    ...state,
                    {
                        name: action.payload.name,
                        isOpen: false,
                        component: action.payload.component,
                        modalProps: {modalWidth: '560px', ...action.payload.data, ...action.payload.props},
                        headerComponent: action.payload.header,
                        footerComponent: action.payload.footer,
                        mode: action.payload.mode
                    }
                ]
        }),
        unregisterModal: ((state, action) => {
            return state.filter(item => item.name !== action.payload)
        })
    },
});

export const { show, hide, registerModal, unregisterModal } = modalSlice.actions;

export default modalSlice.reducer;
