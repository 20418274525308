import React, {useState} from 'react'; 
import PropTypes from 'prop-types';
import {Wrapper} from "styled/Wrapper";
import styled from "styled-components";
import Calendar from "components/Calendar";
import { isNil } from 'lodash';

const SimpleCalendar = ({handleHide, showShaded, onClick, defaultValue, fromDate, toDate, disableSelectedDateCallback}) => {
    let defaultDate = new Date(Date.parse(defaultValue));
    defaultDate.setDate(1);
    const [date, setDate] = useState(defaultDate);
    const [activeStartDate, setActiveStartDate] = useState(new Date(Date.parse(defaultValue)));

    const changeVisibleMonth = (direction, month, year) => {
        setDate(state => {
            return new Date(!isNil(year) ? year : state.getFullYear(), !isNil(month) ? month : state.getMonth() + direction, 1);
        })
    }

    const handleClickDay = (date) => {
        onClick(date);
        setActiveStartDate(new Date(date.year, date.month, date.day));
    }

    return (
        <Wrapper paddings={'12px 0'}>
            <Content>
                <CalendarWrapper>
                    <Calendar onClick={handleClickDay} onChange={changeVisibleMonth} date={date} showShaded={showShaded} activeDate={activeStartDate} fromDate={fromDate} toDate={toDate} disableSelectedDateCallback={disableSelectedDateCallback}/>
                </CalendarWrapper>
            </Content>
        </Wrapper>
    );
};

const CalendarWrapper = styled.div`
    padding: 0 12px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
`

SimpleCalendar.propTypes = {
    handleHide: PropTypes.func.isRequired,
};

export default SimpleCalendar;
