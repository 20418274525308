import React, { useMemo } from 'react'; 
import PropTypes from 'prop-types';
import styled from "styled-components";
import ActionIcon from "../ActionIcon";
import ArrowIcon from "../Icons/ArrowIcon";
import CalendarCell from "./CalendarCell";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import FloatingMenu from 'components/FloatingMenu';
import SelectButton from './SelectButton';
import { find } from 'lodash';

dayjs.extend(isBetween);

const Calendar = ({date, showShaded, activeDate, onChange, onClick, fromDate, toDate, activeField, disableSelectedDateCallback}) => {

    // console.log('date', date);
    // console.log('activeDate', activeDate);

    const days = [
        'ПН',
        'ВТ',
        'СР',
        'ЧТ',
        'ПТ',
        'СБ',
        'ВС',
    ]

    const months = [
        {
            id: 0,
            label: 'Январь',
            value: 0,
        },
        {
            id: 1,
            label: 'Февраль',
            value: 1,
        },
        {
            id: 2,
            label: 'Март',
            value: 2,
        },
        {
            id: 3,
            label: 'Апрель',
            value: 3,
        },
        {
            id: 4,
            label: 'Май',
            value: 4,
        },
        {
            id: 5,
            label: 'Июнь',
            value: 5,
        },
        {
            id: 6,
            label: 'Июль',
            value: 6,
        },
        {
            id: 7,
            label: 'Август',
            value: 7,
        },
        {
            id: 8,
            label: 'Сентябрь',
            value: 8,
        },
        {
            id: 9,
            label: 'Октябрь',
            value: 9,
        },
        {
            id: 10,
            label: 'Ноябрь',
            value: 10,
        },
        {
            id: 11,
            label: 'Декабрь',
            value: 11,
        }
    ]

    const getYearCollection = useMemo(() => {
        const start = 1970;
        const end = 2050;
        const collection = [];

        for(let year = start; year <= end; year++){
            collection.push({
                label: year,
                value: year
            })
        }

        return collection;
    })

    const from = fromDate && dayjs(fromDate);
    const to = toDate && dayjs(toDate);

    const year = date.getFullYear()
    const month = date.getMonth()
    const daysInMonth = new Date(year, month + 1, 0).getDate()
    const dayOfWeek = date.getDay()
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay()
    const activeMonth = activeDate.getMonth()
    const activeDay = activeDate.getDate()
    const activeYear = activeDate.getFullYear()

    // console.log('year', year)
    // console.log('month', month)
    // console.log('day', day)
    // console.log('activeDay', activeDay)
    // console.log('activeMonth', activeMonth)
    // console.log('activeYear', activeYear)
    // console.log('daysInMonth', daysInMonth)
    // console.log('dayOfWeek', dayOfWeek)
    // console.log('firstDayOfMonth', firstDayOfMonth)

    const getDaysInMonth = (month) => {
        return new Date(year, month, 0).getDate()
    }

    const options = { year: 'numeric', month: 'long' };

    const handleClick = ({day, month, year}) => {

        if(disableSelectedDateCallback){
            if(!(day === activeDay && month === activeMonth && year === activeYear)){
                onClick({
                    year,
                    month,
                    day
                })
            }
        }else{
            onClick({
                year,
                month,
                day
            })
        }
    }

    const handleShift = (direction) => {
        onChange(direction)
    }

    const handleMonth = (obj) => {
        onChange(null, obj?.value, null)
    }

    const handleYear = (obj) => {
        onChange(null, null, obj?.value)
    }

    // const renderDate = (date) => {
    //     return date.toLocaleDateString("ru-RU", options).charAt(0).toUpperCase() + date.toLocaleDateString("ru-RU", options).slice(1)
    // }

    let calendar = []

    const renderCalendar = () => {
        calendar = []
        for(let i = 0; i < daysInMonth; i++) {

            const curDate = dayjs().year(year).month(month).date(i+1).hour(0).minute(0);
            const disabled = (from && to) ? !curDate.isBetween(from, to, 'd', '[]') :
                            (from && !to) ? curDate.isBefore(from, 'd') :
                            (!from && to) ? curDate.isAfter(to, 'd') : false

            calendar.push(<CalendarCell
                day={i+1}
                month={month}
                year={year}
                onClick={disabled ? () => {} : handleClick}
                active={(activeMonth === month && activeYear === year) ? activeDay === i+1 : false}
                disabled={disabled}
                fromDate={fromDate}
                activeField={activeField}
            />)
        }

        return calendar
    }

    const renderPrevDates = () => {
        calendar = []
        for(let i = 0; i < (dayOfWeek === 0 ? 6 : dayOfWeek - 1); i++) {

            const curDate = dayjs().year(year).month(month-1).date(getDaysInMonth(month) - i).hour(0).minute(0);
            const disabled = (from && to) ? !curDate.isBetween(from, to, 'd', '[]') :
                            (from && !to) ? curDate.isBefore(from, 'd') :
                            (!from && to) ? curDate.isAfter(to, 'd') : false

            calendar.unshift(<CalendarCell day={getDaysInMonth(month) - i} month={month-1} year={year} 
            shaded={!showShaded}
            onClick={disabled ? () => {} : handleClick} /*fromDate={fromDate} activeField={activeField}*/ disabled={disabled} />)
        }
        return calendar
    }

    const renderNextDates = () => {
        calendar = []
        const iterations = (lastDayOfMonth === 0 ? 7 : 7 - lastDayOfMonth);
        for(let i = 0; i < iterations; i++) {

            const curDate = dayjs().year(year).month(month+1).date(i+1).hour(0).minute(0);
            const disabled = (from && to) ? !curDate.isBetween(from, to, 'd', '[]') :
                            (from && !to) ? curDate.isBefore(from, 'd') :
                            (!from && to) ? curDate.isAfter(to, 'd') : false

            if(i === 0 && iterations === 7) { //Не рисуем полную строку shaded дат для следующего месяцы
                break;
            }

            calendar.push(<CalendarCell day={i+1} month={month+1} year={year} 
                shaded={!showShaded}
                onClick={disabled ? () => {} : handleClick} /*fromDate={fromDate} activeField={activeField}*/ disabled={disabled} />)
        }
        return calendar
    }

    const currentYearObj = useMemo(() => find(getYearCollection, { value: year }), [year]);
    const currentMonthObj = useMemo(() => find(months, { value: month }), [month]);

    return (
        <CalendarWrapper>
            <Header>
                <ActionIcon icon={ArrowIcon} rotate={90} action={() => handleShift(-1)} size={'32px'} tooltip={'Предыдущий'} tooltipZIndex="10002" />
                <DateWrapper>
                    {/* {renderDate(date)} */}
                    <SelectButton label={currentMonthObj?.label} component={FloatingMenu} anchorZIndex={100} active={currentMonthObj?.value} componentProps={{list: months, onClick: handleMonth, maxContainerHeight: 'unset'}} />
                    <SelectButton label={currentYearObj?.label} component={FloatingMenu} anchorZIndex={100} active={currentYearObj?.value} componentProps={{list: getYearCollection, onClick: handleYear, fixedHeightValue: '200px', scrollWheelSpeed: 0.15}} />
                </DateWrapper>
                <ActionIcon icon={ArrowIcon} rotate={-90} action={() => handleShift(1)} size={'32px'} tooltip={'Следующий'} tooltipZIndex="10002" />
            </Header>
            <Content>
                <CalendarRow>
                    {days.map(item => <Day>{item}</Day>)}
                </CalendarRow>
                <CalendarRow>
                    {renderPrevDates()}
                    {renderCalendar()}
                    {renderNextDates()}
                </CalendarRow>
            </Content>
        </CalendarWrapper>
    );
};

const CalendarRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    
    :last-child {
        margin-bottom: 0;
    }
`

const Day = styled.div`
    margin-right: 12px;
    width: 32px;
    text-align: center;
    ${({theme}) => theme.mixins.robotoMedium({size: theme.fonts.sizes.label, color: theme.colors.black30, lineheight: theme.fonts.lineHeight.small})};
  
    :nth-child(7n) {
        margin-right: 0;
    }
`

const Content = styled.div`
    padding: 0 4px;
`

const CalendarWrapper = styled.div`
    width: 304px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`

const DateWrapper = styled.div`
    ${({theme}) => theme.mixins.robotoMedium()};
    display: flex;
    flex-direction: row;
`

Calendar.propTypes = {
    date: PropTypes.object.isRequired,
    activeDate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Calendar;
